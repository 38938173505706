import { SortDescriptor } from "format-lib/index.bin";
import { eTags } from "./models/KPIsManager.bin";
import { ClassProperty } from "./models/VertexProperty.bin";
import { ref_AdvertiserGroups } from "./models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "./models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "./models/orientdb/ref_Brands.bin";
import { ref_Products } from "./models/orientdb/ref_Products.bin";
import { ref_Messages } from "./dto/client/ref_Messages.bin";
import { DataProvider } from "./provider";
import { ref_Discount } from "./models/types/vertex.bin";
import { recurseAll } from "tools-lib";
import { matchIndicateur } from "adwone-engine/index.bin";

export type PromiseResolve<T> = (value?: T | PromiseLike<T>) => void;
export type PromiseReject = (error?: any) => void;

export type Dictionary<T> = { [prop: string]: T };
export type DictionaryList<T> = { [prop: string]: T[] };
export type DictionaryKey<T, V> = { [prop in keyof T]: V };

export function SanitizeDuplication<T>(element: T): T {
    delete element['Import'];
    delete element['Deversement'];
    delete element["_id"];
    delete element["@rid"];
    delete element["__v"];
    delete element["Status"];

    /** Case of duplication, remove bound kpis */
    if (element["KPIs"])
        Object.keys(element["KPIs"])
            .filter(k => k.startsWith("Bound"))
            .forEach(k => delete element["KPIs"][k]);

    return element;
}

export const rmAccents = (s: string) => {
    return s?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/'/g, " ");
}

export function arrayOrNull(arr: any[]) {
    return arr?.length ? arr : null;
}

export function orderBy<T>(data: T[], descriptors: SortDescriptor<T>[]) {

    if (!descriptors?.length)
        return data;

    const results = [...data];
    descriptors.forEach(d => {
        results.sort((a, b) => {
            const valA = a[d.field];
            const valB = b[d.field];

            if (valA === valB)
                return 0;

            if (typeof valA === "string")
                return valA.localeCompare(valB?.toString?.());
            if (typeof valB === "string")
                return valB.localeCompare(valA?.toString?.());

            if (typeof valA === "number") {
                if (Number.isNaN(valB)) return -1;
                return valA - Number(valB);
            }
            if (typeof valB === "number") {
                if (Number.isNaN(valA)) return 1;
                return valB - Number(valA);
            }

            if (typeof valA === "boolean" && (valB === undefined || valB === null)) return -1;
            if (typeof valB === "boolean" && (valA === undefined || valA === null)) return 1;

            if (valA === valB)
                return 0;

            if (valA > valB)
                return 1;
            if (valB > valA)
                return -1;
            return 0;
        });

        if (d.dir === "desc")
            results.reverse();
    })

    return results;
}

export const JoinElements = (obj: any[]) => {
    const templ = (val: any) => {

        if (val?.cluster !== undefined && val?.position !== undefined)
            return val.toString();

        if (typeof val === "object")
            return JSON.stringify(val);

        return val?.toString?.() ?? val;
    };

    return obj.filter(e => e !== undefined && e !== null)
        .filter(e => e != "")
        .map(templ)
        .join("|");
}

export function toDictionary<T>(array: T[], key: (e: T) => string): Dictionary<T> {
    let dico: { [prop: string]: T } = {};
    array?.forEach(e => dico[key(e)] = e);
    return dico;
}

export function toDictionaryValue<T, V>(array: T[], key: (e: T) => string, value: (e: T) => V): Dictionary<V> {
    let dico: { [prop: string]: V } = {};
    array?.forEach(e => dico[key(e)] = value(e));
    return dico;
}

export function toDictionaryList<T>(array: T[], key: (e: T) => string): DictionaryList<T> {
    let dico: { [prop: string]: T[] } = {};
    array?.forEach(e => {
        if (!dico[key(e)]) dico[key(e)] = [e];
        else dico[key(e)].push(e);
    });
    return dico;
}

export function propertyOf<TObj>(name: (keyof TObj) & string) { return name }
export function propertiesOf<TObj>(...names: (keyof TObj)[]) { return names }

export function Instantiate<T>(typeTargt: new () => T, data: any): T {
    let target = Object.create(typeTargt.prototype);
    return Object.assign(target, data);
}

export function distinct<T>(array: T[], key: (e: T) => string = (e) => e?.toString()): T[] {
    let res: T[] = [];
    let dico: { [prop: string]: T } = {};
    array?.forEach(e => {
        if (!dico[key(e)]) {
            dico[key(e)] = e
            res.push(e);
        }
    });
    return res;
}

export function groupBy<T>(array: T[], key: (e: T) => string | number, skipUndefined: boolean = false): { [key: string]: T[] } {
    const res: { [key: string]: T[] } = {};
    for (const e of array) {
        const keyFind = key(e);
        if (skipUndefined && keyFind === undefined) {
            continue;
        }
        if (!res[keyFind]) {
            res[keyFind] = [];
        }
        res[keyFind].push(e);
    }
    return res;
}

export async function groupByAsync<T>(array: T[], key: (e: T) => Promise<string>): Promise<{ [key: string]: T[]; }> {
    const res: { [key: string]: T[] } = {};
    for (const e of array) {
        const keyStr = await key(e);
        if (!res[keyStr]) {
            res[keyStr] = [];
        }
        res[keyStr].push(e);
    }
    array.forEach(e => {

    });
    return res;
}

export function truncate(str: string, num: number, dot?: true) {
    if (!str)
        return str;
    if (str.length <= num)
        return str
    return `${str.slice(0, dot ? num - 3 : num)}${dot ? "..." : ""}`;
}

/**
 * @description Prépare et ordonne les elements pour la colonne de filtre
 * @author Bastien Douib
 * @export
 * @template T
 * @param {ClassProperty[]} _metas
 * @param {Set<String>} [shouldNotBeFiltered=new Set([])]
 * @returns {*}  {(("Dates" | "Hierarchy" | keyof T)[])}
 */
export function orderMetaDatas<T>(_metas: ClassProperty[], shouldNotBeFiltered: Set<String> = new Set([])): ("Dates" | "Hierarchy" | keyof T)[] {
    let metas = [..._metas];
    let orders = [];
    if (metas.filter((m) => m.name === "Start" || m.name === "End").length >= 1) {
        orders.push("Dates");
        metas = metas.filter((m) => !["Start", "End"].includes(m.name));
    }
    if (
        metas.filter((m) =>
            [
                ref_AdvertiserGroups.name,
                ref_Advertisers.name,
                ref_Brands.name,
                ref_Products.name,
            ].includes(m.linkedClass)
        ).length
    ) {
        orders.push("Hierarchy");
        metas = metas.filter(
            (m) =>
                ![
                    ref_AdvertiserGroups.name,
                    ref_Advertisers.name,
                    ref_Brands.name,
                    ref_Products.name,
                ].includes(m.linkedClass)
        );
    }
    orders = [
        ...orders,
        ...metas.filter((m) => !shouldNotBeFiltered.has(m.linkedClass)).map((e) => e.name),
    ];
    return orders;
}

export function distinctValues(array: string[]) {
    let uniqueArray = array.filter((item, pos) => array.indexOf(item) == pos);
    return uniqueArray.filter(e => e != undefined);
}

export function firstOrArray<T>(array: T[], origin: any | any[]): T | T[] {
    if (Array.isArray(origin))
        return array;
    return array[0];
}

export function firstOrDefault<T>(array: T[]): T {
    if (array.length)
        return array[0];
    return undefined;
}

export function firstOrDefaultWhere<T>(array: T[], test: (e: T) => any): T {
    if (array?.length) {
        for (const element of array) {
            if (test(element)) return element;
        }
    }
    return undefined;
}

export function agregateEntries(original: string[] | string, filter: string[]) {
    if (!Array.isArray(original)) original = [original]
    return original.filter(o => filter.includes(o));
}

export function extract<Target, Source>(obj: { [prop in keyof Target]: keyof Source }, data: any): Target {

    if (!data) return null;
    let res: any = {};

    Object.entries(obj).forEach((e) => {
        if (data.hasOwnProperty(e[1]))
            res[e[0]] = data[<string>e[1]]
    });

    if (!Object.keys(res).length)
        return null;
    return res;
}

export function getDifferenceKeys(data1: any, data2: any) {
    const keys: string[] = [];
    const allKeys = Array.from(new Set([...Object.keys(data1 ?? {}), ...Object.keys(data2 ?? {})]))
    for (const key of allKeys) {
        if (JSON.stringify(data1?.[key] ?? {}) !== JSON.stringify(data2?.[key] ?? {}))
            keys.push(key);
    }
    return keys;
}

export function toArray<T>(obj: T | T[]): T[] {
    return Array.isArray(obj) ? obj : [obj];
}

export function safeArray<T>(array: T[]) {
    return array ?? [];
}

export function safeNumber(value: number) {
    if (isNaN(value)) return 0;
    if (typeof value === "string") return Number(value);
    return value ?? 0;
}

/**
 * @description Determine if an array contains one single value and return it
 * @author Bastien Douib
 * @param {string[]} array
 * @returns {*}  {string}
 */
export function arrayUnique(array: string[]): string {
    const newArray = [...new Set(array.filter(Boolean))];
    return newArray.length === 1 ? newArray[0] : undefined;
}

export function extractSub<Source>(data: Source, props: (keyof Source)[], defaultValue?: any): Partial<Source> {

    if (!data) return null;
    let res: Partial<Source> = {};

    props.forEach((e) => {
        if (data.hasOwnProperty(e))
            res[e] = data[<string>e]
        else if (defaultValue !== undefined)
            res[e] = defaultValue;
    });

    if (!Object.keys(res).length)
        return null;
    return res;
}

export function removeSub<Source>(data: Source, props: (keyof Source)[]): Partial<Source> {

    if (!data) return null;
    let res: Partial<Source> = clone(data);

    props.forEach((e) => {
        if (data.hasOwnProperty(e))
            delete res[e]
    });

    if (!Object.keys(res).length)
        return null;
    return res;
}

/**
 * @description return true if params are identical
 */
export function compareObjects(_o: { [key: string]: any }, _p: { [key: string]: any }) {

    const o = duplicate(_o);
    const p = duplicate(_p);

    var i: number,
        keysO = Object.keys(o).sort(),
        keysP = Object.keys(p).sort();

    if (keysO.length !== keysP.length) return false; //not the same nr of keys
    if (keysO.join("") !== keysP.join("")) return false; //different keys
    for (i = 0; i < keysO.length; ++i) {
        if (o[keysO[i]] instanceof Array) {
            if (!(p[keysO[i]] instanceof Array)) return false;

            if (o[keysO[i]].length != p[keysO[i]].length) return false;

            for (let j = 0; j < o[keysO[i]].length; j++) {
                let ret = compareObjects(o[keysO[i]][j], p[keysO[i]][j]);
                if (!ret) return false;
            }
            //if (p[keysO[i]].sort().join("") !== o[keysO[i]].sort().join("")) return false;
        } else if (o[keysO[i]] instanceof Date) {
            if (!(p[keysO[i]] instanceof Date)) return false;
            if ("" + o[keysO[i]] !== "" + p[keysO[i]]) return false;
        } else if (o[keysO[i]] instanceof Function) {
            if (!(p[keysO[i]] instanceof Function)) return false;
            //ignore functions, or check them regardless?
        } else if (o[keysO[i]] instanceof Object) {
            if (!(p[keysO[i]] instanceof Object)) return false;
            if (o[keysO[i]] === o) {
                //self reference?
                if (p[keysO[i]] !== p) return false;
            } else if (compareObjects(o[keysO[i]], p[keysO[i]]) === false) return false; //WARNING: does not deal with circular refs other than ^^
        } else if (o[keysO[i]] !== p[keysO[i]])
            //change !== to != for loose comparison
            return false; //not the same value
    }

    return true;
}

export function orderKeys(_o: { [key: string]: any }) {
    const o = duplicate(_o);
    const keysO = Object.keys(o).sort();
    for (let i = 0; i < keysO.length; ++i) {
        if (o[keysO[i]] instanceof Object)
            o[keysO[i]] = orderKeys(o[keysO[i]]);
    }
    return o;
}

export function serializeObject(_o: { [key: string]: any }) {
    return JSON.stringify(_o);
}

export function ClearEmptyValues<T>(data: T) {
    if (!data)
        return null;
    const newParams = duplicate(data);
    for (let [key, value] of Object.entries(data)) {
        if (Array.isArray(value) && value.length === 0) {
            delete newParams[key];
        }
    }
    return newParams;
}

export function ClearUndefinedValues<T>(data: T) {
    if (!data)
        return null;
    const newParams = duplicate(data);
    for (let [key, value] of Object.entries(data)) {
        if (value === undefined) {
            delete newParams[key];
        }
    }
    return newParams;
}

export function compareArrays(a1: string[], a2: string[]) {
    if ((a1 && !a2) || (!a1 && a2)) return false;
    if (!a1) a1 = [];
    if (!a2) a2 = [];

    const a2Sorted = [...a2].sort();
    return a1.length === a2.length && [...a1].sort().every((value, index) => value === a2Sorted[index]);
}

export function includeArrays(a1: string[], a2: string[]) {
    if ((a1 && !a2) || (!a1 && a2)) return false;
    if (!a1) a1 = [];
    if (!a2) a2 = [];

    return a1.length >= a2.length && a2.every(e => a1.includes(e));
}

export function duplicate<T>(data: T): T {
    return JSON.parse(JSON.stringify(data ?? {}));
}

export function clone<T>(data: T, visited = new Map<any, any>()): T {
    if (data === undefined || data === null)
        return data;

    if (typeof data === 'object') {

        if (visited.has(data))
            return visited.get(data);

        if (Array.isArray(data))
            return [...data.map((d) => clone(d, visited))] as (T & any[]);
        if (data instanceof Date)
            return new Date(data) as (T & Date);

        const copy = Object.assign(Object.create(Object.getPrototypeOf(data)), data) as T;
        visited.set(data, copy);
        Object.entries(data).forEach(([k, v]) => {
            copy[k] = clone(v, visited);
        });
        return copy;
    }
    return data;
}

export function memoize(func: (...agrs: any) => any, options?: { keyExpiration?: number, key?: (...args) => string }) {
    const results = {};
    const timeouts = {};

    return (...args: any) => {
        const argsKey = options?.key ? options?.key(...args) : JSON.stringify(args);
        if (!results[argsKey]) {
            results[argsKey] = func(...args);
        }

        const keyExpiration = options?.keyExpiration;
        if (keyExpiration) {
            if (timeouts[argsKey])
                clearTimeout(timeouts[argsKey]);
            timeouts[argsKey] = setTimeout(() => {
                delete results[argsKey];
                console.log(`Memoize key ${argsKey} expired`);
            }, keyExpiration);
        }

        return results[argsKey];
    };
};

export function memoizeAsyncBase<TArgs, TRes>(func: (...agrs: any[]) => Promise<TRes>, options?: { keyExpiration?: number }): (...agrs: TArgs[]) => Promise<TRes> {
    const results: { [prop: string]: Promise<TRes> } = {};
    return async (...args: TArgs[]) => {
        const argsKey = JSON.stringify(args ?? {});
        if (!results[argsKey])
            results[argsKey] = func(...args);
        return results[argsKey];
    };
};

let memoFunc = memoizeAsyncBase;
export function SetMemoizeAsync<TArgs, TRes>(func: typeof memoizeAsync) {
    memoFunc = func;
}

export function memoizeAsync<TArgs, TRes>(func: (...agrs: any[]) => Promise<TRes>, options?: { keyExpiration?: number }): (...agrs: TArgs[]) => Promise<TRes> {
    // must be overriden
    return memoFunc(func, options);
};

export function duplicateObject(data: any) {
    let copy = {};
    if (data) {
        Object.entries(data).forEach(([k, v]) => {
            if (typeof v !== "function")
                copy[k] = v;
        })
    }

    return JSON.parse(JSON.stringify(copy));
}

export function roundToTwoDecimals(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function convertToHtmlString(input: string): string {
    return input;
    // const escapedString = input
    //     .replace(/'/g, "'")
    //     .replace(/é/g, "&eacute;")
    //     .replace(/"/g, "&quot;")
    //     .replace(/&/g, "&amp;")
    //     .replace(/</g, "&lt;")
    //     .replace(/>/g, "&gt;");

    // return escapedString;
}

export function roundToNDecimals(num: number, n: number): number {
    return Math.round((num + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n);
}

export enum ePropertyOptionFilter {
    DiscountNotEmpty = "DiscountNotEmpty",
    DistinctIsUnique = "DistinctIsUnique"
}

export function IndicateurInfoFilter(obj: any, match: matchIndicateur) {
    const filter = <ePropertyOptionFilter><unknown>match['filter'];
    switch (filter) {
        case ePropertyOptionFilter.DiscountNotEmpty:
            const v = obj as ref_Discount
            return v?.CO?.Value > 0 || v?.FO?.Value > 0 || v?.FOS?.Value > 0 || v?.CO?.Rate > 0 || v?.FO?.Rate > 0 || v?.FOS?.Rate > 0;
        case ePropertyOptionFilter.DistinctIsUnique:
            if (Array.isArray(obj))
                return distinct(toArray(obj), i => i[match.subProperty])?.length == 1;
            return true;
        default:
            return true;
    }
}

export function performances(text: string, callBack: () => void) {
    const start = new Date();
    let res = callBack();
    const end = new Date();
    console.log(`${text}: `, end.getTime() - start.getTime(), ` ms`);
    return res;
}

export async function performancesAsync<T>(text: string, callBack: () => Promise<T>) {
    const start = new Date();
    let res = await callBack();
    const end = new Date();
    console.log(`[PERF] ${text}: `, end.getTime() - start.getTime(), ` ms`);
    return res;
}

export async function* splitBlocks<T>(elements: T[], blockSize = 100, callBack?: () => (void | Promise<void>)) {
    if (!callBack) callBack = ReleaseEventLoop;
    for (let i = 0; i < elements.length; i++) {
        // Yield (retourner) chaque élément au consommateur de l'itérateur
        yield elements[i];

        // Si l'index actuel + 1 est un multiple de la taille du bloc, exécuter le callBack
        if ((i + 1) % blockSize === 0) {
            await callBack();
        }
    }

    // Gérer le cas où le nombre total d'éléments n'est pas un multiple de la taille du bloc
    if (elements.length % blockSize !== 0) {
        await callBack();
    }
}

export async function splitBlocksFor<T>(elements: T[], callBack: (e: T) => void, blockSize = 100) {
    for (let i = 0; i < elements.length; i++) {
        callBack(elements[i]);
        if ((i + 1) % blockSize === 0) {
            await ReleaseEventLoop();
        }
    }
}

export async function splitBlocksForAsync<T>(elements: T[], callBack: (e: T) => Promise<void>, blockSize = 100) {
    for (let i = 0; i < elements.length; i++) {
        await callBack(elements[i]);
        if ((i + 1) % blockSize === 0) {
            await ReleaseEventLoop();
        }
    }
}


type SubElementMatchArgs = ({ subProperty: string, value: any } | { subProperty: string, filter: ePropertyOptionFilter });
export function GetSubElement(data: any, prop: string, _match?: SubElementMatchArgs | SubElementMatchArgs[]) {

    if (!data || !prop)
        return null;

    let obj = data;
    if (hasOwnProperty(obj, prop))
        return obj[prop];
    const match = _match ? toArray(_match) : undefined;
    const props = prop.split(`.`);
    for (const key of props) {
        if (Array.isArray(obj))
            obj = obj
                .map(e => e?.[key])
                .filter(Boolean)
        else {
            obj = obj?.[key];
            if (Array.isArray(obj))
                obj = obj
                    .filter(Boolean)
                    .filter(e => {
                        if (!match) return true;
                        return match.every(m => {
                            if (m.subProperty != key) return true;
                            else if ("value" in m) return compareObjects(m['value'], extractSub(e, Object.keys(m['value'])));
                            else if ("filter" in m) {
                                return IndicateurInfoFilter(e, m);
                            }
                        })

                    })
                    .filter(Boolean)
        }
    }
    return obj;
}

export function IsIDDB(id: string) {
    return IsOrientDB(id) || IsMongoDB(id);
}

export function IsOrientDB(id: string) {
    if (!id || id.length == 0 || !id.startsWith?.('#'))
        return false;
    const part = id.split(":");
    if (part.length != 2)
        return false;
    return part.every(p => /-?\d+/.test(p));
}

export function IsMongoDB(id: string) {
    return id && id.length === 24 && /^[0-9a-fA-F]+$/.test(id);
}

export function SetSubElement(data: any, prop: string, value: any) {
    if (!data)
        return;

    let obj = data;
    const props = prop.split(`.`);
    for (let i = 0; i < props.length - 1; i++) {
        const key = props[i];
        if (!obj.hasOwnProperty(key)) obj[key] = {};
        obj = obj[key];
    }
    obj[props[props.length - 1]] = value;
}

export function RemoveKeyChar(data: any, toReplace: string, newStr: string) {
    if (Array.isArray(data)) {
        data.forEach(d => RemoveKeyChar(d, toReplace, newStr));
        return data;
    }

    if (data && typeof data === "object") {
        Object.entries(data).forEach(([k, v]) => {
            if (typeof k === "string") {
                const newKey = k.replace(toReplace, newStr);
                if (newKey !== k) {
                    delete data[k];
                    data[newKey] = RemoveKeyChar(v, toReplace, newStr);
                } else {
                    data[k] = RemoveKeyChar(v, toReplace, newStr);
                }
            } else {
                data[k] = RemoveKeyChar(v, toReplace, newStr);
            }
        });
    }
    return data;
}

export function GetFlatElements<T>(elements: T[], propChildren: (keyof T) & string): T[] {
    const subElements = elements?.map(e => GetFlatElements(e[propChildren] as any, propChildren)) ?? [];
    return (elements ?? []).concat(...subElements);
}

export function JSONEqualityComparer(a: any, b: any) {
    return JSON.stringify(a ?? {}) == JSON.stringify(b ?? {})
}

export function Serialize(data: any) {
    let ToString: string = "";
    try {
        ToString = data?.toString();
    } catch (error) {

    }

    let SerializedData: any = {};
    try {
        SerializedData = data && JSON.parse(JSON.stringify(data));
    } catch (error) {

    }

    return {
        SerializedData,
        ToString,
    }
}

export function SerializeError(error: any) {
    let ToString: string = "";
    try {
        ToString = error?.toString();
    } catch (error) {

    }

    let SerializedError: any = {};
    try {
        SerializedError = error && JSON.parse(JSON.stringify(error));
    } catch (error) {

    }

    return {
        SerializedError,
        ToString,
        Message: error?.message,
        ResponseData: error?.response?.data,
        Stack: error?.stack,
        Code: error?.code,
        CodeResponse: error?.response?.code,
    }
}

let _releaseEventLoopOverride: typeof ReleaseEventLoop = null;
export const SetReleaseEventLoopOverride = (_override: typeof ReleaseEventLoop) => {
    _releaseEventLoopOverride = _override;
}

export function ReleaseEventLoop() {
    if (_releaseEventLoopOverride)
        return _releaseEventLoopOverride();
    return new Promise((res, rej) => {
        setImmediate ? setImmediate(() => res(undefined)) : setTimeout(() => res(undefined), 0);
    });
}

export function hasConfig<TConfig>(config: Partial<TConfig>, keysToIgnore: string[] = ["Start", "End", "ViewMode", "CrossedtableConfig"]): boolean {
    const newObject = { ...config };
    for (const key of keysToIgnore) {
        delete newObject[key];
    }
    return Object.values(newObject).filter(Boolean).length > 0;
}

export function GetHashCode(obj: any) {
    if (!obj) return "";

    const str = JSON.stringify(obj);
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function GenerateKey(...els: (string | number | { [key: string]: any } | string[])[]) {
    return els.map((el) => {
        let toString = "";
        if (Array.isArray(el))
            toString = el.join("-");
        else if (typeof el === 'object')
            toString = GetHashCode(el).toString();
        else
            toString = el as string;
        return `[${toString}]`;
    }).join("-");
}

export function IsPromise(p) {
    if (
        p !== null &&
        typeof p === 'object' &&
        typeof p.then === 'function' &&
        typeof p.catch === 'function'
    ) {
        return true;
    }

    return false;
}

export function clearEmptyValues<TConfig>(object: TConfig): TConfig {
    const newObject = { ...object };
    if (object)
        for (let [key, value] of Object.entries(object)) {
            if ((Array.isArray(value) && value.length === 0) || value == undefined) {
                delete newObject[key];
            }
        }
    return newObject;
}

// format response with modifiers
export function formater<T>(children: T[], modifiers: { [key: string]: string }): T[] {
    if (children.length === 0 || typeof children[0] !== "object") return children;
    return children.map((child) => {
        const formatedChild = { ...child };
        for (const key of Object.keys(modifiers)) {
            if (modifiers[key]) {
                formatedChild[modifiers[key]] = Array.isArray(formatedChild[key])
                    ? formater(formatedChild[key], modifiers)
                    : formatedChild[key];
                delete formatedChild[key];
            }
        }
        return formatedChild;
    });
}

export function splitArray<T>(_datas: T[], splitNumber: number): T[][] {
    const splited: T[][] = []
    const datas = [..._datas];
    while (datas.length > 0) {
        splited.push(datas.splice(0, splitNumber))
    }
    return splited;
}

export const Typed = <T>(d: T) => d;

export const hasOwnProperty = <T>(object: T, property: string) => {
    if (!object)
        return false;

    return Object.prototype.hasOwnProperty.call(object, property);
};

export const sleep = (time: number) => {
    return new Promise((res, rej) => {
        setTimeout(() => {
            res(undefined);
        }, time);
    })
}

export const groupByTag = (properties: { Tags?: string[], Name: string }[]): { [key in eTags]?: string[] } => {
    const tags = Object.values(eTags);
    const group = {};
    tags.forEach(e => group[e] = []);
    for (const p of properties.filter(e => e.Tags)) {
        for (const tag of tags) {
            if (p.Tags.includes(tag)) {
                group[tag].push(p.Name);
            }
        }
    }
    return group;
}

export function RGBAToHexA(rgba, forceRemoveAlpha = false) {
    return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
        .split(',') // splits them at ","
        .filter((string, index) => !forceRemoveAlpha || index !== 3)
        .map(string => parseFloat(string)) // Converts them to numbers
        .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
        .map(number => number.toString(16)) // Converts numbers to hex
        .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
        .join("") // Puts the array to togehter to a string
}

export function ToArrayObject(obj: any) {
    const params: any = {};
    if (obj) {
        if (obj.AdvertiserGroup) params.AdvertiserGroup = toArray(obj.AdvertiserGroup);
        if (obj.Advertiser) params.Advertiser = toArray(obj.Advertiser);
        if (obj.Brand) params.Brand = toArray(obj.Brand);
        if (obj.Product) params.Product = toArray(obj.Product);
        if (obj.Campaign) params.Campaign = toArray(obj.Campaign);
    }
    return params;
}

export function SplitArrayInChunks<T>(myArray: T[], sizeOfChunk) {
    let result: T[][] = [];
    for (let i = 0; i < myArray.length; i += sizeOfChunk) {
        let sousTableau = myArray.slice(i, i + sizeOfChunk);
        result.push(sousTableau);
    }
    return result;
}

export function removeDiacritics(str: any) {
    if (typeof str !== 'string')
        return str;

    const defaultDiacriticsRemovalMap = [
        { 'base': 'A', 'letters': /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g },
        { 'base': 'AA', 'letters': /[\uA732]/g },
        { 'base': 'AE', 'letters': /[\u00C6\u01FC\u01E2]/g },
        { 'base': 'AO', 'letters': /[\uA734]/g },
        { 'base': 'AU', 'letters': /[\uA736]/g },
        { 'base': 'AV', 'letters': /[\uA738\uA73A]/g },
        { 'base': 'AY', 'letters': /[\uA73C]/g },
        { 'base': 'B', 'letters': /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g },
        { 'base': 'C', 'letters': /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g },
        { 'base': 'D', 'letters': /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g },
        { 'base': 'DZ', 'letters': /[\u01F1\u01C4]/g },
        { 'base': 'Dz', 'letters': /[\u01F2\u01C5]/g },
        { 'base': 'E', 'letters': /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g },
        { 'base': 'F', 'letters': /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
        { 'base': 'G', 'letters': /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g },
        { 'base': 'H', 'letters': /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g },
        { 'base': 'I', 'letters': /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g },
        { 'base': 'J', 'letters': /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
        { 'base': 'K', 'letters': /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g },
        { 'base': 'L', 'letters': /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g },
        { 'base': 'LJ', 'letters': /[\u01C7]/g },
        { 'base': 'Lj', 'letters': /[\u01C8]/g },
        { 'base': 'M', 'letters': /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g },
        { 'base': 'N', 'letters': /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g },
        { 'base': 'NJ', 'letters': /[\u01CA]/g },
        { 'base': 'Nj', 'letters': /[\u01CB]/g },
        { 'base': 'O', 'letters': /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g },
        { 'base': 'OI', 'letters': /[\u01A2]/g },
        { 'base': 'OO', 'letters': /[\uA74E]/g },
        { 'base': 'OU', 'letters': /[\u0222]/g },
        { 'base': 'P', 'letters': /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g },
        { 'base': 'Q', 'letters': /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
        { 'base': 'R', 'letters': /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g },
        { 'base': 'S', 'letters': /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g },
        { 'base': 'T', 'letters': /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g },
        { 'base': 'TZ', 'letters': /[\uA728]/g },
        { 'base': 'U', 'letters': /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g },
        { 'base': 'V', 'letters': /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g },
        { 'base': 'VY', 'letters': /[\uA760]/g },
        { 'base': 'W', 'letters': /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g },
        { 'base': 'X', 'letters': /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
        { 'base': 'Y', 'letters': /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g },
        { 'base': 'Z', 'letters': /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g },
        { 'base': 'a', 'letters': /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g },
        { 'base': 'aa', 'letters': /[\uA733]/g },
        { 'base': 'ae', 'letters': /[\u00E6\u01FD\u01E3]/g },
        { 'base': 'ao', 'letters': /[\uA735]/g },
        { 'base': 'au', 'letters': /[\uA737]/g },
        { 'base': 'av', 'letters': /[\uA739\uA73B]/g },
        { 'base': 'ay', 'letters': /[\uA73D]/g },
        { 'base': 'b', 'letters': /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g },
        { 'base': 'c', 'letters': /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g },
        { 'base': 'd', 'letters': /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g },
        { 'base': 'dz', 'letters': /[\u01F3\u01C6]/g },
        { 'base': 'e', 'letters': /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g },
        { 'base': 'f', 'letters': /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
        { 'base': 'g', 'letters': /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g },
        { 'base': 'h', 'letters': /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g },
        { 'base': 'hv', 'letters': /[\u0195]/g },
        { 'base': 'i', 'letters': /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g },
        { 'base': 'j', 'letters': /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
        { 'base': 'k', 'letters': /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g },
        { 'base': 'l', 'letters': /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g },
        { 'base': 'lj', 'letters': /[\u01C9]/g },
        { 'base': 'm', 'letters': /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g },
        { 'base': 'n', 'letters': /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g },
        { 'base': 'nj', 'letters': /[\u01CC]/g },
        { 'base': 'o', 'letters': /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g },
        { 'base': 'oi', 'letters': /[\u01A3]/g },
        { 'base': 'ou', 'letters': /[\u0223]/g },
        { 'base': 'oo', 'letters': /[\uA74F]/g },
        { 'base': 'p', 'letters': /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g },
        { 'base': 'q', 'letters': /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
        { 'base': 'r', 'letters': /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g },
        { 'base': 's', 'letters': /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g },
        { 'base': 't', 'letters': /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g },
        { 'base': 'tz', 'letters': /[\uA729]/g },
        { 'base': 'u', 'letters': /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g },
        { 'base': 'v', 'letters': /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g },
        { 'base': 'vy', 'letters': /[\uA761]/g },
        { 'base': 'w', 'letters': /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g },
        { 'base': 'x', 'letters': /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
        { 'base': 'y', 'letters': /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g },
        { 'base': 'z', 'letters': /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g }
    ];

    for (const diacritic of defaultDiacriticsRemovalMap)
        str = str.replace(diacritic.letters, diacritic.base);

    return str;
}

export function SameDayDates(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

// function that capitalize first letter of sentence and the rest of the sentence is lowercase
export function CapitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

type GetDuplicatedMessagesParams = { Support: string, AdvertiserGroup: string, BroadcastArea: string, Currency: string, Start?: Date };
export async function GetDuplicatedMessages({ Support, AdvertiserGroup, BroadcastArea, Currency, Start }: GetDuplicatedMessagesParams) {
    if (!Support || !AdvertiserGroup || !BroadcastArea || !Currency)
        return [];

    const duplicates = await DataProvider.search<ref_Messages>(ref_Messages.name, {
        Active: true,
        Source: "ADWONE",
        Support,
        AdvertiserGroup,
        BroadcastArea,
        Currency,
        properties: ["Start", "Advertiser", "Brand", "Product", "AdvertiserGroup", "BroadcastArea", "Currency", "Support", "Campaign", "Active",
            "AdvertiserGroup.Name as AdvertiserGroupName", "Advertiser.Name as AdvertiserName", "Brand.Name as BrandName", "Product.Name as ProductName", "Campaign.Name as CampaignName"],
        ...(Start ? { _takeAll: true, _strictParams: { Start: new Date(Start)?.toString() } } : {})
    });
    return duplicates;
}

export function removePasswordProperties(data: any) {
    const cloneElement = clone(data);
    recurseAll(cloneElement, (rec) => {
        if (rec && typeof rec === 'object') {
            const keys = Object.keys(rec);
            // get keys that contain password
            const passwordKeys = keys.filter(k => k.toLowerCase().includes("password"));
            // remove password keys
            passwordKeys.forEach(k => rec[k] = "********");
        }
    });
    return cloneElement;
}

export function isPasswordStrong(password: string) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/.test(password);
}