
import * as React from "react";
import { RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import { Trad } from "trad-lib";
import { IsMMUser } from "../../../utils/localstorage.bin";

type EstimateModeModeProps = {
    estimateMode?: boolean,
    onChange?: (value: boolean) => void
}

export function EstimateMode({ estimateMode, onChange }: EstimateModeModeProps) {

    const handleChange = (event: RadioButtonChangeEvent) => {
        onChange?.(event.value);
    }

    return <div>{
        IsMMUser() &&
        <>
            <RadioButton
                name="group1"
                value={false}
                checked={!estimateMode}
                label={Trad("hierarchy_mode")}
                onChange={handleChange} />
            <RadioButton
                name="group1"
                value={true}
                checked={estimateMode}
                label={Trad("estimate_mode")}
                onChange={handleChange} />
        </>}
    </div>;
}