import * as React from "react"
import { TextField } from "@material-ui/core";
import { extractSub, JSONEqualityComparer } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { setMessageSync } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";


export function AdditionalFormatEditor() {

    const dispatch = useDispatch();
    const { BroadcastArea, AdditionalFormat } = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ["BroadcastArea", "AdditionalFormat"]) ?? {}, JSONEqualityComparer);

    return <TextField
        autoComplete='off'
        style={{ width: '100%' }}
        label={Trad("format_complement")}
        value={AdditionalFormat ?? ""}
        variant="outlined"
        disabled={!BroadcastArea}
        onChange={(e) => {
            const message = store.getState().messageEditor.get();
            message.AdditionalFormat = e.target.value;
            dispatch(setMessageSync(message));
        }} />
}