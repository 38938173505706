
import * as React from "react";
import { Trad } from "trad-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { Element } from "../Generic/Common.bin";
import { useState } from "react";
import { AdvancedFilters } from "./AdvancedFilters.bin";
import { getIcon } from "adwone-lib/index";
import { TooltipManager } from "../../CustomTooltip";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { AdvertiserHierarchyFilter, Filter } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import { ClearEmptyValues, clearEmptyValues, clone, compareObjects, duplicate, GetHashCode } from "hub-lib/tools.bin";
import { ModeleCreatorBase } from "../ModeleCreator/ModeleCreatorBase";
import { ref_FilterConfigurations } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { GetUser } from "../../../utils/localstorage.bin";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setFilters } from "../../../redux/projectSlice";
import { AdvertiserHierarchyComponent, AdvertiserHierarchyComponentProps } from "../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent";
import { StoreFiltersMessages } from "../AdvertiserHierarchyComponent/StoreFilters";
import { DataMapSwitch } from "../../Tools";
import { FiltersChangeTrigger, FiltersChangeTriggerProps } from "./FiltersChangeTrigger";
import { DateRangePickerFilter } from "./DateRangePickerFilter";
import { CampaignFilter } from "../AdvertiserHierarchyComponent/CampaignFilter";
import { HierarchyState } from "../HierarchyComponent/HierarchyState.bin";
import { RowSize } from "../../../styles/theme";
import { CustomBadge } from "./MenuItemFilters";
import { Client } from "hub-lib/client/client.bin";
import { KPIsManager, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { KeyValidator, MultiKeysValidator } from "../../../utils/KeyValidator";
import { SupportHierarchyKeys } from "../SupportHierarchyComponent/SupportHierarchyComponent";
import { SanitizeFilter } from "hub-lib/models/external.bin";

export type ObjectType = {
    objectType: string,
}

export type FiltersOptions = {
    onChange?: FiltersChangeTriggerProps['onChange'],
    hide?: {
        AdvancedFilters?: true,
        Hierarchy?: true,
        Date?: true,
        MapFilters?: true,
        Modele?: true
    },
    actionContainerProps?: {
        onChange: (conf: Partial<Filter>) => void,
        conf: Partial<Filter>;
    }
    dateConfigurations?: {
        min?: Date,
        maxYearsRange?: number
    }
}

export type FiltersComponentProps = ObjectType & FiltersOptions;

export function FiltersComponent({ hide, onChange, objectType, actionContainerProps, dateConfigurations }: FiltersComponentProps) {
    const dispatch = useDispatch();
    const modeleFilters = duplicate(useSelector((root: RootState) => root.project.filtersModele, compareObjects));
    const config = !actionContainerProps ? duplicate(useSelector((root: RootState) => root.project.filters, compareObjects)) : actionContainerProps.conf;
    const [sanitizeConfig, setSanitizeConfig] = React.useState<Partial<Filter>>();
    const configSerializable = clone(config);
    delete configSerializable.Start;
    delete configSerializable.End;
    React.useEffect(() => {
        SanitizeFilter(objectType, configSerializable).then(f => setSanitizeConfig(f));
    }, [GetHashCode(configSerializable)]);

    config.Start = new Date(config.Start);
    config.End = new Date(config.End);

    const [suffixKey, setSuffixKey] = useState(0);
    const keyConf = `conf_key_${modeleFilters?.["@rid"]}_${suffixKey.toString()}`;

    const saveFilters = (_modeleFilters: ref_FilterConfigurations, event?: {
        type: "contentChanged" | "selectionChanged";
    }) => {
        if (actionContainerProps && actionContainerProps.onChange)
            actionContainerProps.onChange(_modeleFilters.Filters);
        else
            dispatch(setFilters(_modeleFilters));
    }

    return <>
        {onChange && <FiltersChangeTrigger onChange={onChange} />}

        {/** CLEAR FILTER BUTTON */}
        <div className="view-icons" style={{ display: "flex" }}>
            <CustomIconButton
                onMouseOver={e => TooltipManager.Push({ target: e.target, text: Trad("init_filters") })}
                onClick={() => {
                    const newConfig = new Filter();
                    newConfig.Source = ["ADWONE"];
                    newConfig.Start = config.Start;
                    newConfig.End = config.End;
                    setSuffixKey(suffixKey + 1);
                    saveFilters({ ...modeleFilters, Filters: newConfig });
                }}>{getIcon("filterAltOff")}</CustomIconButton>

            {!hide?.Date && <div className="secondary-container-component">
                <DateRangePickerFilter min={dateConfigurations?.min} maxYearsRange={dateConfigurations?.maxYearsRange} />
            </div>}
        </div>

        {/** *** SOURCE *** */}
        {!hide?.MapFilters && GetUser().customer?.DisplayMAPData && <DataMapSwitch
            onChange={sources => {
                const newConf = { ...modeleFilters, Filters: config };
                newConf.Filters.Source = sources;
                saveFilters(newConf)
            }} />
        }

        {/** MODELE FILTERS SELECTOR */}
        {!hide?.Modele
            && <MultiKeysValidator propertyNames={KPIsManager.AdvertiserProperties} objectType={objectType} options={{ atLeastOne: true }}>
                <Element>
                    <ModeleCreatorBase
                        key={`Filters-ModeleCreatorBase-${modeleFilters?.["@rid"]}`}
                        objectType={ref_FilterConfigurations}
                        onChange={conf => {
                            const confCloned = clone(conf);
                            // TODO temporaire
                            confCloned.Filters = ClearEmptyValues({
                                ...config,
                                ...new AdvertiserHierarchyFilter,
                                ...AdvertiserHierarchyFilter.getFilters(confCloned.Filters)
                            })
                            saveFilters(confCloned);
                        }}
                        modele={modeleFilters}
                        type={ref_Messages.name} />
                </Element>
            </MultiKeysValidator>}

        {!hide?.Hierarchy &&
            <div className="clearfix" style={{ position: 'relative' }}>
                {/* <div style={{ float: 'left', width: '19px', height: '160px' }} className='lines-AdvertiserHierarchyComponent'>
                    <div className="separator-hierarchy-container">
                        <div className="separator-hierarchy adw-row" />
                        <div className="separator-hierarchy adw-row" />
                        <div className="separator-hierarchy adw-row" />
                        <div className="separator-hierarchy adw-row" />
                    </div>
                </div> */}

                {/** left border separator */}
                <div style={{ height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.12)', position: 'absolute', top: -26 }} />

                <AdvertiserHierarchyComponent
                    objectName={objectType}
                    separators={true}
                    reduxStore={StoreFiltersMessages}
                    store={config}
                    onChange={store => saveFilters({
                        ...modeleFilters,
                        Filters: clearEmptyValues({ ...config, ...store })
                    })}>
                    {/** GROUPES */}
                    {/*(!hide?.Hierarchy && !IsMMUser()) &&
                        <VertexAutocomplete
                            multiple
                            params={{ [propertyOf<ref_Groups>("Category")]: eGroupCategories.Magasin, Active: true }}
                            type={ref_Groups.name}
                            label={TradClassName(ref_Groups.name)}
                            defaultValue={(options: ref_Groups[]) =>
                                config?.Group?.map(g => options.find(o => o["@rid"] == g)).filter(e => e) ?? []}
                            onChange={async (values: ref_Groups[]) => {
                                config.Group = values?.map(v => v["@rid"]);
                                saveFilters({ ...modeleFilters, Filters: config });
                                setSuffixKey(suffixKey + 1);
                            }} />*/}
                </AdvertiserHierarchyComponent>
            </div>}

        {!hide?.AdvancedFilters
            && <>
                <KeyValidator propertyName='Campaign' objectType={objectType}>
                    <CampaignFilter
                        store={config}
                        onChange={(filters) => saveFilters({ ...modeleFilters, Filters: clearEmptyValues({ ...config, ...filters }) })} />
                    <div className="adw-row"></div>
                </KeyValidator>

                <KeyValidator propertyName='State' objectType={objectType}>
                    <HierarchyState
                        store={config}
                        onChanged={(filters) => saveFilters({ ...modeleFilters, Filters: clearEmptyValues({ ...config, ...filters }) })} />
                    <div className="separator-line adw-row"></div>
                </KeyValidator>

                <MultiKeysValidator propertyNames={[...SupportHierarchyKeys, "ModelProperties.AdCreation"]} objectType={objectType} options={{ atLeastOne: true }}>
                    <AdvancedFiltersComponent badge={hasAdvancedFilters(sanitizeConfig)}>
                        <AdvancedFilters
                            key={`AdvancedFilters-${keyConf}`}
                            objectType={objectType}
                            config={config}
                            applyConfig={(filters) => saveFilters({ ...modeleFilters, Filters: filters })} />
                    </AdvancedFiltersComponent>
                </MultiKeysValidator>
            </>}
    </>
}

export const hasAdvancedFilters = (filters: Partial<Filter>, basicFilters = false) => {
    return filters?.Media?.length > 0
        || filters?.Currency?.length > 0
        || filters?.Campaign?.length > 0 && basicFilters
        || filters?.Status?.length > 0 && basicFilters
        || filters?.BroadcastArea?.length > 0
        || filters?.AdvCompany_Com?.length > 0
        || filters?.AdvCompany_Fin?.length > 0
        || filters?.Support?.length > 0
        || filters?.Format?.length > 0
        || filters?.Placement?.length > 0
        || filters?.['ModelProperties.AdCreation']?.length > 0
}

export function AdvancedFiltersComponent(props) {

    const [expanded, setExpanded] = useState(false);

    return <CustomExpansionPanel
        badge={props.badge}
        title={Trad("advanced_filters")}
        expanded={expanded}
        onExpand={() => {
            console.log("expanded", expanded);
            setExpanded(!expanded);
        }} >
        <div>
            {expanded && props.children}
        </div>
    </CustomExpansionPanel>
}

type CustomExpansionPanel = {
    title: string,
    expanded: boolean,
    onExpand: (event) => void,
    children: React.ReactNode,
    badge?: boolean
}

export function CustomExpansionPanel({ title, expanded, onExpand, children, badge }: CustomExpansionPanel) {

    return <div>
        <div style={{ width: '100%' }}>
            <div className="customexpander-arrow-container" onClick={onExpand} style={{ height: RowSize }}>
                <div className="customexpander-title">

                    <span>
                        {title}
                        {badge && <CustomBadge />}
                    </span>

                </div>
                <div className="customexpander-arrow" style={{ height: RowSize }}>
                    {getIcon(expanded ? "arrow_drop_down" : "arrow_drop_up")}
                </div>
            </div>
            {expanded
                && <div className="main-margin-container" >
                    {children}
                </div>}
        </div>

    </div>
}


export function ModeleSelector() {

    const dispatch = useDispatch();
    const modeleFilters = duplicate(useSelector((root: RootState) => root.project.filtersModele, compareObjects));
    const config = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
    config.Start = new Date(config.Start);
    config.End = new Date(config.End);
    return <ModeleCreatorBase
        key={`ModeleSelector-${modeleFilters.Name}-${modeleFilters["@rid"]}`}
        objectType={ref_FilterConfigurations}
        onChange={conf => {
            const confCloned = clone(conf);
            confCloned.Filters = ClearEmptyValues({
                ...config,
                ...new AdvertiserHierarchyFilter,
                ...AdvertiserHierarchyFilter.getFilters(confCloned.Filters)
            })
            dispatch(setFilters(confCloned));
        }}
        modele={modeleFilters}
        type={ref_Messages.name} />
}