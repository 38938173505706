import * as React from "react";
import ListItemLink from './ListItemLink'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import SubMenu, { SubMenuProps } from './SubMenu.bin'
import { Trad } from 'trad-lib';
import { getIcon } from "adwone-lib/index"
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { GetUser, IsDebugMode } from "../../../utils/localstorage.bin";
import { eRoles } from "hub-lib/business/rights/rights.bin";
//import SellsyLogo from "../SellsyLogo";

type ListItemsState = {
    subMenus: SubMenuProps["menu"][]
}

function ListItems() {

    const isMounted = React.useRef(false);
    const [state, setState] = React.useState<ListItemsState>({
        subMenus: [],
    });

    React.useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            let subMenus = [];
            const isSellingMode = GetUser()?.customer?.Activity == 'Selling';

            if (RightManager.hasRight(eFunctions.ref_Messages, eRights.read) || RightManager.hasRight(eFunctions.ref_Campaigns, eRights.read) || RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)) {
                let children = []
                if (RightManager.hasRight(eFunctions.ref_Campaigns, eRights.read))
                    children.push({ name: Trad('campaigns'), path: '/campaigns', icon: getIcon("ref_Campaigns") })
                if (RightManager.hasRight(eFunctions.ref_Messages, eRights.read)) {
                    children.push({ name: Trad('messages'), path: '/messages', icon: getIcon("ref_Messages") })
                    if (RightManager.hasRight(eFunctions.ref_Messages, eRights.viewReporting))
                        children.push({ name: Trad('TCD'), path: '/reporting', icon: getIcon("ref_Messages") })
                }
                if (RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)/* && !RightManager.hasRight(eFunctions.Sellsy, eRights.read)*/)
                    children.push({ name: Trad('estimates'), path: '/estimates', icon: getIcon("estimates") })
                // if (RightManager.hasRight(eFunctions.Sellsy, eRights.read)) //todo
                //     children.push({ name: Trad('Sellsy'), path: '/sellsy', icon: <SellsyLogo /> })

                subMenus = [...subMenus, {
                    name: isSellingMode ? Trad('sales') : Trad('purchases'),
                    icon: getIcon("purchases"),
                    children
                }]
                /*
                                children = [];
                                children.push({ name: Trad('couplages'), path: '/supplier_campaigns', icon: getIcon("ref_Campaigns") });
                                children.push({ name: Trad('spots'), path: '/supplier_spots', icon: getIcon("ref_Campaigns") });
                                subMenus = [...subMenus, {
                                    name: Trad('supplierExchanges'),
                                    icon: getIcon("purchases"),
                                    children
                                }]*/
            }
            if (RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read) || RightManager.hasRight(eFunctions.ref_Agreements, [eRights.create, eRights.read, eRights.update, eRights.delete])) {
                let children = []
                if (RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read))
                    children.push({ name: Trad('currency_and_change_rate'), path: '/currency_and_exchange_rates', icon: getIcon("currencies") })

                if (RightManager.hasRight(eFunctions.ref_GlobalAgreements, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('global_agreements'), path: '/configurations', icon: getIcon("agreements") })
                if (RightManager.hasRight(eFunctions.ref_Agreements, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('agreements'), path: '/agreements', icon: getIcon("agreements") })

                subMenus = [...subMenus, {
                    name: Trad('purchases_settings'),
                    icon: getIcon("purchases_settings"),
                    children
                }]

            }

            let admin_menus = []
            if (RightManager.hasRight(eFunctions.User, eRights.read, [eRoles.administrateur, eRoles.superAdministrateur]))
                admin_menus.push({ name: Trad('users'), path: '/users', icon: getIcon("users") })
            if (RightManager.hasRight(eFunctions.User, eRights.read) && RightManager.hasRight(eFunctions.UserRights, eRights.read))
                admin_menus.push({ name: Trad('wallet_management'), path: '/userrights', icon: getIcon("supersettings") })
            if (RightManager.hasRight(eFunctions.ref_Groups, eRights.create))
                admin_menus.push({ name: Trad('groups'), path: '/groups', icon: getIcon("category") })
            if (RightManager.hasRight(eFunctions.User, eRights.read, [eRoles.administrateur]))
                admin_menus.push({ name: Trad('referential'), path: '/clntreferential', icon: getIcon("source") })
            if (admin_menus.length > 0)
                subMenus = [...subMenus, {
                    name: Trad('administration'),
                    icon: getIcon("settings"),
                    children : admin_menus
                }]

            if ((RightManager.hasRight(eFunctions.ref_Supports, [eRights.create, eRights.read, eRights.update, eRights.delete])
                || RightManager.hasRight(eFunctions.ref_AdvertisingCompanies, [eRights.create, eRights.read, eRights.update, eRights.delete])
                || RightManager.hasRight(eFunctions.ref_BroadcastAreas, [eRights.create, eRights.read, eRights.update, eRights.delete])
                || RightManager.hasRight(eFunctions.ref_Media, [eRights.create, eRights.read, eRights.update, eRights.delete])
                || RightManager.hasRight(eFunctions.ref_Property, [eRights.create, eRights.read, eRights.update, eRights.delete])
                || RightManager.hasRight(eFunctions.ref_Advertisers, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                || RightManager.hasRight(eFunctions.Trad, [eRights.create, eRights.read, eRights.update, eRights.delete])
                || RightManager.hasRight(eFunctions.ReferentialHasViews, [eRights.create, eRights.read, eRights.update, eRights.delete])) {
                let children = []
                if (RightManager.hasRight(eFunctions.ref_Supports, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    || RightManager.hasRight(eFunctions.ref_AdvertisingCompanies, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    || RightManager.hasRight(eFunctions.ref_BroadcastAreas, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    || RightManager.hasRight(eFunctions.ref_Media, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    || RightManager.hasRight(eFunctions.ref_Property, [eRights.create, eRights.read, eRights.update, eRights.delete])
                    || RightManager.hasRight(eFunctions.ref_Advertisers, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('referential'), path: '/referential', icon: getIcon("source") })
                if (RightManager.hasRight(eFunctions.Trad, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('translation_tools'), path: '/translation', icon: getIcon("translate") })
                if (RightManager.hasRight(eFunctions.ReferentialHasViews, [eRights.create, eRights.read, eRights.update, eRights.delete])) {
                    children.push({ name: Trad('data_link'), path: '/link', icon: getIcon("link") })
                    children.push({ name: Trad('linksimilarities'), path: '/linksimilarities', icon: getIcon("link") })
                }
                if (RightManager.hasRight(eFunctions.ExcelTemplate, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('files'), path: '/files', icon: getIcon("folder") })

                subMenus = [...subMenus, {
                    name: Trad('data_management'),
                    icon: getIcon("storage"),
                    children
                }]

            }
            if (RightManager.hasRight(eFunctions.User, eRights.read) && RightManager.hasRight(eFunctions.Logs, eRights.read) && RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]) && RightManager.hasRight(eFunctions.UserRights, [eRights.create, eRights.read, eRights.update, eRights.delete])) {
                let children = []
                children.push({ name: Trad('Versions'), path: '/versions', icon: getIcon("info") })
                children.push({ name: Trad('Calendar'), path: '/calendar', icon: getIcon("schedulerView") })
                if (RightManager.hasRight(eFunctions.Logs, eRights.read)) {
                    children.push({ name: Trad('Logs'), path: '/logs', icon: getIcon("logs") })
                    children.push({ name: Trad('History'), path: '/history', icon: getIcon("history") })
                    children.push({ name: Trad('MissingProperties'), path: '/missingproperties', icon: getIcon("history") })
                    children.push({ name: Trad('user_sessions'), path: '/usersessions', icon: getIcon("supersettings") })
                    children.push({ name: Trad('Delier un message'), path: '/unlinkmessage', icon: getIcon("delete") })
                    children.push({ name: Trad('Grafana'), path: '/grafana', icon: getIcon("logs") })
                    children.push({
                        name: Trad("external_data"),
                        icon: getIcon("storage"),
                        children: [
                            { name: Trad('imports'), path: '/tsmimports', icon: getIcon("schedule") },
                            { name: Trad('tsm_errors'), path: '/tsmerrors', icon: getIcon("schedule") },
                            { name: Trad('Offers'), path: '/offers', icon: getIcon("schedule") },
                            { name: Trad('Publications'), path: '/publications', icon: getIcon("schedule") },
                            { name: Trad('Circulations'), path: '/circulations', icon: getIcon("schedule") }
                        ]
                    });
                    children.push({
                        name: Trad("Traitements pige"),
                        icon: getIcon("api"),
                        children: [
                            {
                                name: Trad("Nielsen"),
                                icon: getIcon("settings"),
                                children: [
                                    { name: Trad("Configuration traitement"), path: "/nielsenDataProcessingConfig", icon: getIcon("settings") },
                                    { name: Trad("Souscriptions"), path: "/nielsenClientSubscriptions", icon: getIcon("settings") }
                                ]
                            }
                        ]
                    });
                }
                if (RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('customer_settings'), path: '/customers', icon: getIcon("customer") })
                if (RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]) && RightManager.hasRight(eFunctions.UserRights, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('wallet_customer'), path: '/customerrights', icon: getIcon("supersettings") })
                if (RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]) && RightManager.hasRight(eFunctions.UserRights, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('externalclients'), path: '/externalclients', icon: getIcon("customer") })
                if (RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.read, eRights.update, eRights.delete]) && RightManager.hasRight(eFunctions.UserRights, [eRights.create, eRights.read, eRights.update, eRights.delete]))
                    children.push({ name: Trad('invalidestimates'), path: '/invalidestimates', icon: getIcon("customer") })

                subMenus = [...subMenus, {
                    name: Trad('super_admin'),
                    icon: getIcon("settings"),
                    children
                }]
            }
            if (IsDebugMode() && RightManager.hasRight(eFunctions.Logs, eRights.read)) {
                let children = [
                    { name: Trad('broadcastmessage'), path: '/broadcastmessage', icon: getIcon("podcasts") },
                    { name: Trad('crons'), path: '/crons', icon: getIcon("task") },
                    { name: Trad('Console'), path: '/console', icon: getIcon("terminal") }
                ];
                subMenus = [...subMenus, {
                    name: Trad('development'),
                    icon: getIcon("api"),
                    children
                }]
            }
            setState({ subMenus })
        }
    }, [isMounted.current])

    return (
        <List className="navigation_list" component="nav" style={{ overflow: "overlay" }}>
            <ListItemLink icon={getIcon("home")} primary={<Typography>{Trad("dashboard")}</Typography>} to="/" />
            {/* {RightManager.hasRight(eFunctions.ref_Messages, eRights.read) && <ListItemLink icon={getIcon("charts")} primary={<Typography>{Trad('results')}</Typography>} to="/reports" />} */}
            {state.subMenus.map(menu => <SubMenu key={menu.name} menu={menu} />)}
            {(RightManager.hasRight(eFunctions.ref_Imports, [eRights.create, eRights.read]) && GetUser().profileName == eRoles.superAdministrateur) && <ListItemLink icon={getIcon("up")} primary={<Typography>{Trad('imports')}</Typography>} to="/imports" />}
        </List>
    );
}

export default ListItems;
