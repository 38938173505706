import { GetFirstDayOfYear, GetLastDayOfYear } from "tools-lib";
import { extractSub } from "../../tools.bin";
import { ref_Groups } from "./ref_Groups.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
import { IAdvertiserHierarchy, IDateRangeModel } from "../../types";

export class AdvertiserHierarchyFilter {
	/* Hierarchie Annonceur */
	Group: ref_Groups["@rid"][] = undefined;
	AdvertiserGroup: rid[] = undefined;
	Advertiser: rid[] = undefined;
	Brand: rid[] = undefined;
	Product: rid[] = undefined;

	static getFilters(data: any, defaultValue = undefined) {
		return extractSub(data ?? {}, Object.keys(new AdvertiserHierarchyFilter()) as (keyof AdvertiserHierarchyFilter)[], defaultValue);
	}
}

export class Filter extends AdvertiserHierarchyFilter {

	Campaign: rid[] = undefined;

	Source: string[] = ["ADWONE"];

	/* Hierarchie Support */
	Media: rid[] = [];
	Support: rid[] = [];
	AdvCompany_Com: rid[] = [];
	AdvCompany_Fin: rid[] = [];
	Format: rid[] = [];
	Placement: rid[] = [];
	BroadcastArea: rid[] = [];
	Currency: rid[] = [];

	Status: string[] = [];
	"ModelProperties.AdCreation": rid[] = [];

	Start: Date = GetFirstDayOfYear();
	End: Date = GetLastDayOfYear();

	static getFilters(configAny: any) {
		let copy: any = new Filter();
		let obj: any = {};
		Object.keys(copy).forEach((key: string) => {
			let value = configAny[key];
			if (value?.length > 0)
				obj[key] = configAny[key];
		});
		return obj;
	}
}

export class AdvertiserHierarchy implements IAdvertiserHierarchy, IDateRangeModel {
	AdvertiserGroup?: rid | rid[] = undefined;
	Advertiser?: rid | rid[] = undefined;
	Brand?: rid | rid[] = undefined;
	Product?: rid | rid[] = undefined;
	Campaign?: rid | rid[] = undefined;
	Start?: Date = null;
	End?: Date = null;
}

export class ref_FilterConfigurations extends ref_ConfigurationsBase {
	Filters: Partial<AdvertiserHierarchyFilter> = new AdvertiserHierarchyFilter;
}