import { Checkbox } from '@progress/kendo-react-inputs';
import * as React from 'react'
import { Trad } from 'trad-lib';
import { FilterStorage } from '../../../utils/localstorage.bin';
import { VertexGrid } from '../Generic/VertexGrid.bin';

type UsersGridFiltersArgs = {
    grid: VertexGrid<any>
}

export function UsersGridFilters({ grid }: UsersGridFiltersArgs) {

    let filter = FilterStorage.getLocalStorageValue("user-grid");
    if (!filter) { filter = {}; FilterStorage.setLocalStorageValue("user-grid", {}); }
    const [value, setValue] = React.useState(filter);

    React.useEffect(() => {
        if (grid.props.vertexParams.Active != filter.Active) {
            if (!filter.Active) delete grid.props.vertexParams.Active;
            else grid.props.vertexParams.Active = true;
            grid.UpdateRows();
        }
    })

    return <>
        <Checkbox
            label={Trad("hide_inactive_users")}
            value={value.Active}
            onChange={(event) => {
                const newValue: any = {};
                if (event.value)
                    newValue.Active = true;
                setValue(newValue);
                FilterStorage.setLocalStorageValue("user-grid", newValue);
            }} />
    </>
} 