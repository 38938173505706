import React, { useEffect, useRef } from "react";
import { PageTemplateProps, PDFExportProps, savePDF } from "@progress/kendo-react-pdf";
import EventEmitter from "events";
import { defineFont } from '@progress/kendo-drawing/pdf';
import Logo from '../../../utils/adwone.png';
import { FormatDate } from "tools-lib";
import { Trad } from "trad-lib";

export let PDFExportEvent: EventEmitter = new EventEmitter();

export const CustomPDFExport = (props: PDFExportProps) => {
    const pdfExportComponent = useRef<HTMLDivElement>(null);

    const exportHandler = () => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date();
        let date = [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
        let hour = [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join('-');
        let element = pdfExportComponent.current || document.body;
        savePDF(element, {
            ...props,
            paperSize: [`${pdfExportComponent.current.offsetWidth + 40}pt`, `${pdfExportComponent.current.offsetHeight + 120}pt`],
            margin: {
                top: 79, // adjust with the height for other browsers (not chrome)
                left: 20,
                right: 20,
                bottom: 39 // adjust with the height for other browsers (not chrome)
            },
            pageTemplate: (props: PageTemplateProps) => {
                const currentYear = new Date().getFullYear();
                const copyright = `©Copyright AdwOne ${currentYear} `;
                return (
                    <>
                        <div style={{ position: "absolute", top: 20, left: 20, display: "flex", alignItems: "center" }}>
                            <img alt="AdwOne" className='toolbarcontent' src={Logo} style={{marginRight: 20}} />
                            <span>{`${Trad("exported_on")}`}</span>
                            <span style={{marginLeft: 5, marginRight: 5}}>{":"}</span>
                            <div style={{fontSize: "1.25rem", fontWeight: "500"}}>{FormatDate(d, true)}</div>
                        </div>
                        <div style={{ position: "absolute", bottom: 10, right: 20 }}>
                            {copyright}
                        </div>
                    </>
                );
            },
            fileName: `${props.fileName}_${date}_${hour}`,
        });
    };

    useEffect(() => {
        defineFont({
            'Rubik': '/Rubik-Regular.ttf',
            'Rubik|bold': '/Rubik-Bold.ttf'
        });
        PDFExportEvent.addListener("export", exportHandler);
        return () => {
            PDFExportEvent.removeListener("export", exportHandler);
        };
    }, []);

    return (
        <div ref={pdfExportComponent}>
            {props.children}
        </div>
    );
};
