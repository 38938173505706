import { InputAdornment, TextField } from '@material-ui/core';
import { Client } from 'hub-lib/client/client.bin';
import { User } from 'hub-lib/models/orientdb/User.bin';
import * as React from 'react'
import { Trad } from 'trad-lib';
import { Notify } from '../../../utils/Notify.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { ErrorMessage } from '../../ConfigurableComponents/ErrorMessage.bin';
import history from '../../../utils/history'
import { CustomIconButton } from '../../VertexGrid/Generic/CustomIconButton';
import { getIcon } from 'adwone-lib';
import { isPasswordStrong } from 'hub-lib/tools.bin';
import { PasswordComplexity } from '../../auth/PasswordComplexity';


class TState {
    loading: boolean = true
    old_password: string = ''
    new_password: string = ''
    new_password_confirmation = ''
    error_msg: string = ''
    showError: boolean = false
    showPassword: boolean = false
    showConfirmPassword: boolean = false
}

class TProps {
    user?: User;
    token?: string;
}

export class PassForm extends React.Component<TProps, TState>{
    constructor(props: TProps) {
        super(props)
        let newState = new TState()
        this.state = newState
    }

    updateUserPassword = async () => {

        const jwt = this.props.token;
        const user = this.props.user;

        const { new_password_confirmation } = this.state;

        try {
            if (user)
                await Client.updateVertex(User.name, { "@rid": user["@rid"], password: new_password_confirmation }, false)
            else if (jwt)
                await Client.setNewPassword(jwt, new_password_confirmation);

            Notify(Trad("pass_changed_success"), "success");

            setTimeout(async () => {
                await Client.logout();
                if (window.location.pathname == "/") window.location.reload();
                else history.push('/');
            }, 700);
        } catch (error) {
            Notify(Trad("cant_change_pass"), "error")
        }
    }
    isPassValid = () => {
        if (this.state.new_password !== this.state.new_password_confirmation) {
            this.setState({
                showError: true,
                error_msg: Trad("different_passwords")
            })
        }
        // if (!isPasswordStrong(this.state.new_password)) {
        //     this.setState({
        //         showError: true,
        //         error_msg: Trad("password_weak")
        //     })
        // }
    }
    render() {
        let { showError, error_msg, new_password_confirmation } = this.state
        return (
            <form noValidate>
                <div style={{ width: '100%', marginBottom: 20 }}>
                    <TextField id="user_newpass"
                        autoComplete='off'
                        type={this.state.showPassword ? "text" : "password"}
                        style={{ width: '100%' }}
                        label={Trad("new_pass")}
                        variant="outlined"
                        InputProps={{
                            style: { fontSize: 14 },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CustomIconButton onClick={() => this.setState({ showPassword: !this.state.showPassword })} >
                                        {getIcon(this.state.showPassword ? "visibility" : "visibility_off")}
                                    </CustomIconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            this.setState({
                                showError: false,
                                new_password: e.target.value
                            }, () => { this.isPassValid() })
                        }}
                    />
                </div>
                <div style={{ width: '100%', marginBottom: 20 }}>
                    <TextField id="user_newpassconfirm"
                        autoComplete='off'
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        style={{ width: '100%' }}
                        label={Trad("new_pass_confirm")}
                        variant="outlined"
                        InputProps={{
                            style: { fontSize: 14 },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CustomIconButton onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })} >
                                        {getIcon(this.state.showConfirmPassword ? "visibility" : "visibility_off")}
                                    </CustomIconButton>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => {
                            this.setState({
                                showError: false,
                                new_password_confirmation: e.target.value
                            }, () => { this.isPassValid() })
                        }}
                    />
                    {showError &&
                        <ErrorMessage message={error_msg} margin={'20px 0'} padding={'10px'} />
                    }
                </div>
                <div style={{ width: '100%', marginBottom: 20 }}>
                    <CustomButton
                        Label={Trad("btn_change_password")}
                        className="custom_btn_primary"
                        style={{ float: 'right', marginBottom: 20 }}
                        disabled={showError || !isPasswordStrong(this.state.new_password)}
                        onClick={() => { this.updateUserPassword() }}
                    />
                </div>
                <PasswordComplexity password={this.state.new_password} />
            </form>
        )
    }
}