
export function recurse<T>(elements: T[], key: keyof T, fn: (elment: T, deepth: number, index: number, indexChild: number, elements: number) => any, deepth: number = 0, index = { n: 0 }) {
    if (!elements) return;

    let count = 0;
    for (const element of elements) {
        fn(element, deepth, index.n, count++, elements.length);
        index.n++;
        recurse<T>(element[key] as any, key, fn, deepth + 1, index);
    }
}

export async function recurseAsync<T>(elements: T[], key: keyof T, fn: (elment: T, deepth: number, index: number, indexChild: number, elements: number) => Promise<any>, deepth: number = 0, index = { n: 0 }) {
    if (!elements) return;

    let count = 0;
    for (const element of elements) {
        await fn(element, deepth, index.n, count++, elements.length);
        index.n++;
        await recurseAsync<T>(element[key] as any, key, fn, deepth + 1, index);
    }
}

export function recurseAll<T>(elements: T | T[], fn: (elment: any, deepth: number, index: number) => any, deepth: number = 0, index = { n: 0 }, visited = new Set<any>()) {
    if (!elements) return;

    const array = Array.isArray(elements) ? elements : [elements];
    for (const element of array) {

        if (visited.has(element))
            continue;

        if (Array.isArray(element)) {
            visited.add(element);
            recurseAll<T>(element, fn, deepth + 1, index, visited);
            continue;
        }

        fn(element, deepth, index.n);
        index.n++;

        if (element && typeof element == "object") {
            visited.add(element);
            Object.entries(element).forEach(([key, val]) => {
                if (!visited.has(val))
                    recurseAll<T>(val, fn, deepth + 1, index, visited);
            });
        }
    }
}

export function mapRecurse<T, Y>(arg: T[], key: keyof T, fn: (element: T, deepth: number, parent: Y) => Y, deepth: number = 0, parent?: Y): Y[] {
    if (!arg) return null;
    let target: Y[] = arg.map((a) => fn(a, deepth, parent));
    target.forEach(a => (a as any)[key] = mapRecurse((a as any)[key], key, fn, deepth + 1, a));
    return target;
}