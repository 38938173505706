import React from "react";


import { VertexGrid } from "../../Generic/VertexGrid.bin";
import { ModeleColumnsCreator } from "../../ModeleCreator/ModeleColumnsCreator.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { Element } from "../../Generic/Common.bin";
import { useDispatch, useSelector } from "react-redux";
import { setDashboard } from "../../../../redux/projectSlice";
import { clone, compareObjects } from "hub-lib/tools.bin";
import { RootState, store } from "../../../../redux/store";
import { ModeleDashboard } from "../../ModeleCreator/ModeleDashboard.bin";
import { ref_DashboardConfigurations } from "hub-lib/dto/client/ref_DashboardConfigurations.bin";
import { useEffect, useState } from "react";
import { IndicateurKPI } from "adwone-engine/index.bin";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory"
import { Trad } from "trad-lib";
import { setTable } from "../../../../redux/gridConfigurationsSlice";
import { Client } from "hub-lib/client/client.bin";

const indicateursName = [
    "Brut",
    "Brut Base Achat",
    "Brut format défaut",
    "Brut Diffusé",
    "Brut Valorisé",
    "Net",
    "netCoFTHono",
    "netCoFT",
    "Net FO",
    "netFoFTHono",
    "netFoFT",
    "netFoHono",
    "netFoTTC",
    "Net FOS",
    "Total",
    "totalCOHonoFPFC",
    "Total FO",
    "totalFOHonoFPFC",
    "Total FOS",
];

export function TableComponent<T>({ grid, objectType }: FormConfigurationArgs<T>) {

    const [indicateurs, setIndicateurs] = useState<IndicateurKPI[]>(null);
    const dispatch = useDispatch();
    // const configuration = clone(store.getState().project.columnsModele);
    const configuration = clone(useSelector((root: RootState) => root.columsConfigurations.configurations?.[objectType.name]?.table, compareObjects));
    const dashBoardconfiguration = useSelector((root: RootState) => root.project.dashboardModele);

    const getIndicateurs = async () => {
        const tradIndicateursName = indicateursName.map(e => Trad(e));
        const indicateurs = (await AggregatorFactory.GetInstance().Get(objectType).Provide()).map(i => i.indicateur).filter(i => i.valueType === "Price" && tradIndicateursName.includes(i.name)) as IndicateurKPI[];
        setIndicateurs(indicateurs);
    }

    useEffect(() => {
        if (!indicateurs && configuration?.ViewMode === "Dashboard") {
            getIndicateurs();
        }
    })

    const saveTable = (_modeleTable: ref_TableConfigurations, event?: {
        type: "contentChanged" | "selectionChanged";
    }) => {
        if (event?.type == "selectionChanged") dispatch(setTable(_modeleTable));
        else dispatch(setTable(_modeleTable));
    }
    const saveDashboard = (_modeleDashboard: ref_DashboardConfigurations) => {
        dispatch(setDashboard(_modeleDashboard));
    }
    if (configuration?.ViewMode === "Dashboard")
        return <ModeleDashboard
            indicateurs={indicateurs}
            hideModele
            objectType={ref_DashboardConfigurations}
            type={objectType.name}
            onChange={saveDashboard}
            modele={dashBoardconfiguration} />
    return <Element className="ModeleColumnsCreator">
        <ModeleColumnsCreator
            disableLocalStorage
            saveConfiguration={_conf => Client.updateVertex(ref_TableConfigurations.name, _conf)}
            objectType={ref_TableConfigurations}
            onChange={saveTable}
            modele={grid.props.configuration}
            type={objectType.name} />
    </Element>
}

type FormConfigurationArgs<T> = {
    objectType: new () => T;
    onColumnsChange?: (modele: Partial<ref_TableConfigurations>) => any,
    grid: VertexGrid<T>
}