
import * as React from 'react'
import { TChartContainerProps, ChartContainer } from './ChartContainer.bin';
import { VertexAutocomplete } from "adwone-lib/index";
import { Trad } from 'trad-lib';
import { DateRangepicker } from '../ConfigurableComponents/DateRangepicker';
import { GetToday, DateNoZone } from 'tools-lib';
import { GenericPopover } from '../ConfigurableComponents/GenericPopover.bin';
import moment from 'moment';
import { GetLocalStorageFiltersChartObj, SetLocalStorageChartFilters } from '../../utils/localstorage.bin';
import { GetHashCode } from 'hub-lib/tools.bin';
import { useDispatch, useSelector } from 'react-redux';
import { setChartConfig } from '../../redux/projectSlice';
import { RootState } from '../../redux/store';

export class TProps extends TChartContainerProps {
    vertexAutocomplete: {
        label: string;
        vertexType?: string;
        options?: any[];
        vertexProperty: string;
    }[];
    // activateTrad?: boolean;
    chartKey?: string;
    multiple?: boolean;
    disableCustomLegend?: boolean;
    customChart?: {
        legend?: TChartContainerProps["legendProps"],
        seriesItem?: TChartContainerProps["chartSeriesItemProps"],
        max?: TChartContainerProps["max"],
        fixed?: TChartContainerProps["fixed"],
        flatten?: TChartContainerProps["flatten"],
        title?: TChartContainerProps["titleProps"],
        axis?: TChartContainerProps["axis"],
    }
}

class TState {
    filter: { [prop: string]: string[] };
    start: Date;
    end: Date;
    Names: any = {};
}

export function ControllableChart({ start: startProp, end: endProp, chartKey, filters, vertexAutocomplete, type, customKPI, dimension, KPI, Indicateur, multiple, disableCustomLegend, customChart, onSeriesClick }: TProps) {

    const [state, setState] = React.useState<TState>();
    const project = useSelector(
        (root: RootState) => root.project
    );

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!state) {
            const localStorageConfig = GetLocalStorageFiltersChartObj(chartKey);
            const newState: TState = localStorageConfig ?? new TState();
            if (localStorageConfig) {
                newState.start = DateNoZone(newState.start)
                newState.end = DateNoZone(newState.end)
                if (!newState.Names) // legacy
                    newState.Names = {};
            } else {
                newState.filter = { ...filters }
                newState.start = startProp ?? filters?.Start ?? new Date(GetToday().getFullYear(), 0, 1)
                newState.end = endProp ?? filters?.End ?? new Date(GetToday().getFullYear(), 11, 31)
            }
            setState(newState);
        }
    })

    if (!state)
        return <></>

    const { filter, start, end, Names } = state;
    const anchorKey = `anchorParent${Date.now()}`

    const onConfChange = (state: any) => {
        setState(state);
        SetLocalStorageChartFilters(chartKey, state)
    }

    return <div id={anchorKey} style={{ height: "100%" }}>
        {!disableCustomLegend && <>
            <div className='legend-graph'>
                <div>{Trad("filters") + ": " + Array.from(new Set(Object.values(Names))).join(', ')}</div>
                <div>{Trad("from") + ": " + moment(start).format("DD/MM/YYYY")}</div>
                <div>{Trad("to") + ": " + moment(end).format("DD/MM/YYYY")}</div>
            </div>

            <div className="NonDraggableArea draggable_buttons">
                <GenericPopover anchorParent={anchorKey}>
                    <div style={{ width: "300px" }}>
                        {vertexAutocomplete.map((v, i) => (
                            <div key={`VertexAutocomplete-${i}`}
                                style={{ marginBottom: 10 }}>
                                <VertexAutocomplete
                                    label={v.label}
                                    type={v.vertexType}
                                    options={v.options}
                                    multiple={multiple}
                                    needTrad={v.options?.length > 0}
                                    clearText={Trad("clear")}
                                    defaultValue={(all: any[]) => {
                                        let selection;
                                        const alreadySelected = filter[v.vertexProperty];

                                        if (typeof all?.[0] === 'string') {
                                            if (all?.[0] !== "all")
                                                all.unshift("all");
                                            selection = alreadySelected ?? [];
                                        } else {
                                            if (all?.[0]?.["@rid"] && !multiple)
                                                all.unshift({ Name: Trad("all") });
                                            if (alreadySelected?.length) {
                                                selection = all.filter((e) => alreadySelected.some((selected) => selected == e["@rid"]))
                                            }
                                            return multiple ? selection : selection?.[0]
                                        }
                                    }}
                                    onChange={(value: any) => {
                                        const newFilter = { ...filter };
                                        delete newFilter[v.vertexProperty];
                                        if (typeof value === 'string') {
                                            if (value != "all")
                                                newFilter[v.vertexProperty] = [value]
                                            state.Names[i] = Trad(value);
                                        } else {
                                            if (value.length) {
                                                newFilter[v.vertexProperty] = value.map(v => v["@rid"])
                                                value.forEach((v, index) => {

                                                    state.Names[index] = v.Name;
                                                });
                                            } else {
                                                if (value?.["@rid"]) {
                                                    newFilter[v.vertexProperty] = [value["@rid"]]
                                                    state.Names[i] = value?.Name;
                                                } else {
                                                    state.Names = {};
                                                }
                                            }
                                        }
                                        const newState = { ...state, filter: newFilter };
                                        onConfChange(newState);
                                    }}
                                />
                            </div>))}

                        <DateRangepicker
                            takeAllSpace={true}
                            defaultStart={start}
                            defaultStop={end}
                            handleChangeDate={(e: { start: Date, end: Date }) => {
                                const newState = { ...state, start: e.start, end: e.end };
                                onConfChange(newState)
                            }} />
                    </div>
                </GenericPopover>
            </div>
        </>}

        <ChartContainer
            key={`ChartContainer-${GetHashCode({ type, state, dimension, KPI, Indicateur })}`}
            customKPI={customKPI}
            // activateTrad={this.props.activateTrad}
            legendProps={customChart?.legend}
            chartSeriesItemProps={customChart?.seriesItem}
            max={customChart?.max}
            fixed={customChart?.fixed}
            flatten={customChart?.flatten}
            titleProps={customChart?.title}
            type={type}
            dimension={dimension}
            KPI={KPI}
            Indicateur={Indicateur}
            onSeriesClick={(fields) => {
                dispatch(setChartConfig(fields));
            }}
            chartFilters={project.dashboardModele?.filters}
            budget={project?.dashboardBudget}
            axis={customChart?.axis}
            filters={filter}
            start={start ?? (filter?.["Start"] as any)}
            end={end ?? (filter?.["End"] as any)}
        />
    </div>
}