import { Grid } from "@material-ui/core";
import { Indicateur } from "adwone-engine/index.bin";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory"
import { eColumnType } from "hub-lib/models/types.bin";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_PivotGridConfigurations } from "hub-lib/dto/client/ref_PivotGridConfigurations.bin";
import { PivotGridModeleCreator } from "./PivotGridModeleCreator";
import { Client } from "hub-lib/client/client.bin";
import { clone, compareObjects } from "hub-lib/tools.bin";

type PivotGridConfigurationProps = {
    configToShow: ref_PivotGridConfigurations;
    onChange: (conf: ref_PivotGridConfigurations) => void;
}

export function PivotGridConfiguration(props: PivotGridConfigurationProps) {
    // const config = useSelector((root: RootState) => root.columsConfigurations.configurations?.[ref_Messages.name]?.pivotGrid);
    const config = clone(useSelector((root: RootState) => root.tcd.pivotGridConfigToShow, compareObjects));
    const [dimensions, setDimensions] = React.useState<Indicateur[]>([]);

    React.useEffect(() => {
        AggregatorFactory.GetInstance().Get(ref_Messages).Provide()
            .then(indicateurs => {
                setDimensions(indicateurs.filter(i => i.columnType == eColumnType.Property).map(i => i.indicateur));
            });

    }, [Boolean(dimensions)]);

    const onConfigurationChange = (conf: ref_PivotGridConfigurations, event: { type: "contentChanged" | "selectionChanged"; }) => {
        props.onChange(conf);
    }

    return <Grid container style={{ height: "95%" }} direction="row">
        <Grid item xs={12}>
            <PivotGridModeleCreator
                objectType={ref_PivotGridConfigurations}
                type={ref_Messages.name}
                modele={config}
                onChange={onConfigurationChange}
                saveConfiguration={_conf => Client.updateVertex(ref_PivotGridConfigurations.name, _conf)}
                dimensions={dimensions}
                config={props.configToShow}
                disableLocalStorage={true}
            />
        </Grid>
    </Grid>
}