
export * from "./AdwAutocomplete";
export * from "./VertexAutocomplete";
export * from "./DividerWithText";
export * from "./IconManager";

import * as AdwAutocompleteImp from "./AdwAutocomplete";
import * as VertexAutocompleteImp from "./VertexAutocomplete";
import * as DividerWithTextImp from "./DividerWithText";
import * as IconManagerImp from "./IconManager";

export class Components {
    static AdwAutocomplete = AdwAutocompleteImp;
    static VertexAutocomplete = VertexAutocompleteImp;
    static DividerWithText = DividerWithTextImp;
    static IconManager = IconManagerImp;
}
