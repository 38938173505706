import { TradClassName } from "trad-lib";
import { VertexGrid } from "../../Generic/VertexGrid.bin";
import { Client } from "hub-lib/client/client.bin";
import { Filter } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import { clone, compareObjects } from "hub-lib/tools.bin";
import { ModeleCreatorBase } from "../../ModeleCreator/ModeleCreatorBase";
import { ExportArg, ref_ExportConfigurations } from "hub-lib/dto/client/ref_ExportConfigurations.bin";
import { ExportsComponent } from "../../../ExportsComponent/ExportsComponent";
import { AggregateExport } from "hub-lib/models/external.bin";
import { CrossedTableConfigurable } from "../../../ConfigurableComponents/CrossedTableConfigurable.bin";
import { useSelector } from "react-redux";
import { getExportSelector, RootState, store } from "../../../../redux/store";
import { setExport } from "../../../../redux/gridConfigurationsSlice";

type ExportConfigurationArgs<T> = {
    filters: Partial<Filter>,
    open: boolean;
    close: () => void;
    generateConfigCrossedTable: () => Promise<any>;
    grid: VertexGrid<T>;
}

export function ExportConfiguration<T>({ filters, open, close, generateConfigCrossedTable, grid }: ExportConfigurationArgs<T>) {
    const config = clone(useSelector((root: RootState) => root.columsConfigurations.configurations?.[grid.objectPrototype.name]?.export, compareObjects));
    return (
        <>
            <ModeleCreatorBase
                disableLocalStorage
                selector={getExportSelector(grid.objectPrototype.name)}
                objectType={ref_ExportConfigurations}
                onChange={_config => store.dispatch(setExport(_config))}
                modele={config}
                saveConfiguration={_template => Client.updateVertex(ref_ExportConfigurations.name, _template)}
                type={grid.objectPrototype.name}
            />

            <ExportsComponent
                key={config["@rid"]}
                documentType={grid.objectPrototype.name}
                config={config}
                filters={filters}
                table={grid.props.configuration}
                onValidate={async (args: ExportArg) => {
                    const { ViewModeExport } = args;
                    const storeGrid = store.getState().grid;
                    const currentViewMode = grid.props.configuration.ViewMode;
                    const gridFilter = currentViewMode == 'Table' ? storeGrid.gridFilter : undefined
                    const sort = currentViewMode == 'Table' ? storeGrid.sort : undefined;

                    if (ViewModeExport === "Table") {
                        args['headerFilters'] = gridFilter;
                        args['sort'] = sort;
                        grid.exportExcel("formated", args);
                    }
                    else {
                        const confCT = await generateConfigCrossedTable();
                        const arg: AggregateExport = { ...CrossedTableConfigurable.getAggregatedArgsForExports(confCT), ...args };
                        arg.ViewModeExport = ViewModeExport === "SchedulerLight" ? "Scheduler" : ViewModeExport;
                        arg.headerFilters = gridFilter;
                        arg.sort = sort;

                        if (ViewModeExport === "SchedulerLight")
                            arg.columns = [];
                        Client.downloadExport("formated", arg, TradClassName(grid.objectPrototype.name));
                    }
                    close();
                }} />
        </>
    )
}