import * as React from "react";
import { Trad } from "trad-lib";
import { RowSize } from "../../../styles/theme";
import { DateRangePickerFilter } from "../Filters/DateRangePickerFilter";
import { ModeleSelector } from "../Filters/FiltersComponent";
type ToolbarAdwArgs = { children: any, hasSelectedItems?: boolean, count?: number, includeFilters?: boolean, className?: string, includeDates?: boolean, min?: Date, maxYearsRange?: number };
export function ToolbarAdw({ children, hasSelectedItems, count, includeFilters, className, includeDates, min, maxYearsRange }: ToolbarAdwArgs) {
    return <div className={'clearfix custom-toolbar-adwtelerikgrid-fixed flex reverse between ' + (hasSelectedItems ? "toolbar-adwtelerikgrid-selecteditems" : "") + ` ${className ?? ''} `}>
        <div className="count-elements margin-left">
            {(includeFilters || includeDates) &&
                <div className='secondary-container-component'>
                    <div style={{ width: 305, height: RowSize }} className='DateRangePickerFilter-container'>
                        <DateRangePickerFilter min={min} maxYearsRange={maxYearsRange} />
                    </div>
                    {includeFilters &&
                        <div style={{ width: 305, height: RowSize, marginLeft: 10 }} className='ModeleSelector-container'>
                            <ModeleSelector />
                        </div>}
                </div>}
            {/* {!includeFilters && count != undefined && <span className="margin-left">{count} {count <= 1 ? Trad("element") : Trad("elements")}</span>} */}
        </div>
        {children}
    </div>
}

export function SelectedItemsContainer({ children }) {
    return <div className="custom-toolbar-adwtelerikgrid-content">
        <div className={'top-left-items selected-items-container'}>
            {children}
        </div>
    </div>
}

export function ToolbarActions({ children }) {
    return <div className={'top-right-items-actions'}>
        {children}
    </div>
}

export function ConfigurationPanelContainer({ children }) {
    return <div className={'top-right-items'}>
        {children}
    </div>
}

export function BreadcrumbsCustomContainer({ children }) {
    return <div style={{ display: "inline-grid", height: "100%", width: "100%" }}>
        {children}
    </div>
}

export function ToolbarContainer({ children }) {
    return <div style={{ width: "100%" }}>
        {children}
    </div>
}