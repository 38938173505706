import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_ProductsId = rid;

export class ref_Products extends Referentials {
	"@rid"?: ref_ProductsId;
	End: Date;
	Name: string;
	Start: Date;

}