
import * as React from "react";
import { RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import { Trad } from "trad-lib";
import { GetUser, IsMMUser } from "../../../utils/localstorage.bin";
import { src_MM } from "hub-lib/models/orientdb/src_MM.bin";


type MultiAdvertisersModeProps = {
    multiAdvertisers?: boolean,
    onChange?: (value: boolean) => void
}

export function MultiAdvertisersMode({ multiAdvertisers, onChange }: MultiAdvertisersModeProps) {

    const handleChange = (event: RadioButtonChangeEvent) => {
        onChange?.(event.value);
    }

    const user = GetUser();

    return <div>{
        !IsMMUser() &&
        <>
            <RadioButton
                name="group1"
                value={true}
                checked={multiAdvertisers}
                label={Trad("multi_advertisers_mode")}
                onChange={handleChange} />
            <RadioButton
                name="group1"
                value={false}
                checked={!multiAdvertisers}
                label={Trad("basic_hierarchy_mode")}
                onChange={handleChange} />
        </>}
    </div>;
}