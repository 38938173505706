import React from "react";
import Template from "../../Template"
import { Client } from "hub-lib/client/client.bin";
import { ref_NielsenDataProcessingConfig } from "hub-lib/dto/admin/pigedataprocessing/ref_NielsenDataProcessingConfig";
import { FormControl, Grid, TextField } from "@material-ui/core";
import { BreadcrumbsCustomContainer, ToolbarAdw } from "../../../VertexGrid/Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { Trad } from "trad-lib";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";

const NielsenDataProcessingConfig = () => {
    const [config, setConfig] = React.useState<ref_NielsenDataProcessingConfig>(null);

    React.useEffect(() => {
        Client.searchVertex(ref_NielsenDataProcessingConfig.name).then((res) => {
            setConfig(res.data.results[0]);
        });
    }, []);

    const onChange = (e: any) => {
        setConfig({ ...config, [e.target.id]: e.target.value });
    }

    const onButtonClick = () => {
        if (config["@rid"]) {
            Client.updateVertex(ref_NielsenDataProcessingConfig.name, config).then((res) => {
                console.log(res);
            });
        } else {
            Client.createVertex(ref_NielsenDataProcessingConfig.name, config).then((res) => {
                console.log(res);
            });
        }
    }

    return (
        <div className="grid_container">
            {config &&
                <div style={{ width: '100%' }}>
                    <ToolbarAdw>
                        <BreadcrumbsCustomContainer>
                            <BreadcrumbsCustom elements={[
                                { text: Trad("home"), href: "/" },
                                { text: Trad("Configuration traitement Nielsen") }
                            ]} />
                        </BreadcrumbsCustomContainer>
                    </ToolbarAdw>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField style={{ width: '30%' }}
                                variant="outlined"
                                label={Trad("Hote du serveur FTP")}
                                id="ftpHost"
                                value={config.ftpHost}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField style={{ width: '30%' }}
                                variant="outlined"
                                label={Trad("Nom d'utilisateur du serveur FTP")}
                                id="ftpUser"
                                value={config.ftpUser}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField style={{ width: '30%' }}
                                variant="outlined"
                                label={Trad("Mot de passe du serveur FTP")}
                                id="ftpPassword"
                                value={config.ftpPassword}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField style={{ width: '30%' }}
                                variant="outlined"
                                label={Trad("Extensions des fichiers à télécharger")}
                                id="ftpExtensions"
                                value={config.ftpExtensions}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField style={{ width: '30%' }}
                                variant="outlined"
                                label={Trad("Dossier de téléchargement")}
                                id="downloadFolder"
                                value={config.downloadFolder}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomButton
                                className="custom_btn_primary"
                                Label={Trad("save")}
                                onClick={onButtonClick}
                            />
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}

export default Template(NielsenDataProcessingConfig);