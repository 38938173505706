import * as React from 'react'
import { Trad } from 'trad-lib'
import { Client } from 'hub-lib/client/client.bin'
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin'
import { Notify } from '../../../utils/Notify.bin'

class TProps {
    open: boolean
    toggleDialog: () => void
    closeReport: () => void
    rid: string;
}

export default class ReportEstimates extends React.Component<TProps, any>{
    constructor(props: TProps) {
        super(props)
    }

    render() {
        return (
            <GenericDialog
                dialogTitle={Trad("report_error")}
                open={this.props.open}
                actions
                id={"dialog_report_estimates"}
                cancelAction={() => this.props.toggleDialog()}
                submitClass={"custom_btn_modification_filled"}
                handleClose={() => this.props.toggleDialog()}
                submitAction={() => {
                    Client.signalInvalidEstimates(this.props.rid).then(r => {
                        Notify(Trad("estimate_reported_success"), "success")
                        this.props.closeReport()
                    }).catch(e => { console.log(e) })
                }}
                submitTitle={Trad("report")}
                dialogContent={<>
                    <p>{Trad("report_estimate_confirmation")}</p>
                </>}
            />
        )
    }
}