import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { clearEmptyValues, compareObjects, extractSub, GetHashCode } from "hub-lib/tools.bin";
import * as React from "react";
import { useRef } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { AnyAction, Store } from "redux";
import { TradProp } from "trad-lib";
import { KeyValidator } from "../../../utils/KeyValidator";
import { Row } from "../../Tools";
import { MultiVertexAutoComplete } from "../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent";
import { AdvertiserStoreMultiRid } from "../AdvertiserHierarchyComponent/AdvertiserStoreSlice";
import { RootStateFilters } from "../AdvertiserHierarchyComponent/StoreFilters";
import { setStore, SupportStoreMultiRid } from "./SupportStoreSlice";

export type StoreProps = {

    objectType: string;

    store: SupportStoreMultiRid,

    /**
     * Return changed store whith null when no value
     */
    onChange?: (store: SupportStoreMultiRid) => void
}

export type SupportHierarchyComponentProps = StoreProps & {
    hideCampaign?: boolean,
    reduxStore: Store<any, AnyAction>
}

export const SupportHierarchyKeys: (keyof SupportStoreMultiRid)[] = [
    "Media",
    "Currency",
    "BroadcastArea",
    "AdvCompany_Com",
    "AdvCompany_Fin",
    "Support",
    "Format",
    "Placement"];

export function SupportHierarchyComponent({ store, onChange, reduxStore, objectType }: SupportHierarchyComponentProps) {
    return <Provider store={reduxStore}>
        <Initialize objectType={objectType} store={store} onChange={onChange} />
        {SupportHierarchyKeys.map(keyStore =>
            <HierarchyItem
                key={`SupportHierarchyComponent-${keyStore}`}
                objectType={objectType}
                multiSelection
                keyStore={keyStore} />)}
    </Provider>
}

const SubObj = (obj: SupportStoreMultiRid) => {
    return extractSub(clearEmptyValues(obj), ["Media", "BroadcastArea", "Currency", "Support", "AdvCompany_Com", "AdvCompany_Fin", "Format", "Placement"], null);
}

function Initialize({ store, onChange }: StoreProps) {

    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const storeChanged = useSelector((root: RootStateFilters) => root.supportStorage?.store);
    const isLoaded = useSelector((root: RootStateFilters) => root.supportStorage?.isLoaded);

    React.useEffect(() => {
        if (!isLoaded || !compareObjects(SubObj(store), SubObj(storeChanged))) {
            dispatch(setStore({ store: SubObj(store), isLoaded: true }))
        }
    }, [GetHashCode(store)])

    React.useEffect(() => {
        if (isMounted.current && isLoaded && !compareObjects(SubObj(store), SubObj(storeChanged))) {
            onChange?.(SubObj(storeChanged));
        }
        isMounted.current = true;
    }, [GetHashCode(storeChanged)])

    return <></>
}

type HierarchyItemProps = { multiSelection: boolean, keyStore: keyof SupportStoreMultiRid, objectType: string }
export function HierarchyItem({ multiSelection, keyStore, objectType }: HierarchyItemProps) {
    return <KeyValidator objectType={objectType} propertyName={keyStore}>
        <Row>
            {multiSelection && <MultiVertexAutoComplete
                storeKey={keyStore}
                rootKey="supportStorage"
                label={TradProp(keyStore, ref_Messages)}
                setStoreFunc={setStore}
            />}
        </Row>
    </KeyValidator>
}