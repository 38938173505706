import * as React from 'react'
import Template from '../Template';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { GridBase } from '../../VertexGrid/Generic/GridBase.bin';
import { Client } from 'hub-lib/client/client.bin';
import { ExternalClients, ExternalCompany } from 'hub-lib/models/ExternalClients';
import { ADWColumn, VertexAutocomplete } from 'adwone-lib';
import { Trad, TradClassName, TradProp } from 'trad-lib';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';
import { ref_Customers } from 'hub-lib/models/orientdb/ref_Customers.bin';
import { GenericDialog, eDialogMode } from '../../ConfigurableComponents/GenericDialog.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ReferentialForm } from '../../VertexGrid/Adwone-admin/Referential/ReferentialForm.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { findBestMatch } from 'string-similarity';
import { ConfigurationPanelContainer, ToolbarAdw } from '../../VertexGrid/Generic/ToolbarAdw';
import { ConfigurationPanel } from '../../VertexGrid/Messages/ConfigurationPanel';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { setPercentage, setReload } from '../../../redux/percentageSimilaritySlice';
import { RootState, store } from '../../../redux/store';
import { TooltipManager } from '../../CustomTooltip';
import { UserRights } from 'hub-lib/models/orientdb/UserRights.bin';
import { CustomerRightsExtended } from './RightsOfCustomers.bin';
import { lnk_AdvertiserGroupRight, lnk_AdvertiserGroupRightId } from 'hub-lib/models/orientdb/lnk_AdvertiserGroupRight.bin';
import { lnk_AdvertiserRight } from 'hub-lib/models/orientdb/lnk_AdvertiserRight.bin';

type EditionState = {
    mode: eDialogMode
    editedAdvertiser: Partial<ref_Advertisers>
}

function ExternalClientsGridComponent() {
    const [data, setData] = React.useState<ExternalClients[]>(null);
    const [customer, setCustomer] = React.useState<ExternalClients>(null);
    const [annoncers, setAnnoncers] = React.useState<ref_Advertisers[]>(null);
    const reloadAnnoncers = useSelector((root: RootState) => root.similarityPercentage.reload)
    const dispatch = useDispatch();

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (data && !reloadAnnoncers) return;
            const res = await Client.searchVertexTyped(ExternalClients, {});
            const customers = await Client.searchVertexTyped(ref_Customers, { '@rid': res.map(r => r.Customer), properties: ['Company.Name as CompanyName', 'Rights'] });
            res.forEach(r => {
                const customer = customers.find(c => c['@rid'] === r.Customer);
                r['CompanyName'] = customer?.['CompanyName'];
                r.Rights = customer?.Rights;
            });
            setData(res);
            setCustomer(res[0]);
            setAnnoncers(await Client.searchVertexTyped(ref_Advertisers, { properties: ["@rid", "Name"] }));
        });

        if (reloadAnnoncers) {
            setAnnoncers(null);
            dispatch(setReload(false));
        }
    }, [reloadAnnoncers])

    const confComponent = <ConfigurationPanel
        elements={[{
            type: "icon",
            title: () => Trad("similarité"),
            icon: "filterAlt",
            element: <SetSimilarity />
        }, {
            type: "icon",
            title: () => Trad("export"),
            icon: "download",
            element: <CustomButton Label={Trad("export")} onClick={async () => {

                const companies = await Client.searchVertexTyped(ref_Advertisers, { Siret: customer.Companies.map(c => c.Siret) });
                // create csv file
                const csv = [
                    ['id;Name;Siret;Correspondance'],
                    ...customer.Companies.map(c => `${c.Import.id};${c.Name};${c.Siret};${companies.find(co => co.Siret === c.Siret)?.Name ?? ""}`)
                ].join('\n');

                const BOM = '\uFEFF';
                const csvContent = BOM + csv;

                // download file
                const a = document.createElement('a');
                const file = new Blob([csvContent], { type: 'text/csv' });
                a.href = URL.createObjectURL(file);
                a.download = `${customer.Customer}_sellsy.csv`;
                a.click();

            }} />
        }]} />

    const refreshCustomerRights = async () => {
        console.log("refreshCustomerRights");
        const customerAdvertisers = await Client.searchVertexTyped(ref_Advertisers, {
            Siret: customer.Companies.map(c => c.Siret),
            properties: ["@rid", "Name", "first(out('lnk_Hierarchy')) as parent"]
        })
        const customerRights = (await Client.searchVertex(UserRights.name, { "@rid": customer.Rights }))?.data.results[0] as CustomerRightsExtended;
        customerRights.AllAdvertiserGroups = false;
        customerRights.AllNoParentAdvertisers = false;
        const advGroupRights = [];
        const advRights = [];
        customerAdvertisers.forEach(ca => {
            if (ca["parent"]) {
                let lnk_advGroupRight = customerRights.lnk_AdvertiserGroupRight?.find(g => g['@rid'] == ca["parent"]) as any;
                if (!lnk_advGroupRight)
                    lnk_advGroupRight = { "@rid": ca["parent"] };

                lnk_advGroupRight["AllAdvertisers"] = false;
                if (!advGroupRights.some(gr => gr["@rid"] == lnk_advGroupRight["@rid"]))
                    advGroupRights.push(lnk_advGroupRight);
            }
            let lnk_advRight = customerRights.lnk_AdvertiserRight?.find(a => a['@rid'] == ca["@rid"]) as any;
            if (!lnk_advRight)
                lnk_advRight = { "@rid": ca["@rid"] };
            if (!advRights.some(ar => ar["@rid"] == lnk_advRight["@rid"]))
                advRights.push(lnk_advRight);
        })
        customerRights.lnk_AdvertiserGroupRight = advGroupRights as any;
        customerRights.lnk_AdvertiserRight = advRights as any;
        //console.log("customerRights", customerRights);
        try {
            await Client.updateVertex(UserRights.name, { ...customerRights, customer: customer['@rid'] })
            dispatch(setReload(false));
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div style={{ overflow: 'hidden' }} className="grid_container">
            <div style={{ width: '100%' }}>
                <ToolbarAdw className='externalclients-toolbar'>
                    <ConfigurationPanelContainer>
                        {confComponent}
                    </ConfigurationPanelContainer>
                </ToolbarAdw>

                {
                    data && customer &&
                    <div style={{ marginTop: 10, display: "flex" }}>
                        <div style={{ width: 500 }}>
                            <VertexAutocomplete
                                label='Client'
                                getOptionLabel={(option: ExternalClients) => option?.['CompanyName'] ?? ""}
                                options={data}
                                defaultValue={(options: ExternalClients[]) => options?.find(o => o['@rid'] === customer?.['@rid'])}
                                onChange={(value: ExternalClients) => {
                                    setCustomer(value);
                                }}
                            />
                        </div>
                        {customer.Rights && <CustomButton Label="Refresh Rights" onClick={refreshCustomerRights} />}
                    </div>
                }

                {customer && data && annoncers && <ExternalGrid key={`ExternalGrid-${customer['@rid']}`} data={data} customer={customer} annoncers={annoncers} />}
            </div>
        </div>

    )
}
export const ExternalClientsGrid = Template(ExternalClientsGridComponent);

function ExternalGrid({ data, customer, annoncers }) {
    const [grid, setGrid] = React.useState(null);
    const [edition, setEdition] = React.useState<EditionState>(null);
    const [companies, setCompanies] = React.useState<ref_Advertisers[]>(null);
    const dispatch = useDispatch();


    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (companies) return;
            setCompanies(await Client.searchVertexTyped(ref_Advertisers, { Siret: customer.Companies.map(c => c.Siret) }));

        });
    })
    React.useEffect(() => {
        if (!companies || !data || grid) return;
        Promise.resolve().then(async () => {
            const similarityCol = new ADWColumn<ExternalCompany>(Trad("referential_similarity"), "Similarity", ePropType.String);
            const nameCol = new ADWColumn<ExternalCompany>(`${TradProp("Name", ExternalCompany)} (Sellsy)`, "Name", ePropType.String);
            const siretCol = new ADWColumn<ExternalCompany>(TradProp("Siret", ExternalCompany), "Siret", ePropType.String);
            const companyCol = new ADWColumn<ExternalCompany>(Trad("correspondance_referential"), "Siret", ePropType.String);

            similarityCol.cellValue = (item: ExternalCompany, row) => {
                const data = {
                    annoncers,
                    dataItem: row.dataItem,
                    setEdition
                }
                return <SimilarityColumnValue data={data} />
            }

            companyCol.cellValue = (item: ExternalCompany, row) => {
                const company = companies.find(c => c.Siret === row.dataItem.Siret);
                if (!company)
                    return <div style={{ float: 'right' }}>
                        <CustomButton style={{ width: 100 }} Label={Trad('create')} onClick={() => {
                            setEdition({
                                mode: eDialogMode.create,
                                editedAdvertiser: {
                                    Name: row?.dataItem?.Name, Siret: row?.dataItem?.Siret
                                }
                            });
                        }} />
                    </div>

                return <>{company ? company.Name : ""}
                    <div style={{ float: 'right' }}>
                        <CustomButton
                            style={{ width: 100 }}
                            Label={Trad('modify')}
                            onClick={() => {
                                setEdition({
                                    mode: eDialogMode.modify,
                                    editedAdvertiser: company
                                });
                            }} />
                    </div>
                </>;
            };

            nameCol.width = 300;
            siretCol.width = 150;
            companyCol.width = 300;
            similarityCol.width = 300;

            //const gpAdvts: (ref_AdvertiserGroups & { children: ref_Advertisers[] })[] = await Client.searchVertexTyped(ref_AdvertiserGroups, { deep: true }) as any;
            const rows = customer.Companies ?? [];
            const gridBase = new GridBase({
                objectPrototype: ExternalCompany,
                rows,
                columns: [nameCol, siretCol, companyCol, similarityCol],
            });
            setGrid(gridBase);
        });
    });

    return (
        <div>
            {
                grid && <AdwTelerikGrid
                    hideToolbar
                    isCopyDisable
                    grid={grid}
                    uneditable={true} />
            }
            {edition &&
                <GenericDialog
                    open={Boolean(edition)}
                    dialogTitle={`${Trad(edition.mode)} ${TradClassName(ref_Advertisers.name)}`}>
                    <div className='adw-form-dialog-container'>
                        <ReferentialForm
                            onSave={e => { setEdition(null); dispatch(setReload(true)); setGrid(null); }}
                            {...(edition.mode === eDialogMode.modify ? { selectedItem: edition.editedAdvertiser } : {})}
                            {...(edition.mode === eDialogMode.create ? { defaultValues: edition.editedAdvertiser } : {})}
                            mode={edition.mode}
                            onCancel={() => setEdition(null)}
                            parentClass={ref_AdvertiserGroups.name}
                            selectedVertex={ref_Advertisers.name} />
                    </div>
                </GenericDialog>
            }
        </div>
    )
}

function SetSimilarity() {
    const [rating, setRating] = React.useState(0.8)
    const dispatch = useDispatch();
    return (
        <div>
            <label className="k-checkbox-label">Similarités:</label>
            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: '8px' }}>
                <Slider buttons={false} step={1} defaultValue={rating} min={0.8} max={1} onChange={e => setRating(e.value)}>
                    <SliderLabel position={0.8}>80%</SliderLabel>
                    <SliderLabel position={1}>100%</SliderLabel>
                </Slider>
                <CustomButton className="custom_btn_primary validation-button search-button" onClick={() => dispatch(setPercentage(rating))} Label="Rechercher" />
            </div>
        </div>
    )
}

function SimilarityColumnValue({ data }: { data: { annoncers: ref_Advertisers[], dataItem: ExternalCompany, setEdition: React.Dispatch<React.SetStateAction<EditionState>> } }) {
    const selectedRating = useSelector((root: RootState) => root.similarityPercentage.percentage)
    const bestMatch = findBestMatch(data.dataItem.Name, data.annoncers.map((annoncer: any) => annoncer.Name));
    const bestRatings = bestMatch.ratings.filter((match: any) => {
        return match.rating >= selectedRating
    }).map((match: any) => match);

    const matchedAnnoncers = bestRatings.map((bestRating: any) => {
        const obj = data.annoncers.find((annoncer: any) => bestRating.target === annoncer.Name);
        return { ...bestRating, ...obj };
    });
    const annoncersNames = matchedAnnoncers.map((annoncer: any) => {
        return <div onMouseOver={(e: any) => TooltipManager.Push({ target: e.target, text: annoncer.Name })}>
            <CustomButton
                style={{ width: 250 }}
                Label={(`(${annoncer['@rid']}) ${(annoncer.rating * 100).toFixed(0)}%  ${annoncer.Name}`)}
                onClick={async () => {
                    let c = await Client.searchVertexTyped(ref_Advertisers, { "@rid": annoncer["@rid"] })
                    c[0].Siret = data?.dataItem?.Siret
                    data.setEdition({
                        mode: eDialogMode.modify,
                        editedAdvertiser: c[0]
                    });
                }}
            />
        </div>
    })

    return (
        <>
            {annoncersNames}
        </>
    )
}