import * as React from 'react';
import IconButton from '@material-ui/core/IconButton'
import { GenericTooltip, GenericTooltipProps } from '../../../ConfigurableComponents/GenericTooltip.bin';

type CommandIconButtonType = {
    icon: JSX.Element;
    disabled?: boolean;
    onClick?: () => void;
    tooltipContent?: GenericTooltipProps["tooltipContent"];
    color: string;
}
export function CommandIconButton({ disabled, onClick, tooltipContent, color, icon }: CommandIconButtonType) {
    return <div style={{ maxWidth: 50, float: "right" }}>
        <GenericTooltip tooltipContent={tooltipContent}>
            <IconButton
                style={{ color }}
                aria-label="edit"
                disabled={disabled}
                onClick={() => onClick?.()}>
                {icon}
            </IconButton>
        </GenericTooltip>
    </div>
}