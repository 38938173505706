
import * as React from "react";
import TextField from '@material-ui/core/TextField'
import { GetNumberFormatCustom } from "../../Messages/NumberFormatCustom.bin";
import { eKPIType, IModelInfo, IsNumber } from "hub-lib/models/KPIsManager.bin";
import { lnk_HasKPI } from "hub-lib/models/orientdb/lnk_HasKPI.bin";
import { lnk_HasPropertyType } from "hub-lib/models/orientdb/lnk_HasPropertyType.bin";
import { Format, GetNumericFormat } from "format-lib/index.bin";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { TextEditor } from "../../TextEditor.bin";
import { ContainerComponent } from "../ContainerComponent";
import { IMediaModel, kpis } from "hub-lib/types";
import { CustomNumericTextBox } from "../Common.bin";
import { NumericTextBoxProps } from "@progress/kendo-react-inputs";

export class TProps {
    modelInfo: IModelInfo;
    isDisable: () => boolean;
    getValue: () => any;
    onChange?: (value: any) => void;
    isTextEditor?: boolean;
}

export function MetricsTextfield(props: TProps) {
    const { isTextEditor, modelInfo } = props;
    if (isTextEditor)
        return <MetricsComment {...props} />

    if (IsNumber(modelInfo.ValueType))
        return <MetricsNumber {...props} />;

    return <MetricsTextfieldBase {...props} />;
}

export function MetricsTextfieldBase({ modelInfo, isDisable, getValue, onChange }: TProps) {

    const _isDisable = useSelector((root: RootState) => isDisable());
    const value = useSelector((root: RootState) => getValue());

    const _onChange: TProps['onChange'] = (m) =>
        onChange?.(m)

    return (<TextField
        key={modelInfo.Name}
        disabled={_isDisable}
        autoComplete='off'
        style={{ width: '100%' }}
        label={Format(modelInfo)}
        type={modelInfo.ValueType === "String" ? "String" : "Number"}
        value={value}
        variant="outlined"
        onChange={(e) => {
            let value: any;
            if (modelInfo.ValueType === "String")
                value = e.target.value;
            else {
                value = parseFloat(e.target.value);
                if (isNaN(value)) value = 0;
                if (value < 0) value = 0;
            }

            _onChange?.(value);
        }}
        {...(modelInfo.ValueType !== "String" && value !== undefined && value != null && {
            InputProps: { inputComponent: GetNumberFormatCustom(modelInfo.ValueType) },
            InputLabelProps: { shrink: true }
        })}
    />);
}

export function MetricsNumber({ modelInfo, isDisable, getValue, onChange }: TProps) {

    const props: Partial<NumericTextBoxProps> = {};
    if (modelInfo.ValueType === eKPIType.Percent) {
        props.step = 0.1;
        props.min = 0;
        props.max = 100;
    }

    return <CustomNumericTextBox
        key={modelInfo.Name}
        title={Format(modelInfo)}
        disabled={isDisable()}
        value={getValue()}
        onChange={e => {
            let value = e.value;
            if (isNaN(value)) value = 0;
            if (value < 0) value = 0;
            onChange?.(value)
        }}
        format={GetNumericFormat(modelInfo.ValueType)}
        {...props} />
}

let timeOut = null;
export function MetricsComment({ modelInfo, getValue, onChange }: TProps) {

    const [_value, setValue] = React.useState(getValue());

    const _onChange: TProps['onChange'] = (m) => {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
            onChange?.(m)
        }, 700);
    }

    return <ContainerComponent title={Format(modelInfo)} className="auto-height no-padding no-margin">
        <TextEditor
            key={modelInfo.Name}
            content={_value}
            onChange={value => {

                //MetricsTextfieldEvent.emit('changed');
                setValue(value);
                _onChange?.(value);
            }} />
    </ContainerComponent>
}