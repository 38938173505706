

import * as React from "react";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { MyCommandCell } from "./MyCommandCell.bin";
import { ref_Discount, ref_Intervals } from "hub-lib/models/types/vertex.bin";
import { DiscountBase } from "hub-lib/dto/referential/ref_DiscountClassesExtended";
import { ref_DiscountTypes, ref_DiscountTypesId } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { Grid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { VertexRidCell } from "../Generic/VertexRidCell.bin";
import { clone, propertyOf } from "hub-lib/tools.bin";
import { ref_DiscountClasses, ref_DiscountClassesId } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { IsDebugMode } from "../../../utils/localstorage.bin";
import { Notify } from "../../../utils/Notify.bin";
import { CellNumberComponent } from "./discounts/CellNumberComponent.bin";
import { CellPercentComponent } from "./discounts/CellPercentComponent.bin";
import { Trad } from "trad-lib";
import { CellIntervalsComponent } from "./discounts/CellIntervalsComponent.bin";
import { CellRemoveComponent } from "./discounts/CellRemoveComponent";
import { CellLockValueComponent } from "./discounts/CellLockValueComponent";
import { IntervalsCommandCell } from "../Agreements/IntervalsCommandCell";
import { lastCol, colWidth, dragCol, removeCol } from "./DiscountEditor/DiscountEditor.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { DiscountCanBeEdited } from "hub-lib/business/DiscountManager.bin";

type Row = { [props in keyof ref_Discount]?: any } & { [props: string]: any }

export type discountExtended = (ref_Discount & { Minimum?: number, Maximum?: number });

export class DCAProps {

    isForIntervals?: boolean;
    message?: ref_Messages;

    /** Activate lockValue checkBox  */
    lockValueEnable?: boolean;
    canLockValue?: (item: ref_Discount) => boolean;
    onLockValueChanged?: (row: ref_Discount) => void;

    /** Activate clear button  */
    isRemovable?: (row: ref_Discount) => boolean;
    onRemoveClick?: (row: ref_Discount) => void;

    hideIntervalCells?: boolean;
    hideCommandCells?: boolean;
    validator?: {
        [prop in keyof discountExtended]?: (row: discountExtended, rows?: discountExtended[], type?: "CO" | "FO" | "FOS") => { valid: boolean, message?: string }
    };
    intervalActivated?: boolean;
    draggable?: () => boolean;
    allClasses: ref_DiscountClasses[];
    allTypes: ref_DiscountTypes[];
    sameBase?: boolean;
    types?: ref_DiscountTypesId[];
    classes: ref_DiscountClassesId[];
    discountsProvider: () => ref_Discount[];
    base?: () => number;
    endEdit?: () => void;
    enterEdit?: () => void;
    onChange?: () => any;
    refreshAfterDelete?: () => any;
    type?: "CO" | "FO" | "FOS";
    currencyCode: string;
    isEditable?: (d: ref_Discount) => boolean;
    ranking?: () => boolean;
    additionnalCommands?: { icon: JSX.Element, function: (d: any) => void }[]
    emptyspace?: boolean;
    newRowAdded?: (row: Row) => void;
    hideIntervalNumber?: boolean;
    onRef?: (r: DiscountCategoryArray) => void;
    cellLock?: (defaultComponent: JSX.Element, props: GridCellProps) => JSX.Element;
}

export class DCAState {
    gridData: Row[];
    gridDataBase: Row[];
    activeItem: any;
}

let sortPosition = (a: Row, b: Row) => {
    if (a.LastPosition && !b.LastPosition) return 1;
    if (!a.LastPosition && b.LastPosition) return -1;
    return 0;
}

export let GetDragCell = (reorder: any, dragStart: any): any => {
    return class DragCell extends React.Component<any, any> {
        render() {
            const item = this.props.dataItem;
            const cursor: React.CSSProperties["cursor"] = item.LastPosition ? "not-allowed" : "move";
            return (
                <td style={{ textAlign: 'center', cursor }}
                    draggable="true"
                    onDragStart={(e) => {
                        if (item.LastPosition) return;
                        e.dataTransfer.setData("dragging", "");
                        dragStart(this.props.dataItem);
                    }}
                    onDragOver={(e) => {
                        if (item.LastPosition) return;
                        reorder(this.props.dataItem);
                        e.preventDefault();
                        e.dataTransfer.dropEffect = "copy";
                    }}>
                    <span className="k-icon k-i-reorder" style={{ cursor, textAlign: 'center' }} />
                </td>
            );
        }
    }
}

export class DiscountCategoryArray extends React.Component<DCAProps, DCAState> {

    editField = "inEdit";
    CommandCell: any;

    constructor(props: DCAProps) {
        super(props);
        const gridData = this.wrap(this.props.discountsProvider()?.filter(d => this.props.types ? this.props.types.includes(d.DiscountType) : true));
        this.state = {
            gridData,
            gridDataBase: [...gridData].sort(this.sort),
            activeItem: undefined
        }

        this.props?.onRef?.(this);

        const isDisabled = (d: ref_Discount) => {
            if (!this.isEditable(d.DiscountType))
                return true;
            if (props.isEditable)
                return !props.isEditable(d);
            return false;
        };

        this.CommandCell = props.isForIntervals ? IntervalsCommandCell({
            edit: this.enterEdit,
            remove: this.remove,
            add: this.add,
            discard: this.discard,
            update: this.update,
            cancel: this.cancel,
            isDisabled,
            editField: this.editField,
            additionnalCommands: this.props.additionnalCommands
        }) : MyCommandCell({
            remove: this.remove,
            isDisabled,
            additionnalCommands: this.props.additionnalCommands
        });
    }

    componentDidMount() {
        this.refreshGrid();
    }

    refreshGrid = () => {
        const gridData = this.wrap(this.props.discountsProvider()?.filter(d => this.props.types ? this.props.types.includes(d.DiscountType) : true));
        this.setState({
            gridData,
            gridDataBase: [...gridData].sort(this.sort),
            activeItem: undefined
        }, () => this.updateCascade())
    }

    currentType = () => {
        let { type } = this.props;
        if (type) return type;
        return undefined;
    }

    toDiscount = (row: Row): (ref_Discount & { Minimum?: number, Maximum?: number }) => {
        const { ranking } = this.props;
        const discount = new ref_Discount();

        const dclass = this.props.allClasses.find(c => c["@rid"] == row.Type);
        if (!dclass) console.warn(`No class found`);

        const dtype = dclass && this.props.allTypes.find(c => c["@rid"] == dclass.DiscountType);
        if (!dtype) console.warn(`No type found`);

        discount.LastPosition = dclass?.LastPosition;
        discount.DiscountClass = dclass?.["@rid"];
        discount.DiscountType = dtype?.["@rid"];
        discount.IsRate = row.IsRate;
        discount.Operator = dclass?.Operator as any;

        let t = this.currentType();
        if (!t) {
            discount.Rate = row.Rate;
            discount.Value = row.Value;
        }

        discount.CO = row.CO;
        discount.FO = row.FO;
        discount.FOS = row.FOS;
        discount.Agreement = row.Agreement;
        discount.Intervals = row.Intervals;

        if (this.props.intervalActivated) {
            (discount as any).Minimum = row.Minimum ?? 0;
            (discount as any).Maximum = row.Maximum ?? 0;
        }

        if (ranking?.()) discount.Rank = row.Rank;
        else discount.Rank = dclass?.Rank as any;

        return discount;
    }

    updateRow = (row: Row) => {
        if (!row) return undefined;

        if (isNaN(row.Rank)) row.Rank = 0;
        if (isNaN(row.Rate)) row.Rate = 0;
        if (isNaN(row.Value)) row.Value = 0;


        const { base } = this.props;
        if (!base) {
            if (row.IsRate) row.Value = 0;
            else row.Rate = 0;
        }

        const dclass = this.props.allClasses.find(c => c["@rid"] == row.Type);
        if (!dclass) {
            console.log(`cannot find row.Type ${row.Type}`)
            return row;
        }
        const dtype = this.props.allTypes.find(c => c["@rid"] == dclass.DiscountType);
        if (!dtype) {
            console.log(`cannot find DiscountType ${dclass.DiscountType}`)
            return row;
        }

        row.DiscountClass = dclass["@rid"];
        row.DiscountType = dtype["@rid"];
        row.Operator = dclass.Operator as any;

        if (!row.hasOwnProperty("Rank"))
            row.Rank = dclass.Rank as any;
        row.LastPosition = dclass.LastPosition;
        return row;
    }

    getDiscount = (includeEdit: boolean = false): ref_Discount[] => {
        const rows = this.state.gridData;
        return rows
            .filter(r => includeEdit || !r.inEdit)
            .filter(r => this.isValid(r, false))
            .map(r => this.toDiscount(r));
    }

    getDiscountAndUpdate = (includeEdit: boolean = false): ref_Discount[] => {
        const rows = this.state.gridData;
        return rows.filter(r => includeEdit || !r.inEdit).map(r => {
            const d = this.toDiscount(r);
            if (r.dataItem?.Intervals)
                d.Intervals = r.dataItem?.Intervals;
            if (r.dataItem?.Base)
                d.Base = r.dataItem?.Base;
            r.dataItem = d;
            return d;
        });
    }

    getCustomDiscount = (): (ref_Discount & { Minimum: number, Maximum: number })[] => {
        const rows = this.state.gridData;
        const discounts = rows.filter(r => !r.inEdit).map(this.toDiscount);
        return discounts as any;
    }

    setRowType = (row: any, prop: "Rate" | "Value", value: any) => {
        let t = this.currentType();
        if (t) {
            if (row[t])
                row[t][prop] = value;
        }
    }

    updateCascade = () => {
        const { base } = this.props;
        const rows = this.state.gridData;
        if (!rows)
            return;

        rows.forEach(this.updateRow);

        if (!base)
            return;

        let currentBase = base();
        let result = currentBase;

        const computeRows = (currentRows: Row[], cascade: boolean = true) => {
            let totalRemise = 0;
            currentRows.forEach(row => {

                row.Base = currentBase;
                if (cascade)
                    row.Base += totalRemise;

                const discountClass = this.props.allClasses.find(dc => dc["@rid"] === row.DiscountClass);
                if (discountClass?.Base) {
                    const base: DiscountBase = discountClass.Base as any;
                    switch (base.Type) {
                        case "DiscountType":
                            const allFrais = rows.filter(r => r.DiscountType === base.Data && r !== row);
                            const res = allFrais.map(frais => frais.Value ?? 0).reduce((a, b) => a + b, 0);
                            row.Base = res;
                            break;

                        case "KPI":
                            row.Base = this.props.message.KPIs[base.Field + this.props.type];
                            break;

                        default:
                            if (IsDebugMode())
                                throw new Error(`base.Type not found: `, base);
                            console.error(`base.Type not found: `, base);
                            break;
                    }
                }

                let remise = 0;
                if (row.IsRate) {
                    remise = Math.abs(row.Rate * row.Base);
                    row.Value = remise;
                    this.setRowType(row, 'Value', remise);
                }
                else {
                    remise = Math.abs(row.Value);
                    row.Rate = Math.abs(row.Base ? (remise / row.Base) : 0);
                    this.setRowType(row, 'Rate', row.Rate);
                }

                if (row.Value == -0) row.Value = 0;
                if (row.Rate == -0) row.Rate = 0;

                if (isNaN(row.Rate)) row.Rate = 0;
                if (isNaN(row.Value)) row.Value = 0;


                if (!isNaN(remise))
                    totalRemise += remise * row.Operator;
            });
            return totalRemise;
        }

        if (this.props.ranking?.()) {
            let ranks = Array.from(new Set(rows.map(r => r.Rank)));
            ranks.sort((a, b) => a - b);

            ranks.forEach(rank => {
                let currentRows = rows.filter(row => row.Rank === rank);
                currentRows.sort(sortPosition);
                const totalRemise = computeRows(currentRows, false);
                currentBase += totalRemise;
                result += totalRemise;
            });
        } else {
            const totalRemise = computeRows(rows, true);
            result += totalRemise;
        }

        this.forceUpdate();

        return result;
    }

    enterEdit = (dataItem: any) => {
        this.setState({
            gridData: this.state.gridData.map(item => {
                // cancel current elements in edition
                if (item.inEdit)
                    return this.state.gridDataBase.find(p => p["@rid"] === item["@rid"]);

                return item["@rid"] === dataItem["@rid"] ?
                    { ...item, inEdit: true } : item
            }).filter(e => e)
        }, () => {
            this.updateCascade();
            this.props.enterEdit?.()
        });

    }

    remove = (dataItem: any) => {
        const gridData = [...this.state.gridData];
        this.removeItem(gridData, dataItem);
        this.removeItem(this.state.gridDataBase, dataItem);

        this.setState({ gridData }, () => {
            this.props?.onChange();
            this.refreshGrid();
            if (this.props.refreshAfterDelete)
                this.props?.refreshAfterDelete();
        });
    }

    add = (dataItem: Row) => {
        if (!this.isValid(dataItem))
            return;

        if (!dataItem.DiscountClass) {
            this.removeItem(this.state.gridData, dataItem);
        }

        dataItem.inEdit = undefined;
        dataItem["@rid"] = this.generateId();

        this.state.gridDataBase.unshift(dataItem);
        this.setState({
            gridData: [...this.state.gridData].sort(this.sort)
        }, () => {
            this.props.onChange?.()
            this.props.endEdit?.()
            this.props.newRowAdded?.(dataItem)
            this.refreshGrid();
        });
    }

    discard = (dataItem: any) => {
        const gridData = [...this.state.gridData];
        this.removeItem(gridData, dataItem);
        this.setState({ gridData }, () => {
            this.props.endEdit?.();
            this.refreshGrid();
        });
    }

    isValid = (row: Row, notify: boolean = true) => {
        const { validator, type } = this.props;
        if (validator) {
            const discount = this.toDiscount(row);
            const discounts = this.state.gridData
                .filter(d => !d.inEdit)
                .map(this.toDiscount);

            const errors = Object.values(validator)
                .map(v => v(discount, discounts, type))
                .filter(v => !v.valid);
            if (errors.length) {
                let msg = `${Trad('element_not_valid')}: \n`;
                msg += errors
                    .filter(e => e.message)
                    .map(e => Trad(e.message))
                    .join(`\n`);
                console.log(`error`, msg);
                if (notify)
                    Notify(msg, "error");
                return false;
            }
        }
        return true;
    }

    update = (dataItem: Row): void => {
        if (!this.isValid(dataItem))
            return;

        if (!dataItem.DiscountClass)
            return this.cancelCurrentChanges();

        const gridData = [...this.state.gridData];
        const updatedItem = { ...dataItem, inEdit: undefined };

        this.updateItem(gridData, updatedItem);
        this.updateItem(this.state.gridDataBase, updatedItem);

        const gridDataSorted = [...gridData].sort(this.sort);
        this.setState({ gridData: gridDataSorted }, () => {
            this.props.onChange?.()
            this.props.endEdit?.()
        });
    }

    cancel = (dataItem: any) => {
        const originalItem = this.state.gridDataBase.find(p => p["@rid"] === dataItem["@rid"]);
        if (originalItem) {
            const gridData = this.state.gridData.map(item => item["@rid"] === originalItem["@rid"] ? originalItem : item);
            this.setState({ gridData }, () => {
                this.updateCascade();
                this.props?.onChange();
            });
        } else {
            this.setState({ gridData: this.state.gridData.filter(d => d != dataItem) }, () => {
                this.updateCascade();
                this.props?.onChange();
            });
        }
        this.props.endEdit?.()
    }

    updateItem = (gridData: any[], item: any) => {
        let index = gridData.findIndex((p: any) => p === item || (item["@rid"] && p["@rid"] === item["@rid"]));
        if (index >= 0) {
            gridData[index] = { ...item };
        } else {
            gridData.push(item);
        }
    }

    itemChange = (event: { dataItem: ref_Discount, value: any, field?: string, endEdit?: boolean }) => {

        // TODO on supprime le check du changement de valeur pour le moment, on passera par redux pour le faire
        // const old = clone(this.state.gridDataBase.find(d => d["@rid"] === event.dataItem["@rid"]));

        const gridData = this.state.gridData.map(item => {
            if (item["@rid"] === event.dataItem["@rid"]) {
                const obj = { ...item, [event.field]: event.value };
                if (event.field === "Rate") obj.IsRate = true;
                if (event.field === "Value") obj.IsRate = false;
                return obj;
            }
            else {
                return item;
            }
        });

        let elmnt = gridData.find(d => d["@rid"] === event.dataItem["@rid"]);

        if (event.field === "Rate" || event.field == "Value") {
            let t = this.currentType();
            if (t) {
                elmnt[t] = {
                    Rate: elmnt.Rate,
                    Value: elmnt.Value
                }
                // if (event.endEdit &&
                //     ((old?.[t] && (elmnt[t].Rate !== old[t].Rate ||
                //         elmnt[t].Value !== old[t].Value)) ||
                //         elmnt.IsRate !== old?.IsRate)
                // )
                {
                    elmnt.Agreement = null;
                }
            }
        }
        else if (event.field === "Rank") {
            // if (old && elmnt.Rank !== old.Rank) {
            elmnt.Agreement = null;
            // }
        }

        this.updateRow(elmnt)
        gridData.sort(sortPosition);
        this.setState({ gridData }, () => {
            this.props?.onChange();
            if (event.endEdit) {
                this.props?.endEdit?.();
            }
        });
    }

    itemRowChange = (event: any) => {
        const gridData = this.state.gridData.map(item =>
            item["@rid"] === event.dataItem["@rid"] ?
                { ...item, [event.field]: event.value, IsRate: event.field == "Rate" } : item
        );

        if (IsDebugMode()) {
            console.log(`itemRowChange event`, event);
            console.log(`itemRowChange gridData`, gridData);
        }

        this.setState({ gridData }, () => this.props?.onChange());
    }

    addNew = (filter?: string, inEdit: boolean = true, baseObject: Partial<Row> = {}) => {
        return new Promise((res, rej) => {
            try {
                const newDataItem: Row = { ...baseObject, inEdit, Discontinued: false, filter: filter, Base: this.props.base?.(), "@rid": Date.now() };
                this.updateRow(newDataItem);

                if (IsDebugMode())
                    console.log(`addNew newDataItem`, newDataItem);

                this.setState({
                    gridData: [newDataItem, ...this.state.gridData]
                }, () => res(newDataItem));
            } catch (error) {
                rej(error);
            }
        })
    }

    addNewFromDiscount = (d: ref_Discount & { Minimum?: number, Maximum?: number }) => {

        const base = this.wrap([d])[0]
        const newDataItem: Row = { ...base, inEdit: true, Discontinued: false, Base: this.props.base?.() };
        newDataItem["@rid"] = Date.now();

        if (IsDebugMode())
            console.log(`addNewFromDiscount newDataItem`, newDataItem);

        this.setState({
            gridData: [newDataItem, ...this.state.gridData]
        });
    }

    cancelCurrentChanges = () => {
        this.setState({ gridData: [...this.state.gridDataBase] });
    }

    generateId = () => Date.now();

    removeItem(gridData: any, item: any) {
        let index = gridData.findIndex((p: any) => p === item || item["@rid"] && p["@rid"] === item["@rid"]);
        if (index >= 0) {
            gridData.splice(index, 1);
        }
    }

    getModulation = (disc: ref_Discount) => {
        let t = this.currentType();
        if (t) {
            if (disc[t]) return disc[t];
            if (t === "FOS" && disc.FO) return disc.FO;
            else {
                return disc["CO"] ?? {
                    Rate: 0,
                    Value: 0
                }
            }
        }
        else {
            return {
                Rate: disc.Rate,
                Value: disc.Value
            }
        }
    }

    wrap = (discounts: ref_Discount[]) => {
        return discounts?.map((d, i) => {
            let element = this.props.allClasses?.find(c => c["@rid"] == d.DiscountClass);
            if (!element) return undefined;
            let mod = this.getModulation(d);
            const row = {
                "@rid": i + 1,
                Type: element["@rid"],
                Rate: mod.Rate,
                Rank: d.Rank,
                Value: mod.Value,
                CO: d.CO,
                FO: d.FO,
                FOS: d.FOS,
                Base: this.props.base?.(),
                inEdit: false,
                IsRate: d.IsRate,
                Agreement: d.Agreement,
                Minimum: (d as any).Minimum,
                Maximum: (d as any).Maximum,
                dataItem: d,
                Intervals: d.Intervals
            }
            this.updateRow(row);
            return row;
        }).filter(d => d) ?? [];
    }

    reorder = (dataItem: any) => {

        if (!this.state.activeItem || (this.state.activeItem === dataItem)) return;

        let reorderedData = this.state.gridData.slice();
        let prevIndex = reorderedData.findIndex((p: any) => (p === this.state.activeItem));
        let nextIndex = reorderedData.findIndex((p: any) => (p === dataItem));
        reorderedData.splice(prevIndex, 1);
        reorderedData.splice(nextIndex, 0, this.state.activeItem);

        this.setState({
            gridData: reorderedData,
            activeItem: this.state.activeItem
        }, () => this.props?.onChange());
    }

    shouldComponentUpdate = (nextProps: DCAProps, nextState: DCAState) => {
        if (nextState.activeItem != this.state.activeItem)
            return false;
        return true;
    }

    dragStart = (dataItem: any) => {
        if (this.state.activeItem != dataItem)
            this.setState({ activeItem: dataItem });
    }

    isEditable(dtype: ref_DiscountTypes["@rid"]) {
        if (this.currentType() === "CO")
            return true;
        return DiscountCanBeEdited(dtype)
    }

    cellPercent = (props: GridCellProps) => <CellPercentComponent {...props} editable={this.isEditable(props.dataItem.DiscountType)} ptr={this} itemChange={this.itemChange} />;
    cellIntervals = (props: any) => <CellIntervalsComponent {...props} ptr={this} currency={this.props.currencyCode} hideIntervalNumber={this.props.hideIntervalNumber} />

    cellNumberPriceEditableMinMax = (props: any) => <CellNumberComponent {...props} ptr={this} editable={true} opt={{ type: ePropType.Integer, step: 1, format: "n0" }} currency={this.props.currencyCode} itemChange={this.itemChange} />
    cellNumberPriceEditable = (props: GridCellProps) => <CellNumberComponent {...props} ptr={this} editable={this.isEditable(props.dataItem.DiscountType)} opt={{ type: ePropType.Double, minmax: true, color: true, applyOperator: true }} currency={this.props.currencyCode} itemChange={this.itemChange} />
    cellNumberPrice = (props: any) => <CellNumberComponent {...props} ptr={this} editable={false} opt={{ type: ePropType.Double, minmax: true }} currency={this.props.currencyCode} itemChange={this.itemChange} />
    cellNumberIntEditable = (props: any) => <CellNumberComponent {...props} ptr={this} editable={true} opt={{ type: ePropType.Integer }} itemChange={this.itemChange} />


    filterExist = (classRid: string) => {
        return !this.props.discountsProvider()?.find(d => d.DiscountClass == classRid);
    }
    sort = (a: Row, b: Row) => {
        if (this.props.ranking?.())
            return a.Rank - b.Rank
        if (this.props.intervalActivated)
            return a.Minimum - b.Minimum
    }
    render() {

        let { gridData } = this.state;
        const { onLockValueChanged, base, draggable, ranking, intervalActivated, hideCommandCells, hideIntervalCells, isRemovable, onRemoveClick, lockValueEnable, canLockValue, cellLock } = this.props;

        if (!intervalActivated)
            gridData.forEach(g => g.LabelName = this.props.allClasses.find(t => t["@rid"] == g.Type)?.Name ?? "Not found");

        gridData.sort(sortPosition);
        return (<>
            {gridData && <Grid data={gridData}
                onItemChange={this.itemChange}
                className="DiscountCategoryArray">

                {intervalActivated && <Column field={propertyOf<ref_Intervals>('Minimum')} editor="numeric" width={colWidth} cell={this.cellNumberPriceEditableMinMax} />}
                {intervalActivated && <Column field={propertyOf<ref_Intervals>('Maximum')} editor="numeric" width={colWidth} cell={this.cellNumberPriceEditableMinMax} />}

                {ranking?.() && <Column field={propertyOf<ref_Discount>('Rank')} editor="numeric" width={colWidth} cell={this.cellNumberIntEditable} />}
                {(draggable?.() === undefined || draggable?.()) && <Column width={dragCol} cell={GetDragCell(this.reorder, this.dragStart)} />}

                {!intervalActivated && <Column field={"Type"} title={Trad('Type')} cell={VertexRidCell<any>({
                    isValid: (row) => {
                        let validator = this.props.validator?.["DiscountClass"]
                        if (validator) {
                            return validator(this.toDiscount(row))?.valid
                        }
                        return true
                    },
                    dataProvider: (row: any) => {
                        let f = row.filter;
                        let res = this.props.classes;
                        if (f) res = this.props.allClasses.filter(c => c.DiscountType == f).map(c => c["@rid"]);

                        res = res.filter(this.filterExist);
                        if (row.DiscountClass) res.unshift(row.DiscountClass);

                        return res;
                    },
                    dataProviderFormater: (data: rid) => this.props.allClasses.find(t => t["@rid"] == data)?.Name ?? "Not found",
                    editable: true,
                    onChange: (dataItem) => {
                        this.updateRow(dataItem);
                    },
                })} />}

                {base !== undefined && <Column field={'Base'} width={colWidth} cell={this.cellNumberPrice} />}
                {!hideIntervalCells && !intervalActivated && <Column field={propertyOf<ref_Discount>('Intervals')} width={colWidth} cell={this.cellIntervals} />}
                <Column field={propertyOf<ref_Discount>('Rate')} editor="numeric" width={colWidth} cell={this.cellPercent} />
                <Column field={propertyOf<ref_Discount>('Value')} editor="numeric" width={colWidth} cell={this.cellNumberPriceEditable} />
                {/** lockValueEnable CheckBox Cell */}
                {lockValueEnable && <Column
                    width={removeCol}
                    cell={(props: GridCellProps) => {
                        const component = <CellLockValueComponent
                            {...props}
                            canChangeValue={item => canLockValue?.(item) ?? true}
                            onLockChanged={(locked, item) => {

                                if (locked) {
                                    this.updateItem(this.state.gridData, item);
                                    onLockValueChanged?.(item);
                                    return;
                                }

                                this.removeItem(this.state.gridData, item);
                                onRemoveClick(item);
                            }} />
                        return cellLock ? cellLock(component, props) : component;
                    }}
                />}

                {/** suppression des discounts utilisateurs */}
                {isRemovable && <Column width={removeCol} cell={(props: GridCellProps) =>
                    <CellRemoveComponent
                        {...props}
                        isRemovable={isRemovable}
                        onRemoveClick={(item) => {
                            this.removeItem(this.state.gridData, item);
                            onRemoveClick?.(item)
                        }} />}
                />}
            </Grid>}
        </>);
    }
}