import { useSelector } from 'react-redux';
import { compareObjects, extractSub } from 'hub-lib/tools.bin';
import { RootState, SetModelSync, store } from '../../../redux/store';
import { Title } from '../../Tools';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { CustomIconButton } from '../Generic/CustomIconButton';
import { getIcon } from 'adwone-lib';
import { Trad } from 'trad-lib';
import { GenericForm } from '../../layout/GenericForm';
import { EstimatesLinkedDetail } from '../Estimates/EstimateLinkedDetails.bin';
import { eDialogMode } from '../../ConfigurableComponents/GenericDialog.bin';
import { AddNewEstimate, setSelectedEstimate } from '../../../redux/campaignEditorSlice';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';

type CampaignEstimatePickerProps = {
    mode: eDialogMode
};

export function CampaignEstimatePicker({ mode }: CampaignEstimatePickerProps) {
    const enableEstimate = useSelector((root: RootState) => root.campaignEditor.enableEstimate);
    if (!enableEstimate) return <></>;
    return <CampaignEstimatePickerComponent mode={mode} />
}

export function CampaignEstimatePickerComponent({ mode }: CampaignEstimatePickerProps) {

    const campaign = useSelector((root: RootState) => extractSub(root.campaignEditor.get(), ['@rid', 'Estimates', 'AdvertiserGroup', 'Advertiser', 'Brand', 'Product']), compareObjects);
    const selectedEstimate = useSelector((root: RootState) => root.campaignEditor.selectedEstimate);
    const form = useSelector((root: RootState) => root.campaignEditor.form);

    if (!campaign || !form) return <></>;
    return <div id='estimatepicker'>
        <Title>{Trad('estimate')}</Title>
        <TabStrip
            className="full-width mainmargin-container tabpanel_fullwidth tabpanel_rightmenu"
            selected={selectedEstimate}
            onSelect={(e) => store.dispatch(setSelectedEstimate(e.selected))}>
            {campaign.Estimates.map((estimate, index) =>
                <TabStripTab title={<EstimateTabTitle estimate={estimate} index={index} />}>
                    <GenericForm
                        disabled={mode === eDialogMode.modify && estimate.ExternalID !== null}
                        form={form}
                        store={estimate.CreateParams ?? {}}
                        onAnswer={(_store, _key, _value) => {
                            console.log(`[onAnswer]`, _store, _key, _value);
                            const _campaign = store.getState().campaignEditor.get();
                            _campaign.Estimates[index].CreateParams = _store;
                            SetModelSync(ref_Campaigns.name, _campaign);
                        }}
                        order={{
                            modeles: "message_details_leftcombo",
                            priceCategory: "message_details_rightcombo",
                            tvaMode: "message_details_leftcombo",
                            documentAppearance: "message_details_rightcombo"
                        }} />
                    {mode == eDialogMode.modify && estimate.ExternalID !== null && <EstimatesLinkedDetail dataItem={{ dataItem: { ["@rid"]: estimate.ExternalID } }} dataIndex={0} hideMessage />}
                </TabStripTab>)}
            <TabStripTab title={<AddEstimateButton />} />
        </TabStrip>
    </div>
}

function EstimateTabTitle({ estimate, index }) {
    const nbEstimates = useSelector((root: RootState) => root.campaignEditor.get()?.Estimates?.length ?? 0);
    const selectedEstimate = useSelector((root: RootState) => root.campaignEditor.selectedEstimate);

    return <div>
        {estimate.EstimateLib}
        {nbEstimates > 1 && index !== selectedEstimate && estimate.ExternalID === null &&
            <CustomIconButton onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const newSelected = selectedEstimate === nbEstimates - 1 ? selectedEstimate - 1 : selectedEstimate;
                const data = store.getState().campaignEditor.get();
                data.Estimates.splice(index, 1);
                store.dispatch(setSelectedEstimate(newSelected));
                SetModelSync("ref_Campaigns", data);
            }} >{getIcon("close")}</CustomIconButton>}
    </div>
}


function AddEstimateButton() {
    return <div onClick={() => {
        const campaign = store.getState().campaignEditor.get();
        let number = campaign.Estimates.length;
        const estimates = campaign.Estimates.filter(e => e.ExternalID === null);
        const lastEstimate = estimates[estimates.length - 1];
        if (lastEstimate) {
            const [numberLast] = lastEstimate.EstimateLib.match(/\d+/g);
            number = parseInt(numberLast);
        }
        store.dispatch(AddNewEstimate({ nb: number + 1 }));
    }}>
        <CustomIconButton
            className="custom_btn_secondary">
            {getIcon("plus_rounded")}
        </CustomIconButton>
    </div>

}