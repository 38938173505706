import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { AdwAutocomplete, ADWColumn } from "adwone-lib";
import { ref_CalendarEvent } from "hub-lib/dto/client/ref_CalendarEvent";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import React from "react";
import { Trad, TradProp } from "trad-lib";
import { SimpleDatePicker } from "../../ConfigurableComponents/SimpleDatepicker.bin";
import { Row } from "../../Tools";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { CustomTextField } from "../../VertexGrid/Generic/CustomTextField";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Template from "../Template";

export const CalendarSettings = Template(function () {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {
            const columns: ADWColumn<ref_CalendarEvent>[] = [
                new ADWColumn(TradProp("Updated_at"), "Updated_at", ePropType.Datetime),
                new ADWColumn(TradProp("Created_at"), "Created_at", ePropType.Datetime),
            ];
            const hiddenProperties: (keyof ref_CalendarEvent)[] = [];
            //const properties: string[] = [];
            const grid = new VertexGrid<ref_CalendarEvent>({
                disableStore: true,
                objectPrototype: ref_CalendarEvent,
                devMode: false,
                columns,
                vertexParams: {
                    Active: true,
                    properties: ["*"]
                },
                width: {
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });

    return <DefaultGrid
        objectPrototype={ref_CalendarEvent}
        grid={grid}
        dialogContent={(data, mode) => <CalendarSettingsDialog data={data} mode={mode} />}
        gridProps={{
            selectable: true,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
})

const CalendarSettingsDialog = (props: { data: ref_CalendarEvent, mode: string }) => {

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const item = props.data;

    if (!item.Start) item.Start = new Date();
    if (!item.End) item.End = new Date();
    if (!item.IsAllDay) item.IsAllDay = false;
    if (!item.Category) item.Category = "market";
    if (!item.Description) item.Description = "";

    console.log(props.data)
    return <Grid container style={{ minHeight: 350 }}>
        <Grid item xs={6}>
            <Row>
                <CustomTextField label={TradProp("Name")} value={item.Name ?? ""} onChange={e => {
                    item.Name = e;
                    forceUpdate();
                }} />
            </Row>

            <Grid container>
                <Grid xs={6} item className="message_details_leftcombo">
                    <SimpleDatePicker
                        label={`${Trad("Début")} *`}
                        defaultValue={new Date(item.Start)}
                        onChange={(event) => {
                            const oldStart = item.Start;
                            item.Start = event.value;
                            if (oldStart && item.End && item.Start > item.End) {
                                const diff = Math.abs(item.End.getTime() - oldStart.getTime());
                                item.End = new Date(item.Start.getTime() + diff);
                                forceUpdate();
                            }
                        }}
                    />
                </Grid>
                <Grid xs={6} item className="message_details_rightcombo">
                    <SimpleDatePicker
                        key={`End_${GetHashCode(item.End)}`}
                        label={Trad("Fin")}
                        min={new Date(item.Start)}
                        defaultValue={item.End ? new Date(item.End) : undefined}
                        onChange={(event) => {
                            if (event.value < new Date(item.Start))
                                item.End = new Date(item.Start);
                            else
                                item.End = event.value;
                            forceUpdate()
                        }}
                        resetDate={() => {
                            item.End = null;
                            forceUpdate()
                        }}
                    />
                </Grid>
            </Grid>

            <Row>
                {/* <CustomTextField label={TradProp("Category")} value={item.Category ?? ""} onChange={e => {
                    item.Category = e;
                    forceUpdate();
                }} /> */}
                <AdwAutocomplete
                    disableClearable
                    options={["personal", "webinars", "survey_data", "market", "publications_tsm"]}
                    value={item.Category}
                    onChange={(event: any, value: any) => {
                        item.Category = value;
                        forceUpdate();
                    }}
                    getOptionLabel={l => Trad(l)}
                    renderInput={(params) => <TextField {...params} label={TradProp("Category")} variant="outlined" />}
                />
            </Row>

            <Row>
                <CustomTextField label={TradProp("Description")} value={item.Description ?? ""} onChange={e => {
                    item.Description = e;
                    forceUpdate();
                }} />
            </Row>

            <Row>
                <FormControlLabel
                    key={`IsAllDay-${item.IsAllDay}`}
                    style={{ marginTop: 0, marginLeft: 5 }}
                    control={
                        <Checkbox
                            checked={item.IsAllDay}
                            onChange={(event) => {
                                item.IsAllDay = event.target.checked;
                                forceUpdate();
                            }}
                            name={TradProp('IsAllDay')}
                            color="primary" />}
                    label={TradProp('IsAllDay')} />
            </Row>

        </Grid>
    </Grid >
}
