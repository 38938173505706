import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IItemRow } from "adwone-lib/index";
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_SchedulerConfigurations } from 'hub-lib/dto/client/ref_SchedulerConfigurations.bin';
import { clone, duplicate, GetSubElement } from 'hub-lib/tools.bin';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ConsoleDebug } from '../utils/localstorage.bin';
import { RowWrapper } from '../components/crossedTable/CrossedTableTelerikTree.bin';

export interface GridState<T> {
    selectedItems: (IItemRow<T> | RowWrapper<T>)[];

    data: T[];
    schedulerTemplate: ref_SchedulerConfigurations;

    // Contient les styles filtrés (schedulerTemplate.Styles) sur les data présentes (présent dans data)
    dataStyles: ref_SchedulerConfigurations['Style'];

    sort: SortDescriptor[];
    gridFilter: CompositeFilterDescriptor;
    gridFilterBase: CompositeFilterDescriptor;
}

const initialState: GridState<any> = {
    selectedItems: [],
    data: [],
    schedulerTemplate: null,
    dataStyles: {},
    sort: [],
    gridFilter: { logic: 'and', filters: [] },
    gridFilterBase: { logic: 'and', filters: [] }
}

function createDataStyles<T>(data: T[], schedulerTemplate: ref_SchedulerConfigurations) {

    const dataStyles: ref_SchedulerConfigurations['Style'] = {};

    if (!schedulerTemplate?.Style)
        return dataStyles;

    const keys = new Set<string>(Object.values(schedulerTemplate.Style).map(v => v.key));
    if (schedulerTemplate.Style && data?.length > 0 && keys.size > 0)
        data?.forEach(d => {
            keys.forEach(key => {
                const value = GetSubElement(d, key);
                const template = schedulerTemplate.Style[value];
                if (template)
                    dataStyles[value] = clone(template);
            })
        });
    return dataStyles;
}

export const authSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        setSort: <T>(state, action: PayloadAction<GridState<T>['sort']>) => {
            state.sort = clone(action.payload);
        },
        setGridFilter: <T>(state, action: PayloadAction<{ adapted: GridState<T>['gridFilter'], base: GridState<T>['gridFilter'] }>) => {
            state.gridFilter = clone(action.payload.adapted);
            state.gridFilterBase = clone(action.payload.base);
        },
        clearGridFilter: (state) => {
            state.gridFilter = null;
            state.gridFilterBase = null;
        },
        selectItems: (state, action: PayloadAction<any>) => {
            state.selectedItems = duplicate(action.payload);
        },
        setData: <T>(state, action: PayloadAction<T[]>) => {
            const time8540 = new Date().getTime();
            state.data = duplicate(action.payload);
            const _time8540 = new Date().getTime();
            ConsoleDebug(`[PERF] REDUX setData duplicate  Elapsed ${_time8540 - time8540}ms`);

            const time8533 = new Date().getTime();
            state.dataStyles = createDataStyles(action.payload, clone(state.schedulerTemplate));
            const _time8533 = new Date().getTime();
            ConsoleDebug(`[PERF] REDUX createDataStyles Elapsed ${_time8533 - time8533}ms`);

            state.selectedItems = duplicate(state.selectedItems)?.filter(i => state.data?.some(d => d['@rid'] == i?.dataItem?.['@rid']));
        },
        setSchedulerTemplate: (state, action: PayloadAction<ref_SchedulerConfigurations>) => {
            state.schedulerTemplate = duplicate(action.payload);
            state.dataStyles = createDataStyles(clone(state.data), action.payload);
        },
        initializeSchedulerTemplate: (state, action: PayloadAction<ref_SchedulerConfigurations>) => {
            const newValue = clone(action.payload);
            state.schedulerTemplate = newValue;
            state.dataStyles = createDataStyles(clone(state.data), newValue);
        }
    },
});

// Action creators are generated for each case reducer function
export const { selectItems, setData, setSchedulerTemplate, initializeSchedulerTemplate, setSort, setGridFilter, clearGridFilter } = authSlice.actions

export const gridReducer = authSlice.reducer;