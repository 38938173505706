import { Trad } from "trad-lib";
import { CustomGroupButtonProps } from "../components/ConfigurableComponents/CustomGroupButton.bin";
import { getIcon } from "adwone-lib/index";
import { StyleFactory } from "../utils/ToolsReact";

export const RowSize = 28;
export const FooterRowSize = 25;

export const ExportButtonProps: () => Pick<CustomGroupButtonProps, "Label" | "startIcon" | "buttonClassName" | "containerClassname" | "disableMoreIcon"> = () => ({
    startIcon: getIcon("download"),
    Label: Trad("export"),
    buttonClassName: "custom_btn_secondary",
    containerClassname: "custom_btn_secondary",
    disableMoreIcon: true,
    // hasTooltip: Trad("export")
})

export const styleGridContainer = StyleFactory.create({
    messages: {
        height: `calc(100vh - 140px - ${FooterRowSize}px)`
    },
    referential: {
        height: `calc(100vh - 182px - ${FooterRowSize}px)`
    },
    rights: {
        height: 'calc(100vh - 260px)'
    },
    listboxRights: {
        height: `calc(100vh - 260px - ${RowSize}px)`
    },
    treeListRights: {
        height: `calc(100vh - 260px - ${RowSize}px)`,
        overflow: `hidden`,
        display: `inline-block`,
        width: `100%`,
    },
    medialandImport: {
        height: `calc(100vh - 248px - ${FooterRowSize}px)`
    },
    translationsWithDevMode: {
        height: `calc(100vh - 307px - ${FooterRowSize}px)`
    },
    translations: {
        height: `calc(100vh - 150px - ${FooterRowSize}px)`
    },
    translationsLoader: {
        height: `calc(100vh - 140px - ${FooterRowSize}px)`
    },
    translationsLoaderDevMode: {
        height: `calc(100vh - 300px - ${FooterRowSize}px)`
    },
    agreementForm: {
        height: "calc(100vh - 338px)"
    },
    estimates: {
        height: `calc(100vh - 150px)`
    },
    negotiationForm: {
        height: 250
    },
    supportForm: {
        height: 350
    }
});
