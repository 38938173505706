import * as React from "react";
import { Menu, MenuItemModel } from "@progress/kendo-react-layout";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { Trad } from "trad-lib";
import { GetHashCode } from "hub-lib/tools.bin";
import { Client } from "hub-lib/client/client.bin";

const classItem = (c: ref_DiscountClasses, t: ref_DiscountTypes) => ({ text: Trad(c.Name), data: { class: c, type: t } })
const typeItem = (t: ref_DiscountTypes, classes: ref_DiscountClasses[]) => ({ text: Trad(t.Name), items: classes.filter(c => c.DiscountType == t["@rid"]).map((e) => classItem(e, t)) })

let types: ref_DiscountTypes[];
type MenuAddDiscountProps = { classes: ref_DiscountClasses[], onClick?: (dType: ref_DiscountTypes, dClass: ref_DiscountClasses) => void }
export function MenuAddDiscount({ classes, onClick }: MenuAddDiscountProps) {

    const [items, setItems] = React.useState<MenuItemModel[]>();

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (types == null)
                types = await Client.searchVertexTyped(ref_DiscountTypes);

            const items: MenuItemModel[] = [{
                text: Trad("add"),
                items: types.filter(t => classes?.some(c => c.DiscountType == t["@rid"])).map(t => typeItem(t, classes))
            }];

            setItems(items);
        })
    }, [GetHashCode(classes)]);

    return <Menu className="menu_custom_btn_primary"
        items={items}
        onSelect={async (e) => {
            if (!e.item.data) return;
            const dClass: ref_DiscountClasses = e.item.data.class;
            const dType: ref_DiscountTypes = e.item.data.type
            onClick?.(dType, dClass);
        }} />
}