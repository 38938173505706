import { ref_BroadcastAreas, ref_BroadcastAreasId } from "./ref_BroadcastAreas.bin";
import { ref_Media, ref_MediaId } from "./ref_Media.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_RegionalId = rid;

export class ref_Regional extends Referentials {
	"@rid"?: ref_RegionalId;
	BroadcastAreas: ref_BroadcastAreasId[];
	Medias: ref_MediaId[];

}