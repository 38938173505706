import * as React from "react";
import { GetCurrentLocale, Trad } from "trad-lib";
import {
    Chart,
    ChartAxisDefaults,
    ChartLegend,
    ChartLegendProps,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemProps,
    ChartTitle,
    ChartTitleProps,
    ChartTooltip,
    SeriesClickEvent,
} from "@progress/kendo-react-charts";
import 'hammerjs'

import { BasicAggregateManager, DataValue } from "hub-lib/business/BasicAggregateManager.bin";
import { SeriesType } from "@progress/kendo-react-charts/dist/npm/common/property-types";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { FormatNumber, GetKPITemplate } from "format-lib/index.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { Row } from "adwone-engine/types.bin";
import Loader from "../layout/Loader";
import { ADWProperty } from "hub-lib/types";
import { firstOrDefault, includeArrays, propertyOf, toArray } from "hub-lib/tools.bin";
import { getMonthName, isBetween, setEndOfMonth } from "tools-lib";
import { IndicateurKPI } from "adwone-engine/index.bin";
import moment from "moment";

const MAX = 9;

let dico: any = {
    None: "lightgrey",
    Simulated: "#F27629",
    Opted: "#1A81EF",
    Confirmed: "#3AB36E",
    Cancelled: "#DA3240",
};

let colors = [
    "#009BCE",
    "#3AB36E",
    "#F27629",
    "#1A81EF",
    "#DA3240",
    "#6DC6E3",
    "#8ED4AC",
    "#F8B185",
    "#7CB7F6",
    "#EA8A92",
];

let selectedColors = {
    "#009BCE": "#66c3e1",
    "#3AB36E": "#88d1a8",
    "#F27629": "#f7ac7e",
    "#1A81EF": "#75b3f5",
    "#DA3240": "#e8848c",
    "#6DC6E3": "#a7dcee",
    "#8ED4AC": "#bbe5cd",
    "#F8B185": "#fad0b5",
    "#7CB7F6": "#b0d3f9",
    "#EA8A92": "#f2b8bd",
};

type Max = {
    nb?: number;
    hide?: boolean;
    otherWhen?: number;
};
export class TChartContainerProps {
    dimension: ADWProperty | ADWProperty[];
    KPI?: string;
    Indicateur?: IndicateurKPI;
    KPIType?: eKPIType;
    customKPI?: (a: Row<ref_Messages>) => any;
    type: SeriesType;
    filters?: { Start?: Date, End?: Date, } & { [prop: string]: string[] };
    chartFilters?: { [prop: string]: string | string[] };
    budget?: number;
    start?: Date;
    end?: Date;
    titleProps?: ChartTitleProps;
    max?: Max;
    fixed?: number;
    flatten?: boolean;
    axis?: {
        kilo?: boolean;
    }
    legendProps?: ChartLegendProps;
    chartSeriesItemProps?: ChartSeriesItemProps;
    onSeriesClick?: (fields: ({field: string, value: any})[]) => void;
}

class TChartContainerState {
    series?: DataValue[][];
    isDate?: boolean;
}

const fixSeries = (series: DataValue[], fixed?: number, isDate?: boolean) => {
    if (fixed === undefined) return series;
    for (let index = 0; index < fixed; index++) {
        const label = isDate ? getMonthName((index + 1).toString(), true) : index.toString();
        const element = series.findIndex(s => s.Label === label);
        if (element === -1)
            series.splice(index, 0, {
                Label: label,
                Value: series[index - 1]?.Value ?? index.toString(),
                KPI: 0,
            });
    }
}

const maxSeries = (series: DataValue[], index: number, allseries: DataValue[][], maxi: number, props: TChartContainerProps) => {
    if (props.max?.otherWhen) {
        // group when serie is less than otherWhen
        const total = series.reduce((acc, val) => acc + val.KPI, 0);
        const others = series.filter(s => (s.KPI / total) < props.max?.otherWhen);
        if (others.length > 0) {
            allseries[index] = [
                ...series.filter(s => (s.KPI / total) >= props.max?.otherWhen),
                {
                    Label: Trad("others"),
                    Value: others.map(o => o.Value),
                    KPI: others.reduce((acc, val) => acc + val.KPI, 0),
                }
            ];
        }
    }
    else if (series.length > maxi) {
        allseries[index] = [
            ...series.slice(0, maxi),
            (!props.max?.hide ? series.slice(maxi).reduce(
                (acc, val) => ({
                    ...acc,
                    KPI: acc.KPI + val.KPI,
                }),
                {
                    Label: Trad("others"),
                    Value: Trad("others"),
                    KPI: 0,
                }
            ) : undefined),
        ].filter(Boolean);
    }
    return allseries[index];
}

const colorSeries = (series: DataValue[], color: string, props: TChartContainerProps, isSingleDataSet: boolean, isDate: boolean) => {
    const dimension = firstOrDefault(toArray(props.dimension));
    if (!props?.chartSeriesItemProps?.color) {
        if (dimension.field === propertyOf<ref_Messages>("Status"))
            series.forEach((s) => (s.color = dico[s.Value]));
        else if (isSingleDataSet)
            series.forEach((s, i) => {
                s.color = colors[i % colors.length];
            });
        else {
            series.forEach((s, i) => {
                s.color = color;
            });
        }
    } else {
        series.forEach((s, i) => {
            s.color = color;
        });
    }

    const field = dimension.field;
    if (props?.filters?.[field]) {
        const value = props.filters[field];
        series.forEach((s, i) => {
            if (isDate) {
                if (!isBetween(new Date(s.Origin), new Date(props.filters?.Start), new Date(props.filters?.End))) {
                    s.color = selectedColors[s.color];
                }
            } else {
                if (!(s.Value === value || (Array.isArray(value) && value.includes(s.Value) || (Array.isArray(value) && Array.isArray(s.Value) && includeArrays(value, s.Value))))) {
                    s.color = selectedColors[s.color];
                }
            }
        });
    }
}

export class ChartContainer extends React.Component<TChartContainerProps, TChartContainerState> {

    constructor(props: TChartContainerProps) {
        super(props);
        this.state = {
            series: undefined,
            isDate: false,
        };
    }

    async componentDidMount() {
        const field = firstOrDefault(toArray(this.props.dimension)).field;
        const isDate = toArray(this.props.dimension).find(d => d.field === "Start" || d.field === "End") !== undefined;

        this.setState({ isDate });

        const mgr = new BasicAggregateManager();
        mgr.dimension = this.props.dimension;
        mgr.customKPI = this.props.customKPI;
        mgr.Indicateur = this.props.Indicateur;
        mgr.KPI = this.props.KPI;
        mgr.KPIType = this.props.KPIType;
        // remove field from filters
        mgr.filters = { ...this.props.filters };
        if (isDate) {
            mgr.filters["Start"] = this.props.start;
            mgr.filters["End"] = this.props.end;
        } else if (this.props.chartFilters?.[field]) {
            delete mgr.filters[field];
        }
        mgr.start = this.props.start;
        mgr.end = this.props.end;
        try {
            let allseries = await mgr.get();
            const isSingleDataSet = allseries.length === 1;
            for (let index = 0; index < allseries.length; index++) {
                let series = allseries[index];

                if (this.props.fixed)
                    fixSeries(series, this.props.fixed, isDate);
                else if(!this.props.flatten)
                    series = maxSeries(series, index, allseries, (this.props.max?.nb ?? MAX), this.props);

                colorSeries(series, colors[index % colors.length], this.props, isSingleDataSet, isDate);
            }

            this.setState({ series: allseries });
        } catch (error) {
            console.log(`error charts`, error);
        }
    }

    flatName = (e: DataValue) => {
        return {...e, Label: `${e.Label} ${e.Value}`};
    }

    renderTooltip = (context: any, series?: TChartContainerState["series"]) => {
        const { isDate } = this.state;
        const { category, categoryIndex, value, percentage: originalPercentage } = context.point || context;
        let percentage = originalPercentage;
        if (!percentage && this.props.budget) {
            percentage = (value / this.props.budget);
        }
        const isNumber = typeof percentage === "number";
        const serieValue = (this.props.flatten ? series.flat().map(this.flatName) : series.flat()).find(s => s.Label === category && s.KPI === value);

        let tempPer = GetKPITemplate(eKPIType.Percent);

        if (this.props.customKPI) {
            let val = value.toLocaleString(GetCurrentLocale(), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
            return (
                <div>
                    {Trad(category)} - {val}{isNumber ? ` - ${tempPer(Number(percentage))}` : ""}
                </div>
            );
        }

        const formatValue = FormatNumber(value / 1000);

        return (
            <div>
                {isDate && series.length > 1 ? `${serieValue?.Value} ` : ""}{isDate ? getMonthName((moment(serieValue.Origin).month() + 1).toString()) : category} {formatValue + "k"} {isNumber ? `${tempPer(Number(percentage))}` : ""}
            </div>
        );
    };

    handleSeriesClick = (event: SeriesClickEvent) => {
        let { onSeriesClick } = this.props;
        let { series, isDate } = this.state;

        const dataValue = (this.props.flatten ? series.flat().map(this.flatName) : series.flat()).find((e) => e.Label === event.category);
        if (isDate) {
            const endofmonth = new Date(dataValue.Origin);
            setEndOfMonth(endofmonth);
            onSeriesClick?.([{field: "Start", value: new Date(dataValue.Origin).toISOString()}, {field: "End", value: endofmonth.toISOString()}]);
        } else {
            const field = firstOrDefault(toArray(this.props.dimension)).field;
            onSeriesClick?.([{field, value: dataValue.Value}]);
        }
    }

    labelContent = (dataItem: any) => {
        let value = dataItem.value;
        if (typeof value === "number" && this.props.axis?.kilo) {
            let budget = value / 1000;
            return `${FormatNumber(budget)}k`.replaceAll(" ", " ");
        }
        if (toArray(this.props.dimension).length !== 1 && typeof value === "string") {
            return value.split(" ").join("\n");
        }
        return typeof value === "number" ? FormatNumber(value).replaceAll(" ", " ") : value;
    };

    render() {
        let { series } = this.state;

        if (!series) return <Loader />;

        if (series?.length === 0)
            return <div className="no-data-graph">{Trad("no_data_for_filters")}</div>;
        return (
            <Chart style={{ height: "100%" }} onSeriesClick={this.handleSeriesClick}>
                <ChartTitle visible={false} {...this.props.titleProps} font="bold 14px Rubik, sans-serif" />
                <ChartTooltip render={(e) => this.renderTooltip(e, series)} />
                <ChartLegend position="bottom" {...this.props.legendProps} />
                <ChartSeries>
                    {this.props.flatten ? (
                        <ChartSeriesItem
                            type={this.props.type}
                            data={series.flat().map(this.flatName) || []}
                            field="KPI"
                            categoryField="Label"
                            {...this.props.chartSeriesItemProps}
                        />
                    ) : series.map((s, key) => (
                        <ChartSeriesItem
                            key={key}
                            type={this.props.type}
                            data={s || []}
                            field="KPI"
                            categoryField="Label"
                            {...this.props.chartSeriesItemProps}
                        />
                     ))}
                </ChartSeries>
                <ChartAxisDefaults labels={{ font: "12px Rubik, sans-serif", content: this.labelContent }} />
            </Chart>
        );
    }
}
