import * as React from "react";
import { Grid } from "@material-ui/core";
import { LEGOCrons } from "hub-lib/dto/admin/ref_Crons.bin";
import { Trad, TradClassName } from "trad-lib";
import { getIcon, VertexAutocomplete } from "adwone-lib/index";
import { ref_CustomersExtended } from "hub-lib/dto/referential/ref_CustomersExtended.bin";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { TooltipManager } from "../../CustomTooltip";
import { FormatDate } from "tools-lib";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory"
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { MessageAggregator } from "hub-lib/aggregator/MessageAggregator";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { Client } from "hub-lib/client/client.bin";
import { src_AdwOne } from "hub-lib/models/orientdb/src_AdwOne.bin";

const styleRow: React.CSSProperties = {
    height: '100%',
    alignContent: 'center',
    display: 'grid'
}

type LEGOConfiguration = {
    cron: LEGOCrons,
    modeleActivated?: boolean,
    onChange: (cron: LEGOCrons) => void
}

export function LEGOConfiguration({ cron, onChange, modeleActivated }: LEGOConfiguration) {

    const [collection, setCollection] = React.useState<string>(null);

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (cron.TaskParams?.Customer && !collection) {
                const [customer] = await Client.searchVertexTyped(ref_CustomersExtended, { "@rid": cron.TaskParams.Customer });
                const [src] = await Client.searchVertexTyped(src_AdwOne, { "@rid": customer.Sources });
                setCollection(src.URI);
            }
        });

    }, [cron.TaskParams?.Customer]);

    console.log(`[LEGOConfiguration]`, cron);

    const lastUpdate = cron.TaskParams.LastMessagesIndicateursUpdate;
    const indicateurs = cron.TaskParams.MessagesIndicateurs ?? [];
    return <Grid container style={{ marginBottom: 12 }}>
        <Grid item xs={6} style={{ paddingRight: 6 }}>
            <VertexAutocomplete
                disableClearable
                type={ref_CustomersExtended.name}
                params={{ Authorization: "MediaManager", properties: ["*", "Company.Name as Name"] }}
                label={Trad("customer")}
                onResetValue={(options: any) => undefined as any}
                defaultValue={options => options.find(e => e["@rid"] == cron.TaskParams?.Customer)}
                onChange={(client) => {
                    cron.TaskParams.Customer = client?.["@rid"];
                    onChange(cron);
                }}
            />
        </Grid>
        <Grid item xs={6} style={{ paddingRight: 6 }}>

            <CustomIconButton
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("refresh_global_agreement") })}
                style={{ float: "right" }}
                className="custom_btn_secondary popover_button"
                onClick={async () => {
                    // const message = store.getState().messageEditor.get();
                    // await DiscountManager.initGlobalAgreement(message);
                    // dispatch(setMessage(message));
                    if (cron.TaskParams.MessagesIndicateurs) {
                        if (!cron.TaskParams.HistoryMessagesIndicateurs)
                            cron.TaskParams.HistoryMessagesIndicateurs = [];
                        cron.TaskParams.HistoryMessagesIndicateurs.push({
                            indicateurs: cron.TaskParams.MessagesIndicateurs,
                            date: cron.TaskParams.LastMessagesIndicateursUpdate
                        });
                    }

                    cron.TaskParams.MessagesIndicateurs = await (AggregatorFactory.GetInstance().Get(ref_Messages) as MessageAggregator).ProvideLEGOIndicateurs();
                    cron.TaskParams.LastMessagesIndicateursUpdate = new Date();
                    console.log(`[LEGOConfiguration] NEW`, cron);
                    onChange(cron);
                }}>
                {getIcon("cached")}
            </CustomIconButton>

            <div className="adw-row" style={styleRow}>
                <div>{`Nombre indicateurs: ${indicateurs.length}`}</div>
                <div>{`Dernière mise à jour: ${lastUpdate ? FormatDate(lastUpdate) : 'jamais'}`}</div>
            </div>
        </Grid>
        {modeleActivated && collection &&
            <Grid item xs={6} style={{ paddingRight: 6 }}>
                <VertexAutocomplete
                    disableClearable
                    type={ref_TableConfigurations.name}
                    params={{
                        collection: collection,
                        getAll: true,
                        Active: true,
                        properties: ["*"]
                    }}
                    getOptionLabel={(option: ref_TableConfigurations) => `${option?.Name} - ${option?.Owner}`}
                    label={TradClassName(ref_TableConfigurations.name)}
                    onChange={(options: ref_TableConfigurations) => {
                        cron.TaskParams.MessagesIndicateurs = options.Columns;
                        cron.TaskParams.LastMessagesIndicateursUpdate = new Date();
                        onChange(cron);
                    }}
                />
            </Grid>}
    </Grid>
}