import * as React from "react";

import Loader from "../../layout/Loader";
import { Trad, TradClassName, TradMultiLang } from "trad-lib";
import { Trad as TradClass } from "hub-lib/dto/admin/Trad.bin"
import { AdwTelerikGrid } from "../Generic/AdwTelerikGrid.bin";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { ADWColumn, AdwRow } from "adwone-lib/index";

import PopupLanguages from "./PopupLanguages.bin";
import { propertyOf } from "hub-lib/tools.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { IsDebugMode } from "../../../utils/localstorage.bin";
import { NewTranslation } from "./NewTranslation.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { BreadcrumbsCustom } from "../../BreadcrumbsCustom";
import { styleGridContainer } from "../../../styles/theme";
import { Client } from "hub-lib/client/client.bin";
import { AdwAutocomplete } from "adwone-lib/index";
import { redirections } from "../../../configuration.bin";
import { GenericPopover } from "../../ConfigurableComponents/GenericPopover.bin";
import ReactJson from "react-json-view";
import { BreadcrumbsCustomContainer, ToolbarAdw } from "../Generic/ToolbarAdw";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";

class TState {
    currentCollection?: string;
    collections: string[];
    gridStatic: VertexGrid<TradClass>;
    gridDynamic: VertexGrid<TradClass>;
    viewMode: "api_translation" | "data_translation" = "api_translation";
}

let anchorId = 0;
const savedRedirection = redirections[Trad.name];

export function TranslateGrid() {

    const [state, setState] = React.useState<TState>(new TState());

    React.useEffect(() => {
        if (!state?.gridDynamic || !state?.gridStatic) {
            Promise.resolve().then(async () => {

                if (state?.currentCollection) redirections[Trad.name] = null;
                else redirections[Trad.name] = savedRedirection;

                const collections = await Client.getElement<string[]>("/TradCollections");
                setState({
                    ...state,
                    collections,
                    gridStatic: createGrid("static"),
                    gridDynamic: createGrid("dynamic")
                })
            })
        }
    })

    const createGrid: (Type: "static" | "dynamic") => VertexGrid<TradClass> = (Type) => {
        let columns: ADWColumn<TradClass>[] = [];
        let hiddenProperties: string[] = [];

        /** Loupe */
        const openLogPath: any = "openLog";
        const openLog = new ADWColumn<TradClass>(" ", openLogPath, ePropType.String, false);
        openLog.width = 30;
        openLog.cellValue = (cellValue: any, dataItem?: AdwRow<TradClass>) => {
            const anchorKey = `anchor-${anchorId++}`;
            return <div id={anchorKey}>
                <GenericPopover anchorParent={anchorKey} icon="zoom">
                    <ReactJson src={dataItem.dataItem} displayDataTypes={false} sortKeys displayObjectSize={false} />
                </GenericPopover></div>
        };
        columns.push(openLog);

        /** FR */
        let columnfr = new ADWColumn<TradClass>("Fr", ("TranslationsFR" as any), ePropType.Any, true);
        columnfr.getValue = async (row: TradClass) => {
            return row?.Translations?.["fr-FR"] ?? '';
        }
        columnfr.width = 250
        columns.push(columnfr);
        /** US */
        let columnus = new ADWColumn<TradClass>("Us", ("TranslationsUS" as any), ePropType.Any, true);
        columnus.getValue = async (row: TradClass) => {
            return row?.Translations?.["en-US"] ?? '';
        }
        columnus.width = 250
        columns.push(columnus);
        /** GB */
        let columngb = new ADWColumn<TradClass>("Gb", ("TranslationsGB" as any), ePropType.Any, true);
        columngb.getValue = async (row: TradClass) => {
            return row?.Translations?.["en-GB"] ?? '';
        }
        columngb.width = 250
        columns.push(columngb);
        hiddenProperties.push(propertyOf<TradClass>("Translations"));
        hiddenProperties.push(propertyOf<TradClass>("Type"));
        hiddenProperties.push(propertyOf<TradClass>("Class"));
        hiddenProperties.push(propertyOf<TradClass>("Property"));
        hiddenProperties.push(propertyOf<TradClass>("Value"));

        return new VertexGrid<TradClass>({
            computeCellValues: true,
            objectPrototype: TradClass,
            objectPrototypeSuffixe: "/" + (state?.currentCollection ?? ""),
            devMode: false,
            width: ({
                Code: 280,
                TranslationsFR: 250,
                TranslationsGB: 250,
                TranslationsUS: 250,
                Created_by: 160,
                Updated_by: 160,
                Created_at: 135,
                Updated_at: 135,

            } as any),
            order: ["openLog", "TranslationsFR", "TranslationsGB", "TranslationsUS", "Created_by", "Updated_by", "Created_at", "Updated_at"],
            columns,
            vertexParams: {
                Active: true,
                Type,
                properties: ["*"],
            },
            readOnlyProperties: ["Created_by", "Updated_by", "Created_at", "Updated_at"],
            hiddenProperties,
            rowToVertex: (row, object) => {
                let trad: TradClass = object;
                if (!trad.Translations)
                    trad.Translations = { "fr-FR": "", "en-US": "", "en-GB": "" };
                trad.Translations["fr-FR"] = row["TranslationsFR"];
                trad.Translations["en-US"] = row["TranslationsUS"];
                trad.Translations["en-GB"] = row["TranslationsGB"];

            }
        })
    }
    const onViewChange = (viewMode: typeof state.viewMode) => {
        setState({ ...state, viewMode });
        viewMode === "api_translation" ? state.gridStatic?.UpdateRows() : state.gridDynamic?.UpdateRows();
    };
    if (!state?.gridStatic)
        return <Loader />;

    const gridHeight = state.currentCollection || IsDebugMode() && RightManager.hasRight(eFunctions.Trad, eRights.create) ? styleGridContainer.translationsWithDevMode.height : styleGridContainer.translations.height;

    const loaderHeight = state.currentCollection || IsDebugMode() && RightManager.hasRight(eFunctions.Trad, eRights.create) ? styleGridContainer.translationsLoaderDevMode.height : styleGridContainer.translationsLoader.height;

    return (
        <div style={{ width: '100%' }}>
            <ToolbarAdw>
                <div style={{ textAlign: 'right', width: "100%" }}>
                    <PopupLanguages />
                </div>
                <BreadcrumbsCustomContainer>
                    <BreadcrumbsCustom elements={[
                        { text: Trad("home"), href: "/" },
                        { text: Trad("translation_tools") }
                    ]} />
                </BreadcrumbsCustomContainer>
            </ToolbarAdw>


            <AdwAutocomplete
                options={["AdwOne", ...state.collections]}
                value={state.currentCollection ?? "AdwOne"}
                onChange={(event: any, value: any) => {
                    if (value == "AdwOne")
                        value = undefined;
                    setState({ ...state, currentCollection: value, gridDynamic: null, gridStatic: null });
                }} />

            {(state.currentCollection || IsDebugMode() && RightManager.hasRight(eFunctions.Trad, eRights.create)) && <div style={{ minWidth: "100%", margin: '10px 0' }}>
                <NewTranslation suffixe={"/" + (state?.currentCollection ?? "")} onChange={() => {
                    state.gridStatic?.UpdateRows();
                    state.gridDynamic?.UpdateRows();
                }} />
            </div>}
            {state.viewMode === "api_translation" && <AdwTelerikGrid
                gridHeight={gridHeight}
                loaderHeight={loaderHeight}
                isCopyDisable
                isDeleteDisable={!RightManager.hasRight(eFunctions.Trad, eRights.delete)}
                commandCellArgs={{ isEditable: RightManager.hasRight(eFunctions.Trad, eRights.update) }}
                grid={state.gridStatic} />}

            {state.viewMode === "data_translation" && <AdwTelerikGrid
                gridHeight={gridHeight}
                loaderHeight={loaderHeight}
                isCopyDisable
                isDeleteDisable={!RightManager.hasRight(eFunctions.Trad, eRights.delete)}
                commandCellArgs={{ isEditable: RightManager.hasRight(eFunctions.Trad, eRights.update) }}
                grid={state.gridDynamic} />}

            <FooterCellOptions
                viewMode={state.viewMode}
                onViewChange={onViewChange}
            />
        </div>
    )
}
function FooterCellOptions({ viewMode, onViewChange }) {
    const tabs: Array<any> = [
        { title: 'api_translation', viewMode: 'api_translation' },
        { title: 'data_translation', viewMode: 'data_translation' },
    ];

    const [selected, setSelected] = React.useState<number>(0);
    const tabIndex = tabs.findIndex(tab => tab.viewMode === viewMode);

    React.useEffect(() => {
        setSelected(tabIndex)
    }, [tabIndex])

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
        onViewChange(tabs[e.selected].viewMode);
    };
    return (
        <TabStrip tabContentStyle={{ display: 'none' }} selected={selected} onSelect={handleSelect}>
            {tabs.map((item, index) => {
                return (
                    <TabStripTab
                        title={Trad(item.title)}
                        key={index}>
                    </TabStripTab>
                );
            })}
        </TabStrip>
    );
};