import * as React from "react";

import { DateRangepicker } from "../../../ConfigurableComponents/DateRangepicker"
import { HierarchyComboManager } from "../../HierarchyComponent/HierarchyComponent.bin";
import { Element } from "../../Generic/Common.bin";
import { FilterStorage } from "../../../../utils/localstorage.bin";
import { useDispatch, useSelector } from "react-redux";
import { onChange } from "../../../../redux/referentialTreeSlice";
import { RootState } from "../../../../redux/store";
import { clearEmptyValues, duplicate, toArray } from "hub-lib/tools.bin";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Trad } from "trad-lib";

let timeout: any = null;

export function AdvertiserFiltersComponent() {

    const config = duplicate(useSelector((root: RootState) => root.referentialTree.advertiserFilter));

    const dispatch = useDispatch();

    const onConfChange = (newConfig: any) => {
        dispatch(onChange({ advertiserFilter: clearEmptyValues(newConfig) }));
    }

    return (
        <>
            <Element className="adw-row">
                <DateRangepicker
                    defaultStart={config.Start ?? null}
                    defaultStop={config.End ?? null}
                    handleChangeDate={(e: { start: Date, end: Date }) => {
                        if (e.end) {
                            config.Start = e.start;
                            config.End = e.end;
                            onConfChange;
                        }
                    }}
                />
            </Element>
            <HierarchyComboManager store={config} multi={true} onConfChanged={onConfChange} fullWidth />
            <Element className="adw-row">
                <Checkbox
                    label={Trad("hide_inactive_elements")}
                    value={!toArray(config.Active).includes(false)}
                    onChange={(event) => {
                        config.Active = (event.value) ? true : [true, false];
                        onConfChange(config);
                    }} />
            </Element>
        </>
    )
}

