import * as React from 'react'

export class TPros {
    day :any;
    selected :any;
    select :any;
}

export default class Day extends React.Component<TPros, any>{
    constructor(props :TPros) {
        super(props);
    }
    returnTrueCategory(){
        return this.props.day.catName?.find((e :any)=>e.allDay)?.name ?? this.props.day.catName?.[0]?.name ?? ""
    }
    render() {
        this.returnTrueCategory()
        let {day, selected, select} = this.props;
        let category = this.returnTrueCategory()
        return (
            <div
                className={
                    "day" +
                    (day.isToday ? " today" : "") +
                    (day.isCurrentMonth ? "" : " different-month") +
                    (day.date?.isSame(selected) ? " selected" : "") +
                    (day.hasEvents ? " has-events" : "") + ` ${category}` +
                    (day.isBetween ? " isBetween" : "") +
                    (day.hasPreviousDay ? " hasPreviousDay" : "") +
                    (day.hasNexDay ? " hasNexDay" : "")
                }
                onClick={() => select(day)}
            >
                <div className="day-number">{day.number}</div>
            </div>
        );
    }
}