import { ref_AdvertisingCompanyGroups, ref_AdvertisingCompanyGroupsId } from "./ref_AdvertisingCompanyGroups.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_AdvertisingCompanyGroups, mm_AdvertisingCompanyGroupsId } from "./mm_AdvertisingCompanyGroups.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasAdvertisingCompanyGroupId = rid;

export class vw_mm_HasAdvertisingCompanyGroup extends ReferentialHasViews<src_MMId, mm_AdvertisingCompanyGroupsId, ref_AdvertisingCompanyGroupsId> {
	"@rid"?: vw_mm_HasAdvertisingCompanyGroupId;

}