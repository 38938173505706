import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ref_PivotGridConfigurations } from 'hub-lib/dto/client/ref_PivotGridConfigurations.bin';
import { AggregateExport } from 'hub-lib/models/external.bin';

export interface TcdState {
    currentTCD: AggregateExport;
    pivotGridConfigToShow: ref_PivotGridConfigurations;
}

const initialState: TcdState = {
    currentTCD: null,
    pivotGridConfigToShow: null
}

export const tcdSlice = createSlice({
    name: 'tcd',
    initialState,
    reducers: {
        setCurrentTCD: (state, action: PayloadAction<AggregateExport>) => {
            state.currentTCD = action.payload;
        },
        setPivotGridConfigToShow: (state, action: PayloadAction<ref_PivotGridConfigurations>) => {
            state.pivotGridConfigToShow = action.payload;
        }
    },
    extraReducers: (builder) => {
    },
})

export const { setCurrentTCD, setPivotGridConfigToShow } = tcdSlice.actions
export const tcdReducer = tcdSlice.reducer;