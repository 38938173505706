import { TreeListTextFilter } from "@progress/kendo-react-treelist";
import { Client } from "hub-lib/client/client.bin";
import * as React from "react";
import { Trad, TradProp } from "trad-lib";
import { CustomTreeList } from "../../VertexGrid/Generic/CustomTreeList";
import Template from "../Template";
import { ref_UserSessions } from "hub-lib/dto/client/ref_UserSessions";
import { ref_Customers } from "hub-lib/models/orientdb/ref_Customers.bin";
import { User } from "hub-lib/models/orientdb/User.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { Typed } from "hub-lib/tools.bin";
import { GetCellTemplate } from "format-lib/index.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import Loader from "../Loader";

type UserSessionsRow = {
    id: any;
    "@rid": string;
    label: string;
    children?: UserSessionsRow[];
    expanded?: boolean,
    session?: ref_UserSessions,
    lastUpdate?: Date
}

type UserSessionsWrap = {
    [customer: string]: {
        [user: string]: ref_UserSessions
    }
}

export function UserSessionsGridComponent() {
    const [data, setData] = React.useState<UserSessionsRow[]>(null);

    React.useEffect(() => {
        if (!data) {
            Client.getUserSessions().then((res: UserSessionsWrap) => {
                const customerRids = Object.keys(res);
                const userRids = Object.values(res).flatMap(o => Object.keys(o));

                Promise.all([
                    Client.searchVertexTyped(ref_Customers, { "@rid": customerRids, properties: ['Company.Name as CompanyName'] }),
                    Client.searchVertexTyped(User, { "@rid": userRids })
                ]).then(([customers, users]) => {
                    const userMap = (customerRid: IRid['@rid'], userRid: IRid['@rid']) => {

                        const session: ref_UserSessions = res[customerRid][userRid];
                        const classes = Object.keys(session?.Rights ?? {});

                        if (!session?.Rights)
                            console.log(`[UserSessionsGrid] No rights for ${userRid}`)

                        return Typed<UserSessionsRow>({
                            id: userRid,
                            '@rid': userRid,
                            label: users.find(c => c["@rid"] == userRid)?.name + ` (${session["@rid"]})`,
                            session: session,
                            lastUpdate: session.Updated_at ? new Date(session.Updated_at) : null,
                            children: classes.map(c => Typed<UserSessionsRow>({
                                id: `${userRid}_${c}`,
                                '@rid': '',
                                label: c + ` (${session.Rights[c]?.length ?? 0})`,
                                children: session.Rights[c].map(r => Typed<UserSessionsRow>({
                                    id: `${userRid}_${r["@rid"]}`,
                                    '@rid': r["@rid"],
                                    label: r.Name,
                                    children: []
                                }))
                            }))
                        })
                    }

                    const customerMap = (rid: IRid['@rid']) => {
                        return Typed<UserSessionsRow>({
                            id: rid,
                            '@rid': rid,
                            label: customers.find(c => c["@rid"] == rid)?.['CompanyName'],
                            children: Object.keys(res[rid]).map(u => userMap(rid, u))
                        })
                    }

                    const dataToSet = (Object.keys(res).map(customerMap)).filter(c => c.children.length > 0);

                    // sort data recursively
                    const sortData = (data: UserSessionsRow[]) => {
                        data.sort((a, b) => a.label.localeCompare(b.label));
                        data.forEach(d => d.children && sortData(d.children));
                    }

                    sortData(dataToSet);

                    setData(dataToSet);
                })
            })
        }
    });

    if (data === null)
        return <Loader time text={`${Trad('loading')} `} />

    return <div className='grid_base_container'>
        <CustomTreeList
            expandField='expanded'
            subItemsField='children'
            data={data ?? []}
            rowHeight={30}
            gridProps={{
                className: 'custom-tree-list  link-mgr'
            }}
            columns={[{
                title: TradProp('@rid'),
                field: '@rid',
                filter: TreeListTextFilter,
                className: "no-wrap",
                width: '200px',
                resizable: true,
            }, {
                title: TradProp('Updated_at'),
                field: 'lastUpdate',
                filter: TreeListTextFilter,
                className: "no-wrap",
                cell: props => <CellDate {...props} />,
                width: '150px',
                resizable: true,
            }, {
                title: 'Rights',
                field: 'rights',
                filter: TreeListTextFilter,
                className: "no-wrap",
                width: '200px',
                cell: props => <CellRmRights {...props} />,
                resizable: true,
            },
            {
                title: TradProp('Name'),
                field: 'label',
                filter: TreeListTextFilter,
                expandable: true,
                resizable: true
            }]} />
    </div>
}

function CellRmRights(props) {
    const session: ref_UserSessions = props?.dataItem?.session;

    const [isProcessing, setIsProcessing] = React.useState(false);

    if (isProcessing)
        return <td>loading...</td>

    if (!session)
        return <td></td>

    return <td>
        <button
            onClick={async () => {
                setIsProcessing(true);
                await Client.Post('/deprecatedrights', { userRid: session.User, customerRid: session.Customer });
                session.DeprecatedRights = true;
                setIsProcessing(false);
            }}>rm rights</button></td >
}

function CellDate(props) {
    const session: ref_UserSessions = props?.dataItem?.session;

    if (!session?.Updated_at)
        return <td></td>

    return <td>{GetCellTemplate(ePropType.Datetime)(session.Updated_at)}</td>
}

export const UserSessionsGrid = Template(UserSessionsGridComponent);