

export class Element {
    id: number;
    code: string;
    name: string;
}

export class EstimatesResponse {
    estimates: MMEstimate[]
}

export enum MMStatus {
    ClientValidated = "ClientValidated",
    AgencyValidated = "AgencyValidated",
    Canceled = "Canceled",
    Archived = "Archived",
    Valuation = "Valuation"
}

export enum MMContractStatus {
	Mandataire = "Mandataire" ,
	Commissionnaire = "Commissionnaire"
}

//Estimate API
export type MMEstimate = {
    ids: {
        planSystem: string,
        buySystem: string
    },
    version: number,
    estimateNumber: string,
    name: string,
    company: Element,
    advertiserGroup: Element,
    advertiser: Element,
    brand: Element,
    product: Element,
    sProduct: Element,
    activityType: Element,
    startDate: string,
    endDate: string,
    media: Element,
    campaign: Element,
    contract: Element & {
        transparencyIndicator: boolean,
    },
    order: Element,
    status: MMStatus,
    insertionCount: number
}

//Estimate MasterData
export class mm_Estimates {
    ids: {
        BuySystem: string
    };
    name: string;
    shortName: string;
    version: number;
    groupId: string;
    productId: string;
    advertiserId: string;
    brandId: string;
    description: string;
    endDate: string;
    locationId: string;
    media: Element;
    mediaType: string;
    startDate: string;
    transparency: string;
    department: Element;
    type: Element;
    status: string;
    estimateStatus: MMStatus;
}

export class MMEstimateFilter {
    companyCode: string;
    advertiserGroupCode: string;
    minStartDate: string | Date;

    maxStartDate?: string | Date;
    advertiserCode?: string
    brandCode?: string;
    productCode?: string;
    mediaCode?: string;
    campaignCode?: string;
    status?: string; // "Valuation",
    empty?: boolean;
    interfaceFrom?: string;
}

export class PairEstimates {
    buySystemEstimateCodes: string[];
    planSystemCampaignCode: string
}