import { Trad } from "trad-lib";
import { ConfigurationPanel } from "../ConfigurationPanel";
import { GetHashCode, compareObjects, duplicate, hasConfig } from "hub-lib/tools.bin";
import { Badge } from "@progress/kendo-react-indicators";
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from "../../Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { FiltersComponent } from "../../Filters/FiltersComponent";
import { PivotGridConfiguration } from "./Configuration/PivotGridConfiguration";
import { RootState, store } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { CustomGroupButton } from "../../../ConfigurableComponents/CustomGroupButton.bin";
import { Client } from "hub-lib/client/client.bin";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { ref_PivotGridConfigurations } from "hub-lib/dto/client/ref_PivotGridConfigurations.bin";
import React from "react";
import { setPivotGrid } from "../../../../redux/gridConfigurationsSlice";
import { setPivotGridConfigToShow } from "../../../../redux/tcdSlice";
import { CustomIconButton } from "../../Generic/CustomIconButton";
import { getIcon } from "../../../ConfigurableComponents/IconManager.bin";
import { Notify } from "../../../../utils/Notify.bin";
import { SanitizeFilter } from "hub-lib/models/external.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { FilterBadge } from "../../Filters/MenuItemFilters";

interface CustomPivotGridConfigurationPanelProps {
    typeName: string;
}

export function CustomPivotGridConfigurationPanel(props: CustomPivotGridConfigurationPanelProps) {
    const dispatch = useDispatch();
    // const filters = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
    const config = duplicate(useSelector((root: RootState) => root.columsConfigurations.configurations?.[props.typeName]?.pivotGrid, compareObjects));
    const configToShow = useSelector((root: RootState) => root.tcd.pivotGridConfigToShow);
    const [panelOpen, setPanelOpen] = React.useState(false);

    const setConfigToShow = (config: ref_PivotGridConfigurations) => {
        dispatch(setPivotGridConfigToShow(config));
    }

    const onCancelButtonClick = () => {
        setConfigToShow(config);
    }

    const onApplyButtonClick = () => {
        if (configToShow.Rows.length > 0 && configToShow.Columns.length > 0 && configToShow.Kpis.length > 0) {
            dispatch(setPivotGrid(configToShow));
            setPanelOpen(false);
        }
        else
            Notify(Trad("error_pivotgrid_config_fill_all"), "error");
    }

    React.useEffect(() => {
        setConfigToShow(config);
    }, [GetHashCode(config)])

    const actionContainer = (
        <div className="adw-form-action pivot-grid-configurator-button-row">
            <CustomButton className="custom_btn_primary_cancel" onClick={onCancelButtonClick} Label={Trad("cancel")} />
            <CustomButton className="custom_btn_primary" onClick={onApplyButtonClick} Label={Trad("apply")} />
        </div>
    )

    const confComponent =
        <ConfigurationPanel
            delegateOpenArgs={{ panelOpen, setPanelOpen }}
            elements={[{
                type: "icon",
                title: () => Trad("filters"),
                icon: "filterAlt",
                badge: <FilterBadge objectType={ref_Messages.name} />,
                element: <FiltersComponent objectType={props.typeName} />
            }, {
                type: "icon",
                title: () => Trad("settings"),
                icon: "settings",
                element: (
                    <PivotGridConfiguration
                        configToShow={configToShow}
                        onChange={setConfigToShow}
                    />
                ),
                actionContainer: actionContainer
            }, {
                type: "component",
                component: <CustomIconButton
                    className="no-radius no-shadow icon-panel-item"
                    onClick={() => { Client.downloadExport("formated", { ...store.getState().tcd.currentTCD, type: 'aggregate' }, 'reporting') }}>
                    {getIcon("download")}
                </CustomIconButton>
            }]} />

    return (
        <ToolbarAdw includeDates>
            <BreadcrumbsCustomContainer>
                <BreadcrumbsCustom
                    elements={[{ text: Trad("home"), href: "/" }, { text: Trad("TCD") }]} />
            </BreadcrumbsCustomContainer>
            <ConfigurationPanelContainer>
                {confComponent}
            </ConfigurationPanelContainer>
        </ToolbarAdw>
    )
}