import * as React from "react";
import { IRid } from "hub-lib/models/IRid.bin";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { VertexAutocomplete } from "adwone-lib/index";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import {
  ref_PropertyType,
  ref_PropertyTypeId,
} from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { Client } from "hub-lib/client/client.bin";
import { Trad, TradProp } from "trad-lib";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { HierarchyConfig } from "../HierarchyComponent/HierarchyComponent.bin";
import { distinct, GetHashCode } from "hub-lib/tools.bin";
import { eCompare } from "hub-lib/operators.bin";
import { FavoriteVertexAutoComplete } from "../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { SupportExtended } from "hub-lib/dto/referential/SupportExtended.bin";
import { AdvertisingCompanyExtended } from "hub-lib/dto/referential/AdvertisingCompanyExtended.bin";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { getBadgeLabelFormat, getBadgeLabelPlacement, getDefaultFormatOffer, getFormatOffer, getFormatPriceExistsComponent, getLabelFormat, getLabelPlacement, getPlacementOffer, getPlacementPriceExistsComponent } from "../Messages/MessageEditor/OfferTools";
import { FormatExtended } from "hub-lib/dto/referential/FormatExtended.bin";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { EmplacementExtended } from "hub-lib/dto/referential/EmplacementExtended.bin";

export interface ISupportHierarchy {
  Support?: rid | rid[];
  BroadcastArea?: rid | rid[];
  Format?: rid | rid[];
  Placement?: rid | rid[];
  Currency?: rid | rid[];
  Media?: rid | rid[];
}

export class SupportHierarchy implements ISupportHierarchy {
  Support?: rid | rid[] = undefined;
  BroadcastArea?: rid | rid[] = undefined;
  Format: rid | rid[] = undefined;
  Placement: rid | rid[] = undefined;
  Currency: rid | rid[] = undefined;
  Media: rid | rid[] = undefined;
}

class TProps extends HierarchyConfig<ISupportHierarchy> {

}

class TState {
  store: ISupportHierarchy = new SupportHierarchy();
  disabled: boolean = true;
  formatRid: ref_PropertyTypeId = undefined;
  emplacRid: ref_PropertyTypeId = undefined;
  BroadcastAreasFromSupport: rid[] = [];
  CurrencyFromSupport: rid[] = [];
  MediasFromSupport: rid[] = [];
  keySupport: number = 0;
}

export class HierarchySupportComponent extends React.Component<TProps, TState> {

  constructor(props: TProps) {
    super(props);

    let newState = new TState();

    if (this.props.store) {
      newState.store = this.props.store;
    }

    this.state = newState;
  }

  async componentDidMount() {
    let formatRid = (
      await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Format" })
    )?.data?.results[0]["@rid"];
    let emplacRid = (
      await Client.get<ref_PropertyType>(ref_PropertyType, {
        Type: "Emplacement",
      })
    )?.data?.results[0]["@rid"];
    this.refreshCombos(true);
    this.setState({
      formatRid,
      emplacRid
    });
  }

  isPropertyDisabled = (key: keyof ISupportHierarchy) => {
    return this.props.disableProperty?.[key];
  }

  isMandatory = (key: keyof ISupportHierarchy) => {
    return this.props.mandatory?.[key];
  }

  refreshCombos = async (onInit: boolean = false) => {
    this.setState({ disabled: true });
    let BroadcastAreasFromSupport = [];
    let CurrencyFromSupport = [];
    let MediasFromSupport = [];
    if (this.state.store.Support) {
      const supports: SupportExtended[] = (
        await Client.searchVertex(SupportExtended.name, {
          "@rid": this.state.store.Support,
          properties: ["@rid", "Medias"],
          options: {
            lnkCurrencies: true,
            lnkBroadcastAreas: true,
            lnkAdvertisingCompanies: true,
            traverselnk: true,
            current: true
          }
        })
      )?.data?.results;

      const s = supports[0];

      BroadcastAreasFromSupport = s?.lnkBroadcastAreas?.map(l => l.out) ?? [];
      CurrencyFromSupport = s?.lnkCurrencies?.map(l => l.out);
      MediasFromSupport = s?.Medias ? [...s.Medias] : [];

      const advCompanyRids = distinct(s?.lnkAdvertisingCompanies?.filter(lnk => lnk.Active == true)?.map(lnk => lnk.in), e => e);
      if (advCompanyRids) {
        const advCompanies: AdvertisingCompanyExtended[] = (await Client.searchVertex(AdvertisingCompanyExtended.name, { "@rid": advCompanyRids }))?.data?.results;
        BroadcastAreasFromSupport = [...BroadcastAreasFromSupport, ...advCompanies.map(c => c.lnkBroadcastAreas).reduce((a, b) => [...a, ...b], []).map(l => l.out)];
      }
    }
    if (CurrencyFromSupport?.length >= 1 && !onInit)
      this.state.store.Currency = CurrencyFromSupport[0]
    if (MediasFromSupport?.length >= 1 && !onInit)
      this.state.store.Media = MediasFromSupport[0]
    this.setState({
      keySupport: this.state.keySupport + 1,
      disabled: false,
      BroadcastAreasFromSupport,
      CurrencyFromSupport,
      MediasFromSupport
    });
  };

  render() {
    let {
      store,
      keySupport,
      disabled
    } = this.state;
    return (
      <div>
        <div className="adw-row">
          <FavoriteVertexAutoComplete
            type={ref_Supports.name}
            label={`${TradProp("Support")}${this.isMandatory("Support") ? " *" : ""}`}
            onResetValue={(options: any) => {
              store.BroadcastArea = undefined;
              store.Format = undefined;
              store.Placement = undefined;
              store.Currency = undefined;
              store.Media = undefined;
              return undefined as any;
            }}
            defaultValue={(options: IRid[]) =>
              options.find((v) => v["@rid"] == store.Support)
            }
            params={{
              properties: ["*"],
            }}
            disabled={this.isPropertyDisabled("Support")}
            onChange={async (value: any) => {
              if (value != this.state.store.Support) {
                this.state.store.Support = value?.["@rid"];
                store.BroadcastArea = undefined;
                store.Format = undefined;
                store.Placement = undefined;
                store.Currency = undefined;
                this.forceUpdate();
                await this.refreshCombos();
                this.props?.onConfChanged(store);
              }
            }
            }
          />
        </div>
        <div className="adw-row"
          key={`${store.Support}_${keySupport}_Broadcastareas`}
        >
          <VertexAutocomplete
            type={ref_BroadcastAreas.name}
            label={`${TradProp("BroadcastAreas", ref_Agreements)}${this.isMandatory("BroadcastArea") ? " *" : ""}`}
            onResetValue={(options: any) => [] as any}
            afterLoadFilter={(broadcastareas: IRid[]) =>
              broadcastareas?.filter((m) => {
                if (
                  store.Support &&
                  this.state?.BroadcastAreasFromSupport?.length > 0
                ) {
                  return this.state?.BroadcastAreasFromSupport.includes(
                    m["@rid"]
                  );
                } else {
                  return true;
                }
              })
            }
            disabled={(!store.Support && !this.isPropertyDisabled("BroadcastArea")) || disabled}
            //disabled={(!store.Support && !agreement.Global) || disableHchySupp}
            defaultValue={(options: any[]) =>
              options.filter((v) => store?.BroadcastArea?.includes(v["@rid"]))
            }
            params={{
              properties: ["*"],
            }}
            multiple
            onChange={(value: any) => {
              this.state.store.BroadcastArea = value.map(
                (e: ref_BroadcastAreas) => e["@rid"]
              );
              this.props?.onConfChanged(store);
              this.forceUpdate();
            }}
          />
        </div>
        <div className="adw-row" key={`${store.Support}_${keySupport}_Formats`} >
          <FormatPicker store={store} disabled={disabled} hierarchySupportComponent={this} />
        </div>
        <div className="adw-row" key={`${store.Support}_${keySupport}_Placements`} >
          <PlacementPicker store={store} disabled={disabled} hierarchySupportComponent={this} />
        </div>
        <div className="adw-row"
          key={`${store.Support}_${keySupport}_Currency`}
        >
          <VertexAutocomplete
            type={ref_Currencies.name}
            label={`${TradProp("Currency", ref_Agreements)}${this.isMandatory("Currency") ? " *" : ""}`}
            onResetValue={(options: any) => undefined as any}
            afterLoadFilter={(currencies: IRid[]) =>
              currencies.filter((m) => {
                if (
                  store.Support &&
                  this.state?.CurrencyFromSupport?.length > 0
                ) {
                  return this.state.CurrencyFromSupport.includes(m["@rid"]);
                } else {
                  return true;
                }
              })
            }
            disabled={(!store.Support && !this.isPropertyDisabled("Currency")) || disabled}
            //disabled={(!store.Support && !agreement.Global) || disableHchySupp}
            defaultValue={(options: IRid[]) =>
              options.find((v) => v["@rid"] == store.Currency)
            }
            params={{
              properties: ["@rid", "Name"],
            }}
            onChange={(value: any) => {
              store.Currency = value?.["@rid"];
              this.props?.onConfChanged(store);
              this.forceUpdate();
            }}
          />
        </div>
      </div>
    );
  }
}

type FormatPickerProps = {
  store: ISupportHierarchy;
  disabled: boolean;
  hierarchySupportComponent: HierarchySupportComponent;
}

function FormatPicker({ store, disabled, hierarchySupportComponent }: FormatPickerProps) {
  const storeKey = "offer";
  const [currencies, setCurrencies] = React.useState<ref_Currencies[]>(null);
  const offers = useSelector((state: RootState) => state.offer.offers);

  React.useEffect(() => {
    if (!currencies)
      KPIsManagerCache.GetCurrencies().then(c => setCurrencies(c));
  })

  return <FavoriteVertexAutoComplete
    key={`FavoriteVertexAutoComplete-formats_${storeKey}_${GetHashCode(offers?.map(o => o["@rid"]))}`}
    type={FormatExtended.name}
    label={`${TradProp("Formats", ref_Agreements)}${hierarchySupportComponent.isMandatory("Format") ? " *" : ""}`}
    onResetValue={(options: any) => [] as any}
    disabled={!store.Support || disabled || hierarchySupportComponent.isPropertyDisabled("Support")}
    //disabled={!store.Support || disableHchySupp || agreement.Global}
    afterLoadFilter={(formats: any[]) =>
      formats?.filter((F) => {
        if (
          store.Support &&
          hierarchySupportComponent.state?.MediasFromSupport?.length > 0
        ) {
          return F.Medias.some(media => hierarchySupportComponent.state?.MediasFromSupport?.includes(media))
        } else {
          return true;
        }
      })
    }
    multiple
    defaultValue={(options: any[]) =>
      options.filter((v) => store?.Format?.includes(v["@rid"]))
    }
    params={{
      properties: ["*"],
      "_operators": [{
        property: "PropertyType",
        value: hierarchySupportComponent.state.formatRid,
        compare: eCompare.Contains
      }],
    }}
    onChange={(value: any) => {
      store.Format = value.map(
        (e: ref_Property) => e["@rid"]
      );
      hierarchySupportComponent.props?.onConfChanged(store);
      hierarchySupportComponent.forceUpdate();
    }}
    leftColumns={[getFormatPriceExistsComponent(storeKey)]}
    getCategory={(o: FormatExtended) => getDefaultFormatOffer({ format: o?.Format, couleur: o?.Couleur, storeKey }) ? Trad("other_with_price") : Trad("other_with_no_price")}
    SortCategories={[Trad("other_with_price"), Trad("other_with_no_price")]}
    getOptionLabel={(f: FormatExtended) => getLabelFormat(f, currencies, storeKey)}
    getOptionLabelComponent={(f: FormatExtended) => getBadgeLabelFormat(f, currencies, storeKey)}
    Sort={(a, b, groupA, groupB) => {
      const tradFav = Trad("favorite");
      if (groupA === tradFav && groupB === tradFav) {
        const offerA = getDefaultFormatOffer({ format: a?.Format, couleur: a?.Couleur, storeKey });
        const offerB = getDefaultFormatOffer({ format: b?.Format, couleur: b?.Couleur, storeKey });
        if (offerA && !offerB) return -1;
        if (!offerA && offerB) return 1;
        return 0;
      }
      return undefined;
    }}
  />
}

function PlacementPicker({ store, disabled, hierarchySupportComponent }: FormatPickerProps) {

  const [currencies, setCurrencies] = React.useState<ref_Currencies[]>(null);
  const offers = useSelector((state: RootState) => state.offer.offers);

  React.useEffect(() => {
    if (!currencies)
      KPIsManagerCache.GetCurrencies().then(c => setCurrencies(c));
  })

  const storeKey = "offer";
  return <FavoriteVertexAutoComplete
    type={EmplacementExtended.name}
    key={`FavoriteVertexAutoComplete-placements_${storeKey}_${GetHashCode(offers?.map(o => o["@rid"]))}`}
    label={`${TradProp("Placements", ref_Agreements)}${hierarchySupportComponent.isMandatory("Placement") ? " *" : ""}`}
    afterLoadFilter={(emplacements: any[]) =>
      emplacements.filter((p) => {
        if (
          store.Support &&
          hierarchySupportComponent.state.MediasFromSupport.length > 0
        ) {
          return p.Medias.some(media => hierarchySupportComponent.state?.MediasFromSupport?.includes(media))
        } else {
          return true;
        }
      })
    }
    multiple
    onResetValue={(options: any) => [] as any}
    disabled={!store.Support || disabled || hierarchySupportComponent.isPropertyDisabled("Placement")}
    //disabled={!store.Support || disableHchySupp || agreement.Global}
    defaultValue={(options: any[]) =>
      options.filter((v) => hierarchySupportComponent.state.store?.Placement?.includes(v["@rid"]))
    }
    params={{
      properties: ["*"],
      "_operators": [{
        property: "PropertyType",
        value: hierarchySupportComponent.state.emplacRid,
        compare: eCompare.Contains
      }],
    }}
    onChange={(value: any) => {
      store.Placement = value.map(
        (e: ref_Property) => e["@rid"]
      );
      hierarchySupportComponent.props?.onConfChanged(store);
      hierarchySupportComponent.forceUpdate();
    }}
    leftColumns={[getPlacementPriceExistsComponent(storeKey)]}
    getCategory={(o: EmplacementExtended) => getPlacementOffer(o?.Emplacement, null, storeKey) ? Trad("other_with_price") : Trad("other_with_no_price")}
    SortCategories={[Trad("other_with_price"), Trad("other_with_no_price")]}
    getOptionLabelComponent={(p) => getBadgeLabelPlacement(p, currencies, storeKey)}
    Sort={(a, b, groupA, groupB) => {
      const tradFav = Trad("favorite");
      if (groupA === tradFav && groupB === tradFav) {
        const offerA = getPlacementOffer(a.Emplacement, null, storeKey);
        const offerB = getPlacementOffer(b.Emplacement, null, storeKey);
        if (offerA && !offerB) return -1;
        if (!offerA && offerB) return 1;
        return 0;
      }
      return undefined;
    }}
  />
}
