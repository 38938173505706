
import * as React from "react";

export interface ISerializable {
    save(): string;
    load(): string;
}

export class TProps {
    // element key
    key: React.Key

    // x position
    x: number;

    // y position
    y: number;

    // width
    w: number;

    // height
    h: number;

    title?: string;

    children?: React.ReactElement<ISerializable>
}

export class CustomLayout extends React.Component<TProps, any> {

    static defaultProps = {
        title: "Title"
    }

    render() {

        return (
            <div style={{ height: "100%" }}>
                <div className="title-CustomLayout">{this.props.title}</div>
                <div className="react-grid-item-content">
                    {this.props.children}
                </div>
            </div>)
    }
}