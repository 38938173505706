import * as React from 'react'
import { Trad } from 'trad-lib';
import { ADWGrid, eRowEvent, INode, AdwRow } from './ADWGrid';

class TPropsBase<TRow extends INode<TRow> & { expanded?: boolean, selected?: boolean }> {
    grid: ADWGrid<TRow>
}

export class TStateBase<TRow> {
    // this is the total row of the grid
    totalRow: AdwRow<TRow>;
    // the total row is loading, if true, it means that the grid is loading the total row and the current total row is not up to date
    totalRowLoading: boolean = false;

    rows: AdwRow<TRow>[];
    loading: boolean = false;
    versionGridComponent: number = 0;
}

let beg: number = 0;

export abstract class ADWGridComponent<TRow, TProps extends TPropsBase<TRow>, TState extends TStateBase<TRow>> extends React.Component<TProps, TState> {

    async afertComputeRows(rows: AdwRow<TRow>[]) {
        // nothing here
    }

    abstract DrawComponent(): JSX.Element;

    constructor(props: TProps) {
        super(props);

        this.state = this.initializeState();
        this.componentDidMount();
    }

    abstract initializeState(): TState;

    componentDidMount() {
        this.componentWillUnmount();
        this.props.grid.onRowsChanged.addListener(eRowEvent.loading, this.loadingRows);
        this.props.grid.onRowsChanged.addListener(eRowEvent.rowsAdded, this.computeRows);
        this.props.grid.onRowsChanged.addListener(eRowEvent.sourceChanged, this.computeRows);
        this.props.grid.onColumnsReordered.addListener("reorder", this.incrementVersion);
    }

    componentWillUnmount() {
        this.props.grid.onRowsChanged.removeListener(eRowEvent.loading, this.loadingRows);
        this.props.grid.onRowsChanged.removeListener(eRowEvent.rowsAdded, this.computeRows);
        this.props.grid.onRowsChanged.removeListener(eRowEvent.sourceChanged, this.computeRows);
        this.props.grid.onColumnsReordered.removeListener("reorder", this.incrementVersion);
    }

    incrementVersion = () => {
        this.setState({ versionGridComponent: this.state.versionGridComponent + 1 })
    }

    computeRows = () => {
        this.props.grid.Compute().then(async ({ rows, totalRow }) => {
            await this.afertComputeRows(rows);
            this.setState({ rows, totalRow, loading: false }, this.rowInitialized);
        });
    }

    loadingRows = () => {
        this.setState({ loading: true });
    }

    rowInitialized = () => {
        // nothing here
    }

    render = () => {
        beg = Date.now();
        return <div key={`grid-component-key-${this.state?.versionGridComponent}`}>
            {this.DrawComponent()}
        </div>

    }
}

