

export function EncapsulatedMethod(previous: (propertyName, args, caller) => any, next: (propertyName, args, res, caller) => any, returnnext: boolean = false) {

    return function (
        target: any,
        propertyName: string,
        propertyDesciptor: PropertyDescriptor): PropertyDescriptor {

        const method = propertyDesciptor.value;
        let caller = target.constructor.name;

        propertyDesciptor.value = function (...args: any[]) {

            previous(propertyName, args, caller)

            // invoke greet() and get its return value
            let result: any = method.apply(this, args);

            if (result && typeof result["then"] === 'function') {

                let prom: Promise<any> = result;
                result = prom.then(r => {
                    let retnext = next(propertyName, args, r, caller);
                    if (returnnext) return retnext;
                    return r;
                });

            } else {
                let retnext = next(propertyName, args, result, caller);
                if (returnnext) return retnext;
            }

            // return the result of invoking the method
            return result;
        }
        return propertyDesciptor;
    }
}

class CustomError extends Error {
    error: any;
    constructor(_error: any) {
        super(_error?.toString());
        this.error = _error;
    }
}

export function ErrorHandler(msg: any) {
    return ErrorCatching((error, propertyName, args) => {
        throw new CustomError({ msg, propertyName, args, error, toString: () => msg });
    });
}

export function ErrorCatching(error: (reason: any, propertyName: string, args: any[], context: any) => any) {

    return function (
        target: Object,
        propertyName: string,
        propertyDesciptor: PropertyDescriptor): PropertyDescriptor {

        const method = propertyDesciptor.value;
        propertyDesciptor.value = function (...args: any[]) {

            let result: any = undefined;
            try {
                result = method.apply(this, args);
                if (result && typeof result["then"] === 'function') {
                    let prom: Promise<any> = result;
                    result = prom.catch(e => error(e, propertyName, args, this))
                }
            }
            catch (e) {
                error(e, propertyName, args, this);
            }

            // return the result of invoking the method
            return result;
        }
        return propertyDesciptor;
    }
}

export function EncapsulatedMethodAsync(previous: (propertyName, args, store) => Promise<any>, next: (...p) => Promise<any>, error?: (...p) => Promise<any>) {

    return function (
        target: Object,
        propertyName: string,
        propertyDesciptor: PropertyDescriptor): PropertyDescriptor {

        const method = propertyDesciptor.value;

        propertyDesciptor.value = function (...args: any[]) {
            return new Promise((resolve, reject) => {

                const store = {}
                const catchException = ex => {
                    if (error != undefined) error.apply(this, [propertyName, args, ex, store]).then(r => reject(ex)).catch(reject);
                    else reject(ex);
                }

                previous.apply(this, [propertyName, args, store]).then(r => {

                    // invoke greet() and get its return value
                    let result: any = method.apply(this, args);

                    if (result && typeof result["then"] === 'function') {

                        let prom: Promise<any> = result;
                        prom.then(res => {
                            next.apply(this, [propertyName, args, res, store]).then(() => resolve(res)).catch(reject);
                        }).catch(catchException);

                    } else {
                        next.apply(this, [propertyName, args, result, store]).then(resolve).catch(reject);
                    }
                }).catch(catchException)
            })

        }
        return propertyDesciptor;
    }
}