import { Trad } from "trad-lib";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { BreadcrumbsCustomContainer, ToolbarAdw } from "../../../VertexGrid/Generic/ToolbarAdw";
import { NielsenMediaDeliveryFrequence, ref_NielsenClientSubscription } from "hub-lib/dto/admin/pigedataprocessing/ref_NielsenClientSubscription";
import Template from "../../Template";
import React from "react";
import { Client } from "hub-lib/client/client.bin";
import { Grid, TextField } from "@material-ui/core";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { SimpleDatePicker } from "../../../ConfigurableComponents/SimpleDatepicker.bin";
import { Nielsen_Major_Category } from "hub-lib/models/orientdb/Nielsen_Major_Category.bin";
import { Nielsen_Mid_Category } from "hub-lib/models/orientdb/Nielsen_Mid_Category.bin";
import { Nielsen_Minor_Category } from "hub-lib/models/orientdb/Nielsen_Minor_Category.bin";
import { Nielsen_Advertiser } from "hub-lib/models/orientdb/Nielsen_Advertiser.bin";
import { Nielsen_Brand } from "hub-lib/models/orientdb/Nielsen_Brand.bin";
import { Nielsen_Brand_Product } from "hub-lib/models/orientdb/Nielsen_Brand_Product.bin";
import { Nielsen_Product } from "hub-lib/models/orientdb/Nielsen_Product.bin";
import { Nielsen_Media_Type } from "hub-lib/models/orientdb/Nielsen_Media_Type.bin";
import { Nielsen_Media_Channel } from "hub-lib/models/orientdb/Nielsen_Media_Channel.bin";
import Loader from "../../Loader";
import { ContainerComponent } from "../../../VertexGrid/Generic/ContainerComponent";
import { MultiSelect, MultiSelectPageChangeEvent } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { AdwAutocomplete } from "adwone-lib";
import { Notify } from "../../../../utils/Notify.bin";

interface NielsenNomenclature {
    data: any[];
    filteredData: any[];
    subsetData: any[];
    skip: number;
    total: number;
    subscriptionKey: string;
}

const pageSize = 50;

const NielsenClientSubscription = () => {
    const [subscriptions, setSubscriptions] = React.useState<ref_NielsenClientSubscription[]>([]);
    const [nomenclatures, setNomenclatures] = React.useState<Map<string, NielsenNomenclature>>(new Map());

    const nomencToGet = [
        { name: Nielsen_Major_Category.name, subscriptionKey: "majorCategoriesToInclude" },
        { name: Nielsen_Mid_Category.name, subscriptionKey: "midCategoriesToInclude" },
        { name: Nielsen_Minor_Category.name, subscriptionKey: "minorCategoriesToInclude" },
        { name: Nielsen_Advertiser.name, subscriptionKey: "advertisersToInclude" },
        { name: Nielsen_Brand.name, subscriptionKey: "brandsToInclude" },
        //{ name: Nielsen_Brand_Product.name, subscriptionKey: "brandProductsToInclude" },
        //{ name: Nielsen_Product.name, subscriptionKey: "productsToInclude" },
        { name: Nielsen_Media_Type.name, subscriptionKey: "mediaTypesToInclude" },
        { name: Nielsen_Media_Channel.name, subscriptionKey: "mediaChannelsToInclude" }
    ]

    React.useEffect(() => {
        const action = async () => {
            const tmpNomenclatures = new Map<string, NielsenNomenclature>();

            const res1 = await Client.searchVertex(ref_NielsenClientSubscription.name);
            setSubscriptions(res1.data.results);

            for (let i = 0; i < nomencToGet.length; i++) {
                const name = nomencToGet[i];
                const res = await Client.searchVertex(name.name);
                const results = res.data.results;
                const sortedResults = results.sort((a: any, b: any) => a.FrenchLabel.localeCompare(b.FrenchLabel));
                const finalResults = sortedResults.map((result: any) => {
                    return { ...result, FrenchLabel: `${result.FrenchLabel} (${result.ExternalID})` };
                });

                tmpNomenclatures.set(name.name, { data: finalResults, filteredData: finalResults, subsetData: finalResults.slice(0, pageSize), skip: 0, total: results.length, subscriptionKey: name.subscriptionKey });
            }

            setNomenclatures(tmpNomenclatures);
        }

        action();
    }, []);

    const allNomencLoaded = () => {
        return nomenclatures.size === nomencToGet.length;
    }

    const onAddClick = () => {
        setSubscriptions([...subscriptions, new ref_NielsenClientSubscription()]);
    }

    const onChange = (e: any) => {
        const splitTarget = e.target.id.split('_');
        const field = splitTarget[0];
        const index = parseInt(splitTarget[1]);
        const newSubscriptions = [...subscriptions];

        newSubscriptions[index][field] = e.target.value;
        setSubscriptions(newSubscriptions);
    }

    const onCheckboxChange = (e: any, index: number) => {
        const newSubscriptions = [...subscriptions];
        newSubscriptions[index].spotaspot = e.target.value;
        setSubscriptions(newSubscriptions);
    }


    const onDateChange = (date: any) => {
        const splitTarget = date.target.props.id.split('_');
        const field = splitTarget[0];
        const index = parseInt(splitTarget[1]);
        const value = new Date(date.target.value);
        const newSubscriptions = [...subscriptions];

        newSubscriptions[index][field] = value.getFullYear() * 10000 + (value.getMonth() + 1) * 100 + value.getDate();
        setSubscriptions(newSubscriptions);
    }

    const onFilterChange = (nomenclature: NielsenNomenclature, name: string, e: any) => {
        const filteredData = nomenclature.data.filter((item) => item.FrenchLabel.toLowerCase().includes(e.filter.value.toLowerCase()));
        const newNomenclatures = new Map(nomenclatures);

        newNomenclatures.set(name, { ...nomenclature, filteredData: filteredData, subsetData: filteredData.slice(0, pageSize), skip: 0, total: filteredData.length });
        setNomenclatures(newNomenclatures);
    }

    const onPageChange = (nomenclature: NielsenNomenclature, name: string, e: MultiSelectPageChangeEvent) => {
        const skip = e.page.skip;
        const take = e.page.take;
        const newSubsetData = nomenclature.filteredData.slice(skip, skip + take);
        const newNomenclatures = new Map(nomenclatures);

        newNomenclatures.set(name, { ...nomenclature, subsetData: newSubsetData, skip: skip });
        setNomenclatures(newNomenclatures);
    }

    const renderNomenclatureMultiSelect = (name: string, index: number, subscriptionIdx: number) => {
        const nomenclature = nomenclatures.get(name);

        if (!nomenclature)
            return <></>;

        return (
            <Grid key={"nomencSelect-" + index} item xs={6}>
                <ContainerComponent title={Trad(name)} className="configuration-multiselect unlock">
                    <MultiSelect
                        className="custom-multiselect"
                        textField="FrenchLabel"
                        dataItemKey="ExternalID"
                        data={nomenclature.subsetData}
                        filterable
                        onFilterChange={(e) => onFilterChange(nomenclature, name, e)}
                        virtual={{
                            total: nomenclature.total,
                            pageSize: pageSize,
                            skip: nomenclature.skip
                        }}
                        onPageChange={(e) => onPageChange(nomenclature, name, e)}
                        onChange={(e) => {
                            const tmpSubscriptions = [...subscriptions];

                            tmpSubscriptions[subscriptionIdx][nomenclature.subscriptionKey] = e.value.map((item: any) => item.ExternalID);
                            setSubscriptions(tmpSubscriptions);
                        }}
                        value={subscriptions[subscriptionIdx][nomenclature.subscriptionKey].map((item: any) => nomenclature.data.find((nomen: any) => nomen.ExternalID === item))}
                    />
                </ContainerComponent>
            </Grid>
        );
    }

    const renderDeliveryFrequencyByMedia = (subscription: ref_NielsenClientSubscription, index: number, subscriptionIdx: number, mediaDelivery: any) => {
        return (
            <Grid key={subscription.client + "-mediaDelivery-" + index} container item xs={6} direction="column">
                <Grid item>
                    <p>{mediaDelivery.media}</p>
                </Grid>
                <Grid item>
                    <AdwAutocomplete
                        value={mediaDelivery.deliveryFrequency}
                        options={[NielsenMediaDeliveryFrequence.DAILY, NielsenMediaDeliveryFrequence.WEEKLY, NielsenMediaDeliveryFrequence.MONTHLY]}
                        title={Trad("Fréquence de livraison")}
                        onChange={(_e, value) => {
                            const tmpSubscriptions = [...subscriptions];

                            tmpSubscriptions[subscriptionIdx].deliveryFrequencyByMedia[index].deliveryFrequency = value;
                            setSubscriptions(tmpSubscriptions);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField style={{ width: '100%', marginTop: '10px' }}
                        variant="outlined"
                        type="number"
                        label={Trad("Jour de livraison")}
                        id={`${index}_${mediaDelivery.media}`}
                        value={mediaDelivery.deliveryDay}
                        onChange={(e) => {
                            const tmpSubscriptions = [...subscriptions];

                            tmpSubscriptions[subscriptionIdx].deliveryFrequencyByMedia[index].deliveryDay = parseInt(e.target.value);
                            setSubscriptions(tmpSubscriptions);
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    const renderSubscriptionItem = (subscription: ref_NielsenClientSubscription, index: number) => {
        return (
            <Grid item container xs={8} key={"sub-" + index} spacing={1}>
                <Grid item xs={12}>{Trad("Souscription ") + (index + 1)}</Grid>
                <Grid item xs={6}>
                    <TextField style={{ width: '100%' }}
                        variant="outlined"
                        label={Trad("Nom du client")}
                        id={`client_${index}`}
                        value={subscription.client}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id={`dataStartDate_${index}`}
                        label={Trad("Date de début des données")}
                        onChange={onDateChange}
                        value={new Date(subscription.dataStartDate / 10000, ((subscription.dataStartDate / 100) % 100) - 1, subscription.dataStartDate % 100)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField style={{ width: '100%' }}
                        variant="outlined"
                        label={Trad("Dossier de destination des fichiers")}
                        id={`folderToPutFiles_${index}`}
                        value={subscription.folderToPutFiles}
                        onChange={onChange}
                    />
                </Grid>
                {nomencToGet.map((name, i) => renderNomenclatureMultiSelect(name.name, i, index))}
                <Grid item xs={12}>
                    <Checkbox
                        label={Trad("Spot à spot")}
                        checked={subscription.spotaspot}
                        onChange={(e) => { onCheckboxChange(e, index) }}
                    />
                </Grid>
                {subscription.deliveryFrequencyByMedia.map((mediaDelivery, i) => renderDeliveryFrequencyByMedia(subscription, i, index, mediaDelivery))}
            </Grid>
        );
    }

    const onSaveClick = async () => {
        // Verify if all fields are filled
        const toUpdate = subscriptions.filter(subscription => subscription["@rid"]);
        const toCreate = subscriptions.filter(subscription => !subscription["@rid"]);

        if (toUpdate.length > 0)
            await Client.updateVertex(ref_NielsenClientSubscription.name, toUpdate);

        if (toCreate.length > 0) {
            const createRes = await Client.createVertex(ref_NielsenClientSubscription.name, toCreate);
            const created = createRes.data.results;
            const tmpSubscriptions = [...subscriptions];

            for (let i = 0; i < created.length; i++) {
                // Check by client
                const subscription = created[i];
                const index = tmpSubscriptions.findIndex(sub => sub.client === subscription.client);

                if (index !== -1) {
                    tmpSubscriptions[index]["@rid"] = subscription["@rid"];
                }
            }
        }
    }

    return (
        <div className="grid_container">
            <div style={{ width: '100%' }}>
                <ToolbarAdw>
                    <BreadcrumbsCustomContainer>
                        <BreadcrumbsCustom elements={[
                            { text: Trad("home"), href: "/" },
                            { text: Trad("Configuration souscriptions clients Nielsen") }
                        ]} />
                    </BreadcrumbsCustomContainer>
                </ToolbarAdw>
                <>
                    {allNomencLoaded() ?
                        <Grid container spacing={1}>
                            {subscriptions.map((subscription, index) => renderSubscriptionItem(subscription, index))}
                            <Grid item xs={12}>
                                <CustomButton
                                    className="custom_btn_primary"
                                    Label={Trad("add")}
                                    onClick={onAddClick}
                                />
                                <CustomButton
                                    style={{ marginLeft: '10px' }}
                                    className="custom_btn_primary"
                                    Label={Trad("save")}
                                    onClick={onSaveClick}
                                />
                            </Grid>
                        </Grid>
                        :
                        <Loader />
                    }
                </>
            </div>
        </div>
    );
}

export default Template(NielsenClientSubscription);