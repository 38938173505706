import { ADWColumn } from "adwone-lib";

/**
 * Class to define the custom width of a column
 * Stored in the localStorage when user change the width of a column
 */
export class CustomWidth {

    private static key = "customWidths";

    public static getCustomWidths(): { [key: string]: number | string } {
        const customWidths = localStorage.getItem(CustomWidth.key);
        return customWidths ? JSON.parse(customWidths) : {};
    }

    public static setCustomWidths(customWidths: { [key: string]: number | string }) {
        localStorage.setItem(CustomWidth.key, JSON.stringify(customWidths ?? {}));
    }
}

export function ConvertWidthToNumber(width: number | string): number {
    if (typeof width == 'number') return width;
    else if (typeof width == 'string' && width.endsWith('px')) return Number(width.replace('px', ''));
    return 0;
}

export function GetColumnWidthNumber(col: ADWColumn<any>) {
    let res: number = 0;
    if (typeof col.width == 'number') res = col.width;
    else if (typeof col.width == 'string' && col.width.endsWith('px')) res = Number(col.width.replace('px', ''));
    return res;
}