import { ADWColumn } from "adwone-lib/index";
import { Client } from 'hub-lib/client/client.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import * as React from 'react'
import { useEffect, useState } from 'react';
import { TradProp } from 'trad-lib';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { GridBase } from '../../VertexGrid/Generic/GridBase.bin';

const gridHeight = 350;

class AdvRow {
    GroupAdvName: string;
    AdvName: string;

    constructor(_groupAdvName?, _advName?) {
        this.GroupAdvName = _groupAdvName;
        this.AdvName = _advName;
    }
}

export function AvailableAdvertisersGrid() {

    const [grid, setGrid] = useState<GridBase<AdvRow>>(undefined)

    const initGrid = async () => {
        const gpAdvCol = new ADWColumn<AdvRow>(TradProp("AdvertiserGroup", ref_Messages), "GroupAdvName", ePropType.String);
        const advCol = new ADWColumn<AdvRow>(TradProp("Advertiser", ref_Messages), "AdvName", ePropType.String);

        gpAdvCol.width = 350;
        advCol.width = 350;

        const gpAdvts: (ref_AdvertiserGroups & { children: ref_Advertisers[] })[] = await Client.searchVertexTyped(ref_AdvertiserGroups, { deep: [ref_Advertisers.name], properties : ["Name"] }) as any;
        const rows = gpAdvts.flatMap(gp => (gp.children?.length > 0 ? gp.children.map(c => new AdvRow(gp.Name, c.Name)) : [new AdvRow(gp.Name, "-")]))

        const grid = new GridBase({
            objectPrototype: AdvRow,
            rows,
            columns: [gpAdvCol, advCol]
        });

        setGrid(grid);
    }

    useEffect(() => {
        if (!grid)
            initGrid();
    })

    return <div style={{ height: gridHeight, position: 'relative' }}>
        {grid && <AdwTelerikGrid
            isCopyDisable
            grid={grid}
            uneditable={true}
            gridHeight={gridHeight} />}
    </div>
}