import React from 'react'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Aside from './Navigation/Aside'

toast.configure()

const Template = (Component) => {
    const Layout = props => (
           <Aside>
               <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
               <Component {...props}/>
            </Aside>
    )
    return Layout
}

export default Template;


