import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { lnk_HasCompany } from "../../models/orientdb/lnk_HasCompany.bin";
import { lnk_Mandate } from "../../models/orientdb/lnk_Mandate.bin";
import { ref_Advertisers } from "../../models/orientdb/ref_Advertisers.bin";
import { clnt_Companies } from "../client/clnt_Referentials";

export class AdvertiserExtended extends ref_Advertisers {
    lnkCurrencies: lnk_HasCurrency[];
    lnkContacts : lnk_HasCompany[];
    lnkMandates : lnk_Mandate[];
    parent?: any;
    ClntData? : clnt_Companies;
}