import {
    Grid, TextField, RadioGroup, FormControlLabel, Radio, Checkbox,
    Chip, InputAdornment, IconButton, InputLabel, FormControl, OutlinedInput
} from '@material-ui/core'
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin'
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin'
import { ref_Agencies } from 'hub-lib/models/orientdb/ref_Agencies.bin'
import { ref_AgencyGroups } from 'hub-lib/models/orientdb/ref_AgencyGroups.bin'
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin'
import * as React from 'react'
import { Trad, TradClassName } from 'trad-lib'
import { VertexAutocomplete } from "adwone-lib/index";
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'
import { CustomGroupButton } from '../../ConfigurableComponents/CustomGroupButton.bin'
import { getIcon } from "adwone-lib/index"
import history from '../../../utils/history'
import { CustomerInfos } from './CustomerInfos.bin'
import { Client } from 'hub-lib/client/client.bin'
import { IRid } from 'hub-lib/models/IRid.bin'
import { NewClient } from './NewClient.bin'
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker'
import { ref_CustomersExtended } from 'hub-lib/dto/referential/ref_CustomersExtended.bin'
import { ref_Contracts } from 'hub-lib/models/orientdb/ref_Contracts.bin'
import { TextArea } from '@progress/kendo-react-inputs';
import { ref_Modules } from 'hub-lib/models/orientdb/ref_Modules.bin'
import { ref_Functions } from 'hub-lib/models/orientdb/ref_Functions.bin'
import { Notify } from '../../../utils/Notify.bin'
import { IsDebugMode } from '../../../utils/localstorage.bin'
import { SSOConfiguration } from './SSOConfiguration'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin'
import { ref_AdvertisingCompanyGroups } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin'
import { groupBy } from 'hub-lib/tools.bin'

export class TProps {
    mode: eMode;
    openNewClient?: (company: ref_Companies) => void
    customerSetting?: ref_CustomersExtended
    companiesKey?: string
    key?: number
    CloseNewclient?: () => void
    open?: boolean
    closeDialog?: () => void
    newCompany?: ref_Companies
}
export enum eMode {
    create,
    modify
}
let clientTypeOption = ["Client", "Prospect", "Factice"]
let dicoCreateTrad: any = {
}
export const CompanyTypes: (new () => ref_Companies)[] = [ref_AdvertiserGroups, ref_Advertisers, ref_AgencyGroups, ref_Agencies, ref_AdvertisingCompanies]

class TState {
    open: boolean = false
    customerSetting: ref_CustomersExtended
    mails_domain_tmp: string
    contracts: ref_Contracts[]
    modules: ref_Modules[]
    functions: ref_Functions[]
    contractSelected: ref_Contracts
    companiesKey: number = 0;
    selected: number = 0;
}

export class CustomerSettingsForm extends React.Component<TProps, TState>{
    constructor(props: TProps) {
        super(props)

        dicoCreateTrad = {
            [eMode.create]: Trad("create_setting"),
            [eMode.modify]: Trad("edit_setting"),
        }

        let newState = new TState()
        if (this.props.mode === eMode.create) {
            newState.customerSetting = new ref_CustomersExtended()
            newState.customerSetting.Start = new Date()
            newState.customerSetting.End = new Date()
            newState.customerSetting.MailsDomain = []
            newState.customerSetting.Licences = 1
            newState.customerSetting.Type = "Client"
            newState.customerSetting.Billing = null
            newState.customerSetting.Activity = "Buying"
            newState.customerSetting.AdvertiserMode = 'ref_Campaigns';
            newState.customerSetting.SupportMode = 'ref_Messages';
        } else {
            newState.customerSetting = this.props.customerSetting
            if (!newState.customerSetting.AdvertiserMode)
                newState.customerSetting.AdvertiserMode = newState.customerSetting.Activity == "Buying" ? 'ref_Campaigns' : 'ref_Messages';
            if (!newState.customerSetting.SupportMode)
                newState.customerSetting.SupportMode = newState.customerSetting.Activity == "Buying" ? 'ref_Messages' : 'ref_Campaigns';
            if (!this.props.customerSetting.MailsDomain)
                newState.customerSetting.MailsDomain = []
        }
        this.state = newState
    }
    setLicences = () => {
        const { customerSetting, contractSelected } = this.state
        if (contractSelected.UserLimit && customerSetting.Licences > contractSelected.UserLimit)
            customerSetting.Licences = contractSelected.UserLimit
        this.forceUpdate()
    }
    async componentDidMount() {
        const { customerSetting } = this.state
        let contractSelected
        let contracts: ref_Contracts[] = (await Client.searchVertex(ref_Contracts.name))?.data?.results
        let modules: ref_Modules[] = (await Client.searchVertex(ref_Modules.name))?.data?.results
        let functions: ref_Functions[] = (await Client.searchVertex(ref_Functions.name))?.data?.results
        if (customerSetting.Contract) {
            contractSelected = contracts.find(e => e['@rid'] == customerSetting.Contract)
        }
        else {
            customerSetting.Contract = contracts[0]["@rid"]
            contractSelected = contracts[0]
            if (contracts[0].Modules) {
                contracts[0]?.Modules.forEach(e => {
                    customerSetting.lnkHasModule.push({ in: null, Licences: 5, out: e["@rid"], Users: [] })
                })
            }
        }

        this.setState({
            contracts,
            modules,
            functions,
            contractSelected
        }, () => this.setLicences())
    }
    onValidateForm = async (r: ref_CustomersExtended): Promise<void> => {
        if (r["@rid"]) {
            //  update
            try {
                await Client.updateVertex(ref_CustomersExtended.name, r);
                history.push('/customers', { success: "edited" });
            } catch (e) { }
        } else {
            // create
            try {
                let createdCompany = (await Client.createVertex(ref_CustomersExtended.name, r))?.data?.results?.Company;
                history.push('/customers', { success: "create", createdCompany });
            } catch (e) { }
        }
    }
    changeCompany = (ridCompany: any) => {
        this.state.customerSetting.Company = ridCompany
        this.forceUpdate()
    }
    changeCountry = (ridCounty: any) => {
        this.state.customerSetting.Country = ridCounty
        this.forceUpdate()
        Client.updateVertex(ref_CustomersExtended.name, this.state.customerSetting).then(res => {
            return res;
        }).catch(e => console.error(e));
    }
    render() {
        let { customerSetting, mails_domain_tmp, contractSelected, selected } = this.state

        return (
            <>
                <Grid item xs={12} className="adw-title adw-form-title">
                    <div>{getIcon("person")}</div>
                    {Trad("customer_infos")}
                </Grid>
                <Grid container className='block-container'>
                    <Grid item xs={4} className="adw-row">
                        {this.props.mode === eMode.modify &&
                            <VertexAutocomplete
                                label={Trad("property_company")}
                                disableClearable
                                disabled
                                defaultValue={(options: IRid[]) => options?.find((v) => v["@rid"] === customerSetting.Company)}
                                type={ref_Companies.name}
                            />
                        }
                        {this.props.mode === eMode.create &&
                            <VertexAutocomplete
                                key={`companies_autocomplete_${this.state.companiesKey}_${this.props.companiesKey}`}
                                label={Trad("property_company")}
                                disableClearable
                                defaultValue={((options: IRid[]) => options?.find((v) => v["@rid"] === customerSetting.Company))}
                                onChange={(value: any) => {
                                    customerSetting.Company = value["@rid"]
                                    customerSetting.Country = value.Country
                                    if (value["@class"] == ref_AdvertisingCompanies.name || value["@class"] == ref_AdvertisingCompanyGroups.name)
                                        customerSetting.Activity = "Selling";
                                    else
                                        customerSetting.Activity = "Buying";

                                    this.forceUpdate()
                                }}
                                type={ref_Companies.name}
                                params={{
                                    isNotClient: true,
                                }}
                            />
                        }
                    </Grid>
                    {this.props.mode === eMode.create &&
                        <Grid item xs={3}>
                            <CustomGroupButton
                                Label={Trad("create_company")}
                                containerClassname="custom_btn_nostyle_primary"
                                buttonClassName="custom_btn_nostyle_primary"
                                startIcon={getIcon("plus_rounded")}
                                style={{ margin: "10px 20px", textTransform: "none" }}
                                buttons={
                                    CompanyTypes.map(e => {
                                        return { Label: TradClassName(e.name), onClick: () => this.props.openNewClient(new e()) }
                                    })
                                }
                            />
                        </Grid>}
                    {this.props.open &&
                        <NewClient key={this.props.key}
                            CloseNewclient={() => this.setState({ companiesKey: this.state.companiesKey + 1 }, () => this.props.CloseNewclient())}
                            open={this.props.open}
                            closeDialog={this.props.closeDialog}
                            company={this.props.newCompany}
                            changeCompany={this.changeCompany}
                        />}
                    {customerSetting.Company && <><CustomerInfos changeCountry={this.changeCountry} company={customerSetting.Company} key={customerSetting.Company} /></>}
                </Grid>
                <Grid item xs={12} className="adw-title adw-form-title">
                    <div>{getIcon("lock")}</div>
                    {Trad("contract_properties")}
                </Grid>
                <Grid container className='block-container'>
                    <Grid item xs={4} >
                        <DateRangepicker
                            defaultStart={customerSetting.Start ? new Date(customerSetting.Start) : new Date()}
                            defaultStop={customerSetting.End ? new Date(customerSetting.End) : new Date()}
                            handleChangeDate={(value: any) => {
                                customerSetting.Start = value.start
                                customerSetting.End = value.end
                                this.forceUpdate()
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={4} className="box-right" key={contractSelected?.['@rid']}>
                        <TextField style={{ width: "100%" }}
                            variant="outlined"
                            disabled={!contractSelected || contractSelected.UserLimit == 1}
                            value={customerSetting.Licences}
                            label={Trad("licences_count") + " *"}
                            onChange={(e) => {
                                if (parseInt(e.target.value) > contractSelected.UserLimit) {
                                    Notify(Trad("user_limit_exceed"), "error")
                                } else {
                                    customerSetting.Licences = parseInt(e.target.value);
                                    this.forceUpdate()
                                }
                            }}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={4} className="box-right">
                        <VertexAutocomplete
                            options={clientTypeOption}
                            disableClearable
                            defaultValue={(options: any[]) => options?.find((v) => v === customerSetting.Type)}
                            onChange={(value: any) => { customerSetting.Type = value; this.forceUpdate() }}
                            label={Trad("licence_type") + " *"}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="adw-title adw-form-title">
                    {Trad("contract_base")}
                </Grid>
                <Grid container className='block-container'>
                    {this.state.contracts && this.state.contracts.map((c: ref_Contracts, i: number) => {
                        const findContract = customerSetting.Contract == c['@rid']
                        return <Grid item xs={4} key={`${c['@rid']}_draw_contracts`} className={i % 3 == 0 ? "" : "box-right"}>
                            <div className={findContract ? 'contract_selected' : 'contract'}>
                                <h3>{c.Name}</h3>
                                <p>{c.UserLimit ? `${Trad("number_user_authorized")} ${c.UserLimit}` : `${Trad("users_number_no_limit")}`}</p>
                                <p>{c.CampaignLimit ? `${Trad("number_campaigns_authorized")} ${c.CampaignLimit}` : `${Trad("campaigns_number_no_limit")}`}</p>
                                <p><CustomButton Label={findContract ? Trad("chosen_formula") : Trad("choose_formula")} className={findContract ? "custom_btn_contract" : "custom_btn_primary"} onClick={() => {
                                    customerSetting.Contract = c['@rid'];
                                    customerSetting.lnkHasModule = [];
                                    this.setState({ contractSelected: c }, () => {
                                        this.setLicences();
                                    })
                                }} /></p>
                            </div>
                        </Grid>
                    })}
                </Grid>
                <Grid item xs={12} className="adw-title adw-form-title">{Trad("Activity")}</Grid>
                <Grid container key={`customer_activity`} item xs={12} className='block-container'>
                    {["Buying", "Selling"].map((activity: string, i: number) => {
                        const selected = customerSetting.Activity == activity;
                        return <Grid key={`draw_activity_${activity}`} item xs={4} className={i % 3 == 0 ? "" : "box-right"}>
                            <div className={customerSetting.Activity == activity ? 'module_selected' : 'module'}>
                                <h3>{Trad(activity)}</h3>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    <li key={`${activity}_functions`}>{Trad(`${activity}_Campaign_mode`)}</li>
                                </ul>
                                <p><CustomButton
                                    Label={selected ? Trad("activated_option") : Trad("activate_option")}
                                    className={selected ? "custom_btn_contract" : "custom_btn_primary"}
                                    onClick={() => {
                                        if (!selected) {
                                            customerSetting.Activity = activity as any;
                                            this.forceUpdate()
                                        }
                                    }} /></p>
                            </div>
                        </Grid>
                    })}
                </Grid>
                <Grid container key={`customer_mode`} item xs={12} className='block-container'>
                    {["AdvertiserMode", "SupportMode"].map((mode: string) => {

                        return <>
                            <Grid item xs={12} className="adw-title adw-form-title">{Trad(mode)}</Grid>
                            {["ref_Campaigns", "ref_Messages"].map((choice: string, i: number) => {
                                const selected = customerSetting[mode] == choice;
                                return <Grid key={`draw_${mode}_${choice}`} item xs={4} className={i % 3 == 0 ? "" : "box-right"}>
                                    <div className={selected ? 'module_selected' : 'module'}>
                                        <h3>{Trad(choice)}</h3>
                                        <p><CustomButton
                                            Label={selected ? Trad("activated_option") : Trad("activate_option")}
                                            className={selected ? "custom_btn_contract" : "custom_btn_primary"}
                                            onClick={() => {
                                                if (!selected) {
                                                    customerSetting[mode] = choice as any;
                                                    this.forceUpdate()
                                                }
                                            }} /></p>
                                    </div>
                                </Grid>
                            })}
                        </>
                    })}
                </Grid>
                {contractSelected?.Modules?.length > 0 &&

                    <Grid item xs={12} className="adw-title adw-form-title">Options</Grid>
                }
                <Grid container key={`${customerSetting.Contract}_${customerSetting?.lnkHasModule?.length}_link_modules`} item xs={12} className='block-container'>
                    {this.state.modules && this.state.modules?.filter(m => !m.Category && this.state.contracts.find(c => c['@rid'] == customerSetting.Contract).Modules?.some(e => e == m['@rid'])).map((mod: ref_Modules, i: number) => {
                        const findModule = customerSetting?.lnkHasModule?.find(e => e.out == mod['@rid']);
                        return <Grid key={`${mod['@rid']}_draw_modules_${customerSetting?.lnkHasModule?.length}`} item xs={4} className={i % 3 == 0 ? "" : "box-right"}>
                            <div className={findModule ? 'module_selected' : 'module'}>
                                <h3>{mod.Name}</h3>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    {this.state.functions.filter(f => mod.Functions.some(e => f['@rid'] == e)).map(funct => {
                                        return <li key={`${funct['@rid']}_draw_functions`}>{Trad(funct.Name)}</li>
                                    })}
                                </ul>
                                {!mod.AllUsers &&
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        label={Trad("licences_count") + " *"}
                                        disabled={!findModule || customerSetting.Licences == 1}
                                        value={findModule ? findModule.Licences : customerSetting.Licences}
                                        onChange={(event) => {
                                            if (parseInt(event.target.value) > customerSetting.Licences) {
                                                Notify(Trad("user_limit_exceed"), "error")
                                            } else {
                                                customerSetting.lnkHasModule.find(e => e.out == mod['@rid']).Licences = parseInt(event.target.value)
                                                this.forceUpdate()
                                            }
                                        }}

                                    />}
                                <p><CustomButton
                                    Label={findModule ? Trad("activated_option") : Trad("activate_option")}
                                    className={findModule ? "custom_btn_contract" : "custom_btn_primary"}
                                    onClick={() => {
                                        if (findModule) {
                                            customerSetting.lnkHasModule = customerSetting.lnkHasModule.filter(e => e.out !== mod['@rid'])
                                            this.forceUpdate()
                                        } else {
                                            if (!customerSetting.lnkHasModule)
                                                customerSetting.lnkHasModule = [];
                                            customerSetting.lnkHasModule.push({ in: null, Licences: 1, out: mod['@rid'], Users: [] })
                                            this.forceUpdate()
                                        }
                                    }} /></p>
                            </div>
                        </Grid>
                    })}
                </Grid>

                {Object.entries(groupBy(this.state.modules?.filter(m => m.Category) ?? [], m => m.Category))
                    .map(([category, modules]) =>
                        <ModuleCategory
                            category={Trad(category)}
                            modules={modules}
                            customer={customerSetting}
                            onchange={() => this.forceUpdate()} />)}

                <Grid item xs={12} className="adw-title adw-form-title">{Trad("comments")}</Grid>
                <Grid item xs={12} className='block-container'>
                    <TextArea
                        className="custom_textarea"
                        rows={4}
                        value={customerSetting.Comments}
                        onChange={(event) => { customerSetting.Comments = (event.value as string); this.forceUpdate() }}
                    />
                </Grid>
                <Grid item xs={12} className="adw-title adw-form-title">
                    <div>{getIcon("configure")}</div>
                    {Trad("technical_params")}
                </Grid>
                <TabStrip
                    className="tabpanel_fullwidth tabpanel_rightmenu"
                    selected={selected}
                    onSelect={(e) => {
                        this.setState({ selected: e.selected });
                    }}
                >
                    <TabStripTab title={`${Trad("authentication_method")} (${customerSetting.SSO ? "SSO" : "AdwOne"})`}>
                        <RadioGroup value={customerSetting.SSO ? "SSO" : "AdwOne"} onChange={(e) => {

                            if (e.target.value === "AdwOne") delete customerSetting.SSO;
                            else if (e.target.value === "SSO") customerSetting.SSO = {
                                CALLBACK_URL: null,
                                ENTRY_POINT: null,
                                ISSUER: null,
                                HOME: null,
                                ISSUER_PRIVATE: null,
                                ERROR: null,
                                IDP_PUBLIC: null,
                                ROUTER_PATH: null,
                            }
                            this.forceUpdate()
                        }}>
                            <FormControlLabel value="AdwOne" control={<Radio />} label="AdwOne" />
                            <FormControlLabel value="SSO" control={<Radio />} label="SSO" />
                        </RadioGroup>
                        {customerSetting.SSO &&
                            <>
                                <TextField
                                    variant="outlined"
                                    label={Trad("portal_sso")}
                                    value={customerSetting.SSO.ENTRY_POINT ?? ""}
                                    onChange={(e) => {
                                        customerSetting.SSO.ENTRY_POINT = e.target.value; this.forceUpdate()
                                    }}
                                    style={{ width: "80%", margin: "10px 0" }}
                                />
                                <TextField
                                    variant="outlined"
                                    label={Trad("issuer")}
                                    value={customerSetting.SSO.ISSUER ?? ""}
                                    onChange={(e) => {
                                        customerSetting.SSO.ISSUER = e.target.value; this.forceUpdate()
                                    }}
                                    style={{ width: "80%", margin: "10px 0" }}
                                />
                                <FormControl variant="outlined" style={{ width: '80%' }}>
                                    <InputLabel htmlFor="outlined-adornment-password">{Trad("mail_domain")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        value={mails_domain_tmp}
                                        onChange={(e) => {
                                            if (e.target.value.length > 0) {
                                                this.setState({
                                                    mails_domain_tmp: e.target.value
                                                })
                                            }
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        if (mails_domain_tmp?.length) {
                                                            customerSetting.MailsDomain.push(mails_domain_tmp)
                                                            this.setState({
                                                                mails_domain_tmp: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                >
                                                    {getIcon("plus_rounded")}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={150}
                                    />
                                </FormControl>
                                <div style={{ width: '80%', marginTop: 5 }}>
                                    {customerSetting.MailsDomain && customerSetting.MailsDomain.map(e => {
                                        return (
                                            <Chip
                                                key={e + Date.now()}
                                                style={{ margin: 2 }}
                                                label={e}
                                                onDelete={() => {
                                                    customerSetting.MailsDomain = customerSetting.MailsDomain.filter(s => s !== e)
                                                    this.forceUpdate()
                                                }}
                                            />
                                        )
                                    })}
                                </div>

                                {IsDebugMode() && <SSOConfiguration sso={customerSetting?.SSO} />}
                            </>
                        }
                    </TabStripTab>
                    <TabStripTab title={`${Trad("data_rights_administration")} (${customerSetting.Authorization})`}>
                        <RadioGroup value={customerSetting.Authorization} onChange={(e) => { (customerSetting.Authorization as any) = e.target.value; this.forceUpdate() }}>
                            <FormControlLabel value="AdwOne" control={<Radio />} label="AdwOne" />
                            <FormControlLabel value="MediaManager" control={<Radio />} label="Media Manager" />
                            <FormControlLabel value="MediaPilot" control={<Radio />} label="Media Pilot" />
                        </RadioGroup>
                        {customerSetting.Authorization === "MediaManager" &&
                            <Grid container xs={8} className="adw-row" style={{ paddingTop: 12 }}>
                                <><TextField
                                    variant="outlined"
                                    label={Trad("mediamanager_adress")}
                                    value={customerSetting.MediaManagerAPI}
                                    onChange={(e) => { customerSetting.MediaManagerAPI = e.target.value; this.forceUpdate() }}
                                    style={{ width: "100%" }}
                                />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={customerSetting.Vpn}
                                                onChange={(e) => { customerSetting.Vpn = e.target.checked; this.forceUpdate() }}
                                                name="vpn"
                                                color="primary"
                                            />
                                        }
                                        label={Trad("vpn_required")}
                                    />
                                    {this.props.mode === eMode.modify &&
                                        <div className="adw-row box-right legend" style={{ width: "100%", backgroundColor: "rgb(237, 237, 236", paddingTop: "12px", paddingBottom: "12px" }}>
                                            <label>{Trad("masterdata_key")}</label>
                                            <div>{customerSetting.MasterDataKey}</div>
                                        </div>
                                    }
                                </>
                            </Grid>
                        }
                        {customerSetting.Authorization === "MediaPilot" &&
                            <Grid container xs={8} className="adw-row" style={{ paddingTop: 12 }}>
                                <TextField
                                    variant="outlined"
                                    label={Trad("mediapilot_adress")}
                                    value={customerSetting.MediaPilotAPI}
                                    onChange={(e) => { customerSetting.MediaPilotAPI = e.target.value; this.forceUpdate() }}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                        }
                    </TabStripTab>
                    <TabStripTab title={`${Trad("billing_method")}${customerSetting.Billing ? ` (${customerSetting.Billing})` : ""}`}>
                        <RadioGroup value={customerSetting.Billing} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value as "AdwOne" | "MediaManager";
                            if (!value) {
                                customerSetting.Billing = null;
                            } else {
                                customerSetting.Billing = value;
                            }
                            this.forceUpdate();
                        }}>
                            <FormControlLabel value={null} control={<Radio />} label={Trad("none")} />
                            <FormControlLabel value="AdwOne" control={<Radio />} label="AdwOne (Sellsy)" />
                            <FormControlLabel value="MediaManager" control={<Radio />} label="Media Manager" />
                        </RadioGroup>

                        {customerSetting.Billing === "AdwOne" &&
                            <Grid item xs={8} style={{ paddingTop: 12 }}>
                                <TextField
                                    variant="outlined"
                                    label={Trad("client_id")}
                                    value={customerSetting?.Sellsy?.client_id}
                                    onChange={(e) => {
                                        const sellsy = {
                                            ...customerSetting.Sellsy,
                                            client_id: e.target.value
                                        }
                                        customerSetting.Sellsy = sellsy;
                                        this.forceUpdate();
                                    }}
                                    style={{ width: "100%", margin: "10px 0" }}
                                />
                                <TextField
                                    variant="outlined"
                                    label={Trad("client_secret")}
                                    value={customerSetting?.Sellsy?.client_secret}
                                    onChange={(e) => {
                                        const sellsy = {
                                            ...customerSetting.Sellsy,
                                            client_secret: e.target.value
                                        }
                                        customerSetting.Sellsy = sellsy;
                                        this.forceUpdate();
                                    }}
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                        }
                    </TabStripTab>
                </TabStrip>
                <div className="adw-form-action">
                    <CustomButton
                        Label={Trad("cancel")}
                        onClick={() => history.push('/customers')}
                        className="custom_btn_primary_cancel cancel"
                    />
                    <CustomButton
                        Label={dicoCreateTrad[this.props.mode]}
                        startIcon={getIcon("check")}
                        className="custom_btn_primary_validation"
                        onClick={() => this.onValidateForm(this.state.customerSetting)}
                    />
                </div>
            </>
        )
    }
}

type ModuleCategoryProps = { category: string, modules: ref_Modules[], customer: ref_CustomersExtended, onchange: () => void }
function ModuleCategory({ category, modules, customer, onchange }: ModuleCategoryProps) {
    return (
        <Grid container key={`category_${category}`} item xs={12} className='block-container'>
            <Grid item xs={12} className="adw-title adw-form-title">{category}</Grid>
            {modules.map((mod: ref_Modules, i: number) => {
                const findModule = customer?.lnkHasModule?.find(e => e.out == mod['@rid']);

                return <Grid key={`${mod['@rid']}_draw_modules_${modules.length}`} item xs={2} style={{ padding: 2 }}>
                    <div className={findModule ? 'module_selected' : 'module'}>
                        <h3>{Trad(mod.Name)}</h3>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                            {mod.Functions.map(f => <li key={`${f}_draw_functions`}>{Trad(f)}</li>)}
                        </ul>
                        <p>
                            <CustomButton
                                Label={findModule ? Trad("activated_option") : Trad("activate_option")}
                                className={findModule ? "custom_btn_contract" : "custom_btn_primary"}
                                onClick={() => {
                                    if (findModule)
                                        customer.lnkHasModule = customer.lnkHasModule.filter(e => e.out !== mod['@rid'])
                                    else {
                                        if (!customer.lnkHasModule)
                                            customer.lnkHasModule = [];
                                        customer.lnkHasModule.push({ in: null, Licences: 1, out: mod['@rid'], Users: [] })
                                    }
                                    onchange();
                                }} />
                        </p>
                    </div>
                </Grid>
            })}
        </Grid>
    )
}