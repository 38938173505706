import * as React from 'react'
import Template from '../Template'
import EstimateDetails from '../../VertexGrid/Estimates/EstimateDetails.bin';

const EstimatesDetails = () => {
    return (
        <>
            <div className="grid_container">
                <EstimateDetails />
            </div>
        </>
    );
}

export default Template(EstimatesDetails);
