import { User, UserId } from "./User.bin";
import { ref_Contacts, ref_ContactsId } from "./ref_Contacts.bin";
import { ref_Companies, ref_CompaniesId } from "./ref_Companies.bin";
import { ref_Persons, ref_PersonsId } from "./ref_Persons.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";

export type lnk_HasCompanyId = rid;

export class lnk_HasCompany extends E<ref_CompaniesId, ref_PersonsId> {
	"@rid"?: lnk_HasCompanyId;
	Account: UserId;
	Contact: ref_ContactsId;
	End: Date;
	Start: Date;

}