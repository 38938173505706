import * as React from 'react'
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin'
import { ExternalCampaignImport } from 'hub-lib/models/custom/ExternalCampaignImport'
import { ClearEmptyValues, extractSub, GetHashCode } from 'hub-lib/tools.bin';
import { AdvertiserInfos, CampaignPicker, StoreArg } from '../VertexGrid/Campaigns/CampaignPicker';
import { NewCampaignButton } from './NewCampaignButton';
import { CampaignFilter } from '../VertexGrid/Messages/CampaignSearch.bin';
import { Client } from 'hub-lib/client/client.bin';
import { FilterStorage } from '../../utils/localstorage.bin';
import { CampaignPerformance } from '../VertexGrid/Campaigns/CampaignPerformance';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { Trad } from 'trad-lib';
import { Grid } from '@material-ui/core';
import { IAdvertiserHierarchy } from 'hub-lib/types';

const firstElement = (e: string | string[]) => Array.isArray(e) ? e[0] : e;

type CampaignSelectionProps = { campaignImport: ExternalCampaignImport, onChange?: (c: StoreArg<IAdvertiserHierarchy>['store']) => void }
export function CampaignSelection({ campaignImport, onChange }: CampaignSelectionProps) {

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const propsLocked: (keyof IAdvertiserHierarchy)[] = ['Group', 'AdvertiserGroup', 'Advertiser', 'Brand', 'Product'];
    const initStore = (campaign: ref_Campaigns) => ({ ...extractSub(campaign, propsLocked), Campaign: campaign?.['@rid'] })
    const [store, setStore] = React.useState<StoreArg<IAdvertiserHierarchy>['store']>(initStore(campaignImport.Referential))
    const [campaignFilter, setCampaignFilter] = React.useState<CampaignFilter>({ ...FilterStorage.GetLocalStorageFiltersObj() });
    const [options, setOptions] = React.useState<ref_Campaigns[]>();
    const [medias, setMedias] = React.useState<ref_Media[]>();

    const selectedCampaign = campaignImport.Referential;


    React.useEffect(() => {
        campaignImport.Messages.forEach(m => {
            const msg = m.Referential;
            msg.Campaign = store.Campaign;
            msg.Group = firstElement(store.Group);
            msg.AdvertiserGroup = firstElement(store.AdvertiserGroup);
            msg.Advertiser = firstElement(store.Advertiser);
            msg.Brand = firstElement(store.Brand);
            msg.Product = firstElement(store.Product);
        });
        onChange?.(store);
    }, [GetHashCode(store)])

    React.useEffect(() => {
        Client.searchVertexTyped(ref_Campaigns, getCampaignParams())
            .then(setOptions);
        if (!medias)
            Client.searchVertexTyped(ref_Media)
                .then(setMedias);

    }, [GetHashCode(campaignFilter)])

    const getCampaignParams = () => ClearEmptyValues({
        AdvertiserGroup: campaignFilter?.AdvertiserGroup,
        Advertiser: campaignFilter?.Advertiser,
        Brand: campaignFilter?.Brand,
        Product: campaignFilter?.Product,
        Start: campaignFilter?.Start,
        End: campaignFilter?.End,
        Source: "ADWONE",
        Active: true
    });

    const mediaPresseRid = medias?.find(m => m.Name === 'PRESSE')?.['@rid'];

    if (campaignImport?.Referential && !campaignImport.Referential.Performances)
        campaignImport.Referential.Performances = {};

    if (mediaPresseRid && campaignImport?.Referential && !campaignImport.Referential.Performances[mediaPresseRid])
        campaignImport.Referential.Performances[mediaPresseRid] = {};

    // take performances from Referential or BaseReferential
    const performances = campaignImport.Referential?.Performances ?? campaignImport.BaseReferential?.Performances;

    return <>
        <NewCampaignButton
            store={store}
            onChange={newCampaign => {
                options.unshift(newCampaign);
                campaignImport.Referential = { ...newCampaign, ...campaignImport.BaseReferential };
                forceUpdate();
            }} />
        <Grid item xs={12} className="adw-title">{Trad("campaign_association")}</Grid>
        <Grid container className='block-container'>
            <CampaignPicker
                campaignFilter={campaignFilter}
                onFilterChanged={setCampaignFilter}
                defaultValue={selectedCampaign}
                options={options ?? []}
                params={{ properties: [...propsLocked, 'Name', 'Start', 'End', 'Budget'] }}
                onChange={async value => {
                    campaignImport.Referential = value;
                    if (!campaignImport.Referential.Performances)
                        campaignImport.Referential.Performances = {};

                    // if no performances for print media, take it from BaseReferential
                    if(!campaignImport.Referential.Performances[mediaPresseRid] && campaignImport.BaseReferential.Performances[mediaPresseRid])
                        campaignImport.Referential.Performances[mediaPresseRid] = campaignImport.BaseReferential.Performances[mediaPresseRid];
                    
                    setStore(initStore(value));
                }}
            />
            <AdvertiserInfos
                store={store}
                campaign={selectedCampaign}
                onStoreChanged={(store: any) => {
                    setStore({ ...store, ...initStore(campaignImport.Referential) });
                }}
            />
        </Grid>
        {mediaPresseRid
            && performances
            && <>
                <Grid item xs={12} className="adw-title">{Trad("performances")}</Grid>
                <CampaignPerformance
                    kpis={campaignImport.Referential?.KPIs ?? {}}
                    performances={performances[mediaPresseRid]}
                    activeCGrp={false}
                    onChange={(field, value) => {
                        if (!performances[mediaPresseRid]) performances[mediaPresseRid] = {};
                        performances[mediaPresseRid][field] = value;
                    }} />
            </>}
    </>
}
