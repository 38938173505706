import { Grid } from "@material-ui/core";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import React from "react";
import { Trad, TradProp } from "trad-lib";
import { DateRangepicker } from "../../ConfigurableComponents/DateRangepicker";
import { CustomTextField } from "../Generic/CustomTextField";
import { eKPI, eKPIType } from "hub-lib/models/KPIsManager.bin";

type CampaignDatesArgs = { campaignRid: IRid["@rid"], params?: any }
export function CampaignInfos({ campaignRid, params }: CampaignDatesArgs) {

    const [campaignLoading, setCampaignLoading] = React.useState<ref_Campaigns["@rid"]>();
    const [campaign, setCampaign] = React.useState<ref_Campaigns>();

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (campaignRid && campaign?.["@rid"] != campaignRid && campaignLoading != campaignRid) {
                setCampaignLoading(campaignRid);
                const cmp = (await Client.searchVertex(ref_Campaigns.name, { ...params, "@rid": campaignRid, Source: "ADWONE" }))?.data?.results?.[0];
                if (cmp) {
                    cmp.Start = new Date(cmp.Start);
                    cmp.End = new Date(cmp.End);
                }
                setCampaign(cmp);
            }
        });
    });

    return <>

        <Grid item xs={6} className={"message_details_leftcombo"}>
            <CustomTextField disabled kpiType={eKPIType.Decimal} label={Trad("Budget")} value={campaign?.KPIs[eKPI.Budget]} />
        </Grid>

        <Grid item xs={6} className={"message_details_rightcombo"} key={`dates-camp-${campaign?.["@rid"]}`}>
            <DateRangepicker defaultStart={campaign?.Start} defaultStop={campaign?.End} disabled />
        </Grid>
    </>
}