import * as React from 'react'
import { Row, Title } from '../../Tools';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, SetModelSync, store } from '../../../redux/store';
import { VertexGrid } from '../Generic/VertexGrid.bin';
import { Grid } from '@material-ui/core';
import { AdwTelerikGrid } from '../Generic/AdwTelerikGrid.bin';
import { ADWColumn, AdwRow } from 'adwone-lib';
import { FilesPicker } from '../Generic/FilesPicker.bin';
import { Trad, TradProp } from 'trad-lib';
import { ref_Visuals } from 'hub-lib/dto/client/ref_Attachments.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { styleGridContainer } from '../../../styles/theme';

export function VisualsEditor() {
    const campaignRid = useSelector((root: RootState) => root.campaignEditor.data?.["@rid"]);
    const [visualGrid, setVisualGrid] = useState<VertexGrid<ref_Visuals>>(undefined);

    React.useEffect(() => {
        if (visualGrid) return;
        const columns = [];
        const nameColumn = new ADWColumn<ref_Visuals>(TradProp("Name", ref_Visuals), "Name", ePropType.String, true);
        nameColumn.width = "*";

        const fileColumn = new ADWColumn<ref_Visuals>(TradProp("File", ref_Visuals), "File", ePropType.String, false);
        fileColumn.width = 450;

        fileColumn.cellValue = (cellValue: any, row?: AdwRow<ref_Visuals>) => {

            console.log("cellValue row", row);
            const data = store.getState().campaignEditor.get();
            const visual = data.Visuals.find(v => v["@rid"] == row.id || v["@rid"] == row.dataItem["@rid"]);

            return <FilesPicker rid={visual?.["@rid"]}
                uploadDisabled={row.inEdit}
                attachments={visual?.FileInfo ? [visual.FileInfo] : null}
                onRemove={() => {
                    visual.File = null;
                    visual.FileInfo = null;
                    SetModelSync("ref_Campaigns", data);
                }}
                onChange={(attachment) => {
                    visual.File = attachment['@rid'];
                    visual.FileInfo = attachment;
                    SetModelSync("ref_Campaigns", data);
                }} />
        };
        columns.push(nameColumn);
        columns.push(fileColumn);
        const hiddenProperties = ["Name", "File", "Campaign", "Status", "Created_by", "Updated_by", "Created_at", "Updated_at"];
        const _visualGrid = new VertexGrid<ref_Visuals>({
            objectPrototype: ref_Visuals,
            devMode: false,
            hiddenProperties,
            columns,
            vertexParams: {
                Active: true,
                properties: ["*", "FileInfo"],
                "Campaign": campaignRid ?? null
            },
            afterSearch: (visuals) => {
                const data = store.getState().campaignEditor.get();
                data.Visuals = visuals;
                SetModelSync("ref_Campaigns", data);
                return visuals;
            },
            onInlineNew: (visual, row) => {
                visual["@rid"] = row.id;
                visual["Active"] = true;
                const data = store.getState().campaignEditor.get();
                data.Visuals.push(visual);
                SetModelSync("ref_Campaigns", data);
            },
            onInlineEdited: (visual, row) => {
                const data = store.getState().campaignEditor.get();
                let oldVisual = data.Visuals.find(v => v["@rid"] == row.id || v["@rid"] == row.dataItem["@rid"]);
                let position = data.Visuals.indexOf(oldVisual);
                if (!data.Visuals[position]["@rid"])
                    data.Visuals[position]["@rid"] = row.id;
                data.Visuals[position].Name = visual.Name;
                SetModelSync("ref_Campaigns", data);
            },
            onInlineDeleted: (visuals, rows) => {
                const deletedIds = rows.map(r => r.dataItem["@rid"] ?? r.id);
                const data = store.getState().campaignEditor.get();
                data.Visuals = data.Visuals.filter(v => !deletedIds.includes(v["@rid"]));
                SetModelSync("ref_Campaigns", data);
            },
            validator: async (visual, errors, row) => {
                const data = store.getState().campaignEditor.get();
                const doublon = data.Visuals.some(v => v.Name == visual.Name && v["@rid"] != row.id && v["@rid"] != visual["@rid"]);
                if (doublon)
                    errors.push("Name");
            }
        });
        setVisualGrid(_visualGrid);
    })

    return <div id='advertisingpicker'>
        <Title>{Trad('advertising_creation')}</Title>
        <Row>
            <Grid container className='block-container'>
                {visualGrid && <Grid item xs={12} className="message_details_full">
                    <AdwTelerikGrid
                        grid={visualGrid}
                        deleteInline
                        selectable
                        customAddText={Trad("add_visual")}
                        gridHeight={styleGridContainer.supportForm.height}
                        addButton />
                </Grid>}
            </Grid>
        </Row>
    </div>
}