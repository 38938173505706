import * as React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { CustomIconButton } from '../Generic/CustomIconButton';
import { getIcon } from 'adwone-lib';

export function CustomAddMessage({ editField, edit }, customCommandCellFunction: (data: any) => void) {
    return class extends React.Component<any, any> {
        render() {
            const { dataItem } = this.props;
            const inEdit = dataItem[editField];
            return inEdit ? (
                <></>
            ) : (
                <td className="k-grid-content-sticky k-command-cell add-border-left clear-padding" style={{ right: '-1px', textAlign: "left" }} >
                    <CustomIconButton
                        className={dataItem.selected == true ? 'is-selected' : 'primary_color'}
                        onClick={() => edit(dataItem)}>
                        {getIcon('edit_inline')}
                    </CustomIconButton>
                    <CustomIconButton className="validation_color" aria-label="edit" disabled={dataItem.dataItem.Invalide} style={{ color: '#009BCE' }} onClick={() => {
                        customCommandCellFunction(dataItem.dataItem)
                    }}>
                        <AddCircleOutlineIcon />
                    </CustomIconButton>
                </td>
            );
        }
    }
}