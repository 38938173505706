import { Trad, TradProp } from "trad-lib";
import { ClassProperty, ePropType } from "../models/VertexProperty.bin";
import { DataProvider } from "../provider";
import { IAggregator, IndicateurOption, cumulIndicateurArgs } from "./AggregatorFactory";
import { KPIsManagerCache, ToEKPIType, eKPIType, eKPI, IsPrice } from "../models/KPIsManager.bin";
import { Indicateur, IndicateurComputed, IndicateurDiscount, IndicateurInfo, IndicateurKPI, IndicateurToString, eIndicateurType } from "adwone-engine/index.bin";
import { Typed, duplicate, toArray } from "../tools.bin";
import { eColumnType } from "../models/types.bin";
import { ref_KPIs } from "../models/orientdb/ref_KPIs.bin";
import { ref_Media } from "../models/orientdb/ref_Media.bin";
import { lnk_HasKPI } from "../models/orientdb/lnk_HasKPI.bin";
import { RightManager, eFunctions, eRights } from "../models/types/rights.bin";
import { GetIndicateurKPIName } from "./IndicateurListing";
import { ref_DiscountClasses } from "../models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "../models/orientdb/ref_DiscountTypes.bin";
import { Sort } from "format-lib/index.bin";
import { eDiscountOptionType, eDiscountOptionValue } from "../models/external.bin";
import { ref_PropertyType } from "../models/orientdb/ref_PropertyType.bin";

export const DicoColumnType = {
    [eColumnType.Property]: 0,
    [eColumnType.KPI]: 1,
    [eColumnType.PriceReturned]: 2,
    [eColumnType.Discount]: 3,
    [eColumnType.DiscountValue]: 4,
    [eColumnType.DiscountFO]: 5,
    [eColumnType.DiscountFOValue]: 6,
    [eColumnType.DiscountFOS]: 7,
    [eColumnType.DiscountFOSValue]: 8,
    [eColumnType.PriceBound]: 9,
    [eColumnType.DiscountValueBound]: 10,
    [eColumnType.DiscountFOValueBound]: 11,
    [eColumnType.Barter]: 12
}

export abstract class AggregatorBase<T> implements IAggregator {

    protected _objectType: new () => T;

    private discounts: ref_DiscountClasses[];
    private discountTypes: ref_DiscountTypes[];

    protected async GetDefaultColumnNames() { return [] };
    protected async GetDefaultVentilationNames() { return [] };

    public async GetDefaultConfig(): Promise<{
        Columns: Indicateur[],
        Ventilations: Indicateur[]
    }> {
        const indicateurs = (await this.Provide());

        const columnNames = await this.GetDefaultColumnNames();
        const ventilationNames = await this.GetDefaultVentilationNames();

        return {
            Columns: columnNames.map(c => indicateurs.find(i => c == i.indicateur.name)?.indicateur).filter(i => i),
            Ventilations: ventilationNames.map(c => indicateurs.find(i => c == i.indicateur.name)?.indicateur).filter(i => i)
        };
    }

    protected GetMediaFamilyII() {
        return Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('ModelProperties.MediaFamily' as any, this._objectType),
            field: 'ModelProperties.MediaFamily',
            valueType: eKPIType.Rid,
            options: {
                priorityToField: 'ModelProperties.MediaFamilyOther'
            }
        });
    }

    protected GetPeriodicityII() {
        return Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('ModelProperties.Periodicity' as any, this._objectType),
            field: 'ModelProperties.Periodicity',
            valueType: eKPIType.Rid,
            options: {
                priorityToField: 'ModelProperties.PeriodicityComment'
            }
        });
    }

    protected GetAgencyResII() {
        return Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            name: TradProp('Agency_Res' as any, this._objectType),
            field: 'Agency_Res',
            valueType: eKPIType.Rid,
            options: {
                priorityToField: 'ModelProperties.Agency_ResOther'
            }
        });
    }

    /**
     *
     */
    constructor(objectType: new () => T) {
        this._objectType = objectType;
    }

    protected GetModelRidIO() {
        return Typed<IndicateurOption>({
            indicateur: {
                type: eIndicateurType.info,
                name: TradProp('@rid', this._objectType),
                field: '@rid',
                valueType: eKPIType.String
            },
            columnType: eColumnType.Property
        })
    }

    async getDiscountClasses(filter?: (c: ref_DiscountClasses) => boolean): Promise<ref_DiscountClasses[]> {
        if (!this.discounts) {
            this.discounts = await DataProvider.search<ref_DiscountClasses>(ref_DiscountClasses.name);
        }

        if (!filter) {
            const [barterType] = await this.getDiscountTypes((t) => t.Name == "Barter");
            filter = (c: ref_DiscountClasses) => c.DiscountType != barterType["@rid"];
        }

        return this.discounts.filter(filter);
    }

    async getDiscountTypes(filter?: (t: ref_DiscountTypes) => boolean): Promise<ref_DiscountTypes[]> {
        if (!this.discountTypes) {
            this.discountTypes = await DataProvider.search<ref_DiscountTypes>(ref_DiscountTypes.name);
        }
        return this.discountTypes.filter(filter ?? (d => d.Name != "Barter"));
    }

    protected GetForcedIndicateurs(): { [field: string]: IndicateurInfo | IndicateurInfo[] } {
        return {};
    }

    public async MetadataToIndicateurOptions(): Promise<IndicateurOption[]> {
        const properties = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties();
        const forcedIndicateurs = this.GetForcedIndicateurs();
        const propertyTypes = await DataProvider.search<ref_PropertyType>(ref_PropertyType.name);

        return properties.filter(p => !p.hidden).flatMap(p => {

            let indInfo: IndicateurInfo[] = undefined;
            if (forcedIndicateurs?.[p.name])
                indInfo = toArray(forcedIndicateurs[p.name]);
            else {
                const propertyType = propertyTypes.find(pt => p.name == `ModelProperties.${pt.Type}`);
                indInfo = [{
                    type: IsPrice(propertyType?.ValueType as eKPIType) ? eIndicateurType.kpi : eIndicateurType.info,
                    name: TradProp(p.name as any, this._objectType),
                    field: p.name,
                    valueType: propertyType?.ValueType ?? ToEKPIType(p.type)
                }];
            }
            return indInfo.map(i => (Typed<IndicateurOption>({
                indicateur: i,
                columnType: eColumnType.Property
            })));
        });
    }

    protected async KPIToIndicateurOptions(kpiType?: eKPIType): Promise<IndicateurOption[]> {
        const kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs();
        const lnk_kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs();;

        let options = {};
        let columnType = eColumnType.KPI;

        if (kpiType == eKPIType.PriceBound) {
            columnType = eColumnType.PriceBound;
            options = { isPriceBound: true };
        }
        if (kpiType == eKPIType.PriceReturned) {
            columnType = eColumnType.PriceReturned;
            options = { isPriceReturned: true };
        }

        return kpis.filter((k: ref_KPIs) => !kpiType ||
            (kpiType && lnk_kpis.filter(lnk => lnk.KPI === k["@rid"] && [eKPIType.Price, kpiType].includes(lnk.ValueType))).length)
            .map(k => {
                /** on cherche le lnkKpi pour avoir la clé et le type */
                const lnkKpi = lnk_kpis.find(lnk => lnk.KPI === k["@rid"])

                if (!lnkKpi) {
                    //console.log(`KPI not found`, k);
                    return null;
                }

                const indKpi: IndicateurKPI = {
                    type: eIndicateurType.kpi,
                    name: GetIndicateurKPIName(k.Name, kpiType),
                    valueType: kpiType ?? lnkKpi.ValueType,
                    field: lnkKpi.Id,
                    options: {
                        rid: k["@rid"],
                        ...options
                    }
                }
                if (k.Aggregate === false)
                    indKpi.options.aggregate = false;
                return {
                    indicateur: indKpi,
                    columnType: columnType
                };
            })
            .filter(k => k);;
    }

    protected async DiscountToIndicateurOptions(): Promise<IndicateurOption[]> {
        const discounts = Sort(ref_DiscountClasses.name, await this.getDiscountClasses());
        const discountIOs = discounts.map((d: ref_DiscountClasses) =>
        ([{
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Percent,
                name: `${Trad("taux")} ${Trad(d.Name)}`,
                options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.CO }
            },
            columnType: eColumnType.Discount
        }, {
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Percent,
                name: `${Trad("taux")} ${Trad(d.Name)} FO`,
                options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.FO }
            },
            columnType: eColumnType.DiscountFO
        }, {
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Percent,
                name: `${Trad("taux")} ${Trad(d.Name)} FOS`,
                options: { rid: d["@rid"], value: eDiscountOptionValue.Rate, type: eDiscountOptionType.FOS }
            },
            columnType: eColumnType.DiscountFOS
        }, {
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Price,
                name: `${Trad("montant")} ${Trad(d.Name)}`,
                options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.CO }
            },
            columnType: eColumnType.DiscountValue
        }, {
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Price,
                name: `${Trad("montant")} ${Trad(d.Name)} FO`,
                options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.FO }
            },
            columnType: eColumnType.DiscountFOValue
        }, {
            indicateur: {
                type: eIndicateurType.discount,
                valueType: eKPIType.Price,
                name: `${Trad("montant")} ${Trad(d.Name)} FOS`,
                options: { rid: d["@rid"], value: eDiscountOptionValue.Value, type: eDiscountOptionType.FOS }
            },
            columnType: eColumnType.DiscountFOSValue
        }]));

        return discountIOs.reduce((a, b) => a.concat(b), [])
            .sort((a, b) => a.indicateur.type.localeCompare(b.indicateur.type));
    }

    protected GetDiscountIO_Typed(discountIOs: IndicateurOption[], kpiType: eKPIType.PriceBound | eKPIType.PriceReturned): IndicateurOption[] {
        let options = {};

        const GetColumnType = (columnType: eColumnType) => {
            if (kpiType == eKPIType.PriceBound)
                return (columnType + "Bound") as eColumnType;
            return columnType;
        }
        if (kpiType == eKPIType.PriceBound) {
            options = { isPriceBound: true };
        }
        if (kpiType == eKPIType.PriceReturned) {
            options = { isPriceReturned: true };
        }

        return discountIOs
            .filter(p => {
                const indicD = (p.indicateur as IndicateurDiscount);
                // seulement les CO/FO en montant pour les valeurs déversées
                return (kpiType != eKPIType.PriceBound || [eDiscountOptionType.CO, eDiscountOptionType.FO].includes(indicD.options.type))
                    && indicD.options.value === eDiscountOptionValue.Value;
            })
            .map((p: IndicateurOption): IndicateurOption => ({
                indicateur: {
                    ...duplicate(p.indicateur),
                    name: GetIndicateurKPIName(p.indicateur.name, kpiType),
                    valueType: kpiType,
                    options: { ...(p.indicateur as IndicateurDiscount).options, ...options }
                },
                columnType: GetColumnType(p.columnType)
            }))
            .sort((a, b) => a.indicateur.name.localeCompare(b.indicateur.name))
    }

    protected async DiscountToCumulIndicateurOption(
        discountTypeName: string,
        indicateurName: string,
        args?: cumulIndicateurArgs): Promise<IndicateurOption> {

        const taxesRid = (await this.getDiscountTypes()).find(t => t.Name === discountTypeName)["@rid"];
        const discounts = await this.getDiscountClasses(d => d.DiscountType === taxesRid);
        const taxesClasses = discounts.map((d: ref_DiscountClasses) => (Typed<IndicateurDiscount>({
            type: eIndicateurType.discount,
            valueType: eKPIType.Price,
            name: `montant ${d.Name}`, // Ne pas traduire ce champs car tout l'indicateur est serializé sert de clé
            options: {
                rid: d["@rid"],
                value: eDiscountOptionValue.Value,
                type: eDiscountOptionType.CO,
                ...args?.discountOptions
            }
        })));

        const cumul: IndicateurOption = {
            indicateur: Typed<IndicateurComputed>({
                name: indicateurName,
                indicateurs: taxesClasses,
                operator: "+",
                type: eIndicateurType.computed,
                valueType: eKPIType.Price,
                ...(args?.computedOptions != undefined && { options: args.computedOptions })
            }),
            columnType: eColumnType.DiscountValue,
            ...args?.option
        };
        return cumul;
    }

    protected Sort_IO(indicateurs: IndicateurOption[]): IndicateurOption[] {
        return indicateurs.sort((a, b) => {
            const type = DicoColumnType[a.columnType] - DicoColumnType[b.columnType];
            if (type != 0)
                return type;
            return a.indicateur.name.localeCompare(b.indicateur.name);
        });
    }

    Provide: () => Promise<IndicateurOption[]> = async () => {

        const propertyIOs: IndicateurOption[] = await this.MetadataToIndicateurOptions();

        let contents: IndicateurOption[] = [...propertyIOs];

        return this.Sort_IO(contents);
    }

    async UpdateIndicateursNames(indicateurs: Indicateur[], _allIndicateurs?: Indicateur[]) {
        const allIndicateurs = _allIndicateurs ?? (await this.Provide()).map(op => op.indicateur);
        const signatures = allIndicateurs.map(ind => ({ signature: IndicateurToString(ind), name: ind.name }))

        if (indicateurs)
            for (const indicateur of indicateurs) {
                const sign = IndicateurToString(indicateur);
                const found = signatures.find(s => s.signature === sign);
                if (found)
                    indicateur.name = found.name;
            }
    }

    protected async CreateCompareIndicateur(title: string, field1: string, field2: string) {
        return Typed<IndicateurComputed>({
            name: title,
            valueType: eKPIType.Number,
            operator: "=",
            type: eIndicateurType.computed,
            indicateurs: [
                Typed<IndicateurInfo>({
                    name: "net",
                    valueType: eKPIType.String,
                    type: eIndicateurType.info,
                    field: field1
                }),

                Typed<IndicateurInfo>({
                    name: "net",
                    valueType: eKPIType.String,
                    type: eIndicateurType.info,
                    field: field2
                }),
            ]
        });
    }

    protected async GetCPMIndicateurOptions(kpiType: eKPIType = eKPIType.Price): Promise<IndicateurOption[]> {
        const kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserKPIs();
        const lnk_kpis = await KPIsManagerCache.GetInstance(this._objectType.name).GetLnkHasKPIs();

        const medias = await KPIsManagerCache.GetMedias();
        const mediasPress = medias.find(m => m.Name === "PRESSE");

        const netKpi = kpis.find(k => k.Name == eKPI.Net);
        const netLnkKpi = lnk_kpis.find(lnk => lnk.KPI === netKpi["@rid"]);

        const cpmIOs = [];
        const getIndicateurCPM = (name: string, performanceKPI: eKPI, volumeKPI: eKPI) => {
            const pKpi = kpis.find(k => k.Name == performanceKPI);
            const vKpi = kpis.find(k => k.Name == volumeKPI);
            if (!pKpi || !vKpi)
                return;
            const pLnKpi = lnk_kpis.find(lnk => lnk.KPI === pKpi["@rid"]);
            const vLnkKpi = lnk_kpis.find(lnk => lnk.KPI === vKpi["@rid"]);

            const cpm = this.CreateCPM(name, kpiType, netLnkKpi, netKpi, pLnKpi, pKpi, mediasPress, vLnkKpi, vKpi);
            cpmIOs.push({ indicateur: cpm, columnType: eColumnType.KPI });
        };

        getIndicateurCPM("cpm_net", eKPI.TotalCirculation, eKPI.Total);
        getIndicateurCPM("cpm_paye_net", eKPI.PaidCirculation, eKPI.Paid);

        return cpmIOs;
    }

    private CreateCPM(name: string, kpiType: eKPIType, netLnkKpi: lnk_HasKPI, netKpi: ref_KPIs, diffusionLnKpi: lnk_HasKPI, diffusionKpi: ref_KPIs, mediasPress: ref_Media, nbtotalLnkKpi: lnk_HasKPI, nbtotalKpi: ref_KPIs) {
        return Typed<IndicateurComputed>({
            name: GetIndicateurKPIName(name, kpiType),
            valueType: eKPIType.Price,
            operator: "/",
            type: eIndicateurType.computed,
            options: { rate: 1000, isPriceReturned: kpiType == eKPIType.PriceReturned },
            indicateurs: [
                Typed<IndicateurKPI>({
                    name: "net",
                    valueType: eKPIType.Price,
                    type: eIndicateurType.kpi,
                    field: netLnkKpi.Id,
                    options: { rid: netKpi["@rid"], isPriceReturned: kpiType == eKPIType.PriceReturned }
                }),

                Typed<IndicateurComputed>({
                    name: 'diviseur_total',
                    valueType: eKPIType.Number,
                    type: eIndicateurType.computed,
                    operator: '+',
                    indicateurs: [
                        Typed<IndicateurKPI>({
                            name: "diffusion_total",
                            valueType: eKPIType.Number,
                            type: eIndicateurType.kpi,
                            field: diffusionLnKpi.Id,
                            options: {
                                rid: diffusionKpi["@rid"],
                                filter: { Media: mediasPress?.["@rid"] }
                            }
                        }),
                        Typed<IndicateurKPI>({
                            name: "nb_total",
                            valueType: eKPIType.Number,
                            type: eIndicateurType.kpi,
                            field: nbtotalLnkKpi.Id,
                            options: {
                                rid: nbtotalKpi["@rid"],
                                filterIgnore: { Media: mediasPress?.["@rid"] }
                            }
                        })
                    ]
                }),
            ]
        });
    }

}