import { ref_Discount } from 'hub-lib/models/types/vertex.bin';
import * as React from 'react';
import { SelectorDiscountComputation } from './SelectorDiscountComputation';
import { NumericTextBox, NumericTextBoxHandle } from '@progress/kendo-react-inputs';
import { GetCurrentLocale } from 'trad-lib';
import { AgreementValueComparer } from '../../../discounts/AgreementValueComparer';
import { store } from '../../../../../../redux/store';
import { setMessage } from '../../../../../../redux/messageEditorSlice';

type RateCellComponentProps = { discount: ref_Discount };
export function RateCellComponent({ discount }: RateCellComponentProps) {

    const editable = true;

    const { computation, value } = SelectorDiscountComputation(discount.DiscountClass);
    const rate = Math.abs((value?.Rate ?? 0) * 100);
    const [isEditing, setIsEditing] = React.useState(false);
    const ref = React.createRef<NumericTextBoxHandle>();

    const onChange = (newValue: number) => {
        const message = store.getState().messageEditor.get();
        const netType = store.getState().messageEditor.currentNetType;

        const d = message.Discounts.find(d => d.DiscountClass == discount.DiscountClass);
        d.IsRate = true;
        if (!d[netType])
            d[netType] = { Rate: 0, Value: 0 };
        d[netType].Rate = newValue / 100;
        if (d.IsCPM) {
            d.IsCPM = false;
            delete d.ValueCPM;
            delete d.KpiCPM;
        }
        store.dispatch(setMessage(message))
    }

    React.useEffect(() => {
        if (isEditing) {
            ref.current.focus();
            ref.current.element.select();
        }
    }, [isEditing])

    if (editable && isEditing) {
        let id = `rate_${discount.DiscountClass}`;
        return (
            <div style={{ width: '100%' }}
                onKeyDown={e => {
                    if (e.code == "Enter" || e.code == "NumpadEnter")
                        setIsEditing(false);
                }}>
                <NumericTextBox
                    ref={ref}
                    format="n8"
                    onBlur={() => setIsEditing(false)}
                    className={'CellNumberComponent-editing-cell'}
                    id={id}
                    spinners={true}
                    min={0}
                    max={100}
                    step={0.01}
                    value={rate}
                    onChange={(e) => onChange(e.value)} />
            </div>);
    }

    const template = (_value) => _value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 })

    let className = "";
    let star = false;
    if (!computation?.Discount?.IsRate) {
        star = true;
        className = "gray-font";
    }

    return <div
        style={{ position: 'relative' }}
        className={className + ` ${editable ? 'editable-cell-discount' : ''} `}
        onClick={() => {
            if (!editable)
                return;
            setIsEditing(true);
        }}>

        {computation
            && <AgreementValueComparer
                type="rate"
                discount={computation.Discount}
                formater={template} />}

        {star && <div className="star_cell" style={{ marginRight: -12 }}>*</div>}
        {template(rate) + '%'}
    </div>
}