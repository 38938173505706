
export function LoadFakeStorage(data?: { [p: string]: string }) {
    const keys: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
        keys.push(localStorage.key(i));
    }
    keys.forEach(k => localStorage.removeItem(k));
    Object.entries(data ?? {}).forEach(([k, v]) => {
        localStorage.setItem(k, v);
    })
}
