import { eStateType, ref_Messages } from "hub-lib/dto/client/ref_Messages.bin"
import { AdwRow, ADWColumn, getIcon, TooltipManager } from "adwone-lib/index";
import { Trad, TradProp } from "trad-lib";
import { clone, GetSubElement } from "hub-lib/tools.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { ConsoleDebug, IsMMUser } from "../../../../utils/localstorage.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { props as GridBaseProps } from "../GridBase.bin";
import { DataGrid, DataGridProps } from "./DataGrid";
import { Client } from "hub-lib/client/client.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { IndicateurToString } from "adwone-engine/index.bin";
import { Notify } from "../../../../utils/Notify.bin";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";
import { store } from "../../../../redux/store";

const dicoNotifColumnChanged = {};
export async function GetDefaultColumnConfiguration(objectType: string) {

    let configuration: ref_TableConfigurations = null;

    // first check if user has a local configuration for this table type and path in local storage
    const userConfLocalSto = store.getState().columsConfigurations?.configurations?.[objectType]?.table;
    if (userConfLocalSto && objectType == userConfLocalSto.Table)
        configuration = clone(userConfLocalSto);
    else {
        // if not, check if user has a default configuration for this table type in db
        [configuration] = (await Client.searchVertex(ref_TableConfigurations.name, {
            Table: objectType,
            Path: window.location.pathname,
            default: true
        }))?.data?.results;
    }

    //
    if (configuration) {
        const allIndicateurs = (await AggregatorFactory.GetInstance().Get(objectType).Provide()).map(i => i.indicateur);
        const cpy = [...configuration.Columns];
        configuration.Columns = configuration.Columns.filter(c => allIndicateurs.some(o => IndicateurToString(o) === IndicateurToString(c)));
        ConsoleDebug(`finalConf.Columns => filtered`, cpy, configuration.Columns)

        /** Colonnes ignorées */
        if (!dicoNotifColumnChanged[configuration.Name]) {
            const columnRemoved = cpy.filter(c => !configuration.Columns.some(f => f === c) && c?.name);
            if (columnRemoved?.length) {
                Notify(`${Trad("columns_changed")} ` + columnRemoved.map(c => c.name).join(", "), "warning");
                dicoNotifColumnChanged[configuration.Name] = true;
            }
        }

        await AggregatorFactory.GetInstance().Get(objectType).UpdateIndicateursNames(configuration.Columns, allIndicateurs);
    }

    /** Ce cas ne devrait normalement pas arriver car on est censé avoir un tableau par défaut */
    if (!configuration) {
        configuration = new ref_TableConfigurations();
        configuration.Name = Trad("empty_conf");
        configuration.Columns = [];
    }

    return configuration;
}

export async function CreateGrid(params?: {
    vertexParams?: any,
    vertexGridParams?: Partial<GridBaseProps<ref_Messages>>,
    columnFilter?: (col: ADWColumn<ref_Messages>) => boolean,
    templateCell?: (property: keyof ref_Messages, cellValue: any, dataItem: AdwRow<ref_Messages>, baseCell: any) => any,
    dataGridProps?: Partial<DataGridProps<ref_Messages>>,
    configuration?: ref_TableConfigurations
}) {
    let columns: ADWColumn<ref_Messages>[] = [];
    const hiddenProperties: (keyof ref_Messages)[] = [];
    const getDiffCol = (prop: keyof ref_Messages, isDeversement: boolean = false) => {
        const diffColumn = new ADWColumn<ref_Messages>(TradProp(prop, ref_Messages), prop, ePropType.Boolean, true)
        diffColumn.cellContent = (cell, row: AdwRow<ref_Messages>) => {
            const { Formated, Value } = cell;
            if (Value === "-1" || (isDeversement && Value === undefined))
                return <div style={{ textAlign: "center" }}> - </div>

            const diff = isDeversement ? Value : Value === "0";
            return <div className={diff ? "red_indicator" : "green_indicator"} />
        };
        hiddenProperties.push(prop);
        return diffColumn;
    }

    const checkLinkCol = (prop: string) => {
        const column = new ADWColumn<ref_Messages>(prop, prop, ePropType.Boolean, true);
        column.titleHeader = " ";
        column.width = 40;
        column.autoTooltip = false;
        column.cellContent = (cell, row: AdwRow<ref_Messages>) => {
            const { Formated, Value } = cell;
            const isOk = Value == "1";
            if (!isOk)
                return <div
                    style={{ color: 'darkorange', display: 'flex' }}
                    onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("check_mm_links") })}>
                    {getIcon('error_filled')}
                </div>;
            return <div />
        };
        hiddenProperties.push(prop as any);
        return column;
    }

    const diffC0Column = getDiffCol("Deversement.InfoCO.Diff" as any, true);
    const diffF0Column = getDiffCol("Deversement.InfoFO.Diff" as any, true);
    const diffColumn = getDiffCol("compliance_placement_pige" as keyof ref_Messages);

    const pricingLockedColumn = new ADWColumn<ref_Messages>(TradProp("PricingLocked", ref_Messages), "PricingLocked", ePropType.Boolean, true);
    pricingLockedColumn.cellContent = (cell, row: AdwRow<ref_Messages>) => {
        const { Formated, Value } = cell;
        return <div style={{ display: "flex" }}>{Value ? getIcon("locked") : ""}</div>
    };
    columns.push(pricingLockedColumn);
    hiddenProperties.push("PricingLocked");
    
    columns.push(checkLinkCol('checklink.Format'));
    columns.push(checkLinkCol('checklink.Placement'));
    columns.push(checkLinkCol('checklink.AllLinks'));

    if (RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)) {
        columns.push(diffC0Column);
        columns.push(diffF0Column);
    }
    columns.push(diffColumn);

    if (IsMMUser())
        hiddenProperties.push('Group');

    if (params?.columnFilter)
        columns = columns.filter(params?.columnFilter);

    return new DataGrid({
        objectPrototype: ref_Messages,
        configuration: params?.configuration ?? await GetDefaultColumnConfiguration(ref_Messages.name),
        columns,
        hiddenProperties,
        customConfCol: true,
        vertexParams: {
            Active: true,
            ...params?.vertexParams
        },
        width: {
            ["Deversement.InfoCO.Diff" as any]: 40,
            ["Deversement.InfoFO.Diff" as any]: 40,
            "Status": 85,
            Media: 100,
            Currency: 100,
            ReturnedCurrency: 100,
            ...params?.vertexGridParams?.width
        },
        frozenLeft: [],
        ...params?.vertexGridParams,
        ...(params?.dataGridProps ?? {})
    }) as any;
}