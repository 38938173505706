import * as React from 'react'
import { TranslateGrid } from '../../VertexGrid/Adwone-admin/TranslateGrid.bin'
import Template from '../Template'

class Translate extends React.Component<any, any> {
    render(){
        return (
            <>
                <div className="grid_container">
                    <TranslateGrid/>
                </div>
            </>
        );
    }
}

export default Template(Translate);