import { ref_AdvertiserGroups, ref_AdvertiserGroupsId } from "./ref_AdvertiserGroups.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_AdvertiserGroups, mm_AdvertiserGroupsId } from "./mm_AdvertiserGroups.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasAdvertiserGroupId = rid;

export class vw_mm_HasAdvertiserGroup extends ReferentialHasViews<src_MMId, mm_AdvertiserGroupsId, ref_AdvertiserGroupsId> {
	"@rid"?: vw_mm_HasAdvertiserGroupId;

}