import * as React from 'react'
import { TooltipManager } from '../../CustomTooltip';
import { Switch } from '@progress/kendo-react-inputs';
import { RowSize, styleGridContainer } from '../../../styles/theme';
import { Grid } from '@material-ui/core';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';
import { IRid } from 'hub-lib/models/IRid.bin';
import { CustomTreeList } from '../../VertexGrid/Generic/CustomTreeList';
import { TreeListRefNode } from '../../VertexGrid/Adwone-admin/Referential/ReferentialTreeList';
import { TreeListCellProps, TreeListColumnProps, TreeListTextFilter } from '@progress/kendo-react-treelist';
import { Trad } from 'trad-lib';
import { clone, GetFlatElements } from 'hub-lib/tools.bin';
import Loader from '../Loader';

export type ListItemContext = {
    label: string;
    Rights: any;
    data: IRid[];
    allRights: any;
    allProperty: string;
    lnkProperties: string[];
    parentRid?: string;
    enabledProps?: any;
    allChildrenProps?: string[];
    allNoParentProp?: string;
    readOnlyItemIds?: string[];
}

export const refAllItem = (itemRights: ListItemContext, onChange?: (event, data?) => void) => {
    const fakeItem = itemRights.allRights?.["@rid"] == "#-1:-1";

    return <div key={`${itemRights.label}_${Date.now()}`}>
        <div style={{ float: 'left', fontWeight: 'bold', display: "flex", alignItems: "center", height: RowSize }}>
            <span>{itemRights.label}</span>
        </div>
        <div style={{ float: 'right', display: "flex", alignItems: "center", height: RowSize }}>
            <Switch
                size='small'
                checked={itemRights.allRights?.[itemRights.allProperty]}
                onChange={(event) => {
                    itemRights.allRights[itemRights.allProperty] = !itemRights.allRights[itemRights.allProperty]
                    if (fakeItem || itemRights.allProperty == "AllAdvertiserGroups")
                        itemRights.Rights["AllNoParentAdvertisers"] = itemRights.allRights[itemRights.allProperty];
                    //Tous les liens du contexte sont supprimés.
                    //Dans le cas des annonceurs, les annonceurs du groupe seulement sont supprimés
                    itemRights.lnkProperties.forEach(prop => {
                        delete itemRights.Rights[prop];
                    });

                    if (onChange)
                        onChange(event);
                }}
            />
        </div>
    </div>
}

export const refListItem = (props, itemRights: ListItemContext, onChange?: (event, data?) => void) => {
    const { dataItem, ...others } = props;
    const fakeItem = dataItem["@rid"] == "#-1:-1";
    return (
        <li {...others} className={`k-item ${(itemRights?.parentRid == dataItem["@rid"]) ? "is-selected" : ""}`}>
            <div style={{ width: "100%" }}
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: dataItem.Name })}
                className={`clearfix`}
                key={"moda" + dataItem["@rid"]}>
                <div className='listitem-label-rights'>
                    <span>{dataItem.Name}</span>
                </div>
                <div style={{ float: "right", display: "flex", alignItems: "center", height: RowSize }}>
                    {!fakeItem &&
                        <Switch
                            size='small'
                            disabled={itemRights.readOnlyItemIds?.includes(dataItem["@rid"])}
                            checked={itemRights.allRights?.[itemRights.allProperty]
                                || itemRights.Rights?.[itemRights.lnkProperties[0]]?.some((e: any) => e["@rid"] == dataItem["@rid"])
                                || itemRights.readOnlyItemIds?.includes(dataItem["@rid"])}
                            onChange={(event) => {
                                if (!itemRights.Rights?.[itemRights.lnkProperties[0]])
                                    itemRights.Rights[itemRights.lnkProperties[0]] = [];
                                if (!event.target.value) {
                                    if (itemRights.allRights[itemRights.allProperty]) {
                                        itemRights.Rights[itemRights.lnkProperties[0]] = itemRights.data.filter((e: any) => e["@rid"] !== dataItem["@rid"]).map(d =>
                                            ({ "@rid": d["@rid"], ...itemRights?.enabledProps }));
                                        itemRights.allRights[itemRights.allProperty] = false;
                                        if (fakeItem)
                                            itemRights.Rights["AllNoParentAdvertisers"] = false;
                                    }
                                    else
                                        itemRights.Rights[itemRights.lnkProperties[0]] = itemRights.Rights[itemRights.lnkProperties[0]].filter((e: any) => e["@rid"] !== dataItem["@rid"])
                                } else {
                                    itemRights.Rights[itemRights.lnkProperties[0]].push({ "@rid": dataItem["@rid"], ...itemRights?.enabledProps })
                                    if (itemRights.data.length == itemRights.Rights[itemRights.lnkProperties[0]].length) {
                                        itemRights.allRights[itemRights.allProperty] = true;
                                        if (fakeItem)
                                            itemRights.Rights["AllNoParentAdvertisers"] = true;
                                        itemRights.lnkProperties.forEach(prop => {
                                            delete itemRights.Rights[prop];
                                        });
                                    }
                                }
                                if (onChange)
                                    onChange(event, dataItem);
                            }}
                        />
                    }
                </div>
            </div>
        </li>
    );
};

type ListBoxRightsArgs = { context: ListItemContext, className?: string, onItemClick?: (event: ListBoxItemClickEvent) => void, onSwitchChange?: (event, data?) => void };

type TreeRightsArgs = { activeParent?: boolean, columnProperties?: { field: string, title: string, subProperty?: string }[] } & ListBoxRightsArgs;

export function ListBoxRights({ context, className, onItemClick, onSwitchChange }: ListBoxRightsArgs) {
    return <>
        {context.Rights && context.allRights &&
            <Grid item xs={6} className={className ? className : "message_details_leftcombo"}
                style={{ ...styleGridContainer.rights, overflow: 'hidden' }}>
                {refAllItem(context, onSwitchChange)}
                <ListBox data={context.data ?? []}
                    className='listbox-rights'
                    style={styleGridContainer.listboxRights}
                    onItemClick={onItemClick}
                    item={(props) => refListItem(props, context, onSwitchChange)}
                    textField="Label" />
            </Grid>}
    </>
}

type TreeRightsState = {
    data: TreeListRefNode[],
    columns: TreeListColumnProps[]
}

export function TreeRights({ context, className, activeParent, columnProperties, onItemClick, onSwitchChange }: TreeRightsArgs) {

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [state, setState] = React.useState<TreeRightsState>(null);
    const { data, columns } = state ?? {};

    const getLnkProperties = (level: number) => {
        const _level = (level < context.lnkProperties.length) ? level : context.lnkProperties.length - 1;
        return context.lnkProperties[_level];
    }

    const updateTree = (dataItem: TreeListRefNode, checked: boolean, updateChildren: boolean = true) => {
        //console.log("dataItem", dataItem);

        let updateParent = activeParent;
        let allChildren = false;
        let parentItem;
        let parentAllChildrenProp = null;
        let parentNode: TreeListRefNode = null;
        const fakeItem = dataItem["@rid"] == "#-1:-1";
        const fakeParent = dataItem["parent"] == "#-1:-1";

        if (dataItem["parent"]) {
            if (fakeParent) {
                if (context.allNoParentProp)
                    allChildren = context.Rights[context.allNoParentProp];
            }
            else {
                parentAllChildrenProp = context?.allChildrenProps?.[dataItem.level - 1];
                parentItem = context.Rights?.[getLnkProperties(dataItem.level - 1)]?.find((e: any) => e["@rid"] == dataItem["parent"]);
                allChildren = parentItem?.[parentAllChildrenProp];
            }
            parentNode = GetFlatElements(data, 'Children')?.find(d => d.id == dataItem["parent"]);
        }

        dataItem["Activated"] = checked && !dataItem["Disabled"];

        if (checked) {
            if (!context.Rights?.[getLnkProperties(dataItem.level)])
                context.Rights[getLnkProperties(dataItem.level)] = [];
            const newItem = { "@rid": dataItem["@rid"], ...context?.enabledProps };

            const allChildrenProp = context?.allChildrenProps?.[dataItem.level];
            if (updateChildren) {
                if (fakeItem) {
                    if (context.allNoParentProp)
                        context.Rights[context.allNoParentProp] = true;
                }
                else if (allChildrenProp)
                    newItem[allChildrenProp] = true;
            }

            const existingItem = context.Rights[getLnkProperties(dataItem.level)].find((e: any) => e["@rid"] == dataItem["@rid"]);;
            if (!allChildren && !existingItem && !fakeItem)
                context.Rights[getLnkProperties(dataItem.level)].push(newItem);
        }
        else if (context.Rights?.[getLnkProperties(dataItem.level)]) {

            if (updateChildren || !dataItem.Children || !dataItem.Children.some(c => c["Activated"]))
                context.Rights[getLnkProperties(dataItem.level)] = context.Rights[getLnkProperties(dataItem.level)].filter((e: any) => e["@rid"] !== dataItem["@rid"]);
            if (allChildren) {
                //Si un enfant est désactivé alors que le parent a la propriété "tous les enfants" il faut ajouter les autres enfants dans les droits
                const otherChildren = parentNode.Children.filter(c => c.id != dataItem["@rid"]);
                if (fakeParent) {
                    if (context.allNoParentProp)
                        context.Rights[context.allNoParentProp] = false;
                }
                else if (parentItem) //Suppression de la propriété "tous les enfants" dans le parent
                    parentItem[parentAllChildrenProp] = false;
                if (otherChildren.length > 0) {
                    for (const child of otherChildren)
                        updateTree(child, true);
                    updateParent = false;
                }
            }
            else
                updateParent = parentNode && !parentNode.Children.some(c => c["Activated"]);
        }

        if (dataItem.Children && updateChildren)
            for (const child of dataItem.Children)
                updateTree(child, checked);
        if (dataItem.level > 0 && updateParent && parentNode)
            updateTree(parentNode, checked, false);
    };

    const createNode = (d: any, level: number = 0, parent?: TreeListRefNode): TreeListRefNode => {
        let node: TreeListRefNode = new TreeListRefNode();
        node = {
            id: d["@rid"] ?? "#-1:-1",
            level: level,
            ...d
        };
        let outOfPerimeter = false;
        if (columnProperties?.some(c => c.field == "lnkCurrencies")) {
            node["lnkCurrencies"] = node?.["lnkCurrencies"]?.length ? node["lnkCurrencies"] : parent?.["lnkCurrencies"];
            outOfPerimeter = (context.enabledProps?.["Currency"] && !node?.["lnkCurrencies"]?.some(l => l.out == context.enabledProps["Currency"]))
                || (context.enabledProps?.["Customer"] && !context.enabledProps["Customer"].includes(node["@rid"]));
        }

        let allChildren = false;
        const allNoParent = context?.allNoParentProp ? context.Rights[context.allNoParentProp] : false;
        //allChildren est vrai si son parent à la propriété tous les enfants à vrai
        if (parent) {
            const allChildrenProp = context?.allChildrenProps?.[level - 1];
            if (parent.id == "#-1:-1") // cas particulier des enfants sans parent
                allChildren = allNoParent;
            else if (allChildrenProp) {
                const parentItem = context.Rights?.[getLnkProperties(level - 1)]?.find((e: any) => e["@rid"] == parent.id);
                allChildren = parent["Activated"] && parentItem && parentItem[allChildrenProp];
            }
        }
        let otherActivated = false;
        //Le noeud fake est activé si la propriété tous sans parent est activée ou qu'il y a un enfant sans parent dans les droits
        if (node.id == "#-1:-1") {
            const levelUpIds = context.Rights?.[getLnkProperties(level + 1)]?.map(r => r["@rid"]);
            otherActivated = allNoParent || (levelUpIds && d["children"].some(c => levelUpIds.includes(c["@rid"])));
        }
        node["Disabled"] = outOfPerimeter || context.allRights?.[context.allProperty] || context.readOnlyItemIds?.includes(d["@rid"]);
        node["Activated"] = !outOfPerimeter && (context.allRights?.[context.allProperty]
            || context.Rights?.[getLnkProperties(level)]?.some((e: any) => e["@rid"] == d["@rid"])
            || allChildren || otherActivated);
        if (d["children"])
            node.Children = d["children"].map(i => createNode(i, level + 1, node));
        return node;
    }

    React.useEffect(() => {
        if (!state) {
            const activeCell = ({ dataItem, onChange }: TreeListCellProps) =>
                <td>
                    <div style={{ float: "right", display: "flex", alignItems: "center", height: RowSize }}>
                        <Switch
                            size='small'
                            disabled={dataItem?.["Disabled"]}
                            checked={dataItem?.['Activated']}
                            onChange={(event) => {
                                dataItem['Activated'] = event.target.value
                                onChange({ dataItem, level: [], syntheticEvent: null })
                            }} />
                    </div>
                </td>
            const columns: TreeListColumnProps[] = [{
                field: "Name",
                width: "100%",
                title: Trad("Name"),
                filter: TreeListTextFilter,
                expandable: true,
                resizable: true
            }];
            if (columnProperties)
                columnProperties.forEach(property =>
                    columns.push({
                        field: property.field,
                        width: 70,
                        title: property.title,
                        cell: (cellProps) => {
                            const cell = (props: any) => {
                                let value = "";
                                if (property.subProperty)
                                    value = props.dataItem?.[property.field]?.[0]?.[property.subProperty];
                                else
                                    value = props.dataItem?.[property.field];
                                return <td {...props} style={{ display: "contents" }}>{value}</td>;
                            }
                            return <td>{cell(cellProps)}</td>
                        },
                        resizable: true
                    }));
            columns.push({
                field: "Activated",
                width: 100,
                cell: activeCell
            });
            setState({
                data: context.data.map((el) => createNode(el, 0)),
                columns: columns
            });
        }
    })
    console.log(context.Rights);
    return <>
        {context.Rights && context.allRights &&
            <Grid item xs={6} className={className ? className : "message_details_leftcombo"}
                style={{ ...styleGridContainer.rights, overflow: 'hidden' }}>
                {refAllItem(context, (e, data) => {
                    context.Rights[context.lnkProperties[0]] = undefined;
                    setState(null);
                    forceUpdate();
                    if (onSwitchChange)
                        onSwitchChange(e, data);
                })}
                <div style={styleGridContainer.treeListRights}>
                    {!state && <Loader />}
                    {state && <CustomTreeList
                        data={data}
                        expandField="Expanded"
                        subItemsField="Children"
                        columns={columns}
                        gridProps={{
                            onItemChange: (e) => {
                                const item = GetFlatElements(data, 'Children')?.find(d => d.id == e.dataItem.id);
                                if (!item) {
                                    console.log(`item not found`, e.dataItem, data);
                                    return;
                                }
                                console.log("item", item);
                                updateTree(item, e.dataItem['Activated']);
                                forceUpdate();
                                if (onSwitchChange)
                                    onSwitchChange(e, data);
                            }
                        }}
                    />}
                </div>
            </Grid>}
    </>
}