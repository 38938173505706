import * as React from "react";
import { Client } from "hub-lib/client/client.bin";
import { AdvertiserHierarchyFilter, ref_FilterConfigurations } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { clone, compareObjects, firstOrDefault, GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { ProjectState, saveDashboard, saveExport, saveFilters, saveTable } from "../../../redux/projectSlice";
import { IconAction } from "../ModeleCreator/ModeleCreatorBase";
import { ref_ConfigurationsBase } from "hub-lib/dto/client/ref_ConfigurationsBase.bin";
import { RootState, store } from "../../../redux/store";
import { ref_ProjectConfigurations } from "hub-lib/dto/client/ref_ProjectConfigurations.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { ref_ExportConfigurations } from "hub-lib/dto/client/ref_ExportConfigurations.bin";
import { ref_DashboardConfigurations } from "hub-lib/dto/client/ref_DashboardConfigurations.bin";

type SaveButtonProps = {
    type: string,
    getConfiguration?: () => any;
    saveConfiguration?: (conf: any) => void;
    selector?: (root: RootState) => any;
}

export function SaveButton({ type, saveConfiguration, selector }: SaveButtonProps) {

    const elementSelector = selector ?? GetProjectSelector(type);
    const [baseConfiguration, setBaseConfiguration] = React.useState<ref_ConfigurationsBase>();
    const configuration: any = useSelector(elementSelector, compareObjects);

    React.useEffect(() => {
        if (configuration?.["@rid"] && (baseConfiguration?.["@rid"] != configuration["@rid"]))
            Client.searchVertex(type, { '@rid': configuration["@rid"] })
                .then((res) => setBaseConfiguration(firstOrDefault(res.data.results)));
    });

    const isDisabled = !baseConfiguration
        || !configuration?.["@rid"]
        || Compare(configuration, baseConfiguration)

    return <IconAction
        disabled={isDisabled}
        onClick={() => {
            if (saveConfiguration) {
                saveConfiguration(configuration);
            } else {
                Save(configuration);
            }
            setBaseConfiguration(configuration);
        }}
        tooltip={Trad("save")} icon={"save"} />
}

function Save(configuration: ref_ConfigurationsBase) {
    switch (configuration.DocumentType) {
        case ref_FilterConfigurations.name:
            store.dispatch(saveFilters(configuration as ref_FilterConfigurations));
            break;
        // case ref_TableConfigurations.name:
        //     store.dispatch(saveTable(configuration as ref_TableConfigurations));
        //     break;
        // case ref_ExportConfigurations.name:
        //     store.dispatch(saveExport(configuration as ref_ExportConfigurations));
        //     break;
        case ref_DashboardConfigurations.name:
            store.dispatch(saveDashboard(configuration as ref_DashboardConfigurations));
            break;
    }
}

function Compare(configuration: ref_ConfigurationsBase, base: ref_ConfigurationsBase) {
    switch (configuration.DocumentType) {
        case ref_FilterConfigurations.name:
            return compareObjects(AdvertiserHierarchyFilter.getFilters((configuration as ref_FilterConfigurations).Filters), AdvertiserHierarchyFilter.getFilters((base as ref_FilterConfigurations).Filters));
        default:
            return compareObjects(configuration, base);
    }
    return false;
}

export function GetProjectSelector(type: string): (root: RootState) => any {
    switch (type) {
        case ref_FilterConfigurations.name:
            return (root: RootState) => root.project['filtersModele'];
        // case ref_TableConfigurations.name:
        //     return 'columnsModele';
        case ref_ProjectConfigurations.name:
            return (root: RootState) => root.project['project'];
        // case ref_ExportConfigurations.name:
        //     return (root: RootState) => root.project['exportModele'];
    }
}

export function GetStoreModeleElement<T>(type: string, selector?: (root: RootState) => any) {
    const elementSelector = selector ?? GetProjectSelector(type);
    const modele = elementSelector(store.getState());
    return clone(modele) as any as T;
}