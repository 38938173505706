import * as React from 'react'
import { Grid } from '@material-ui/core';
import { Trad } from 'trad-lib';
import Template from '../Template';
import { CustomerSettingsForm, eMode } from './CustomerSettingForm.bin';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';
let clientKey = 0

class TState{
    openNewClient: boolean = false
    selectedCompany?: ref_Companies
}
class NewCustomerSetting extends React.Component<any, TState>{
    constructor(props: any) {
        super(props)
        let newState = new TState()
        this.state = newState
    }
    openNewClient= (company: ref_Companies) => {
        this.setState({
            openNewClient:true,
            selectedCompany: company
        })
    }

    render() {
        return (
            <>
                <div className="grid_container" style={{height: "auto"}}>
                    <div style={{ width: '100%'}}>
                        <Grid item xs={12}>
                            <BreadcrumbsCustom elements={[
                                    { text: Trad("home"), href: "/" },
                                    { text: Trad("customer_settings"), href: "/customers"},
                                    { text: Trad("add_customer_settings")}
                                ]} />
                        </Grid>
                        <CustomerSettingsForm
                            mode={eMode.create}
                            companiesKey={`${clientKey}${this.state?.selectedCompany?.constructor.name}`}
                            openNewClient={this.openNewClient}
                            key={clientKey}
                            CloseNewclient={()=>this.setState({openNewClient:false})}
                            open={this.state.openNewClient}
                            newCompany={this.state?.selectedCompany}
                            closeDialog={()=>this.setState({openNewClient:false}, ()=>{clientKey+=1})}
                            />
                    </div>
                </div>
            </>
        )
    }
}

export default Template(NewCustomerSetting);