import * as React from 'react'
import { FavoriteVertexAutoComplete } from '../AdwAutocomplete/FavoriteVertexAutoComplete';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { Trad } from 'trad-lib';
import { TextField } from '@material-ui/core';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { GetDefaultBroadcastAreas } from '../../redux/messageEditorSlice';
import { GetHashCode } from 'hub-lib/tools.bin';

type BroadcastLinkSelectorProps = {
    onChange: (value) => void,
    defaultValue: ref_BroadcastAreas['@rid'],
    support: ref_Supports['@rid']
}

export function BroadcastLinkSelector({ support, onChange, defaultValue }: BroadcastLinkSelectorProps) {

    const [options, setOptions] = React.useState<ref_BroadcastAreas[]>();

    React.useEffect(() => {
        GetDefaultBroadcastAreas(support)
            .then(({ broadcastAreaDefault, broadcastAreaOptions }) => {
                console.log(`[BroadcastLinkSelector]`, support, broadcastAreaOptions)
                setOptions(broadcastAreaOptions);
            })
    }, [support])

    return <FavoriteVertexAutoComplete
        key={`BroadcastLinkSelector-${support}-${GetHashCode({ options })}`}
        options={options ?? []}
        type={ref_BroadcastAreas.name}
        label={Trad(ref_BroadcastAreas.name)}
        defaultValue={options => options.find(o => o["@rid"] == defaultValue)}
        onChange={onChange}
        onResetValue={(options: any) => undefined as any}
        renderInput={(params) =>
            <><TextField
                error={defaultValue === undefined}
                fullWidth
                {...params}
                label={Trad(ref_BroadcastAreas.name)}
                variant="outlined"
                inputProps={{
                    ...params.inputProps
                }}
            /></>
        } />
}