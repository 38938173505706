import * as React from "react";
import GroupsTree from "../../VertexGrid/Groups/GroupsTree.bin";
import Template from "../Template";

const Groups = () => {
    return (
        <GroupsTree />
    );
};

export default Template(Groups);
