import { KPIsManagerCache, lnk_HasPropertyTypeExtended } from "hub-lib/models/KPIsManager.bin";
import * as React from "react";
import { useSelector } from "react-redux";
import { GetEditorState, RootState, store } from "../../../../redux/store";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { TradValue } from "trad-lib";


type ValueChoiceCheckerArgs = { children?: any, propId: string, propValue: string, model: "ref_Messages" | "ref_Campaigns" }
export function ValueChoiceChecker({ children, propId, propValue, model }: ValueChoiceCheckerArgs) {

    const data = GetEditorState(model, store.getState()).get();
    const dataPropValue =  useSelector((root: RootState) => GetEditorState(model, root).data.ModelProperties[propId]);
    const [valueChoices, setValueChoices] = React.useState<lnk_HasPropertyTypeExtended["ValueChoices"]>();

    React.useEffect(() => {
        if (!valueChoices) {
            Promise.resolve().then(async () => {
                const properties: lnk_HasPropertyTypeExtended[] = await KPIsManagerCache.GetInstance(model).GetLnkHasProperties(data.Media);
                setValueChoices(properties?.find(p => p.Id == propId).ValueChoices);
            })
        }
    })

    if (!valueChoices)
        return <></>

    if (dataPropValue != valueChoices.find(v => v.value == TradValue(ref_Property.name, "Name", propValue))?.rid)
        return <></>

    return <>{children}</>
}