import * as React from "react"
import Grid from '@material-ui/core/Grid'
import { Trad, } from 'trad-lib';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../../redux/store';
import { setMessageSync } from '../../../redux/messageEditorSlice';
import { PlacementEditor } from './MessageEditor/PlacementEditor';
import { ContainerComponent } from "../Generic/ContainerComponent";
import { TextEditor } from "../TextEditor.bin";
import { CustomNumericTextBox } from "../Generic/Common.bin";

export function DiffusionPicker() {

    return <Grid container>
        <Grid item xs={6} className="message_details_leftcombo">
            <PlacementEditor broadcastPlacement />
        </Grid>
        <Grid item xs={6} className="message_details_rightcombo">
            <BroadcastGrossPicker />
        </Grid>
        <Grid item xs={12} className='block-container'>
            <BroadcastCommentsPicker />
        </Grid>
    </Grid>
}

function BroadcastCommentsPicker() {

    const BroadcastComments = useSelector((root: RootState) => root.messageEditor.get().ModelProperties?.BroadcastComments);
    const dispatch = useDispatch();

    return <ContainerComponent title={Trad('broadcasted_comment')} className="auto-height no-padding no-margin">
        <TextEditor
            content={BroadcastComments}
            onChange={value => {
                const msg = store.getState().messageEditor.get();
                msg.ModelProperties.BroadcastComments = value;
                dispatch(setMessageSync(msg))
            }} />
    </ContainerComponent>
}

function BroadcastGrossPicker() {

    const currencyCode = useSelector((root: RootState) => root.messageEditor.currencyCode);
    const BroadcastGross = useSelector((root: RootState) => root.messageEditor.get().KPIs.BroadcastGross);
    const dispatch = useDispatch();

    return <CustomNumericTextBox
        min={0}
        title={`${Trad('broadcasted_gross')} ${currencyCode}`}
        value={BroadcastGross}
        onChange={(e) => {
            const msg = store.getState().messageEditor.get();
            msg.KPIs.BroadcastGross = e.value;
            dispatch(setMessageSync(msg));
        }} />
}