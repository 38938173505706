import * as React from "react"
import { Format, GetKPITemplate } from "format-lib/index.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { ref_Offers } from "hub-lib/dto/client/ref_Offers.bin";
import { store } from "../../../../redux/store";
import { ref_OffersSerializable } from "../../../../redux/messageEditorSlice";
import { FormatExtended } from "hub-lib/dto/referential/FormatExtended.bin";
import { EmplacementExtended } from "hub-lib/dto/referential/EmplacementExtended.bin";
import { TradValue } from "trad-lib";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { toArray } from "hub-lib/tools.bin";

export const getElementLabel = (elmnt: IRid, offer: Pick<ref_Offers, "KPIs" | "Currency">, currencies: ref_Currencies[]) => {
    const baseStr = Format(elmnt, undefined, ref_Property.name);
    const priceTmplt = GetKPITemplate(eKPIType.Price);
    return offer ? `${baseStr} - ${priceTmplt(offer.KPIs.Gross)} ${currencies?.find(c => c['@rid'] === offer.Currency)?.Code}` : baseStr;
}

export const getBadgeElementLabel = (elmnt: IRid, offer: Pick<ref_Offers, "KPIs" | "Currency">, currencies: ref_Currencies[]) => {
    const baseStr = Format(elmnt, undefined, ref_Property.name);
    const str = TradValue(ref_Property.name, "Name", baseStr);
    return (offer ? <span style={{ display: 'flex', alignItems: 'center' }}>
        <div className='check_price_badge has_price' />{str}</span> : str) as string;
}

const IsOfferFormatExtended = (offer: ref_OffersSerializable, format: IRid["@rid"] | IRid["@rid"][], couleur: IRid["@rid"]): boolean => {
    const _formats = toArray(format);
    return _formats.includes(offer.ModelProperties.Format as string)
        && ((!couleur/* && !offer.ModelProperties.Couleur*/) || offer.ModelProperties.Couleur == couleur);
}

export const getPriceExistsComponent = (offer: Pick<ref_Offers, "@rid">) =>
    offer ? <div className='check_price has_price' /> : <div className='check_price has_no_price' />;

export const getFormatPriceExistsComponent = (storeKey?: 'messageEditor' | 'offer') => (format: FormatExtended): React.ReactNode => {
    return getPriceExistsComponent(getDefaultFormatOffer({ format: format?.Format, couleur: format?.Couleur, storeKey }));
}
type getFormatOfferProps = { format: IRid["@rid"], couleur: IRid["@rid"], placement?: IRid["@rid"] | IRid["@rid"][], offers?: ref_OffersSerializable[], storeKey?: 'messageEditor' | 'offer' }
export const getFormatOffer = ({ format, couleur, placement, offers, storeKey }: getFormatOfferProps) => {
    if (!storeKey) storeKey = 'messageEditor';
    if (placement === undefined)
        placement = store.getState()[storeKey]?.data?.Placement;
    const _placements = placement ? toArray(placement) : undefined;
    return (offers ?? store.getState()[storeKey].offers ?? [])
        .filter(o => o.ModelProperties)
        .find(o => IsOfferFormatExtended(o, format, couleur)
            && (_placements?.includes(o.ModelProperties.Emplacement as string) || (!_placements?.length && !o.ModelProperties.Emplacement))) ?? null;
}

type getDefaultFormatOfferProps = { format: IRid["@rid"], couleur: IRid["@rid"], offers?: ref_OffersSerializable[], storeKey?: 'messageEditor' | 'offer' }
export const getDefaultFormatOffer = ({ format, couleur, offers, storeKey }: getDefaultFormatOfferProps) => {
    if (!storeKey) storeKey = 'messageEditor';
    return (offers ?? store.getState()[storeKey]?.offers ?? [])
        .filter(o => o.ModelProperties)
        .find(o => IsOfferFormatExtended(o, format, couleur) && !o.ModelProperties.Emplacement) ?? null;
}

export const getLabelFormat = (format: FormatExtended, currencies: ref_Currencies[], storeKey?: 'messageEditor' | 'offer') => {
    if (!storeKey) storeKey = 'messageEditor';
    const defaultFormat = getDefaultFormatOffer({ format: format?.Format, couleur: format?.Couleur, storeKey })
    return TradValue(ref_Property.name, "Name", getElementLabel(format, defaultFormat, currencies));
}

export const getBadgeLabelFormat = (format: FormatExtended, currencies: ref_Currencies[], storeKey?: 'messageEditor' | 'offer') => {
    if (!storeKey) storeKey = 'messageEditor';
    const defaultFormat = getDefaultFormatOffer({ format: format?.Format, couleur: format?.Couleur, storeKey })
    return getBadgeElementLabel(format, defaultFormat, currencies);
}

export const getPlacementOffer = (placement: IRid["@rid"], offers?: ref_OffersSerializable[], storeKey?: 'messageEditor' | 'offer') => {
    if (!storeKey) storeKey = 'messageEditor';
    return (offers ?? store.getState()[storeKey]?.offers ?? [])
        .filter(o => o.ModelProperties)
        .find(o => IsOfferFormatExtended(o, store.getState()[storeKey]?.data.Format, store.getState()[storeKey]?.data?.ModelProperties?.["Couleur"])
            && o.ModelProperties.Emplacement == placement);
}

export const getPlacementPriceExistsComponent = (storeKey?: 'messageEditor' | 'offer') => (placement: EmplacementExtended) => {
    const found = getPlacementOffer(placement?.Emplacement, null, storeKey);
    if (found)
        console.log("[getPlacementPriceExistsComponent]", found);
    return getPriceExistsComponent(found);
}
export const getLabelPlacement = (placement: IRid, currencies: ref_Currencies[]) =>
    TradValue(ref_Property.name, "Name", getElementLabel(placement, getPlacementOffer(placement["@rid"]), currencies));

export const getBadgeLabelPlacement = (placement: IRid, currencies: ref_Currencies[], storeKey?: 'messageEditor' | 'offer') =>
    getBadgeElementLabel(placement, getPlacementOffer(placement["@rid"], null, storeKey), currencies);
