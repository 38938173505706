import { ADWColumn } from "adwone-lib";
import { ref_ImportTsmLog } from "hub-lib/dto/admin/ref_ImportTsmLog";
import { ref_Offers } from "hub-lib/dto/client/ref_Offers.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { Trad, TradProp } from "trad-lib";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Template from "../Template";
import { JSONViewer } from "../../ConfigurableComponents/JSONViewer";

export function TSMImportsGridComponent() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {

            const reportColumn = new ADWColumn(TradProp("Report"), "Report", ePropType.Any);
            reportColumn.width = 300;
            reportColumn.cellValue = (value, row) => {
                return <div style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative"
                }}>
                    <span>{value?.Message ?? "_"}</span>
                    <div style={{
                        position: 'absolute',
                        right: 0
                    }}>
                        <JSONViewer data={value} />
                    </div>
                </div>
            };

            const columns: ADWColumn<ref_ImportTsmLog>[] = [
                new ADWColumn(TradProp("Date"), "Date", ePropType.Datetime),
                reportColumn
            ];
            const hiddenProperties: (keyof ref_ImportTsmLog)[] = ["Date", "Report", "@rid", "rid" as any];
            //const properties: string[] = [];
            const grid = new VertexGrid<ref_ImportTsmLog>({
                disableStore: true,
                objectPrototype: ref_ImportTsmLog,
                devMode: false,
                columns,
                vertexParams: {
                    Active: true,
                    properties: ["*"]
                },
                width: {
                    Report: 300
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return <DefaultGrid
        objectPrototype={ref_ImportTsmLog}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            sort: [{ field: "Date", dir: "desc" }],
            uneditable: true
        }} />
}

export const TSMImportsGrid = Template(TSMImportsGridComponent);