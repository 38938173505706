import * as React from 'react'
import Template from '../Template'
import { ChangeRateConfig, CurrenciesGrid } from '../../VertexGrid/Currencies/CurrenciesGrid.bin'

import {PopupSettingsCurrencies} from './PopupSettingsCurrencies.bin'

class TState {
  config : ChangeRateConfig;
}

class Currencies extends React.Component<any, TState> {
  constructor(props: any) {
    super(props)
    let storage = localStorage.getItem('configCurrenciesGrid')

    this.state = {
      config: storage ? JSON.parse(storage) : undefined
    }
  }

  onValidate = (filtre: any, date: any) => {
    this.setState({
      config: {
        Company: filtre?.["@rid"],
        date: { start: date.start, end: date.end }
      }
    }, () => {
      localStorage.setItem('configCurrenciesGrid', JSON.stringify(this.state.config))
    })
  }

  onConfChange = () => {
    localStorage.setItem('configCurrenciesGrid', JSON.stringify(this.state.config))
  }

  render() {
    return (
      <>
        <div className="grid_container">

          {!this.state.config && <PopupSettingsCurrencies onValidate={this.onValidate} />}
          {this.state.config && <CurrenciesGrid config={this.state.config} onConfChange={this.onConfChange} />}

        </div>
      </>
    )
  }
}
export default Template(Currencies)