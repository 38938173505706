
import * as React from 'react'
import { ADWColumn, AdwAutocomplete, VertexAutocomplete } from "adwone-lib/index";
import { Client } from 'hub-lib/client/client.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { useEffect, useState } from 'react';
import { Trad } from 'trad-lib';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { GridBase } from '../../VertexGrid/Generic/GridBase.bin';
import { JSONViewer } from '../../ConfigurableComponents/JSONViewer';
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from '../../VertexGrid/Generic/ToolbarAdw';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { ConfigurationPanel } from '../../VertexGrid/Messages/ConfigurationPanel';
import Template from '../Template';
import { FavoriteVertexAutoComplete } from '../../AdwAutocomplete/FavoriteVertexAutoComplete';
import { select } from '../../../redux/linkMgrSlice';

const gridHeight = 800;

class LogRow {
  timestamp: Date;
  message: any;
  process_id: number;
  app_name: string;
}

export default function Pm2LogsGrid() {

  const [grid, setGrid] = useState<GridBase<LogRow>>(undefined)
  const [rows, setRows] = React.useState<LogRow[]>(null);
  const [jsonFiles, setJsonFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [nLines, setNLines] = useState("50");

  useEffect(() => {
    Promise.resolve().then(async () => {
      await Client.getPm2Logs().then(files => {
        files.sort((a, b) => b.name.localeCompare(a.name));
        if (!selectedFile) {
          setSelectedFile(files[0].name);
        }
        if (!rows) {
          const file = files.find(f => f.name === selectedFile);
          if (file)
            setRows(getFormattedData(file.data));
        }
        setJsonFiles(files);
      })

    });
  }, [rows, selectedFile, nLines])

  useEffect(() => {
    Promise.resolve().then(async () => {

      const dateCol = new ADWColumn<LogRow>(Trad("timestamp"), "timestamp", ePropType.Datetime);
      const messageCol = new ADWColumn<LogRow>(Trad("message"), "message", ePropType.String);
      dateCol.width = 350;
      messageCol.width = 600;
      console.log(jsonFiles, "jsonFiles")
      if (!rows) return;
      console.log(rows, "rows")

    const grid = new GridBase({
      objectPrototype: LogRow,
      rows,
      columns: [dateCol, messageCol]
    });

    setGrid(grid);
    });

  }, [rows, selectedFile, nLines])


  const getFormattedData = (data) => {
    console.log(nLines, "nlines")
    const lines = data.split('\n');
    const formattedData = lines
      .map(line => line.trim() && JSON.parse(line))
      .sort((a, b) => b?.timestamp?.localeCompare(a?.timestamp)).slice(0, nLines);
    return formattedData;
  }

  const confComponent = <ConfigurationPanel
    elements={[{
      type: "icon",
      title: () => Trad("filters"),
      icon: "filterAlt",
      element: <ConfigLog jsonFiles={jsonFiles} />
    }]}
  />

  const onChangeFile = (e) => {
    const file = jsonFiles.find(f => f.name === e.target.innerText);

    if (file) {
      setSelectedFile(file.name);
      setRows(getFormattedData(file.data));
      setGrid(null)
    }
  }
  const onChangeNlines = (e) => {
    const file = jsonFiles.find(f => f.name === selectedFile);
    if (file) {
      setNLines(e.target.innerText)
      setRows(null);
      setGrid(null)
    }
  }
  console.log(grid, "grid")

  // console.log({ selectedFile, grid, rows, jsonFiles })
  return (
    <div className='grid_container'>
      <div style={{ width: '100%' }}>
        <ToolbarAdw>
          <div className="count-elements margin-left">
            <div className='secondary-container-component'>
              <div style={{ width: 150, height: 25, marginLeft: 10 }} className='ModeleSelector-container'>
                <AdwAutocomplete
                  defaultValue={"50"}
                  options={["50", "100", "200", "500", "1000"]}
                  onChange={(e) => { onChangeNlines(e) }}
                />
              </div>
              <div style={{ width: 305, height: 25, marginLeft: 10 }} className='ModeleSelector-container'>
                <AdwAutocomplete
                  value={selectedFile || "Select a file to view logs"}
                  options={jsonFiles.map(f => f.name) || []}
                  onChange={(e) => { onChangeFile(e) }}
                />
              </div>
            </div>
          </div>
          <ConfigurationPanelContainer>
            {confComponent}
          </ConfigurationPanelContainer>
          <BreadcrumbsCustomContainer>
            <BreadcrumbsCustom
              elements={[
                { text: Trad("home"), href: "/" },
                { text: Trad("PM2 Logs") }
              ]} />
          </BreadcrumbsCustomContainer>
        </ToolbarAdw>
        <div style={{ height: gridHeight, position: 'relative' }}>
          {grid && <AdwTelerikGrid
            grid={grid}
            uneditable={true}
            gridHeight={gridHeight}

          />}
        </div>
      </div>
    </div>
  )

}
function ConfigLog({ jsonFiles }) {
  const [length, setLength] = React.useState(50)

  return (
    <div>
      {jsonFiles.map(file => (
        <div style={{ display: 'flex' }}>
          <div>
            <h3 style={{ marginRight: '5px', color: '#009BCE' }}>{file.name}</h3>
          </div>
          <div>
            <a style={{ lineHeight: '38px' }}
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                file.data
              )}`}
              download={file.name}>{"Télécherger"}
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

export const Pm2logs = Template(Pm2LogsGrid);