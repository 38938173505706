import * as React from "react";
import { GlobalAgreementsGrid } from "../../VertexGrid/GlobalAgreements/GlobalAgreementsGrid";
import Template from "../Template";

const GlobalAgreements = () => {
    return (
        <GlobalAgreementsGrid />
    );
};

export default Template(GlobalAgreements);
