import { ref_Brands, ref_BrandsId } from "./ref_Brands.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Brands, mm_BrandsId } from "./mm_Brands.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasBrandId = rid;

export class vw_mm_HasBrand extends ReferentialHasViews<src_MMId, mm_BrandsId, ref_BrandsId> {
	"@rid"?: vw_mm_HasBrandId;

}