import { Client } from 'hub-lib/client/client.bin';
import { TwoFactorVerifyRoute } from 'hub-lib/index.bin';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { UrlParser } from 'tools-lib'
import { Redirect } from 'react-router';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Trad } from 'trad-lib';
import { CustomButton } from '../ConfigurableComponents/CustomButton.bin';
import { Checkbox } from "@progress/kendo-react-inputs";

export const TwoFactorAuth = () => {
    const [otp, setOtp] = useState('');
    const [rememberBrowser, setRememberBrowser] = useState(false);
    const [uri, setUri] = useState('');
    const [incorrect, setIncorrect] = useState(false);
    const verifyCode = async (e: any) => {
        e.preventDefault();
        try {
            const res = await Client.Post(TwoFactorVerifyRoute, { otp, rememberBrowser, tokenBrowser: localStorage.getItem("tokenBrowser") });
            if (res.status === 200) {
                setIncorrect(false);

                if (res.data.token)
                    localStorage.setItem("tokenBrowser", res.data.token);

                const redirect = UrlParser("redirect") ?? "/";
                setUri(decodeURIComponent(redirect));
            }
        } catch (error) {
            setIncorrect(true);
        }
    }

    const sendEmail = async (e: any) => {
        try {
            await Client.Post(TwoFactorVerifyRoute + "/mail", {});
        } catch (error) {
            setIncorrect(true);
        }
    }

    return (
        <>
            {uri && <Redirect to={uri} />}
            <Grid container style={{
                width: "100%",
                height: "calc(100vh - 95px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Grid item>
                    <Card variant="outlined">
                        <CardHeader title={Trad("2FA_title")} subheader={Trad("2FA_sub_title")} />
                        <CardContent style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px"
                        }}>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                inputStyle={{ width: "30px", height: "40px" }}
                                renderInput={(props) => <input {...props} />}
                            />
                            {incorrect && <Alert severity="error" style={{ width: "100%", padding: "1%" }}>{Trad("2FA_incorrect")}</Alert>}
                            <a href="" onClick={sendEmail} style={{ fontSize: "10px" }}>{Trad("2FA_resend")}</a>
                            <CustomButton onClick={verifyCode} Label={Trad("btn_2FA_verify")} className={"custom_btn_primary_validation"} />
                            {/* <Button style={{ margin: "1vh", backgroundColor: "#009BCE", color: "white", width: "100%" }} onClick={verifyCode}>{Trad("btn_2FA_verify")}</Button> */}
                        </CardContent>
                        {/** checkbox rember browser */}
                        <CardContent style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px"
                        }}>
                            <Checkbox label={Trad("2FA_remember")} checked={rememberBrowser} onChange={(e) => setRememberBrowser(e.value)} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default TwoFactorAuth