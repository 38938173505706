import * as React from "react";
import {
    Paper,
    Popper,
    Grow,
    ClickAwayListener,
    MenuList,
    MenuItem,
    PopperPlacementType,
} from "@material-ui/core";
import EventEmitter from "events";

export class PopperState {
    placement?: PopperPlacementType;
    title?: string;
    childColor?: string;
    isIcon?: boolean;
    buttons?: {
        onClick: () => any;
        label: string | JSX.Element;
        disabled?: boolean;
    }[];
    open?: boolean;
    anchorEl: any;
}

export enum ePopperEvent {
    open = "open",
    close = "close",
}

export class PopperEventEmitter extends EventEmitter {
    emit(event: ePopperEvent, args: PopperState, originEvent?: any): boolean {
        return super.emit(event, args, originEvent);
    }

    addListener(event: ePopperEvent, listener: (args: PopperState) => void): any {
        return super.addListener(event, listener);
    }

    constructor() {
        super();
        this.setMaxListeners(100);
    }
}

export const popperEventEmitter: PopperEventEmitter = new PopperEventEmitter();

export class GenericPopper extends React.Component<any, PopperState> {
    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            anchorEl: null,
        };
    }

    componentDidMount() {
        popperEventEmitter.addListener(ePopperEvent.open, this.handleToggle);
    }

    componentWillUnmount() {
        popperEventEmitter.removeListener(ePopperEvent.open, this.handleToggle);
    }

    handleToggle = (args: PopperState, event?: any) => {
        event?.preventDefault();
        event?.stopPropagation();
        const clickOnAnotherPopper = this.state.open && args.anchorEl !== this.state.anchorEl;
        this.setState({
            open: clickOnAnotherPopper ? true : this.state.open ? false : true,
            ...args,
        });
    };

    handleClose = (event: any) => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { isIcon, title, anchorEl, open, buttons, childColor } = this.state;
        return (
            <Popper
                placement={isIcon ? "left-start" : "bottom"}
                open={open}
                anchorEl={anchorEl}
                role={undefined}
                style={{ zIndex: 7500, boxShadow: "0px 3px 2px 1px #00000026" }} // de la maquette
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            {isIcon && title && (
                                <div
                                    className="title-CustomGroupButton"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    {title}
                                </div>
                            )}
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList id="split-button-menu">
                                    {buttons?.map((b, i) => (
                                        <MenuItem
                                            key={`button-group-item-${i}`}
                                            style={{ color: childColor }}
                                            onClick={(event) => {
                                                {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    b.onClick();
                                                }
                                            }}
                                            disabled={b.disabled}
                                        >
                                            {b.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }
}
