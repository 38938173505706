import { EncapsulatedMethod, ErrorCatching } from "./EncapsulatedMethod";
import { EventEmitter } from 'events';
import { clone, duplicate } from "hub-lib/tools.bin";

// pour activer tous les logs:
// node .\server\server.bin.js -v dbsd

const logEvent = new EventEmitter();

export class LogBase {
    Date: Date;
    Layer: eLayerType;
    Report: any
}

/**
 * Layer types
 */
export enum eLayerType {
    DataAccess = 'DataAccess',
    Performance = 'Performance',
    Business = 'Business',
    Service = 'Service',
    User = 'User',
    Error = 'Error',
    ConnexionLost = 'ConnexionLost',
    Ava = 'Ava',
    Default = 'Default',
    Rights = 'Rights',
    MediaOcean = 'MediaOcean',
    MasterData = 'MasterData',
    Hook = 'Hook',
    Worker = 'Worker',
    Boot = 'Boot',
    LockingEventLoop = 'LockingEventLoop',
    SocketClient = 'SocketClient',
    SocketError = 'SocketError',
    SocketMessage = 'SocketMessage',
    TSMError = 'TSMError',
    OfferError = 'OfferError',
    Medialand = 'Medialand',
    SSO = 'SSO',
    Mail = 'Mail',
    MAP = 'MAP',
    Task = 'Task',
    Security = 'Security',
    File = 'File',
    Sellsy = 'Sellsy'
}

export let InfoProviders: InfoProvider[] = [];

export let BeforeLog: (() => any)[] = [];

let consoleLog = (report: LogBase) => console.log(report);

// only error on console
SetOutput(eLayerType.Error, consoleLog);

type eLayerTypeKey = keyof typeof eLayerType

let detailLevel = 0;

type IEnumLog<R> = { [key in eLayerTypeKey]?: R };

let logActivation: IEnumLog<boolean> = {
    Error: true,
    Default: true
}

export let BaseLogActivated = () => process.argv.includes(argVerbose) || process.argv.includes("-verb");

/** Check if logs are activated */
let argVerbose = "-v";
let argVerboseLevel = "-vLevel";

export const getDicoLogs = () => logActivation;

export let logActivated = (layer: eLayerType = eLayerType.Default) => {
    return BaseLogActivated() && logActivation[eLayerType[layer]];
}

const ActivateArgs = () => {
    if (process.argv.includes(argVerboseLevel)) {
        let idxV = process.argv.indexOf(argVerboseLevel);
        let parsed = parseInt(process.argv?.[idxV + 1], 10);
        if (!isNaN(parsed)) {
            SetLevelLogs(parsed);
        }
    }

    if (process.argv.includes("-verb")) {
        let idxV = process.argv.indexOf("-verb");
        let parsed = parseInt(process.argv?.[idxV + 1], 10);
        if (!isNaN(parsed)) {
            SetLevelLogs(parsed);
        }
    }
}

export const consoleLogsActivated = () => {
    if (!BaseLogActivated()) {
        console.log("\x1b[36m", `Add ${argVerbose} arg to activate logs.`, "\x1b[0m");
        console.log("\x1b[36m", `${argVerbose} (default conf: ${eLayerType.Default}, ${eLayerType.Error})`, "\x1b[0m");
        let all = '';
        for (var val in eLayerType) {
            if (val == eLayerType.Default) continue;
            console.log("\x1b[36m", `${argVerbose} ${val[0]} (${val})`, "\x1b[0m");
            all += val[0];
        }
        console.log("\x1b[36m", `${argVerbose} ${all} (for all)`, "\x1b[0m");
        console.log("\x1b[36m", `Add ${argVerboseLevel} number (for specifying log level, 0 default if not specified)`, "\x1b[0m");
    }
    else {
        console.log("\x1b[32m", `Logs activated`, "\x1b[0m");
        let idx = process.argv.indexOf(argVerbose);
        if (idx == -1)
            idx = process.argv.indexOf("-verb");
        let opt = process.argv?.[idx + 1]?.toLowerCase();
        if (opt && !opt?.startsWith("-")) {

            let layers: any[] = Object.keys(eLayerType);
            layers?.forEach(l => {

                if (l == eLayerType.Default)
                    return;

                if (opt.includes(l[0].toLowerCase()))
                    ActivateLog(l);
            })
        }

        ActivateLog(eLayerType.Rights);
        ActivateLog(eLayerType.MediaOcean);
        ActivateLog(eLayerType.SocketError);
        ActivateLog(eLayerType.MasterData);
        ActivateArgs();
    }

    ActivateLog(eLayerType.Business);
    ActivateLog(eLayerType.TSMError);
    ActivateLog(eLayerType.OfferError);
    ActivateLog(eLayerType.Hook);
    ActivateLog(eLayerType.Worker);
    ActivateLog(eLayerType.Boot);
    ActivateLog(eLayerType.LockingEventLoop);
    ActivateLog(eLayerType.SocketClient);
    ActivateLog(eLayerType.Medialand);
    ActivateLog(eLayerType.Performance);
    ActivateLog(eLayerType.SSO);
    ActivateLog(eLayerType.Mail);
    ActivateLog(eLayerType.Security);
    ActivateLog(eLayerType.File);
    ActivateLog(eLayerType.Task);
    ActivateLog(eLayerType.Sellsy);
    ActivateLog(eLayerType.MAP);
    ActivateLog(eLayerType.SocketMessage);
    ActivateLog(eLayerType.User);
    ActivateLog(eLayerType.ConnexionLost);
    ActivateLog(eLayerType.Ava);
}

export function SetLevelLogs(level: number) {
    detailLevel = level;
    console.log("\x1b[32m", `Log level details: ${detailLevel}`, "\x1b[0m");
}

export function log(report: any, layer: eLayerType = eLayerType.Default, details: number = 0, override: any = undefined) {
    try {

        BeforeLog?.forEach(b => {
            try {
                b?.();
            } catch (error) {
                console.error(error);
            }
        });

        if (details > detailLevel) return;
        if (!logActivated(layer)) return;

        let logB: LogBase = {
            Date: report.Date ?? new Date(),
            Layer: layer,
            Report: report
        };

        InfoProviders.forEach(i => {
            let data = i();
            if (data)
                for (var k in data) {
                    logB[k] = data[k];
                }
        })

        logB = { ...logB, ...override }

        logEvent.emit(layer, logB);
    } catch (e) {
        console.log("cannot log");
        console.error(e);
    }
}

export function logError(err, data?: any) {

    let messageError: string = "";
    try {
        messageError = err?.toString();
    } catch (error) {

    }

    let SerializedError: any = {};
    try {
        SerializedError = err && JSON.parse(JSON.stringify(err));
    } catch (error) {

    }

    const report = {
        ...data,
        Error: { /*error: err,*/ message: err?.message, stack: err?.stack },
        Msg: messageError,
        SerializedError,
    }

    log(report, eLayerType.Error);
}

type ErrorLogArgs = { Category?: string, Action?: string }
export function ErrorLog(args: ErrorLogArgs = undefined) {
    return ErrorCatching((reason: any, propertyName: string, args: any[], context: any) => {

        let contextCloned = null;
        try {
            contextCloned = clone(context);
        } catch (error) {
            contextCloned = 'cannot clone context';
        }

        const report = duplicate({
            Function: propertyName,
            Message: null,
            Args: args,
            ContextType: context?.constructor?.name,
            Context: contextCloned,
            Error: reason,
            ErrorMessage: reason?.message,
            ErrorStack: reason?.stack,
            Category: "Attribute_catch",
            Action: context?.constructor?.name ?? "",
            ...args,
        });

        if (report.Context?.Session?.userArgs?.password)
            delete report.Context.Session.userArgs.password;
        if (report.Context?.Session)
            delete report.Context.Session;

        log(report, eLayerType.Error);
        throw reason;
    });
}

/**
 * Check current log details, and may return empty object if details is > to current detail level
 * @param report
 * @param details
 */
export function logDetail(report: any, details: number = 0) {

    if (details > detailLevel) return {};
    return report;
}

/**
 * Activate logs for a given layer
 * @param key Layer log
 */
export function ActivateLog(key: eLayerType) {
    if (key) {
        console.log("\x1b[32m", `Activation logs: ${key}`, "\x1b[0m");
        logActivation[key] = true;
    }
}

/**
 * Desactivate logs for a given layer
 * @param key Layer log
 */
export function DesactivateLog(key: eLayerType) {
    logActivation[key] = true;
}

export function GetActivateLog(): eLayerTypeKey[] {

    let logs: eLayerTypeKey[] = <eLayerTypeKey[]>Object.keys(logActivation);
    return logs.filter(l => logActivation[l]);
}

export function SetOutput(layer: eLayerType, output: (log: LogBase) => void, clearAll: boolean = false) {
    if (clearAll)
        logEvent.removeAllListeners(layer);
    logEvent.on(layer, output);
}

export type InfoProvider = () => { [key: string]: any }
export let logMethod = (p?: { message?: string, layer?: eLayerType, infoProviders?: InfoProvider[], before?: (() => any)[], after?: (() => any)[] }) => {

    let layer = (p?.layer !== undefined) ? p?.layer : eLayerType.Default;

    let hrstart: any = undefined;
    let Id = Date.now();
    let date = undefined;
    return EncapsulatedMethod((propertyName, args, caller) => {

        if (!logActivated(layer))
            return;

        p?.before?.forEach(ptr => ptr());
        date = Date.now();

        hrstart = process.hrtime();

    }, (propertyName, args, res, caller) => {

        if (!logActivated(layer))
            return;

        try {

            var hrend = process.hrtime(hrstart);

            let report = {
                Id,
                Date: date,
                Function: propertyName,
                Description: p?.message,
                Time: hrend[1] / 1000000,
                Caller: caller,
                ...logDetail({
                    Params: args,
                    ResultsCount: res?.length
                }, 0),
            };

            p?.infoProviders?.forEach(prov => {
                let i = prov();
                for (var k in i) {
                    report[k] = i[k];
                }
            });

            // désactivation temporaire
            // log(report, layer);

        } catch (error) {
            console.log("\x1b[31m", `Cannot log.`, "\x1b[0m");
        }

        p?.after?.forEach(ptr => ptr());
    });
}


