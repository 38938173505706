import { ref_PropertyType, ref_PropertyTypeId } from "./ref_PropertyType.bin";
import { ref_Regional, ref_RegionalId } from "./ref_Regional.bin";
import { rid } from "./CommonTypes.bin";

export type ref_PropertyId = rid;

export class ref_Property extends ref_Regional {
	"@rid"?: ref_PropertyId;
	AllBroadcastAreas: boolean;
	End: Date;
	LinkedProperties: ref_PropertyId[];
	Name: string;
	PropertyType: ref_PropertyTypeId[];
	Start: Date;

}