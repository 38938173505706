import * as React from 'react'
import { Trad, TradProp } from 'trad-lib'
import { ExternalCampaignImport, ExternalLink } from 'hub-lib/models/custom/ExternalCampaignImport'
import { TPropsVertexAuto, VertexAutocomplete } from "adwone-lib/index";
import { getIcon } from "adwone-lib/index";
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { TextField } from '@material-ui/core';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { rid } from 'hub-lib/models/orientdb/CommonTypes.bin';
import { ref_PropertyType } from 'hub-lib/models/orientdb/ref_PropertyType.bin';
import { Client } from 'hub-lib/client/client.bin';
import Loader from '../layout/Loader';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { OrientInstance } from 'hub-lib/models/orientdb/OrientInstance.bin';
import { distinct } from 'hub-lib/tools.bin';
import { FavoriteVertexAutoComplete } from '../AdwAutocomplete/FavoriteVertexAutoComplete';
import { eCompare } from 'hub-lib/operators.bin';
import { Row } from '../Tools';
import { SubTitle } from '../VertexGrid/Generic/Common.bin';
import { BroadcastLinkSelector } from './BroadcastLinkSelector';
import { AdvertisingCompaniesLinkSelector } from './AdvertisingCompaniesLinkSelector';
import { DiscountManager } from 'hub-lib/business/DiscountManager.bin';
import { InitOJD } from 'hub-lib/business/ojd';

class TProps {
    campaignImport: ExternalCampaignImport
    onChange?: () => void
}

let formatRid: rid = undefined;
let emplacRid: rid = undefined;
let colorRid: rid = undefined;

export function LinksSelection({ campaignImport, onChange }: TProps) {

    const [loaded, setLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [baseLinks, setBaseLinks] = React.useState<{ [prop in keyof ref_Messages]?: ExternalLink[] }>();
    const [missingLinks, setMissingLinks] = React.useState(0);

    React.useEffect(() => {

        if (loading) return;
        setLoading(true);

        Promise.resolve().then(async () => {
            formatRid = formatRid ?? (await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Format" }))?.data?.results?.[0]?.["@rid"];
            emplacRid = emplacRid ?? (await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Emplacement" }))?.data?.results?.[0]?.["@rid"];
            colorRid = colorRid ?? (await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Couleur" }))?.data?.results?.[0]?.["@rid"];
            const token = new URLSearchParams(window.location.search).get("token")
            const baseLinks = (await Client.getExternalImport(token)).import.Links;
            setBaseLinks(baseLinks);
            getMissingLinks();
            setLoaded(true)
        })
    })

    const generateLinks = (
        title: string,
        msgKeys: (keyof ref_Messages) | (keyof ref_Messages)[],
        vertex: string,
        params?: {
            vertexParams?: any,
            activateBroadcast?: boolean,
            mergePropName?: string
        }) => {

        msgKeys = Array.isArray(msgKeys) ? msgKeys : [msgKeys];

        const colRightSizePercent = 60;
        const sizeThree = `${colRightSizePercent / 3}%`;

        const drawLink = (
            link: ExternalLink,
            i: number,
            __msgKeys: string[],
            label: string,
            baseProperties: ExternalLink[]) => {

            const color = (link.Referential === undefined && __msgKeys[0] == "Placement")
                ? '#F4611D'
                : GetColor(params, link, baseProperties, i);
            const icon = getIcon(link.Referential ? "valid" : "error_filled")
            const favDimensions: string[] = [ref_Supports.name, ref_Property.name];

            const propsVertex: TPropsVertexAuto<any> = {
                type: vertex,
                label: label,
                params: params?.vertexParams,
                defaultValue: options => options.find(o => o["@rid"] == link?.Referential?.['@rid']),
                onChange: async (value: OrientInstance) => {

                    const links = Object.values(campaignImport.Links)
                        .map(v => v.filter(l => l.Imports?.some(i => link.Imports?.some(i2 => i.Source == i2.Source && i.Value == i2.Value))))
                        .reduce((a, b) => a.concat(b), []);
                    links.forEach(l => l.Referential = value);

                    for (const imp of link.Imports) {
                        for (const msgKey of __msgKeys) {
                            const messages = campaignImport.Messages
                                .filter(m => m.Links[msgKey]?.Imports.some(i => i.Source === imp.Source && i.Value === imp.Value));

                            for (const m of messages) {
                                m.Referential[msgKey] = value?.['@rid'] as never;
                                if (value?.['@class'] === ref_Supports.name) {
                                    const sup = value as ref_Supports;
                                    m.Referential.Media = sup.Medias?.[0];
                                    await InitOJD(m.Referential);
                                }
                            }
                        }
                    }

                    getMissingLinks();
                    onChange?.();
                },
                onResetValue: (options: any) => undefined as any,
                renderInput: (params) =>
                    <TextField
                        error={link.Referential === undefined}
                        fullWidth
                        {...params}
                        {...((link.Referential === undefined && __msgKeys[0] == "Placement") && { className: 'warning-element' })}
                        label={label}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps
                        }} />
            };

            return (
                <div key={`import _link_${vertex}${i}_${__msgKeys.join(",")}`} className='element_missing_link'>
                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <div style={{ width: `${95 - colRightSizePercent}%` }}>
                            {link.Imports?.[0]?.Label}
                        </div>
                        {getIcon("next")}
                        <div style={{ marginLeft: "auto", width: params?.activateBroadcast ? sizeThree : colRightSizePercent + '%', paddingRight: params?.activateBroadcast ? 5 : 0 }} id="no_focus">
                            {favDimensions.includes(vertex) ? <FavoriteVertexAutoComplete {...propsVertex} /> : <VertexAutocomplete {...propsVertex} />}
                        </div>

                        {
                            params?.activateBroadcast &&
                            <div style={{ width: sizeThree, paddingLeft: 5 }} id="no_focus">
                                <BroadcastLinkSelector
                                    support={link.Referential?.['@rid']}
                                    defaultValue={link?.BroadcastArea?.['@rid']}
                                    onChange={async value => {
                                        link.BroadcastArea = value;
                                        for (const imp of link.Imports) {
                                            const messages = campaignImport.Messages
                                                .filter(m => m.Links.Support?.Imports.some(i => i.Source === imp.Source && i.Value === imp.Value));
                                            messages.forEach(m => m.Referential.BroadcastArea = value?.['@rid']);
                                            for (const msgKey of messages) {
                                                await DiscountManager.initGlobalAgreement(msgKey.Referential);
                                                await InitOJD(msgKey.Referential);
                                            }
                                        }
                                        getMissingLinks();
                                        onChange?.();
                                    }} />
                            </div>
                        }

                        {
                            params?.activateBroadcast &&
                            <div style={{ width: sizeThree, paddingLeft: 5 }} id="no_focus">
                                <AdvertisingCompaniesLinkSelector
                                    support={link.Referential?.['@rid']}
                                    broadcastArea={link.BroadcastArea?.['@rid']}
                                    defaultValue={link.AdvertisingCompany}
                                    onChange={value => {
                                        link.AdvertisingCompany = value;
                                        link.Imports.forEach(imp => {
                                            const messages = campaignImport.Messages
                                                .filter(m => m.Links.Support?.Imports.some(i => i.Source === imp.Source && i.Value === imp.Value));
                                            messages.forEach(m => {
                                                m.Referential.AdvCompany_Com = value;
                                                m.Referential.AdvCompany_Fin = value;
                                            });
                                        });
                                        getMissingLinks();
                                        onChange?.();
                                    }} />
                            </div>
                        }

                        <span style={{ marginLeft: 5, color }}>
                            {icon}
                        </span>
                    </div>
                    <div className='clearfix' />
                </div>
            )
        }

        if (params.mergePropName) {
            const getUniqLinks = (dicoLinks: { [prop in keyof ref_Messages]?: ExternalLink[] }) => {
                msgKeys = Array.isArray(msgKeys) ? msgKeys : [msgKeys];
                const properties = msgKeys.map(msgKey => dicoLinks[msgKey])
                    .reduce((a, b) => a.concat(b), []);

                const uniqProperties: ExternalLink[] = [];
                properties.forEach(p => {
                    if (!uniqProperties.some(pr => pr.Imports.some(imp1 => p.Imports.some(imp2 => imp1.Source === imp2.Source && imp1.Value === imp2.Value))))
                        uniqProperties.push(p);
                });
                return uniqProperties;
            };

            const uniqLinks = getUniqLinks(campaignImport.Links);
            const uniqLinksBase = getUniqLinks(baseLinks);

            return <Row>
                <SubTitle>{title}</SubTitle>
                {uniqLinks.map((e, i) => drawLink(e, i, msgKeys as any, params.mergePropName, uniqLinksBase))}
            </Row>
        }

        return (
            <Row>
                <SubTitle>{title}</SubTitle>
                {
                    msgKeys.map(msgKey => {
                        const properties: ExternalLink[] = campaignImport.Links[msgKey].filter(l => l.Imports);
                        const baseProperties: ExternalLink[] = baseLinks[msgKey];
                        return <>
                            {
                                properties.map((e, i) => drawLink(e, i, [msgKey], TradProp(msgKey, ref_Messages), baseProperties))
                            }
                        </>
                    })
                }
                <div className="clearfix"></div>
            </Row >
        )
    }

    const getMissingLinks = () => {
        const { Links } = campaignImport
        const missingLinks = distinct(Object.entries(Links)
            .map(([k, v]) => v)
            .reduce((a, b) => a.concat(b), [])
            .filter(v => v.Imports?.find(i => i.Source == "tsm"))
            .filter(v => !v.Referential), (v) => v.Imports?.find(i => i.Source == "tsm").Value);
        setMissingLinks(missingLinks.length);
    }

    const GetColor = (params: { vertexParams?: any; activateBroadcast?: boolean; mergePropName?: string; }, link: ExternalLink, baseProperties: ExternalLink[], i: number) => {
        let color = "";
        if (params?.activateBroadcast && (!link.BroadcastArea?.['@rid'] || !link.AdvertisingCompany))
            color = "#DA3240";
        else if (link.Referential?.['@rid'] === baseProperties[i].Referential?.['@rid']
            && link.BroadcastArea?.['@rid'] === baseProperties[i].BroadcastArea?.['@rid']
            && link.AdvertisingCompany === baseProperties[i].BroadcastArea?.['@rid'])
            color = link.Referential ? "#3AB36E" : "#DA3240";
        else
            color = link.Referential ? "black" : "#DA3240";
        return color;
    }

    const { Support, Format, Placement, AdvCompany_Com, AdvCompany_Fin } = campaignImport.Links

    if (!loaded)
        return <Loader />

    return (
        <div>
            <h4 style={{ color: missingLinks > 0 ? "#DA3240" : "#3AB36E" }}>{missingLinks > 0 ? `${missingLinks} ${Trad("missing_entries_links")}` : Trad("no_missing_links")}</h4>
            {Support.length
                && generateLinks(TradProp("Support"), "Support", ref_Supports.name, {
                    activateBroadcast: true,
                    vertexParams: { properties: ["*"] }
                })}

            {Format.length && generateLinks(TradProp("Format"),
                "Format",
                ref_Property.name,
                {
                    vertexParams: {
                        "_operators": [{
                            property: "PropertyType",
                            value: formatRid,
                            compare: eCompare.Contains
                        }]
                    }
                })}

            {Placement?.some(p => p?.Imports?.length)
                && generateLinks(TradProp("Placement"), "Placement", ref_Property.name, {
                    vertexParams: {
                        "_operators": [{
                            property: "PropertyType",
                            value: emplacRid,
                            compare: eCompare.Contains
                        }]
                    }
                })}

            {/* {(AdvCompany_Com.length || AdvCompany_Fin.length) && generateLinks(
                TradProp("AdvCompany"),
                ["AdvCompany_Com", "AdvCompany_Fin"],
                ref_AdvertisingCompanies.name,
                {
                    mergePropName: TradProp("AdvCompany")
                })} */}
            {/*Colors.length && this.generateLinks(Trad("colors"), "Colors", ref_Property.name, { PropertyType: [colorRid] })*/}
        </div>
    )

}