import { GET_ITEMS, ITEMS_LOADING, NO_ITEMS } from '../actions/types'

const initialState = {
    results: [],
    loading: false,
    msg: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ITEMS:
            // let formatedResults = action.payload.forEach(item=>item[checked])
            return {
                ...state,
                results: action.payload,
                msg: null,
                loading: false
            }
        case NO_ITEMS:
            return {
                ...state,
                results: [],
                msg: 'Aucun résultat'
            }
        case ITEMS_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}

