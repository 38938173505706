import * as React from 'react'
import { DateNoZone } from 'tools-lib';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin';
import { ref_AdvertisingCompanyGroups } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin';
import { ref_Agencies } from 'hub-lib/models/orientdb/ref_Agencies.bin';
import { ref_AgencyGroups } from 'hub-lib/models/orientdb/ref_AgencyGroups.bin';
import { ref_Brands } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Persons } from 'hub-lib/models/orientdb/ref_Persons.bin';
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { IRid } from 'hub-lib/models/IRid.bin';
import { ADWColumn, AdwRow } from "adwone-lib/index";
import { eDialogMode } from '../../../ConfigurableComponents/GenericDialog.bin';
import { VertexGrid } from '../../Generic/VertexGrid.bin';
import { GetOrder } from 'format-lib/index.bin';
import { Trad } from 'trad-lib';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { Client } from 'hub-lib/client/client.bin';
import { E } from 'hub-lib/models/orientdb/E.bin';
import { props } from '../../Generic/GridBase.bin';
import { clone, compareObjects, distinct, extractSub, GetSubElement } from 'hub-lib/tools.bin';
import { ref_SubAgencies } from 'hub-lib/models/orientdb/ref_SubAgencies.bin';
import { ConsoleDebug } from '../../../../utils/localstorage.bin';
import { lnk_Mandate } from 'hub-lib/models/orientdb/lnk_Mandate.bin';
import { lnk_AdvertisingCompanySupport } from 'hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin';
import { AdvertiserExtended } from 'hub-lib/dto/referential/AdvertiserExtended.bin';
import { AdvertiserGroupExtended } from 'hub-lib/dto/referential/AdvertiserGroupExtended.bin';
import { AdvertisingCompanyExtended } from 'hub-lib/dto/referential/AdvertisingCompanyExtended.bin';
import { AgencyExtended } from 'hub-lib/dto/referential/AgencyExtended.bin';
import { AgencyGroupExtended } from 'hub-lib/dto/referential/AgencyGroupExtended.bin';
import { BrandExtended } from 'hub-lib/dto/referential/BrandExtended.bin';
import { BroadcastAreaExtended } from 'hub-lib/dto/referential/BroadcastAreaExtended.bin';
import { ProductExtended } from 'hub-lib/dto/referential/ProductExtended.bin';
import { SupportExtended } from 'hub-lib/dto/referential/SupportExtended.bin';
import { ref_PersonExtended } from 'hub-lib/dto/referential/ref_PersonExtended';

export const DefaultStartDate: Date = DateNoZone("2014-01-01");

export function GetParentClass(className: string, hierarchy: string[]): string {
    if (!hierarchy)
        return undefined;
    if (hierarchy?.length < 2)
        return className;
    let index = hierarchy?.indexOf(className) - 1;
    if (index < 0)
        return undefined;
    return hierarchy[index];
}

export function GetChildClass(className: string, hierarchy: string[]): string {
    if (hierarchy?.length < 2)
        return className;
    let index = hierarchy?.indexOf(className) + 1;
    if (index == hierarchy?.length)
        return undefined;
    return hierarchy[index];
}

export function NewElement(refName: string) {

    switch (refName) {
        case ref_AdvertisingCompanyGroups.name:
            return new ref_AdvertisingCompanyGroups();
        case ref_AdvertisingCompanies.name:
            return new AdvertisingCompanyExtended();
        case ref_Agencies.name:
            return new AgencyExtended();
        case ref_AgencyGroups.name:
            return new AgencyGroupExtended();
        case ref_AdvertiserGroups.name:
            return new AdvertiserGroupExtended()
        case ref_Advertisers.name:
            return new AdvertiserExtended()
        case ref_Brands.name:
            return new BrandExtended()
        case ref_BroadcastAreas.name:
            return new BroadcastAreaExtended();
        case ref_Currencies.name:
            return new ref_Currencies();
        case ref_Persons.name:
            return new ref_Persons();
        case ref_PersonExtended.name:
            return new ref_PersonExtended();
        case ref_Media.name:
            return new ref_Media();
        case ref_Products.name:
            return new ProductExtended();
        case ref_SubAgencies.name:
            return new ref_SubAgencies();
        case ref_Supports.name:
            return new SupportExtended();
        case 'Format':
            return new ref_Property();
        case 'Emplacement':
            return new ref_Property();
        default:
            console.log(`Cannot find: ${refName}`)
            break;
    }
}

export async function searchHierarchy(className: string, params?: any): Promise<IRid> {
    ConsoleDebug(`exec searchHierarchy`, className, params);
    const newElement = NewElement(className);
    if (!newElement) {
        console.warn(`Cannot NewElement on ${className}`)
        return null;
    }
    const res = await Client.searchVertex(newElement.constructor.name, params);
    return res?.data?.results?.[0];
}

export function createDefaultColumn<TLink>(isEditable: () => boolean, propertyName: string = "Default") {
    let defaultColumn = new ADWColumn<TLink>(Trad(propertyName), propertyName, ePropType.Boolean, true)
    defaultColumn.cellValue = (CellValue: any, dataItem: AdwRow<TLink>) =>
        dataItem.dataItem[propertyName] ? <span className="by_default_lnk_adwcmp_support">{Trad("by_default")}</span> : '';

    defaultColumn.cell.isEditable = (row: AdwRow<TLink>) => row?.dataItem?.[propertyName] || isEditable();

    return defaultColumn;
}

export function createLinkColumn<TReferential>(title: string, property: (keyof TReferential) & string, subProp?: string, filter?: (element: any) => boolean, key?: string) {
    let columnCustom = new ADWColumn<TReferential>(title, key ?? property, ePropType.Link, true);
    columnCustom.valueIsArray = !subProp;
    columnCustom.cellValue = (cellValue: any, dataItem?: AdwRow<TReferential>) => {
        if (subProp) {
            let value = (GetSubElement(dataItem.dataItem, property as string) as any[])
                ?.filter(filter ?? (() => true))
                ?.sort((a, b) => {
                    if (a.Default && !b.Default) return -1;
                    if (!a.Default && b.Default) return 1;

                    const astr = a[subProp];
                    const bstr = b[subProp];
                    return astr.localeCompare(bstr);
                })
                ?.map(e => e[subProp]);
            if (value)
                value = distinct(value, v => v);
            return value?.join("|");
        }
        return dataItem.dataItem?.[`${property as string}Name`];
    }
    return columnCustom;
}


export class LinkGrid<TVertex extends IRid> extends VertexGrid<TVertex> {

    async InitStore(bindingPath: string) {
        const elementType = this.properties.find(p => p.name == bindingPath)?.linkedClass;
        if (elementType) {
            const params = {};
            if (this.objectPrototype.name == lnk_Mandate.name && elementType == ref_Companies.name) {
                if (this.props.vertexParams.out)
                    params["@class"] = [ref_AgencyGroups.name, ref_Agencies.name];
                if (this.props.vertexParams.in)
                    params["@class"] = [ref_AdvertiserGroups.name, ref_Advertisers.name];
            }
            let value = (await Client.searchVertex(elementType, params)).data.results;
            //Filtrage des sous agences
            if (this.objectPrototype.name == lnk_AdvertisingCompanySupport.name && bindingPath == "in") {
                value = value.filter(v => v["@class"] == ref_AdvertisingCompanies.name);
            }
            this.store[bindingPath] = value;
            return value;
        } else {
            this.store[bindingPath] = [];
        }
    }
}

export function createLinkGrid<TElement extends IRid, TLink extends E<string, string>>(linkType: new () => TLink,
    field: "lnkBroadcastAreas" | "lnkAdvertisingCompanies" | "lnkCurrencies" | "lnkContacts" | "lnkMandates" | "lnkSupports",
    element: () => TElement,
    mode: eDialogMode,
    direction: "in" | "out" = "in",
    params: Partial<TLink> & { properties?: string[] } = {},
    gridProps: Partial<props<TLink>> = {}) {
    const reverseDirection = direction == "in" ? "out" : "in";

    const initializeLink = (link: TLink, push: boolean = false) => {
        if (!link["Start"])
            link["Start"] = DefaultStartDate;
        link["Active"] = true;
        link[direction] = element()?.["@rid"];
        link = { ...link, ...params };
        if (push) {
            if (!element()[field])
                element()[field] = [];
            if (link["@rid"]) {
                element()[field] = element()[field].filter(l => l["@rid"] !== link["@rid"]);
            } else {
                // dans le cas où l'on modifie un élément qui a été crée (pas de rid)
                element()[field] = element()[field].filter(l => !compareObjects(extractSub(l, ["in", "out", "Roles"])  , extractSub<any>(link, ["in", "out", "Roles"])));
            }
            element()[field].push(link);
        }
      }

    const paramsFilter = (link: any) => {
        for (const prop in params) {
            if (Array.isArray(link[prop])) {
                if (!link[prop].filter(p => (params[prop] as any).includes(p)).length)
                    return false;
            }
            else if (link[prop] != params[prop])
                return false;
        }
        return true;
    }
    const columns = [];
    const hiddenProperties = [];
    if (field != "lnkMandates" && field != "lnkContacts") {
        columns.push(createDefaultColumn<TLink>(() => field == "lnkSupports" || !element()[field]?.some(l => l["Default"] && paramsFilter(l))));
        hiddenProperties.push("Default");
    }
    return new LinkGrid<TLink>({
        objectPrototype: linkType,
        initializePrototype: (link: TLink) => initializeLink(link),
        emptyGrid: mode === eDialogMode.create,
        onInlineEdited: (link) => initializeLink(link, true),
        onInlineNew: (link) => initializeLink(link, true),
        onInlineDeleted: (links) => {
            let link = links[0];
            element()[field] = element()[field].filter(l => (l[reverseDirection] !== link[reverseDirection] && paramsFilter(l)) || !paramsFilter(l));
        },
        devMode: false,
        order: GetOrder<TLink>(linkType),
        columns,
        width: { in: 300, out: 300 },
        vertexParams: { Active: true, properties: ["*"], [direction]: element()?.["@rid"], ...params },
        ...gridProps,
        hiddenProperties: [direction, ...hiddenProperties, ...(gridProps?.hiddenProperties ?? [])]
    });
}