import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from 'hub-lib/client/client.bin';
import { IRid } from 'hub-lib/models/IRid.bin';
import { ref_Persons } from 'hub-lib/models/orientdb/ref_Persons.bin';
import { duplicate } from 'hub-lib/tools.bin';
import { store } from './store';
import { lnk_HasCompany } from "hub-lib/models/orientdb/lnk_HasCompany.bin";
import { ConsoleDebug } from '../utils/localstorage.bin';

type Store = { [prop: string]: IRid }
export interface StorageState {
    store: Store
}

const initialState: StorageState = {
    store: {}
}

export const storageSlice = createSlice({
    name: 'storage',
    initialState,
    reducers: {
        setStorage: (state, action: PayloadAction<Store>) => {
            state.store = duplicate(action.payload);
            ConsoleDebug(`setStorage`, state.store);
        }
    },
});

export function storeElements(rows: { Updated_by?: IRid["@rid"], Created_by?: IRid["@rid"] }[]) {

    const currentStore = store.getState().verticesStorage;
    const allUsers = Array.from(new Set<string>(rows?.flatMap(r => [r.Updated_by, r.Created_by]) ?? []))
        .filter(u => u)
        .filter(u => !currentStore[u]);

    if (allUsers.length) {
        Client.searchVertex(lnk_HasCompany.name, {
            Account: allUsers,
            properties: ["Account", "out.FirstName as FirstName", "out.LastName as LastName"]
        })
            .then(res => res.data.results.map(r => ({
                ...r,
                "@rid": r.Account,
                "@class": ref_Persons.name
            })))
            .then(persons => {
                const storage = duplicate(store.getState().verticesStorage.store);
                persons.forEach(p => storage[p["@rid"]] = p);
                store.dispatch(setStorage(storage));
            })
    }
}


export const { setStorage } = storageSlice.actions
export const storageReducer = storageSlice.reducer;