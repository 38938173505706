import { ref_BroadcastAreas, ref_BroadcastAreasId } from "./ref_BroadcastAreas.bin";
import { ref_Media, ref_MediaId } from "./ref_Media.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_ModelId = rid;

export class ref_Model extends V {
	"@rid"?: ref_ModelId;
	Country: ref_BroadcastAreasId;
	Description: string;
	DocumentType: string;
	MediaTypes: ref_MediaId;

}