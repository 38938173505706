import * as React from "react";
import { Chip, Grid, GridSize } from "@material-ui/core";
import { ref_FilterConfigurations } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { ClassProperty } from "hub-lib/models/VertexProperty.bin";
import { Client } from "hub-lib/client/client.bin";
import { firstOrDefault } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { DividerWithText, getIcon } from "adwone-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ChipBox } from "./ChipBox.bin";
import { DateChip } from "./DateChip.bin";

class TPropsFiltersConfiguration {
    id: string;
    table: string;
    gridSize: GridSize;
}

export function FiltersChip({ id, table, gridSize }: TPropsFiltersConfiguration) {
    const [config, setConfig] = React.useState<ref_FilterConfigurations>();
    const [open, setOpen] = React.useState<boolean>(true);

    const canBeDisplay = (name: string, filter: string | string[]): boolean => {
        if (
            !name ||
            ["CrossedtableConfig", "ViewMode", "End"].includes(name) ||
            (Array.isArray(filter) && !filter.length)
        ) {
            return false;
        }
        return true;
    };

    const dateFirst = (arr: string[]): string[] => {
        arr.splice(
            0,
            0,
            arr.splice(
                arr.findIndex((s) => s === "Start"),
                1
            )[0]
        );
        return arr;
    };

    const [metaData, setMetaData] = React.useState<ClassProperty[]>(null);
    const fetchMetaData = async (): Promise<void> => {
        const data = (await Client.getMetadata(table)).data.results;
        setMetaData(data);
    };
    const fetchData = async (): Promise<void> => {
        const defaultParams = { Active: true, Table: ref_Messages.name, Default: true };
        const data = await Client.searchVertexTyped(
            ref_FilterConfigurations,
            id
                ? {
                    "@rid": id,
                    Active: true,
                }
                : defaultParams
        );
        setConfig(firstOrDefault(data));
    };
    React.useEffect(() => {
        if (!metaData) {
            fetchMetaData();
        }
        if (!config) {
            fetchData();
        }
    });
    return (
        <>
            <DividerWithText
                startIcon={getIcon("filterAlt")}
                endIcon={open ? getIcon("less") : getIcon("more")}
                onClick={() => setOpen(!open)}
                hideLines
            >{`${config?.Name}`}</DividerWithText>
            <Grid container className={`widget-expendable ${open ? "open" : ""}`}>
                {config?.Filters &&
                    dateFirst(Object.keys(config.Filters))
                        .filter((key) => canBeDisplay(key, config.Filters[key]))
                        .map((key) =>
                            key === "Start" ? (
                                <Filter
                                    name={Trad("period")}
                                    date={{ Start: config.Filters["Start"], End: config.Filters["End"] }}
                                    gridSize={gridSize}
                                />
                            ) : (
                                <Filter
                                    name={key}
                                    filter={config.Filters[key]}
                                    metaData={metaData}
                                    gridSize={gridSize}
                                />
                            )
                        )}
            </Grid>
        </>
    );
}

class TPropsFilter {
    name: string | "Date";
    filter?: string | string[];
    date?: { Start: Date; End?: Date };
    metaData?: ClassProperty[];
    gridSize: GridSize;
}

function Filter({ name, filter, date, metaData, gridSize }: TPropsFilter) {
    const [toDisplay, setToDisplay] = React.useState<string[]>([]);

    const fetchData = async (ids: string[]): Promise<void> => {
        const meta = metaData.find((e) => e.name === name);
        if (meta.linkedClass) {
            const find = await Client.searchVertex(meta.linkedClass, { "@rid": ids });
            setToDisplay(find.data.results.map((e) => e.Name));
        } else {
            setToDisplay(ids);
        }
    };

    React.useEffect(() => {
        if (!toDisplay.length && metaData) {
            fetchData(Array.isArray(filter) ? [...filter] : [filter]);
        }
    });

    return (
        <Grid item xs={gridSize} key={`grid-filter-${name}`}>
            <ChipBox title={Trad(metaData?.find((e) => e.name === name)?.linkedClass ?? name)}>
                {date ? (
                    <DateChip Start={date.Start} End={date.End} />
                ) : (
                    <>
                        {toDisplay.map((s: string) => (
                            <Chip variant="outlined" label={s.toString()} className="chip-element" />
                        ))}
                    </>
                )}
            </ChipBox>
        </Grid>
    );
}
