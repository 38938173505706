export function UrlParser(_paramName: string, defaultValue?: string, char: string = "?") {

    let location = window.location.href;
    var array = location.split(char);

    if (array.length > 1) {
        for (let idx = 1; idx < array.length; idx++) {
            var arrayAnd = array[idx].split("&");

            for (const chunk of arrayAnd) {
                let res = chunk.split("=");
                if (res.length == 2) {
                    if (res[0] === _paramName)
                        return res[1];
                }
            }
        }
    }

    return defaultValue;
}