import { IRid } from "../../models/IRid.bin";
import { UserId } from "../../models/orientdb/User.bin";
import { INamed, IUpsertInfo } from "../../types";

export class ref_CalendarEvent implements IRid, INamed, IUpsertInfo {
    '@rid'?: string;

    Name: string;
    Start: Date;
    End: Date;
    IsAllDay: boolean;
    Category: string;
    Description: string;

    Created_at?: Date;
	Created_by?: UserId;
    Updated_at?: Date;
	Updated_by?: UserId;
}