import { GetCellTemplate } from "format-lib/index.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { Trad, TradComposed, TradProp } from "trad-lib";
import { Row } from "../../Tools";
import { PanelDisable, SubTitle } from "../Generic/Common.bin";
import { extractSub, JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../redux/store";
import { eKPI } from "hub-lib/models/KPIsManager.bin";
import { KeyValidator } from "../../../utils/KeyValidator";
import { Switch } from '@progress/kendo-react-inputs';
import { eDialogMode } from "../../ConfigurableComponents/GenericDialog.bin";
import { AdvertiserOverview, Field, StartEndOverview, SupportOverview } from "../Generic/ModelForm/ModelDetails";
import { setEnableEstimate } from "../../../redux/campaignEditorSlice";

type CampaignOverview = {
    children?: any;
    showPerformances?: boolean;
    mode: eDialogMode;
};
export function CampaignOverview({
    children,
    showPerformances,
    mode
}: CampaignOverview) {
    return (
        <div className="MessageEditorOverview">
            {children}
            <Row>
                <SubTitle anchor="#campaignpicker">{Trad("Campaign")}</SubTitle>
                <NameOverview />
                <KeyValidator objectType="ref_Campaigns" propertyName="Support">
                    <SupportOverview model="ref_Campaigns" anchor="#campaignpicker" />
                </KeyValidator>
                <StartEndOverview model="ref_Campaigns" anchor='#campaignpicker' />
                <KPIsOverview />
                <AdvertiserOverview model="ref_Campaigns" anchor='#campaignpicker' />
            </Row>
            <KeyValidator objectType="ref_Campaigns" propertyName="Estimates">
                <EstimateOverview mode={mode} />
            </KeyValidator>
            <KeyValidator objectType="ref_Campaigns" propertyName="Visuals">
                <AdvertisingOverview />
            </KeyValidator>
            {showPerformances && <PerformancesOverview />}
        </div>
    );
}

function NameOverview() {

    const campaign = useSelector((root: RootState) => extractSub(root.campaignEditor.get(), ['Name']), JSONEqualityComparer) ?? {};

    return <Field field="Name" value={campaign.Name} anchor="#campaignpicker" />
}

function KPIsOverview() {

    const campaign = useSelector((root: RootState) => extractSub(root.campaignEditor.get(), ['KPIs']), JSONEqualityComparer) ?? {};

    return <>
        <KeyValidator objectType="ref_Campaigns" propertyName={eKPI.Budget}>
            <Field field={'KPIs.Budget' as any} model="ref_Campaigns" value={priceFormater(campaign.KPIs[eKPI.Budget])} anchor="#campaignpicker" />
        </KeyValidator>
        <KeyValidator objectType="ref_Campaigns" propertyName={eKPI.Pagination}>
            <Field field={'KPIs.Pagination' as any} model="ref_Campaigns" value={campaign.KPIs[eKPI.Pagination]} anchor="#campaignpicker" />
        </KeyValidator>
    </>
}

function EstimateOverview({ mode }: { mode?: eDialogMode }) {
    const campaign = useSelector((root: RootState) => extractSub(root.campaignEditor.get(), ['Estimates']), JSONEqualityComparer) ?? {};
    const enableEstimate = useSelector((root: RootState) => root.campaignEditor.enableEstimate);
    // const updateEstimate = useSelector((root: RootState) => root.campaignEditor.updateEstimate);

    return (
        <InfosValidator>
            <Row>
                <SubTitle anchor="#estimatepicker">
                    {Trad("estimate")}
                    {(mode == eDialogMode.create || enableEstimate === true || campaign?.Estimates?.length === 0) &&
                        <Switch
                            checked={enableEstimate}
                            onChange={(event) => {
                                event.nativeEvent.preventDefault();
                                event.nativeEvent.stopPropagation();
                                store.dispatch(setEnableEstimate(!enableEstimate));
                            }}
                            name={Trad("create_estimate")}
                        />}
                </SubTitle>
                {campaign.Estimates?.map((estimate, i) => {
                    return (
                        <Field
                            label={TradComposed("estimate_number", [(i + 1).toString()])}
                            value={estimate.EstimateLib}
                            anchor="#estimatepicker"
                        />
                    );
                })}
            </Row>
        </InfosValidator>
    );
}

export function InfosValidator({
    children,
    text,
}: {
    children: any;
    text?: string;
}) {
    const campaign = useSelector((root: RootState) => extractSub(root.campaignEditor.get(), ['AdvertiserGroup', 'Advertiser']), JSONEqualityComparer) ?? {};

    return (
        <PanelDisable disabled={!Boolean(campaign.AdvertiserGroup) && !Boolean(campaign.Advertiser)} text={text}>
            {children}
        </PanelDisable>
    );
}

const priceFormater = GetCellTemplate(ePropType.Double);

function AdvertisingOverview() {
    return (
        <InfosValidator text={Trad("complete_campaign_infos")}>
            <Row>
                <SubTitle anchor="#advertisingpicker">{Trad("advertising_creation")}</SubTitle>
            </Row>
        </InfosValidator>
    );
}

function PerformancesOverview() {
    return (
        <Row>
            <SubTitle anchor="#performancespicker">{Trad("performances")}</SubTitle>
        </Row>
    );
}
