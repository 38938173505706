import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_PropertyTypeId = rid;

export class ref_PropertyType extends V {
	"@rid"?: ref_PropertyTypeId;
	Rank: number;
	Tags: string[];
	Type: string;
	ValueType: string;

}