
export type SocketArg = SocketMessage | SocketCommand | SocketAction;

/**
 * Socket protocol
 */
export enum eSocketMsg {
    notification = "notification",
    command = "command",
    action = "action"
}

export enum eSocketCommand {
    clearCacheSession = "clearCacheSession",
    progress = "progress",
    sendLocalstorage = "sendLocalstorage",
    logVersionClient = "logVersionClient"
}

export type TaskProgressCommand = {
    taskId: string;
    total: number;
    current: number;
    message: string;
    // type?: TypeOptions;
}

type TypeOptions = "info" | "success" | "warning" | "error" | "default";

/**
 * send message arg
 */
export type SocketMessage = {
    force?: boolean;
    type: eSocketMsg.notification;
    message: string;
    messageType: TypeOptions;
}

export type SocketAction = {
    type: eSocketMsg.action,
    message?: string,
    vertex: string,
    service: string,
    body?: any,
    options?: {
        type?: TypeOptions,
    }
}

/**
 * send something to do to client
 */
export type SocketCommand = {
    type: eSocketMsg.command;
    command: eSocketCommand;
    infos?: any;
    forceUpdate?: boolean;
}