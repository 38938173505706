import * as React from 'react'
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin'
import { Grid } from '@material-ui/core';
import { Trad, TradProp } from 'trad-lib';
import Loader from '../../../layout/Loader';
import { extractSub, groupBy, JSONEqualityComparer, propertyOf } from 'hub-lib/tools.bin';
import { GetEditorState, RootState } from '../../../../redux/store';
import { FormatEditor } from '../../Messages/MessageEditor/FormatEditor';
import { PlacementEditor } from '../../Messages/MessageEditor/PlacementEditor';
import { AdditionalFormatEditor } from '../../Messages/MessageEditor/AdditionalFormatEditor';
import { AdditionalPlacementEditor } from '../../Messages/MessageEditor/AdditionalPlacementEditor';
import { SupportEditor } from './SupportEditor';
import { BroadcastAreaEditor } from './BroadcastAreaEditor';
import { ModelPropertiesEditor } from './ModelPropertiesEditor';
import { ModelPropertiesChoicesEditor } from './ModelPropertiesChoicesEditor';
import { PropertyEditor } from './PropertyEditor';
import { ValueChoiceChecker } from './ValueChoiceChecker';
import { CurrencyEditor } from './CurrencyEditor';
import { LnkAdvPropertyEditor } from '../../Messages/MessageEditor/LnkAdvPropertyEditor';
import { useSelector } from 'react-redux';
import { GroupEditor } from './GroupEditor';
import { DatePropertyEditor } from './DatePropertyEditor';
import { KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { KeyValidator } from '../../../../utils/KeyValidator';
import { ref_Groups } from 'hub-lib/dto/client/ref_Groups.bin';
import { ref_Brands } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Products } from 'hub-lib/models/orientdb/ref_Products.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { Client } from 'hub-lib/client/client.bin';
import { AnchorRow } from '../../../Tools';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { GetCellTemplate } from 'format-lib/index.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';

export type SubOverviewArgs = { anchor: string }
export type ModelEditorArgs = { model: "ref_Messages" | "ref_Campaigns", rightFirst?: boolean }

type ElementProps = {
    label: string;
    value: any;
    anchor?: string;
};

export type FieldProps = {
    field?: keyof ref_Messages | keyof ref_Campaigns;
    value: string;
    model?: string;
    anchor?: string;
    label?: string;
};

function Element({ label, value, anchor }: ElementProps) {
    return (
        <div className="overview-container">
            <AnchorRow anchor={anchor}>
                <span className={`overview-field`}>{label + ": "}</span>
                {value}
            </AnchorRow>
        </div>
    );
}

export function Field({ field, model, value, anchor, label }: FieldProps) {
    return <Element label={label ?? TradProp(field, model)} value={value} anchor={anchor} />;
}

export function StartEndOverview({ model, anchor }: ModelEditorArgs &SubOverviewArgs) {
    const data = useSelector((root: RootState) => extractSub(GetEditorState(model, root).get(), ['Start', 'End']), JSONEqualityComparer) ?? {};
    return <>
        <Field field='Start' value={GetCellTemplate(ePropType.Date)(data.Start)} anchor={anchor} />
        <Field field='End' value={GetCellTemplate(ePropType.Date)(data.End)} anchor={anchor} />
    </>
}

export function AdvertiserOverview({ model, anchor }: ModelEditorArgs & SubOverviewArgs) {

    const [advertisergroup, setAdvertisergroup] = React.useState<ref_AdvertiserGroups['Name']>('');
    const [advertiser, setAdvertiser] = React.useState<ref_Advertisers['Name']>('');
    const [brand, setBrand] = React.useState<ref_Brands['Name']>('');
    const [product, setProduct] = React.useState<ref_Products['Name']>('');
    const [group, setGroup] = React.useState<ref_Groups['Name']>('');

    const data = useSelector((root: RootState) => extractSub(GetEditorState(model, root).get(), ['Group', 'AdvertiserGroup', 'Advertiser', 'Brand', 'Product']), JSONEqualityComparer) ?? {};

    const propCheck = [
        { type: ref_AdvertiserGroups, setter: setAdvertisergroup, value: data.AdvertiserGroup },
        { type: ref_Advertisers, setter: setAdvertiser, value: data.Advertiser },
        { type: ref_Brands, setter: setBrand, value: data.Brand },
        { type: ref_Products, setter: setProduct, value: data.Product },
        { type: ref_Groups, setter: setGroup, value: data.Group }
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <>{!Boolean(data?.Group)
        ? <>
            <KeyValidator objectType={model} propertyName="AdvertiserGroup">
                <Field field="AdvertiserGroup" value={advertisergroup} anchor={anchor} />
            </KeyValidator>
            <KeyValidator objectType={model} propertyName="Advertiser">
                <Field field="Advertiser" value={advertiser} anchor={anchor} />
            </KeyValidator>
            <KeyValidator objectType={model} propertyName="Brand">
                <Field field="Brand" value={brand} anchor={anchor} />
            </KeyValidator>
            <KeyValidator objectType={model} propertyName="Product">
                <Field field="Product" value={product} anchor={anchor} />
            </KeyValidator>
        </>
        : <>
            <Field field='Group' value={group} anchor={anchor} />
        </>
    }</>
}

export function SupportOverview({ model, anchor }: ModelEditorArgs & SubOverviewArgs) {
    const [support, setSupport] = React.useState<ref_Supports['Name']>('');
    const [currency, setCurrency] = React.useState<ref_Currencies["Name"]>("");
    const [broadcastArea, setBroadcastArea] = React.useState<ref_BroadcastAreas['Name']>('');

    const data = useSelector((root: RootState) => extractSub(GetEditorState(model, root).get(), ['Support', 'Currency', 'BroadcastArea']), JSONEqualityComparer) ?? {};

    const propCheck = [
        { type: ref_Supports, setter: setSupport, value: data.Support },
        { type: ref_Currencies, setter: setCurrency, value: data.Currency },
        { type: ref_BroadcastAreas, setter: setBroadcastArea, value: data.BroadcastArea },
    ]

    propCheck.forEach(p => {
        React.useEffect(() => {
            if (!p.value) return p.setter('');
            Client.searchVertex(p.type.name, { '@rid': p.value, properties: ['Name'] }).then(res => p.setter(res?.data?.results?.[0]?.Name))
        }, [p.value])
    })

    return <>
        <Field field='Support' value={support} anchor={anchor} />
        <Field field='Currency' value={currency} anchor={anchor} />
        <Field field='BroadcastArea' value={broadcastArea} anchor={anchor} />
    </>
}

export function SupportDetails({ model }: ModelEditorArgs) {

    const media = useSelector((root: RootState) => GetEditorState(model, root).data.Media);
    const [medias, setMedias] = React.useState<ref_Media[]>();

    React.useEffect(() => {
        KPIsManagerCache.GetMedias().then(setMedias);
    }, [Boolean(medias)])


    if (!medias) return <Loader />

    const Cinema_Or_HorsMedia = ["CI", "HM"].includes(medias?.find(m => media == m["@rid"])?.Code);

    return <>
        <Grid item xs={6} className="message_details_leftcombo">
            <SupportEditor model={model} />
        </Grid>

        <Grid item xs={6} className="message_details_rightcombo">
            <CurrencyEditor model={model} />
        </Grid>

        <Grid item xs={6} className="message_details_leftcombo">
            <BroadcastAreaEditor model={model} />
        </Grid>

        <Grid item xs={6} className="message_details_rightcombo">
            <ModelPropertiesEditor model={model} property='BroadcastAreaDetail' />
        </Grid>
        <KeyValidator objectType={model} propertyName="AdvCompany_Com">
            <Grid item xs={6} className="message_details_leftcombo">
                <LnkAdvPropertyEditor
                    property="AdvCompany_Com"
                    mandatory={!Cinema_Or_HorsMedia} />
            </Grid>
        </KeyValidator>
        <KeyValidator objectType={model} propertyName="AdvCompany_Fin">
            <Grid item xs={6} className="message_details_rightcombo">
                <LnkAdvPropertyEditor
                    property="AdvCompany_Fin"
                    mandatory={!Cinema_Or_HorsMedia} />
            </Grid>
        </KeyValidator>
    </>
}

export function PeriodicityEditor({ model }: ModelEditorArgs) {
    return <><Grid item xs={6} className="message_details_leftcombo">
        <ModelPropertiesChoicesEditor model={model} property='Periodicity' />
    </Grid>
        <Grid item xs={6} className="message_details_rightcombo">
            <ValueChoiceChecker model={model} propId='Periodicity' propValue='others'>
                <ModelPropertiesEditor model={model} property='PeriodicityComment' label={Trad("property_Periodicity_comment")} />
            </ValueChoiceChecker>
        </Grid></>
}

export function MediaFamilyEditor({ model }: ModelEditorArgs) {
    const MediaFamilyOther = useSelector((root: RootState) => GetEditorState(model, root).data.ModelProperties?.["MediaFamilyOther"]);

    return <><Grid item xs={6} className="message_details_leftcombo">
        <GroupEditor model={model} property="MediaFamily" require={propertyOf<ref_Messages>("Support")} other="MediaFamilyOther" modelProperty />
    </Grid>
    <Grid item xs={6} className="message_details_rightcombo">
        {/* if we choice other */}
        {MediaFamilyOther != null &&
            <ModelPropertiesEditor model={model} property='MediaFamilyOther' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.MediaFamilyOther` as any, ref_Messages)} />
        }
    </Grid>
    </>
}

export function PeriodDetails({ model, rightFirst }: ModelEditorArgs) {
    const position1 = rightFirst ? "right" : "left";
    const position2 = rightFirst ? "left" : "right";

    return <>
        <Grid item xs={6} className={`message_details_${position1}combo`}>
            <ModelPropertiesEditor model={model} property='Dated' />
        </Grid>

        <Grid item xs={6} className={`message_details_${position2}combo`}>
            <ModelPropertiesEditor model={model} property='Reference' />
        </Grid>

        <Grid item xs={6} className={`message_details_${position1}combo`}>
            <ModelPropertiesEditor model={model} property='EditorialTheme' />
        </Grid>
    </>
}

export function FormatDetails({ model }: ModelEditorArgs) {
    const media = useSelector((root: RootState) => GetEditorState(model, root).data.Media);
    const PlacementCategoryOther = useSelector((root: RootState) => GetEditorState(model, root).data.ModelProperties?.["PlacementCategoryOther"]);
    const [medias, setMedias] = React.useState<ref_Media[]>();

    React.useEffect(() => {
        KPIsManagerCache.GetMedias().then(setMedias);
    }, [Boolean(medias)])

    if (!medias) return <Loader />

    const Cinema_Or_HorsMedia = ["CI", "HM"].includes(medias?.find(m => media == m["@rid"])?.Code);
    return <>
        <Grid item xs={6} className="message_details_leftcombo">
            <FormatEditor />
        </Grid>
        <Grid item xs={6} className="message_details_rightcombo">
            <AdditionalFormatEditor />
        </Grid>
        {!Cinema_Or_HorsMedia && <>
            <Grid item xs={6} className="message_details_leftcombo">
                <PlacementEditor />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <AdditionalPlacementEditor />
            </Grid>
            <Grid item xs={6} className="message_details_leftcombo">
                {/* <ModelPropertiesEditor property='PlacementCategory' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.PlacementCategory` as any, ref_Messages)} /> */}
                <GroupEditor model={model} property="PlacementCategory" require={propertyOf<ref_Messages>("Placement")} other="PlacementCategoryOther" modelProperty />
            </Grid>
            {/* if we choice other */}
            {PlacementCategoryOther != null && <Grid item xs={6} className="message_details_rightcombo">
                <ModelPropertiesEditor model={model} property='PlacementCategoryOther' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.PlacementCategoryOther` as any, ref_Messages)} />
                {/* <PropertyEditor property='MediaFamilyOther' label={Trad("other")} /> */}
            </Grid>}
        </>}
    </>
}

export function TimeLimits({ model }: ModelEditorArgs) {
    return <>
        <Grid item xs={12} className="adw-title">{Trad("time_limit")}</Grid>
        <Grid container className='block-container'>
            <Grid item xs={6} style={{ overflow: 'visible' }} className="message_details_leftcombo">
                <DatePropertyEditor model={model} property='ConfirmationTimeLimit' label={Trad("confirmation_limit")} />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <PropertyEditor model={model} property='ConfirmationComments' label={Trad("comment")} />
            </Grid>
            <Grid item xs={6} style={{ overflow: 'visible' }} className="message_details_leftcombo">
                <DatePropertyEditor model={model} property='TechDeliveryTimeLimit' label={Trad("technical_deadline")} />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <PropertyEditor model={model} property='TechDeliveryComments' label={Trad("comment")} />
            </Grid>
            <Grid item xs={6} style={{ overflow: 'visible' }} className="message_details_leftcombo">
                <DatePropertyEditor model={model} property='CancellationTimeLimit' label={Trad("cancel_limit")} />
            </Grid>
            <Grid item xs={6} className="message_details_rightcombo">
                <PropertyEditor model={model} property='CancellationComments' label={Trad("comment")} />
            </Grid>
        </Grid>
    </>
}