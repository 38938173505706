import * as React from "react";
import { extractSub, JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { RootState } from "../../../redux/store";
import { PanelDisable } from "../Generic/Common.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";

export function OfferValidatior({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['Campaign']), JSONEqualityComparer) ?? {};

    return <PanelDisable disabled={!Boolean(message.Campaign)} text={Trad('select_campaign')} >
        {children}
    </PanelDisable>
}

export function PricingValidatior({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['Campaign', 'Currency']), JSONEqualityComparer) ?? {};
    return <PanelDisable disabled={!Boolean(message.Campaign) || !Boolean(message.Currency)} >
        {children}
    </PanelDisable>
}

export function DiffusionValidator({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['Campaign']), JSONEqualityComparer) ?? {};
    return <PanelDisable disabled={!Boolean(message.Campaign)}>
        {children}
    </PanelDisable>
}

export function MetricsValidatior({ children }) {
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ['Campaign', 'Currency']), JSONEqualityComparer) ?? {};
    return <PanelDisable disabled={!Boolean(message.Campaign) || !Boolean(message.Currency)} >
        {children}
    </PanelDisable>
}

type MediaValidatorProps = { children: React.ReactNode, mediaType?: string, notEquals?: boolean }
export function MediaValidator({ children, mediaType, notEquals }: MediaValidatorProps) {
    const media = useSelector((root: RootState) => root.messageEditor.get().Media);
    const [medias, setMedias] = React.useState<ref_Media[]>();

    React.useEffect(() => {
        KPIsManagerCache.GetMedias().then(setMedias);
    }, [Boolean(medias)])

    if (!medias) return <></>

    const checkMediaType = () => {
        const mediaCheckRid = medias.find(m => m.Code == mediaType)?.["@rid"];
        return (mediaCheckRid == media) != Boolean(notEquals);
    }

    return <>
        {mediaType
            ? (checkMediaType() && children)
            : media && children}
    </>
}