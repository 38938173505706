import { Client } from "hub-lib/client/client.bin";
import { ref_Favorites } from "hub-lib/models/orientdb/ref_Favorites.bin";
import { distinct, propertyOf } from "hub-lib/tools.bin";

export class Favorites {

    private uniqFavorites: ref_Favorites = null;

    static instance: Favorites;
    static GetInstance() {
        if (!Favorites.instance) {
            Favorites.instance = new Favorites();
        }
        return Favorites.instance;
    }

    private _getTable = async () => (await Client.searchVertexTyped(ref_Favorites, { [propertyOf<ref_Favorites>('Table')]: "all" }))?.[0];

    async Initialize() {
        this.uniqFavorites = await this._getTable();
        if (!this.uniqFavorites) {
            const allExistingFavorites = await Client.searchVertexTyped(ref_Favorites, {});
            this.uniqFavorites = new ref_Favorites();
            this.uniqFavorites.Table = "all";
            this.uniqFavorites.TableIds = distinct(allExistingFavorites.map(fav => fav.TableIds).flat());
            await Client.createVertex(ref_Favorites.name, this.uniqFavorites, false);
            this.uniqFavorites = await this._getTable();
        }
        return this.uniqFavorites;
    }

    async GetTable() {
        return this.Initialize();
    }

    GetCurrentTable() {
        return this.uniqFavorites;
    }
}