import * as React from "react";

import { GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import { Trad } from "trad-lib";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { CustomIconButton } from "../Generic/CustomIconButton";

export function MyCommandCell(arg: {
    remove: any,
    isDisabled?: (d: ref_Discount) => boolean,
    additionnalCommands?: { icon: JSX.Element, function: (d: any) => void, color?: string }[]
}) {

    const { remove, isDisabled, additionnalCommands } = arg;
    return class extends React.Component<any, any> {

        constructor(props: any) {
            super(props);
            this.state = {
                open: false
            }
        }

        render() {

            let open = (this.state as any)?.open;
            const dataItem: ref_Discount = this.props.dataItem;

            return <td className="k-command-cell clear-padding">

                {additionnalCommands?.map((c, i) => (
                    <CustomIconButton key={`additionnalCommands-cell-${i}`} disabled={isDisabled && isDisabled(dataItem)} style={{ color: c.color ?? '#009BCE' }} aria-label="edit" onClick={() => c.function(dataItem)}>
                        {c.icon}
                    </CustomIconButton>))}

                {/* <CustomIconButton disabled={isDisabled && isDisabled(dataItem)} style={{ color: '#009BCE' }} aria-label="edit" onClick={() => this.setState({ open: !open })}>
                    <DeleteIcon />
                </CustomIconButton> */}
                <GenericDialog
                    dialogTitle={Trad("confirmation")}
                    open={open}
                    submitTitle={Trad("yes")}
                    dialogContent={<p>{Trad("delete_confirmation")}</p>}
                    submitClass={"custom_btn_danger"}
                    handleClose={() => this.setState({ open: !open })}
                    actions={true}
                    submitAction={() => {
                        this.setState({ open: !open });
                        remove(dataItem)
                    }}
                />
            </td>
        }
    }
}