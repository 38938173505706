
import * as React from "react";
import { Trad } from "trad-lib";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { VertexAutocomplete } from "adwone-lib/index";
import { IRid } from "hub-lib/models/IRid.bin";
import { ChangeRateConfig } from "./CurrenciesGrid.bin";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { switchStyles } from "../../Tools";
import { DateRangePickerFilter } from "../Filters/DateRangePickerFilter";

export class TProps {
    config: ChangeRateConfig;
    onConfChange: () => any;
}

export function CurrenciesFilters({ config, onConfChange }: TProps) {

    const [reverse, setReverse] = React.useState(config.reverse);

    /**
     * Selection d'un group Advertiser
     * @param advGrp
     */
    const onAdvChange = (advGrp: IRid) => {
        if (advGrp) {
            config.Company = advGrp["@rid"];
            onConfChange();
        }
    }

    /**
     * Switch type de saisie
     * @param e
     * @param value
     */
    const switchChange = (event: SwitchChangeEvent) => {
        config.reverse = event.value;
        onConfChange();
        setReverse(config.reverse);
    }

    return <>
        <div className="adw-row">
            <DateRangePickerFilter />
            {/* <DateRangepicker
                defaultStart={config.Start}
                defaultStop={config.End}
                handleChangeDate={onChangeDate}
                id={'curencies-datepicker'} /> */}
        </div>
        <div className="adw-row">
            <VertexAutocomplete
                type={ref_AdvertiserGroups.name}
                disableClearable={true}
                defaultValue={((options: IRid[]) => options?.find((v) => v["@rid"] === config.Company))}
                onChange={(value: IRid) => onAdvChange(value)}
                label={Trad("advertiser_group")} />
        </div>
        <div className="adw-row" style={switchStyles.switchContainer}>
            <div style={switchStyles.switchLabel}>{Trad("switch_entry")}</div>
            <Switch
                size='small'
                checked={reverse}
                onChange={switchChange} />
        </div>


    </>
}