import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_CurrenciesId = rid;

export class ref_Currencies extends Referentials {
	"@rid"?: ref_CurrenciesId;
	Code: string;
	End: Date;
	Label: string;
	Name: string;
	Start: Date;

}