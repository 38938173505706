import * as React from "react";
import { GridCellProps } from "@progress/kendo-react-grid";
import { ADWGrid, AdwRow, eComputeEvent, ePropertyEvent } from "adwone-lib/index";
import { TdCell } from "./CustomCell.bin";
import { useEffect, useState } from "react";
import { ConsoleDebug } from "../../../utils/localstorage.bin";
import { VertexAutocomplete } from "adwone-lib/index";

import './cells.scss';
import { GetHashCode, GetSubElement } from "hub-lib/tools.bin";

export class VertexRidCellArgs<TRow> {

    /**
     * ComboBox choices
     */
    dataProvider: (row: any) => Promise<any[]> | any[];

    dataProviderFormater: (data: any) => string;

    bindingField?: string;

    isValid?: (value: any) => boolean;

    cellValue?: (cellValue: any, dataItem?: AdwRow<TRow>) => Promise<any>;

    cellContent?: (cell: { Formated: string, Value: any }, row: AdwRow<TRow>) => Promise<any>;

    editable?: boolean;

    onChange?: (row: any, field: string) => any;

    frozen?: "left" | "right";
    frozenLeftPx?: number;
    frozenRightPx?: number;

    className?: string;

    classNameProvider?: (row: AdwRow<TRow>) => string;

    grid?: { selectRow: (row: any) => any }

    vGrid?: ADWGrid<TRow>;
}

/**
 * Cellule de vertex en édition
 * Affiche un DropDownList avec les vertices disponibles
 * @param props
 * @returns
 */
function DropDownCellComponent<TRow>(props: GridCellProps & { args: VertexRidCellArgs<TRow> }) {

    const [data, setData] = useState<any>(undefined);
    const [value, setValue] = useState<any>(undefined);
    const [errors, setErrors] = useState<string[]>(undefined);

    const { args, field, dataItem, onChange } = props;

    const onError = (_errors) => {
        ConsoleDebug(`Fire event error from: `, props?.field, ` for `, _errors);
        setErrors(_errors);
    }

    useEffect(() => {
        updateComponent();
        updateValue();
        ADWGrid.onErrorProperties.addListener(ePropertyEvent.error, onError);
        const row: AdwRow<any> = dataItem;
        row.event?.addListener(eComputeEvent.forceUpdate, forceUpdateComponent);

        return () => {
            ADWGrid.onErrorProperties.removeListener(ePropertyEvent.error, onError);
            row.event?.removeListener(eComputeEvent.forceUpdate, forceUpdateComponent);
        }
    });

    const forceUpdateComponent = async (e?) => {
        const newData = (await args.dataProvider(dataItem))
            ?.map((d: any) => ({
                text: args.dataProviderFormater ? args.dataProviderFormater(d) : d.toString(),
                value: d
            }));
        setData(newData);
    }

    const updateComponent = async (e?) => {
        if (!data)
            await forceUpdateComponent(e);
    }

    const updateValue = () => {
        if (data) {
            const dataValue = dataItem[field] === null ? '' : dataItem[field];
            const newValue = data?.find((c: any) => args.bindingField ? c.value[args.bindingField] === dataValue : c.value === dataValue);
            setValue(newValue);
        }
    }

    const handleChange = async (val: any) => {
        let newValue = val?.value;
        if (newValue && args.bindingField) newValue = newValue[args.bindingField];

        dataItem[field] = newValue ?? null;

        setValue(newValue);
        await args?.onChange(dataItem, field);
    }

    function checkArgsValidity(dataItem: any): boolean {
        if (errors?.includes(props.field)) return false;
        return args.isValid ? args.isValid(dataItem) : true;
    }

    let className = `${args.className ?? ""} `;
    let frozen = undefined;
    if (args.frozen) {
        className += ` k-grid-content-sticky `;
        frozen = { style: { left: args?.frozenLeftPx, right: args?.frozenRightPx } };
    }

    return <td className={className + " edit-cell-VertexAutocomplete"} {...frozen}>
        {data?.length > 0 && dataItem.inEdit && args.editable &&
            <div className={!checkArgsValidity(dataItem) ? "not-valid" : ""}>
                <VertexAutocomplete
                    key={`VertexAutocomplete-${args.bindingField}-${value?.text}-${GetHashCode(data)}`}
                    onChange={handleChange}
                    multiple={false}
                    defaultValue={(values) => value}
                    // disabled={!checkArgsValidity(dataItem)}
                    options={data}
                    nullOnClear
                    getOptionLabel={(o) => o.text}
                    label={""} />
            </div>}
    </td>
}

/**
 * Affichage d'une cellule qui n'est pas en édition ou non éditable
 * @param props
 * @returns
 */
function ReadOnlyCell<TRow>(props: GridCellProps & { args: VertexRidCellArgs<TRow> }) {

    const { args, dataItem, field } = props;
    if (!dataItem)
        return <td></td>;

    const getCellContent = () => {
        const f = args?.dataProviderFormater;

        const dataValueBase = GetSubElement(dataItem, field) ?? GetSubElement(dataItem.dataItem, field);
        const dataValue = dataValueBase ?? '';

        const newContent = args?.cellValue ?
            args?.cellValue?.(dataValue, dataItem) :
            dataValue;

        const element = React.isValidElement(newContent) ? newContent : (f ? f(newContent) : newContent);
        return element;
    }

    const [content, setContent] = useState<any>(getCellContent());

    useEffect(() => {
        const row: AdwRow<any> = dataItem;
        row.event?.addListener(eComputeEvent.forceUpdate, updateContent);
        return () => { row.event?.removeListener(eComputeEvent.forceUpdate, updateContent) };
    })

    const updateContent = () => {
        setContent(getCellContent());
    }

    const getClassName = () => {
        let className = `${args.className ?? ""} `;
        className += args?.classNameProvider ? ` ${args.classNameProvider(dataItem)} ` : '';
        if (args.frozen)
            className += ` k-grid-content-sticky `;
        return className;
    }

    const getStyle = () => {
        let style: React.CSSProperties = undefined;
        if (args.frozen)
            style = { left: args?.frozenLeftPx, right: args?.frozenRightPx };
        return style;
    }

    return <TdCell className={getClassName()} style={getStyle()} grid={args.grid} row={props.dataItem}>
        {content}
    </TdCell>
}

/**
 * Composant graphique d'une cellule qui représente un vertex
 * La cellule se transorme en DropDownList si la row est en édition et la cellule éditable
 * @param args
 * @returns
 */
export function VertexRidCell<TRow>(args: VertexRidCellArgs<TRow>) {
    return function (props: GridCellProps) {

        const { dataItem, rowType } = props;
        if (!dataItem || rowType == 'groupHeader')
            return <></>;

        if (!dataItem.inEdit || !args.editable)
            return <ReadOnlyCell {...props} args={args} />;

        return <DropDownCellComponent {...props} args={args} />;
    }
}