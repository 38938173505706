import { Referentials, ReferentialsId } from "./Referentials.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";

export type lnk_HierarchyId = rid;

export class lnk_Hierarchy extends E<ReferentialsId, ReferentialsId> {
	"@rid"?: lnk_HierarchyId;
	Active: boolean;
	End: Date;
	Start: Date;

}