import * as React from 'react'
import { createStyles, LinearProgress, withStyles } from '@material-ui/core';


const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: 'rgb(240,240,240)',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#009BCE',
    },
  }),
)(LinearProgress);

class TProps{
    current: number
    maximum: number
}

export class CustomProgressBar extends React.Component<TProps, any>{
    constructor(props: TProps) {
        super(props);
    }
    render(){
        return(
            <BorderLinearProgress variant="determinate" value={(this.props.current)*100/this.props.maximum} /> 
        )
    }
}