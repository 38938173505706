import { Grid } from '@material-ui/core';
import { Client } from 'hub-lib/client/client.bin';
import { ref_CustomersExtended } from 'hub-lib/dto/referential/ref_CustomersExtended.bin';
import * as React from 'react'
import { Trad } from 'trad-lib';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import Template from '../Template';
import { CustomerSettingsForm, eMode } from './CustomerSettingForm.bin';

class TState {
    customerSetting: ref_CustomersExtended
}
class EditCustomerSetting extends React.Component<any, TState>{
    constructor(props: any) {
        super(props)
        let newState = new TState()
        this.state = newState
    }
    componentDidMount(){
        Client.searchVertex(ref_CustomersExtended.name, {"@rid": decodeURIComponent(this.props.match.params.customerSettingId)}).then(r=>{
            this.setState({
                customerSetting: r.data.results[0]
            })
        })
    }
    render() {
        return (
            <>
                {this.state.customerSetting && <div className="grid_container" style={{height: "auto"}}>
                    <div style={{ width: '100%'}}>
                        <Grid item xs={12}>
                        <BreadcrumbsCustom elements={[
                                { text: Trad("home"), href: "/" },
                                { text: Trad("customer_settings"), href: "/customers"},
                                { text: Trad("edit_customer_settings")}
                            ]} />
                        </Grid>
                        <CustomerSettingsForm mode={eMode.modify} customerSetting={this.state.customerSetting}/>
                    </div>
                </div>}
            </>
        )
    }
}

export default Template(EditCustomerSetting);