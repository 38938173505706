import React from 'react'
import Template from '../Template'

import { CouplagesGrid } from '../../VertexGrid/Suppliers/CouplagesGrid';

const Couplages = () => {
    return (
        <>
            <div className="grid_container">
                <CouplagesGrid />
            </div>
        </>

    );
}

export default Template(Couplages);