let pointerX = -1;
let pointerY = -1;
let target: EventTarget = undefined;

document.onmousemove = function (event) {
    target = event.target;
    pointerX = event.pageX;
    pointerY = event.pageY;
}

export function GetMousePosition() {
    return {
        target,
        pointerX,
        pointerY
    };
}