import { Grid, Button, Popover, TextField } from "@material-ui/core";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import React from "react";
import { Trad, TradClassName, TradProp } from "trad-lib";
import { FilterStorage } from "../../../utils/localstorage.bin";
import { getIcon, VertexAutocomplete } from "adwone-lib/index";
import { CampaignFilter, CampaignSearch, SearchProps } from "../Messages/CampaignSearch.bin";
import { CampaignInfos } from "./CampaignInfos";
import { Client } from "hub-lib/client/client.bin";
import { Format } from "format-lib/index.bin";
import { ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { clone, extractSub, GetHashCode, toArray, ToArrayObject } from "hub-lib/tools.bin";
import { AdvertiserHierarchyComponent } from "../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent";
import { StoreCreationCampaigns } from "../AdvertiserHierarchyComponent/StoreFilters";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { eKPI, eKPIType } from "hub-lib/models/KPIsManager.bin";
import { CustomTextField } from "../Generic/CustomTextField";
import { IAdvertiserHierarchy } from "hub-lib/types";
import { ModelPropertiesEditor } from "../Generic/ModelForm/ModelPropertiesEditor";

export type CampaignPickerArg = {
    isLocked?: boolean,
    options?: ref_Campaigns[],
    campaignFilter?: CampaignFilter,
    onFilterChanged?: SearchProps['onFilterChanged'],
    hideSearchButton?: boolean,
    onChange: (cmp: ref_Campaigns) => void,
    defaultValue: ref_Campaigns,
    params?: any;
    readonlyHiearchy?: boolean,
    validationMode?: boolean
}

export type StoreArg<THierarchy> = {
    // Pilotable componant
    store: THierarchy & { Campaign?: IRid["@rid"] },
    onStoreChanged?: (store: THierarchy, propsLocked: (keyof IAdvertiserHierarchy)[]) => void,
    campaign: ref_Campaigns
};

export function CampaignPicker({ isLocked, options, params, campaignFilter: _campaignFilter, defaultValue, validationMode, hideSearchButton, onChange, onFilterChanged }: CampaignPickerArg) {
    const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement>();
    const [campaignFilter, setCampaignFilter] = React.useState<CampaignFilter>(_campaignFilter ?? { ...FilterStorage.GetLocalStorageFiltersObj() });

    const openCampaignFilter = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);

    const getCampaignParams = () => {
        const searchParams: any = {
            ...(!params.CacheInfos && {
                AdvertiserGroup: campaignFilter?.AdvertiserGroup,
                Advertiser: campaignFilter?.Advertiser,
                Brand: campaignFilter?.Brand,
                Product: campaignFilter?.Product,
                Start: campaignFilter?.Start,
                End: campaignFilter?.End
            }),
            Source: "ADWONE",
            Active: true,
            ...params
        };

        Object.keys(searchParams).forEach((key: string) => {
            if (searchParams[key] === undefined)
                delete searchParams[key];
        });

        return searchParams;
    }
    const open = Boolean(anchorEl);
    const id = open ? 'campaign_search_popover' : undefined;

    return <>
        <Grid container className="message_details_full" >
            <Grid item xs={12} className="selection-campaign-row">
                <div className={"campaign-autocomplete-container " + ((validationMode && !defaultValue) ? "k-invalid" : "")}>
                    <VertexAutocomplete
                        key={`campaign-selector-${defaultValue?.["@rid"]}`}
                        nullOnClear
                        disabled={isLocked}
                        {...(options && {
                            options,
                            key: `campaign-selector_${GetHashCode(options?.map(o => o['@rid']))}_${defaultValue?.["@rid"]}`
                        })}
                        refreshOnPropChanged
                        type={ref_Campaigns.name}
                        label={Trad("campaign")}
                        defaultValue={_options => _options.find(e => e["@rid"] == defaultValue?.["@rid"]) ?? defaultValue}
                        params={getCampaignParams()}
                        onChange={onChange} />
                </div>
                {!hideSearchButton && <>
                    <CustomButton
                        Label={getIcon("filter_list")}
                        className={`custom_btn_primary`}
                        onClick={openCampaignFilter} />

                    {/* <CustomIconButton
                            className="custom_btn_primary"
                            onClick={openCampaignFilter}>
                            {getIcon("filter_list")}
                        </CustomIconButton> */}

                    {/* <Button aria-describedby={id} variant="contained" className="custom_btn_primary" onClick={openCampaignFilter}>
                            <FilterList />
                        </Button> */}
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(undefined)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <div style={{ margin: 20 }}>
                            <CampaignSearch
                                // saveOnChange
                                filter={campaignFilter as any}
                                onFilterChanged={(filter: CampaignFilter) => {
                                    setCampaignFilter(filter);
                                    onFilterChanged?.(filter);
                                }} />
                        </div>
                    </Popover>
                </>}
            </Grid>
        </Grid>

        <CampaignInfos campaignRid={defaultValue?.["@rid"]} params={params} />
    </>
}

export function AdvertiserInfos({ campaign, store, onStoreChanged }: StoreArg<IAdvertiserHierarchy>) {

    const propsLocked: any = ['AdvertiserGroup', 'Advertiser', 'Brand', 'Product'];
    const lockedProperties: (keyof IAdvertiserHierarchy)[] = campaign ? Object.entries(extractSub(campaign, propsLocked) ?? {}).filter(([k, v]) => v).map(([k, v]) => k) : propsLocked;

    const multiStore = ToArrayObject(store);
    return <>
        {!campaign?.Group &&
            <AdvertiserHierarchyComponent
                objectName={ref_Campaigns.name}
                multiSelection={false}
                reduxStore={StoreCreationCampaigns}
                store={multiStore}
                isLocked={Boolean(!multiStore.Campaign)}
                lockedKeys={lockedProperties as any}
                onChange={(conf) => {
                    const copy: IAdvertiserHierarchy = clone(conf);
                    copy.AdvertiserGroup = toArray(conf.AdvertiserGroup)?.[0];
                    copy.Advertiser = toArray(conf.Advertiser)?.[0];
                    copy.Brand = toArray(conf.Brand)?.[0];
                    copy.Product = toArray(conf.Product)?.[0];
                    onStoreChanged?.(copy, propsLocked)
                }} />}

        {campaign?.Group &&
            <Grid item xs={12} className="selection-campaign-row">
                <RidTextfield type={ref_Groups.name} rid={campaign.Group} />
            </Grid>}
    </>
}

export function SupportInfos({ campaign }: { campaign: ref_Campaigns }) {
    return <>
        {campaign &&
            <>
                <Grid item xs={6} className="message_details_leftcombo">
                    <RidTextfield type={ref_Supports.name} rid={campaign.Support} />
                </Grid>
                <Grid item xs={6} className="message_details_rightcombo">
                    <RidTextfield type={ref_Currencies.name} rid={campaign.Currency} />
                </Grid>
                <Grid item xs={6} className="message_details_leftcombo">
                    <RidTextfield type={ref_BroadcastAreas.name} rid={campaign.BroadcastArea} />
                </Grid>
                <Grid item xs={6} className="message_details_rightcombo">
                    <ModelPropertiesEditor model="ref_Messages" property='BroadcastAreaDetail' />
                    {/*<TextField label={TradProp("BroadcastAreaDetail", ref_Messages)} style={{ width: "100%" }} variant="outlined" value={campaign.ModelProperties?.["BroadcastAreaDetail"]} disabled />*/}
                </Grid>
                <Grid item xs={6} className={"message_details_leftcombo"}>
                    <CustomTextField disabled kpiType={eKPIType.Number} label={Trad("Pagination")} value={campaign.KPIs?.[eKPI.Pagination]} />
                </Grid>
                <Grid item xs={6} className={`message_details_rightcombo`}>
                    <ModelPropertiesEditor model="ref_Messages" property='EditorialTheme' />
                </Grid>
            </>
        }
    </>
}

type RidTextfieldProps = { type: string, rid: IRid["@rid"] }
function RidTextfield({ type, rid }: RidTextfieldProps) {

    const [text, setText] = React.useState<string>("");
    React.useEffect(() => {
        Client.searchVertex(type, { "@rid": rid }).then(res => {
            setText(Format(res?.data?.results?.[0]) ?? "");
        })
    }, [rid])

    return <TextField label={TradClassName(type)} style={{ width: "100%" }} variant="outlined" value={text} disabled />
}