import { IRid } from "../../models/IRid.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { ref_Sources } from "../../models/orientdb/ref_Sources.bin";

export class ref_History implements IRid {
    "@rid"?: rid;
    source: ref_Sources["@rid"];
    date: Date;
    rid: IRid["@rid"];
    previousObject: any;
    newObject: any;
    changes: any;
    checked?: boolean;
}