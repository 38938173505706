import { ref_CurrenciesId } from "../../models/orientdb/ref_Currencies.bin";
import { ref_CompaniesId } from "../../models/orientdb/ref_Companies.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { User } from "../../models/orientdb/User.bin";
import { IUpsertInfo } from "../../types";

export type lnk_ChangeRateId = rid;

export class lnk_ChangeRate implements IUpsertInfo {
	"@rid"?: lnk_ChangeRateId;
	in: ref_CurrenciesId;
	out: ref_CurrenciesId;
	Rate: number;
	Start: Date;
	End: Date;
	Company: ref_CompaniesId;
	Active: boolean;
	Created_at?: Date;
	Created_by?: User["@rid"];
	Updated_at?: Date;
	Updated_by?: User["@rid"];
}