import * as React from "react";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { NumericTextBox, NumericTextBoxHandle } from "@progress/kendo-react-inputs";
import { GridCellProps } from '@progress/kendo-react-grid';
import { GetCellTemplate, GetNumericFormat, GetNumericStep } from "format-lib/index.bin";
import { DiscountCategoryArray } from "../DiscountCategoryArray.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { AgreementValueComparer } from "./AgreementValueComparer";

export type CellNumberOptions = {
    type: ePropType,
    minmax?: boolean,
    min?: number,
    max?: number,
    color?: boolean,
    step?: number,
    format?: string,
    applyOperator?: boolean
}

export type CellNumberPropsType = GridCellProps & { ptr: DiscountCategoryArray, editable: boolean, opt: CellNumberOptions, currency?: string, itemChange: (event: { dataItem: any, value: any, field?: string, endEdit?: boolean }) => any }
export function CellNumberComponent({ dataItem, field, opt, ptr, editable, currency, itemChange }: CellNumberPropsType) {

    const key: keyof ref_Discount = field as any;
    const discount: ref_Discount = dataItem;
    const value = discount[key] as number;

    const [isEditing, setIsEditing] = React.useState(false);
    const ref = React.createRef<NumericTextBoxHandle>();

    const [stateValue, setStateValue] = React.useState(Math.abs(value));

    React.useEffect(() => {
        if (isEditing) {
            ref.current.focus();
            ref.current.element.select();
        }
    }, [isEditing])

    const minMax: { min?: number, max?: number } = {};

    // always > 0 when edit
    if (opt.minmax)
        minMax.min = 0;
    if (opt.min)
        minMax.min = opt.min;
    if (opt.max)
        minMax.max = opt.max;
    let valid = true;
    const validator = ptr?.props?.validator?.[key];
    if (validator) {
        const toDiscount = ptr.toDiscount(dataItem);
        const discounts = ptr.state.gridData
            .filter(d => !d.inEdit)
            .map(ptr.toDiscount);

        valid = validator(toDiscount, discounts, ptr.props.type)?.valid;
    }

    if (editable && (dataItem.inEdit || isEditing)) {
        return (<td
            colSpan={1}
            className="k-grid-edit-cell gray-font gray-back"
            onKeyDown={e => {
                if (e.code == "Enter" || e.code == "NumpadEnter") {
                    itemChange({ dataItem, value: Math.abs(stateValue), field: key, endEdit: true })
                    setIsEditing(false)
                }
            }}>
            <NumericTextBox
                ref={ref}
                className={'CellNumberComponent-editing-cell'}
                spinners={true}
                id={`${dataItem["@rid"]}-${key}`}
                step={opt.step ?? GetNumericStep(opt.type)}
                {...minMax}
                format={opt.format ?? GetNumericFormat(opt.type)}
                value={stateValue}
                valid={valid}
                onFocus={() => setStateValue(Math.abs(value))}
                onBlur={() => { itemChange({ dataItem, value: stateValue, field: key, endEdit: true }); setIsEditing(false) }}
                onChange={(e) => {
                    const newValue = Math.abs(e.value);
                    setStateValue(newValue);
                }} />
        </td>);
    }

    let valueNbr = Math.abs(value ?? 0);
    if (opt.applyOperator)
        valueNbr *= discount.Operator;

    const template = GetCellTemplate(opt.type);
    let str = `${template(valueNbr)}`;
    if (currency)
        str += ` ${currency}`

    let className = "";
    let star = false;
    if (opt?.color) {
        if (discount.IsRate) {
            star = true;
            className = "gray-font";
        }
    }

    const isEditingMessage = Boolean(ptr?.props?.message);
    return <td className={className + " k-grid-edit-cell gray-back"} style={{ textAlign: 'right', position: 'relative' }}
        onClick={() => {
            if (!editable)
                return;
            setStateValue(Math.abs(value));
            setIsEditing(true);
        }}>
        {isEditingMessage
            && editable
            && <AgreementValueComparer
                type="amount"
                discount={discount}
                formater={template} />}
        {star && <div className="star_cell">*</div>}
        {str}
    </td>

}