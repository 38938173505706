

import * as React from "react";
import { GridCellProps, GridEditCell } from '@progress/kendo-react-grid';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { ADWGrid, AdwRow, eComputeEvent, ePropertyEvent } from "adwone-lib/index";
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { GetNumericFormat, GetNumericStep } from 'format-lib/index.bin';
import { ConsoleDebug, IsDebugMode } from "../../../utils/localstorage.bin";
import { TooltipManager } from "../../CustomTooltip";
import { useEffect, useState } from "react";
import { GetCurrentLocale } from "trad-lib";
import { SimpleDatePicker } from "../../ConfigurableComponents/SimpleDatepicker.bin";
import { GetSubElement } from "hub-lib/tools.bin";


type TdCellArgs = { className: string, style: React.CSSProperties, children: any, row, grid: { selectRow: (row: any) => any }, tooltipText?: string };
export function TdCell({ className, style, children, grid, row, tooltipText }: TdCellArgs) {
    return <td
        onMouseOver={(e) => {
            let tooltip = undefined;
            if (tooltipText && typeof tooltipText === "string") tooltip = tooltipText;
            else if (typeof children === "string") tooltip = children;

            if (tooltip)
                TooltipManager.Push({ target: e.target, text: tooltip });
        }}
        onClick={e => {
            if (row.selected) return;
            if (IsDebugMode())
                console.log(`grid.selectRow(row)`, row, e);
            grid?.selectRow?.(row);
        }}
        className={className}
        style={style}>
        {children}
    </td>
}

type CustomCellArgs = {
    dataType: ePropType,
    textCell?: (value: any) => any,
    editable: boolean,
    classname: string,
    frozen?: 'left' | 'right',
    frozenLeftPx?: number,
    frozenRightPx?: number,
    onChange?: (row: any, field: string, value?: any) => any,
    noTemplate?: boolean,
    classNameProvider?: (row: AdwRow<any>) => string,
    cellValue?: (cellValue: any, dataItem?: AdwRow<any>) => Promise<any>,
    onEdit?: (newValue: any, dataItem: AdwRow<any>) => void,
    cell?: {
        isEditable?: (row: AdwRow<any>) => boolean;
    };
    grid?: { selectRow: (row: any) => any },
    vGrid?: ADWGrid<any>;
}

export const CustomCell = (args: CustomCellArgs) => {
    return function (props: GridCellProps) {

        if (!props.dataItem || props.rowType == 'groupHeader')
            return <></>;

        const { inEdit } = props.dataItem;
        const editable = args.cell?.isEditable?.(props.dataItem) ?? args.editable;

        if (!(inEdit && editable))
            return <ReadOnlyCell {...props} args={args} />

        return <CustomCellComponent {...props} args={args} />
    }
}

function OnlyCellValue(props: GridCellProps & { args: CustomCellArgs }) {

    const isMounted = React.useRef(false);
    const { args } = props;
    const style: React.CSSProperties = {};
    let classname = `${args?.classname ?? ""} `;

    if (args.frozen) {
        classname += ` k-grid-content-sticky `;
        if (args?.frozenLeftPx)
            style.left = args?.frozenLeftPx;
        if (args?.frozenRightPx)
            style.right = args?.frozenRightPx;
    }

    const cacheKey = `Cache-${props.field}`;
    const resCell = props.dataItem[cacheKey] ?? args.cellValue(props.dataItem[props.field], props.dataItem);
    const value = (!args.noTemplate && !resCell?.["then"]) ? resCell : undefined;

    const [loading, setLoading] = useState<any>(false);
    const [resObject, setResObject] = useState<any>(value);

    useEffect(() => {
        isMounted.current = true;
        if (!loading && resObject === undefined) {
            setLoading(true);
            Promise.resolve().then(async () => {
                if (isMounted.current && resObject === undefined) {
                    const resCached = await resCell;
                    props.dataItem[cacheKey] = resCached;
                    setResObject(!args.noTemplate && resCached);
                }
            });
        }
        return () => { isMounted.current = false };
    });

    const txtValue = props.dataItem?.[props.field]?.toString?.();
    return <TdCell className={classname} style={style} grid={args.grid} row={props.dataItem}>
        {args.noTemplate && <span onMouseOver={(e) =>
            TooltipManager.Push({
                target: e.target,
                text: txtValue
            })}>
            {txtValue}
        </span>}
        {resObject}
    </TdCell>
}

function ReadOnlyCell(props: GridCellProps & { args: CustomCellArgs }) {
    const { args, field, dataItem } = props;

    if (args.cellValue || args.noTemplate)
        return <OnlyCellValue {...props} />

    let classname = `${args?.classname ?? ""} `;

    const getValue = () => GetSubElement(dataItem, field) ?? GetSubElement(dataItem.dataItem, field);
    const [value, setValue] = useState(getValue());
    const row: AdwRow<any> = dataItem;

    // console.log(`[ReadOnlyCell]`, props.field, dataItem)

    useEffect(() => {
        row.event?.addListener(eComputeEvent.reRender, updateComponent);
        return () => { row.event?.removeListener(eComputeEvent.reRender, updateComponent) };
    })

    const updateComponent = () =>
        setValue(getValue())

    const style: React.CSSProperties = {};
    if (args.frozen) {
        classname += ` k-grid-content-sticky `;
        if (args?.frozenRightPx)
            style.right = args?.frozenRightPx;
        if (args?.frozenLeftPx)
            style.left = args?.frozenLeftPx;
    }

    const txt: any = args.textCell(value);
    let addclass = "default";
    if (typeof txt === 'string')
        addclass = txt?.replace(/\s/g, '') ?? "empty";

    classname += ` cellule_${addclass} `;
    classname += args.classNameProvider ? ` ${args.classNameProvider(props.dataItem)} ` : '';

    const txtValue = txt?.toString?.();
    return <TdCell className={classname} style={style} grid={args.grid} row={props.dataItem}>
        <span onMouseOver={(e) => {
            const reelVal = props.dataItem[props.field + "_cellValue"];
            if (typeof reelVal === "number") {
                TooltipManager.Push({
                    target: e.target,
                    text: reelVal?.toLocaleString(GetCurrentLocale(), { maximumFractionDigits: 8 })
                })
            } else
                TooltipManager.Push({
                    target: e.target,
                    text: txtValue
                })
        }}
            onMouseOut={(e) =>
                TooltipManager.Hide()}>
            {txtValue}
        </span>
    </TdCell >
}

function CustomCellComponent(props: GridCellProps & { args: CustomCellArgs }) {

    const { editor, args, dataItem, onChange } = props;
    const [errors, setErrors] = useState<string[]>(undefined);
    const [valid, setValid] = useState<boolean>(true);

    const onError = (_errors: string[]) => {
        ConsoleDebug(`Fire event error from: `, props?.field, ` for `, _errors);
        setErrors(_errors);
        setValid(!_errors?.includes(props?.field))
    }

    useEffect(() => {
        ADWGrid.onErrorProperties.addListener(ePropertyEvent.error, onError);
        return () => { ADWGrid.onErrorProperties.removeListener(ePropertyEvent.error, onError) };
    });

    switch (editor) {
        case "date":
            return <InputDateCell {...props} valid={valid} />
        case "numeric":
            return <InputNumericCell {...props} args={args} valid={valid} />
        case "text":
            return <InputTextCell {...props} valid={valid} />
        default:
            return <GridEditCell {...props} onChange={e => { args?.onChange?.(dataItem, e.field, e.value); return onChange(e) }} />
    }
}

function InputDateCell(props: GridCellProps & { valid?: boolean }) {

    const { dataItem, field, onChange, valid } = props;
    const [value, setValue] = useState(dataItem[field]);
    const row: AdwRow<any> = dataItem;

    useEffect(() => {
        row.event.addListener(eComputeEvent.reRender, updateComponent);
        return () => { row.event.removeListener(eComputeEvent.reRender, updateComponent) };
    })

    const updateComponent = () => {
        setValue(dataItem[field])
    }

    let event: any = {};
    const onblur: any = (a: GlobalEventHandlers, b: FocusEvent): any => {
        onChange(event);
    }

    return <td className="edit-datepicker-inline">
        <SimpleDatePicker
            ignoreContainer={true}
            valid={valid}
            id={`DateInput_${field}`}
            onBlur={onblur}
            value={value}
            ignorePopup={true}
            onChange={(e) => {
                dataItem[field] = e.value;
                event = {
                    dataItem: dataItem,
                    syntheticEvent: e.syntheticEvent,
                    field,
                    value: e.value
                };
                setValue(e.value);
            }}
        />
    </td>
}

function InputNumericCell(props: GridCellProps & { args: CustomCellArgs, valid: boolean }) {
    const { dataItem, field, args, valid } = props;
    const [value, setValue] = useState(dataItem[field]);
    const row: AdwRow<any> = dataItem;

    useEffect(() => {
        row.event.addListener(eComputeEvent.reRender, updateComponent);
        return () => { row.event.removeListener(eComputeEvent.reRender, updateComponent) };
    })

    const updateComponent = () => {
        setValue(dataItem[field])
    }

    const id = `NumericTextBox_${field}`;
    return <td>
        <NumericTextBox
            valid={valid}
            spinners={true}
            id={id}
            min={0}
            format={GetNumericFormat(args.dataType)}
            step={GetNumericStep(args.dataType)}
            value={value}
            onChange={(e) => {
                dataItem[field] = e.value;
                setValue(e.value);
                args.onEdit?.(e.value, dataItem);
            }} />
    </td>
}

function InputTextCell(props: GridCellProps & { valid: boolean }) {

    const { dataItem, field, valid } = props;
    const [value, setValue] = useState(dataItem[field]);
    const row: AdwRow<any> = dataItem;

    useEffect(() => {
        row.event.addListener(eComputeEvent.reRender, updateComponent);
        return () => { row.event.removeListener(eComputeEvent.reRender, updateComponent) };
    })

    const updateComponent = () => {
        setValue(dataItem[field])
    }

    const event: any = {};
    const onblur: any = (a: GlobalEventHandlers, b: FocusEvent): any => {
        props.onChange(event);
    };

    const id = `TextBox_${field}`;
    return <td>
        <Input
            id={id}
            ref={(ref) => {
                if (ref?.element) {
                    let element = document.getElementById(id);
                    if (element)
                        element.onblur = onblur
                }
            }}
            valid={valid}
            value={value}
            onChange={(e) => {
                dataItem[field] = e.target.value;
                setValue(e.target.value);
            }} />
    </td>
}