import { ref_Property, ref_PropertyId } from "./ref_Property.bin";
import { src_TSM, src_TSMId } from "./src_TSM.bin";
import { tsm_Format, tsm_FormatId } from "./tsm_Format.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_tsm_HasFormatId = rid;

export class vw_tsm_HasFormat extends ReferentialHasViews<src_TSMId, tsm_FormatId, ref_PropertyId> {
	"@rid"?: vw_tsm_HasFormatId;

}