import { ref_KPIs, ref_KPIsId } from "./ref_KPIs.bin";
import { eKPIType } from "../KPIsManager.bin";
import { ref_SourceTypes, ref_SourceTypesId } from "./ref_SourceTypes.bin";
import { ref_Model, ref_ModelId } from "./ref_Model.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";

export type lnk_HasKPIId = rid;

export class lnk_HasKPI extends E<ref_SourceTypesId, ref_ModelId> {
	"@rid"?: lnk_HasKPIId;
	Id: string;
	KPI: ref_KPIsId;
	Name: string;
	ValueType: eKPIType;

}