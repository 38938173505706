import { User } from "../../models/orientdb/User.bin";
import { ref_Persons } from "../../models/orientdb/ref_Persons.bin";
import { ref_Companies } from "../../models/orientdb/ref_Companies.bin";
import { ref_Sources } from "../../models/orientdb/ref_Sources.bin";
import { UserRightsId } from "../../models/orientdb/UserRights.bin";
import { ref_ModulesId } from "../../models/orientdb/ref_Modules.bin";
import { ref_CustomersExtended } from "./ref_CustomersExtended.bin";
import { UserPermissions } from "../../models/types/rights.bin";

export class UserExtended extends User {
    company: ref_Companies;
    person:ref_Persons;
    lastConnexion: Date;
    lastAccess: Date;
    isOnline: boolean;
    mail: string;
    job: string;
    profileName: string;
    sources: ref_Sources[];
    phone: string;
    Name?: string;
    customerRights?: UserRightsId;
    maskRights: number;
    authentication: "SSO" | "AdwOne";
    userPermissions: UserPermissions;
    customer?: ref_CustomersExtended;
    modules?: ref_ModulesId[];
}