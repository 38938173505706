import * as React from 'react';

export class Renderers {
    enterEdit;
    exitEdit;
    cancelEdit;
    editFieldName;
    preventExit;
    preventExitTimeout;
    blurTimeout;

    constructor(enterEdit, exitEdit, editFieldName, cancelEdit?) {
        this.enterEdit = enterEdit;
        this.exitEdit = exitEdit;
        this.cancelEdit = cancelEdit;
        this.editFieldName = editFieldName;
    }

    cellRender = (tdElement, cellProps) => {
        if (cellProps.expandable)
            return tdElement;

        const dataItem = cellProps.dataItem;
        const field = cellProps.field;
        const editedField = cellProps.dataItem[this.editFieldName];
        const additionalProps = (editedField && cellProps.field === editedField) ?
            {
                ref: (td) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                },
                onKeyDown: (e) => {
                    //console.log("Key", e.key);
                    if (e.key == "Enter") {
                        this.exitEdit.call();
                    }
                    if (e.key == "Escape") {
                        this.cancelEdit?.call(undefined, dataItem);
                    }
                }
            } : {
                onClick: (event) => {
                    const targetClasses = event.target.classList;
                    if (targetClasses &&
                        (targetClasses.contains('k-i-expand') || targetClasses.contains('k-i-collapse'))) {
                        return;
                    }
                    this.enterEdit.call(undefined, dataItem, field);
                }
            };

        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
    }

    rowRender = (trElement, _dataItem) => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                this.preventExit = true;
                clearTimeout(this.preventExitTimeout);
                this.preventExitTimeout = setTimeout(() => { this.preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(this.blurTimeout);
                if (!this.preventExit) {
                    this.blurTimeout = setTimeout(() => { this.exitEdit.call(undefined); });
                }
            },
            onFocus: () => { clearTimeout(this.blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }
}