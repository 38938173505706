import * as React from "react";

export function ContainerComponent({
    children,
    title,
    className,
    style,
}: React.ComponentPropsWithRef<"div">) {
    return (
        <div className={"container-component " + (className ?? "")} style={style}>
            <label
                className={
                    "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled "
                }
                data-shrink="true"
                id="mui-21845-label"
            >
                {title}
            </label>
            {children}
        </div>
    );
}
