import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_PersonsId = rid;

export class ref_Persons extends Referentials {
	"@rid"?: ref_PersonsId;
	FirstName: string;
	LastName: string;

}