import { ref_BroadcastAreas, ref_BroadcastAreasId } from "./ref_BroadcastAreas.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Supports, mm_SupportsId } from "./mm_Supports.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasBroadcastAreaId = rid;

export class vw_mm_HasBroadcastArea extends ReferentialHasViews<src_MMId, mm_SupportsId, ref_BroadcastAreasId> {
	"@rid"?: vw_mm_HasBroadcastAreaId;

}