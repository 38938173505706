import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface BackgroundState {

    visible: boolean;
    element: {
        top: number;
        left: number;
        width: number;
        height: number;
    }
}

const initialState: BackgroundState = {
    visible: false,
    element: undefined
}

export const backgroundSlice = createSlice({
    name: 'background',
    initialState,
    reducers: {
        showElement: (state, action: PayloadAction<Element>) => {
            if (action.payload) {
                const rect = action.payload.getClientRects();
                state.element = {
                    left: rect[0].left,
                    top: rect[0].top,
                    height: rect[0].height,
                    width: rect[0].width
                };
            }
        },
        show: (state) => {
            state.visible = true;
        },
        hide: (state) => {
            state.visible = false;
        },
    },
})

// Action creators are generated for each case reducer function
export const { show, hide, showElement } = backgroundSlice.actions

export const backgroundReducer = backgroundSlice.reducer;