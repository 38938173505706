import { Chip } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { Trad } from "trad-lib";

export function DateChip({ Start, End }: { Start: Date, End: Date }) {
    return (
        // <span>{`${Trad("from")} ${moment(Start).format(
        //     "DD/MM/YYYY"
        // )} ${Trad("to")}: ${moment(End).format("DD/MM/YYYY")}`}</span>
        // <div>
        //     <div>{`${Trad("from")}: ${moment(Start).format(
        //         "DD/MM/YYYY"
        //     )}`}</div>
        //     <div>{`${Trad("to")}: ${moment(End).format("DD/MM/YYYY")}`}</div>
        // </div>
        <>
            <Chip
                variant="outlined"
                label={`${Trad("from")}: ${moment(Start).format(
                            "DD/MM/YYYY"
                        )}`}
                className="chip-element"
            />
            <Chip
                variant="outlined"
                label={`${Trad("to")}: ${moment(End).format("DD/MM/YYYY")}`}
                className="chip-element"
            />
        </>
    )
}