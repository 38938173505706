const keyNotifications = 'notifications';

export function SetLocalStorageNotifications(params) {
    try {
        const notifStorage = localStorage.getItem('notifications');
        if (notifStorage) {
            const newStorage = JSON.parse(notifStorage)
            newStorage.push(params);

            // get only the last 20 notifications
            if (newStorage.length > 20) {
                (newStorage as []).splice(0, newStorage.length - 20);
            }

            localStorage.setItem(keyNotifications, JSON.stringify(newStorage));
        } else {
            localStorage.setItem(keyNotifications, JSON.stringify([params]));
        }
    } catch (error) {
        localStorage.removeItem(keyNotifications);
    }
}