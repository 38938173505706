import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { Trad } from "trad-lib";

export async function CheckElement(element: IRid, rid: IRid['@rid'], typeName: string) {
    if (element || !rid) return element;
    const [found] = (await Client.searchVertex(typeName, { '@rid': rid, Active: [true, false] }))?.data?.results;
    if (found) {
        return { ...found, warning: Trad('data_not_found') }
    }

    return element;
}