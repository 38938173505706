import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CursorPosition = {
    top: number,
    left: number
}

export interface TooltipState {
    text: string;
    position: CursorPosition;
}

const initialState: TooltipState = {
    text: undefined,
    position: undefined
}

export const tooltipSlice = createSlice({
    name: 'tooltip',
    initialState,
    reducers: {
        showTooltip: (state, action: PayloadAction<TooltipState>) => {
            state.text = action.payload.text;
            state.position = action.payload.position;
        },
        hideTooltip: (state) => {
            state.text = undefined,
            state.position = undefined
        },
    },
})

// Action creators are generated for each case reducer function
export const { showTooltip, hideTooltip } = tooltipSlice.actions

export const tooltipReducer = tooltipSlice.reducer;