import * as React from "react";

export function OverflowExpendable({ children }) {
    const refParent = React.useRef(null);
    const ref = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [isOverflow, setIsOverflow] = React.useState(null);
    React.useEffect(() => {
        if (ref.current && refParent.current) {
            setIsOverflow(isOverflow || ref.current.clientHeight > refParent.current.clientHeight);
        }
    });
    return (
        <>
            <div style={{position: "relative"}}>
                <div ref={refParent} className={`container-chip-overflow ${open ? "open" : ""}`} onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)}>
                    <div ref={ref}>
                        {children}
                    </div>
                </div>
                {isOverflow && !open && <span style={{position: "absolute", bottom: 0, right: 0}}>...</span>}
            </div>
        </>
    )
}