import * as React from 'react'
import { DateRangePicker, DateRangePickerProps } from '@progress/kendo-react-dateinputs'
import { Trad, TradComposed } from 'trad-lib';
import { CustomDateInput } from './CustomDateInput.bin';
import { ContainerComponent } from '../VertexGrid/Generic/ContainerComponent';
import { CustomIconButton } from '../VertexGrid/Generic/CustomIconButton';
import { getIcon, TooltipManager } from "adwone-lib/index";
import { EventEmitter } from 'events';
import moment from 'moment';

export class TProps {
    min?: Date;
    max?: Date;
    takeAllSpace?: boolean;
    defaultStart: Date;
    defaultStop: Date;
    handleChangeDate?: (value: { start: Date, end: Date }) => void
    id?: any;
    disabled?: boolean;
    maxYearsRange?: number;
}

export const DateRangepickerCloseEvent = new EventEmitter()

export function DateRangepicker({ min, max, takeAllSpace, defaultStart, defaultStop, handleChangeDate, id, disabled, maxYearsRange }: TProps) {

    const _defaultStartDate = defaultStart ? new Date(defaultStart) : null;
    const _defaultEndDate = defaultStop ? new Date(defaultStop) : null;

    const [startDate, setStartDate] = React.useState(defaultStart);
    const [endDate, setEndDate] = React.useState(defaultStop);

    React.useEffect(() => {
        setStartDate(_defaultStartDate);
    }, [_defaultStartDate?.getTime()])

    React.useEffect(() => {
        setEndDate(_defaultEndDate);
    }, [_defaultEndDate?.getTime()])

    const [open, setOpen] = React.useState(false);
    const [invalid, setInvalid] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const closePopup = () => setOpen(false);
    const handleClick = (event: any) => setOpen(!open);

    React.useEffect(() => {
        DateRangepickerCloseEvent.addListener("close", closePopup);
        return () => { DateRangepickerCloseEvent.removeListener("close", closePopup) };
    })

    const style: React.CSSProperties = { marginBottom: 15 };
    if (takeAllSpace)
        delete style.maxWidth;

    return (
        <ContainerComponent title={Trad("period")} className={(invalid ? " k-invalid " : "") + (disabled ? " Mui-disabled " : "")}>
            <div className={"clearfix daterangepicker_container "} id="custom_daterangepicker">

                <div className='daterangepicker_content'>
                    <div className={"clearfix daterangepicker_icon"}>
                        {open === false && <CustomIconButton onClick={handleClick} disabled={disabled}>{getIcon("dateRange")}</CustomIconButton>}
                        {open === true && <CustomIconButton onClick={handleClick} disabled={disabled}>{getIcon("expandLess")}</CustomIconButton>}
                    </div>

                    <div className={"clearfix daterangepicker_selector"}>
                        <DateRangePickerContent
                            disabled={disabled}
                            min={min}
                            max={max}
                            id={id}
                            show={open}
                            format="dd/MM/yyyy"
                            value={{ start: startDate, end: endDate }}
                            onChange={(e) => {
                                let { start, end } = e.value;
                                // stop if the date is the same
                                if (start?.getTime() === startDate?.getTime() && end?.getTime() === endDate?.getTime())
                                    return;
                                // min date check
                                if (min && start?.getTime() < min.getTime())
                                    start = min;
                                setStartDate(start);

                                if (!end) setEndDate(undefined);
                                else setEndDate(end);

                                // max years range check
                                const isOutOfRange = (maxYearsRange ? moment(end).diff(moment(start), 'years') >= maxYearsRange : false);

                                if (start && end && start.getTime() > end.getTime() || isOutOfRange) {
                                    if (isOutOfRange)
                                        setMessage(TradComposed("date_out_of_range", [maxYearsRange]));
                                    else
                                        setMessage(Trad("start_date_must_be_before_end_date"));
                                    return setInvalid(true);
                                }
                                if (invalid) setInvalid(false);
                                if (message) setMessage(null);
                                handleChangeDate?.({ start, end });
                                if (open && end)
                                    setOpen(false);
                            }} />
                    </div>
                </div>
                <span className="display-center" style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: message })}>
                    {message ? getIcon('info', {}, "warning_color") : <div className='material-icons MuiIcon-root'></div>}
                </span>
            </div>
        </ContainerComponent>
    )
}

const startInput = CustomDateInput({ prefix: () => Trad("from"), type: "start" });
const endInput = CustomDateInput({ prefix: () => Trad("to"), type: "end" });

function DateRangePickerContent(props: DateRangePickerProps) {

    const customProps: DateRangePickerProps = {};
    if (props.defaultValue) {
        const start = props.defaultValue.start ? new Date(props.defaultValue.start) : undefined;
        const end = props.defaultValue.end ? new Date(props.defaultValue.end) : undefined;
        customProps.defaultValue = { start, end }
    }

    if (props.value) {
        const start = props.value.start ? new Date(props.value.start) : null;
        const end = props.value.end ? new Date(props.value.end) : null;
        customProps.value = { start, end }
    }

    return <DateRangePicker
        startDateInput={startInput}
        endDateInput={endInput}
        {...props}
        {...customProps}
    />
}