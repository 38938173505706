import * as React from 'react'
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../redux/store';
import { Grid } from '@material-ui/core';
import { Trad } from 'trad-lib';
import { ContainerComponent } from '../Generic/ContainerComponent';
import { TextEditor } from '../TextEditor.bin';
import { setAttachment } from '../../../redux/campaignEditorSlice';

export function CommentsEditor() {

    const attachments = useSelector((root: RootState) => root.campaignEditor.attachments);

    const generateComments = (label: string, type: string, className: string) => {
        return (<div className={className}>
            <ContainerComponent title={label} className="auto-height no-padding no-margin">
                <TextEditor
                    content={attachments[type]}
                    onChange={(value: string) => store.dispatch(setAttachment({ type, value }))} />
            </ContainerComponent>
        </div>)
    }

    return <>
        {attachments && <Grid item xs={12} className="message_details_full" style={{ display: "flex" }}>
            {generateComments(Trad("internal_comment"), "Internal", "message_details_leftcombo half-width")}
            {generateComments(Trad("external_comment"), "External", "message_details_rightcombo half-width")}
        </Grid>}
    </>;
}