
import * as React from 'react'
import { vw_mm_HasSupport } from "hub-lib/models/orientdb/vw_mm_HasSupport.bin";
import { vw_mm_HasBroadcastArea } from "hub-lib/models/orientdb/vw_mm_HasBroadcastArea.bin";
import { IRid } from 'hub-lib/models/IRid.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { SupportExtended } from 'hub-lib/dto/referential/SupportExtended.bin';
import { extractSub, JSONEqualityComparer } from 'hub-lib/tools.bin';
import { useSelector } from 'react-redux';
import { TradProp } from 'trad-lib';
import { GetEditorState, RootState, SetModel, store } from '../../../../redux/store';
import { FavoriteVertexAutoComplete } from '../../../AdwAutocomplete/FavoriteVertexAutoComplete';
import { WarningMM } from './WarningMM';
import { CheckElement } from './CheckElement';


type SupportEditorArgs = {  onChange?: () => void, model : "ref_Messages" | "ref_Campaigns" }
export function SupportEditor({ onChange, model }: SupportEditorArgs) {

    const { Media, Support, BroadcastArea } = useSelector((root: RootState) => extractSub(GetEditorState(model, root).get(), ['Media', 'Support', 'BroadcastArea']) ?? {}, JSONEqualityComparer);

    return <WarningMM
        cross
        ids={[
            { id: Support, linkClass: vw_mm_HasSupport.name },
            { id: BroadcastArea, linkClass: vw_mm_HasBroadcastArea.name }
        ]}
        hasErrors={(errors) => Boolean(errors?.length)}>
        <FavoriteVertexAutoComplete
            disableClearable
            type={SupportExtended.name}
            refreshOnPropChanged
            label={TradProp("Support", ref_Messages) + " *"}
            params={{
                Medias: [Media],
                properties: ["*"],
                options: {
                    coverUrl: true
                }
            }}
            defaultValue={(options: IRid[]) => CheckElement(options?.find(o => o["@rid"] == Support), Support, ref_Supports.name)}
            onChange={async (irid: IRid) => {
                const support = irid as ref_Supports;
                const data = GetEditorState(model, store.getState()).get();

                if (support?.Medias?.length == 1)
                    data.Media = support.Medias[0];
                data.Support = support["@rid"];
                data.ModelProperties["Periodicity"] = support.Periodicity;

                await SetModel(model, data);
                onChange?.();
            }} />
    </WarningMM>
}