
import * as React from 'react'
import { ref_SchedulerConfigurations } from "hub-lib/dto/client/ref_SchedulerConfigurations.bin";
import { Trad } from "trad-lib";
import { getIcon } from "adwone-lib/index";
import { ContainerComponent } from "../../Generic/ContainerComponent";
import { CustomIconButton } from "../../Generic/CustomIconButton";
import { ColorPicker, ColorPickerChangeEvent } from '@progress/kendo-react-inputs';
import { distinct, firstOrDefault, RGBAToHexA } from 'hub-lib/tools.bin';
import { IsTargetInside, OutsideAlerter } from '../../Generic/Common.bin';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type TextStyleEditorArgs = { template: ref_SchedulerConfigurations, type?: string, selectedItems?: string[], onChange: (v: string) => void }
let colorPickerId = 0;
export function TextThemeEditor({ template, type, selectedItems, onChange }: TextStyleEditorArgs) {
    /**
 * TEXT
 * @param style
 * @returns
 */
    const isStyleSelected = (style: "bold" | "italic" | "underline") => {
        if (type)
            return selectedItems
                ?.map(s => template.Style[s])
                ?.every(t => t && t.textStyle?.[style]);
        else
            return template.StyleDefault.textStyle[style];
    }
    /**
         * SET STYLE TEXTE
         * @param style
         * @returns
         */
    const setStyleText = (style: "bold" | "italic" | "underline") => {
        if (type)
            selectedItems.forEach(s => {
                if (!template.Style[s]) template.Style[s] = { type: type, textStyle: { [style]: true } };
                else {
                    if (!template.Style[s].textStyle) template.Style[s].textStyle = { [style]: true };
                    else template.Style[s].textStyle[style] = !template.Style[s].textStyle[style];
                }
            });
        else
            template.StyleDefault.textStyle[style] = !template.StyleDefault.textStyle[style];
    }

    return <ContainerComponent title={Trad("text_formatting")}>
        <div className='text-form-container' style={{ display: "flex" }}>
            <CustomIconButton className={"no-radius margin-right " + (isStyleSelected("bold") ? "is-selected" : "")}
                onClick={() => { setStyleText("bold"); onChange("bold") }}>{getIcon("bold")}</CustomIconButton>
            <CustomIconButton className={"no-radius margin-right " + (isStyleSelected("italic") ? "is-selected" : "")}
                onClick={() => { setStyleText("italic"); onChange("italic") }}>{getIcon("italic")}</CustomIconButton>
            <CustomIconButton className={"no-radius margin-right " + (isStyleSelected("underline") ? "is-selected" : "")}
                onClick={() => { setStyleText("underline"); onChange("underline") }}>{getIcon("underlined")}</CustomIconButton>
        </div>
    </ContainerComponent>
}


export function ColorItem(args: { value: string, selectedItems?: string[] }) {
    const style: CSSProperties = {};
    if (args.value)
        style.backgroundColor = args.value;
    let textValue: string = "";
    if (args?.selectedItems?.length > 1)
        textValue = args.selectedItems.length.toString();
    if (args?.selectedItems?.length > 99)
        textValue = "99+";
    return <span className='colorItem' style={style}>
        {textValue}
    </span>
}

let timeoutColorPickerChanged = undefined;

export class CustomColorPickerProps { defaultValue: string; fixedValues: string[]; badge?: boolean; onValueChange?: (value: string) => void; }

export function CustomColorPicker(props: CustomColorPickerProps) {
    const [open, setOpen] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>();
    const [colorPickerClassName,] = React.useState(`colorpicker-${colorPickerId++}`);

    const handleOnClick = (e: any) => {
        setOpen(!open);
        e.stopPropagation();
    }

    const handleOnChange = (event: ColorPickerChangeEvent) => {
        const hexaValue = RGBAToHexA(event.value, true);
        setValue(hexaValue);
        clearTimeout(timeoutColorPickerChanged);
        timeoutColorPickerChanged = setTimeout(() => {
            props?.onValueChange(hexaValue);
        }, 1500);
    }

    React.useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue]);

    const isSelected = () => {
        return value;
    }

    const paletteSettings = {
        palette: TextColors,
        columns: TextColors.length,
        tileSize: 30,
    };

    const wrapperRef = React.useRef(null);
    const iconStyle: React.CSSProperties = { padding: "0px", width: 18, height: 18 };
    if (isSelected())
        iconStyle.color = value;

    return <>

        <div className='custom-colorPicker'
            onClick={e => {
                // pour eviter de selectionner la cellule si le picker est dans un tableau
                e.stopPropagation();
            }}>
            <ColorPicker ref={wrapperRef} view="combo"
                open={open}
                value={value ?? '#0000FF'}
                onChange={handleOnChange}
                gradientSettings={{ opacity: false }}
                popupSettings={{ className: colorPickerClassName }}
                paletteSettings={paletteSettings} />

            <CustomIconButton onClick={handleOnClick}
                className="k-button k-button-rectangle k-button-solid k-button-solid-base k-icon-button k-input-button no-radius">
                {props.badge && <ColorItem value={value} />}
                {!props.badge && <span className='k-icon k-i-edit-tools' style={iconStyle}></span>}
                <span role="presentation" className="k-button-icon k-icon k-i-arrow-s"></span>
            </CustomIconButton>
            {open && <OutsideAlerter wrapperRef={wrapperRef} callback={target => {
                const element = document.querySelector(`.${colorPickerClassName}`);
                if (!IsTargetInside(element, target))
                    setOpen(false);
            }} />}
        </div>
    </>
}

export const TextColors = [
    "#000000",
    "#3B93F1",
    "#24A9D5",
    "#56BE83",
    "#F48A48",
    "#DF4F5B",
]

export const BackgroundColors = [
    "#DEEDFD",
    "#DBF1F8",
    "#C7E9D6",
    "#FDEBE0",
    "#FAE2E4",
    "#F0F0F0",
]

export class TextColorEditorProps {
    badge?: boolean;
    template: ref_SchedulerConfigurations;
    type?: string;
    propKey?: string;
    selectedItems?: string[];
    onChange: () => void;
}

export function TextColorEditor({ badge, template, type, propKey, selectedItems, onChange }: TextColorEditorProps) {
    /**
     * SET COLOR TEXT
     * @param code
     */
    const setTextColor = (code: string) => {

        if (type)
            selectedItems?.forEach(s => {
                if (!template.Style[s]) template.Style[s] = { type: type, key: propKey, textStyle: { color: { code } } };
                else {
                    if (!template.Style[s].textStyle) template.Style[s].textStyle = { color: { code } };
                    else template.Style[s].textStyle.color = { code }
                }
            });
        else
            template.StyleDefault.textStyle.color = { code }
    }
    /**
     * TEXT COLOR
     * @param code
     * @returns
     */
    const getTextColors = () => {
        if (type) {
            return distinct(selectedItems?.map(s => template.Style[s]?.textStyle?.color?.code).filter(c => c), t => t);
        }
        return [template.StyleDefault.textStyle.color.code];
    }
    /**
     * RESET TEXT COLOR
     */
    const resetTextColor = () => {
        if (type)
            selectedItems.forEach(s => {
                if (template.Style[s])
                    delete template.Style[s];
            });
        else
            template.StyleDefault.textStyle.color.code = null;
    }
    const colors = getTextColors();
    const color = firstOrDefault(colors);

    let clearButtonStyle: CSSProperties = {};
    if (!color)
        clearButtonStyle.visibility = "hidden";

    return <div className='text-color'>
        <div className='color-item-container'>
            {badge && selectedItems?.length > 1 &&
                <ColorItem value={null} selectedItems={selectedItems} />
            }
        </div>
        <div className='picker-container'>
            <CustomColorPicker
                badge={badge ?? false}
                defaultValue={colors.length == 1 ? color : null}
                fixedValues={TextColors}
                onValueChange={(v) => { setTextColor(v); onChange?.() }}
            />
            <CustomIconButton onClick={() => { resetTextColor(); onChange?.() }}
                className="no-radius" style={clearButtonStyle}>
                {getIcon("reset_style")}
            </CustomIconButton>
        </div>
    </div>
}
