import * as React from "react";

import './notification.css'
import { toast, ToastId, TypeOptions } from "react-toastify";

import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { HubResponse, eCode, eBusinessCode } from "hub-lib/models/types.bin";
import { Trad, TradPropVName } from "trad-lib";
import { Loader } from "@progress/kendo-react-indicators";
import { SetLocalStorageNotifications } from "./localstorageNotifications";
import { CustomButton } from "../components/ConfigurableComponents/CustomButton.bin";

let toastId = null;
const dicoTaskIdToToastId: {
    [prop: string]: ToastId
} = {};

type NotifyOptions = {
    action?: {
        label: string,
        onclick: () => void
    },
    progress?: {
        taskId?: string,
        Current: number,
        Total: number
    }
};

export function Notify(msg: string, type: TypeOptions, options: NotifyOptions = {}) {
    const date = Date.now();
    const { progress, action } = options;
    SetLocalStorageNotifications({ msg, type, date })
    let component = (<>
        {type === "info" && progress && <div style={{ float: "left", height: 32, display: "flex", alignItems: "center", marginRight: 10 }}><Loader /></div>}
        {type === "info" && progress && <div className={"title-notification"}>{Trad("progress")}</div>}
        {type === "warning" && <WarningOutlinedIcon className={"img-notification img-back-notif-" + type}></WarningOutlinedIcon>}
        {type === "error" && <CloseOutlinedIcon className={"img-notification img-back-notif-" + type}></CloseOutlinedIcon>}
        {type === "info" && !progress && <InfoOutlinedIcon className={"img-notification img-back-notif-" + type}></InfoOutlinedIcon>}
        {type === "success" && <CheckOutlinedIcon className={"img-notification img-back-notif-" + type}></CheckOutlinedIcon>}
        {type === "warning" && <div className={"title-notification"}>{Trad("warning")}</div>}
        {type === "error" && <div className={"title-notification"}>{Trad("error")}</div>}
        {type === "info" && !progress && <div className={"title-notification"}>{Trad("information")}</div>}
        {type === "success" && <div className={"title-notification"}>{Trad("success")}</div>}
        <div style={{ whiteSpace: "pre-wrap" }}>{msg}</div>
        {action && <CustomButton Label={action.label} onClick={() => action.onclick()} />}
    </>);

    if (progress) {

        if (progress.taskId) {
            const currentToastId = dicoTaskIdToToastId[progress.taskId];
            if (currentToastId) {
                toast.update(currentToastId, { progress: (progress.Current / (progress.Total + 0.001)), render: component })
                if (progress.Current === progress.Total)
                    setTimeout(() => { toast.dismiss(currentToastId); }, 1000);
            } else {
                const newToastId = toast(component, {
                    position: "top-right",
                    type: type,
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    closeButton: false,
                    progress: (progress.Current / (progress.Total + 0.001)),
                });
                dicoTaskIdToToastId[progress.taskId] = newToastId;
            }
        } else {
            // LEGACY FOR DEVERSEMENT
            if (toastId)
                toast.update(toastId, { progress: (progress.Current / (progress.Total + 0.001)) })
            else {
                toastId = toast(component, {
                    position: "top-right",
                    type: type,
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    closeButton: false,
                    progress: (progress.Current / (progress.Total + 0.001)),
                });
            }
        }
    } else {
        toast(component, {
            position: "top-right",
            type: type,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export function closeNotify() {
    toast.update(toastId, { progress: 100 });
    toastId = null;
}

let dicoError: { [prop in eCode]: string } = {
    [eCode.Unknown]: "error_type_unknown", //"Erreur inconnue",
    [eCode.System]: "error_type_system", //"Erreur inconnue",
    [eCode.Validation]: "error_type_validation", //"Erreur inconnue",
    [eCode.Restricted]: "error_type_restricted", //"Erreur inconnue",
    [eCode.Create]: "error_type_create", //"Impossible de créer cet élément",
    [eCode.Update]: "error_type_update", //"Impossible de modifier cet élément",
    [eCode.Delete]: "error_type_delete", //"Impossible de supprimer cet élément"
    [eCode.Persist]: "error_type_persist", //"Impossible de persister cet élément"
}

let dicoTypeError: { [prop in eBusinessCode]: string } = {
    [eBusinessCode.UnknownError]: "error_business_unknown", // "Erreur inconnue",
    [eBusinessCode.MissingProperty]: "error_business_missingproperties", //"Propriété(s) manquante(s)",
    [eBusinessCode.AlreadyExist]: "error_business_alreadyexists", //"Propriété(s) déjà existante(s)",
    [eBusinessCode.RecordNotFound]: "error_business_recordnotfound", //"Element introuvable",
    [eBusinessCode.Unautorized]: "error_business_unauthorized", //"Non autorisé",
    [eBusinessCode.BadProperty]: "error_business_badproperty", //"Propriété(s) invalide(s)"
}

export function NotifyError(error: HubResponse & { vertex?: string }) {
    console.log(`error`, error);
    let defaultMsg = "Une erreur est survenue";

    if (!error)
        return Notify(defaultMsg, "error");

    let str = "";

    let codeError = error?.error?.code;
    if (codeError) {
        let errorType = Trad(dicoError[codeError]);
        str += (errorType ?? Trad(dicoError[eCode.Unknown]));
    }

    const message = error?.error?.data?.messageStatic;
    if (message) {
        str += "\n";
        str += Trad(message);
    } else {
        let code: eBusinessCode = error?.error?.data?.type;
        if (code) {
            str += "\n";
            str += Trad(dicoTypeError[code] ?? "");
        }

        if (str === "")
            return Notify(defaultMsg, "error");

        const vName = error?.vertex;
        let properties: string[] = error?.error?.data?.properties;
        if (properties) {
            str += ` (${properties?.map(p => TradPropVName(p, vName)).join(', ')})`;
        }
    }

    Notify(str, "error");
}

export function NotifyMissingProperties(properties) {
    NotifyError({ error: { code: eCode.Create, data: { type: eBusinessCode.MissingProperty, properties } } })
}
