

import { CrossedTableConfig } from "../crossedTable/CrossedTableConf.bin";
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';

export class CrossedTableConfigurable {
    static getAggregatedArgsForExports = (config: CrossedTableConfig) => {
        const document = CrossedTableConfig.ToExternalConfig(config);
        document.splitTab = {
            field: "Media",
            type: "@rid",
            linkedClass: ref_Media.name
        }
        return document;
    }
}

