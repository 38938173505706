import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_SubAgenciesId = rid;

export class ref_SubAgencies extends Referentials {
	"@rid"?: ref_SubAgenciesId;
	End: Date;
	Name: string;
	Start: Date;

}