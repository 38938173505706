import { ref_Supports, ref_SupportsId } from "./ref_Supports.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Supports, mm_SupportsId } from "./mm_Supports.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasSupportId = rid;

export class vw_mm_HasSupport extends ReferentialHasViews<src_MMId, mm_SupportsId, ref_SupportsId> {
	"@rid"?: vw_mm_HasSupportId;

}