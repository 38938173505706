import { Client } from "hub-lib/client/client.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { AdvertisingCompanyExtended } from "hub-lib/dto/referential/AdvertisingCompanyExtended.bin";
import { BroadcastAreaExtended } from "hub-lib/dto/referential/BroadcastAreaExtended.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Circulations } from "hub-lib/dto/client/ref_Circulations";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { Typed, compareObjects, distinct, extractSub, hasOwnProperty } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { ConsoleDebug } from "../utils/localstorage.bin";
import { ref_MessagesSerializable } from "./messageEditorSlice";
import { ref_CampaignsSerializable } from "./campaignEditorSlice";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import { lnk_HasBroadcastArea } from "hub-lib/models/orientdb/lnk_HasBroadcastArea.bin";
import { ref_Publications } from "hub-lib/dto/client/ref_Publications.bin";
import { ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Companies } from "hub-lib/models/orientdb/ref_Companies.bin";
import { ref_Agencies } from "hub-lib/models/orientdb/ref_Agencies.bin";
import { DataProvider } from "hub-lib/provider";
import { Notify } from "../utils/Notify.bin";
import { IMediaCompanyModel, IMediaModel } from "hub-lib/types";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { changeEndValue } from "../components/VertexGrid/Generic/ModelForm/DiffusionEditor";
import { NameSort } from "format-lib/index.bin";
import { eDialogMode } from "../components/ConfigurableComponents/GenericDialog.bin";

export const otherRid = "#-1:-1";
export interface IEditorState<TData, TDataSerializable> {
    mode: eDialogMode;
    agencyOptions: ref_Companies[];
    currencyOptions: TCurrency[];
    broadcastOptions: ref_BroadcastAreas[];
    data: TDataSerializable;
    lockNext: boolean;
    validationIssues: (keyof TData | string)[];
    publications: ref_Publications[];
    groups: { [key: string]: ref_Groups[] };
    get: () => TData;
}

export type TCurrency = Partial<ref_Currencies> & {
    Default: boolean,
    link: ref_Supports["@rid"] | ref_BroadcastAreas["@rid"]
}

export async function UpdateAgencyState(supportEditor: IEditorState<ref_Messages | ref_Campaigns, ref_MessagesSerializable | ref_CampaignsSerializable>, data: ref_MessagesSerializable | ref_Messages | ref_Campaigns | ref_CampaignsSerializable) {
    let agencyOptions: ref_Companies[] = undefined;

    if (supportEditor.agencyOptions == undefined) {
        ConsoleDebug('UpdateAgencyState');
        //TODO lien mandat avec l'annonceur
        agencyOptions = await Client.searchVertexTyped(ref_Agencies, { "Active": true });
        agencyOptions.push({ Name: Trad("other"), "@rid": otherRid } as any);
    }
    return agencyOptions;
}

export async function UpdateCurrencyState(supportEditor: IEditorState<ref_Messages | ref_Campaigns, ref_MessagesSerializable | ref_CampaignsSerializable>, data: ref_MessagesSerializable | ref_Messages | ref_Campaigns | ref_CampaignsSerializable) {

    const { BroadcastArea, Support, AdvCompany_Com } = supportEditor.data ?? {};
    let currencyOptions: TCurrency[] = undefined;

    if (data.Support && (supportEditor.currencyOptions == undefined ||
        BroadcastArea !== data.BroadcastArea || Support !== data.Support || AdvCompany_Com != data.AdvCompany_Com)) {
        ConsoleDebug('UpdateCurrencyState');

        const setCurrency = async () => {
            const setValue = (value: TCurrency) => {
                data.Currency = value?.["@rid"];
                return value;
            }

            // Recherche la zone de diffusion qui possède une devise
            // Si la zone de diffusion sélectionnée n'en possède pas, recherche de la zone de diffusion parente (récursif)
            // (search: BroadcastAreaExtended)
            let lastBroadcastArea: ref_BroadcastAreas["@rid"] = data.BroadcastArea;
            if (data.BroadcastArea) {
                const parents = new Set<IRid["@rid"]>();
                while (true) {
                    const [broadcastArea] = await Client.searchVertexTyped(BroadcastAreaExtended, { "@rid": lastBroadcastArea, parent: true });

                    if (!broadcastArea)
                        break;

                    lastBroadcastArea = broadcastArea["@rid"];
                    if (broadcastArea.lnkCurrencies?.length)
                        break;

                    const parent = (broadcastArea as any)?.parent;
                    if (!parent || parents.has(parent))
                        break;

                    lastBroadcastArea = parent;
                    parents.add(parent);
                }
            }

            // Récupération de la Régie commerciale
            // (search: AdvertisingCompanyExtended)
            let advertisingCompany: AdvertisingCompanyExtended = null;
            if (data.AdvCompany_Com)
                advertisingCompany = (await Client.searchVertexTyped(AdvertisingCompanyExtended, { "@rid": data.AdvCompany_Com }))?.[0];

            // Récupération des liens devises pour le support et la zone de diffusion qui possède une devise
            // (search: lnk_HasCurrency)
            const res: (lnk_HasCurrency & { outRid: IRid["@rid"], Code: string, Name: string, Default: boolean })[] = await Client.searchVertexTyped(lnk_HasCurrency, {
                Active: true,
                in: [data.Support, lastBroadcastArea].filter(e => e),
                properties: ["*", "out.Name as Name", "out.Code as Code", "out.@rid as outRid"]
            }) as any;

            currencyOptions = res.map(r => Typed<TCurrency>({
                "@rid": r.outRid,
                Name: r.Name,
                Code: r.Code,
                Default: r.Default,
                link: r.in
            }))

            //Ajout des devises provenant de la régie commerciale
            // (search: ref_Currencies)
            const links = advertisingCompany?.lnkBroadcastAreas?.filter(m => m.DefaultCurrency && m.out == data.BroadcastArea);
            if (links?.length) {
                const currencies = await Client.searchVertexTyped(ref_Currencies, { "@rid": links.map(l => l.DefaultCurrency) });
                currencyOptions = [
                    ...currencyOptions,
                    ...links.map(r => {
                        const currency = currencies.find(c => c["@rid"] == r.DefaultCurrency);
                        if (!currency) return null;
                        return Typed<TCurrency>({
                            "@rid": r.DefaultCurrency,
                            Name: currency.Name,
                            Code: currency.Code,
                            Default: r.Default,
                            link: r.in
                        })
                    }).filter(Boolean)]
            }

            //Ajout de la devise sélectionnée si elle manque dans la liste des options
            // (search: ref_Currencies)
            try {
                if (supportEditor.currencyOptions === undefined && data.Currency) {
                    const defaultValue = currencyOptions?.find(o => o["@rid"] == data.Currency || (o as any).outRid == data.Currency);
                    if (!defaultValue) {
                        console.log(`add inexising currency`)
                        const [missingValue] = await Client.searchVertexTyped(ref_Currencies, { "@rid": data.Currency });
                        if (missingValue) {
                            (missingValue as any).warning = Trad('data_not_found');
                            currencyOptions = [missingValue as any, ...currencyOptions];
                        }
                    }
                }
            } catch (error) {
                console.log(`error`, error)
            }

            const broadcastAreaWithCurrency = lastBroadcastArea;

            if (data.Currency) {
                const found = currencyOptions?.find(v => v["@rid"] == data.Currency);
                if (found) return setValue(found);
            }
            // Si une seule devise dans la liste alors on l'affecte à la data
            if (currencyOptions?.length === 1)
                return setValue(currencyOptions[0]);
            // Si il y a une devise par défaut pour la régie commerciale sur la zone de diff de la data alors on l'affecte à la data
            if (advertisingCompany) {
                const links = advertisingCompany?.lnkBroadcastAreas?.filter(l => l.out == data.BroadcastArea &&
                    l.DefaultCurrency);
                if (links.length) {
                    const link = links.find(l => l.Default) ?? links[0];
                    const opt = currencyOptions?.find(o => o["@rid"] == link.DefaultCurrency);
                    if (opt)
                        return setValue(opt);
                }
            }
            // On affecte la devise associée à la zone de diffusion de la data
            if (broadcastAreaWithCurrency) {
                const currencies = currencyOptions?.filter(e => e.link == broadcastAreaWithCurrency);
                if (currencies.length == 1)
                    return setValue(currencies[0]);

                const defaultCurrency = currencies.find(e => e.Default);
                if (defaultCurrency)
                    return setValue(defaultCurrency);
            }

            const defaultVal = currencyOptions?.find(v => {
                if (data.Currency)
                    return v["@rid"] == data.Currency;
                if (v.Default)
                    return Boolean(setValue(v));
                return false;
            });

            setValue(defaultVal);
        }

        await setCurrency();
    }

    return currencyOptions && distinct(currencyOptions, (e) => e['@rid']);
}

export async function UpdateBroadcastState(supportEditor: IEditorState<ref_Messages | ref_Campaigns, ref_MessagesSerializable | ref_CampaignsSerializable>, data: ref_MessagesSerializable | ref_Messages | ref_Campaigns | ref_CampaignsSerializable) {
    let broadcastOptions: ref_BroadcastAreas[] = undefined;
    const { Support } = supportEditor.data ?? {};
    if (data.Support && (supportEditor.broadcastOptions == undefined || Support !== data.Support)) {
        ConsoleDebug('UpdateBroadcastState');

        const dispatchChanged = (broadcastArea: IRid) => {
            if (broadcastArea) {
                data.BroadcastArea = broadcastArea?.["@rid"];
            }
        }

        const lnkAdv = await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, { "out": data.Support })
        const adv = distinct(lnkAdv.map(l => l.in), e => e);
        const broadcastAreas = await Client.searchVertexTyped(lnk_HasBroadcastArea, { "in": [data.Support, ...adv] });

        broadcastOptions = await Client.searchVertexTyped(ref_BroadcastAreas, {
            Active: true,
            "@rid": distinct(broadcastAreas.map(l => l.out), e => e),
            properties: ["@rid", "Name"]
        })

        const setDefault = async () => {
            if (broadcastOptions.length == 1) {
                return dispatchChanged(broadcastOptions[0]);
            }

            const selectedElement = broadcastOptions?.find(o => o["@rid"] == data.BroadcastArea);
            if (selectedElement)
                return dispatchChanged(selectedElement);

            // recherche les régies associées au support
            let advDefault = distinct(lnkAdv.map(lnk => lnk.in), e => e);
            // si une seule régie alors elle est par défaut. Sinon recherche les régies dont le lien est par défaut
            if (advDefault.length > 1)
                advDefault = distinct(lnkAdv.filter(lnk => lnk.Default).map(lnk => lnk.in), e => e);

            if (advDefault.length) {
                // recherche de la zone de diff par défaut entre dans le lient support <=> régie
                const found = lnkAdv
                    .filter(lnk => advDefault.includes(lnk.in) && lnk.DefaultBroadcastArea && (advDefault.length == 1 || lnk.Default))
                    .map(lnk => lnk.DefaultBroadcastArea);

                if (found.length)
                    return dispatchChanged(broadcastOptions.find(o => found.includes(o["@rid"])))

                // recherche de la zone de diff par défaut de la première régie trouvée
                const broadcastAdvDefault = broadcastAreas.find(lnkB => advDefault.includes(lnkB.in) && lnkB.Default)?.out;
                if (broadcastAdvDefault)
                    return dispatchChanged(broadcastOptions.find(o => o["@rid"] == broadcastAdvDefault));
            }

            // recherche des zones de diff par défaut du support
            const defaultElement = broadcastOptions.find(o => broadcastAreas.some(lnk => lnk.out == o["@rid"] && lnk.Default));
            dispatchChanged(defaultElement);
        }

        await setDefault();
    }
    return broadcastOptions;
}

export async function SetGroup(data: ref_Messages | ref_Campaigns, ids: string, category: string, propertyName: string, other: string, setValue: boolean): Promise<ref_Groups[]> {
    const property = data.ModelProperties[propertyName];
    const propertyOther = data.ModelProperties[other];

    const options = await Client.searchVertexTyped(ref_Groups, {
        Active: true,
        Category: category,
        IDs: ids ?? [],
        _strictParams: { Area: { "$elemMatch": { Class: ref_Advertisers.name, IDs: { $in: [data.Advertiser, null] } } } }
    });
    options.push({ Name: Trad("other"), "@rid": otherRid } as any);

    if (setValue)
        if ((!property && !propertyOther) || (property && !options.some(c => c["@rid"] == property))) {
            const _group = options.find(v => v.Default) ?? options.find(v => v["@rid"] != otherRid);
            data.ModelProperties[propertyName] = _group?.["@rid"] ?? null;
            if (data.ModelProperties[propertyName] && data.ModelProperties[propertyName] !== otherRid)
                data.ModelProperties[other] = null;
            else
                data.ModelProperties[other] = "";
        }
    return options.sort(NameSort("last"));
};


export async function SetPerformancesKPIFromOJD(newData: IMediaModel, oldData: IMediaModel) {
    const medias = await KPIsManagerCache.GetMedias();
    const mediaPresse = medias?.find(m => m.Code == "PR")?.['@rid'];
    // Init/Update OJD
    if (mediaPresse && newData.Media == mediaPresse
        && (!hasOwnProperty(newData.ModelProperties, "OjdYear") || oldData.Support != newData.Support || oldData.BroadcastArea != newData.BroadcastArea)
        && newData.Support
        && newData.BroadcastArea) {
        ConsoleDebug('Init/Update OJD');
        let waves: string[];
        try {
            waves = await DataProvider.getOJDWaves({ support: newData.Support, broadcastArea: newData.BroadcastArea });
        } catch (error) {
            console.error(error);
            Notify(Trad('cannot_retrieve_ojd_data'), 'warning')
            waves = [];
        }

        newData.ModelProperties["OjdYear"] = waves?.[0] ?? null;
        const ojd = waves?.length && (await DataProvider.getOJD({ support: newData.Support, broadcastArea: newData.BroadcastArea, year: newData.ModelProperties?.["OjdYear"] }))?.[0]
        newData.KPIs["Release"] = ojd?.MiseEnDistribution ?? 0;
        newData.KPIs["PaidCirculation"] = ojd?.DiffusionPayeeFrance ?? 0;
        newData.KPIs["TotalCirculation"] = ojd?.DiffusionTotaleFrance ?? 0;
        newData.KPIs["Subscriptions"] = ojd?.Abonnements ?? 0;

        if (!newData.ModelProperties["OjdYear"]) {
            const circulation = (<ref_Circulations[]>await DataProvider.search(ref_Circulations.name, { Support: newData.Support, BroadcastArea: newData.BroadcastArea, Active: true }) ?? [])[0];
            if (circulation) {
                newData.KPIs["Release"] = circulation.KPIs.Release ?? 0;
                newData.KPIs["PaidCirculation"] = circulation.KPIs.PaidCirculation ?? 0;
                newData.KPIs["TotalCirculation"] = circulation.KPIs.TotalCirculation ?? 0;
                newData.KPIs["Subscriptions"] = circulation.KPIs.Subscriptions ?? 0;
            }
            console.log("circulation", circulation);
        }

        if (newData.ModelProperties["OjdYear"] != oldData.ModelProperties?.["OjdYear"]
            || (!compareObjects(extractSub((oldData.KPIs ?? {}) as any, ["Release", "PaidCirculation", "TotalCirculation", "Subscriptions"], 0),
                extractSub(newData.KPIs as any, ["Release", "PaidCirculation", "TotalCirculation", "Subscriptions"], 0))))
            Notify(Trad("update_ojd"), "info");
    }
}

export function OnSupportChange(model: "ref_Campaigns" | "ref_Messages", oldData: IMediaModel & IMediaCompanyModel, newData: ref_Messages | ref_Campaigns) {

    newData.BroadcastArea = null;
    newData.AdvCompany_Com = null;
    newData.AdvCompany_Fin = null;
    newData.Currency = null;
    delete newData.ModelProperties["OjdYear"];
    delete newData.ModelProperties["Dated"];
    delete newData.ModelProperties["Reference"];
    delete newData.ModelProperties["EditorialTheme"];

    if (newData.ModelProperties["Periodicity"] != oldData.ModelProperties["Periodicity"]) {
        console.log("changeEndValue", newData.ModelProperties["Periodicity"]);
        changeEndValue(model, newData);
    }
}
