import { SchedulerChangeTrigger } from './SchedulerChangeTrigger';
import { SchedulerModeleCreator } from './SchedulerModeleCreator/SchedulerModeleCreator';
import { SchedulerModelePicker } from './SchedulerModelePicker';

export type SchedulerProps<T> = {
    objectType: new () => T;
}

export function SchedulerConfigDialog<T>({ objectType }: SchedulerProps<T>) {
    return <>
        <SchedulerChangeTrigger objectType={objectType} />

        {/** modele */}
        <SchedulerModelePicker objectType={objectType} />

        {/** label lines + advanced styles */}
        <SchedulerModeleCreator objectType={objectType} />
    </>
}

