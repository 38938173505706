import React from "react";
import { Trad } from "trad-lib";
import { DiscountGridRow } from "./Rows/DiscountGridRow";
import { RowSize } from "../../../../styles/theme";

const HeaderElement = ({ children }) =>
    <div style={{ height: '100%', alignItems: 'center', display: 'flex' }}><span style={{  }}>{children}</span></div>

export function TitleComponent({ }) {
    return <DiscountGridRow
        intervalCellClassName=""
        amountCellClassName=""
        rowStyle={{height: RowSize}}
        className="gray-back discountGridRowTitle"
        labelCell={<HeaderElement>{Trad('Identification')}</HeaderElement>}
        intervalCell={<HeaderElement>{Trad('Base')}</HeaderElement>}
        rateCell={<HeaderElement>{Trad('rate')}</HeaderElement>}
        amountCell={<HeaderElement>{Trad('amount')}</HeaderElement>}
    />
}