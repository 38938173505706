
import * as React from "react";
import { Button, Paper, Popper, Grow, ClickAwayListener, MenuList, MenuItem, IconButton } from "@material-ui/core";
import { getIcon } from "adwone-lib/index";
import { GenericTooltip } from "./GenericTooltip.bin";
import { TooltipManager } from "../CustomTooltip";
export class CustomGroupButtonProps {
    Label: string | JSX.Element;
    title?: string;
    style?: React.CSSProperties;
    containerClassname?: string;
    disabled?: boolean;
    childColor?: string;
    hasTooltip?: string;
    isIcon?: boolean;
    buttonClassName?: string;
    disableMoreIcon?: boolean;
    startIcon?: JSX.Element;
    onClick?: () => void;
    buttons: {
        onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, button: HTMLElement) => any,
        Label: string | JSX.Element,
        disabled?: boolean
    }[];
}

let idGeneric = 0;

export class CustomGroupButton extends React.Component<CustomGroupButtonProps, any> {

    constructor(props: CustomGroupButtonProps) {
        super(props);
        this.state = {
            open: false,
            idGeneric: `idgeneric-${idGeneric++}`
        };
    }

    handleToggle = () => {
        const { onClick } = this.props;
        onClick?.();
        if (this.props.buttons?.length)
            this.setState({
                open: !this.state.open
            })
    }

    handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        this.setState({
            open: false
        })
    }

    render() {
        const { isIcon, title } = this.props;

        const component = <div onMouseOver={e => title && TooltipManager.Push({ target: e.target, text: title })}>
            {this.props.isIcon ? (<IconButton
                id={this.state.idGeneric}
                className={this.props.buttonClassName ?? "custom_btn_secondary"}
                disabled={this.props.disabled}
                onClick={this.handleToggle} >
                {this.props.startIcon}
            </IconButton>) :
                (<Button
                    id={this.state.idGeneric}
                    className={this.props.buttonClassName ?? "custom_btn_secondary"}
                    endIcon={!this.props.disableMoreIcon ? getIcon("more") : undefined}
                    disabled={this.props.disabled}
                    startIcon={this.props.startIcon}
                    aria-controls={this.state.open ? 'split-button-menu' : undefined}
                    aria-expanded={this.state.open ? 'true' : undefined}
                    aria-haspopup="menu"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.handleToggle();
                    }}>
                    {this.props.Label}
                </Button>)}
        </div>

        return (
            <>
                {this.props.hasTooltip && <GenericTooltip tooltipContent={<>{this.props.hasTooltip}</>}>
                    {component}
                </GenericTooltip>}

                {!this.props.hasTooltip && component}

                {<Popper
                    placement={this.props.isIcon ? "left-start" : "bottom"}
                    open={this.state.open}
                    anchorEl={() => document.getElementById(this.state.idGeneric)}
                    role={undefined}
                    style={{ zIndex: 7500 }}
                    transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                {isIcon && title && <div className="title-CustomGroupButton"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>{title}</div>}
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList id="split-button-menu">
                                        {
                                            this.props.buttons?.map((b, i) =>
                                                <MenuItem key={`button-group-item-${i}`} style={{ color: this.props.childColor }} onClick={(event) => { b.onClick(event, document.getElementById(this.state.idGeneric)); this.handleToggle() }} disabled={b.disabled}>
                                                    {b.Label}
                                                </MenuItem>)
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>}
            </>);
    }
}