import { AModeleCreatorBase, ModeleCreatorBaseProps } from "./ModeleCreatorBase";
import { FormControlLabel, Grid } from "@material-ui/core";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_KPIs } from "hub-lib/models/orientdb/ref_KPIs.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { Trad, TradProp } from "trad-lib";
import { VertexAutocomplete } from "adwone-lib";
import { Element, ElementContainer } from "../Generic/Common.bin";
import { ref_DashboardConfigurations } from "hub-lib/dto/client/ref_DashboardConfigurations.bin";
import { clone, GetHashCode } from "hub-lib/tools.bin";
import { IndicateurKPI } from "adwone-engine/index.bin";
import { Checkbox } from "@material-ui/core";

export class TProps extends ModeleCreatorBaseProps<ref_DashboardConfigurations> {
    indicateurs: IndicateurKPI[];
}

export class ModeleDashboard extends AModeleCreatorBase<TProps, ref_DashboardConfigurations> {
    constructor(props: TProps) {
        super(props);
    }

    afterRender() {
        const { modele, onChange, indicateurs } = this.props;
        return (
            <Element>
                <Grid container className="block-container">
                    <Grid xs={6} item className="message_details_leftcombo">
                        <VertexAutocomplete
                            key={GetHashCode(indicateurs)}
                            options={indicateurs}
                            getOptionLabel={(option: IndicateurKPI) => {
                                return option.name;
                            }}
                            label={Trad("budget")}
                            disableClearable
                            defaultValue={(options: IndicateurKPI[]) =>
                                options.find((e) => e.options?.["rid"] === modele?.indicateur.options?.["rid"])
                            }
                            onChange={(value: IndicateurKPI) => {
                                onChange({
                                    ...modele,
                                    indicateur: value
                                });
                            }}
                        />
                    </Grid>
                    <Grid xs={6} item className="message_details_rightcombo">
                        <VertexAutocomplete
                            type={ref_Currencies.name}
                            onResetValue={(options: any) => undefined as any}
                            params={{
                                properties: ["@rid", "Name", "Code"],
                            }}
                            defaultValue={(options: ref_Currencies[]) =>
                                options.find((e) => e["@rid"] === modele?.currency?.["@rid"])
                            }
                            getOptionLabel={(option: ref_Currencies) => `${option.Name} (${option.Code})`}
                            label={TradProp("Currency", ref_Messages)}
                            onChange={(value: ref_Currencies) => {
                                onChange({
                                    ...modele,
                                    currency: value,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <ElementContainer>
                    <Grid container className="block-container">
                        <Grid xs={12} item className="message_details_leftcombo">
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={modele.kilo}
                                        onChange={() => {
                                            onChange({
                                                ...modele,
                                                kilo: !modele.kilo,
                                            });
                                        }}
                                        name="kilo"
                                        color="primary"
                                    />
                                }
                                label={Trad("kilo")}
                            />
                        </Grid>
                        {/* <Grid xs={12} item className="message_details_leftcombo">
                            <FormControlLabel
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={modele.compareWithPreviewYear}
                                        onChange={() => {
                                            onChange({
                                                ...modele,
                                                compareWithPreviewYear: !modele.compareWithPreviewYear,
                                            });
                                        }}
                                        name="compareWithPreviewYear"
                                        color="primary"
                                    />
                                }
                                label={Trad("compareWithPreviewYear")}
                            />
                        </Grid> */}
                    </Grid>
                </ElementContainer>
            </Element>
        );
    }
}
