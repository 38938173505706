
import * as React from "react";
import { eKPIType, IsNumber } from "hub-lib/models/KPIsManager.bin";
import { GetKPITemplate } from "format-lib/index.bin";


export function GetNumberFormatCustom(type: eKPIType) {

    switch (type) {
        case eKPIType.Decimal:
            return NumberFormatCustomDecimal;
        case eKPIType.Price:
            return NumberFormatCustomDecimal;
        case eKPIType.PriceReturned:
            return NumberFormatCustomDecimal;
        case eKPIType.PriceBound:
            return NumberFormatCustomDecimal;
        case eKPIType.Number:
            return NumberFormatCustomNumber;
        case eKPIType.Percent:
            return NumberFormatCustomPercent;
        default:
            return NumberFormatCustomString;
    }
}


export abstract class NumberFormatCustom extends React.Component<any, any> {

    abstract typeData: eKPIType;

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    getNumericTemplate = (type: eKPIType) => {
        return (val: any) => {

            if (!IsNumber(type))
                return val;

            let number = parseFloat(val);
            if (isNaN(number))
                return "";
            return GetKPITemplate(type)(number);
        }
    }

    adapt = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`NumberFormatCustom default implementation`)
    }

    render() {

        let { focused } = this.state;

        let template = this.getNumericTemplate(this.typeData);

        let value = focused ? this.props.value : template(this.props.value);

        let typeInput = "text";
        if (focused && (this.typeData === eKPIType.Decimal || this.typeData === eKPIType.Percent))
            typeInput = "number";

        //TODO changer en Input material
        return (
            <input
                {...this.props}
                className={this.props.className + ` ` + ` input-${typeInput} ` + ` input-${this.typeData} `}
                onFocus={(...args) => {
                    this.setState({ focused: true });
                    this.props?.onFocus(args)
                }}
                onBlur={(...args) => {
                    this.setState({ focused: false });
                    this.props?.onBlur(args)
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.adapt(e);
                    this.props.onChange(e);
                }}
                type={typeInput}
                value={value}
            />
        );
    }
}

const adaptBase = (e: React.ChangeEvent<HTMLInputElement>, ratio?: number) => {
    let addZeroVirg = false;
    let addZeroDot = false;
    let base = e.target.value.split(",");
    if (base.length > 1 && base[1].length === 1 && base[1][0] === "0") addZeroVirg = true;
    base = e.target.value.split(".");
    if (base.length > 1 && base[1].length === 1 && base[1][0] === "0") addZeroDot = true;

    let num: number = parseFloat(e.target.value);
    if (!isNaN(num)) {
        num = Math.trunc(num * 100) / 100;
        if (ratio) num *= ratio;
        if (addZeroVirg) e.target.value += ",0";
        if (addZeroDot) e.target.value += ".0";
    }
}

export class NumberFormatCustomDecimal extends NumberFormatCustom {
    typeData: eKPIType = eKPIType.Decimal;

    adapt = (e: React.ChangeEvent<HTMLInputElement>) => adaptBase(e);
}

export class NumberFormatCustomNumber extends NumberFormatCustom {
    typeData: eKPIType = eKPIType.Number;

    adapt = (e: React.ChangeEvent<HTMLInputElement>) => {
        let num: number = parseFloat(e.target.value);
        if (!isNaN(num)) {
            e.target.value = Math.trunc(num).toString();
        }
    }
}

export class NumberFormatCustomString extends NumberFormatCustom {
    typeData: eKPIType = eKPIType.String;
}

export class NumberFormatCustomPercent extends NumberFormatCustom {
    typeData: eKPIType = eKPIType.Percent;

    adapt = (e: React.ChangeEvent<HTMLInputElement>) => {
        return adaptBase(e, 100);
     }
}