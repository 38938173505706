import React from "react";
import moment from "moment";
import { FormatPrice, Trad } from "trad-lib";
import { getIcon } from "../ConfigurableComponents/IconManager.bin";
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from "../../redux/store";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { useEffect, useState } from "react";
import { BudgetCompanyChart, MixMediasChart, MonthlyBudgetChart, TopSupportsChart } from "./Charts";
import { clearChartConfig, setDashboardBudget } from "../../redux/projectSlice";
import { IconButton } from "@material-ui/core";
import { BasicAggregateManager } from "hub-lib/business/BasicAggregateManager.bin";
import { Row } from "adwone-engine/types.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { CustomPDFExport } from "../VertexGrid/Generic/CustomPDFExport";
import { FormatNumber, GetCellTemplate } from "format-lib/index.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";

type DashboardProps<T> = {
    objectType: new () => T
}
export function Dashboard<T>({ objectType } : DashboardProps<T>) {
    return (
        <CustomPDFExport
            fileName={`dashboard_messages`}
            author="Adwone"
            title="Dashboard"
        >
            <div className="dashboard-container">
                <Header objectType={objectType} />
                <MixMediasChart objectType={ref_Messages} />
                <TopSupportsChart objectType={ref_Messages}/>
                <MonthlyBudgetChart objectType={ref_Messages}/>
                <BudgetCompanyChart objectType={ref_Messages}/>
            </div>
        </CustomPDFExport>
    );
};

function Header<T>({ objectType }: DashboardProps<T>) {
    const [budget, setBudget] = useState(0);
    const [count, setCount] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();

    const dashboardModele = useSelector((root: RootState) => root.project.dashboardModele);
    const filtersModele = useSelector((root: RootState) => root.project.filtersModele);
    const tabFilters = useSelector((root: RootState) => root.project.filters);
    const filters = { ...tabFilters, ...dashboardModele?.filters };

    const getData = async () => {
        const mgr = new BasicAggregateManager<T>();
        mgr.customKPI = (a: Row<T>) => a?.CountData;
        mgr.dimension = [];
        mgr.Indicateur = dashboardModele?.indicateur;
        mgr.KPIType = eKPIType.Price
        mgr.filters = { ...filters, Currency: dashboardModele?.currency?.["@rid"] };
        const res = await mgr.getAggregate(objectType);
        let { table } = res.data;
        let totalRow = table.Rows[0];

        setCount(totalRow.CountData);

        if (totalRow.ValuesTotal[0].Value === "-") {
            return setBudget(null);
        }

        let budget = totalRow.CountData !== 0 ? totalRow.ValuesTotal[0].Value : 0;
        dispatch(setDashboardBudget(budget));
        // if (dashboardModele.kilo)
        //     budget = budget / 1000;
        // let budgetString = FormatNumber(budget);
        // if (dashboardModele.kilo)
        //     budgetString = `${budgetString} k`;
        setBudget(budget);
    };

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            getData();
        }
    });

    useEffect(() => {
        getData();
    }, [filters]);

    return <div className="dashboard-header dashboard-block">
        <HeaderBlock
            icon="filterAlt"
            title={Trad("name")}
            subtitle={filtersModele?.Name}
        />
        <HeaderBlock
            icon="dateRange"
            title={Trad("period")}
            subtitle={<DateElem Start={tabFilters?.Start} End={tabFilters?.End} />}
        />
        <HeaderBlock
            icon="messages"
            title={Trad(`${objectType.name}_number`)}
            subtitle={`${FormatNumber(count)}`}
        />
        <HeaderBlock
            icon="wallet"
            title={`${Trad("total_budget")}${dashboardModele?.currency?.Code}`}
            subtitle={budget == null ? '-' : FormatPrice(budget, dashboardModele?.currency?.Name, { useThousands: dashboardModele?.kilo })}
            additionalInfo={`${Trad("budget")} ${dashboardModele?.indicateur.name}`}
        />
        <HeaderBlock
            icon="reload"
            title=""
            subtitle=""
            className="hide-pdf"
            onClick={() => dispatch(clearChartConfig())}
            disabled={!dashboardModele?.filters || (dashboardModele?.filters && Object.keys(dashboardModele?.filters).length === 0)}
        />
    </div>
};

const HeaderBlock = ({ icon, title, subtitle, onClick, disabled, className, additionalInfo }: {
    icon: string;
    title: string;
    subtitle: string | JSX.Element;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    additionalInfo?: string;
}) => (
    <div className={`dashboard-header-block ${className ?? ""}`} onClick={onClick}>
        <div className={`flex reverse center ${(title || subtitle) && "padding-right"}`}>
            {onClick && <IconButton onClick={onClick} disabled={disabled}>
                {getIcon(icon, {}, `adw-icon ${disabled ? "disabled" : ""} lg`)}
            </IconButton>}
            {!onClick && getIcon(icon, {}, "adw-icon lg", true)}
        </div>
        {(title || subtitle) && <div className="flex center padding-left">
            <div className="flex column width position-relative">
                <div className="dashboard-header-block-title">{title}</div>
                <div className="dashboard-header-block-value">{subtitle}</div>
                {additionalInfo && <div className="dashboard-header-block-additional">{additionalInfo}</div>}
            </div>
        </div>}
    </div>
);

const DateElem = (props: { Start: Date, End: Date }): any => {

    const templateDate = GetCellTemplate(ePropType.Date);

    return <>
        <span className="prefix-text">{Trad("from")} </span>
        {/* {`${moment(props?.Start).format("DD/MM/YYYY")}`} */}
        {props && templateDate(props.Start)}
        <span className="prefix-text"> {Trad("to")?.toLowerCase?.()} </span>
        {/* {`${moment(props?.End).format("DD/MM/YYYY")}`} */}
        {props && templateDate(props.End)}
    </>
}
