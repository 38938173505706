import * as React from 'react'

import Template from '../Template'
import { Trad, TradClassName, TradProp } from 'trad-lib';
import { RightManager, eFunctions, eRights } from 'hub-lib/models/types/rights.bin';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from "@progress/kendo-react-layout";
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ReferentialTreeList } from '../../VertexGrid/Adwone-admin/Referential/ReferentialTreeList';
import { ReferentialGrid } from '../../VertexGrid/Adwone-admin/Referential/ReferentialGrid.bin';
import { AdvertiserExtended } from 'hub-lib/dto/referential/AdvertiserExtended.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { SupportExtended } from 'hub-lib/dto/referential/SupportExtended.bin';

class TState {
    selected: number = 0
}

class ClntReferential extends React.Component<any, TState> {
    constructor(props: any) {
        super(props)
        let newstate = new TState()
        this.state = newstate
    }
    handleSelect = (e: TabStripSelectEventArguments) => {
        this.setState({ selected: e.selected });
    };

    render() {
        const treeListMode = RightManager.hasRight(eFunctions.ref_AdvertiserGroups, [eRights.read]);
        return (
            <>
                <div>
                    <TabStrip className="tabpanel_fullwidth tabpanel_rightmenu" selected={this.state.selected} onSelect={this.handleSelect}>

                        {RightManager.hasRight(eFunctions.ref_Advertisers, [eRights.read]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_Advertisers.name)}>
                                {treeListMode &&
                                    <ReferentialTreeList
                                        hierarchyClassName={[ref_AdvertiserGroups.name, ref_Advertisers.name]}
                                        extendedProperties={["ClntData"]} />}
                                {!treeListMode &&
                                    <ReferentialGrid
                                        vertex={ref_Advertisers}
                                        vertexName={ref_Advertisers.name}
                                        title={TradClassName(ref_Advertisers.name)}
                                        extendedProperties={["ClntData"]} />
                                }
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_Supports, [eRights.read]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_Supports.name)}>
                                <ReferentialGrid
                                    vertex={SupportExtended}
                                    vertexName={ref_Supports.name}
                                    title={TradClassName(ref_Supports.name)}
                                    extendedProperties={["ClntData"]} />
                            </TabStripTab>
                        }
                    </TabStrip>
                </div>
            </>
        );
    }
}

export default Template(ClntReferential);