import { ref_Advertisers, ref_AdvertisersId } from "./ref_Advertisers.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Advertisers, mm_AdvertisersId } from "./mm_Advertisers.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasAdvertiserId = rid;

export class vw_mm_HasAdvertiser extends ReferentialHasViews<src_MMId, mm_AdvertisersId, ref_AdvertisersId> {
	"@rid"?: vw_mm_HasAdvertiserId;

}