
import { Checkbox } from "@progress/kendo-react-inputs";
import * as React from "react";
import { ADWGrid, eSelectionEvent } from "adwone-lib/index";
import { GridCellProps } from "@progress/kendo-react-grid";

export class TProps {
    props:
        GridCellProps &
        {
            grid: ADWGrid<any>,
            disabled?: boolean
        };
}

export class CustomCheckbox extends React.Component<TProps, any> {

    constructor(props: TProps) {
        super(props);
    }

    render() {
        let { props } = this.props;
        return (<Checkbox
            value={props.dataItem.selected}
            checked={props.dataItem.selected}
            disabled={props.disabled}
            onChange={e => {
                props.selectionChange(props as any);
                this.forceUpdate();
                props.grid.onSelectionChanged.emit(eSelectionEvent.selectionChanged);
            }}
        />)
    }
}