

import * as React from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { VertexGrid } from "../VertexGrid.bin";
import { DataGridMassActions, dialogAction } from "./DataGridMassActions.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { Trad, TradClassName } from "trad-lib";
import { Client } from "hub-lib/client/client.bin";
import { Notify } from "../../../../utils/Notify.bin";
import { selectItems } from "../../../../redux/gridSlice";
import { useDispatch } from 'react-redux'
import { SetSubElement, duplicate, GetHashCode } from "hub-lib/tools.bin";
import { BreadcrumbsCustomContainer, SelectedItemsContainer, ToolbarContainer } from "../ToolbarAdw";
import { BreadcrumbsCustom } from "../../../BreadcrumbsCustom";
import { FilterStorage } from "../../../../utils/localstorage.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { UpdateSet } from "hub-lib/dto/UpdateSet";

type TProps<T> = {
    grid: VertexGrid<T>,
    onChange?: (rids: string[]) => void;
    onRemove?: (items: T[]) => void;
}

export function ToolbarSelectedItems<T>({ grid, onChange, onRemove }: TProps<T>) {
    const [campaignName, setCampaignName] = React.useState('')
    const selectedItems = duplicate(useSelector((state: RootState) => state.grid?.selectedItems) ?? []);

    const filters = FilterStorage.getAdvancedFilters();
    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (!filters || grid.objectPrototype.name != ref_Messages.name) return;
            if (filters.Campaign && filters.Campaign.length === 1)
                setCampaignName((await Client.searchVertex(ref_Campaigns.name, { "@rid": filters.Campaign, properties: ["@rid", "Name"], Source: "ADWONE" }))?.data?.results?.[0]?.Name);
            else
                setCampaignName('')
        });
    }, [GetHashCode(filters?.Campaign)])
    /*
        const onChangeStatus = async (status: eStatusType) => {
            const elements = selectedItems.filter(e => e.dataItem);
            elements.forEach(e => e.dataItem.Status = status);
            selectedItems.forEach(e => e.Status = Trad(status))
            dispatch(selectItems(selectedItems));
            const messages = elements.map(e => ({ "@rid": e.dataItem["@rid"], Status: status }));
            try {
                await Client.updateVertex(grid.objectPrototype.name, messages, false);
                Notify(Trad("status_modification_success"), "success");
            } catch (error) {
                Notify(Trad("status_modification_failed"), "error");
            }

            onChange?.(messages.map(m => m["@rid"]));
        }
    */

    const onSubmitAction = async (action: dialogAction, updateSet: UpdateSet) => {

        switch (action) {
            case "delete":
                await grid.delete(selectedItems as any);
                grid.UpdateRows();
                store.dispatch(selectItems([]));
                onRemove?.([]);
                break;
            default:
                let rids = selectedItems.map(e => {
                    if (e.dataItem?.["@rid"])
                        return e.dataItem["@rid"];
                    if (e.Data?.length == 1)
                        return e.Data[0]?.["@rid"];
                    return null;
                }).filter(Boolean);

                if (updateSet.PropertySet?.choices)
                    rids = updateSet.PropertySet.choices.find(c => c.key == updateSet.PropertySet.value).rids;
                try {
                    const params = rids.map(e => ({ "@rid": e, UpdateSet: updateSet }));
                    await Client.updateVertex(grid.objectPrototype.name, params, false);
                    Notify(Trad(`${action}_modification_success`), "success");
                    onChange?.(rids);
                }
                catch (error) {
                    Notify(Trad(`${action}_modification_failed`), "error");
                }
                store.dispatch(selectItems([]));
                break;
        }
    }

    const elements = [{ text: Trad('home'), href: '/' }, { text: TradClassName(grid.objectPrototype.name) }]
    if (campaignName) {
        elements.push({ text: campaignName })
    }
    return <ToolbarContainer>
        <SelectedItemsContainer>
            <div style={{ display: "flex", alignItems: "center" }}>
                {selectedItems?.length > 0 &&
                    <DataGridMassActions
                        selectedItems={selectedItems.filter(s => s.dataItem?.["@rid"] || !s.Children?.length)}
                        objectType={grid.objectPrototype}
                        onSubmitAction={onSubmitAction} />
                }
            </div>
        </SelectedItemsContainer>
        <BreadcrumbsCustomContainer>
            <BreadcrumbsCustom
                hasSelectedItems={selectedItems?.length > 0}
                elements={elements}
            />
        </BreadcrumbsCustomContainer>
    </ToolbarContainer>
}

