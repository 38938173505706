import * as React from "react";
import { vw_mm_HasAdvertiser } from "hub-lib/models/orientdb/vw_mm_HasAdvertiser.bin";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { ClickModalityHandler, divModalityUnlinkHandler, getData, getDimension, getLinkFromName, getNbLinks, getSource, getTotalNbLinks, getView, iconLinked, isLinked, MappingProperties, UpdateFilteredModalityViews, viewClassname } from "./LinkManagerTools";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { TableExport } from "hub-lib/models/external.bin";
import { Client } from "hub-lib/client/client.bin";
import { Trad, TradClassName } from "trad-lib";
import { select, setState, setViewFilters } from "../../../../redux/linkMgrSlice";
import { src_AdwOne } from "hub-lib/models/orientdb/src_AdwOne.bin";
import { useEffect, useState } from "react";
import { TooltipManager } from "../../../CustomTooltip";
import Loader from "../../Loader";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { RowHeader } from "./LinkManager.bin";
import { ref_Sources } from "hub-lib/models/orientdb/ref_Sources.bin";
import { ref_Customers } from "hub-lib/models/orientdb/ref_Customers.bin";
import { ConsoleDebug, IsDebugMode } from "../../../../utils/localstorage.bin";
import { eCompare, Operator } from "hub-lib/operators.bin";
import { metaEdge } from "hub-lib/models/types.bin";
import { CustomTreeList, TooltipCell } from "../../../VertexGrid/Generic/CustomTreeList";
import { TreeListCellProps, TreeListColumnProps, TreeListDateFilter, TreeListRowProps, TreeListTextFilter } from "@progress/kendo-react-treelist";
import { src_MM } from "hub-lib/models/orientdb/src_MM.bin";
import { src_TSM } from "hub-lib/models/orientdb/src_TSM.bin";
import { mm_Brands } from "hub-lib/models/orientdb/mm_Brands.bin";
import { mm_Products } from "hub-lib/models/orientdb/mm_Products.bin";
import { mm_Supports } from "hub-lib/models/orientdb/mm_Supports.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { vw_mm_HasAdvertiserGroup } from "hub-lib/models/orientdb/vw_mm_HasAdvertiserGroup.bin";
import { vw_mm_HasBrand } from "hub-lib/models/orientdb/vw_mm_HasBrand.bin";
import { vw_mm_HasProduct } from "hub-lib/models/orientdb/vw_mm_HasProduct.bin";
import { Views } from "hub-lib/models/orientdb/Views.bin";
import { CustomGroupButton } from "../../../ConfigurableComponents/CustomGroupButton.bin";
import { IndicateurInfo } from "adwone-engine/index.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { vw_mm_HasSupport } from "hub-lib/models/orientdb/vw_mm_HasSupport.bin";
import { vw_mm_HasAdvertisingCompanies } from "hub-lib/models/orientdb/vw_mm_HasAdvertisingCompanies.bin";
import { GetSubElement, clone } from "hub-lib/tools.bin";
import { mm_Placements } from "hub-lib/models/orientdb/mm_Placements.bin";
import { mm_Formats } from "hub-lib/models/orientdb/mm_Formats.bin";

export function ViewList() {
    return <>
        <div className="header-list">
            <ViewListHeaders />
        </div>

        <ViewListListBox />
    </>
}

function ViewListHeaders() {
    return <>
        {/* Sélection de la base mongo */}
        <RowHeader>
            <div className="selecters">
                <SourceSelector />
                {/* <LinkSelector /> */}
            </div>
        </RowHeader>
        <RowHeader>
            <div className="filters">
                <LinkFilter />
            </div>
        </RowHeader>

        <div className="buttons-link ">
            <DownloadCSVButton />
        </div>
    </>
}

function LinkFilter() {

    const includeLinkedView = useSelector((root: RootState) => root.linkMgr.filtersView.includeLinkedView);
    const includeUnlinkedView = useSelector((root: RootState) => root.linkMgr.filtersView.includeUnlinkedView);
    const excludeInactiveView = useSelector((root: RootState) => root.linkMgr.filtersView.excludeInactiveView);
    const dispatch = useDispatch();

    return <div>
        <Checkbox
            label="Liés"
            value={includeLinkedView}
            onChange={(event) => {
                dispatch(setViewFilters({ includeLinkedView: event.value }));
                UpdateFilteredModalityViews();
            }} />
        <Checkbox
            className="link-checkbox-filter"
            label="Non liés"
            value={includeUnlinkedView}
            onChange={(event) => {
                dispatch(setViewFilters({ includeUnlinkedView: event.value }));
                UpdateFilteredModalityViews();
            }} />
        <Checkbox
            className="link-checkbox-filter"
            label="Actifs seulement"
            value={excludeInactiveView}
            onChange={(event) => {
                dispatch(setViewFilters({ excludeInactiveView: event.value }));
                UpdateFilteredModalityViews();
            }} />
    </div>
}

function DownloadCSVButton() {

    return <CustomGroupButton
        title={"Exporter"}
        Label={"Exporter"}
        containerClassname={"custom_btn_secondary_white"}
        buttonClassName={"custom_btn_secondary_white"}
        buttons={[{
            Label: Trad("Vues"), onClick: () => {
                const { selectedLink, filteredModalitiesViews } = store.getState().linkMgr;
                const arg: TableExport = {
                    type: "table",
                    document: getView(selectedLink).linkedClass,
                    columns: null,
                    filter: { "@rid": filteredModalitiesViews.map(v => v["@rid"]), properties: ["*"] }
                }
                Client.downloadExport("csv", arg, "export");
            }
        }, {
            Label: Trad("Liens"), onClick: () => {
                const { selectedLink, selectedSource } = store.getState().linkMgr;
                const arg: TableExport = {
                    type: "table",
                    document: selectedLink.name,
                    columns: [
                        new IndicateurInfo({ valueType: eKPIType.String, field: "@rid", name: "@rid" }),
                        new IndicateurInfo({ valueType: eKPIType.String, field: "AdwOne_ID", name: "AdwOne_ID" }),
                        new IndicateurInfo({ valueType: eKPIType.String, field: "AdwOne_LABEL", name: "AdwOne_LABEL" }),
                        new IndicateurInfo({ valueType: eKPIType.String, field: "MM_ID", name: "MM_ID" }),
                        new IndicateurInfo({ valueType: eKPIType.String, field: "MM_LABEL", name: "MM_LABEL" })
                    ],
                    filter: {
                        in: selectedSource["@rid"],
                        "Referential.Active": true,
                        properties: ["*", "Referential as AdwOne_ID", "Referential.Name as AdwOne_LABEL", "out.ExternalID as MM_ID", "out.Label as MM_LABEL"],
                        operators: [{
                            property: "Referential",
                            value: null,
                            strictValue: true,
                            compare: eCompare.IsNot
                        }]
                    }
                }
                Client.downloadExport("csv", arg, "export");
            }
        }]} />


    // <CustomButton
    //     Label="Exporter"
    //     className="custom_btn_secondary_white" onClick={() => {
    //         const { selectedLink, filteredModalitiesViews } = store.getState().linkMgr;

    //         const arg: TableExport = {
    //             type: "table",
    //             document: getView(selectedLink).linkedClass,
    //             columns: null,
    //             filter: {
    //                 "@rid": filteredModalitiesViews.map(v => v["@rid"])
    //             }
    //         }
    //         Client.downloadExport("csv", arg, "export");
    //     }} />
}

type SourceType = { customer: ref_Customers & { CompanyName: string }, source: ref_Sources };
function SourceSelector() {

    const selectedPropType = useSelector((root: RootState) => root.linkMgr.selectedProptype);
    const links = useSelector((root: RootState) => root.linkMgr.links);
    const selectedDimension = useSelector((root: RootState) => root.linkMgr.selectedDimension);
    const modalitiesViews = useSelector((root: RootState) => root.linkMgr.modalitiesViews);
    const selectedSource = useSelector((root: RootState) => root.linkMgr.selectedSource);
    const [sources, setSources] = useState<SourceType[]>(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!sources)
            Client.searchVertexTyped(ref_Sources, {}).then(async newSources => {
                const operator: Operator = { property: "Sources", value: newSources.map(s => s["@rid"]), compare: eCompare.ContainsAny }
                const customers = await Client.searchVertexTyped(ref_Customers, {
                    /*Sources: newSources.map(s => s["@rid"]),*/
                    _operators: [operator],
                    properties: ["*", "Company.Name as CompanyName"]
                });

                setSources(newSources.filter(s => s["@class"] != src_AdwOne.name).map(s => ({
                    customer: customers.find(c => c.Sources.includes(s["@rid"])) as any,
                    source: s
                })));

                if (!store.getState().linkMgr.selectedSource)
                    dispatch(select({ selectedSource: newSources[0] }));
            })
    })

    const mongoSelectionChanged = (t: ComboBoxChangeEvent) => {
        const selectedSource = sources.find((s) => s.source["@rid"] === t.value.value)?.source;

        let selectedLink: metaEdge = undefined;
        if (selectedPropType) { // ref_Property
            selectedLink = links
                .filter(l => getDimension(l)?.linkedClass === selectedDimension)
                .filter((l) => getSource(l)?.linkedClass == selectedSource?.["@class"])
                .find(l => MappingProperties[selectedPropType.Type].some(v => l.name.includes(v)))
        } else {
            selectedLink = getLinkFromName(links, selectedDimension || "", selectedSource);
        }

        ConsoleDebug(`mongoSelectionChanged`, selectedSource, links, selectedDimension);
        ConsoleDebug(`mongoSelectionChanged selectedLink`, selectedLink);

        dispatch(setState({
            selectedSource,
            selectedLink,
        }));
        getData();
    };

    const getTrad = (s: SourceType) => {

        let selectedLink: metaEdge = undefined;
        if (selectedPropType) { // ref_Property
            selectedLink = links
                .filter(l => getDimension(l)?.linkedClass === selectedDimension)
                .filter((l) => getSource(l)?.linkedClass == s.source?.["@class"])
                .find(l => MappingProperties[selectedPropType.Type].some(v => l.name.includes(v)))
        } else {
            selectedLink = getLinkFromName(links, selectedDimension || "", s.source);
        }

        const nbLinks = getNbLinks(selectedLink, s.source?.["@rid"])
        const totalNbLinks = getTotalNbLinks(selectedLink, s.source?.["@rid"])

        if (s.customer?.CompanyName) {
            let label = s.customer?.CompanyName;
            if (IsDebugMode())
                label += ` ${s.source["@class"]}`;
            return label + ` (${nbLinks}/${totalNbLinks})`;
        }
        return Trad(s.source["@class"]) + ` (${nbLinks}/${totalNbLinks})`;
    }

    const data = sources?.map(s => ({
        value: s.source["@rid"],
        trad: getTrad(s),
        group: s.customer?.Company ? "Clients" : "Autres"
    }))?.sort((a, b) => {
        const sort = a.group.localeCompare(b.group);
        if (sort === 0)
            return a.trad.localeCompare(b.trad)
        return sort;
    }) ?? [];

    return <span style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <span className="link-title-header">Source: </span><ComboBox
            clearButton={false}
            className="link-input"
            groupField="group"
            value={selectedSource ? data.find(d => d.value == selectedSource["@rid"]) : undefined}
            data={data}
            allowCustom={false}
            textField="trad"
            dataItemKey="value"
            onChange={mongoSelectionChanged} />
    </span>
}

type MyLoaderArgs = { loaderSelector: keyof RootState["linkMgr"]["loaders"] }
function MyLoader({ loaderSelector }: MyLoaderArgs) {
    const loading = useSelector((root: RootState) => root.linkMgr.loaders[loaderSelector]);
    return <div className="link-loader-container">{loading && <div className="link-loader-div"><Loader /></div>}</div>
};

const cellAdvTooltip = (_props: TreeListCellProps) => <TooltipCell
    {..._props}
    generateText={async (rid, row) => {
        const { selectedSource } = store.getState().linkMgr;
        return rid ? (await Client.searchVertex(vw_mm_HasAdvertiser.name, {
            'out.ExternalID': rid,
            properties: ["out.Label as Label"],
            in: selectedSource["@rid"]
        }))?.data?.results?.map(e => e.Label)?.join(", ") : "";
    }} />

function getAdditionnalColumnsByType() {
    const additionnalColumnsByType: { [p: string]: TreeListColumnProps[] } = {
        [mm_Brands.name]: [{
            title: Trad("advertiser"),
            field: "MD_advertiserIds",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true,
            cell: cellAdvTooltip
        }],
        [mm_Products.name]: [{
            title: Trad("advertiser"),
            field: "MD_advertiserIds",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true,
            cell: cellAdvTooltip
        }],
        [mm_Supports.name]: [{
            title: Trad("Media"),
            field: "MD_media.name",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true
        }],
        [mm_Formats.name]: [{
            title: Trad("Media"),
            field: "MD_medias.name",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true
        }],
        [mm_Placements.name]: [{
            title: Trad("Media"),
            field: "MD_medias.name",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true
        }],
    };
    return additionnalColumnsByType;
}


const hierarchyParent = {
    [vw_mm_HasAdvertiser.name]: vw_mm_HasAdvertiserGroup.name,
    [vw_mm_HasBrand.name]: vw_mm_HasAdvertiser.name,
    [vw_mm_HasProduct.name]: vw_mm_HasBrand.name,
    [vw_mm_HasSupport.name]: vw_mm_HasAdvertisingCompanies.name,
}

function ViewListListBox() {

    const filteredModalitiesViews = useSelector((root: RootState) => root.linkMgr.filteredModalitiesViews)?.map(v => ({ id: v["@rid"], children: null, ...v }));
    const topModalitiesViews = useSelector((root: RootState) => root.linkMgr.topModalitiesViews);

    return <ViewTree
        loaderSelector="gridView"
        classNameContainer="view-list-container"
        views={filteredModalitiesViews}
        rowRender={(row, props) => {
            const hiddenClass = topModalitiesViews?.some(t => t["@rid"] == props.dataItem["@rid"]) ? "clicked-item" : "";
            return <tr {...(row.props as any)}
                className={`item-clickable item-view-maingrid k-item ${row.props.className} ${viewClassname(props.dataItem)} ${hiddenClass}`}
                onClick={async (_props: MouseEvent) => {
                    await ClickModalityHandler(props, false)
                }} />;
        }} />
}

type ViewTreeArgs = {
    loaderSelector?: keyof RootState["linkMgr"]["loaders"],
    classNameContainer?: string,
    rowRender: (row: React.ReactElement<HTMLTableRowElement>, props: TreeListRowProps) => React.ReactNode,
    views: (Views & { id: string, children: [] })[]
}

export function ViewTree({ loaderSelector, classNameContainer, views, rowRender }: ViewTreeArgs) {
    const referentialColumnFilter = useSelector((root: RootState) => {
        const mediaFilter = root.linkMgr.filtersReferential.columnFilters?.find(f => f.field == "Media");
        if (!mediaFilter)
            return undefined;
        const viewFilter = clone(mediaFilter);

        const className = views?.[0]?.["@class"];
        if (className == mm_Formats.name || className == mm_Placements.name)
            viewFilter.field = "MD_medias_name_str";
        else
            viewFilter.field = "MD_media.name";
        return [viewFilter];
    });
    let prefix = null;
    switch (store.getState().linkMgr.selectedSource?.["@class"]) {
        case src_MM.name:
            prefix = "MD_";
            break;
        case src_TSM.name:
            prefix = "TSM_";
            break;
    }

    const additionalColumns: TreeListColumnProps[] = [];
    const className = views?.[0]?.["@class"];

    if (className)
        (getAdditionnalColumnsByType()[className] ?? []).forEach(c => {

            let key = c.field;

            const prop = `${key.replace(".", "_")}_str`;
            views.forEach(m => {
                const value = GetSubElement(m, key);
                if (Array.isArray(value)) {
                    m[prop] = value?.join?.(", ");
                }
                else
                    m[prop] = value ?? "";
            });

            if (key == "MD_medias.name")
                c.field = prop;
            additionalColumns.push(c);
        });
    if (className == mm_Supports.name) {
        additionalColumns.push({
            title: TradClassName(ref_AdvertisingCompanies.name),
            field: "MD_supplierIds",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true,
            cell: (_props) => <TooltipCell
                {..._props}
                generateText={async (rid, row) => {
                    const { selectedSource, selectedLink } = store.getState().linkMgr;
                    const parentClass = hierarchyParent[selectedLink.name];
                    if (!parentClass) return rid;
                    return rid ? (await Client.searchVertex(parentClass, {
                        'out.ExternalID': rid,
                        properties: ["out.Label as Label"],
                        in: selectedSource["@rid"]
                    }))?.data?.results?.map(e => e.Label)?.join(", ") : "";
                }} />
        });
    }
    if (views.some(m => m["MD_parentGroupId"])) {
        views.forEach(m => m["MD_parentGroupId_str"] = (Array.isArray(m["MD_parentGroupId"]) ? m["MD_parentGroupId"]?.join?.(", ") : m["MD_parentGroupId"]) ?? "");
        additionalColumns.push({
            title: Trad("parent"),
            field: "MD_parentGroupId",
            filter: TreeListTextFilter,
            className: "no-wrap align-right",
            locked: true,
            resizable: true,
            cell: (_props) => <TooltipCell
                {..._props}
                generateText={async (rid, row) => {
                    const { selectedSource, selectedLink } = store.getState().linkMgr;
                    const parentClass = hierarchyParent[selectedLink.name];
                    if (!parentClass) return rid;
                    return rid ? (await Client.searchVertex(parentClass, {
                        'out.ExternalID': rid,
                        properties: ["out.Label as Label"],
                        in: selectedSource["@rid"]
                    }))?.data?.results?.map(e => e.Label)?.join(", ") : "";
                }} />
        });
    }

    if (prefix) {
        const addCol = (propName: string, title: string) => {
            if (views.some(m => m[propName])) {
                views.forEach(e => {
                    if (typeof e[propName] == "string") {
                        const date = new Date(e[propName]);
                        date.setHours(0, 0, 0, 0)
                        e[propName] = date;
                    }
                });
                additionalColumns.push({
                    title,
                    field: propName,
                    filter: TreeListDateFilter,
                    className: "no-wrap",
                    // format: "{0:g}", // avec heure
                    format: "{0:d}",
                    locked: true,
                    resizable: true
                });
            }
        }
        addCol(`${prefix}createDate`, Trad("creation"));
        addCol(`${prefix}upsertDate`, Trad("modification"));
    }

    return <>
        {views && (
            <div className={`sub_list_style ${classNameContainer ?? ""}`}>
                {loaderSelector && <MyLoader loaderSelector={loaderSelector} />}

                <CustomTreeList
                    subItemsField="children"
                    data={views ?? []}
                    rowHeight={30}
                    filter={referentialColumnFilter}
                    gridProps={{
                        className: "custom-tree-list  link-mgr",
                        rowRender
                    }}
                    columns={[{
                        title: Trad("name"),
                        field: "Label",
                        filter: TreeListTextFilter,
                        className: "no-wrap",
                        width: "100%",
                        resizable: true,
                        cell: (_props) => <TooltipCell {..._props} />
                    },
                    {
                        title: "id",
                        field: "ExternalID",
                        filter: TreeListTextFilter,
                        className: "align-right",
                        locked: true,
                        resizable: true,
                        cell: (_props) => <TooltipCell {..._props} />
                    },
                    ...additionalColumns]} />
            </div>
        )}
    </>
}

type modalityListItemArg = { className?: string, onClick: (props: any, target: HTMLElement) => void }
export const modalityListItem = ({ className, onClick }: modalityListItemArg) => (props) => {
    const { dataItem, selected, ...others } = props;
    const liRef = React.createRef();
    return (
        <li {...others}
            ref={liRef}
            className={`${className} k-item ${viewClassname(props.dataItem)}`}
            onClick={async (e) => {
                onClick(props, liRef.current as HTMLElement)
            }}>
            <div style={{ width: "100%" }}
                className="modality clearfix">
                <div className="item-link"
                    onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: props.dataItem.Label })}
                    style={{ float: "left", fontStyle: props.dataItem["MD_status"] === "Inactive" ? "italic" : "inherit" }}>
                    <span>
                        {props.dataItem.Label}
                    </span>
                </div>

                <div className="view-actions-container">
                    {isLinked(props.dataItem) && <div className="unlink-button-container" style={{ float: "right" }}>
                        <Button
                            className="unlink-button">
                            <i className="material-icons"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    divModalityUnlinkHandler(props.dataItem["@rid"]);
                                }}>{iconLinked(props.dataItem)}</i></Button>
                    </div>}

                    <div style={{ float: "right" }}>{props.dataItem.ExternalID}</div>
                </div>
            </div>
        </li>
    );
};

