import React from 'react'
import Template from '../Template'

import { SpotsGrid } from '../../VertexGrid/Suppliers/SpotsGrid';

const Spots = () => {
    return (
        <>
            <div className="grid_container">
                <SpotsGrid />
            </div>
        </>

    );
}

export default Template(SpotsGrid);