import { ref_Indexables, ref_IndexablesId } from "./ref_Indexables.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ViewsId = rid;

export class Views extends V implements ref_Indexables {
	"@rid"?: ViewsId;
	ExternalID: string;
	Label: string;

}