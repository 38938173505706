import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Client } from 'hub-lib/client/client.bin';
import { eFunctions, eRights, RightManager } from 'hub-lib/models/types/rights.bin';
import * as React from 'react'
import { Trad, TradClassName, TradPropVName } from 'trad-lib'
import { IsDebugMode } from '../../utils/localstorage.bin';
import { AdwAutocomplete, VertexAutocomplete } from "adwone-lib/index";
import { CustomButton } from '../ConfigurableComponents/CustomButton.bin';
import { DateRangepicker } from '../ConfigurableComponents/DateRangepicker';
import MultipleCombo from '../crossedTable/MultipleCombo.bin';
import { SubTitle, Element, ElementContainer } from '../VertexGrid/Generic/Common.bin';
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { ExportArg, ExportArgChoices, GetNewDefaultExport, ref_ExportConfigurations, ViewModeType } from 'hub-lib/dto/client/ref_ExportConfigurations.bin';
import { HasRightToViewMode, ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { clone } from 'hub-lib/tools.bin';
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory';
import { eColumnType } from 'hub-lib/models/types.bin';
import { AggregateExport } from 'hub-lib/models/external.bin';
import { getScheduler, store } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { setExport } from '../../redux/gridConfigurationsSlice';
import { CheckBoxActivation } from './CheckBoxActivation';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';

class TProps {
    documentType: string;
    filters: Partial<Filter>;
    table: ref_TableConfigurations;
    config: ref_ExportConfigurations;
    onValidate: (arg: ExportArg) => void;
}

type TState = ref_ExportConfigurations["ExportArg"];

export function ExportsComponent(props: TProps) {

    const [state, setState] = React.useState<TState>({ ...GetNewDefaultExport(), ...props.config.ExportArg, ...new ExportArgChoices() });
    const dispatch = useDispatch();
    const isMounted = React.useRef(false);

    const saveParams = (partialState: Partial<TState>) => {
        const newState = { ...state, ...partialState };
        setState(newState);
        const currentExport = clone(store.getState().columsConfigurations.configurations[props.config.Table].export);
        currentExport.ExportArg = newState;
        dispatch(setExport(currentExport));
    }

    /** constructor */
    React.useEffect(() => {

        if (isMounted.current) return;
        isMounted.current = true;

        Promise.resolve().then(async () => {
            const state: TState = { ...GetNewDefaultExport(), ...props.config.ExportArg, ...new ExportArgChoices() };
            state.Start = props.filters.Start;
            state.End = props.filters.End;

            if (!state.dimensions)
                state.dimensions = props.table.CrossedtableConfig?.rowventils ?? [];

            let { templateName } = props.config?.ExportArg ?? {};
            const { config } = props;
            const dimensionsChoices = (await AggregatorFactory.GetInstance().Get(config.Table).Provide())
                .filter(i => i.columnType == eColumnType.Property)
                .map(i => i.indicateur);
            const templatesChoices = (await Client.searchFiles())?.data?.map(f => f.filename) ?? [];
            if (IsDebugMode())
                console.log('templatesChoices', templatesChoices);

            if (templateName && !templatesChoices.includes(templateName))
                templateName = null;

            const newState = {
                ...state,
                ...props.config.ExportArg,
                dimensionsChoices,
                templatesChoices,
                templateName,
                Start: state.Start,
                End: state?.End
            }

            saveParams(newState);
        });
    }, [isMounted.current]);


    const radioGroup = () => {
        const { ViewModeExport } = state;

        return <FormControl component="fieldset" style={{ width: "100%", left: "16px" }}>

            <RadioGroup value={ViewModeExport} onChange={(e) => saveParams({ ViewModeExport: e.target.value as ViewModeType })}>
                <FormControlLabel className="margin_top_dialog_export" value="Table" control={<Radio />} label={Trad("Table_exporttype")} />
                {HasRightToViewMode(props.config.Table, "CrossedTable") && <FormControlLabel className="margin_top_dialog_export" value="CrossedTable" control={<Radio />} label={Trad("CrossedTable_exporttype")} />}
                {/* <FormControlLabel value="SchedulerLight" control={<Radio />} label={Trad("SchedulerLight_exporttype")} /> */}
                {HasRightToViewMode(props.config.Table, "Scheduler") && <FormControlLabel className="margin_top_dialog_export" value="Scheduler" control={<Radio />} label={Trad("Scheduler_exporttype")} />}
            </RadioGroup>
        </FormControl>
    }

    /**
     * Composant sous total (only calendrier)
     * @returns
     */
    const temporalTotalComponent = () => {

        const { temporalTotal, subTotalChoices, ViewModeExport, temporalTotalPercents } = state;
        const isSubTotalChecked = (temporalTotal !== undefined);

        return <>
            {(ViewModeExport === "Scheduler" || ViewModeExport === "SchedulerLight") &&
                <ElementContainer>
                    {/**Checkbox */}
                    <Element>
                        {CheckBoxActivation(Trad("add_subTotal"), isSubTotalChecked,
                            () => saveParams({
                                temporalTotal: !isSubTotalChecked ? subTotalChoices[0] : undefined,
                                rowTotalActivated: false,
                                groupingTotalTable: false,
                                temporalTotalPercents: false
                            }))}
                    </Element>

                    {temporalTotal != undefined && <Element className='margin_top_dialog_export'>
                        <VertexAutocomplete
                            key={`temporalTotal-${temporalTotal}`}
                            label={Trad("subTotalChoices")}
                            options={subTotalChoices}
                            disabled={!isSubTotalChecked}
                            disableClearable
                            needTrad
                            defaultValue={(options: any) => options.find(e => e == temporalTotal)}
                            onChange={subT => saveParams({ temporalTotal: subT })} />

                        <Element className='margin_top_dialog_export'>
                            {CheckBoxActivation(Trad("add_subTotal_percent"), temporalTotalPercents,
                                (e) => saveParams({
                                    temporalTotalPercents: e.target.checked,
                                }))}
                        </Element>
                    </Element>}
                </ElementContainer>}
        </>
    }

    const totalPositionComponent = () => {

        const { rowTotal, rowTotalChoices, ViewModeExport } = state;

        return <>
            {ViewModeExport !== "Table" &&
                <MultipleCombo
                    key={`rowTotal_${rowTotal}`}
                    max={1}
                    label={Trad("rowTotalChoices")}
                    choices={rowTotalChoices}
                    transform={(str) => Trad(str)}
                    initValue={(rowTotal ? [rowTotal] : ["top"])}
                    onChange={(rowTT: ("top" | "bottom")[]) => saveParams({ rowTotal: rowTT[0] })} />}
        </>
    }

    const rowTotalActivatedComponent = () => {

        const { ViewModeExport, rowTotalActivated } = state;

        return <>
            {ViewModeExport !== "Table" &&
                <>
                    {/**Checkbox */}
                    {CheckBoxActivation(Trad("add_total_row"), rowTotalActivated,
                        () => saveParams({
                            rowTotalActivated: !rowTotalActivated,
                            groupingTotalTable: false,
                            temporalTotal: undefined
                        }))}
                </>}
        </>
    }

    /**
     * Activer le tableau de total des regroupements
     * @returns
     */
    const groupingTotalTableComponent = () => {

        const { ViewModeExport, groupingTotalTable, hideDetailsRows } = state;
        return <>
            {ViewModeExport !== "Table" && !hideDetailsRows &&
                <div className="margin_top_dialog_export">
                    {/**Checkbox */}
                    {CheckBoxActivation(Trad("add_grouping_total_table"), groupingTotalTable,
                        () => saveParams({
                            groupingTotalTable: !groupingTotalTable,
                            temporalTotal: undefined,
                            rowTotalActivated: false
                        }))}
                </div>}
        </>
    }

    /**
     * Checkbox pour regrouper les doublons
     * @returns
     */
    const groupingRowsComponent = () => {
        const { ViewModeExport, groupingRows } = state;
        return <>
            {ViewModeExport == "Scheduler" &&
                <div className="margin_top_dialog_export">
                    {/**Checkbox */}
                    {CheckBoxActivation(Trad("grouping_duplicates"), groupingRows,
                        () => saveParams({
                            groupingRows: !groupingRows
                        }))}
                </div>}
        </>
    }

    /**
     * Composant pour découper par onglet
     * @returns
     */
    const splitTabComponent = () => {

        const { config } = props;
        const { splitTab, dimensionsChoices, addSplitDimensionToTotal, ViewModeExport } = state;
        const isSplitTabChecked = splitTab != undefined;

        if (props.documentType != ref_Messages.name) {
            if (splitTab)
                saveParams({ splitTab: null });
            return null;
        }

        return <>

            {/**Checkbox */}
            <div className={splitTab ? "margin_bottom_dialog_export" : ""}>
                {CheckBoxActivation(Trad("add_splitTab"), isSplitTabChecked,
                    () => saveParams({ splitTab: !isSplitTabChecked ? dimensionsChoices[0] : undefined }))}
            </div>

            {splitTab && <>
                <VertexAutocomplete
                    key={`splitTab_${dimensionsChoices?.length}_${splitTab?.field}`}
                    label={Trad("splitTabChoices")}
                    options={dimensionsChoices}
                    getOptionLabel={(o) => o['name'] ?? TradPropVName(o.field, config.Table)}
                    disabled={!isSplitTabChecked}
                    disableClearable
                    defaultValue={(options: any[]) => options.find(e => e?.field == splitTab?.field)}
                    onChange={splt => saveParams({ splitTab: splt })} />
                <div className="margin_top_dialog_export">
                    {ViewModeExport != "Table"
                        && CheckBoxActivation(Trad("add_split_dimension_to_total"), addSplitDimensionToTotal,
                            () => saveParams({ addSplitDimensionToTotal: !addSplitDimensionToTotal }))}
                </div>

            </>}
        </>
    }

    /**
     * Composant sélection periode export
     * @returns
     */
    const datePicker = () => {
        //const { Start, End } = this.props.filters;
        const { Start, End } = state;
        return <DateRangepicker
            defaultStart={Start}
            defaultStop={End}
            handleChangeDate={(e: { start: Date, end: Date }) => {
                if (e.end)
                    saveParams({ Start: e.start, End: e.end })
            }} />
    }

    /**
     * Choix des ventilation dans le cas d'un tableau et ou calendrier
     * @returns
     */
    const linesVentilationsComponent = () => {
        const { config } = props;
        const { ViewModeExport, dimensionsChoices, dimensions, hideGroupingColumns, hideDetailsRows, groupingRows } = state;

        return <>
            {ViewModeExport !== "Table" && <>
                {/* <Title text={Trad("lines")} /> */}
                <MultipleCombo
                    key={`dimensionsChoices_${dimensionsChoices?.join('-')}`}
                    max={2}
                    label={Trad("grouping")}

                    choices={dimensionsChoices}
                    defaultValue={{ field: "*", type: null }}
                    initValue={dimensions ?? []}
                    onChange={newDimensions => saveParams({
                        dimensions: newDimensions,
                        hideDetailsRows: newDimensions?.length > 0 ? hideDetailsRows : false
                    })}
                    transform={(str) => str['name'] ?? TradPropVName(str.field, config.Table)} />
                <div className="margin_top_dialog_export">
                    {CheckBoxActivation(Trad("hide_grouping_columns"), hideGroupingColumns,
                        () => saveParams({ hideGroupingColumns: !hideGroupingColumns }))}
                </div>
                <div className="margin_top_dialog_export">
                    {dimensions?.length > 0 &&
                        CheckBoxActivation(Trad("hide_details_rows"), hideDetailsRows,
                            () => saveParams({
                                hideDetailsRows: !hideDetailsRows,
                                groupingRows: !hideDetailsRows ? false : groupingRows
                            }))}
                </div>
            </>}
        </>
    }

    const timeViewComponent = () => {
        let { ViewModeExport, timeView, includeDays } = state;

        let initValue = ["day"];
        // Legacy, now only week_named and week_dated
        if (timeView) {
            if (timeView == "week" || timeView == "day") initValue = ["week_dated"];
            else initValue = [timeView];

            if (timeView == "day") includeDays = true;
        }

        return <>
            {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <>
                <MultipleCombo
                    max={1}
                    label={Trad("time_view")}
                    choices={["week_named", "week_dated"]}
                    initValue={initValue}
                    onChange={views => {
                        const val = views[0] as ("day" | "week");
                        const st: Partial<TState> = {
                            timeView: val
                        };

                        if (val === "week" && state.temporalTotal === "week")
                            st.temporalTotal = "month";

                        saveParams(st);
                    }}
                    transform={(str) => Trad(str)} />

                <div style={{ float: 'right' }}>
                    {CheckBoxActivation(Trad("include_days"), includeDays,
                        () => saveParams({ includeDays: !includeDays }))}</div>
                <div className='clearfix' />
            </>}
        </>
    }

    /**
     * Limite de niveau de sous total
     * @returns
     */
    const subTotalLimitComponent = () => {
        const { ViewModeExport, dimensions, totalLevel } = state;
        const { config } = props
        const defaultValue = "none";
        const choices = dimensions?.map(d => d.field);
        return <>
            {ViewModeExport !== "Table" && <>
                {/*<Title text={Trad("subTotal_limit")} />*/}
                <MultipleCombo
                    key={`totalLevels_${dimensions?.map(d => d.field).join(".")}`}
                    label={Trad(`subTotal_limit`)}
                    max={1}
                    choices={choices}
                    defaultValue={defaultValue}
                    initValue={[totalLevel ?? defaultValue]}
                    onChange={totalLevels =>
                        saveParams({ totalLevel: totalLevels[0]?.['field'] ?? totalLevels[0] ?? defaultValue })}
                    transform={(str) => str == defaultValue ? Trad(defaultValue) : TradPropVName(str, config.Table)} />
            </>}
        </>
    }

    /**
     * détails du total
     * @returns
     */
    const totalDetailsComponent = () => {
        const { ViewModeExport, totalDetails } = state;
        const defaultValue = (new AggregateExport()).totalDetails;
        return <>
            {ViewModeExport !== "Table" && <>
                {/*<Title text={Trad("total_details")} />*/}
                <div className='adw-row'>
                    <AdwAutocomplete
                        disableClearable
                        options={["none", "numeric", "all"]}
                        value={totalDetails ?? defaultValue}
                        onChange={(event: any, value: any) => {
                            saveParams({ totalDetails: value ?? defaultValue })
                        }}
                        getOptionLabel={l => Trad(l)}
                        renderInput={(params) => <TextField {...params} label={Trad("total_details")} variant="outlined" />}
                    />
                </div>

                {/* <MultipleCombo
                    max={1}
                    label={Trad("total_details")}
                    choices={["none", "numeric", "all"]}
                    defaultValue={defaultValue}
                    initValue={[totalDetails ?? defaultValue]}
                    onChange={(totalDetailsSelect: any) =>
                        this.saveParams({ totalDetails: totalDetailsSelect[0] ?? defaultValue })}
                    transform={(str) => Trad(str)} /> */}
            </>}
        </>
    }

    /**
     * Appliquer le template du calendrier
     * @returns
     */
    const applySchedulerUserTemplateComponent = () => {
        const { ViewModeExport, applySchedulerUserTemplate } = state;
        return <>
            {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <div className="margin_top_dialog_export">
                <ElementContainer>
                    {CheckBoxActivation(
                        Trad("apply_scheduler_user_template"),
                        applySchedulerUserTemplate,
                        (e) => saveParams({ applySchedulerUserTemplate: e.target.checked }))}
                </ElementContainer>
            </div>}
        </>
    }

    const stretchTotalComponent = () => {
        const { ViewModeExport, stretchTotal } = state;
        return <>
            {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <div className="margin_top_dialog_export">
                {CheckBoxActivation(
                    Trad("stretch_total_lines"),
                    stretchTotal,
                    (e) => saveParams({ stretchTotal: e.target.checked }))}
            </div>}
        </>
    }

    /**
     * Selection du template d'export
     * @returns
     */
    const templateSelectorComponent = () => {
        const { templatesChoices, templateName } = state;

        return <>
            {templatesChoices?.length > 0 && <>
                <MultipleCombo
                    label={Trad("modele_template")}
                    max={1}
                    choices={templatesChoices}
                    defaultValue={Trad('default')}
                    initValue={templatesChoices.includes(templateName) ? [templateName] : null}
                    onChange={templateNames => saveParams({ templateName: templateNames[0] })}
                />
            </>}
        </>
    }

    /**
     * Contenu de la dialog
     * @returns
     */
    const dialogContent = () => {
        return <Grid container style={{ width: '100%' }}>

            <Grid item xs={4}>
                <SubTitle text={Trad("export_types")} />
                {radioGroup()}
            </Grid>

            <Grid item xs={8}>
                {/** Paramètres de base */}
                <div className='adw-row'>
                    <SubTitle text={Trad("base_parameters_title")} />
                </div>
                <div className='adw-row'>
                    {datePicker()}
                </div>
                {RightManager.hasRight(eFunctions.ExcelTemplate, eRights.read) && templateSelectorComponent()}
                {splitTabComponent()}
            </Grid>

            <Grid item xs={12}>

                {/** Regroupements */}
                <ElementContainer>
                    {linesVentilationsComponent()}
                </ElementContainer>

                <ElementContainer>
                    {/* {ViewModeExport != "Table" && <Title text={Trad("subtotal_category")} />} */}
                    {totalDetailsComponent()}
                    {subTotalLimitComponent()}
                </ElementContainer>

                {/** Paramétrage du total */}
                {/* {ViewModeExport != "Table" && <Title text={Trad("Total")} />} */}
                <Element>{totalPositionComponent()}</Element>
                <Element>{rowTotalActivatedComponent()}</Element>
                <Element>{groupingTotalTableComponent()}</Element>
                {temporalTotalComponent()}

                {/** Scheduler */}
                {/* {(ViewModeExport == "Scheduler" || ViewModeExport == "SchedulerLight") && <Title text={Trad("scheduler")} />} */}
                {RightManager.hasRight(eFunctions.ExcelTemplate, eRights.read) && applySchedulerUserTemplateComponent()}
                {groupingRowsComponent()}
                {timeViewComponent()}
                {stretchTotalComponent()}

            </Grid>
        </Grid>
    }

    const { onValidate, table, documentType } = props;
    const { temporalTotal: subTotal,
        temporalTotalPercents,
        splitTab,
        Start,
        End,
        ViewModeExport,
        dimensions,
        templateName,
        applySchedulerUserTemplate,
        totalLevel,
        totalDetails,
        rowTotal,
        groupingTotalTable,
        rowTotalActivated,
        timeView,
        includeDays,
        addSplitDimensionToTotal,
        hideGroupingColumns,
        hideDetailsRows,
        groupingRows,
        stretchTotal } = state;

    const _addSplitDimensionToTotal = ViewModeExport == "Table" ? false : addSplitDimensionToTotal;

    return <>
        {dialogContent()}
        <div className="adw-row">
            <CustomButton
                Label={Trad("formated")}
                style={{ float: "right" }}
                disabled={!RightManager.hasRight(eFunctions.ref_Messages, eRights.formatedExport)}
                className="custom_btn_primary"
                onClick={() => onValidate?.({
                    temporalTotal: subTotal,
                    temporalTotalPercents,
                    splitTab,
                    Start,
                    End,
                    ViewModeExport,
                    dimensions,
                    templateName: templateName === Trad('default') ? undefined : templateName,
                    applySchedulerUserTemplate,
                    schedulerUserTemplate: getScheduler(documentType),
                    totalLevel,
                    totalDetails,
                    rowTotal,
                    groupingTotalTable,
                    rowTotalActivated,
                    timeView,
                    includeDays,
                    addSplitDimensionToTotal: _addSplitDimensionToTotal,
                    hideGroupingColumns,
                    hideDetailsRows,
                    groupingRows,
                    stretchTotal
                })} />
        </div>
    </>
}