export enum eCompare {
  LowerEqual = "<=",
  UpperEqual = ">=",
  Lower = "<",
  Upper = ">",
  Contains = "contains",
  ContainsAll = "containsall",
  ContainsAny = "containsAny",
  ContainsValue = "containsValue",
  ContainsKey = "containsKey",
  ContainsText = "containsText",
  notContainsIn = "not in",
  ContainsIn = "in",
  Equals = "=",
  NotEquals = "!=",
  Is = "is",
  IsNot = "is not"
}

export class Operator {
  property: string;
  value: any;
  compare: eCompare;
  Nullable?: boolean;
  strictValue?: boolean;
}