import * as React from 'react'
import Day from "./Day.bin"

export class TProps {
    previousCurrentNextView: any;
    currentMonthView: any;
    selected: any;
    select: any;
    monthEvents: any;
}

export default class Week extends React.Component<TProps, any>{
    constructor(props: TProps) {
        super(props);
    }
    render() {
        let days = [];
        let date = this.props.previousCurrentNextView;
        let currentMonthView = this.props.currentMonthView;
        let selected = this.props.selected;
        let select = this.props.select;
        let monthEvents = this.props.monthEvents;
        for (let i = 0; i < 7; i++) {
            let dayHasEvents = false;
            let catName = [];
            let isBetween = false;
            let hasNexDay = false;
            let hasPreviousDay = false;
            for (let event of monthEvents){
                if (event.date.isSame(date, "day") || event.end.isSame(date, "day") || date.isBetween(event.date, event.end, "day")) {
                    dayHasEvents = true;
                    catName.push({name: event.category, allDay: event.allDay})
                    if(date.isBetween(event.date, event.end, "day")){
                        isBetween = true
                    }
                    if(date.isAfter(event.date, "day")){
                        hasPreviousDay = true
                    }
                    if(date.isBefore(event.end, "day")){
                        hasNexDay = true
                    }
                }
            }
            let day = {
                name: date?.format("dd").substring(0, 1),
                number: date?.date(),
                isCurrentMonth: date?.month() === currentMonthView.month(),
                isToday: date?.isSame(new Date(), "day"),
                date: date,
                hasEvents: dayHasEvents,
                catName,
                isBetween,
                hasNexDay,
                hasPreviousDay
            };
            days.push(<Day key={`${day.date}${i}`} day={day} selected={selected} select={select} />);
            date = date?.clone();
            date?.add(1, "d");
        }
        return (
            <div className="row week">
                {days}
            </div>
        );
    }
}