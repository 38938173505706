import { User, UserId } from "./User.bin";
import { ref_Customers, ref_CustomersId } from "./ref_Customers.bin";
import { ref_Indexables, ref_IndexablesId } from "./ref_Indexables.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";
import { IUpsertInfo } from "../../types";

export type ReferentialsId = rid;

export class Referentials extends V implements ref_Indexables, IUpsertInfo {
	"@rid"?: ReferentialsId;
	Active: boolean;
	Created_at?: Date;
	Created_by?: UserId;
	Customers?: ref_CustomersId[];
	Updated_at?: Date;
	Updated_by?: UserId;
}