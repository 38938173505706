import * as React from "react"
import Button from '@material-ui/core/Button'
import { Trad, TradProp } from "trad-lib"
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import { ConfirmContent, GenericDialog } from "../../../ConfigurableComponents/GenericDialog.bin"
import { eStatusType, ref_Messages } from "hub-lib/dto/client/ref_Messages.bin"
import { CustomGroupButton } from "../../../ConfigurableComponents/CustomGroupButton.bin"
import { AdwAutocomplete, getIcon } from "adwone-lib/index"
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin"
import { CustomBadge } from "../../Adwone-admin/Referential/BadgesUsage.bin"
import { Checkbox } from "@progress/kendo-react-inputs"
import { CustomPercentTextBox } from "../Common.bin"
import { DiscountManager } from "hub-lib/business/DiscountManager.bin"
import { distinct, propertyOf } from "hub-lib/tools.bin"
import { eGroupCategories, ref_Groups } from "hub-lib/dto/client/ref_Groups.bin"
import { Client } from "hub-lib/client/client.bin"
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin"
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin"
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin"
import { ShowMessages } from "../../Campaigns/CampaignsGridCreator"
import { UpdateSet } from "hub-lib/dto/UpdateSet"

export class TProps<T> {
    objectType?: new () => T;
    selectedItems?: any[];
    onSubmitAction?: (action: dialogAction, values: any) => Promise<void>;
}
type popOverAction = "fee_rate" | "properties";
export type dialogAction = "delete" | "status" | popOverAction;

class TState {
    action: dialogAction;
    popoverMode: popOverAction;
    anchorEl: HTMLElement | null;
    updateSet: UpdateSet;
}

const status = [eStatusType.None, eStatusType.Simulated, eStatusType.Opted, eStatusType.Confirmed, eStatusType.Cancelled]

type ConfirmationDetailsProps<T> = {
    objectType: new () => T,
    selectedItems: any[],
    action?: dialogAction,
    updateSet?: UpdateSet
}

function ConfirmationDetails<T>({ selectedItems, objectType, action, updateSet }: ConfirmationDetailsProps<T>) {
    const [linkedMessages, setLinkedMessages] = React.useState<number>(0);
    const [boundMessages, setBoundMessages] = React.useState<number>(0);

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            let count = 0;
            let countEstimated = 0;
            if (action == "delete" && objectType.name == ref_Campaigns.name) {
                const campaignRids = selectedItems.map(i => i.dataItem["@rid"]);
                if (campaignRids.length) {
                    const results: ref_Messages[] = (
                        await Client.searchVertex(ref_Messages.name, {
                            properties: ['@rid', 'Deversement'],
                            Source: ['ADWONE'],
                            Active: true,
                            Campaign: campaignRids
                        })
                    )?.data?.results;
                    countEstimated = results.filter((m) => m.Deversement).length || 0;
                    count = results.length - countEstimated;
                }
                setBoundMessages(countEstimated);
                setLinkedMessages(count);
            }
        })
    })

    let property = action;
    if (updateSet.PropertySet) {
        property = TradProp(updateSet.PropertySet.name, ref_Messages);
        //count = actionValue?.choices.find(c => c.key == actionValue.value)?.rids?.length;
    }

    return <>
        <p>{ConfirmContent(selectedItems.map(s => s.dataItem), (action == "delete") ? "delete" : "modify")}</p>
        <ul>
            {linkedMessages > 0 && <li>{linkedMessages + ` ${Trad('messages_attached')}`}</li>}
            {boundMessages > 0 && <li>{boundMessages + ' message(s) déversé(s)'}</li>}
            {updateSet.PropertySet && <li>{`${Trad(property)} : ${Trad(updateSet.PropertySet.value)}`}</li>}
            {updateSet.FeeRates && <>
                {Object.keys(updateSet.FeeRates).map(k => {
                    if (!updateSet.FeeRates[k].update) return;
                    return <li>{`${updateSet.FeeRates[k].label} : ${updateSet.FeeRates[k].value}%`}</li>
                })}
            </>}
        </ul>
    </>
}

export class DataGridMassActions<T> extends React.Component<TProps<T>, TState> {

    constructor(props: TProps<T>) {
        super(props);
        let newState = new TState();
        newState.updateSet = {}
        newState.anchorEl = null;

        this.state = newState;
    }

    feeRatesClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.currentTarget;
        const typeId = (await DiscountManager.GetDiscountTypes()).find(c => c.Name === "Honoraires")?.["@rid"];
        const classes = (await DiscountManager.GetDiscountClasses()).filter(c => c.DiscountType == typeId);

        const feeRates = {};
        classes.forEach(discountClass => {
            feeRates[discountClass["@rid"]] = { update: false, value: 0, typeId: typeId, label: `${Trad("taux")} ${Trad(discountClass.Name)}` };
        });
        this.setState({
            popoverMode: "fee_rate",
            updateSet: { FeeRates: feeRates },
            anchorEl: target
        });
    }

    propertyClick = async (button: HTMLElement, property: string) => {

        const dataItems = this.props.selectedItems.map(i => i["dataItem"] as ref_Messages);
        const supportIds = distinct(dataItems.map(i => i.Support));

        const mediaFamilies = await Client.searchVertexTyped(ref_Groups, {
            Active: true,
            Category: eGroupCategories.MediaFamily,
            IDs: supportIds,
        });

        const isMessageElligible = (mediaFamily: ref_Groups, message: ref_Messages) => {
            if (!mediaFamily.IDs.includes(message.Support))
                return false;
            return mediaFamily.Area.find(area => {
                if (area.Class == ref_Advertisers.name) {
                    if (!area.IDs)
                        return true;
                    if (area.IDs.includes(message.Advertiser))
                        return true;
                }
                return false;
            })
        }
        const propertyChoices = mediaFamilies.map(f =>
        ({
            key: f["@rid"],
            Name: f.Name,
            rids: dataItems.filter(i => isMessageElligible(f, i)).map(m => m["@rid"])
        })).filter(p => p.rids.length > 0);

        const propertySet = {
            name: property,
            value: null,
            choices: propertyChoices
        };
        this.setState({
            popoverMode: "properties",
            updateSet: { PropertySet: propertySet },
            anchorEl: button
        });
    }

    toggleDialog = (action?: dialogAction) => {
        if (!action)
            this.setState({ action: null, updateSet: {}, anchorEl: null, popoverMode: undefined });
        this.setState({ action: action });
    }

    render() {
        const { popoverMode, action, updateSet } = this.state;
        const { objectType, selectedItems } = this.props;
        const open = Boolean(this.state.anchorEl);
        const id = open ? popoverMode : undefined;
        const properties = ["ModelProperties.MediaFamily"];
        const count = selectedItems.length;

        return (
            <>
                <div className="margin-right">
                    <CustomBadge count={count} icon={objectType.name} tradClassKey={objectType.name} />
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({ anchorEl: null, popoverMode: undefined })}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} >

                    <div className="DialogContent-container">
                        <div style={{ width: "300px" }}>
                            {popoverMode == "fee_rate" && Object.keys(updateSet.FeeRates).map(key => {
                                return <div className="message_details_full" style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <Checkbox checked={updateSet.FeeRates[key].update}
                                        onChange={e => {
                                            updateSet.FeeRates[key].update = e.value;
                                            this.setState({ updateSet: updateSet })
                                        }}
                                        style={{ marginRight: "6px" }} />
                                    <CustomPercentTextBox
                                        title={updateSet.FeeRates[key].label}
                                        disabled={!RightManager.hasRight(eFunctions.ref_Messages, eRights.update) || !updateSet.FeeRates[key].update}
                                        value={updateSet.FeeRates[key].value}
                                        onChange={(e) => { updateSet.FeeRates[key].value = e.value; this.setState({ updateSet: updateSet }) }}
                                    />
                                </div>
                            })}
                            {popoverMode == "properties" &&
                                <div className="message_details_full">
                                    <AdwAutocomplete
                                        options={updateSet.PropertySet.choices}
                                        getOptionLabel={(v => v.Name)}
                                        disableClearable
                                        value={updateSet.PropertySet.value && updateSet.PropertySet.choices.find(p => p.key == updateSet.PropertySet.value)}
                                        onChange={async (event: any, value: any) => {
                                            console.log("value", value);
                                            updateSet.PropertySet.value = value?.key;
                                            this.setState({ updateSet: updateSet })
                                        }}
                                        renderInput={(params: any) => <TextField {...params} variant="outlined" label={TradProp(updateSet.PropertySet.name, ref_Messages)} />}
                                    />
                                    {this.props.selectedItems?.map(item => {
                                        return <div>{item["Support"]}</div>
                                    })}
                                </div>}
                        </div>
                        <div>
                            <div className="adw-form-action">
                                <Button aria-describedby={id}
                                    variant="contained"
                                    disabled={(updateSet.PropertySet && !updateSet.PropertySet?.value) ||
                                        (updateSet.FeeRates && !Object.keys(updateSet.FeeRates).some(k => updateSet.FeeRates[k]?.update))}
                                    className="custom_btn_primary_validation" onClick={() => {
                                        this.toggleDialog(popoverMode)
                                    }}>
                                    {Trad("change")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Popover>
                {objectType.name == "ref_Messages" && RightManager.hasRight(objectType.name, eRights.update) && <>
                    <CustomGroupButton
                        Label={Trad("status")}
                        buttonClassName={"custom_btn_secondary_white margin-right"}
                        buttons={status.map((s, index) => (
                            {
                                Label: <><span className={`rounded_status ${s.slice(0, 3)}`}></span>{Trad(s)}</>,
                                onClick: () => {
                                    this.setState({ updateSet: { PropertySet: { name: "Status", value: status[index] } } }, () => this.toggleDialog("status"))
                                }
                            }
                        ))} />
                    <Button aria-describedby={id} startIcon={getIcon("wallet")} className="custom_btn_secondary_white margin-right" onClick={this.feeRatesClick}>
                        {Trad("fee_rate")}
                    </Button>
                    {/*<CustomGroupButton
                        Label={Trad(ref_Property.name)}
                        buttonClassName={"custom_btn_secondary_white margin-right"}
                        buttons={properties.map((p, index) => (
                            {
                                Label: <>{TradProp(p, ref_Messages)}</>,
                                onClick: (event, button) => { this.propertyClick(button, p) }
                            }
                        ))} />*/}
                </>}
                {objectType.name == ref_Campaigns.name && RightManager.hasRight(eFunctions.ref_Messages, eRights.read) &&
                    <>
                        <Button startIcon={getIcon('go')} className="custom_btn_secondary_white margin-right"
                            onClick={() => ShowMessages(selectedItems.map(i => i.dataItem))}>
                            {Trad("see_messages")}
                        </Button>
                    </>
                }
                {RightManager.hasRight(objectType.name, eRights.delete) &&
                    <Button variant="contained"
                        className="custom_btn_danger"
                        startIcon={getIcon("delete")}
                        onClick={() => this.toggleDialog("delete")}>
                        {Trad("delete")}
                    </Button>
                }
                <GenericDialog
                    dialogTitle={Trad("confirmation")}
                    open={Boolean(action)}
                    submitTitle={Trad("yes")}
                    handleClose={() => this.toggleDialog()}
                    actions={true}
                    submitAction={async () => {
                        await this.props.onSubmitAction(action, updateSet);
                        this.toggleDialog();
                    }}>
                    <ConfirmationDetails
                        action={action}
                        selectedItems={selectedItems}
                        objectType={objectType}
                        updateSet={updateSet}
                    />
                </GenericDialog>
            </>
        )
    }
}