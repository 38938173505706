import { ColumnIndicateur, Indicateur } from "adwone-engine/index.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
import { ADWProperty } from "../../types";
import { eFunctions, eRights, RightManager } from "../../models/types/rights.bin";

export const dicoViewModeIcons = {
	Table: "tune",
	CrossedTable: "tune",
	Scheduler: "tune",
	Dashboard: "tune"
}

export type ViewModeType = "Table" | "CrossedTable" | "Scheduler" | "Dashboard";
export class ref_TableConfigurations extends ref_ConfigurationsBase {
	Columns: ColumnIndicateur[] = [];
	//HidedColumns?: Indicateur[];
	FrozenPosition: number = 0;
	ViewMode: ViewModeType = "Table";
	CrossedtableConfig: {
		rowventils?: (ADWProperty | Indicateur)[]
	} = undefined;
}

const mappingViewModeToRight = {
	CrossedTable: eRights.viewCrossedTable,
	Scheduler: eRights.viewScheduler,
	Dashboard: eRights.viewDashboard
}

export function HasRightToViewMode(objectType: string, viewMode: ViewModeType): boolean {
	const right = mappingViewModeToRight[viewMode];
	if (!right)
		return true;
	return RightManager.hasRight(objectType, right);
}

export function RepaireTableConfigurations(toRepaire: ref_TableConfigurations) {
	return { ...new ref_TableConfigurations(), ...toRepaire };
}