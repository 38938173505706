import * as React from "react";
import Grid from '@material-ui/core/Grid'
import { eDialogMode, GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import { Trad, TradClassName } from "trad-lib";
import { UserExtended } from "hub-lib/dto/referential/UserExtended.bin";
import { TextField } from "@material-ui/core";
import { ref_Persons } from "hub-lib/models/orientdb/ref_Persons.bin";
import { VertexAutocomplete } from "adwone-lib/index";
import { ref_Profiles } from "hub-lib/models/orientdb/ref_Profiles.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisingCompanyGroups } from "hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin";
import { ref_AgencyGroups } from "hub-lib/models/orientdb/ref_AgencyGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Agencies } from "hub-lib/models/orientdb/ref_Agencies.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ref_SoftwareEditors } from "hub-lib/models/orientdb/ref_SoftwareEditors.bin"
import { Client } from "hub-lib/client/client.bin";
import { ref_Companies } from "hub-lib/models/orientdb/ref_Companies.bin";
import Loader from "../../layout/Loader";
import { IRid } from "hub-lib/models/IRid.bin";
import { ErrorMessage } from "../../ConfigurableComponents/ErrorMessage.bin";
import { ref_Qualifications } from "hub-lib/models/orientdb/ref_Qualifications.bin";
import { ref_CustomersExtended } from "hub-lib/dto/referential/ref_CustomersExtended.bin";
import { ref_Modules } from "hub-lib/models/orientdb/ref_Modules.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { Sort } from "format-lib/index.bin";
import { ref_Departments } from "hub-lib/models/orientdb/ref_Departments.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import { eRoles } from "hub-lib/business/rights/rights.bin";

export class TProps {
    open: boolean;
    mode: eDialogMode;
    handleClose?: () => void;
    onValidate: (u: UserExtended) => any;
    user?: UserExtended;
    administrator: UserExtended;
    company?: ref_Companies;
    fromCustomerPage?: boolean = false;
}

class TState {
    user: UserExtended
    userCopy: UserExtended = undefined
    validationMode: boolean = false
    companies?: ref_Companies[]
    profiles: ref_Profiles[]
    adwantedProfiles: ref_Profiles[]
    loading: boolean = true
    baseModules: ref_Modules[]
    alertMail: AlertMailChanged
}

let dicoTitleTrad: any = {
}

let dicoCreateTrad: any = {
}
export let groups = [ref_AdvertiserGroups.name, ref_AdvertisingCompanyGroups.name, ref_AgencyGroups.name]

export let groupToChild = {
    [ref_AdvertiserGroups.name]: ref_Advertisers.name,
    [ref_AdvertisingCompanyGroups.name]: ref_AdvertisingCompanies.name,
    [ref_AgencyGroups.name]: ref_Agencies.name
}
export class UsersDialog extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);

        dicoTitleTrad = {
            [eDialogMode.create]: Trad("new_user"),
            [eDialogMode.modify]: Trad("edit_user")
        }

        dicoCreateTrad = {
            [eDialogMode.create]: Trad("create"),
            [eDialogMode.modify]: Trad("modify")
        }

        let newState = new TState()
        if (this.props.mode == eDialogMode.modify)
            newState.userCopy = JSON.parse(JSON.stringify(this.props.user))
        newState.user = this.props.user ? { ...this.props.user } : new UserExtended()
        newState.user.person = newState.user.person !== undefined ? newState.user.person : new ref_Persons();
        newState.user.modules = newState.user.modules ? newState.user.modules : []
        if (this.props.fromCustomerPage)
            newState.user.company = this.props.company
        this.state = newState;
    }
    searchModules = async () => {
        let modules = (await Client.searchVertex(ref_Modules.name))?.data?.results
        this.setState({ baseModules: modules })

    }
    async componentDidMount() {
        await this.searchModules();
        let allProfiles = await Client.searchVertexTyped(ref_Profiles);

        const superAdmin = allProfiles?.find(e => e.Name == eRoles.superAdministrateur);
        const dataManager = allProfiles?.find(e => e.Name == eRoles.dataManager);

        const profiles = allProfiles;
        if (this.props.fromCustomerPage) {
            let adminRole = profiles?.find(e => e.Name == "administrateur")["@rid"];
            let companies = (await Client.searchVertex(ref_Companies.name, { "@rid": this.props.company["@rid"] }))?.data.results
            this.setState(prevState => ({
                user: {
                    ...prevState.user,
                    company: this.props.company,
                    profile: adminRole,
                    profileName: "administrateur"
                },
                companies,
                profiles: profiles,
                loading: false
            }))
        } else {
            if (this.props?.administrator && this.props?.administrator.company["@class"] === ref_SoftwareEditors.name) {
                const allCustomers = await Client.searchVertexTyped(ref_CustomersExtended, { Active: true });
                const customerCompanies = allCustomers.map(c => c.Company);

                const companies = Sort(ref_Companies.name, (await Promise.all([
                    Client.searchVertexTyped(ref_Companies, { '@rid': customerCompanies }),
                    Client.searchVertexTyped(ref_Companies, { parents: customerCompanies }),
                    Client.searchVertexTyped(ref_SoftwareEditors, { Active: true })
                ])).flat());

                this.setState({
                    companies,
                    profiles: profiles, //[superAdmin, ...profiles],
                    loading: false,
                    adwantedProfiles: [superAdmin, dataManager],
                })
            }
            else if (this.props?.administrator && !groups.includes(this.props.administrator.company["@class"])) {
                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        company: this.props.administrator.company
                    },
                    companies: [this.props.administrator.company],
                    profiles,
                    loading: false
                }))
            } else {
                let companies = (await Client.searchVertex(groupToChild[this.props?.administrator.company["@class"]], { "parents": this.props?.administrator.company["@rid"] }))?.data.results
                companies.unshift(this.props?.administrator.company)
                this.setState({
                    companies,
                    profiles,
                    loading: false
                })
            }
        }
    }
    updateCustomer = async () => {
        let { user } = this.state
        if (RightManager.hasRight(eFunctions.ref_Customers, eRights.read)) {
            const parents = await Client.searchVertexTyped(ref_Companies, { children: user.company["@rid"] });
            const [customer] = await Client.searchVertexTyped(ref_CustomersExtended, { Company: [user.company["@rid"], ...parents.map(p => p["@rid"])] });
            user.customer = customer;
        } else {
            user.customer = this.props.administrator.customer;
        }

        this.forceUpdate()
    }

    optionClassName() {
        let { user, administrator, fromCustomerPage } = this.props;
        let position: string = user?.customer ? "left" : "right";
        if (administrator?.company?.["@class"] === ref_SoftwareEditors.name || fromCustomerPage || administrator?.profileName == "super-administrateur")
            position = (position == "left") ? "right" : "left";


        console.log(`[optionClassName]`, position, administrator?.company?.["@class"] === ref_SoftwareEditors.name || fromCustomerPage || administrator?.profileName == "super-administrateur", { user, administrator, fromCustomerPage })
        return position;
    }

    getModules() {
        let { user, baseModules } = this.state;
        return baseModules.filter((e: ref_Modules) => (user.customer as ref_CustomersExtended)?.lnkHasModule?.some(m =>
            m.out == e["@rid"] && !m["AllUsers"]))
    }

    render() {
        if (this.state.loading)
            return <Loader />
        let { user, companies, profiles, adwantedProfiles, userCopy } = this.state;
        let { administrator } = this.props;
        return (
            <>
                <GenericDialog
                    open={this.props.open}
                    handleClose={this.props.handleClose}
                    disableCancel={this.props.fromCustomerPage}
                    dialogTitle={dicoTitleTrad[this.props.mode]}
                    submitAction={() => { this.props.onValidate(user) }}
                    actions={true}
                    submitTitle={dicoCreateTrad[this.props.mode]}
                    id={'users_grid_dialog'}
                    dialogContent={
                        <Grid container>
                            <Grid item xs={6} className="message_details_leftcombo">
                                <TextField id="user_firstname"
                                    autoComplete='off'
                                    style={{ width: '100%' }}
                                    label={`${Trad("firstname")} *`}
                                    value={user.person.FirstName}
                                    variant="outlined"
                                    onChange={(e) => { user.person.FirstName = e.target.value; this.forceUpdate() }}
                                />
                            </Grid>
                            <Grid item xs={6} className="message_details_rightcombo">
                                <TextField id="user_lastname"
                                    autoComplete='off'
                                    style={{ width: '100%' }}
                                    label={`${Trad("lastname")} *`}
                                    value={user.person.LastName}
                                    variant="outlined"
                                    onChange={(e) => { user.person.LastName = e.target.value; this.forceUpdate() }}
                                />
                            </Grid>
                            <Grid item xs={12} className="message_details_full">
                                <TextField id="user_mail"
                                    autoComplete='off'
                                    type="email"
                                    style={{ width: '100%' }}
                                    label={`${Trad("property_mail")} *`}
                                    value={user.mail}
                                    variant="outlined"
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        user.mail = value;

                                        if (userCopy && (userCopy?.mail != value))
                                            this.state.alertMail?.Show();
                                        else
                                            this.state.alertMail?.Unshow();

                                        this.forceUpdate();
                                    }}
                                />
                                <AlertMailChanged onRef={(alertMail) => {
                                    this.setState({ alertMail });
                                }} />
                            </Grid>
                            <Grid item xs={6} className="message_details_leftcombo">
                                <TextField id="user_job"
                                    autoComplete='off'
                                    style={{ width: '100%' }}
                                    label={`${Trad("job")} (${Trad("optionnal")})`}
                                    value={user.job}
                                    variant="outlined"
                                    onChange={(e) => { user.job = e.target.value; this.forceUpdate() }}
                                />
                            </Grid>
                            <Grid item xs={6} className="message_details_rightcombo">
                                <TextField id="user_number"
                                    autoComplete='off'
                                    style={{ width: '100%' }}
                                    label={`${Trad("phone")} (${Trad("optionnal")})`}
                                    value={user.phone}
                                    variant="outlined"
                                    onChange={(e) => { user.phone = e.target.value; this.forceUpdate() }}
                                />
                            </Grid>
                            <Grid item xs={6} className="message_details_leftcombo">
                                <VertexAutocomplete
                                    key={`profile_${user.profile}`}
                                    options={user?.company?.["@class"] == ref_SoftwareEditors.name ? adwantedProfiles : profiles}
                                    disabled={this.props.fromCustomerPage}
                                    label={`${Trad("property_profile")} *`}
                                    disableClearable
                                    onChange={(value: any) => {
                                        user.profile = value["@rid"];
                                        if (adwantedProfiles?.length && adwantedProfiles.some(p => p["@rid"] == user.profile)) {
                                            user.company = companies.find(c => c["@class"] == ref_SoftwareEditors.name);
                                            user.customer = null;
                                        }
                                        this.forceUpdate();
                                    }}
                                    defaultValue={((profiles: IRid[]) => profiles?.find((v) => v["@rid"] === user.profile))}
                                />
                            </Grid>
                            <Grid item xs={6} className="message_details_rightcombo">
                                {(!groups.includes(administrator?.company?.["@class"]) && administrator?.company?.["@class"] !== ref_SoftwareEditors.name && !this.props.fromCustomerPage) &&
                                    <VertexAutocomplete
                                        label={`${Trad("property_company")} *`}
                                        options={[administrator?.company]}
                                        defaultValue={((companies: IRid[]) => companies?.find((v) => v["@rid"] === user.company?.["@rid"]))}
                                        disabled
                                    />
                                }
                                {(groups.includes(administrator?.company?.["@class"]) || administrator?.company?.["@class"] === ref_SoftwareEditors.name || this.props.fromCustomerPage) &&
                                    <VertexAutocomplete
                                        key={`company_${user.company?.["@rid"]}`}
                                        options={this.props.fromCustomerPage ? [user.company] : companies}
                                        disabled={this.props.fromCustomerPage}
                                        label={`${Trad("property_company")} *`}
                                        disableClearable
                                        onChange={(value: any) => {
                                            user.company = value;
                                            if (adwantedProfiles?.length && adwantedProfiles.some(p => p["@rid"] == user.profile) && user.company["@class"] != ref_SoftwareEditors.name)
                                                user.profile = null;
                                            if (adwantedProfiles?.length && !adwantedProfiles.some(p => p["@rid"] == user.profile) && user.company["@class"] == ref_SoftwareEditors.name)
                                                user.profile = adwantedProfiles.find(p => p.Name == eRoles.superAdministrateur)["@rid"];
                                            this.updateCustomer()
                                        }}
                                        defaultValue={((companies_alt: IRid[]) => this.props.fromCustomerPage ? user.company : companies_alt?.find((v) => v["@rid"] === user.company?.["@rid"]))}
                                    />
                                }
                            </Grid>
                            {user.customer &&
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <VertexAutocomplete
                                        type={ref_Departments.name}
                                        label={TradClassName(ref_Departments.name)}
                                        onChange={(value: any) => { user.Department = value["@rid"]; this.forceUpdate() }}
                                        params={{ Company: user.customer.Company }}
                                        defaultValue={((departments: IRid[]) => departments?.find((v) => v["@rid"] === user?.Department))}
                                    />
                                </Grid>
                            }
                            {(administrator?.company?.["@class"] === ref_SoftwareEditors.name || this.props.fromCustomerPage || administrator?.profileName == "super-administrateur") &&
                                <Grid item xs={6} className={`message_details_${user.customer ? "right" : "left"}combo`}>
                                    <VertexAutocomplete
                                        type={ref_Qualifications.name}
                                        label={`${Trad("static/ref_Qualifications/undefined")} *`}
                                        disableClearable
                                        onChange={(value: any) => { user.qualification = value["@rid"]; this.forceUpdate() }}
                                        defaultValue={((qualifications: IRid[]) => qualifications?.find((v) => v["@rid"] === user?.qualification))}
                                    />
                                </Grid>}
                            <Grid item xs={12}
                                key={this.state.user?.company?.["@rid"]}
                                className={`message_details_leftcombo`}>
                                <Grid item xs={6}>
                                    {(user.customer && this.getModules()) &&
                                        <VertexAutocomplete
                                            label={Trad("Options")}
                                            multiple
                                            options={this.getModules()}
                                            defaultValue={(modules: any[]) =>
                                                modules.filter((v) => user?.modules?.includes(v["@rid"]))
                                            }
                                            onChange={(value: any) => {
                                                user.modules = value.map(
                                                    (e: any) => e["@rid"]
                                                );
                                                this.forceUpdate();
                                            }}
                                            disabled={!this.getModules()}
                                            onResetValue={(modules: any) => [] as any}
                                        />
                                    }</Grid>
                            </Grid>
                        </Grid>
                    }
                />
            </>
        )
    }
}

class AlertMailChanged extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            showElement: false
        }
    }

    Show() {
        this.setState({ showElement: true });
    }

    Unshow() {
        this.setState({ showElement: false });
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    render() {
        const { showElement } = this.state;
        if (showElement)
            return <div style={{ marginTop: 15 }}><ErrorMessage message={Trad("warning_changing_log")} padding={'10px'} /></div>
        return <div></div>;

    }
}