import { ref_MappingMM, ref_MappingMMId } from "./ref_MappingMM.bin";
import { ref_Sources, ref_SourcesId } from "./ref_Sources.bin";
import { rid } from "./CommonTypes.bin";

export type src_MMId = rid;

export class src_MM extends ref_Sources {
	"@rid"?: src_MMId;
	DATA_MAP: boolean;
	Mapping: string;
	MappingMM: ref_MappingMMId;
	MasterEndpoint: string;
	URI_Estimates: string;
	URI_Financial: string;
	URI_MAP: string;

}