import * as React from 'react'
import Template from '../Template'
import { ADWColumn, getIcon, VertexAutocomplete } from 'adwone-lib';
import { ref_CustomersExtended } from 'hub-lib/dto/referential/ref_CustomersExtended.bin';
import { ModeleGrid } from '../../VertexGrid/Generic/ModeleGrid/ModeleGrid';
import { DataGrid } from '../../VertexGrid/Generic/ModeleGrid/DataGrid';
import { vw_mm_HasFormat } from 'hub-lib/models/orientdb/vw_mm_HasFormat.bin';
import { IndicateurComputed, IndicateurInfo } from 'adwone-engine/index.bin';
import { ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { Client } from 'hub-lib/client/client.bin';
import { src_MM } from 'hub-lib/models/orientdb/src_MM.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { ReferentialHasViews } from 'hub-lib/models/orientdb/ReferentialHasViews.bin';
import { TradClassName } from 'trad-lib';
import { vw_mm_HasAdvertiserGroup } from 'hub-lib/models/orientdb/vw_mm_HasAdvertiserGroup.bin';
import { vw_mm_HasAdvertiser } from 'hub-lib/models/orientdb/vw_mm_HasAdvertiser.bin';
import { vw_mm_HasBrand } from 'hub-lib/models/orientdb/vw_mm_HasBrand.bin';
import { vw_mm_HasProduct } from 'hub-lib/models/orientdb/vw_mm_HasProduct.bin';
import { vw_mm_HasPlacement } from 'hub-lib/models/orientdb/vw_mm_HasPlacement.bin';
import { ConfigurationPanelContainer, ToolbarAdw } from '../../VertexGrid/Generic/ToolbarAdw';
import { ConfigurationPanel } from '../../VertexGrid/Messages/ConfigurationPanel';
import { CustomIconButton } from '../../VertexGrid/Generic/CustomIconButton';
import { store } from '../../../redux/store';
import { setGridFilter } from '../../../redux/gridSlice';
import { vw_mm_HasSupport } from 'hub-lib/models/orientdb/vw_mm_HasSupport.bin';
import { vw_mm_HasAdvertisingCompanies } from 'hub-lib/models/orientdb/vw_mm_HasAdvertisingCompanies.bin';
import { JSONRequestViewer, JSONViewer } from '../../ConfigurableComponents/JSONViewer';
import { Referentials } from 'hub-lib/models/orientdb/Referentials.bin';
import { Views } from 'hub-lib/models/orientdb/Views.bin';

const availableLinkes = [
    { name: vw_mm_HasAdvertiserGroup.name, type: vw_mm_HasAdvertiserGroup },
    { name: vw_mm_HasAdvertiser.name, type: vw_mm_HasAdvertiser },
    { name: vw_mm_HasBrand.name, type: vw_mm_HasBrand },
    { name: vw_mm_HasProduct.name, type: vw_mm_HasProduct },
    { name: vw_mm_HasFormat.name, type: vw_mm_HasFormat },
    { name: vw_mm_HasPlacement.name, type: vw_mm_HasPlacement },
    { name: vw_mm_HasSupport.name, type: vw_mm_HasSupport },
    { name: vw_mm_HasAdvertisingCompanies.name, type: vw_mm_HasAdvertisingCompanies }

]

function DataLinkSimilarities() {
    return <div className="grid_container datalinksimilarities">
        <ViewSimilarities />
    </div>
}

function ViewSimilarities() {

    const [customer, setCustomer] = React.useState<ref_CustomersExtended>(null);
    const [link, setLink] = React.useState<string>(null);
    const [dataGrid, setDataGrid] = React.useState<DataGrid<any>>(null);

    const createDataGrid = async (customer: ref_CustomersExtended, linkType: new () => any) => {
        const configuration = new ref_TableConfigurations();

        const columnSimilarities = new IndicateurComputed();
        columnSimilarities.name = "Similarités";
        columnSimilarities.indicateurs = [
            new IndicateurInfo({ field: "ReferentialName", name: "Référentiel", valueType: eKPIType.String }),
            new IndicateurInfo({ field: "ViewName", name: "Vue", valueType: eKPIType.String }),
        ];
        columnSimilarities.operator = 'similarity';
        columnSimilarities.options = {
            valueTransform: [
                { function: "split", args: ["/", 1] },
                { function: "pop" }]
        };

        configuration.Columns = [
            new IndicateurInfo({ field: "Referential", name: "Référentiel (ID)", valueType: eKPIType.String }),
            new IndicateurInfo({ field: "ReferentialName", name: "Référentiel", valueType: eKPIType.String }),
            new IndicateurInfo({ field: "ViewId", name: "Vue (ID MM)", valueType: eKPIType.String }),
            new IndicateurInfo({ field: "ViewName", name: "Vue", valueType: eKPIType.String }),
            columnSimilarities
        ];

        const customViewer = (prop, docType) => ({ Formated, Value }, row) => {
            return <span style={{ display: 'flex' }}>
                <span><JSONRequestViewer id={row?.dataItem?.dataItem?.[prop] ?? null} docType={docType} /></span>
                <span>{Formated}</span>
            </span>
        };

        const refCustom = new ADWColumn<any>("Référentiel (ID)", "Referential");
        refCustom.cellContent = customViewer('Referential', Referentials.name);

        const viewCustom = new ADWColumn<any>("Vue (ID MM)", "ViewId");
        viewCustom.cellContent = customViewer('out', Views.name);;

        const [mmSource] = await Client.searchVertexTyped(src_MM, { "@rid": customer.Sources });

        const grid = new DataGrid({
            bodyParameters: { hideDetailsData: false },
            objectPrototype: linkType,
            vertexParams: {
                properties: ["*", "Referential", "Referential.Name as ReferentialName", "out.Label as ViewName", "out.ExternalID as ViewId"],
                in: mmSource['@rid'],
                "Referential.Active": true
            },
            afterSearch: (rows: { dataItem: ReferentialHasViews<any, any, any>, _computed: any }[]) => {
                console.log(`[rows]`, rows)
                const filtered = rows.filter(r => r.dataItem.Referential);
                return filtered;
            },
            columns: [refCustom, viewCustom],
            configuration
        });

        console.log(`[grid]`, grid)
        setDataGrid(null);
        setDataGrid(grid);

        await grid.Initialize();
    };

    return <div style={{ width: '100%' }}>
        <ToolbarAdw>
            <ConfigurationPanelContainer>
                <ConfigurationPanel
                    elements={[{
                        type: "component",
                        component: <CustomIconButton onClick={() => {
                            dataGrid.exportExcel("csv");
                        }}>{getIcon("download")}</CustomIconButton>
                    }]} />
            </ConfigurationPanelContainer>
        </ToolbarAdw>
        <div style={{ display: 'flex', gap: 5 }}>
            <CustomerPicker onChange={(customer: ref_CustomersExtended) => {
                setCustomer(customer);
                if (link)
                    createDataGrid(customer, availableLinkes.find(l => l.name == link).type);
            }} />

            <LinkPicker onChange={(link: string) => {
                setLink(link);
                if (customer)
                    createDataGrid(customer, availableLinkes.find(l => l.name == link).type);
            }} />

        </div>

        {dataGrid && <ModeleGrid
            headerFiltersChanged={(adapted, base) => store.dispatch(setGridFilter({ adapted, base }))}
            isCopyDisable
            commandCellArgs={{ isEditable: false, isCopyDisable: true }}
            groupable={false}
            gridHeight={`calc(100vh - 140px)`}
            grid={dataGrid}
            hideToolbar
            selectable
        />}
    </div>
}

type CustomerPickerProps = { onChange: (customer: ref_CustomersExtended) => void }
function CustomerPicker({ onChange }: CustomerPickerProps) {
    return <div style={{ width: 250, marginBottom: 5 }}>
        <VertexAutocomplete
            label="Customer"
            type={ref_CustomersExtended.name}
            getOptionLabel={(customer: ref_CustomersExtended) => customer['CompanyName']}
            params={{ properties: ["*", "Sources", "Billing", "Company.Name as CompanyName"] }}
            afterLoadFilter={(customers: ref_CustomersExtended[]) => customers.filter(c => c.Billing == "MediaManager")}
            onChange={onChange}
        />
    </div>
}

function LinkPicker({ onChange }) {
    return <div style={{ width: 250, marginBottom: 5 }}>
        <VertexAutocomplete
            label="lien"
            options={availableLinkes.map(l => l.name)}
            getOptionLabel={(link: string) => TradClassName(link)}
            onChange={onChange}
        />
    </div>
}

export default Template(DataLinkSimilarities);