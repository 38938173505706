import { AdwAutocomplete, VertexAutocomplete } from "adwone-lib";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Model } from "hub-lib/models/orientdb/ref_Model.bin";
import { ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { ref_ImportTsmLog } from "hub-lib/dto/admin/ref_ImportTsmLog";
import { ClassProperty } from "hub-lib/models/VertexProperty.bin";
import { clone, orderMetaDatas, toArray } from "hub-lib/tools.bin";
import * as React from "react";

import { Trad, TradClassName, TradProp } from "trad-lib";
import { IsMMUser } from "../../utils/localstorage.bin";
import { FiltersComponent as Filters } from "./Filters/FiltersComponent";
import { ePropType } from "hub-lib/models/VertexProperty.bin";

type Order<T> = {
    [key in "Dates" | "Hierarchy" | keyof T]?: ({ }: TPropsFilters<T>) => JSX.Element;
};

const shouldNotBeFiltered = new Set([ref_Model.name, ref_ImportTsmLog.name]);

type Filter<T> = {
    component: ({ }: TPropsFilters<T>) => JSX.Element;
    configKey: keyof T;
    linkedClass: string;
    params: any;
};

export type HideOptions = {
    Date?: true;
    Hierarchy?: true;
    Modele?: true;
    MapFilters?: true;
    AdvancedFilters?: true;
};

export type FiltersParamsValue = { options?: string[] } & { [key: string]: any }
export class TPropsFiltersComponents<T> {
    objectType: string;
    order?: (keyof Order<T>)[];
    filtersParams?: { [key in keyof Partial<T>]: FiltersParamsValue };
    additionalConfig?: Partial<T>;
    metaDatas: ClassProperty[] = [];
    onConfChange?: (state: Partial<T>) => void;
    hideOptions?: HideOptions;
}

class TState<T> {
    additionalFilters: Filter<T>[] = [];
    hideOptions: HideOptions = {};
}

export class FiltersComponent<T> extends React.Component<TPropsFiltersComponents<T>, TState<T>> {
    constructor(props: TPropsFiltersComponents<T>) {
        super(props);
        const newState = new TState<T>();
        newState.hideOptions = {
            Date: true,
            Hierarchy: true,
            Modele: true,
            MapFilters: true,
        };
        this.state = newState;
    }

    MMcheck(name: string | number | symbol): boolean {
        if (name === ref_Groups.name) {
            return !IsMMUser();
        }
        return true;
    }

    componentDidMount() {
        this.generateFilters();
    }

    generateFilters() {
        const { metaDatas, order, filtersParams, hideOptions: propHideOptions } = this.props;
        const { hideOptions } = this.state;
        const filters = [];
        const newOptions = clone(hideOptions);
        const orders = order ?? orderMetaDatas<T>(metaDatas, shouldNotBeFiltered);
        for (const o of orders) {
            const meta = metaDatas.find((m) => m.name === o);
            if (o === "Dates") {
                delete newOptions["Date"];
            } else if (o === "Hierarchy") {
                delete newOptions["Hierarchy"];
                delete newOptions["Modele"];
                delete newOptions["MapFilters"];
            } else if (meta && this.MMcheck(meta.linkedClass)) {
                if (meta.linkedClass) {
                    if (!meta.name.includes("ModelProperties"))
                        filters.push({
                            component: LinkedFilter,
                            configKey: o,
                            linkedClass: meta.linkedClass,
                            params: filtersParams?.[o],
                        });
                } else if(meta.type === ePropType.String) {
                    filters.push({
                        component: StringFilter,
                        configKey: o,
                        params: filtersParams?.[o],
                    });
                }
            }
        }
        this.setState({
            additionalFilters: filters,
            hideOptions: { ...newOptions, ...propHideOptions },
        });
    }

    render() {
        const { onConfChange, additionalConfig } = this.props;
        const { additionalFilters, hideOptions } = this.state;
        if (
            !additionalFilters.length &&
            hideOptions.Date &&
            hideOptions.Hierarchy &&
            hideOptions.MapFilters &&
            hideOptions.Modele
        ) {
            return <div>{Trad("no-filters")}</div>;
        }
        return (
            <>
                <Filters
                    objectType={this.props.objectType}
                    hide={{
                        AdvancedFilters: true,
                        MapFilters: hideOptions.MapFilters,
                        Modele: hideOptions.Modele,
                        Date: hideOptions.Date,
                        Hierarchy: hideOptions.Hierarchy,
                    }}
                />
                {!hideOptions.AdvancedFilters &&
                    additionalFilters.map((filter, key) => (
                        <filter.component
                            key={`additional-filter-${key}`}
                            config={clone(additionalConfig)}
                            onConfChange={onConfChange}
                            configKey={filter.configKey}
                            linkedClass={filter.linkedClass}
                            params={filter.params}
                        />
                    ))}
            </>
        );
    }
}

type TPropsFilters<T> = {
    onConfChange: (state: Partial<T>) => void;
    config: Partial<T>;
    configKey?: keyof T;
    linkedClass?: string;
    params?: { options?: string[] } & { [key: string]: any };
};

// function DatesFilter({ onConfChange: onConfChange, config: config }: TPropsFilters<any>) {
// return (
//     <div className="adw-row">
//         <DateRangepicker
//             defaultStart={config.Start}
//             defaultStop={config.End}
//             handleChangeDate={async (e: { start: Date; end: Date }) => {
//                 if (e.end) {
//                     config.Start = new Date(e.start);
//                     config.End = new Date(e.end);
//                     onConfChange(clearEmptyValues(config));
//                 }
//             }}
//         />
//     </div>
// );
// }

// function HierarchyFilter({ onConfChange: onConfChange, config: config }: TPropsFilters<any>) {
//     return (
//         <HierarchyComboManager
//             readonly={Boolean(config?.Group?.length)}
//             store={config}
//             multi={true}
//             onConfChanged={(filters) => onConfChange(clearEmptyValues(filters))}
//             fullWidth
//         />
//     );
// }

function LinkedFilter<T>({
    onConfChange,
    config,
    configKey,
    linkedClass,
    params,
}: TPropsFilters<T>) {
    return (
        <div className="adw-row">
            <VertexAutocomplete
                multiple
                nullOnClear
                params={{
                    properties: ["*"],
                    ...(params ?? {}),
                }}
                defaultValue={(options: IRid[]) => {
                    const array = toArray(config[configKey] as IRid);
                    return array?.map((g) => options.find((o) => o["@rid"] == g)).filter((e) => e) ?? [];
                }}
                type={linkedClass}
                label={TradClassName(linkedClass)}
                onChange={async (values: any) => {
                    config[configKey] = values?.map((v) => v["@rid"]);
                    onConfChange(config);
                }}
            />
        </div>
    );
}

function StringFilter<T>({
    onConfChange,
    config,
    configKey,
    params,
}: TPropsFilters<T>) {
    const value = config[configKey] as any;
    return <>
        {params?.options && <div className="adw-row">
            <VertexAutocomplete
                label={TradProp(configKey.toString())}
                multiple
                options={params?.options ?? []}
                defaultValue={ () => value}
                getOptionLabel={(option: string) => Trad(option)}
                onChange={(value: any) => {
                    config[configKey] = value;
                    onConfChange(config);
                }}
            />
        </div>}
    </>
}