import * as React from "react";
import { ADWColumn, AdwRow } from "adwone-lib/index";
import { Format } from "format-lib/index.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { RootState } from "../../../redux/store";
import { removeDiacritics } from "hub-lib/tools.bin";

export function CreateColumnLink<T>(objectPrototype: new () => T, bindingPath: ((keyof T) & string)) {
    const columnCustom = new ADWColumn<T>(TradProp(bindingPath, objectPrototype), bindingPath, ePropType.Link, true);
    columnCustom.cellValue = (cellValue: any, dataItem?: AdwRow<T>) => {
        const rid = dataItem.dataItem[bindingPath] as unknown as IRid["@rid"];
        return <CellLinkComponent
            rid={rid}
            onValueChanged={value => dataItem[`${bindingPath}_cellValue`] = removeDiacritics(value)} />
    }
    return columnCustom;
}

type CellLinkComponentArgs = { rid: IRid["@rid"], onValueChanged?: (value: string) => void }
function CellLinkComponent({ rid, onValueChanged }: CellLinkComponentArgs) {
    if (rid) {
        const storage = useSelector((root: RootState) => root.verticesStorage.store);
        const element = storage[rid];
        const formatedValue = Format(element);
        onValueChanged?.(formatedValue);
        return <span>{formatedValue}</span>
    }
    return <span></span>;
}

