import * as React from "react";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { initMessageCurrencyOptions } from "../../../../redux/messageEditorSlice";
import { GetEditorState, RootState, SetModel, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { WarningMM } from "./WarningMM";
import { vw_mm_HasCurrency } from "hub-lib/models/orientdb/vw_mm_HasCurrency.bin";
import { initCampaignCurrencyOptions } from "../../../../redux/campaignEditorSlice";

type CurrencyEditorArgs = { model: "ref_Messages" | "ref_Campaigns" }

export function CurrencyEditor({ model }: CurrencyEditorArgs) {

    const dispatch = useDispatch();
    const Currency = useSelector((root: RootState) => GetEditorState(model, root).get()?.Currency);
    const currencyOptions = useSelector((root: RootState) => GetEditorState(model, root).currencyOptions);
    const lockNext = useSelector((root: RootState) => GetEditorState(model, root).lockNext);

    React.useEffect(() => {
        if (currencyOptions === undefined)
            dispatch(model == "ref_Campaigns" ? initCampaignCurrencyOptions(undefined) : initMessageCurrencyOptions(undefined));
    }, [currencyOptions])

    const getElement = options => options?.find(o => o["@rid"] == Currency);
    const warning = currencyOptions && Currency && (getElement(currencyOptions) as any)?.warning;

    return <WarningMM ids={[{ id: Currency, linkClass: vw_mm_HasCurrency.name }]}>
        <FavoriteVertexAutoComplete
            key={`CurrencyEditor-${GetHashCode({ currencyOptions, Currency })}`}
            disableClearable
            disabled={lockNext}
            loading={lockNext}
            warning={warning}
            // enabledOnlyLoading={isLoading}
            label={TradProp("Currency", ref_Messages) + " *"}
            options={currencyOptions ?? []}
            defaultValue={getElement}
            onChange={async value => {
                if (Currency != value["@rid"]) {
                    const data = GetEditorState(model, store.getState()).get();
                    data.Currency = value["@rid"];
                    SetModel(model, data);
                }
                return value;
            }} />
    </WarningMM>
}