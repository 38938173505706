
import * as React from "react";
import { FormControl, TextField } from "@material-ui/core";
import { Trad } from "trad-lib";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { key1, key2,/*, key3*/
key4,
key5,
key6} from "./DashBoardGridLayout.bin";
import { EventEmitter } from 'events';
import { WidgetRemovedEvent } from "./CustomGridLayout.bin";
import { GetLayout } from "../../utils/localstorage.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";

export let WidgetAddedEvent: EventEmitter = new EventEmitter();

export class SearchWidgets extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: undefined,
            version: 0
        }
    }

    componentDidMount = () => {
        WidgetRemovedEvent.addListener("removed", this.forceRefresh);
    }

    componentWillUnmount = () => {
        WidgetRemovedEvent.removeListener("removed", this.forceRefresh);
    }

    forceRefresh = () => {
        let { version } = this.state;
        this.setState({ version: version + 1 });
    }

    render() {
        let { value, version } = this.state;

        let options = [
            { id: key1, label: Trad("Net_investment_by_advertiser") },
            { id: key2, label: Trad("Net_investment_by_media") },
            { id: key4, label: Trad("number_msg_status") },
            // { id: key5, label: Trad("direct_filter_configuration") }
            /*{ id: key3, label: Trad("Net_investment_by_advertiser") }*/];

        if (RightManager.hasRight(eFunctions.ref_ProjectConfigurations, eRights.read)) {
            options.push({ id: key6, label: Trad("direct_project_configuration") })
        }

        let layout = GetLayout();
        options = options.filter(op => !layout.find((lay: any) => lay.i === op.id))

        return (
            <FormControl
                key={`form-search-version-${version}`}
                variant="outlined"
                style={{ width: '100%' }}>
                <Autocomplete
                    placeholder={Trad("search_widget")}
                    options={options}
                    getOptionLabel={(op) => op.label}
                    noOptionsText={Trad("no_options")}
                    clearText={Trad("clear")}
                    onChange={(e: any, v: any) => {
                        WidgetAddedEvent.emit("added", v.id);
                        this.forceRefresh();
                    }}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={Trad("search_widget")}
                            variant="outlined"
                        />
                    )}
                />
            </FormControl>);
    }
}