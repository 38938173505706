import * as React from 'react'
import { CustomersGrid } from '../../VertexGrid/Adwone-admin/CustomersGrid.bin';
import Template from '../Template';

class Customers extends React.Component<any, any>{
    render() {
        return (
            <>
                <div className="grid_container">
                    <CustomersGrid/>
                </div>
            </>
        )
    }
}

export default Template(Customers);