import React, { useEffect } from 'react';
import Template from '../Template';
import { connect } from 'react-redux';
import { selectProject } from '../../../redux/projectSlice';
import history from "../../../utils/history";
import { Client } from 'hub-lib/client/client.bin';

const Grafana = ({ selectProject }: any) => {
    const [url, setUrl] = React.useState<string | null>(null);

    useEffect(() => {
        Promise.resolve().then(async () => {
            await Client.getGrafanaUrl().then(url => {
                console.log(url, "url")
                setUrl(url);
            })

        });
        const projectRid = new URLSearchParams(window.location.search).get("project");
        if (projectRid) {
            selectProject(projectRid);
            history.replace("/garafana");
        }
    }, [selectProject])
    return (
        <div className="">
            <iframe src={url} frameBorder="0" width="100%" height="900"></iframe>
        </div>
    );
};

export default connect(null, { selectProject })(Template(Grafana));

