import * as React from 'react'
import { extractSub, GetDuplicatedMessages, JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { setMessage } from "../../../../redux/messageEditorSlice";
import { GetEditorState, RootState, SetModel, store } from "../../../../redux/store";
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { TradProp } from 'trad-lib';
import { Grid } from '@material-ui/core';
import { changeEndValue } from './DiffusionEditor';
import { CalendarProps } from '@progress/kendo-react-dateinputs';
import { PublicationsEditor } from './PublicationsEditor';
import { getIcon } from 'adwone-lib';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { GenericTooltip } from '../../../ConfigurableComponents/GenericTooltip.bin';
import { ref_Publications } from 'hub-lib/dto/client/ref_Publications.bin';
import { DuplicatedMessagesTooltip } from '../../Messages/PublicationsCalendar/PublicationsCalendar.bin';

type StartEndEditorArgs = { model: "ref_Messages" | "ref_Campaigns", TSMCalendarEnable?: boolean }

export function StartEndEditor({ model, TSMCalendarEnable }: StartEndEditorArgs) {
    return <Grid container style={{ marginBottom: 0 }}>
        <Grid item xs={6} className="left_column" style={{ position: 'relative' }}>
            <StartEditor model={model} TSMCalendarEnable={TSMCalendarEnable} />
        </Grid>
        <Grid item xs={6} className="right_column">
            <EndEditor model={model} TSMCalendarEnable={TSMCalendarEnable} />
        </Grid>
    </Grid>
}

export function StartEditor({ model, TSMCalendarEnable }: StartEndEditorArgs) {
    const _start = useSelector((root: RootState) => GetEditorState(model, root).data.Start);
    const Start = _start ? new Date(_start) : null;
    let savedData = null;
    const saveData = async () => {
        if (!savedData) return;
        await changeEndValue(model, savedData);
        await SetModel(model, savedData);
        savedData = null;
    }
    return <>
        <SimpleDatePicker
            {...(TSMCalendarEnable ? { calendar: TSMModelCalendar(model) } : {})}
            key={`start_${Start?.toString()}`}
            label={`${TradProp("Start")} *`}
            defaultValue={Start ? new Date(Start) : null}
            onBlur={async (event) => {
                await saveData();
            }}
            onClose={async () => {
                await saveData();
            }}
            onChange={async ({ value }) => {
                const data = GetEditorState(model, store.getState()).get();
                data.Start = value;
                savedData = data;
            }} />
        {model == "ref_Messages" && <WarningDuplicatedMessage /> }
    </>
}

export function EndEditor({ model, TSMCalendarEnable }: StartEndEditorArgs) {
    const { Start, End } = useSelector((root: RootState) => extractSub(GetEditorState(model, root).get(), ["Start", "End"]) ?? {}, JSONEqualityComparer);
    const endDateValid = !Boolean(End) || (Boolean(Start) && Start.getTime() <= End.getTime());
    let savedData = null;
    const saveData = async () => {
        if (!savedData) return;
        await SetModel(model, savedData);
        savedData = null;
    }
    return <SimpleDatePicker
        valid={endDateValid}
        key={`end_${End?.toString()}`}
        label={TradProp("End")}
        defaultValue={End ? new Date(End) : null}
        onBlur={async (event) => {
            await saveData();
        }}
        onClose={async () => {
            await saveData();
        }}
        onChange={async ({ value }) => {
            const data = GetEditorState(model, store.getState()).get();
            data.End = value;
            savedData = data;
        }} />
}

export function TSMModelCalendar(model : "ref_Messages" | "ref_Campaigns") {
    return (props: CalendarProps) => <TSMCalendar model={model} {...props} />
}
export function TSMCalendar({model, onChange }: CalendarProps & { model: "ref_Messages" | "ref_Campaigns" }) {
    return <div style={{ width: 700 }}>
        <PublicationsEditor
            model={model}
            onChange={date => onChange?.({ value: date, target: { value: date }, syntheticEvent: null })} />
    </div>
}

export function WarningDuplicatedMessage() {

    const [duplicatedMessages, setDuplicatedMessages] = React.useState<ref_Messages[]>();
    const { Support, AdvertiserGroup, BroadcastArea, Currency, Start } = useSelector((root: RootState) => extractSub(root.messageEditor.get(), ["Support", "AdvertiserGroup", "BroadcastArea", "Currency", "Start"]) ?? {}, JSONEqualityComparer);

    React.useEffect(() => {
        if (!Support || !AdvertiserGroup || !BroadcastArea || !Currency || !Start) setDuplicatedMessages(null);
        else GetDuplicatedMessages({ Support, AdvertiserGroup, BroadcastArea, Currency, Start }).then(setDuplicatedMessages);
    }, [Support, AdvertiserGroup, BroadcastArea, Currency, Start?.getTime?.()]);

    return <>
        {duplicatedMessages?.length > 0
            && <GenericTooltip tooltipContent={() => <DuplicatedMessagesTooltip messages={duplicatedMessages} />}>
                <div className='warning-duplicate-icon'>{getIcon("warning")}</div>
            </GenericTooltip>}
    </>
}