import * as React from "react";
import { RootState } from "../../../redux/store";
import { ToolbarAdw } from "../Generic/ToolbarAdw";
import { useSelector } from "react-redux";
import { maxYearsRangeMessage, minDateMessage } from "tools-lib";

type MessagesToolbarArgs = { children: any };
export function MessagesToolbar({ children }: MessagesToolbarArgs) {
    const selectedItems = useSelector((state: RootState) => state.grid?.selectedItems);
    const length = useSelector((state: RootState) => state.grid?.data?.length);

    return (
        <ToolbarAdw
            includeFilters
            hasSelectedItems={selectedItems?.length > 0}
            count={length}
            min={minDateMessage}
            maxYearsRange={maxYearsRangeMessage}
        >
            {children}
        </ToolbarAdw>
    );
}
