import * as React from 'react'
import Button from '@material-ui/core/Button'

export class TProps {
    Label?: string | JSX.Element;
    style?:React.CSSProperties;
    className?: string
    disabled?: boolean;
    aria?: any;
    id?: any;
    onClick?: (event?:any) => any
    startIcon?: string | JSX.Element;
    endIcon?: string | JSX.Element;
}

export class CustomButton extends React.Component<TProps, any>{
    constructor(props: TProps){
        super(props)
    }
    render(){
        return(
            <Button variant="contained"
                aria-describedby={this.props.aria}
                disabled={this.props.disabled}
                id={this.props.id}
                className={this.props.className ?? "custom_btn_secondary"}
                onClick={this.props.onClick}
                style={this.props.style ?? {}}
                startIcon={this.props.startIcon}
                endIcon={this.props.endIcon}>
                {this.props.Label}
            </Button>
        )
    }
}