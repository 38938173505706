import { TimePickerProps, TimePicker as KendoTimePicker } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";

import * as React from "react";
import { ContainerComponent } from "../VertexGrid/Generic/ContainerComponent";
import { getIcon } from "adwone-lib/index";

export class TProps {
    resetTime?: () => void;
    id?: string;
    ignorePopup?: boolean;
    ignoreContainer?: boolean;
    popupAlign?: {
        horizontal: "left" | "center" | "right";
        vertical: "top" | "center" | "bottom";
    };
}

class TState {
    datePickerKey: number = 0;
}

export class TimePicker extends React.Component<TimePickerProps & TProps, TState> {
    constructor(props: TimePickerProps & TProps) {
        super(props);
        this.state = new TState();
    }
    _el: HTMLElement;
    render() {
        const { ignorePopup, ignoreContainer } = this.props;

        const content = (
            <div className="custom_daterangepicker-container">
                <div
                    ref={(el) => (this._el = el)}
                    className={`custom_daterangepicker`}
                    id={this.props.id ?? "custom_daterangepicker"}
                >
                    <KendoTimePicker
                        {...this.props}
                        label={null}
                        width={this.props.width ? this.props.width : "100%"}
                        className={`content-simpledatepicker ${this.props.className ?? ""}`}
                        {...(!ignorePopup && {
                            popup: (props) => (
                                <Popup
                                    {...props}
                                    popupAlign={this.props.popupAlign ?? props.popupAlign}
                                    appendTo={this._el}
                                />
                            ),
                        })}
                    />
                </div>
                {this.props.resetTime && (
                    <div
                        className="clear-date-button"
                        onClick={() => {
                            this.props.resetTime();
                            this.setState({ datePickerKey: this.state.datePickerKey + 1 });
                        }}
                    >
                        {getIcon("clear")}
                    </div>
                )}
            </div>
        );

        return (
            <>
                {ignoreContainer && content}
                {!ignoreContainer && (
                    <ContainerComponent title={this.props.label}>{content}</ContainerComponent>
                )}
            </>
        );
    }
}
