import * as React from "react";

import { AdwTelerikGrid } from "../Generic/AdwTelerikGrid.bin";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { SortDescriptor } from "@progress/kendo-data-query";
import { ADWColumn, AdwRow } from "adwone-lib/index";
import Loader from "../../layout/Loader";
import { UsersDialog } from "./UsersDialog.bin";
import { Trad, TradProp } from "trad-lib";
import { Notify } from "../../../utils/Notify.bin";
import { UserExtended } from "hub-lib/dto/referential/UserExtended.bin";
import { propertyOf, removeDiacritics } from "hub-lib/tools.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { Format, GetOrder, GetSort } from "format-lib/index.bin";
import { EditGridActions } from '../Cells/EditGridActions'
import { Client } from "hub-lib/client/client.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { createStyles, IconButton, LinearProgress, withStyles } from "@material-ui/core";
import { lnk_HasModule } from "hub-lib/models/orientdb/lnk_HasModule.bin";
import { GenericTooltip } from "../../ConfigurableComponents/GenericTooltip.bin";
import { ref_Qualifications } from "hub-lib/models/orientdb/ref_Qualifications.bin";
import { getIcon } from "adwone-lib/index";
import { BreadcrumbsCustomContainer, ToolbarAdw, ConfigurationPanelContainer, ToolbarActions } from "../Generic/ToolbarAdw";
import { BreadcrumbsCustom } from "../../BreadcrumbsCustom";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import { ConfigurationPanel } from "../Messages/ConfigurationPanel";
import { TooltipManager } from "../../CustomTooltip";
import { eDialogMode } from "../../ConfigurableComponents/GenericDialog.bin";
import { UsersGridFilters } from "./UsersGridFilters";
import { FilterStorage } from "../../../utils/localstorage.bin";
import { editCell } from "../../crossedTable/CrossedTableTelerikTree.bin";

class TState {
    editMode: boolean;
    userEdited?: UserExtended = new UserExtended();
    administrator?: UserExtended;
    grid?: VertexGrid<UserExtended>;
    mode?: eDialogMode;
    sort?: SortDescriptor[];
    contractLicences?: number;
    modulesFromContract?: lnk_HasModule[];
    AllocatedLicences?: number;
    qualificationFromLicences?: ref_Qualifications[];
    totalItems: number;
}
const BorderLinearProgress = withStyles(() =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: 'rgb(240,240,240)',
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#009BCE',
        },
    }),
)(LinearProgress);
export class UsersGrid extends React.Component<any, TState> {

    constructor(props: any) {
        super(props);
        Client.log({ Category: "navigation", Action: UserExtended.name });
        this.state = {
            editMode: false,
            totalItems: 0
        };
    }

    getLicences = async () => {
        const currentUserCached = (await Client.getUser()).user;
        if (currentUserCached.profileName != "super-administrateur") {
            const [currentUser] = (await Client.searchVertexTyped(UserExtended, { "@rid": currentUserCached["@rid"] })) as UserExtended[];
            const contractLicences = currentUser?.customer?.Licences;
            const modulesFromContract = currentUser?.customer?.lnkHasModule;
            const AllocatedLicences = currentUser?.customer?.AllocatedLicences;
            this.setState({ administrator: currentUser, contractLicences, modulesFromContract, AllocatedLicences });
        }
        else
            this.setState({ administrator: currentUserCached });
    }

    async componentDidMount() {

        const currentUserCached = (await Client.getUser()).user;
        await this.getLicences()
        let qualificationFromLicences = (await Client.searchVertex(ref_Qualifications.name, { Name: ["client", "prospect"] }))?.data?.results
        let columns: ADWColumn<UserExtended>[] = [];
        let hiddenProperties: string[] = [];

        /** Hide useless columns */
        hiddenProperties.push(propertyOf<UserExtended>("status"));
        hiddenProperties.push(propertyOf<UserExtended>("Rights"));
        hiddenProperties.push(propertyOf<UserExtended>("password"));
        hiddenProperties.push(propertyOf<UserExtended>("lastConnexion"));
        hiddenProperties.push(propertyOf<UserExtended>("roles"));
        hiddenProperties.push(propertyOf<UserExtended>("qualification"));
        hiddenProperties.push(propertyOf<UserExtended>("isOnline"));
        hiddenProperties.push(propertyOf<UserExtended>("name"));
        hiddenProperties.push(propertyOf<UserExtended>("phone"));
        hiddenProperties.push(propertyOf<UserExtended>("isDefaultPassword"));
        hiddenProperties.push(propertyOf<UserExtended>("Customers"));

        // add @rid for super admin
        if (currentUserCached.profileName == "super-administrateur")
            columns.push(new ADWColumn<UserExtended>("@rid", "@rid", ePropType.String, false));

        /** Column User */
        let columnperson = new ADWColumn<UserExtended>(TradProp("person", UserExtended), propertyOf<UserExtended>("person"), ePropType.Embedded, false);
        columnperson.cellValue = (cellValue: any, dataItem?: AdwRow<UserExtended>) => {
            const displayValue = `${dataItem.dataItem.person?.FirstName ?? "NoFirstName"} ${dataItem.dataItem.person?.LastName ?? "NoLastName"}`;
            dataItem['person_cellValue'] = removeDiacritics(displayValue);
            return <span>
                <span style={{ float: 'left', color: dataItem.dataItem.Active ? 'black' : "rgb(190, 190, 190)" }}>{displayValue}</span>
                <span style={{ float: 'right', marginRight: 25, marginTop: 2, height: 24 }}>
                    {(dataItem.dataItem.qualification && !qualificationFromLicences.some(e => e["@rid"] == dataItem.dataItem.qualification)) ? <GenericTooltip style={{ padding: 10 }} tooltipContent={Trad("user_not_include_into_licenceCount")}><span style={{ color: "rgb(190, 190, 190)" }}>{getIcon("error")}</span></GenericTooltip> : ''}
                </span>
            </span>
        }
        columnperson.width = 250
        columns.push(columnperson);
        hiddenProperties.push(propertyOf<UserExtended>("person"));

        /** profile */
        let columnprofile = new ADWColumn<UserExtended>(TradProp("profile", UserExtended), propertyOf<UserExtended>("profile"), ePropType.Link, false);
        columnprofile.cellValue = (cellValue: any, dataItem?: AdwRow<UserExtended>) => {
            dataItem['profile_cellValue'] = cellValue ? dataItem.dataItem.profileName : '';
            return <span className={dataItem.dataItem.Active ? `users_table_${dataItem['profile_cellValue']}` : `users_table_${dataItem['profile_cellValue']}_inactive`}>{dataItem['profile_cellValue']}</span>
        }
        columns.push(columnprofile);
        hiddenProperties.push(propertyOf<UserExtended>("profile"));

        /** company */
        let columnCompany = new ADWColumn<UserExtended>(TradProp("company", UserExtended), propertyOf<UserExtended>("company"), ePropType.Embedded, false);
        columnCompany.getValue = async (row: UserExtended) => Format(row.company);
        columns.push(columnCompany);
        hiddenProperties.push(propertyOf<UserExtended>("company"));

        let grid = new VertexGrid<UserExtended>({
            objectPrototype: UserExtended,
            devMode: false,
            columns,
            width: {
                person: 230,
                mail: 230,
                profile: 230,
                job: 280,
                company: 230
            },
            filterRows: r => r.company,
            vertexParams: {
                ...(FilterStorage.getLocalStorageValue("user-grid") ?? {}),
                // Active: true,
                properties: ["*"],
                excludeRights: true,
            },
            hiddenProperties,
            order: GetOrder<UserExtended>(UserExtended),
        });
        let sort = GetSort<UserExtended>(UserExtended);

        this.setState({
            grid,
            sort,

        })
    }

    error(msg: string) {
        Notify(msg, "error");
    }

    onValidateDialog = (u: UserExtended) => {

        Promise.resolve().then(res => {
            if (u["@rid"]) {
                //  update
                return Client.updateVertex(UserExtended.name, u).then(response1 => {
                    this.setState({ editMode: false });
                    return response1;
                }).catch(e => console.error(e));
            } else {
                // creation
                return Client.createVertex(UserExtended.name, u).then(response2 => {
                    this.setState({ editMode: false });
                    return response2;
                }).catch(e => console.error(e));
            }
        }).then(async res => { this.state.grid.UpdateRows(); await this.getLicences() })
    }
    modifyUser = async (row: AdwRow<UserExtended>, changeStatus?: boolean) => {
        if (changeStatus) {
            const afterChanged = async () => {
                this.state.grid.UpdateRows()
                Notify(!row.dataItem.Active ? Trad("user_active_success") : Trad("user_inactive_success"), "success");
                await this.getLicences()
            }

            const onError = async () => Notify(!row.dataItem.Active ? Trad("user_active_failed") : Trad("user_inactive_failed"), "error");

            if (row.dataItem.Active)
                Client.deleteVertex(UserExtended.name, row.dataItem["@rid"], false)
                    .then(() => afterChanged())
                    .catch(() => onError());
            else
                Client.updateVertex(UserExtended.name, { "@rid": row.dataItem["@rid"], Active: true }, false)
                    .then(() => afterChanged())
                    .catch(() => onError());
        } else {
            await this.getLicences()
            this.setState({ editMode: true, userEdited: row.dataItem, mode: eDialogMode.modify })
        }

    }

    render() {
        if (!this.state.grid)
            return <Loader />;
        let { editMode, totalItems, AllocatedLicences, modulesFromContract, contractLicences, administrator } = this.state;

        const confComponent = <ConfigurationPanel
            elements={[{
                type: "component",
                component: <IconButton
                    onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("create_user") })}
                    disabled={!RightManager.hasRight(eFunctions.User, eRights.create) || (administrator.profileName !== "super-administrateur" && AllocatedLicences >= contractLicences)}
                    className="custom_btn_primary no-radius no-shadow icon-panel-item"
                    onClick={() => this.setState({ editMode: true, userEdited: undefined, mode: eDialogMode.create })}>
                    {getIcon("plus")}
                </IconButton>
            }, {
                type: "icon",
                title: () => Trad("filters"),
                icon: "filterAlt",
                // badge: hasConfig(config) ? <Badge cutoutBorder align={{ vertical: "top", horizontal: "start" }} /> : null,
                element: <UsersGridFilters grid={this.state.grid} />
            }, {
                type: "icon",
                title: () => Trad("export"),
                icon: "download",
                element: <>
                    <div className="adw-row">
                        <CustomButton
                            Label={Trad("CSV")}
                            style={{ float: "right" }}
                            disabled={!RightManager.hasRight(eFunctions.User, eRights.create)}
                            className="custom_btn_primary"
                            onClick={() => this.state.grid.exportExcel("csv")} />
                    </div></>
            }
            ]} />

        return (
            <div style={{ width: '100%', position: "relative" }}>
                {editMode && <UsersDialog
                    administrator={administrator}
                    onValidate={this.onValidateDialog}
                    mode={this.state.mode}
                    open={true}
                    user={this.state.userEdited}
                    handleClose={() => this.setState({ editMode: false })} />}
                <ToolbarAdw count={totalItems}>

                    {administrator.profileName !== "super-administrateur" &&
                        <ToolbarActions>
                            <LicencesComponent
                                allocatedLicences={AllocatedLicences}
                                contractLicences={contractLicences}
                                modules={modulesFromContract} />
                        </ToolbarActions>}

                    <BreadcrumbsCustomContainer>
                        <BreadcrumbsCustom elements={[
                            { text: Trad("home"), href: "/" },
                            { text: Trad("users_mgt") }
                        ]} />
                    </BreadcrumbsCustomContainer>

                    <ConfigurationPanelContainer>
                        {confComponent}
                    </ConfigurationPanelContainer>
                </ToolbarAdw>
                <AdwTelerikGrid
                    grid={this.state.grid}
                    //onAddNew={() => this.setState({ editMode: true, userEdited: undefined, mode: eDialogMode.create })}
                    // onEdit={(row: AdwRow<UserExtended>) => this.modifyUser(row)}
                    isCopyDisable
                    commandCellArgs={{ isEditable: RightManager.hasRight(eFunctions.User, eRights.update) }}
                    commandCellWidth={editCell * 2}
                    customCommandCell={EditGridActions}
                    customCommandCellFunction={this.modifyUser}
                    onRowInitialized={(rows) => this.setState({ totalItems: rows?.length })}
                    sort={this.state.sort}
                    pluriCustom
                    hideToolbar
                />
            </div>
        )
    }
}

type LicencesComponentProps = {
    allocatedLicences: number;
    contractLicences: number;
    modules: lnk_HasModule[];
    width?: number | string;
}

export function LicencesComponent({ allocatedLicences, contractLicences, modules, width }: LicencesComponentProps) {
    return <div style={{ width: width ?? 200, lineHeight:1 }}>
        <div style={{ float: 'left' }}>{Trad("licences")}</div>
        <div style={{ float: 'right' }}>
            <span className="primary_color">{allocatedLicences}</span>
            <span style={{ color: "rgb(190,190,190)" }}>{`/${contractLicences}`}</span>
        </div>
        <GenericTooltip
            tooltipContent={
                <div className="tooltip" style={{ height: 20 + (20 * (modules?.length)) }}>
                    <div style={{ marginBottom: 20 }}>
                        <span style={{ float: "left", textAlign: "left" }}>Formule</span>
                        <span style={{ float: "right", fontWeight: "bold", marginRight: 10 }}>{`${allocatedLicences}/${contractLicences}`}</span>
                    </div>
                    {modules?.map((e, index, array) => {
                        const style = index === array.length - 1 ? { marginBottom: 20 } : {};
                        return <div key={`module-${e["@rid"]}`} style={style}>
                            <span style={{ float: "left", textAlign: "left" }}>{e["outName"]}</span>
                            <span style={{ float: "right", marginLeft: 12, marginRight: 10 }}>{`${e?.Users?.length}/${e.Licences}`}</span>
                        </div>
                    })}
                </div>
            }>
            <BorderLinearProgress variant="determinate" style={{ width: "100%" }} value={(allocatedLicences) * 100 / contractLicences} />
        </GenericTooltip>
    </div>
}