import React from 'react'
import { UsersGrid } from '../../VertexGrid/Admin/UsersGrid.bin';
import Template from '../Template'

const Users = () => {
    return (
        <>
            <div className="grid_container">
                <UsersGrid/>
            </div>
        </>
    );
}

export default Template(Users);