import React from 'react'
import Template from './Template'
import _403 from '../../utils/403.svg'
import { CustomButton } from '../ConfigurableComponents/CustomButton.bin'
import history from '../../utils/history'

const Unauthorized= () =>{
    return (
        <div className="grid_container">
            <div style={{width:550, textAlign:'center', margin:'150px auto 0 auto'}}>
                <img src={_403} alt={_403} style={{maxHeight:'100vh', height:150, marginBottom:40}}/>
                <h1>Erreur 403</h1>
                <p>Vous n'avez pas les droits pour accéder à cette page. Veuillez contacter un administrateur ou réessayer utltérieurement.</p>
                <CustomButton Label={"Retour à l'accueil"} className="custom_btn_primary" onClick={()=>{history.push('/')}}/>
            </div>
        </div>
    )
}

export default Template(Unauthorized)