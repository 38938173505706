
import * as React from 'react';

import { GanttProps } from '@progress/kendo-react-gantt/dist/npm/interfaces/GanttProps';
import { Gantt, GanttMonthView, GanttWeekView } from '@progress/kendo-react-gantt';
import { GanttHandle } from '@progress/kendo-react-gantt/dist/npm/Gantt';
import ReactDOM from 'react-dom';
import { GanttColumnProps } from '@progress/kendo-react-gantt/dist/npm/interfaces/GanttColumnProps';
import { Day, ZonedDate } from '@progress/kendo-date-math';
import { DateRange } from '@progress/kendo-react-gantt/dist/npm/interfaces/DateRange';
import { DateNoTime } from 'tools-lib';
import { getIcon } from "adwone-lib/index";
import { CustomIconButton } from '../VertexGrid/Generic/CustomIconButton';
import { PrintMesureTime, StartMeasureTime } from '../../utils/localstorage.bin';

let slotWidth = 24;

type CustomProps<T> = {
    start: Date;
    end: Date;
    viewMode?: "Day" | "Week";
    taskFomaters?: {
        month?: (d: Date) => any;
        week?: (d: Date) => any;
        day?: (d: Date) => any;
        year?: (m: number) => any;
    };
    columns: ({ frozen: boolean } & GanttColumnProps)[];
    selectable?: boolean;
    renderGroup?: (arg: T) => JSX.Element;
    renderEvent?: (arg: T) => JSX.Element;
}

class TState {
    viewMode: "Day" | "Week";
}

let timeout: any = null;

type TProp<T> = CustomProps<T> & GanttProps;
export class CustomGantt<T> extends React.Component<TProp<T>, TState> {

    dicoRows: { [prop: number]: T } = {};
    ref: GanttHandle;
    flatRows: T[] = [];
    Rows: T[];

    constructor(props: TProp<T>) {
        super(props);
        let { viewMode } = this.props;
        if (!viewMode)
            viewMode = "Day";
        this.state = {
            viewMode
        }
    }

    componentWillUnmount() {
        clearTimeout(timeout);
    }

    indexRows = (rows: T[]) => {
        this.dicoRows = {};
        this.Rows = rows;
        this.flatRows = [];

        let idx = 0;
        mapRecurse<any, T>(rows, this.props?.taskModelFields?.children ?? "Children",
            (row: T) => {
                this.dicoRows[idx] = row;
                idx++;
                return row;
            });

        let recurse = (data: T[]) => {
            data?.forEach(d => {
                this.flatRows.push(d);
                recurse((d as any).Children)
            })
        };
        recurse(rows);
    }

    render() {
        let { viewMode } = this.state;
        this.indexRows(this.props.taskData);

        let start = DateNoTime(this.props.start);
        let end = DateNoTime(this.props.end);
        end.setHours(23)

        let range: DateRange = {
            start,
            end,
            zonedStart: ZonedDate.fromUTCDate(start),
            zonedEnd: ZonedDate.fromUTCDate(end)
        }

        return <Gantt

            ref={(ref) => {
                if (ref?.element) {
                    this.ref = ref;

                    this.CustomizeDisplay();

                    if (viewMode === "Day")
                        this.DayView(ref);

                    // si viewMode === "Week"
                    // alors WeekView;
                }
            }}
            {...this.props}>
            {viewMode === "Day" && <GanttWeekView dateRange={range} workWeekStart={Day.Monday} workWeekEnd={Day.Sunday} slotWidth={slotWidth} />}
            {viewMode === "Week" && <GanttMonthView dateRange={range} slotWidth={slotWidth} />}
        </Gantt>
    }

    getComponent() {
        return this.ref;
    }

    /**
     * Custom Scheduler
     */
    private CustomizeDisplay() {

        const timeCustomizeDisplay = StartMeasureTime();

        if (this.props.renderEvent) {
            this.RenderComponent('k-task k-task-single', {
                className: "task-element-override-new k-task-single-override-new",
                style: { width: "0px", marginLeft: "-2px" }
            }, this.props.renderEvent);

            this.RenderComponent('k-task k-task-milestone', {
                className: "task-element-override-new k-task-milestone-override-new",
                style: {
                    width: "0px",
                    marginLeft: "-1px"
                }
            }, this.props.renderEvent);
        }

        if (this.props.renderGroup)
            this.RenderComponent('k-task k-task-summary', {
                className: "task-element-override-new k-task-summary-override-new",
                style: {
                    marginLeft: "-2px"
                }
            }, this.props.renderGroup, true);

        this.DrawHeaders();
        PrintMesureTime(timeCustomizeDisplay, "CustomizeDisplay");
    }


    /**
     * Render component inside targetClassName component
     * Also, apply params to elements
     * @param targetClassName
     * @param params
     * @param component
     */
    private RenderComponent(targetClassName: string, params: any, component: (row: T) => JSX.Element, checkSize?: boolean) {

        // not ready yet
        if (!this.ref)
            return;

        const elements = this.ref.element.getElementsByClassName(targetClassName);
        let array: Element[] = Array.from(elements);
        for (const element of array) {
            const anyElm: any = element;
            let recurse = (el: any, obj: any) => {
                Object.entries(el).forEach(([k, v]) => {
                    if (typeof v !== "object") obj[k] = v;
                    else if (obj[k] === undefined) obj[k] = v;
                    else recurse(v, obj[k]);
                });
            };
            recurse(params, element);

            const taskId = element.getAttribute('data-task-id');
            ReactDOM.render(component(this.dicoRows[Number(taskId)]), element);

            if (this.state.viewMode === "Day")
                setTimeout(() => {
                    const att = element.getAttribute('offsetadded');
                    if (!checkSize || att != "true")
                        anyElm.style.width = `${anyElm.offsetWidth + slotWidth}px`;
                    element.setAttribute('offsetadded', 'true')
                }, 1);
        }
    }

    /**
     * work on header
     */
    private DrawHeaders() {

        let nbVentils = this.props.columns?.filter(c => c.frozen)?.length ?? 0;

        if (this.props.selectable)
            nbVentils++;

        /**
         * prevent non-frozen headers to get over frozen headers when horizontal scrolling
         */
        let elements = this.ref.element.getElementsByClassName("k-header k-grid-header-sticky");
        let array = Array.from(elements);
        array.forEach((e: any, i) => {
            if (i >= nbVentils) {
                e.style['z-index'] = 5;
                e.style.right = `-10000px`;
                e.style.left = `-10000px`;
            } else {
                e.style['z-index'] = 6;
            }
        });

        /**
         * Lock all header on top, fixed
         */
        Array.from(this.ref.element.getElementsByClassName("k-header"))
            .forEach((e: any) => {
                e.style.top = 0;
                e.style.borderRightWidth = 0;
            });

        /**
         * Z-index on filter header
         */
        Array.from(this.ref.element.getElementsByClassName("k-filter-row"))
            .forEach((e: Element) => {
                Array.from(e.getElementsByTagName("th")).forEach((el: any, i) => {
                    if (i >= nbVentils) el.style['z-index'] = 2;
                    else el.style['z-index'] = 5;
                });
            });

        /** top style grid, correction décalage, filter row pas prise en compte  */
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            let headerElements = Array.from(this.ref.element.getElementsByClassName("k-grid-header"))[0] as HTMLScriptElement;
            let ganttCol = Array.from(this.ref.element.getElementsByClassName("k-gantt-columns"))[0] as HTMLScriptElement;
            if (!ganttCol) return;
            // let filterRow = Array.from(this.ref.element.getElementsByClassName("k-filter-row"))[0] as HTMLScriptElement;
            // if (filterRow) {
            const base = Number(ganttCol.style.top.split("px")[0])
            ganttCol.style.top = `${headerElements.offsetHeight}px`;
            ganttCol.style.cssText += ` min-width:${ganttCol.style.width} !important`;
            // }
        }, 500);

        /** min width */
        let layout = Array.from(this.ref.element.getElementsByClassName("k-table-layout-fixed"))[0] as HTMLScriptElement;
        layout.style.cssText += ` min-width:${layout.offsetWidth}px !important`;

        let filterrow = Array.from(this.ref.element.getElementsByClassName("k-filter-row"))[0] as HTMLScriptElement;
        if (filterrow)
            filterrow.style.height = "40px";
    }

    /**
     * Render DOM
     * @param element
     * @param start
     * @param className
     * @param format
     * @param increment
     */
    private RenderDom(element: Element, start: Date, end: Date, className: (d: Date) => string, format: (d: Date) => string, increment: (d: Date) => Date) {
        let current = new Date(start);
        element.className += " custom-granularity-header";
        Array.from(element.children).forEach((w) => {
            w.className = className(current);

            let lastDay = increment(new Date(current));
            lastDay.setDate(lastDay.getDate() - 1);
            if (lastDay.getTime() > end.getTime()) lastDay = end;
            let remains = Math.trunc((lastDay.getTime() - current.getTime()) / 1000 / 60 / 60 / 24) + 1;

            (w as any).colSpan = remains;

            let str = format(current);
            ReactDOM.render(<div>{str}</div>, w);
            current = increment(current);
        });
        current.setDate(current.getDate() - 1);
        return current;
    }

    private NewRenderDom(start: Date, end: Date, className: (d: Date) => string, format: (d: Date) => string, increment: (d: Date) => Date) {
        var tr = document.createElement('tr');
        tr.className = "custom-granularity-header";
        let current = new Date(start);
        while (current.getTime() < end.getTime()) {

            let td = document.createElement('td');
            td.className = className(current);
            tr.append(td);

            /** Get last day of periode */
            let lastDay = increment(new Date(current));
            lastDay.setDate(lastDay.getDate() - 1);

            if (lastDay.getTime() > end.getTime()) {
                lastDay = new Date(end);
            }

            let remains = Math.round((lastDay.getTime() - current.getTime()) / 1000 / 60 / 60 / 24) + 1;
            td.colSpan = remains;

            let strMonth = format(current);
            ReactDOM.render(<div>{strMonth}</div>, td);

            current = increment(current);
        }

        return tr;
    }

    private DayView = (ref: GanttHandle) => {
        const timeDayView = StartMeasureTime();

        let startDate = DateNoTime(this.props.start);
        let endDate = DateNoTime(this.props.end)

        let header = Array.from(ref.element.getElementsByClassName("k-grid-header"))[0]
        let firstTr = header.children[0];

        /** get times headers */
        let lastLine = firstTr.children[firstTr.childElementCount - 1];
        if (lastLine.className.includes('edit-column-cell'))
            lastLine = firstTr.children[firstTr.childElementCount - 2];

        if (!lastLine || !lastLine.childElementCount) return;

        let tbody = lastLine.children[0].children[0];
        if (!tbody) return;

        /** get existing header lines */
        let firstLineHeaders = tbody.children[0];
        let lastLineHeaders = tbody.children[1];

        /** write weeks */
        this.RenderDom(firstLineHeaders, startDate, endDate,
            d => "week-granularity-header",
            d => this.props.taskFomaters?.week ? this.props.taskFomaters.week(d) : `W ${getWeekNumber(d)}`,
            (d) => {
                d.setDate(d.getDate() + 7);
                return getMonday(d);
            });

        /** write days */
        this.RenderDom(lastLineHeaders, startDate, endDate,
            d => `day-granularity-header ${(d.getDay() == 6 || d.getDay() == 0) ? "k-nonwork-hour" : ""}`,
            d => this.props.taskFomaters?.day ? this.props.taskFomaters.day(d) : `Day${d.getDate()}`,
            d => { d.setDate(d.getDate() + 1); return d; });

        /** write months */
        let tr = this.NewRenderDom(startDate, endDate,
            d => "month-granularity-header",
            d => this.props.taskFomaters?.month ? this.props.taskFomaters.month(d) : `M${d.getMonth()}`,
            d => new Date(d.getFullYear(), d.getMonth() + 1, 1))

        let newElement = tbody.insertBefore(tr, firstLineHeaders);

        /** write years */
        tr = this.NewRenderDom(startDate, endDate,
            d => "year-granularity-header",
            d => d.getFullYear()?.toString(),
            d => new Date(d.getFullYear() + 1, 0, 1))

        tbody.insertBefore(tr, newElement);
        PrintMesureTime(timeDayView, "DayView");
    }
}

function mapRecurse<T, Y>(arg: T[], key: keyof T, fn: (element: T, deepth: number, parent: Y) => Y, deepth: number = 0, parent?: Y): Y[] {
    if (!arg) return null;
    let target: Y[] = arg.map((a) => fn(a, deepth, parent));
    target.forEach(a => (a as any)[key] = mapRecurse((a as any)[key], key, fn, deepth + 1, a));
    return target;
}

export function getWeekNumber(d: any) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    // Return array of year and week number
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}

export function getMonday(d: Date) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

type TEditProps<T> = {
    dataItem: T,
    isEditable?: (d: T) => boolean;
    onEdit: (d: T) => void;
    onCopy: (d: T) => void;
};
export class EditCell<T> extends React.Component<TEditProps<T>, any> {
    render() {

        const { dataItem, isEditable, onEdit, onCopy } = this.props;

        if (isEditable && !isEditable(dataItem))
            return <div></div>

        return (<div style={{ width: 100 }}>
            <CustomIconButton disabled={false} className="custom_btn_secondary " aria-label="edit" onClick={() => { onEdit(dataItem) }}>
                {getIcon("edit_inline")}
            </CustomIconButton>
            {<CustomIconButton className="primary_color" aria-label="edit" onClick={() => { onCopy(dataItem) }}>
                {getIcon("copy_inline")}
            </CustomIconButton>}
        </div>);
    }
}