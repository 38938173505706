import { ComputatedCascade, DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { Client } from "hub-lib/client/client.bin";
import { ref_Model } from "hub-lib/models/orientdb/ref_Model.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Offers } from "hub-lib/dto/client/ref_Offers.bin";
import { hasOwnProperty, propertyOf } from "hub-lib/tools.bin";
import { Trad } from "trad-lib";
import { Notify } from "./Notify.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";

export async function GetMessageOffers(message: ref_Messages, additionalParams?: any) {
    let offers: ref_Offers[] = [];
    if (message.BroadcastArea &&
        message.Media &&
        message.Start &&
        message.Support) {
        const model: ref_Model = (await Client.searchVertex(ref_Model.name, {
            [propertyOf<ref_Model>("DocumentType")]: "Offer",
            [propertyOf<ref_Model>("Country")]: message.BroadcastArea,
            [propertyOf<ref_Model>("MediaTypes")]: [message.Media]
        }))?.data?.results?.[0] as ref_Model;

        if (model)
            offers = (await Client.searchVertex(ref_Offers.name, {
                Support: message.Support,
                BroadcastArea: message.BroadcastArea,
                Media: message.Media,
                Model: model["@rid"],
                Start: message.Start,
                End: message.End,
                ...additionalParams
            }))?.data?.results;
    }
    return offers;
}

export async function SetPriceOffer(message: Pick<ref_Messages, "KPIs" | "DiscountMode" | "Discounts" | "PaidPlacement" | "PricingLocked" | "ModelProperties">,
    campaign: Pick<ref_Campaigns, "KPIs">,
    offer: Pick<ref_Offers, "KPIs" | "ModelProperties">, propertyName: string, notify: boolean = true) {
    let computatedCascade: ComputatedCascade = null;
    if (message.PricingLocked) return;
    if (offer) {
        let changed = message.KPIs.GrossVal != offer.KPIs.Gross;
        message.KPIs.GrossVal = offer.KPIs.Gross;

        if (propertyName == "Format" || message.PaidPlacement) {
            changed = changed || message.KPIs.Gross != offer.KPIs.Gross;
            changed = changed || message.KPIs.GrossBa != offer.KPIs.Gross;
            message.KPIs.Gross = offer.KPIs.Gross;
            message.KPIs.GrossBa = offer.KPIs.Gross;
        }
        message.KPIs.NetCO = offer.KPIs.Gross;
        message.KPIs.NetFO = offer.KPIs.Gross;
        message.KPIs.NetFOS = offer.KPIs.Gross;
        message.KPIs.TotalCO = offer.KPIs.Gross;
        message.KPIs.TotalFO = offer.KPIs.Gross;
        message.KPIs.TotalFOS = offer.KPIs.Gross;

        // if (hasOwnProperty(message.ModelProperties, "BudgetCommitment"))
        //     delete message.ModelProperties.BudgetCommitment;

        if (hasOwnProperty(offer.ModelProperties, "BudgetCommitment") && typeof offer.ModelProperties.BudgetCommitment == "number")
            message.ModelProperties.BudgetCommitment = offer.ModelProperties.BudgetCommitment;

        // if (!message.ModelProperties.OjdYear && hasOwnProperty(offer.ModelProperties, "Diffusion")) {
        //     message.KPIs['TotalCirculation'] = offer.ModelProperties.Diffusion;
        //     message.KPIs["Release"] = 0;
        //     message.KPIs["PaidCirculation"] = 0;
        //     message.KPIs["Subscriptions"] = 0;
        // }

        computatedCascade = await DiscountManager.UpdateCascade(message, campaign);
        if (notify && changed)
            Notify(Trad("price_has_been_updated"), "info");
    }
    return computatedCascade;
}

export async function SetBroadcastPriceOffer(message: Pick<ref_Messages, "KPIs">, offer: Pick<ref_Offers, "KPIs">, notify: boolean = true) {
    if (offer) {
        let changed = message.KPIs.BroadcastGross != offer.KPIs.Gross;
        message.KPIs.BroadcastGross = offer.KPIs.Gross;
        if (notify && changed)
            Notify(Trad("price_has_been_updated"), "info");
    }
}