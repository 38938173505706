import * as React from "react";
import { DateTimePicker, DateTimePickerProps } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";

import { getIcon } from "adwone-lib/index";
import { ContainerComponent } from "../VertexGrid/Generic/ContainerComponent";

export class TProps {
    resetDate?: () => void;
    id?: string;
    ignorePopup?: boolean;
    ignoreContainer?: boolean;
    popupAlign?: {
        horizontal: 'left' | 'center' | 'right';
        vertical: 'top' | 'center' | 'bottom';
    };
}
class TState {
    datePickerKey: number = 0;
}
export class SimpleDateTimePicker extends React.Component<DateTimePickerProps & TProps, TState> {
    constructor(props: DateTimePickerProps & TProps) {
        super(props);
        this.state = new TState();
    }
    _el: HTMLElement;
    render() {
        const { ignorePopup, ignoreContainer } = this.props;

        const content = <div className="custom_daterangepicker-container">
            <div
                ref={(el) => (this._el = el)}
                className={`custom_daterangepicker`}
                id={this.props.id ?? "custom_daterangepicker"}
            >
                {/* {this.props.label && <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled">{this.props.label}</label>} */}
                <DateTimePicker
                    {...this.props}
                    label={null}
                    width={this.props.width ? this.props.width : "100%"}
                    className={`content-simpledatepicker ${this.props.className ?? ""}`}
                    key={this.state.datePickerKey}
                    {...(!ignorePopup && { popup: (props) => <Popup {...props} popupAlign={this.props.popupAlign ?? props.popupAlign} appendTo={this._el} /> })}
                />
            </div>
            {this.props.resetDate && (
                <div
                    className="clear-date-button"
                    onClick={() => {
                        this.props.resetDate();
                        this.setState({ datePickerKey: this.state.datePickerKey + 1 });
                    }}
                >
                    {getIcon("clear")}
                </div>
            )}
        </div>

        return <>
            {ignoreContainer && content}
            {!ignoreContainer && <ContainerComponent title={this.props.label}>{content}</ContainerComponent>}
        </>

    }
}
