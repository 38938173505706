
export class ErrorRedirection extends Error {

    static Type = "redirection";
    static IsErrorRedirection = (error: any): error is ErrorRedirection => error?.type === ErrorRedirection.Type;

    type: string = ErrorRedirection.Type;
    url: string;

    constructor(url: string) {
        super();
        this.url = url;
    }
}