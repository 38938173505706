
import { TextField } from '@material-ui/core';
import { extractSub, GetHashCode, JSONEqualityComparer } from 'hub-lib/tools.bin';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Trad } from 'trad-lib';
import { GetEditorState, RootState, SetModelSync, store } from '../../../../redux/store';
import { TextEditor } from '../../TextEditor.bin';
import { ContainerComponent } from '../ContainerComponent';

type ModelPropertiesEditorArgs = { property: string, label?: string, model: "ref_Messages" | "ref_Campaigns", textEditor?: boolean, reloadProperties? : string[] }
export function ModelPropertiesEditor({ property, label, model, textEditor, reloadProperties }: ModelPropertiesEditorArgs) {

    let partialKey = {};
    if (reloadProperties?.length)
        partialKey = useSelector((root: RootState) => extractSub(GetEditorState(model, root).get(), reloadProperties as any) ?? {}, JSONEqualityComparer);
    const propertyValue = useSelector((root: RootState) => GetEditorState(model, root).data?.ModelProperties?.[property]);

    const onChange = (value: string) => {
        const data = GetEditorState(model, store.getState()).get();
        if (!data.ModelProperties)
            data.ModelProperties = {};
        data.ModelProperties[property] = value;
        SetModelSync(model, data);
    }

    if (textEditor)
        return <ContainerComponent title={label ?? Trad(`property_${property}`)} className="auto-height no-padding no-margin">
            <TextEditor
                key={`${property}_ ${GetHashCode(partialKey)}`}
                content={propertyValue}
                onChange={value => onChange(value)} />
        </ContainerComponent>
    return <TextField
        autoComplete='off'
        style={{ width: '100%' }}
        label={label ?? Trad(`property_${property}`)}
        value={propertyValue ?? ""}
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
    />
}