import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type LockedPricingProps = {
    children?: any;
    style?: React.CSSProperties;
};

export function LockedPricing({ children, style }: LockedPricingProps) {
    const isLocked = useSelector((root: RootState) => root.messageEditor.data.PricingLocked);

    const _style = style ?? {};

    if (!isLocked)
        return <div style={_style}>{children}</div>;

    return <div
        style={{ position: 'relative', ...style }}
        className="locked_pricing_container"
        onClick={e => e.stopPropagation()}>
        <div className="locked_pricing" />
        {children}
    </div>
}