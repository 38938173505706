import { ePropType } from "hub-lib/models/VertexProperty.bin";

// NOT SET
/*
    String,
    Binary,
    Embedded,
    EmbeddedList,
    EmbeddedSet,
    EmbeddedMap,
    Link,
    LinkList,
    LinkSet,
    LinkMap,
    Transient,
    Custom,
    LinkBag,
    Any
*/

let dicoFilter = {
    [ePropType.Boolean]: 'boolean',
    [ePropType.Integer]: 'numeric',
    [ePropType.Short]: 'numeric',
    [ePropType.Long]: 'numeric',
    [ePropType.Float]: 'numeric',
    [ePropType.Double]: 'numeric',
    [ePropType.Byte]: 'numeric',
    [ePropType.Decimal]: 'numeric',
    [ePropType.Datetime]: 'date',
    [ePropType.Date]: 'date'
}

type filterType = 'text' | 'numeric' | 'boolean' | 'date';

export function GetColumnType(type: ePropType): filterType {
    let value: any = (dicoFilter as any)[type];
    if (!value) value = 'text';
    return value;
}