import { ref_Companies, ref_CompaniesId } from "./ref_Companies.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Agencies, mm_AgenciesId } from "./mm_Agencies.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasAgencieId = rid;

export class vw_mm_HasAgencie extends ReferentialHasViews<src_MMId, mm_AgenciesId, ref_CompaniesId> {
	"@rid"?: vw_mm_HasAgencieId;

}