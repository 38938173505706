import * as React from 'react'
import Loader from '../../layout/Loader';
import { Trad, TradProp } from 'trad-lib';
import { ref_Customers } from 'hub-lib/models/orientdb/ref_Customers.bin';
import { VertexGrid } from '../Generic/VertexGrid.bin';
import { ADWColumn, AdwRow } from "adwone-lib/index";
import { AdwTelerikGrid } from '../Generic/AdwTelerikGrid.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { getIcon } from "adwone-lib/index";
import history from '../../../utils/history';
import { GetCellTemplate, GetOrder, GetSort } from 'format-lib/index.bin';
import { propertyOf } from 'hub-lib/tools.bin';
import { Client } from 'hub-lib/client/client.bin';
import { Notify } from '../../../utils/Notify.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { ref_CustomersExtended } from 'hub-lib/dto/referential/ref_CustomersExtended.bin'
import { IconButton, Tooltip } from '@material-ui/core';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';
import { UsersDialog } from '../Admin/UsersDialog.bin';
import { UserExtended } from 'hub-lib/dto/referential/UserExtended.bin';
import { eFunctions, eRights, RightManager } from 'hub-lib/models/types/rights.bin';
import { CustomIconButton } from '../Generic/CustomIconButton';
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from '../Generic/ToolbarAdw';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { ConfigurationPanel } from '../Messages/ConfigurationPanel';
import { TooltipManager } from '../../CustomTooltip';
import { eDialogMode } from '../../ConfigurableComponents/GenericDialog.bin';
import { LicencesComponent } from '../Admin/UsersGrid.bin';
import { EditGridActions } from '../Cells/EditGridActions';
import { GenericTooltip } from '../../ConfigurableComponents/GenericTooltip.bin';

class TState {
    grid?: VertexGrid<ref_CustomersExtended>;
    ridCompany: string
    success: "create" | "edited";
    openNewUser: boolean = false;
    company: ref_Companies = undefined;
    sort: any;
    totalItems: number
}
let modelCompany = [
    "@rid",
    "@class",
    "Adress",
    "City",
    "Country",
    "End",
    "Gln",
    "Name",
    "Siren",
    "Siret",
    "Start",
    "Tva",
    "ZipCode",
    "Active"
]
export class CustomersGrid extends React.Component<any, TState>{
    constructor(props: any) {
        super(props)
        const locationState = history.location.state as { success: "create" | "edited", createdCompany?: string } | undefined;
        let newState = new TState()
        if (locationState) {
            newState.ridCompany = locationState.createdCompany
            newState.success = locationState.success
        }

        this.state = newState
    }
    async componentDidMount() {
        const { success, ridCompany } = this.state
        let company = undefined
        switch (success) {
            case "create":
                Notify(Trad("customer_create_success"), "success");
                company = (await Client.searchVertex(ref_Companies.name, { "@rid": ridCompany }))?.data?.results?.[0]
                for (let [key] of Object.entries(company)) {
                    if (!modelCompany.includes(key)) {
                        delete company[key]
                    }
                }
                break;
            case "edited":
                Notify(Trad("customer_edited_success"), "success");
                break;
        }
        delete history.location.state
        history.replace("/customers", {});

        let columns: ADWColumn<ref_CustomersExtended>[] = [];
        let hiddenProperties: string[] = [];
        let properties: string[] = [];

        const actityColumn = new ADWColumn<ref_CustomersExtended>(TradProp(propertyOf<ref_CustomersExtended>("Activity"), ref_CustomersExtended), propertyOf<ref_CustomersExtended>("Activity"), ePropType.String, false);
        actityColumn.cellValue = (cellValue: string) => {
            return cellValue ? Trad(cellValue) : "";
        }
        columns.push(actityColumn);

        const licencesColumn = new ADWColumn<ref_CustomersExtended>(TradProp(propertyOf<ref_CustomersExtended>("Licences"), ref_CustomersExtended), propertyOf<ref_CustomersExtended>("Licences"), ePropType.Any, false);
        licencesColumn.cellValue = (cellValue: any, row?: AdwRow<ref_CustomersExtended>) => {
            const customer = row.dataItem;
            return <LicencesComponent
                width={"100%"}
                allocatedLicences={customer.AllocatedLicences}
                contractLicences={customer.Licences}
                modules={customer.lnkHasModule} />
        };
        columns.push(licencesColumn);

        /** Column Period */
        let columnperiod = new ADWColumn<ref_CustomersExtended>(Trad("validity_period"), propertyOf<ref_CustomersExtended>("Start"), ePropType.Any, false);
        columnperiod.getValue = async (row: ref_CustomersExtended) => {
            return `${row.Start ? GetCellTemplate(ePropType.Date)(row.Start) : ''} - ${row.End ? GetCellTemplate(ePropType.Date)(row.End) : ''}`;
        }

        /** Column Company */
        let columncompany = new ADWColumn<ref_CustomersExtended>(Trad("property_Company"), propertyOf<ref_CustomersExtended>("Company"), ePropType.Link, false);
        columncompany.cellValue = (cellValue: any, dataItem?: AdwRow<ref_CustomersExtended>) => {
            dataItem['Company_cellValue'] = dataItem.dataItem["CompanyName"] ?? "";
            if (dataItem.dataItem.Children.length === 0) {
                return (dataItem.dataItem as any)?.[`CompanyName`];
            } else {
                return (
                    <span>
                        {(dataItem.dataItem as any)?.[`CompanyName`]}
                        <div style={{ float: 'inline-end' }}>
                            <GenericTooltip
                                tooltipContent={() => <div>
                                    <p style={{ fontWeight: 'bold' }}>{Trad("companies_of_group")}</p>
                                    <ul style={{ paddingLeft: 10 }}>
                                        {dataItem.dataItem.Children
                                            .sort((a, b) => a["Name"].localeCompare(b["Name"]))
                                            .map((e, i) => <li key={`${i}-${e.Name}`}>{e.Name}</li>)}
                                    </ul>
                                </div>}>
                                <CustomIconButton className="customers_tooltip_companies_button">
                                    {getIcon("group")}
                                </CustomIconButton>
                            </GenericTooltip>
                        </div>
                    </span>
                )
            }
        }
        columncompany.width = 250
        columns.push(columnperiod);
        columns.push(columncompany)
        properties.push(`Company.Name as CompanyName`);
        hiddenProperties.push("Modules");
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Company"));
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Licences"));
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Sources"));
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Start"));
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Rights"));
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("End"));
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Billing"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Authorization"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("MediaManagerAPI"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("MasterDataKey"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("EstimatesAPI"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("FinancialAPI"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("MailsDomain"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Vpn"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("SSO"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Contract"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Comments"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("FormatedExports"))
        hiddenProperties.push(propertyOf<ref_CustomersExtended>("Activity"))

        const sort = GetSort<ref_CustomersExtended>(ref_CustomersExtended);
        const grid = new VertexGrid<ref_CustomersExtended>({
            objectPrototype: ref_CustomersExtended,
            devMode: false,
            columns,
            order: GetOrder<ref_Customers>(ref_Customers),
            vertexParams: {
                properties: ["*", ...properties]
            },
            hiddenProperties,
        });

        this.setState({
            grid,
            sort,
            openNewUser: this.state.success == "create" ? true : false,
            company: this.state.success == "create" ? company : undefined,
        })
    }
    modifyCustomerSetting = (row: AdwRow<ref_CustomersExtended>, changeStatus?: boolean) => {
        if (changeStatus) {
            Client.updateVertex(ref_CustomersExtended.name, { "@rid": row.dataItem["@rid"], Active: !row.dataItem.Active }, false).then(res => {
                this.state.grid.UpdateRows()
                Notify(!row.dataItem.Active ? Trad("customer_setting_active_success") : Trad("customer_setting_inactive_success"), "success");
            }).catch(e => Notify(!row.dataItem.Active ? Trad("customer_setting_active_failed") : Trad("customer_setting_inactive_failed"), "error"))
        } else
            history.push(`/edit_customer/${encodeURIComponent(row.dataItem["@rid"])}`)
    }

    onValidateDialog = (u: UserExtended) => {

        Promise.resolve().then(res => {
            if (u["@rid"]) {
                //  update
                return Client.updateVertex(UserExtended.name, u, false).then(response1 => {
                    Notify(`${Trad("user_modification_success")}: ${u.person.FirstName ?? ''} ${u.person.LastName ?? ''}`, "success");
                    this.setState({ openNewUser: false });
                    return response1;
                }).catch(e => {
                    let message_error = e?.response?.data?.error?.data?.message ?? ''
                    Notify(`${Trad("user_modification_failed")}: ${u.person.FirstName ?? ''} ${u.person.LastName ?? ''} \n ${Trad(message_error)}`, "error")
                });
            } else {
                // creation
                return Client.createVertex(UserExtended.name, u).then(response2 => {
                    this.setState({ openNewUser: false });
                    return response2;
                }).catch(e => {
                    let message_error = e?.response?.data?.error?.data?.message ?? ''
                    Notify(`${Trad("user_creation_failed")}: ${u.person.FirstName ?? ''} ${u.person.LastName ?? ''} \n ${Trad(message_error)}`, "error")
                });
            }
        }).then(res => this.state.grid.UpdateRows())
    }
    render() {
        let { grid, openNewUser, sort, totalItems } = this.state
        if (!grid)
            return <Loader />;
        const confComponent = <ConfigurationPanel
            elements={[{
                type: "component",
                component: <IconButton
                    onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("create_customer") })}
                    disabled={!RightManager.hasRight(eFunctions.ref_Customers, eRights.create)}
                    className="custom_btn_primary no-radius no-shadow icon-panel-item"
                    onClick={() => history.push('/new_customer')}>
                    {getIcon("plus")}
                </IconButton>
            }, {
                type: "icon",
                title: () => Trad("export"),
                icon: "download",
                element: <>
                    <div className="adw-row">
                        <CustomButton
                            Label={Trad("CSV")}
                            style={{ float: "right" }}
                            className="custom_btn_primary"
                            onClick={() => grid.exportExcel("csv")} />
                    </div></>
            }
            ]} />
        return (
            <div style={{ width: '100%' }}>
                <ToolbarAdw count={totalItems}>
                    <ConfigurationPanelContainer>
                        {confComponent}
                    </ConfigurationPanelContainer>
                    <BreadcrumbsCustomContainer>
                        <BreadcrumbsCustom
                            elements={[
                                { text: Trad("home"), href: "/" },
                                { text: Trad("customer_settings") }
                            ]} />
                    </BreadcrumbsCustomContainer>
                </ToolbarAdw>
                <AdwTelerikGrid
                    grid={this.state.grid}
                    sort={sort}
                    customCommandCell={EditGridActions}
                    customCommandCellFunction={this.modifyCustomerSetting}
                    pluriCustom
                    isDeleteDisable={!RightManager.hasRight(eFunctions.ref_Customers, eRights.delete)}
                    isCopyDisable={!RightManager.hasRight(eFunctions.ref_Customers, [eRights.create, eRights.update])}
                    commandCellArgs={{ isEditable: RightManager.hasRight(eFunctions.ref_Customers, eRights.update) }}
                    onRowInitialized={(rows) => this.setState({ totalItems: rows?.length })}
                />
                {openNewUser && <UsersDialog
                    onValidate={this.onValidateDialog}
                    administrator={undefined}
                    company={this.state.company}
                    fromCustomerPage={true}
                    mode={eDialogMode.create}
                    open={true}
                    user={undefined}
                    handleClose={() => this.setState({ openNewUser: false })} />}
            </div>
        )
    }
}