import { User, UserId } from "./User.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_FavoritesId = rid;

export class ref_Favorites extends V {
	"@rid"?: ref_FavoritesId;
	Active: boolean;
	Owner: UserId;
	Table: string;
	TableIds: VId[];

}