import { Grid, TextField } from "@material-ui/core";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import React from "react";
import { Trad, TradProp } from "trad-lib";
import { CustomNumericTextBox } from "../Generic/Common.bin";
import { clone, extractSub, JSONEqualityComparer, Typed } from "hub-lib/tools.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useSelector } from "react-redux";
import { setCampaignSync } from "../../../redux/campaignEditorSlice";
import { RootState, store } from "../../../redux/store";
import { CampaignPerformanceArgs, PerformanceAddButton, PerformanceGrid, PerformanceModeContainer, propertyDescriptorType } from "./CampaignPerformanceTools";
import { CustomBadge } from "../Filters/MenuItemFilters";
import { GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";

type CampaignPerformances = { medias: ref_Media[] }
export function CampaignPerformances({ medias }: CampaignPerformances) {

    const [selected, setSelected] = React.useState<number>(0);
    const campaign = useSelector((root: RootState) => extractSub(root.campaignEditor.get(), ["KPIsMedia", "Performances"]), JSONEqualityComparer);

    const generateTabLabel = (label: string, key: string) => {
        const timePerformances = campaign.Performances[key]?.TimePerformance;
        let hasValue = false;
        if (timePerformances)
            hasValue = timePerformances.some(p => Object.keys(p.Performances).some(k => p.Performances[k].done != 0 || p.Performances[k].objective != 0));
        return <div className='badge-template'>
            {hasValue && <div className='badge-template-icon'><CustomBadge /></div>}
            <span>{label}</span>
        </div>;
    }
    if (!campaign.Performances || Object.keys(campaign.Performances).length <= 0)
        return <></>
    return <TabStrip className="tabpanel_fullwidth" selected={selected} onSelect={e => setSelected(e.selected)}>
        {medias && Object.keys(campaign.Performances)
            .map(mediaId => {
                const media = medias.find(m => m['@rid'] == mediaId);
                if (!media) return <></>;
                return <TabStripTab key={`performances_${mediaId}`} title={generateTabLabel(Trad(media.Name), mediaId)} >
                    <>
                        <Grid item xs={12} className="adw-title"></Grid>
                        <CampaignPerformance
                            kpis={campaign.KPIsMedia?.[mediaId]}
                            performances={campaign.Performances[mediaId]}
                            onChange={(field, value) => {
                                const data = store.getState().campaignEditor.get();
                                data.Performances[mediaId][field] = value;
                                store.dispatch(setCampaignSync(data));
                            }}
                        />
                    </>
                </TabStripTab>
            })}
    </TabStrip>
}

const propertyDescriptor = (field: keyof ref_Campaigns['Performances'][0], type: "number" | "string", isEditable: boolean = true) => (Typed<propertyDescriptorType>({
    field,
    type,
    isEditable
}));

const campaignPropertiesBase = [
    propertyDescriptor("wave", "string"),
    propertyDescriptor("target", "string"),
    /*propertyDescriptor("GRP", "number"),
    propertyDescriptor("contacts", "number"),
    propertyDescriptor("coverage", "number"),
    propertyDescriptor("coverage_thousands", "number"),
    propertyDescriptor("repetition", "number")*/
]

export function CampaignPerformanceGrid(performanceArgs: CampaignPerformanceArgs) {
    const { performances } = performanceArgs;
    const defaultKPIs = ["GRP", "coverage", "CGRP"];
    const optionalKPIs = ["contacts", "coverage_thousands", "repetition"];
    const totalPerformance = performanceArgs.performances.TimePerformance?.find(tp => tp.time == "total")?.Performances;
    const activeKPIs = totalPerformance ? Object.keys(totalPerformance)?.filter(key => !defaultKPIs.includes(key)) : [];
    const [userKpis, setUserKpis] = React.useState<string[]>(activeKPIs);
    const [confirmChange, setConfirmChange] = React.useState<{ mode?: string, kpi?: string }>(null);

    const ChangeMode = (mode: string, verifyData: boolean = true) => {
        if (verifyData) {
            let hasDeletedData = false;
            if (performances.TimeMode != "total")
                hasDeletedData = performances.TimePerformance?.some(p => p.time != "total" && Object.keys(p.Performances).some(k => p.Performances[k].done != 0 || p.Performances[k].objective != 0));
            if (hasDeletedData) {
                setConfirmChange({ mode: mode });
                return;
            }
        }
        performanceArgs.onChange("TimeMode", mode);
    }

    const ChangeUserKPIs = (kpi: string, verifyData: boolean = true) => {
        if (verifyData) {
            let hasDeletedData = false;
            if (userKpis.includes(kpi))
                hasDeletedData = performances.TimePerformance?.some(p => p.Performances[kpi].done != 0 || p.Performances[kpi].objective != 0);
            if (hasDeletedData) {
                setConfirmChange({ kpi: kpi });
                return;
            }
        }
        let oldKPIs = clone(userKpis);
        if (!oldKPIs.includes(kpi))
            oldKPIs.push(kpi);
        else
            oldKPIs = oldKPIs.filter(k => k != kpi);
        setUserKpis(oldKPIs);
    }

    return <>
        <GenericDialog
            open={confirmChange != null}
            dialogTitle={Trad('confirmation')}
            handleClose={() => setConfirmChange(null)}
            submitAction={() => {
                if (confirmChange.mode)
                    ChangeMode(confirmChange.mode, false);
                if (confirmChange.kpi)
                    ChangeUserKPIs(confirmChange.kpi, false);
                setConfirmChange(null);
            }}
            submitClass="custom_btn_danger"
            submitTitle={Trad('yes')}
            actions={true}>
            <p>
                {confirmChange?.mode && Trad("deleted_data_confirmation_mode_" + confirmChange.mode)}
                {confirmChange?.kpi && Trad("deleted_data_confirmation_kpi")}
            </p>
        </GenericDialog>
        <div className='message_details_full'>
            <Grid container direction="row-reverse" style={{ paddingLeft: 10, zIndex: 10 }}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6} className="message_details_left">
                            <PerformanceModeContainer mode={performanceArgs.performances.TimeMode ?? "total"}
                                onChange={mode => ChangeMode(mode)} />
                        </Grid>

                        <Grid item xs={6} className="message_details_right" style={{ position: "relative" }}>
                            <Grid container>
                                <Grid item style={{ position: "absolute", right: 0 }}>
                                    <PerformanceAddButton
                                        kpis={optionalKPIs}
                                        activeKpis={activeKPIs}
                                        onClick={(value) => ChangeUserKPIs(value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        <div className='message_details_full'>
            <PerformanceGrid timeKpis={[...defaultKPIs, ...userKpis]} {...performanceArgs} />
        </div>
    </>
}

export function CampaignPerformance(props: CampaignPerformanceArgs) {

    const { performances, kpis, campaignPropertiesFilter, activeCGrp, onChange } = { ...new CampaignPerformanceArgs, ...props };
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const campaignProperties = campaignPropertiesBase.filter(campaignPropertiesFilter ?? (() => true));

    //const cgrpGross = (kpis?.Gross ?? 0) / (performances?.GRP || 1);
    //const cgrpNetCO = (kpis?.NetCO ?? 0) / (performances?.GRP || 1);
    return <>
        <Grid container className='block-container'>
            {campaignProperties.map((property, i) =>
                <Grid key={`${property.field}-${i}`} item xs={6} className={i % 2 ? "message_details_rightcombo" : "message_details_leftcombo"}>
                    {property.type === "number"
                        ? <NumberEditor
                            value={performances[property.field] as any}
                            label={Trad(property.field)}
                            onChange={(newValue: any) => { onChange(property.field, newValue); forceUpdate?.(); }} />
                        : <TextEditor
                            value={performances[property.field] as any}
                            label={Trad(property.field)}
                            onChange={(newValue: any) => { onChange(property.field, newValue); forceUpdate?.(); }} />}
                </Grid>)}

            {/*activeCGrp
                && <>
                    <Grid item xs={6} className={campaignProperties.length % 2 ? "message_details_rightcombo" : "message_details_leftcombo"}>
                        <NumberEditor value={cgrpGross} label={TradProp('cgrpGross')} disabled />
                    </Grid>

                    <Grid item xs={6} className={(campaignProperties.length + 1) % 2 ? "message_details_rightcombo" : "message_details_leftcombo"}>
                        <NumberEditor value={cgrpNetCO} label={TradProp('cgrpNetCO')} disabled />
                    </Grid>
                </>*/}

        </Grid>
        <CampaignPerformanceGrid {...props} />
    </>
}

class NumberEditorArgs { label: string; value: number; onChange?: (value: number) => void; disabled?: boolean = false }
function NumberEditor(props: NumberEditorArgs) {
    const { label, value, onChange, disabled } = { ...new NumberEditorArgs(), ...props };
    return <CustomNumericTextBox
        disabled={disabled}
        min={0}
        title={label}
        value={value}
        onChange={(e) => {
            const value = e.value;
            onChange?.(value);
        }} />
};

type TextEditorArgs = { label: string, value: string, onChange: (value: string) => void }
function TextEditor({ label, value, onChange }: TextEditorArgs) {
    return <TextField
        // key={modelInfo.Name}
        // disabled={_isDisable}
        autoComplete='off'
        style={{ width: '100%' }}
        label={label}
        type={"Text"}
        value={value}
        variant="outlined"
        onChange={(e) => {
            onChange(e.target.value);
        }}
        InputLabelProps={{ shrink: true }}
    />
};
