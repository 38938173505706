import * as React from 'react'
import { Redirect } from 'react-router-dom'
import Loader from '../layout/Loader';
import { Client } from 'hub-lib/client/client.bin'
import { SetCurrentLocale } from 'trad-lib';
import history from '../../utils/history'
import { RootState, store } from '../../redux/store';
import { initialize } from '../../redux/projectSlice';
import { initializeConfigurations } from '../../redux/gridConfigurationsSlice';
import { useSelector } from 'react-redux';

export default function withAuth(ComponentToProtect: any, permission?: () => boolean) {
    return (props) => {

        const [loading, setLoading] = React.useState(true);
        const [redirect, setRedirect] = React.useState(false);
        const isMounted = React.useRef(false);
        const columsConfigurationsInitialized = useSelector((root: RootState) => root.columsConfigurations.isInitialized);
        const projectInitilized = useSelector((root: RootState) => root.project.isInitialized);
        const path = window.location.pathname;
        const encodedRedirection = encodeURIComponent(window.location.pathname + window.location.search);
        const redirectionArgs = (path.length > 1) ? `?redirect=${encodedRedirection}` : '';

        React.useEffect(() => {
            if (isMounted.current)
                return;
            isMounted.current = true;
            (async () => {
                try {
                    const res = (await Client.getStatus()).data
                    console.log(res);
                    if (permission && !permission())
                        return history.push('/')

                    if(res.isTwoFactorAuthPending){
                        return history.push(`/two_factor_auth${redirectionArgs}`);
                    }

                    localStorage.setItem('user', JSON.stringify(res.user));
                    if (!projectInitilized)
                        store.dispatch(initialize());
                    if (!columsConfigurationsInitialized)
                        store.dispatch(initializeConfigurations());

                    SetCurrentLocale(res.locale);
                    setLoading(false);
                } catch (error) { 
                    console.error(error);
                    localStorage.removeItem('user');
                    setLoading(false);
                    setRedirect(true);
                }
            })();

        }, [isMounted.current]);

        if (loading)
            return <Loader />

        if (redirect)
            return <Redirect to={`/login${redirectionArgs}`} exact />

        if (!columsConfigurationsInitialized || !projectInitilized)
            return <></>

        return <ComponentToProtect {...props} />

    }
}