import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { GenericTooltip, GenericTooltipProps } from "../../../ConfigurableComponents/GenericTooltip.bin";
import { Trad } from "trad-lib";
import { netType } from "hub-lib/business/DiscountManager.bin";
import { getIcon } from "adwone-lib";
import { GetDiscountRate } from "./GetDiscountRate";
import { GetDiscountValue } from "./GetDiscountValue";

type TooltipInfoProps = { tooltipContent: GenericTooltipProps['tooltipContent'] };
const TooltipInfo = ({ tooltipContent }: TooltipInfoProps) =>
    <GenericTooltip tooltipContent={tooltipContent} contentClassName="primary_color" contentStyle={{ display: "flex" }}>
        {getIcon("info")}
    </GenericTooltip>

type AgreementValueComparerProps = { discount: ref_Discount, formater?: (value: number) => string, type: 'rate' | 'amount' };
export function AgreementValueComparer({ discount, type, formater }: AgreementValueComparerProps) {

    const netType = useSelector((root: RootState) => root.messageEditor.currentNetType);
    const currentAgreement = useSelector((root: RootState) => root.messageEditor.currentAgreement);
    const style: React.CSSProperties = {
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translate(0%, -50%)"
    };

    // no agreement applied on this message
    if (!currentAgreement)
        return <></>

    // this discount is not from the agreement
    if (discount.Agreement != currentAgreement["@rid"])
        return <></>

    const discountAgreement = currentAgreement?.Discounts?.find(d => d.DiscountClass == discount.DiscountClass);

    // no agreement for this discount
    if (!discountAgreement)
        return <></>

    // agreement is rate and we want to compare amount
    if (discountAgreement.IsRate && type == 'amount')
        return <></>

    // agrreement discount type is different from current discount type
    if (discountAgreement.IsRate != discount.IsRate)
        return <div style={style}>
            <TooltipInfo tooltipContent={`${Trad('agreement_rate_type_change')}`} />
        </div>;

    let getter: (discount: ref_Discount, cofo: netType) => number = null;
    switch (type) {
        case 'rate':
            getter = GetDiscountRate;
            break;
        case 'amount':
            getter = GetDiscountValue;
            break;
        default:
            throw new Error(`Invalid type: ${type}`);
    }

    const agreementValue = getter(discountAgreement, netType);
    const valueElement = getter(discount, netType);

    return <>{discountAgreement?.IsRate
        && agreementValue != null
        && agreementValue != valueElement
        && <div style={style}>
            <TooltipInfo tooltipContent={`${Trad('agreement_rate')}: ${(formater?.(agreementValue) ?? agreementValue) + '%'}`} />
        </div>}
    </>
}