import React from 'react';
import { GetCellTemplate } from 'format-lib/index.bin';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { SelectorDiscountComputation } from './SelectorDiscountComputation';
import { ref_Discount } from 'hub-lib/models/types/vertex.bin';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

type ComputationBaseCellComponentProps = { discount: ref_Discount };
export function ComputationBaseCellComponent({ discount }: ComputationBaseCellComponentProps) {
    const template = GetCellTemplate(ePropType.Double);
    const currencyCode = useSelector((state: RootState) => state.messageEditor.currencyCode);
    const value = SelectorDiscountComputation(discount.DiscountClass)?.computation?.Base ?? 0;
    return <div>{template(value) + ` ${currencyCode ?? ''}`}</div>
}