import * as React from "react";
import { Trad } from 'trad-lib';
import { ref_ReleaseVersion } from "hub-lib/dto/admin/ref_ReleaseVersion.bin";
import { eDialogMode, GenericDialog, GenericDialogProps } from "../../ConfigurableComponents/GenericDialog.bin";
import { Provider } from "react-redux";
import {  store } from "../../../redux/store";
import { Row, Title } from "../../Tools";
import { StyleFactory } from "../../../utils/ToolsReact";
import { VersionMetrics } from "./VersionMetrics";
import { Client } from "hub-lib/client/client.bin";
import { Notify } from "../../../utils/Notify.bin";

const overviewWidth = '350px';

const StylesBase = StyleFactory.create({
    column: {
        flex: overviewWidth,
        position: 'relative',
        height: '100%',
        overflow: 'auto'
    },
});

const Styles = StyleFactory.create({
    layout: { display: 'flex', height: '100%' },
    columnOverview: {
        ...StylesBase.column,
        flex: overviewWidth,
        background: '#DBF1F8'
    },
    columnContent: {
        ...StylesBase.column,
        flex: `calc(100% - ${overviewWidth})`,
    }
});

export class TProps {
    open: boolean;
    mode: eDialogMode;
    handleClose?: () => void;
    version: ref_ReleaseVersion
    // onValidate: (m: ref_ReleaseVersion) => any;
}

class TState {
    versions: ref_ReleaseVersion[];
    disableCreateButton: boolean = false
    validationMode: boolean = false;
    key?: number;
    needReload?: boolean;
}
let dicoCreateTrad: any = {}

type DialogRender = {
    title?: string | JSX.Element,
    children?: any,
    dialogProps?: Partial<GenericDialogProps>
}
function DialogRender({ title, children, dialogProps }: DialogRender) {
    return <GenericDialog
        open={dialogProps?.open}
        {...dialogProps}
        disablePrimaryButton={dialogProps?.disablePrimaryButton}
        disableCancelButton={dialogProps?.disableCancelButton}
        dialogTitle={title}
        actions={true}
        submitClass={'custom_btn_primary_validation'}
        titleClass='display-none'
        id="messages_dialog_createupdate"
        dialogClassname="messages"
        dialogContent={<Provider store={store}>
            {children}
        </Provider>
        }
    >
    </GenericDialog>
}

export function ReleaseVersionDialog({ open, version, mode, handleClose }: TProps) {

    const [state, setState] = React.useState<TState>();
    dicoCreateTrad = {
        [eDialogMode.create]: Trad("create"),
        [eDialogMode.modify]: Trad("modify"),
    }
    const handleNext = async () => {
        let created
        let updated
        try {
            if (mode == 'create') {
               created = await Client.createVertex(ref_ReleaseVersion.name, state);
            } else {
               updated = await Client.updateVertex(ref_ReleaseVersion.name, state);
            }
            if(created || updated ) window.location.reload()
        } catch (e: any) {
            let message_error = e?.response?.data?.error?.data?.message ?? ''
            Notify(`${Trad("user_creation_failed")}:} \n ${Trad(message_error)}`, "error")
        }
    };
    const handleData = (data) => {
        setState(data)
    }
    return <DialogRender
        dialogProps={{
            open: open,
            handleClose: () => { handleClose(); },
            submitAction: handleNext,
            disablePrimaryButton: false,
            disableCancelButton: false,
            submitTitle: dicoCreateTrad[mode],
        }}>
        <div className='message_dialog' style={Styles.layout}>
            <div className='message_dialog_content' style={Styles.columnContent} >
                <div id='metricspicker'>
                    <Title>{Trad('Create a patch note')}</Title>
                    <Row><VersionMetrics handleData={handleData} version={version} /></Row>
                </div>
            </div>
        </div>
    </DialogRender>
}