import { V } from "hub-lib/models/orientdb/V.bin";
import { eGroupCategories, GroupClassList, ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { duplicate, propertyOf } from "hub-lib/tools.bin";
import * as React from "react";
import { Trad, TradProp } from "trad-lib";
import { BreadcrumbsCustom } from "../../BreadcrumbsCustom";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import { eDialogMode, ConfirmContent, GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import {
    ToolbarAdw,
    SelectedItemsContainer,
    BreadcrumbsCustomContainer,
    ConfigurationPanelContainer,
    ToolbarContainer,
} from "../Generic/ToolbarAdw";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import GroupsTab from "./GroupsTab.bin";
import { RightManager, eFunctions, eRights } from "hub-lib/models/types/rights.bin";
import { TooltipManager } from "../../CustomTooltip";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { ConfigurationPanel } from "../Messages/ConfigurationPanel";
import { addRecord, clear, DataTree, remove } from "../../../redux/treeSlice";
import { connect, useSelector } from "react-redux";
import { IsMMUser } from "../../../utils/localstorage.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { RootState } from "../../../redux/store";

const calcHeight = "calc(100vh - 198px)";

const defaultItem: ref_Groups = {
    Active: true,
    Name: "",
    Category: eGroupCategories.MediaFamily,
    Of: {
        Class: V.name,
    },
    IDs: [],
};

class TState {
    item: ref_Groups = undefined;
    dialogOpen: boolean = false;
    dialogRMOpen: boolean = false;
    mode: eDialogMode;
    selectedItems: DataTree[] = [];
    selected: number = 0;
}

function GroupsToolBar({ selectedItems, confComponent, onRemoveClick }) {
    const dataLength = useSelector((root: RootState) => root.tree.data?.length ?? 0);
    return <ToolbarAdw hasSelectedItems={selectedItems?.length > 0} count={dataLength}>
        <ToolbarContainer>
            {selectedItems?.length > 0 && (
                <SelectedItemsContainer>
                    <div style={{ display: "inline" }}>
                        <CustomButton
                            Label={Trad("delete")}
                            style={{ float: "right" }}
                            className="custom_btn_danger"
                            startIcon={getIcon("delete")}
                            onClick={onRemoveClick}
                        />
                    </div>
                </SelectedItemsContainer>
            )}
            <BreadcrumbsCustomContainer>
                <BreadcrumbsCustom
                    hasSelectedItems={selectedItems?.length > 0}
                    elements={[{ text: Trad("home"), href: "/" }, { text: Trad("groups") }]}
                />
            </BreadcrumbsCustomContainer>
        </ToolbarContainer>
        <ConfigurationPanelContainer>{confComponent}</ConfigurationPanelContainer>
    </ToolbarAdw>
}

class GroupsTree extends React.Component<any, TState> {
    constructor(props: any) {
        super(props);
        const newstate = new TState();
        newstate.item = duplicate(defaultItem);
        this.state = newstate;
    }

    componentDidMount() {
        this.props.clear(GroupClassList[0].Category);
    }

    toggle = (key: keyof TState) => this.setState({ [key]: !this.state[key] } as any);

    isSelected = (index: number) => {
        return this.state.selected === index;
    }

    render() {
        const { selected, item, dialogRMOpen, selectedItems } = this.state;
        const confComponent = (
            <ConfigurationPanel
                elements={[
                    {
                        type: "component",
                        component: (
                            <CustomIconButton
                                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad("add") })}
                                disabled={!RightManager.hasRight(eFunctions.ref_Groups, eRights.create)}
                                className="custom_btn_primary no-radius no-shadow icon-panel-item"
                                onClick={() => this.props.addRecord({ Category: GroupClassList[this.state.selected].Category, Of: { Class: GroupClassList[this.state.selected].Of.Class, Type: GroupClassList[this.state.selected].Of.Type }, isNew: true, IDs: [], Area: [{ Class: ref_Advertisers.name, IDs: null }] })}
                            >
                                {getIcon("plus")}
                            </CustomIconButton>
                        ),
                    },
                ]}
            />
        );
        return (
            <div className="grid_container">
                <div className="grid_body">
                    <GroupsToolBar selectedItems={selectedItems}
                        confComponent={confComponent}
                        onRemoveClick={() => this.toggle("dialogRMOpen")} />
                    <TabStrip
                        className="tabpanel_fullwidth tabpanel_rightmenu"
                        selected={selected}
                        onSelect={(e) => {
                            this.props.clear(GroupClassList[e.selected].Category);
                            this.setState({ selected: e.selected, selectedItems: [] });
                        }}
                    >
                        {GroupClassList.filter(g => {
                            const ok = RightManager.hasRight(g.Of.Class, eRights.read)
                                && (!g.Hierarchy?.class || RightManager.hasRight(g.Hierarchy.class, eRights.read))
                            return ok;
                        }).map((c, index) => (
                            <TabStripTab title={TradProp(c.Category)} key={`tap-strip-tab-${index}`}>
                                <div
                                    className="treeview_container k-grid-table"
                                    style={{ height: calcHeight, overflow: "auto", overflowX: "hidden" }}
                                >
                                    <GroupsTab
                                        isSelected={() => this.isSelected(index)}
                                        el={c}
                                        selectedItems={selectedItems}
                                        setSelectedItems={(e) => this.setState({ selectedItems: e })}
                                    />
                                </div>
                            </TabStripTab>
                        ))}
                    </TabStrip>
                    <GenericDialog
                        open={dialogRMOpen}
                        dialogTitle={Trad("confirmation")}
                        actions
                        submitClass={"custom_btn_danger"}
                        submitTitle={Trad("yes")}
                        startIcon={getIcon("delete")}
                        submitAction={async () => {
                            this.toggle("dialogRMOpen");
                            this.props.remove({ selected: selectedItems, idsField: propertyOf<ref_Groups>("IDs") });
                            this.setState({ selectedItems: [] });
                            // await Client.deleteVertex(ref_Groups.name, selectedItems);
                            // grid.UpdateRows();
                        }}
                        cancelAction={() => this.toggle("dialogRMOpen")}>
                        <p>{ConfirmContent(selectedItems)}</p>
                    </GenericDialog>
                </div>
            </div>
        );
    }
}

export default connect(null, { addRecord, clear, remove })(GroupsTree);
