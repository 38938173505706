import * as React from 'react';
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker';
import { Client } from 'hub-lib/client/client.bin'
import { Trad } from 'trad-lib'
import { VertexAutocomplete } from "adwone-lib/index";
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { GetFirstDayOfYear, GetLastDayOfYear } from 'tools-lib';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';

export class PopupSettingsCurrencies extends React.Component<any, any>{
  constructor(props: any) {
    super(props)
    this.state = {
      open: true,
      groupeAnnonceur: [],
      date: { start: GetFirstDayOfYear(), end: GetLastDayOfYear() },
      groupeSelected: []
    }
  }
  componentDidMount() {
    Client.searchVertex(ref_AdvertiserGroups.name).then(res => {
      let response = res.data.results
      let groupeSelected = response[0]
      response = response.sort((a, b) => a["Name"].localeCompare(b["Name"]))
      this.setState({
        groupeSelected,
        groupeAnnonceur: response
      })
    });
  }
  handleClose = () => {
    this.props.onValidate(this.state.groupeSelected, this.state.date, this.state.groupeAnnonceur)
    this.setState({ open: false })
  }
  handleChangeDate = (value: any) => {
    this.setState({
      date: { start: value.start, end: value.end }
    })
  }
  render() {
    return (
      <>
        {this.state.groupeAnnonceur.length > 0 &&
          <GenericDialog
            open={this.state.open}
            dialogTitle={Trad("table_init")}
            disableCancel={true}
            actions={true}
            id="dialog_setting_currencies"
            submitAction={this.handleClose}
            submitTitle={Trad("apply")}
            // submitClass="custom_btn_primary"
            dialogContent={
              <>
                <div className='adw-row'>
                  <VertexAutocomplete
                    label={Trad("advertiser_group")}
                    options={this.state.groupeAnnonceur}
                    disableClearable
                    defaultValue={(options: any[]) => options?.find((v) => v["@rid"] === this.state.groupeSelected["@rid"])}
                    onChange={(value: any) => { this.setState({ groupeSelected: value }) }} />
                </div>
                <div className='adw-row'>
                  <DateRangepicker defaultStart={this.state.date.start} defaultStop={this.state.date.end} handleChangeDate={this.handleChangeDate} />
                </div>
              </>
            }
          >

          </GenericDialog>}
      </>
    )
  }
}