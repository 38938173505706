import { ref_Companies } from "./orientdb/ref_Companies.bin";
import { ref_Customers } from "./orientdb/ref_Customers.bin";
import { UserRights } from "./orientdb/UserRights.bin";

export class ExternalCompany {
    Name: string;
    Siret: string;
    Import: any;
};

export class ExternalClients {
    '@rid'?: string;
    Customer: ref_Customers['@rid'];
    Created_at: Date;
    Companies: ExternalCompany[];
    Rights: UserRights['@rid'];
}