import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as React from 'react'
import { GetHashCode } from 'hub-lib/tools.bin';

export function CheckBoxActivation(label: string, checked: boolean, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void) {
    return <FormControlLabel
        key={`${GetHashCode(label)}-${checked}`}
        style={{ marginTop: 0 }}
        control={
            <Checkbox
                checked={checked}
                onChange={onChange}
                name="subTotal"
                color="primary" />
        }
        label={label} />
}