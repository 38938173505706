import { Row, Ventilation, Table, Column, CellValue } from "./types.bin";
import { clone, compareArrays, distinct, ePropertyOptionFilter, GetSubElement, groupBy, hasOwnProperty, IndicateurInfoFilter, IsPromise, ReleaseEventLoop, safeArray, safeNumber, splitBlocks, toArray, toDictionary, toDictionaryList, Typed } from "hub-lib/tools.bin";
import { Trad, TradProp } from "trad-lib";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { eKPIType, KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { GetDateRanges, IsIntersec, recurseAll, recurse as recuseData } from "tools-lib";
import { DiscountOptions, eDiscountOptionValue } from "hub-lib/models/external.bin";
import { ADWProperty } from "hub-lib/types";
import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { ReturnCurrencyProvider } from "hub-lib/business/ReturnCurrencyProvider.bin";
import { MetaDataProperty, eColumnType } from "hub-lib/models/types.bin";
import moment from "moment";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { BuildProperties, EngineTools } from "./EngineTools";
import stringSimilarity from "string-similarity";
import { DataProvider } from "hub-lib/provider";
import { RightManager } from "hub-lib/models/types/rights.bin";
import { ReferentialHasViews } from "hub-lib/models/orientdb/ReferentialHasViews.bin";

export enum eIndicateurType {
    kpi = "kpi",
    discount = "discount",
    info = "info",
    computed = "computed",
    join = "join",
    link = "link"
}

export class EngineOptions {
    temporalTotal?: "week" | "month" | "trimester" | "semester";
    timeView?: "day" | "week" | "month" | "trimester" | "semester";
    columnsGeneration?: "fromData" | "fromMetadata";
    headerFilters?: CompositeFilterDescriptor;
    sort?: SortDescriptor[];
    groupingRows?: boolean;
    hideDetailsData?: boolean;
    hideDetailsRows?: boolean;
    resizePeriod?: boolean;
    tableEvolution?: Table<any>;
    onDocumentsLoaded?: (documents: any[]) => void;

    // options for the format of the table
    formatTable?: boolean = true;

    // only compute the total of the table
    onlyTotal?: boolean = false;

    // try to get cache
    uuidCache?: string = null;
}

export function ConvertToIndicateur(r: ADWProperty | Indicateur): Indicateur {
    if (!Object.values(eIndicateurType).includes(<any>r.type)) {
        return Typed<IndicateurInfo>({
            type: eIndicateurType.info,
            valueType: r.type == "@rid" ? eKPIType.Rid : eKPIType.String,
            field: r.field,
            name: r.field ? TradProp(r.field) : r.field
        })
    }
    return <Indicateur>r;
}

export function CreateIndicateur(data: Indicateur): IndicateurBase {
    let ind: IndicateurBase = undefined;
    switch (data.type) {
        case eIndicateurType.kpi:
            ind = new IndicateurKPI();
            break;

        case eIndicateurType.discount:
            ind = new IndicateurDiscount();
            break;

        case eIndicateurType.info:
            ind = new IndicateurInfo();
            break;

        case eIndicateurType.computed:
            ind = new IndicateurComputed();
            break;

        case eIndicateurType.join:
            ind = new IndicateurJoin();
            break;

        case eIndicateurType.link:
            ind = new IndicateurLink();
            break;

        default: {
            console.log(`data indicateur`, data);
            throw new Error("Not implemented CreateIndicateur");
        }
    }
    ind?.Load(data);
    return ind;
}

export type Indicateur = IndicateurInfo | IndicateurKPI | IndicateurDiscount | IndicateurComputed | IndicateurJoin;

type ColumnOptions = {
    name: string,
    isSchedulerHidden?: boolean
}

// ATTENTION, IGNORED PROPERTIES !!!!!!!!!!!!!!!!!!!
const ignoredProperties: (keyof ColumnOptions)[] = ['name', 'isSchedulerHidden']

export type ColumnIndicateur = ColumnOptions & Indicateur;

let interv = null;
let totalMs = 0;
let nbCalls = 0;
export function IndicateurToString(ind: Indicateur) {
    const start = new Date().getTime();
    let copy = clone(ind);
    if (copy) {
        recurseAll(copy, (rec) => {
            if (typeof rec === "object")
                ignoredProperties.forEach(p => delete rec[p]);
        });

        const sortObjectKeys = (obj) => {
            if (Array.isArray(obj)) {
                return obj.map(sortObjectKeys).sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
            } else if (typeof obj === 'object' && obj !== null) {
                const sortedObj = {};
                Object.keys(obj).sort().forEach((key) => {
                    sortedObj[key] = sortObjectKeys(obj[key]);
                });
                return sortedObj;
            }
            return obj;
        };

        copy = sortObjectKeys(copy);
    }
    const res = JSON.stringify(copy);

    // const elapsed = new Date().getTime() - start;
    // totalMs += elapsed;
    // nbCalls++;
    // if (!interv)
    //     interv = setInterval(() => {
    //         console.log(`[IndicateurToString] ${nbCalls} Elapsed ${Math.trunc(totalMs / 100) / 10}s`);
    //     }, 4000);

    return res;
}

/**
 * Boolean, est ce que l'indicateur est en devise restituée
 */
export function IsIndicateurReturned(ind: Indicateur) {
    switch (ind.type) {
        case eIndicateurType.info:
            return false;
        case eIndicateurType.discount:
            return (<IndicateurDiscount>ind).options?.isPriceReturned;
        case eIndicateurType.kpi:
            return (<IndicateurKPI>ind).options?.isPriceReturned;
        default:
            break;
    }
    return false;
}

export function isDirectionPercent(d: eDirection) {
    return d == eDirection["%Vertical"] || d == eDirection["%VerticalTotal"] || d == eDirection["%Horizontal"] || d == eDirection["%HorizontalTotal"] || d == eDirection["%Evolution"];
}

export function isDirectionEvolution(d: eDirection) {
    return d == eDirection.EvolutionU || d == eDirection["%Evolution"];
}

export enum eDirection {
    U = "Unité",
    "%Vertical" = "%V parent",
    "%VerticalTotal" = "%V total",
    "%Horizontal" = "%H parent",
    "%HorizontalTotal" = "%H total",
    EvolutionU = "Evolution (Unité)",
    "%Evolution" = "% Evolution"
}

export type matchIndicateur = { subProperty: string, value: any } |
{ subProperty: string, filter: ePropertyOptionFilter };

export class IndicateurOptionBase {
    linksDescriptor?: { className: string, property: string };
    match?: matchIndicateur[];
    subPropertyValueAsKey?: string;
    mapValueTo?: { [key: string]: any };
};

export class IndicateurBase {

    optionsBase?: { direction?: eDirection, evolOptions?: { type: "year" | "month" | "week", offset: number } };

    options?: IndicateurOptionBase;

    /**
     * Indicateur type kpi|info
     */
    type: eIndicateurType;

    /**
     * Header name
     */
    name: string;

    /**
     * KPI type
     */
    valueType: eKPIType;

    /**
     * Field in the object
     */
    field?: string;

    Load?: (data: any) => void = (data: any) => {
        Object.entries(data).forEach(([k, v]) => {
            if (v && Object.keys(eIndicateurType).includes((<any>v).type)) {
                this[k] = this.Load(v);
            } else {
                this[k] = v;
            }
        });
    }

    GetSubElement?(data: any, prop: string) {
        const { subPropertyValueAsKey, } = this.options ?? new IndicateurOptionBase();
        let result = GetSubElement(data, prop);
        if (subPropertyValueAsKey) {
            const keyVal = GetSubElement(data, subPropertyValueAsKey);
            result = GetSubElement(result, this.options?.mapValueTo?.[keyVal] ?? keyVal);
        }
        return result
    }

    Compute?(msg: ref_Messages[]): any {

        if (this.field === "Start")
            return new Date([...msg].sort((a, b) => new Date(a.Start).getTime() - new Date(b.Start).getTime())?.[0]?.Start)

        if (this.field === "End")
            return new Date([...msg].sort((a, b) => new Date(b.End).getTime() - new Date(a.End).getTime())?.[0]?.End)

        // if type is date, return the min date
        if (this.valueType == eKPIType.Date) {
            const values = msg.map(m => this.GetSubElement(m, this.field)).filter(v => v);
            const dates = values?.map?.(v => new Date(v));
            if (!dates?.length) return "";
            return new Date(Math.min(...dates.map(d => d.getTime())));
        }

        if (this.field === "NbDays")
            return msg.map((m) => Math.ceil((new Date(m.End).getTime() - new Date(m.Start).getTime()) / (1000 * 60 * 60 * 24)))
        if (this.field === "NbWeeks")
            return msg.map((m) => Math.ceil((new Date(m.End).getTime() - new Date(m.Start).getTime()) / (1000 * 60 * 60 * 24 * 7)))

        const map = c => c?.constructor?.name == 'RecordID' ? c.toString() : c

        const flatValues = [];

        const values = msg.map(m => this.GetSubElement(m, this.field));
        values.forEach(v => {
            if (Array.isArray(v)) v.forEach(e => flatValues.push(map(e)));
            else flatValues.push(map(v));
        });

        return Array.from(new Set(flatValues));
    }

    /**
     * Prepare the data before computing the indicator,
     * for example, if we need to retrieve some data from the database and group the calls
     * @param msg
     * @returns
     */
    async Prepare?(msg: ref_Messages[]): Promise<void> {
        // Do nothing by default
        return;
    }

}

export class IndicateurJoin extends IndicateurBase {
    type: eIndicateurType = eIndicateurType.join;

    indicateurs: Indicateur[];

    options?: {
        separator: string;
    } & IndicateurOptionBase;

    Compute?: (messages: ref_Messages[]) => any = async (messages: ref_Messages[]) => {
        try {
            const indicateurValues: string[] = [];
            const indicInstance = this.indicateurs.map(i => CreateIndicateur(i));

            const computedValues: any[][] = [];
            for (const msg of messages) {
                const indicateurValues: any[] = [];
                for (const indicateur of indicInstance) {
                    const value = await indicateur.Compute([msg]);
                    indicateurValues.push(value);
                }
                if (!computedValues.some(cv => JSON.stringify(cv) == JSON.stringify(indicateurValues)))
                    computedValues.push(indicateurValues);
            }
            return computedValues;
        } catch (error) {
            console.error(error);
            return "";
        }
    }
}




export enum eTotalDirection {
    totalCrossedParentRow = "{totalCrossedParentRow}",
    totalRow = "{totalRow}",
    totalCrossedParentColumn = "{totalCrossedParentColumn}",
    totalColumn = "{totalColumn}"
}

export class IndicateurComputed extends IndicateurBase {

    type: eIndicateurType = eIndicateurType.computed;

    indicateurs: Indicateur[];
    operator: "+" | "-" | "/" | "*" | "%" | "=" | "|" | "||" | "similarity" | "==";

    options?: {
        subEngineType?: { className: 'ref_messages', property: 'Campaign' };
        isPriceReturned?: boolean;
        round?: 'ceil',
        formater?: formaterMoment | formaterTrad | formaterHtml // On peut ajouter des "|" pour ajouter de nouveaux formaters
        valueTransform?: { function: string, args?: any[] }[];
        // Eventuel multiplicateur
        rate?: number;
        baseValue?: number;
        valueEquals?: any // only works with "==" operator
    } & IndicateurOptionBase;

    Compute?: (messages: ref_Messages[]) => any = (messages: ref_Messages[]) => {
        try {
            if (this.options?.match) {
                for (const m of this.options.match) {
                    if ("value" in m)
                        messages = messages.filter(msg => m['value'] == GetSubElement(msg, m['subProperty']));
                    if ("filter" in m) {
                        if (!IndicateurInfoFilter(messages, m))
                            return "-";
                    }
                }
            }

            if (this.valueType == eKPIType.Price)
                if (new Set(messages?.map(m => m.Currency) ?? []).size != 1)
                    return '-';

            const getValues = (_messages) => {
                const indicateurValues: number[] = [];
                for (const indicateur of this.indicateurs) {
                    const instanceIndic = CreateIndicateur(indicateur);
                    const indicateurValue = instanceIndic.Compute(_messages);
                    if ((indicateurValue !== "" && !isNaN(indicateurValue)) || (["similarity", "="].includes(this.operator)))
                        indicateurValues.push(indicateurValue);
                }

                if (this.options?.valueTransform?.length) {
                    for (let i = 0; i < indicateurValues.length; i++) {
                        this.options?.valueTransform.forEach(vt => {
                            const value = indicateurValues[i];
                            const func = vt.function;
                            const funcArgs = vt.args;
                            if (value && typeof value?.[func] === "function") {
                                const funcPtr = value[func];
                                if (funcArgs) indicateurValues[i] = funcPtr.apply(value, funcArgs);
                                else indicateurValues[i] = funcPtr.apply(value);
                            }
                        });
                    }
                }

                return indicateurValues;
            }

            let value = 0;
            const indicateurValues = (this.operator != "*") && getValues(messages);
            switch (this.operator) {
                case "*":
                    value = messages.map(m => {
                        const indicateurValues = getValues([m]);
                        if (indicateurValues.length != this.indicateurs.length) return 0;
                        return indicateurValues.reduce((a, b) => a * b, 1);
                    }).reduce((a, b) => a + b, 0);
                    break;
                case "%":
                    if (indicateurValues.length != 2 || indicateurValues[1] === 0) value = 0;
                    else value = ((indicateurValues[0] / indicateurValues[1]) - 1);
                    break;
                case "/":
                    if (indicateurValues.length != 2 || indicateurValues[1] === 0) value = 0;
                    else value = indicateurValues[0] / indicateurValues[1];
                    break;
                case "=":
                    if (indicateurValues.length != 2) value = -1;
                    else if ((indicateurValues?.[0]?.[0] == undefined || indicateurValues?.[1]?.[0] == undefined) && (indicateurValues?.[0]?.[0] || indicateurValues?.[1]?.[0])) {
                        if (indicateurValues?.[0]?.[0]) value = 0;
                        else value = -1;
                    }
                    else if (indicateurValues?.[0]?.[0] == undefined || indicateurValues?.[1]?.[0] == undefined) value = -1;
                    else value = (indicateurValues?.[0]?.[0] === indicateurValues?.[1]?.[0]) ? 1 : 0;
                    break;
                case "==":
                    // every values must be the same
                    if (hasOwnProperty(this.options, "valueEquals")) {
                        const valueEquals = this.options.valueEquals;
                        if (indicateurValues.some(v => v[0] != valueEquals)) value = 0;
                        else value = 1;
                    } else
                        if (indicateurValues.length < 2) value = 1;
                        else if (indicateurValues.some((v, i) => indicateurValues[i][0] !== indicateurValues[0][0])) value = -1;
                        else value = 1;
                    break;
                case "|":
                    value = indicateurValues.reduce((a, b) => a | b, 0);
                    break;
                case "||":
                    value = indicateurValues.reduce((a, b) => {
                        if (a !== undefined && a !== null)
                            return a;
                        return b;
                    }, null);
                    break;
                case "-":
                    value = indicateurValues.reduce((a, b) => a - b, (indicateurValues?.[0] ?? 0) * 2);
                    break;
                case "+":
                    value = indicateurValues.reduce((a, b) => a + b, 0);
                    break;
                case "similarity":
                    if (indicateurValues.length != 2 || !indicateurValues.every(e => typeof e == "string")) value = 0;
                    else value = stringSimilarity.compareTwoStrings(indicateurValues[0]?.toString().toLocaleUpperCase(), indicateurValues[1]?.toString().toLocaleUpperCase());
                    value = Math.round(value * 100);
                    break;
                default:
                    throw new Error(`Operator ${this.operator} not implemented`);
            }
            switch (this.options?.round) {
                case "ceil":
                    value = Math.ceil(value);
                    break;

                default:
                    break;
            }
            return ((this.options?.baseValue ?? 0) + value) * (this.options?.rate || 1);
        } catch (error) {
            console.error(error);
            return 0;
        }
    }

    async Prepare?(msg: ref_Messages[]): Promise<void> {
        for (const indic of this.indicateurs) {
            await CreateIndicateur(indic).Prepare(msg);
        }
    }
}

export class IndicateurDiscount extends IndicateurBase {
    /**
     * Info type
     */
    type: eIndicateurType = eIndicateurType.discount;

    options: DiscountOptions & IndicateurOptionBase;

    Compute?: (msg: ref_Messages[]) => any = (msg: ref_Messages[]) => {

        const promises: Promise<any>[] = [];

        /** On ne prend pas les totaux en % pour le moment */
        if (this.valueType === eKPIType.Percent && msg.length !== 1)
            return "";

        // récupère la valeur du discount
        const discounts = [];
        for (const m of msg) {

            let value: number = 0;
            if (this.options.isPriceBound)
                value = m.KPIs[`Bound:${this.options.rid}:${this.options.type == 'CO' ? eColumnType.DiscountValueBound : eColumnType.DiscountFOValueBound}`];
            else {
                if (this.options.barter)
                    value = DiscountManager.GetValue(m.BarterPercents, this.options.rid, this.options.type, this.options.value);
                else {
                    const cascade = m[`cache-cascade`] ?? DiscountManager.UpdateCascadeSync(m, null); //TODO GET Campaign (CPM)
                    m[`cache-cascade`] = cascade;
                    const foundDiscount = cascade[this.options.type].find(d => d.Discount.DiscountClass == this.options.rid)?.Discount;
                    if (this.options.value == eDiscountOptionValue.Rate) value = foundDiscount?.[this.options.type]?.Rate ?? 0;
                    else value = foundDiscount?.[this.options.type]?.Value ?? 0;
                }
            }

            if (!isNaN(value) && this.options.isPriceReturned) {
                const res = ToReturnedCurrency(m, value);;
                if (IsPromise(res))
                    promises.push(Promise.resolve().then(async () => {
                        discounts.push((await res).value);
                    }));
                else discounts.push((res as ReturnedValue).value)
            } else discounts.push(value)
        }

        const compute = () => discounts.filter(d => d).reduce((a, b) => a + b, 0) ?? 0;
        if (promises.length)
            return Promise.all(promises).then(compute)
        return compute();
    }
}
export type formaterMoment = { type: "moment", format?: string, periodicity?: string } & (formaterMomentBase | formatMomentPeriodicity)

export type formaterMomentBase = {
    format: "DD" | "MM" | "MMMM" | "YYYY" | "WW" | "[T]Q",
}

export type formatMomentPeriodicity = {
    periodicity: "datedWeek" | "semester",
}

export type formaterTrad = { type: "trad", prefixe?: string, suffixe?: string, fallback?: string }
export type formaterHtml = { type: "html" }
type value = { type: "moment" }

export class propertyOption extends IndicateurOptionBase {
    formater?: formaterMoment | formaterTrad | formaterHtml // On peut ajouter des "|" pour ajouter de nouveaux formaters
    value?: value
    priorityToField?: string
    subProperty?: string
    subPropertyDependencies?: string[]
    subPropertyFallback?: string
    MetaData?: MetaDataProperty
    rate?: Number
}

export class IndicateurInfo extends IndicateurBase {
    /**
     * Locker on Info type
     */
    type: eIndicateurType = eIndicateurType.info;

    options?: propertyOption;
    constructor(e?: Partial<IndicateurInfo>) {
        super();
        if (e) Object.entries(e).forEach(([k, v]) => this[k] = v);
    }

    GetSubElement?= (data: any, prop: string) => {
        const { priorityToField, subProperty, subPropertyFallback, MetaData, subPropertyValueAsKey } = this.options ?? new propertyOption();
        let result = undefined;
        if (priorityToField) {
            const valuePriority = GetSubElement(data, priorityToField, this.options?.match);
            result = Boolean(valuePriority) ? valuePriority : GetSubElement(data, prop, this.options?.match);
        }
        else {
            let propertyName = subProperty ? `${prop}.${subProperty}`.replace(/\./g, '') : prop;
            if (MetaData?.name)
                propertyName = MetaData.name;

            result = GetSubElement(data, propertyName, this.options?.match);
            if (!result && subPropertyFallback)
                result = GetSubElement(data, `${prop}.${subPropertyFallback}`.replace(/\./g, ''), this.options?.match);

            if (subPropertyValueAsKey) {
                const keyVal = GetSubElement(data, subPropertyValueAsKey);
                result = GetSubElement(result, this.options?.mapValueTo?.[keyVal] ?? keyVal);
            }
        }

        return result;
    }

    Compute?: (msg: ref_Messages[]) => any = (msg: ref_Messages[]) => {
        let value = super.Compute(msg);

        if (this.options?.value) {
            switch (this.options.value.type) {
                case "moment":
                    const dateVal = moment(new Date(value));
                    if (dateVal.isValid())
                        value = dateVal.valueOf()
                    break;
                default:
                    break;
            }
        }
        // if (this.options?.formater) {
        //     switch (this.options.formater.type) {
        //         case "moment":
        //             const dateVal = moment(new Date(value));

        //             if (dateVal.isValid())
        //                 if (this.options.formater.format) {
        //                     value = dateVal.format(this.options.formater.format);
        //                     if (this.options.formater.format === "MMMM") {
        //                         value = Trad("month_" + dateVal.month())
        //                     }
        //                     if (this.options.formater.format === "WW") {
        //                         value = Trad("week_very_short") + value
        //                     }
        //                 } else if (this.options.formater.periodicity === "semester") {
        //                     value = "S" + (parseInt(dateVal.format('Q')) > 2 ? 2 : 1)
        //                 } else if (this.options.formater.periodicity === "datedWeek") {
        //                     value = Trad("week_of") + ' ' + GetCellTemplate(ePropType.Date)(dateVal.startOf("week"))
        //                 }

        //                 else value = "";
        //             break;
        //         case "trad":
        //             //const text = toArray(value).filter(Boolean).map(v => Trad(v)).join(", ");
        //             //console.log(value, text);
        //             //value = text;
        //             break;
        //         default:
        //             break;
        //     }
        // }


        if (this.options?.formater) {
            switch (this.options.formater.type) {
                case "moment":
                    const dateVal = moment(new Date(value));
                    if (dateVal.isValid()) {
                        if (this.options.formater.format) {

                            switch ((this.options.formater as formaterMoment).format) {
                                case "MMMM":
                                    value = dateVal.month();
                                    break;
                                case "MM":
                                    value = dateVal.month() + 1;
                                    break;
                                case "WW":
                                    value = dateVal.week();
                                    break;
                                case "YYYY":
                                    value = dateVal.year();
                                    break;
                                case "DD":
                                    value = dateVal.date();
                                    break;
                                case "[T]Q":
                                    value = dateVal.quarter();
                                    break;
                                default:
                                    value = dateVal.format(this.options.formater.format);
                                    break;
                            }
                        }

                        if (this.options.formater.periodicity) {
                            switch ((this.options.formater as formatMomentPeriodicity).periodicity) {
                                case "datedWeek":
                                    value = dateVal.startOf("week").toDate();
                                    break;
                                case "semester":
                                    value = dateVal.quarter() > 2 ? 2 : 1;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    else value = "";
                    break;
                case "trad":
                    //const text = toArray(value).filter(Boolean).map(v => Trad(v)).join(", ");
                    //console.log(value, text);
                    //value = text;
                    break;
                default:
                    break;
            }
        }



        if (Array.isArray(value) && value.length === 1)
            return value[0];

        return value;
    }
}

export class IndicateurKPI extends IndicateurBase {

    /**
     * Locker on KPI type
     */
    type: eIndicateurType = eIndicateurType.kpi;

    /**
     * More info about the KPI
     */
    options?: {
        rid?: rid,
        subProperty?: string;
        groupBy?: boolean;
        subPropertyDependencies?: string[];
        isPriceReturned?: boolean,
        isPriceBound?: boolean,
        filter?: Partial<ref_Messages>
        filterIgnore?: Partial<ref_Messages>,
        forceValue?: number,
        aggregate?: boolean
    } & IndicateurOptionBase;


    Compute?: (msg: ref_Messages[]) => any = (_msg: ref_Messages[]) => {

        if (this.options?.match) {
            this.options?.match.forEach(m => {
                if ("value" in m)
                    _msg = _msg.filter(msg => m['value'] == GetSubElement(msg, m['subProperty']));
                if ("filter" in m) {
                    if (!IndicateurInfoFilter(_msg, m))
                        return "-";
                }
            })
        }

        const msg = _msg.filter(m => Boolean(m));
        const promises: Promise<any>[] = [];
        const kpis = [];

        if (this.valueType == eKPIType.Price)
            if (new Set(msg.map(m => m?.Currency)).size != 1)
                return '-';

        const flagged = new Set();
        for (const m of msg) {
            const field = this.options?.isPriceBound ? `Bound${this.field}` : this.field

            let continueLoop: boolean = false;

            /** On ignore les messages qui ne remplissent pas la condition de filter */
            if (this.options?.filter) {
                for (const [k, v] of Object.entries(this.options.filter)) {
                    /** Le KPI est considéré à 0 s'il ne répond pas un filtre */
                    if (m[k] != v) {
                        continueLoop = true;
                        continue;
                    }
                }
            }

            /** On ignore les messges qui remplissent la condition du filterIgnore */
            if (this.options?.filterIgnore) {
                for (const [k, v] of Object.entries(this.options.filterIgnore)) {
                    /** Le KPI est considéré à 0 s'il ne répond au filtre Ignore */
                    if (m[k] === v) {
                        continueLoop = true;
                        continue;
                    }
                }
            }

            if (continueLoop)
                continue;

            const getValue = () => {

                // Do not count many times same data if groupBy is set
                if (this.options?.groupBy) {
                    const val = this.GetSubElement(m, field);
                    if (val && flagged.has(val)) return 0;
                    else if (val) flagged.add(val);
                }

                if (this.options?.subProperty)
                    return this.GetSubElement(m, field + this.options.subProperty.replace(/\./g, ''));

                if (field?.includes?.("."))
                    return this.GetSubElement(m, field);

                if (this.options?.subPropertyValueAsKey)
                    return this.GetSubElement(m, field);

                return m.KPIs?.[field];
            }

            let value = this.options?.forceValue ?? getValue() ?? 0;

            if (!isNaN(value) && value != 0 && this.options?.isPriceReturned) {
                const res = ToReturnedCurrency(m, value);
                if (IsPromise(res))
                    promises.push(Promise.resolve().then(async () => {
                        kpis.push((await res).value);
                    }));
                else kpis.push((res as ReturnedValue).value);
            } else kpis.push(value);
        }
        const aggregate = !hasOwnProperty(this.options, "aggregate") || this.options?.aggregate;
        if (!aggregate) {
            return kpis.length == 1 ? kpis[0] : "";
        }
        const compute = () => {
            // check if all values are numbers
            // if not, try to convert them
            // if canno convert, return '-'
            if (kpis?.some(k => typeof k != "number")) {
                // if some isNaN return '-'
                if (kpis.some(k => isNaN(k)))
                    return '';
                const converted = kpis.map(k => safeNumber(k));
                return converted.reduce((a, b) => a + b, 0);
            }

            return kpis.length ? kpis.reduce((a, b) => a + b, 0) : 0;
        }
        if (promises.length)
            return Promise.all(promises).then(compute)
        return compute();
    }
}

export class IndicateurLink extends IndicateurBase {

    type: eIndicateurType = eIndicateurType.link;

    options?: {
        cross?: boolean,
        links: {
            sourceType: string,
            className: string,
            property: string
        }[]
    } & IndicateurOptionBase;

    Prepare?: (msg: ref_Messages[]) => any = async (_msg: ref_Messages[]) => {

        const indcatorKey = IndicateurToString(this);
        let res: ReferentialHasViews<any, any, any>[][] = <any>await Promise.all(this.options.links.map(ld => {
            const subElements = _msg.map(m => GetSubElement(m, ld.property)).filter(v => v);
            return DataProvider.search(ld.className, {
                in: RightManager.GetUser()?.sources?.find(s => s["@class"] == ld.sourceType)?.['@rid'],
                Referential: subElements
            });
        }));

        _msg.forEach((m, i) => {

            const properties = distinct(this.options.links.map(ld => ld.property));
            if (!properties.some(p => GetSubElement(m, p))) {
                m[`caching_${indcatorKey}`] = 1;
                return 1;
            }

            const found = distinct(this.options.links.flatMap((ld, j) =>
                res[j].filter(r => {
                    const valElement = GetSubElement(m, ld.property);
                    return valElement && r.Referential == valElement;
                })), r => r['@rid']);

            if (this.options?.cross) {
                const isOk = (found.length >= this.options.links.length)
                    && Object.values(groupBy(found, r => r.out)).some(v => distinct(v, e => e["@class"]).length == this.options.links.length);
                m[`caching_${indcatorKey}`] = isOk ? 1 : 0;
                return;
            }

            m[`caching_${indcatorKey}`] = found?.length > 0 ? 1 : 0;
        });
    }

    Compute?: (msg: ref_Messages[]) => any = (msg: ref_Messages[]) => {

        if (msg.length > 1) return "";

        const indcatorKey = IndicateurToString(this);
        const res = msg.map(m => m[`caching_${indcatorKey}`]);
        return res;
    }
}

export const DefaultReturnCurrencyProvider = new ReturnCurrencyProvider(2000);

type ReturnedValue = { value: number, code: string };
export function ToReturnedCurrency(m: ref_Messages, value: number, _mgr?: ReturnCurrencyProvider): ReturnedValue | Promise<ReturnedValue> {
    const apply = (rateRes: { rate: number, currency: rid }) => {
        let code: string = undefined;
        if (rateRes) {
            value *= (rateRes.rate || 1);
            code = rateRes.currency;
        }
        return { value, code };
    }

    const currMgr = _mgr ?? DefaultReturnCurrencyProvider;
    if (currMgr.HasExpired())
        return currMgr.GetCurrency(m.AdvertiserGroup, m.Advertiser, m.Currency, m.Start, m.End).then(apply);
    return apply(currMgr.GetCurrencySync(m.AdvertiserGroup, m.Advertiser, m.Currency, m.Start, m.End));
}

export function CreateIndicateurInfo(field: string, valueType: eKPIType, name?: string): IndicateurInfo {
    return {
        name: name ?? TradProp(field, ref_Messages),
        valueType,
        field,
        type: eIndicateurType.info
    };
}

export function CreateIndicateurKPI(field: string, valueType: eKPIType, name?: string): IndicateurKPI {
    return {
        name: name ?? Trad(field),
        valueType,
        field,
        type: eIndicateurType.kpi
    };
}

export type Aggregator<T> = (data: T[], ind: Indicateur) => any;
export type DimensionValueGetter<T> = (data: T, dimension: string) => any;
export type DimensionResolver<T> = (data: T, dimension: string) => any;

export class EngineArgs { propRows: Indicateur[]; propColumns: Indicateur[]; ind: Indicateur[] }
export abstract class AEngine<T> {

    public Key: string;

    constructor(prototype?: new () => T) {
        this.Key = prototype?.name ?? "default";
    }

    public abstract split(data: T, ratio: number): Promise<T>;

    public async init(data: T[], inds: Indicateur[]): Promise<void> {

        const time5577 = new Date().getTime();
        const indsWithLinks = inds.filter(i => i.options?.linksDescriptor);
        if (!indsWithLinks.length) return;

        const groups = toDictionaryList(indsWithLinks, i => i.options.linksDescriptor.className + '-' + i.options.linksDescriptor.property);
        for (const [key, inds] of Object.entries(groups)) {
            const params = {
                Active: true,
                [inds[0].options.linksDescriptor.property]: data.map(d => d['@rid']),
                properties: distinct([...await BuildProperties(inds[0].options.linksDescriptor.className, inds), inds[0].options.linksDescriptor.property])
            };

            const dataLinked = await DataProvider.search(inds[0].options.linksDescriptor.className, params);
            data.forEach(d => d[key] = dataLinked.filter(dl => dl[inds[0].options.linksDescriptor.property] == d['@rid']));
        }
        const _time5577 = new Date().getTime();
        console.log(`[PERF] [ENGINE INIT] `, _time5577 - time5577, ` ms`);
    }

    public aggregator(data: T[], ind: Indicateur): Promise<any> {
        const indicateur = ind.Compute ? ind : CreateIndicateur(ind);

        if (ind?.options?.linksDescriptor) {
            const subElements = data.flatMap(d => d[ind.options.linksDescriptor.className + '-' + ind.options.linksDescriptor.property]);
            return indicateur.Compute(subElements);
        }

        return indicateur.Compute(data as any);
    }

    //protected abstract dimensionValueGetter(data: T, dimension: (ADWProperty | Indicateur)): any;

    protected async dimensionValueGetter(doc: T, dim: (ADWProperty | Indicateur)) {

        let v = undefined;
        try {
            const indicateur = CreateIndicateur(<Indicateur>dim);
            v = await indicateur.Compute([<any>doc]);
        } catch (error) {
            v = GetSubElement(doc, dim.field);
        }

        if (v === undefined) return null;
        return v;
    }

    //private dimensionResolver: DimensionResolver<T>;

    async Aggregate(data: T[], conf: EngineArgs, dates: { start: Date, end: Date }, options?: EngineOptions): Promise<Table<T>> {

        await ReleaseEventLoop();
        const table: Table<T> = new Table<T>();
        table.DocumentType = this.Key;
        table.Ventilations = conf.propRows;
        table.VentilationsColumns = conf.propColumns;
        let rows: Row<T>[] = [];

        /** table des messages, pas de ventilation */
        const flatMode = false;
        const rowIndicateurs: Indicateur[] = !conf.propRows?.length ?
            [Typed<IndicateurInfo>({
                field: "@rid",
                type: eIndicateurType.info,
                name: "@rid",
                valueType: eKPIType.Rid
            })] : conf.propRows;

        let colVentils: Ventilation<T>[] = [];
        let rowVentils: Ventilation<T>[] = [];

        if (!options?.onlyTotal) {
            console.log(`[Ventilate row] Start ...`);
            const time4129 = new Date().getTime();
            rowVentils = await this.ventilate(data, rowIndicateurs);
            const _time4129 = new Date().getTime();
            console.log(`[Ventilate row] Elapsed `, _time4129 - time4129, `ms`);

            console.log(`[Ventilate column] Start ...`);
            const time4130 = new Date().getTime();
            colVentils = await this.ventilate(data, conf.propColumns);
            const _time4130 = new Date().getTime();
            console.log(`[Ventilate column] Elapsed `, _time4130 - time4130, `ms`);
        }

        const total = new Ventilation<T>();
        total.Children = rowVentils;

        // if (!hasOwnProperty(options, 'hideDetailsData') || !options.hideDetailsData) total.Data = [...data];
        // else total.Data = [];
        total.Data = [...data];
        total.Dimension = Typed<IndicateurInfo>({
            field: "@class",
            type: eIndicateurType.info,
            name: Trad("Total"),
            valueType: eKPIType.String
        });
        total.Value = "Total";
        total.Formated = "Total";

        for (const r of [total]) {
            let row = new Row<T>();
            Object.assign(row, r);
            rows.push(row);
        }

        const indicateurToColumn = <T>(k: Indicateur, data: T[]) => {
            const newcol = new Column();
            newcol.Indicateur = k;
            //newcol.Label = k.name;
            newcol.Cell = { Value: k.name, Formated: k.name, Type: 'header' };
            newcol.Data = data;
            return newcol;
        }

        // let colVentils: Ventilation<T>[] = await this.ventilate(data, /*conf.propColumns*/[]);
        let recurseColumns = (vent: Ventilation<T>[]) => {
            let cols: Column[] = [];
            vent?.forEach(v => {
                let col = new Column();
                col.Indicateur = v.Dimension;
                col.Cell = { Value: v.Value, Formated: toArray(v.Value)?.join(','), Type: 'header' };
                col.Data = v.Data;
                // col.Label = toArray(v.Value)?.join(','); // TradProp(v.Dimension.field);
                cols.push(col);
                if (v.Children) {
                    col.Children = [...recurseColumns(v.Children),
                    ...conf.ind.map(k => indicateurToColumn(k, v.Data))];
                }
                else col.Children = conf.ind.map(k => indicateurToColumn(k, v.Data))
            })
            return cols;
        };

        let columnsFrozen: Column[] = conf.propRows.map(r => {
            let col = new Column();
            col.Indicateur = r;
            // col.Label = r.name;
            col.Cell = { Value: r.name, Formated: r.name, Type: 'header' };
            col.IsRow = true;
            return col;
        })

        let columns: Column[] = recurseColumns(colVentils);
        const columnsFrozenRid = columnsFrozen.find(c => c.Indicateur.field === "@rid");
        table.Columns = [...columnsFrozen, ...columns, ...conf.ind.map(k => indicateurToColumn(k, null))];

        if (flatMode) {
            rows = rows[0].Children;
        }

        table.Rows = rows;
        table.Indicateurs = conf.ind;

        if (options?.temporalTotal)
            this.addTimeVentilations(dates, options, table, data);

        await this.aggregate(table, { enableDirections: conf?.propColumns?.length > 0, tableEvolution: options?.tableEvolution });

        /** remove "@rid" column, in case of includeDetails */
        // if (table.Columns.some(c => c.Indicateur.field === "@rid"))
        //     table.Columns = table.Columns.filter(c => c.Indicateur.field !== "@rid");
        if (columnsFrozenRid)
            table.Columns = table.Columns.filter(c => c !== columnsFrozenRid);

        await ReleaseEventLoop();
        return table;
    }

    private addTimeVentilations(dates: { start: Date; end: Date; }, options: EngineOptions, table: Table<T>, data: T[]) {
        const ranges = GetDateRanges(dates.start, dates.end, options.temporalTotal);
        table.TimeVentilations = {
            Granularity: options.temporalTotal,
            Field: "Gross",
            Ranges: ranges.map(r => {

                return {
                    Start: r.start,
                    End: r.end,
                    Row: new Row(
                        Typed<IndicateurInfo>({
                            field: "@rid",
                            type: eIndicateurType.info,
                            name: "@rid",
                            valueType: eKPIType.Rid
                        }),
                        data.filter((d: any) => {
                            const startMsg = new Date(d.Start);
                            return IsIntersec(r.start, r.end, startMsg, startMsg);
                        }))
                };
            })
        };
    }

    private async ventilate(data: T[], dims: Indicateur[]): Promise<Ventilation<T>[]> {
        if (!dims) return undefined;

        let ventils: Ventilation<T>[] = [];
        let clone = [...dims];
        let first = clone.shift();
        if (!first) return undefined;


        const dicoKeyValue: { [keySerialized: string]: any } = {};

        //let dico = toDictionaryList(data, (e) => this.dimensionValueGetter(e, first));
        type TIndexer = { key: string[], values: T[] };
        const dico: TIndexer[] = [];
        const dicoFlat: { [key: string]: T[] } = {};

        for await (const _d of splitBlocks(data)) {
            const _key = await this.dimensionValueGetter(_d, first);
            const keys = toArray(_key).map(k => {
                const key = k?.toString?.();
                dicoKeyValue[key] = k;
                return key;
            });

            const d = keys.length > 1 ? await this.split(_d, keys.length - 1) : _d;
            for (const key of keys) {

                if (Array.isArray(key)) {
                    const found = dico.find(e => compareArrays(e.key, key));
                    if (found) found.values.push(d);
                    else dico.push({ key, values: [d] });

                    const foundContained = dico.filter(e => !compareArrays(e.key, key) && key.every(k => e.key.includes(k)));
                    foundContained.forEach(f => f.values.push(d));

                    const toInclude = dico.filter(e => (e != found) && ((!Array.isArray(e.key) && found.key.includes(e.key)) || (Array.isArray(e.key) && e.key.every(k => found.key.includes(k)))));
                    toInclude.forEach(e => e.values.forEach(v => {
                        if (!found.values.includes(v))
                            found.values.push(v);
                    }))

                    const toIncludeFlat = Object.entries(dicoFlat).filter(e => found.key.includes(e[0]));
                    toIncludeFlat.forEach(e => e[1].forEach(v => {
                        if (!found.values.includes(v))
                            found.values.push(v);
                    }))

                } else {
                    const found = dicoFlat[key];
                    if (found) found.push(d);
                    else dicoFlat[key] = [d];

                    const foundContained = dico.filter(e => e.key.includes(key));
                    foundContained.forEach(f => f.values.push(d));
                }



                // // 1rt: found exact ventilation
                // let found: TIndexer = Array.isArray(key) ?
                //     dico.find(e => Array.isArray(e.key) && compareArrays(e.key, key)) :
                //     dico.find(e => !Array.isArray(e.key) && e.key == key)
                // if (found) found.values.push(d);
                // else {
                //     found = { key, values: [d] }
                //     dico.push(found);
                // }

                // // 2nd: found where it can fit
                // const foundContained = Array.isArray(key) ?
                //     dico.filter(e => Array.isArray(e.key) && !compareArrays(e.key, key) && key.every(k => e.key.includes(k))) :
                //     dico.filter(e => Array.isArray(e.key) && e.key.includes(key));
                // foundContained.forEach(f => f.values.push(d));

                // // 3rd: if array, take all previou key that fit in
                // if (Array.isArray(key)) {
                //     const toInclude = dico.filter(e => (e != found) && ((!Array.isArray(e.key) && found.key.includes(e.key)) || (Array.isArray(e.key) && e.key.every(k => found.key.includes(k)))))
                //     toInclude.forEach(e => e.values.forEach(v => {
                //         if (!found.values.includes(v))
                //             found.values.push(v);
                //     }))
                // }
            }

        }
        for (const { key, values } of [...dico, ...Object.entries(dicoFlat).map(([k, v]) => ({ key: k, values: v }))]) {
            const ventil = new Ventilation<T>();
            ventil.Value = key === 'undefined' ? Trad('not_specified') : (typeof key == "string" ? (dicoKeyValue[key] ?? key) : key);
            ventil.Dimension = { ...first, field: first.field.replace("Returned", "") };
            ventil.Data = values;
            ventil.Children = await this.ventilate(values, clone);
            ventils.push(ventil);
        }
        return ventils;
    }

    private async aggregate(
        table: Table<T>,
        options?: {
            enableDirections?: boolean,
            tableEvolution?: Table<T>
        }) {
        await ReleaseEventLoop();
        console.log(`[Aggregate] Start...`);
        const time7268 = new Date().getTime();

        let parents: { [k: string]: Column } = {};
        let flatIndicateursByKey: { [signature: string]: { column: Column, parent: Column, signatureParentEvol: string } } = {};
        const flatSignatures: string[] = [];
        const valuesIndicateurs = table.Columns.filter(c => !c.IsRow);
        recuseData(valuesIndicateurs, 'Children', (col: typeof table.Columns[0], deepth) => {
            if (deepth == 0) parents = {};
            if (!col.Children?.length) {
                const parentsCols = Object.values(parents)
                    .slice(0, deepth);

                const parentsKeys = parentsCols
                    .map(p => (`{${p?.Cell?.Value}}`)).join('');

                // in case of evolution table, we need to add the evolution column signature to the key
                const parentsKeysEvol = parentsCols
                    .map(p => {
                        if (p.Indicateur?.name == Trad("year"))
                            return (`{${p?.Cell?.Value - 1}}`);
                        return (`{${p?.Cell?.Value}}`);
                    }).join('');

                flatSignatures.push(parentsKeys + IndicateurToString(col.Indicateur));
                flatIndicateursByKey[parentsKeys + IndicateurToString(col.Indicateur)] = {
                    column: col,
                    parent: parents[deepth - 2],
                    signatureParentEvol: parentsKeysEvol + IndicateurToString(col.Indicateur)
                };

            } else {
                parents[deepth] = col;
            }
        });

        const columnPromises = new Set<string>();
        const allPromises: Promise<any>[] = [];

        const allCurrencies = await EngineTools.GetCurrencies();
        const addValue = (value, ind: IndicateurBase, signature: string, row: Row<T>, data: T[]) => {
            const cellValue: CellValue = {
                Formated: '',
                Value: value,
                IndicateurSignature: signature,
                IndicateurSignatureFull: signature,
                Type: "cell"
            }
            EngineTools.AddCurrencyToCells(cellValue, ind, data, allCurrencies);
            row.ValuesTotal.push(cellValue);

            return cellValue;
        };

        const tableIndicateurs = [
            //...(table?.Indicateurs?.map?.(i => CreateIndicateur(i)) ?? []).map(i => ({ indicateur: i, signature: IndicateurToString(i), data: null, signatureFull: IndicateurToString(i) })),
            // ...Object.entries(flatIndicateursByKey).map(([k, v]) => ({
            ...flatSignatures.map(k => {
                const v = flatIndicateursByKey[k];
                return {
                    signatureParentEvol: v?.signatureParentEvol,
                    column: v?.column,
                    parent: v?.parent,
                    indicateur: v?.column.Indicateur,
                    signatureFull: k,
                    data: v?.column.Data,
                    signature: IndicateurToString(v?.column.Indicateur)
                }
            })
        ];

        // add signature to rows
        recuseData(table.Rows, 'Children', (r: Row<T>, deepth) => {

            let baseSignature = `{${r?.Value?.toString()}}`;
            let valEvolSignature = baseSignature;
            if (r?.Dimension?.name == Trad("year"))
                valEvolSignature = (`{${(Number(r.Value as any) - 1)?.toString()}}`);

            if (!r.Signature) { r.Signature = baseSignature; r.SignatureEvol = valEvolSignature; }
            else { r.Signature += baseSignature; r.SignatureEvol += valEvolSignature; }
            if (r.Children?.length)
                r.Children.forEach(c => { c.Signature = r.Signature; c.SignatureEvol = r.SignatureEvol; });
        });

        const allData = table.Rows.map(r => r.Data).flat();

        const aggregateData = (row: Row<T>, data: T[], indicateur: Indicateur, prefixSignature: string) => {
            const signature = IndicateurToString(indicateur);
            const signatureFull = prefixSignature + signature;

            const existingValue = row.ValuesTotal?.find(v => v.IndicateurSignatureFull == signatureFull);
            if (existingValue) return existingValue;

            const computeTotalCellRow = () => {
                const resultOfCellRow = this.aggregator(data, indicateur);
                const cell = addValue(resultOfCellRow, indicateur, signature, row, data);
                cell.IndicateurSignatureFull = signatureFull;
                return cell;
            }

            // const cell = row.ValuesTotal.find(v => v.IndicateurSignatureFull == signatureFull) ?? computeTotalCellRow();
            const cell = computeTotalCellRow();
            return cell;
        }

        const addRatioCell = (indicateur: Indicateur, signature: string, signatureFull: string, row: Row<T>, dataCrossed: T[], direction: eDirection, cellCrossed: CellValue, cellTotal: CellValue) => {
            const signaturePercentRow = `{${direction}}${signatureFull}`;

            // check already exists
            if (row.ValuesTotal.find(v => v.IndicateurSignatureFull == signaturePercentRow)) return;

            let resRatio = cellTotal.Value ? (safeNumber(cellCrossed.Value) / cellTotal.Value) : 0;
            if (isNaN(resRatio)) resRatio = 0;
            addValue(resRatio, indicateur, signature, row, dataCrossed).IndicateurSignatureFull = signaturePercentRow;
        }

        const evolutionFlatRows: Row<T>[] = [];
        recuseData(options?.tableEvolution?.Rows, 'Children', (row, parent) => evolutionFlatRows.push(row));

        const getEvolutionCellValue = (row: Row<T>, indicateur: Indicateur, signature: string, signatureFull: string): CellValue => {
            // get crossing values prefix of columns
            // ex: "{valCol1}{valCol2}{indicateurEvolSignature}" => "{valCol1}{valCol2}"
            const prefixInd = signatureFull.replace(signature, "");

            // then generate the crossing values + base incateur to get it in the evolution table
            const cloneIndicateur = clone(indicateur);
            cloneIndicateur.optionsBase = { ...cloneIndicateur.optionsBase, direction: null };
            // ex: "{valCol1}{valCol2}{indicateurEvolSignature}" => "{valCol1}{valCol2}{indicateurSignature}"
            const evolIndSignature = `${prefixInd}${IndicateurToString(cloneIndicateur)}`;

            // find the row in the evolution table with the same signature
            const evolRow = evolutionFlatRows.find(r => r.Signature == row.SignatureEvol);
            // get the cell in the evolution table with base indicateur signature
            const cellEvolution = evolRow?.ValuesTotal?.find(v => v.IndicateurSignatureFull == evolIndSignature);
            return cellEvolution;
        }

        const recurse = (rows: Row<T>[], parentRow?: Row<T>) => {
            if (rows)
                for (const row of rows) {
                    row.ValuesTotal = [];
                    for (const { indicateur, signature, data, signatureFull, parent, signatureParentEvol } of tableIndicateurs) {

                        // compute the value of the cell crossed by the row and the column
                        // 1st we get the data that are intersection in the row and in the column
                        const dataCrossed = data ? (row.Data.filter(d => data.some(d2 => d2['@rid'] == d['@rid']))) : row.Data;
                        const resultOfCellCrossed = this.aggregator(dataCrossed, indicateur);

                        const isColEvol = options?.enableDirections && indicateur?.optionsBase?.direction;
                        // const cellCrossed = row.ValuesTotal?.find(v => v.IndicateurSignatureFull == signatureFull) ?? addValue(resultOfCellCrossed, indicateur, signature, row, dataCrossed);
                        const cellCrossed = isColEvol
                            ? (row.ValuesTotal?.find(v => v.IndicateurSignatureFull == signatureFull) ?? addValue(resultOfCellCrossed, indicateur, signature, row, dataCrossed))
                            : addValue(resultOfCellCrossed, indicateur, signature, row, dataCrossed);
                        cellCrossed.IndicateurSignatureFull = signatureFull;

                        // const cellRow = row.ValuesTotal.find(v => v.IndicateurSignatureFull == signatureTotalRow) ?? computeTotalCellRow();
                        if (IsPromise(resultOfCellCrossed)) {
                            columnPromises.add(indicateur.name);
                            allPromises.push(resultOfCellCrossed.then(v => cellCrossed.Value = v))
                        } else {
                            if (isColEvol) {
                                switch (indicateur?.optionsBase?.direction) {
                                    case eDirection.U:
                                        const uCell = addValue(cellCrossed.Value, indicateur, signature, row, dataCrossed);
                                        uCell.IndicateurSignatureFull = `{${eDirection.U}}${signatureFull}`
                                        break;
                                    case eDirection["%Horizontal"]:
                                        // calcule avec le croisement avec la colonne parent
                                        const crossedColDataParent = (row?.Data.filter(d => (parent?.Data ?? allData)?.some(d2 => d2['@rid'] == d['@rid']))) ?? [];
                                        const cellCrossedColParent = aggregateData(row, crossedColDataParent, indicateur, eTotalDirection.totalCrossedParentRow);
                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%Horizontal"], cellCrossed, cellCrossedColParent);
                                        break;
                                    case eDirection["%HorizontalTotal"]:
                                        // calcule avec le total de la ligne
                                        const cellTotalRow = aggregateData(row, row.Data ?? [], indicateur, eTotalDirection.totalRow);
                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%HorizontalTotal"], cellCrossed, cellTotalRow);
                                        break;
                                    case eDirection["%Vertical"]:
                                        // calcule avec le croisement avec la ligne parent
                                        const crossedRowDataParent = ((parentRow?.Data ?? allData).filter(d => (data ?? [])?.some(d2 => d2['@rid'] == d['@rid']))) ?? [];
                                        const cellCrossedRowParent = aggregateData(row, crossedRowDataParent, indicateur, eTotalDirection.totalCrossedParentColumn);
                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%Vertical"], cellCrossed, cellCrossedRowParent);
                                        break;
                                    case eDirection["%VerticalTotal"]:
                                        // calcule avec le total de la colonne
                                        const cellTotalColumn = aggregateData(row, data ?? [], indicateur, eTotalDirection.totalColumn);
                                        addRatioCell(indicateur, signature, signatureFull, row, dataCrossed, eDirection["%VerticalTotal"], cellCrossed, cellTotalColumn);
                                        break;
                                    case eDirection["EvolutionU"]:
                                        const cellEvolutionU = getEvolutionCellValue(row, indicateur, signature, signatureParentEvol);
                                        const cellEvolutionValue = safeNumber(cellEvolutionU?.Value) ?? 0;
                                        let diff = safeNumber(cellCrossed.Value) - cellEvolutionValue;
                                        if (isNaN(diff)) diff = 0;

                                        const currencies = distinct([...safeArray(cellCrossed.Options?.Currencies), ...safeArray(cellEvolutionU?.Options?.Currencies)]);
                                        const currencyCode = currencies?.length == 1 ? (cellCrossed.Options?.CurrencyCode ?? cellEvolutionU?.Options?.CurrencyCode) : null;
                                        // cellCrossed.Options = { Currencies: currencies, CurrencyCode: currencyCode };

                                        // add the cell in current row
                                        const evolCell = addValue(diff, indicateur, signature, row, dataCrossed);
                                        evolCell.IndicateurSignatureFull = `{${eDirection["EvolutionU"]}}${signatureFull}`;
                                        evolCell.Options = { Currencies: currencies, CurrencyCode: currencyCode };

                                        break;

                                    case eDirection["%Evolution"]:
                                        const cellEvolution = getEvolutionCellValue(row, indicateur, signature, signatureParentEvol) ?? { ...new CellValue(), Value: 0 };
                                        const signaturePercentRow = `{${eDirection["%Evolution"]}}${signatureFull}`;
                                        let resRatio: string | number = 0;

                                        if (!safeNumber(cellEvolution.Value)) resRatio = '-'
                                        else resRatio = (safeNumber(cellCrossed.Value) / safeNumber(cellEvolution.Value)) - 1;

                                        addValue(resRatio, indicateur, signature, row, dataCrossed).IndicateurSignatureFull = signaturePercentRow;
                                        break;

                                    default:
                                }

                                // then remove the cell crossed
                                row.ValuesTotal = row.ValuesTotal.filter(v => v.IndicateurSignatureFull != signatureFull);
                                row.ValuesTotal = row.ValuesTotal.filter(v => !Object.values(eTotalDirection).some(d => v.IndicateurSignatureFull?.startsWith(d)));
                            };
                        }
                    }
                    recurse(row.Children, row)
                }
        }
        recurse(table.Rows);
        recurse(table.TimeVentilations?.Ranges?.map(r => r.Row));

        if (allPromises.length)
            console.log(`[PERF] [aggregate] Starting .... Promises: (${allPromises.length}) for: ${Array.from(columnPromises).join(', ')}...`);

        const time6442 = new Date().getTime();
        await Promise.all(allPromises);
        const _time6442 = new Date().getTime();
        console.log(`[PERF] [aggregate] Promises: (${allPromises.length}) for: ${Array.from(columnPromises).join(', ')}  ${_time6442 - time6442}ms`);
        console.log(`[Aggregate] Elapsed `, _time6442 - time7268, `ms`);

        await ReleaseEventLoop();
    }
}

export class EngineManager {

    private dico: { [prop: string]: AEngine<any> } = {};
    private static mgr: EngineManager;

    static GetInstance(): EngineManager {
        if (!EngineManager.mgr)
            EngineManager.mgr = new EngineManager();
        return EngineManager.mgr;
    }

    Register(engine: AEngine<any>) {
        this.dico[engine.Key] = engine;
    }

    Get(type: string): AEngine<any> {
        let engine = this.dico[type];
        if (!engine) {
            engine = this.dico["default"];
        }
        return engine;
    }
}