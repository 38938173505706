import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type UserRightsId = rid;

export class UserRights extends V {
	"@rid"?: UserRightsId;
	AllAdvertiserGroups: boolean;
	AllAgencies: boolean;
	AllBroadcastAreas: boolean;
	AllMedia: boolean;
	AllNoParentAdvertisers: boolean;
}