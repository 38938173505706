import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { clearEmptyValues, compareObjects, duplicate, extractSub, GetHashCode, toArray } from "hub-lib/tools.bin";
import React, { useRef } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { AnyAction, Store } from "redux";
import { Trad, TradProp } from "trad-lib";
import { Row } from "../../Tools";
import { RootStateFilters } from "./StoreFilters";
import { setStore, AdvertiserStoreMultiRid, StoreState, initStore } from "./AdvertiserStoreSlice";
import { AsyncThunk } from "@reduxjs/toolkit";
import { FavoriteVertexAutoComplete } from "../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { FilterStorage } from "../../../utils/localstorage.bin";
import { KeyValidator } from "../../../utils/KeyValidator";
import { KPIsManager } from "hub-lib/models/KPIsManager.bin";

export type StoreProps = {

    store: AdvertiserStoreMultiRid & { Source?: string[], Start?: Date, End?: Date },

    /**
     * Return changed store whith null when no value
     */
    onChange?: (store: AdvertiserStoreMultiRid) => void
    applyFilters?: boolean,
}

export type AdvertiserHierarchyComponentProps = StoreProps & {
    objectName: string,
    separators?: boolean,
    isLocked?: boolean,
    multiSelection?: boolean,
    reduxStore: Store<any, AnyAction>,
    lockedKeys?: (keyof AdvertiserStoreMultiRid)[],
    children?: any
}

const rowStyles: React.CSSProperties = {
    width: '100%'
}

export function AdvertiserHierarchyComponent({ objectName, children, separators, isLocked, store, onChange, reduxStore, lockedKeys, applyFilters, multiSelection = true }: AdvertiserHierarchyComponentProps) {
    return <Provider store={reduxStore}>
        <Initialize applyFilters={applyFilters} store={store} onChange={onChange} />
        {children && <Row separator={separators} style={rowStyles} className='advertiser-hierarchy-component'>{children}</Row>}

        {KPIsManager.AdvertiserProperties.map((key: any) =>
            <KeyValidator key={`advcmp-${key}`} objectType={objectName} propertyName={key}>
                <Row separator={separators} style={rowStyles} className='advertiser-hierarchy-component'>
                    <HierarchyItem keyStore={key} disabled={isLocked || lockedKeys?.includes(key)} setStoreFunc={setStore} multiSelection={multiSelection} />
                </Row>
            </KeyValidator>)}
    </Provider>
}

type MultiVertexAutoCompleteProps = {
    multiple?: boolean,
    disabled?: boolean,
    storeKey: (keyof RootStateFilters["advertiserStorage"]["store"]) | (keyof RootStateFilters["supportStorage"]["store"]),
    label?: string
}

const SubObj = (obj: AdvertiserStoreMultiRid) => {
    return extractSub(clearEmptyValues(obj), ["AdvertiserGroup", "Advertiser", "Brand", "Product", "Campaign"], null);
}

function Initialize({ store, onChange, applyFilters }: StoreProps) {

    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const storeChanged = useSelector((root: RootStateFilters) => root.advertiserStorage?.store);
    const isLoaded = useSelector((root: RootStateFilters) => root.advertiserStorage?.isLoaded);

    React.useEffect(() => {
        let preselected = store;
        if (!isMounted.current && applyFilters && !Object.keys(store).length) {
            dispatch(initStore({}));
            const filterStore = SubObj(FilterStorage.getAdvancedFilters());
            preselected = {};
            const hierarchy: (keyof ref_Messages)[] = ["AdvertiserGroup", "Advertiser", "Brand", "Product"];
            for (const key of hierarchy) {
                // si'il y a un seul élément on le met dans preselected
                if (filterStore[key]?.length == 1) {
                    preselected[key] = [filterStore[key][0]];
                } else {
                    break;
                }
            };
        }

        dispatch(setStore({ store: SubObj(preselected), isLoaded: true }));
    }, [GetHashCode(store)])

    React.useEffect(() => {
        if (isMounted.current && isLoaded && !compareObjects(SubObj(store), SubObj(storeChanged))) {
            onChange?.(SubObj(storeChanged));
        }
        isMounted.current = true;
    }, [GetHashCode(storeChanged)])

    return <></>
}

export function MultiVertexAutoComplete<T>({ disabled, storeKey, rootKey, label, setStoreFunc, multiple }: MultiVertexAutoCompleteProps & {
    rootKey: keyof RootStateFilters,
    setStoreFunc: AsyncThunk<Partial<any>, Partial<any>, {}>
}) {

    if (multiple === undefined) multiple = true;
    const defaultValues: IRid["@rid"][] = toArray(useSelector((root: RootStateFilters) => root[rootKey].store[storeKey]) ?? []);
    const store = duplicate(useSelector((root: RootStateFilters) => root[rootKey].store));
    const data = useSelector((root: RootStateFilters) => root[rootKey][storeKey + "Options"]);
    const loading = useSelector((root: RootStateFilters) => root[rootKey][storeKey + "Loading"]);
    const disabledStore = useSelector((root: RootStateFilters) => root[rootKey][storeKey + "Disabled"]);

    const dispatch = useDispatch();
    let warning: string = undefined;
    let selectedValues = [];
    if (data?.length) {
        const defaultV = defaultValues?.map(e => data?.find(v => v["@rid"] == e)) ?? [];
        selectedValues = defaultV.filter(v => v);
        if (selectedValues.length != defaultValues?.length)
            warning = Trad("invalid_filters");
    }

    return <FavoriteVertexAutoComplete
        key={`autocomplete-${GetHashCode({ loading, storeKey, options: data?.map(d => d["@rid"]), selectedValues })}`}
        warning={warning}
        loading={Boolean(loading)}
        disabled={disabledStore || Boolean(disabled)}
        multiple={multiple}
        options={data}
        label={label}
        defaultValue={values => multiple ? selectedValues : toArray(selectedValues)?.[0]}
        nullOnClear
        onChange={values => {
            store[storeKey] = values ? toArray(values).map(v => v["@rid"]) : null;
            dispatch(setStoreFunc({ store }));
        }} />

}

type HierarchyItemProps = {
    disabled?: boolean,
    multiSelection: boolean,
    keyStore: keyof AdvertiserStoreMultiRid,
    setStoreFunc: AsyncThunk<Partial<StoreState>, Partial<StoreState>, {}>
}

export function HierarchyItem({ multiSelection, keyStore, setStoreFunc, disabled }: HierarchyItemProps) {
    return <MultiVertexAutoComplete
        disabled={disabled}
        multiple={multiSelection}
        storeKey={keyStore}
        rootKey="advertiserStorage"
        label={TradProp(keyStore, ref_Messages)}
        setStoreFunc={setStoreFunc}
    />
}