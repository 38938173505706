import { ref_AdvertiserGroupsId } from "../../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisersId } from "../../models/orientdb/ref_Advertisers.bin";
import { ref_BrandsId } from "../../models/orientdb/ref_Brands.bin";
import { ref_ProductsId } from "../../models/orientdb/ref_Products.bin";
import { ref_MediaId } from "../../models/orientdb/ref_Media.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { IRid } from "../../models/IRid.bin";
import { ref_AgenciesId } from "../../models/orientdb/ref_Agencies.bin";
import { ref_Campaigns } from "./ref_Campaigns.bin";
import { ref_DepartmentsId } from "../../models/orientdb/ref_Departments.bin";

export type Action = 'Read' | 'Write' | 'Delete' | 'Create' | 'Update';
export type Update = 'CO' | 'FO' | 'Both';
export type Types = ("AE" | "FT")[];

export enum EstimateStatus {
    Reported = "Reported",
    Validated = "Validated",
}

export class ref_Estimates implements IRid {
    "@rid"?: rid;
    Import?: {
        Data?: any,
        Type?: string,
        ExternalID?: string,
        upsertDate?: Date,
        Status?: EstimateStatus,
    };
    Lib: string;
    Start: Date;
    End: Date;
    //"DumpDate"?: Date;
    Code: string;
    //"BDC":string;
    Status: string;
    Active: boolean;
    AdvertiserGroup: ref_AdvertiserGroupsId;
    Advertiser?: ref_AdvertisersId;
    Brand?: ref_BrandsId;
    Product?: ref_ProductsId;
    Media: ref_MediaId;
    Agency: ref_AgenciesId;
    Department?: ref_DepartmentsId;
    Invalide?: boolean;
    Type: string;
    ContractStatus: string;
    PdfLink?: string;
    Subject?: string;
    ModelProperties?: any;
    Campaign?: ref_Campaigns['@rid'];
    MessagesCount?: number;
    Created_at?: string;
}

export class LinkedEstimate {
    Type: string;
    Code: string;
    Status: string;
    PdfLink: string;
    Created: Date;
    Currency: string;
    Amount: string;
}