import * as React from "react";
import { InputAdornment } from "@material-ui/core";
import { getIcon, TooltipManager } from "adwone-lib";
import { GenericTooltip } from "../../ConfigurableComponents/GenericTooltip.bin";

type InfoAdornmentProps = { element: string | JSX.Element | (() => JSX.Element) }
export function InfoAdornment({ element }: InfoAdornmentProps) {
    return <InputAdornment
        className="primary-color"
        position="start"
        style={{ cursor: "pointer" }}>
        <GenericTooltip tooltipContent={element}>
            {getIcon("info")}
        </GenericTooltip>
    </InputAdornment>
}
