import { Checkbox } from "@progress/kendo-react-inputs";
import * as React from "react";

class TProps {
    checksbox: { label: string; value: string | number }[];
    array: (string | number)[];
}

const CheckBoxGroup = (props: TProps) => {
    const handleChecked = (key: string | number, checked: boolean) => {
        if (checked) {
            props.array.push(key);
        } else {
            const index = props.array.findIndex(e => e === key);
            props.array.splice(index, 1);
        }
    };
    return (
        <div className="checkbox-group-root">
            {props.checksbox.map((c) => {
                return (
                    <Checkbox
                        key={`checkbox-${c.value}`}
                        label={c.label}
                        value={c.value}
                        defaultChecked={props.array.includes(c.value)}
                        onChange={(e) => handleChecked(c.value, e.value)}
                    />
                );
            })}
        </div>
    );
};

export default CheckBoxGroup;
