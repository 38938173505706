import React from 'react';
import { NavLink } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const ListItemLink = (props) => {
  const { icon, primary, to, isSub } = props;
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, innerRef) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        <NavLink exact to={to} {...linkProps} innerRef={innerRef} />
      )),
    [to],
  )
  return (
    <li>
      <ListItem button component={renderLink} className={isSub ? 'navigation_drawer_list_subitem' : 'navigation_drawer_list_item'}>
        { icon && <ListItemIcon className="navigation_drawer_list_item_icon">{icon}</ListItemIcon>}
        <ListItemText primary={primary} className="navigation_drawer_list_item_text"/>
      </ListItem>
    </li>
  )
}

export default ListItemLink;
