import * as React from 'react'
import ImportsGrid from '../../VertexGrid/Adwone-admin/Imports/ImportsGrid';
import Template from '../Template'

class Imports extends React.Component<any, any> {
    render(){
        return (
            <>
                <div className="grid_container">
                    <ImportsGrid/>
                </div>
            </>
        );
    }
}

export default Template(Imports);