import * as React from "react";
import { ADWColumn } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { ref_GlobalAgreements } from "hub-lib/dto/client/ref_GlobalAgreements.bin";
import { Trad } from "trad-lib";
import { eDialogMode, GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import DefaultGrid from "../DefaultGrid.bin";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { GlobalAgreementsDialog } from "./GlobalAgreementsDialog";
import { GetFirstDayOfYear } from "tools-lib";

const defaultConfiguration: ref_GlobalAgreements = new ref_GlobalAgreements();
defaultConfiguration.Active = true;
defaultConfiguration.Name = "";
defaultConfiguration.BroadcastAreas = null;
defaultConfiguration.Currency = null;
defaultConfiguration.Media = null;
defaultConfiguration.Start = GetFirstDayOfYear();
defaultConfiguration.Discounts = [];
defaultConfiguration.DiscountMode = "cascade";

export const GlobalAgreementsGrid = () => {
    const [grid, setGrid] = React.useState(null);
    const [dialogExist, setDialogExist] = React.useState<ref_GlobalAgreements>(null);
    const [agreement, setAgreement] = React.useState<ref_GlobalAgreements>(null);
    const [mode, setMode] = React.useState<eDialogMode>(null);
    const [ref, setRef] = React.useState<DefaultGrid<ref_GlobalAgreements>>(null);
    React.useEffect(() => {
        if (!grid) {
            const columns: ADWColumn<ref_GlobalAgreements>[] = [];
            const hiddenProperties: string[] = [];
            const properties: string[] = [];
            const grid = new VertexGrid<ref_GlobalAgreements>({
                disableStore: true,
                objectPrototype: ref_GlobalAgreements,
                devMode: false,
                columns,
                order: ["Name", "BroadcastAreas", "Currency", "Media"],
                vertexParams: {
                    Active: true,
                    properties: ["*", ...properties],
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return (<>
        <DefaultGrid
            datesInToolbar
            ref={(ref) => setRef(ref)}
            objectPrototype={ref_GlobalAgreements}
            grid={grid}
            defaultItem={defaultConfiguration}
            dialogContent={(item) => (
                <GlobalAgreementsDialog item={item} />
            )}

            submitDialog={async (item, mode) => {
                setAgreement(item);
                setMode(mode);
                const exists = (await Client.searchVertex(ref_GlobalAgreements.name, { alreadyExist: { ...item, ["@rid"]: mode === eDialogMode.duplicate ? null : item["@rid"] } })).data.results as ref_GlobalAgreements[];
                if (exists.length) {
                    setDialogExist(exists[0]);
                    throw new Error("already_exist");
                }

                switch (mode) {
                    case eDialogMode.create:
                        return (await Client.createVertex(ref_GlobalAgreements.name, item)).data.results;
                    case eDialogMode.modify:
                        return (await Client.updateVertex(ref_GlobalAgreements.name, item)).data.results;
                    case eDialogMode.duplicate:
                        return (await Client.createVertex(ref_GlobalAgreements.name, { ...item, ["@rid"]: null })).data.results;
                }
            }}
            filtersOrder={["Dates", "BroadcastAreas", "Currency", "Media"]}
        />
        <GenericDialog
            open={dialogExist !== null}
            id="custom_items_dialog"
            dialogTitle={`${Trad("attention")}`}
            actions
            dialogProps={{ maxWidth: "md", fullWidth: true }}
            dialogContainerStyle={{ overflow: "visible" }}
            dialogContent={<p>{`Attention, ${dialogExist?.Name !== agreement?.Name ? 'le périmètre défini existe déjà pour ' + dialogExist?.Name : dialogExist?.Name + ' existe déjà'}, voulez-vous l'écraser?`}</p>}
            submitAction={async () => {
                switch (mode) {
                    case eDialogMode.duplicate:
                    case eDialogMode.create:
                        await Client.updateVertex(ref_GlobalAgreements.name, { ...dialogExist, ...agreement, "@rid": dialogExist["@rid"] });
                        break;
                    case eDialogMode.modify:
                        await Client.deleteVertex(ref_GlobalAgreements.name, [agreement["@rid"]]);
                        await Client.updateVertex(ref_GlobalAgreements.name, { ...dialogExist, ...agreement, "@rid": dialogExist["@rid"], "_id": dialogExist["_id"] });
                }
                ref.toggle("dialogOpen");
                setDialogExist(null);
                setAgreement(null);
                setMode(null);
            }}
            submitTitle={Trad("replace")}
            cancelAction={() => {
                setDialogExist(null);
                setAgreement(null);
                setMode(null);
            }}
        />
    </>);
};

