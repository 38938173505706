import React from 'react'
import Template from '../Template'
import { addDays } from "date-fns";
import { ref_History } from "hub-lib/dto/admin/ref_History";
import { ADWColumn, AdwRow, VertexAutocomplete } from 'adwone-lib';
import { extractSub, propertyOf } from 'hub-lib/tools.bin';
import { GenericPopover } from '../../ConfigurableComponents/GenericPopover.bin';
import ReactJson from 'react-json-view';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { Trad, TradClassName, TradProp } from 'trad-lib';
import { VertexGrid } from '../../VertexGrid/Generic/VertexGrid.bin';
import { GetCellTemplate } from 'format-lib/index.bin';
import Loader from '../Loader';
import { ConfigurationPanel } from '../../VertexGrid/Messages/ConfigurationPanel';
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from '../../VertexGrid/Generic/ToolbarAdw';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker';
import { GetToday } from 'tools-lib';
import { Element } from '../../VertexGrid/Generic/Common.bin';
import { src_MM } from 'hub-lib/models/orientdb/src_MM.bin';
import { ref_Customers } from 'hub-lib/models/orientdb/ref_Customers.bin';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Estimates } from 'hub-lib/dto/client/ref_Estimates.bin';

let anchorId = 0;
class History extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {}
  }

  createColJson = (bindingPath: string) => {
    const openLog = new ADWColumn<ref_History>(TradProp(bindingPath), bindingPath, ePropType.String, false);
    openLog.width = 110;
    openLog.cellValue = (cellValue: any, dataItem?: AdwRow<ref_History>) => {

      const anchorKey = `anchor-${anchorId++}`;
      const changes = dataItem.dataItem?.[bindingPath];
      const previousObj = dataItem.dataItem?.["previousObject"];
      const newObj = dataItem.dataItem?.["newObject"];

      dataItem[`${bindingPath}_cellValue`] = changes ? JSON.stringify(changes) : "";

      const previousData = (changes && previousObj) ? extractSub(previousObj, Object.keys(changes)) : undefined;
      const newData = (changes && newObj) ? extractSub(newObj, Object.keys(changes)) : newObj;

      return <>
        {newData && <div id={anchorKey}>
          <GenericPopover anchorParent={anchorKey} icon="zoom">
            <div style={{ display: "flex" }}>
              {previousData &&
                <div>
                  <div className="adw-title">
                    <div>{TradProp("previousObject", ref_History)}</div>
                    <div style={{ fontSize: "12px" }}>{new Date(previousObj["MD_upsertDate"]).toLocaleString()}</div>
                  </div>
                  <ReactJson src={previousData} displayDataTypes={false} sortKeys displayObjectSize={false} />
                </div>
              }
              <div>
                <div className="adw-title">
                  <div>{TradProp("newObject", ref_History)}</div>
                  <div style={{ fontSize: "12px" }}>{new Date(newObj["MD_upsertDate"]).toLocaleString()}</div>
                </div>
                <ReactJson src={newData} displayDataTypes={false} sortKeys displayObjectSize={false} />
              </div>
            </div>
          </GenericPopover>
        </div>}
      </>
    };
    return openLog;
  }

  componentDidMount() {
    const columns: ADWColumn<ref_History>[] = [];
    const hiddenProperties: string[] = [];
    hiddenProperties.push(propertyOf<ref_History>("previousObject"));
    hiddenProperties.push(propertyOf<ref_History>("newObject"));
    hiddenProperties.push(propertyOf<ref_History>("changes"));
    hiddenProperties.push(propertyOf<ref_History>("rid"));
    hiddenProperties.push(propertyOf<ref_History>("checked"));

    const srcColonne = new ADWColumn<ref_History>(TradProp("Source"), "SourceName", ePropType.String, false);
    srcColonne.width = 220;
    columns.push(srcColonne);

    const typeColonne = new ADWColumn<ref_History>(TradProp("Type"), "Type", ePropType.String, false);
    typeColonne.cellValue = (cellValue: any, row?: AdwRow<ref_History>) => {
      const className = row?.dataItem?.previousObject?.["@class"] ?? row?.dataItem?.newObject?.["@class"];
      const classNameTrad = className
        ? TradClassName(className)
        : (Object.keys(row?.dataItem?.newObject?.Import?.Data ?? {}).some(k => k.includes('estimate')) ? TradClassName(ref_Estimates.name) : Trad("Unknown"));
      row[`Type_cellValue`] = classNameTrad;
      return <>{classNameTrad}</>;
    }
    typeColonne.width = 220;
    columns.push(typeColonne);

    const labelColonne = new ADWColumn<ref_History>(TradProp("Label"), "newObject.Label", ePropType.String, false);
    labelColonne.cellValue = (cellValue: any, row?: AdwRow<ref_History>) => {
      const label = row?.dataItem?.previousObject?.Label ?? row?.dataItem?.newObject?.Label ?? row?.dataItem?.newObject?.Import?.Data?.name;
      row[`newObject.Label_cellValue`] = label;
      return <>{label}</>;
    };

    labelColonne.width = 220;
    columns.push(labelColonne);
    columns.push(new ADWColumn<ref_History>(TradProp("ExternalID"), "newObject.ExternalID", ePropType.String, false));
    const actionColumn = new ADWColumn<ref_History>(TradProp("Action"), "Action", ePropType.String, false);
    actionColumn.cellValue = (cellValue: any, row?: AdwRow<ref_History>) => {
      let action = Trad("creation");
      if (row?.dataItem?.previousObject)
        action = Trad("modification");
      row[`Action_cellValue`] = action;
      return <>{action}</>;
    }
    actionColumn.width = 220;
    columns.push(actionColumn);
    //columns.push(this.creatactioneColJson("previousObject"));
    //columns.push(this.createColJson("newObject"));
    columns.push(this.createColJson("changes"));


    const checkedColumn = new ADWColumn<ref_History>(TradProp("Checked"), "Checked", ePropType.Boolean, false);
    checkedColumn.cellValue = (cellValue: any, row?: AdwRow<ref_History>) => {
      return <Checkbox
        checked={row.dataItem?.checked}
        onChange={e => {
          row.dataItem.checked = e.value;
          Client.updateVertex(ref_History.name, { '@rid': row.dataItem['@rid'], checked: e.value });
        }} />
    }
    columns.push(checkedColumn);

    /** Date format */
    const columnDate = new ADWColumn<ref_History>(Trad(("date") as any), propertyOf<ref_History>("date"), ePropType.Date, false);
    columnDate.noTemplate = true;
    columnDate.getValue = async (row: ref_History) => {

      let cellValue: any = row.date;
      if (!cellValue || cellValue === "") return "";

      let str = GetCellTemplate(ePropType.Date)(cellValue);

      let d: Date = new Date(cellValue);
      let h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
      let m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
      let s = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
      let time = `${h}:${m}:${s}`

      return `${str} ${time}`;
    }
    columnDate.cellValue = (cellValue: any, row?: AdwRow<ref_History>) => {
      const date = row.dataItem.date;
      const value = date ? new Date(date) : 0;
      row["date_cellValue"] = value
      return value;
    }
    columnDate.indexOrder = 1
    columnDate.width = 300
    columns.push(columnDate);

    hiddenProperties.push(propertyOf<ref_History>("source"));
    hiddenProperties.push(propertyOf<ref_History>("date"));
    const conf = new TState().config;
    const grid = new VertexGrid<ref_History>({
      objectPrototype: ref_History,
      devMode: false,
      columns,
      width: {
      },
      // order: ["@rid", "Layer", categoryPath, actionPath, "User", "Date", "CallID"],
      vertexParams: {
        ...conf,
        properties: ["*", "source.MasterEndpoint as SourceName"]
      },
      hiddenProperties,
    });
    this.setState({
      grid
    })
  }

  removeAll = (config: any) => {
    if (!config) return {};
    let newParams: any = { ...config }
    for (let [key, value] of Object.entries(config)) {
      if (value === Trad("all"))
        delete newParams[key]

      if (value && Array.isArray(value) && !value.length)
        delete newParams[key]
    }
    return newParams
  }

  onConfChange = (config: any) => {
    let vertexParams = this.state.grid.props.vertexParams
    this.state.grid.props.vertexParams = this.removeAll({ ...vertexParams, ...config }) ?? {};
    this.state.grid.UpdateRows();
  }

  render() {

    if (!this.state.grid)
      return <Loader />;
    const confComponent = <ConfigurationPanel
      elements={[{
        type: "icon",
        title: () => Trad("filters"),
        icon: "filterAlt",
        element: <FiltersComponent onConfChange={this.onConfChange} config={this.props.config} />
      }]} />

    return (
      <div className="grid_container">
        <div style={{ width: '100%' }}>
          <ToolbarAdw>
            <ConfigurationPanelContainer>
              {confComponent}
            </ConfigurationPanelContainer>
            <BreadcrumbsCustomContainer>
              <BreadcrumbsCustom
                elements={[
                  { text: Trad("home"), href: "/" },
                  { text: Trad("logs_mgt") }
                ]} />
            </BreadcrumbsCustomContainer>
          </ToolbarAdw>
          <AdwTelerikGrid
            grid={this.state.grid}
            sort={[{ field: "date", dir: "desc" }]}
            uneditable
            hideToolbar
          />
        </div>
      </div>
    )
  }
}

export default Template(History);

class TState {
  config: {
    Start: Date,
    End: Date,
    source: string[]
  }

  /**
   *
   */
  constructor() {
    this.config = {
      Start: GetToday(),
      End: addDays(GetToday(), 1),
      source: []
    }
  }
}

class TProps {
  config: TState["config"];
  onConfChange: (conf: TState["config"]) => void;
}

export class FiltersComponent extends React.Component<TProps, TState> {

  constructor(props: TProps) {
    super(props);
    let newState = new TState();
    if (this.props.config)
      newState.config = { ...newState.config, ...this.props.config };
    newState.config.Start = new Date(newState.config.Start)
    newState.config.End = addDays(new Date(newState.config.Start), 1)
    this.state = newState;
  }

  render() {
    let { config } = this.state;
    return (
      <>
        <Element className="adw-row">
          <DateRangepicker
            defaultStart={config.Start}
            defaultStop={config.End}
            handleChangeDate={(v) => {
              config.Start = v.start
              config.End = v.end
              this.forceUpdate();
              this.props.onConfChange(this.state.config);
            }} />

        </Element>
        <Element className="adw-row">
          <VertexAutocomplete
            multiple={true}
            params={{ properties: ["MasterEndpoint"], Active: true }}
            type={src_MM.name}
            label={TradClassName(ref_Customers.name)}
            getOptionLabel={o => o.MasterEndpoint}
            defaultValue={(options: src_MM[]) => config?.source?.map(g => options.find(o => o["@rid"] == g)).filter(e => e) ?? []}
            onChange={async (values: src_MM[]) => {
              config.source = values?.map(v => v["@rid"]);
              this.forceUpdate();
              this.props.onConfChange(this.state.config);
            }} />
        </Element>
      </>
    )
  }
}