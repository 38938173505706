import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Client } from 'hub-lib/client/client.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_Offers } from 'hub-lib/dto/client/ref_Offers.bin';
import { KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { ref_Model } from 'hub-lib/models/orientdb/ref_Model.bin';
import { clone, extract, extractSub, GetHashCode, propertyOf } from 'hub-lib/tools.bin';
import { ref_OffersSerializable } from './messageEditorSlice';
import { RootState } from './store';

export interface OfferState {
    // currentTCD: AggregateExport;
    // pivotGridConfigToShow: ref_PivotGridConfigurations;
    // data: Pick<ref_Messages, "Support" | "BroadcastArea" | "Media" | "Format" | "Placement" | "ModelProperties">,
    data: {
        Support: ref_Messages["Support"],
        BroadcastArea: ref_Messages["BroadcastArea"][],
        // Media: ref_Messages["Media"][],
        Format: ref_Messages["Format"][],
        Placement: ref_Messages["Placement"][],
        ModelProperties: ref_Messages["ModelProperties"],
        Start: Date,
        End: Date
    }
    offers: ref_OffersSerializable[];
    get: () => OfferState["data"]
}

const initialState: OfferState = {
    // currentTCD: null,
    // pivotGridConfigToShow: null
    data: null,
    offers: null,
    get() {
        return this.data
    },
}

export const setOfferData = createAsyncThunk(
    'offer/setOfferData',
    async (data: Partial<OfferState['data']>, thunkAPI) => {
        console.log("[setData]", data);

        const state = thunkAPI.getState() as RootState;
        const currentData: typeof data = clone(state.offer.data ?? {});
        const currentOffers = state.offer.offers;

        let offers: ref_OffersSerializable[] = [];
        try {

            // check if something changed
            if (GetHashCode(extractSub(currentData, ['BroadcastArea', 'Support', 'Start', 'End']))
                == GetHashCode(extractSub(data, ['BroadcastArea', 'Support', 'Start', 'End']))) {
                console.log(`[setOfferData] nothing changed`, extractSub(currentData, ['BroadcastArea', 'Support', 'Start', 'End']), extractSub(data, ['BroadcastArea', 'Support', 'Start', 'End']));
                return { data, offers: currentOffers };
            }

            Object.assign(currentData, data);

            if (data.BroadcastArea && data.Support) {

                const medias = await KPIsManagerCache.GetMedias();
                const presse = medias?.find(media => media.Code == "PR")?.["@rid"];

                const model: ref_Model = (await Client.searchVertex(ref_Model.name, {
                    [propertyOf<ref_Model>("DocumentType")]: "Offer",
                    [propertyOf<ref_Model>("Country")]: data.BroadcastArea,
                    [propertyOf<ref_Model>("MediaTypes")]: [presse]
                }))?.data?.results?.[0] as ref_Model;

                if (model)
                    offers = (await Client.searchVertex(ref_Offers.name, {
                        Support: data.Support,
                        BroadcastArea: data.BroadcastArea,
                        Media: presse,
                        Model: model["@rid"],
                        Start: data.Start ? data.Start : undefined,
                        End: data.End ? data.End : undefined
                    }))?.data?.results;
            }

        }
        catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
        return { data, offers };
    })

export const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        setDataSync: (state, action: PayloadAction<Partial<OfferState['data']>>) => {
            console.log("[setDataSync]", action.payload);

            const currentData = clone(state.data ?? {}) as any;
            Object.assign(currentData, action.payload);

            state.data = currentData;
        }
        // setCurrentTCD: (state, action: PayloadAction<AggregateExport>) => {
        //     // state.currentTCD = action.payload;
        // },
        // setPivotGridConfigToShow: (state, action: PayloadAction<ref_PivotGridConfigurations>) => {
        //     // state.pivotGridConfigToShow = action.payload;
        // }
    },
    extraReducers: (builder) => {
        const fulfilledCallback = (state: OfferState, action) => {

            console.log("[setOfferData.fulfilled]", action.payload);

            if (action.payload?.data)
                state.data = action.payload.data;
            if (action.payload?.offers)
                state.offers = action.payload.offers;
        }

        builder.addCase(setOfferData.fulfilled, (state, action) => {
            fulfilledCallback(state, action);
            // state.lockNext = false;
        });
    },
})

export const { setDataSync } = offerSlice.actions
export const offerReducer = offerSlice.reducer;