
import { TextField } from "@material-ui/core";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import * as React from "react";
import { AdwAutocomplete } from "adwone-lib/index";
interface TProps<T> {
    choices: T[];
    defaultValue?: T;
    initValue?: T[];
    max?: number;
    onChange: (ventilations: T[]) => any;
    transform?: (elmnt: T) => void;
    label?: string;
}
interface IState<T> {
    choices: T[];
    comboboxes: T[];
    defaultvalue: T;
}

export default class MultipleCombo<T> extends React.Component<TProps<T>, IState<T>> {
    constructor(props: any) {
        super(props);

        this.state = {
            choices: [],
            comboboxes: [],
            defaultvalue: undefined
        }
    }

    setComboboxes(_choices: T[], _default: T, _initvalues: T[]): void {
        const defaultPtr: T[] = [];
        if (_initvalues) {
            const found = _initvalues.filter(iv => iv).map(iv => _choices.find(c => JSON.stringify(c) === JSON.stringify(iv))).filter(iv => iv)
            found?.forEach(f => defaultPtr.push(f))
        }

        if (_default)
            if (!this.props.max || defaultPtr.length < this.props.max)
                defaultPtr.push(_default);

        this.setState({
            choices: _choices,
            comboboxes: defaultPtr,
            defaultvalue: _default
        });
    }

    componentDidMount() {
        this.setComboboxes(this.props.choices, this.props.defaultValue, this.props.initValue ?? [])
    }

    render() {
        const { label } = this.props;
        const { choices, defaultvalue, comboboxes } = this.state;
        const changeHandler = (index: number, val: any) => {

            let { comboboxes } = this.state;

            comboboxes[index] = val.value;
            comboboxes = comboboxes.filter(v => JSON.stringify(v) !== JSON.stringify(defaultvalue));
            const toSend = [...comboboxes];

            if (defaultvalue)
                if ((!this.props.max || this.props.max > comboboxes.length)
                    && JSON.stringify(comboboxes[comboboxes.length - 1]) !== JSON.stringify(defaultvalue))
                    comboboxes.push(defaultvalue);

            this.setState({ comboboxes }, this.props.onChange(toSend));
        }

        const manageChoices = (Choices: any[], Comboboxes: any) => {
            let newChoices = Choices.filter(x => Comboboxes.indexOf(x) == -1)
            if (this.props.defaultValue)
                return [this.props.defaultValue, ...newChoices]
            return [...newChoices]
        }

        let { transform } = this.props;


        let mapValue = (c: T) => {
            return {
                value: c,
                label: transform ? transform(c) : c
            }
        }

        return (

            <div>
                {comboboxes.map((ch, index) => (
                    <div className="margin_top_dialog_export margin_bottom_dialog_export" key={`${ch}${index}`}>
                        <AdwAutocomplete
                            disableClearable
                            options={manageChoices(choices, comboboxes).map(mapValue)}
                            value={mapValue(ch)}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(event, value) => changeHandler(index, value)}
                            renderInput={((params: AutocompleteRenderInputParams) => (
                                <TextField
                                    {...params}
                                    label={(label && comboboxes.length > 1) ? `${label} ${index + 1}` : label}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            ))}
                        />
                    </div>
                ))}
            </div>
        );
    }
}