import { ref_CurrenciesId } from "../../models/orientdb/ref_Currencies.bin";
import { ref_BroadcastAreasId } from "../../models/orientdb/ref_BroadcastAreas.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { ref_MediaId } from "../../models/orientdb/ref_Media.bin";
import { ref_Discount } from "../../models/types/vertex.bin";
import { IUpsertInfo } from "../../types";
import { User } from "../../models/orientdb/User.bin";

export type ref_AgreementsId = rid;

export class ref_GlobalAgreements implements IUpsertInfo {
    "@rid"?: ref_AgreementsId;
    Active: boolean;
    Name: string;
    BroadcastAreas: ref_BroadcastAreasId[];
    Currency: ref_CurrenciesId;
    Media : ref_MediaId;
    Start: Date;
    End?: Date;
    Created_by?: User["@rid"];
	Created_at?: Date;
	Updated_by?: User["@rid"];
	Updated_at?: Date;
    DiscountMode?: "cascade" | "cumul" | "mixed";
    Discounts?: ref_Discount[];
}