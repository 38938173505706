import * as React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin'
import { Trad, TradClassName } from 'trad-lib'
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'
import { Referentials } from 'hub-lib/models/orientdb/Referentials.bin'
import { CompanyInfosForm } from '../../VertexGrid/Adwone-admin/Referential/CompanyInfosForm'
import { AdressForm } from '../../VertexGrid/Adwone-admin/Referential/AdressForm'
import { CustomTextFieldUpper } from '../../VertexGrid/Generic/CustomTextField'

const TvaRegex = new RegExp("^([a-zA-Z]{2}[0-9]{11})$")

class TProps {
    referential: ref_Companies;
    child?: boolean;
    deleteChild?: (e: Referentials) => void
}

export class NewClientForm extends React.Component<TProps>{
    constructor(props: TProps) {
        super(props)
    }

    render() {
        let { referential, child } = this.props
        let className = referential["@class"] ?? referential.constructor.name;
        return (
            <Grid container className={child ? "panel-conf-container" : ""}>
                <Grid item xs={12} className="adw-title">
                    {TradClassName(className)}
                </Grid>

                <Grid item xs={12} className="message_details_full">
                    <CustomTextFieldUpper
                        label={`${Trad("property_Name")} *`}
                        value={referential.Name}
                        onChange={(e) => {
                            referential.Name = e;
                            this.forceUpdate()
                        }}
                    />
                </Grid>
                <AdressForm element={referential} />
                <CompanyInfosForm element={referential} />
                {child &&
                    <div className="adw-form-action">
                        <CustomButton Label={Trad("delete")}
                            className="custom_btn_danger" onClick={() => { this.props.deleteChild(referential) }} />
                    </div>
                }
            </Grid>
        )
    }
}