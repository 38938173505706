import * as React from 'react'
import { LinkManager } from './LinkManager/LinkManager.bin';
import Template from '../Template'

class DataLink extends React.Component<any, any> {
    render() {
        return (
            <>
                <div className="grid_container" style={{ padding: '5px 5px', background: '#efefef', height: "calc(100vh - 76px)" }}>
                    <LinkManager />
                </div>
            </>
        );
    }
}

export default Template(DataLink);