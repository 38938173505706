import * as React from 'react'

import { Client } from 'hub-lib/client/client.bin'
import { Trad } from 'trad-lib';
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { BadgeTemplate } from '../../crossedTable/scheduler/GroupComponent';
import { Loader } from '@progress/kendo-react-indicators';
import { GetUser, IsMMUser, IsSellsyUser } from '../../../utils/localstorage.bin';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';

const CSSAPIReport: React.CSSProperties = {
  alignItems: 'center',
  display: 'inline-flex'
}

type APIReportProps = {
  label: string,
  version?: string,
  color?: string,
  loading?: boolean,
  borderBottom?: string
  element?: JSX.Element
}

const APIReport = ({ label, version, color, loading, borderBottom, element }: APIReportProps) =>
  <div style={{ width: '100%', minHeight: 30, borderBottom: borderBottom ?? "1px solid #DDDDDD", display: 'flex', alignItems: "center" }}>
    <span style={{ width: "75%", justifyContent: "space-between", ...CSSAPIReport }}>{label} <div style={{paddingRight: 10}}>{element}</div></span>
    {/* <span style={{ width: "10%", textAlign: 'right', ...CSSAPIReport }}>{element}</span> */}
    <span style={{ width: "10%", textAlign: 'right', position: 'relative', height: "100%", ...CSSAPIReport }}>
      {loading && <Loader />}
      {!loading && color && <BadgeTemplate style={{ color, background: color, borderColor: color, position: "relative", transform: "none" }} />}
    </span>
    <span style={{ width: "20%", textAlign: 'right', ...CSSAPIReport }}>{version}</span>
  </div>


class StatusAPIMMState {
  mediaOceanVersion?: string
  mediaOceanLastBuild?: string
  loading?: boolean = true;
  error?: boolean;
}

class StatusAPIAdwoneState {
  serverVersion?: string
  clientVersion?: string
  loading?: boolean = true;
  error?: boolean;
}

class StatusAPIsState {
  apis?: { name: string, code: number }[]
  loading?: boolean = true;
  error?: boolean;
}

class StatusSellsyState {
  connected?: boolean;
  loading?: boolean = true;
  error?: boolean;
}

export function StatusAPI() {

  const [stateAdw, setStateAdw] = React.useState<StatusAPIAdwoneState>(new StatusAPIAdwoneState);
  const [stateMM, setStateMM] = React.useState<StatusAPIMMState>(new StatusAPIMMState);
  const [stateAPIs, setStateAPIs] = React.useState<StatusAPIsState>(new StatusAPIsState);
  const [stateSellsy, setStateSelly] = React.useState<StatusSellsyState>(new StatusSellsyState);
  const user = GetUser();

  React.useEffect(() => {
    if (stateAdw.loading)
      Client.getHubVersion()
        .then(async v => {
          setStateAdw({
            serverVersion: v?.data,
            clientVersion: process.env.REACT_APP_VERSION
          })
        }).catch(e => setStateAdw({ error: true }));
  }, [stateAdw.loading])

  React.useEffect(() => {
    if (stateAdw.loading) {
      if (user?.customer?.Authorization === "MediaManager")
        Client.getMMVersion()
          .then(v => {
            setStateMM({
              mediaOceanVersion: v?.data?.build,
              mediaOceanLastBuild: v?.data?.buildDate
            })
          })
          .catch(e => setStateMM({ error: true }))
    }
  }, [stateMM.loading])

  React.useEffect(() => {
    if (stateAPIs.loading) {
      if (user.profileName == "super-administrateur")
        Client.getStatusAPIAdmin()
          .then(v => setStateAPIs({ apis: Object.entries(v).flatMap(([client, values]) => Object.values(values).map(api => ({ ...api, name: client + ' ' + Trad(api.name) }))) }))
          .catch(e => setStateAPIs({ error: true }))
      else
        Client.getStatusAPI()
          .then(v => {
            if (typeof v != 'object')
              return;
            setStateAPIs({ apis: Object.values(v) })
          })
          .catch(e => setStateAPIs({ error: true }))
    }
  }, [stateAPIs.loading])

  React.useEffect(() => {
    if (stateSellsy.loading) {
      Client.healthSellsy().then(v => setStateSelly({ connected: true })).catch(e => setStateSelly({ error: true }))
    }
  }, [stateSellsy.loading])

  return <>
    <APIReport
      label={Trad("client_version")}
      loading={stateAdw.loading}
      color={stateAdw.error ? 'red' : 'green'}
      version={stateAdw?.clientVersion} />

    <APIReport
      label={Trad("server_version")}
      loading={stateAdw.loading}
      color={stateAdw.error ? 'red' : 'green'}
      version={stateAdw?.serverVersion} />

    {IsMMUser()
      && <>
        <APIReport
          label={Trad("mediaocean_version")}
          loading={stateMM.loading}
          color={stateMM.error ? 'red' : 'green'}
          borderBottom=''
          version={stateMM?.mediaOceanVersion} />

        <APIReport
          label={Trad("mediaocean_lastBuild")}
          loading={stateMM.loading}
          version={stateMM?.mediaOceanLastBuild} />
      </>}

    {IsSellsyUser()
     && <>
      <APIReport
        label={Trad("sellsy_state")}
        loading={stateSellsy.loading}
        color={stateSellsy.error ? 'red' : 'green'}
        element={
          !stateSellsy.loading ? ( stateSellsy.connected ? <CustomButton Label={Trad("logout")} className="custom_btn_primary" onClick={() => {
            Client.logoutSellsy().then(() => window.location.reload())
          }} /> : <CustomButton Label={Trad("login")} className="custom_btn_primary" onClick={() => {
            Client.getSellsyInfos().then((resp) => window.location.href = resp.data.urlAuthorization);
          }} />) : null
        }
      />
     </>}

    {stateAPIs?.apis?.map((a, i) => <APIReport
      key={`api-${a.name}-${i}`}
      color={a.code >= 500 ? 'red' : 'green'}
      label={Trad(a.name)}
      version={a.code?.toString()} />)}
  </>
}

export function StatusAPIDialog({ onClose }) {
  return <GenericDialog
    dialogTitle={Trad('status_api')}
    closable
    open={true}
    handleClose={onClose}
    id='about_dialog'
    dialogContent={<StatusAPI />} />
}