import { ref_Contacts } from "../../models/orientdb/ref_Contacts.bin";
import { ref_Persons } from "../../models/orientdb/ref_Persons.bin";

export class ref_PersonExtended extends ref_Persons {
    Contact: Partial<ref_Contacts>;

    constructor() {
        super();
        this.Contact = new ref_Contacts();
    }
}