import * as React from 'react'
import { useSelector } from "react-redux";
import { GetMousePosition } from '../PointerManager';
import { RootState, store } from '../redux/store';
import { hideTooltip, showTooltip, TooltipState } from '../redux/tooltipSlice';
import { ConsoleDebug } from '../utils/localstorage.bin';

export class TooltipManager {

    private static _timeout;
    private static _interval;

    static Push(tooltip: Pick<TooltipState, "text"> & { target }) {

        store.dispatch(hideTooltip());

        clearInterval(TooltipManager._interval);
        clearTimeout(TooltipManager._timeout);
        if (!tooltip.target) {
            return;
        }

        TooltipManager._interval = setInterval(() => {
            const newTarget = GetMousePosition().target;
            if (newTarget !== tooltip.target) {
                TooltipManager.Hide();
            }
        }, 100)

        TooltipManager._timeout = setTimeout(() => {
            store.dispatch(showTooltip({
                text: tooltip.text,
                position: {
                    left: GetMousePosition()?.pointerX ?? 0,
                    top: GetMousePosition()?.pointerY ?? 0
                }
            }))
        }, 1000);
    }

    static Hide() {
        clearInterval(TooltipManager._interval);
        clearTimeout(TooltipManager._timeout);
        store.dispatch(hideTooltip());
    }
}

export function CustomTooltip({ children }) {

    const position = useSelector((state: RootState) => state.tooltip?.position);
    const text = useSelector((state: RootState) => state.tooltip?.text);
    const [left, setLeft] = React.useState(0);

    return <>
        {children}
        <div
            ref={r => {
                if (!r || !window?.innerWidth || r?.offsetWidth == undefined)
                    return;
                if ((r.offsetWidth + (position?.left ?? 0) + 20) > window.innerWidth) {
                    const decal = r.offsetWidth + (position?.left ?? 0) - window.innerWidth;
                    const newDecal = decal + 20;
                    const diff = Math.abs(newDecal - left);
                    if (diff > 2) {
                        ConsoleDebug("r.offsetWidth", r.offsetWidth);
                        ConsoleDebug("position?.left", position?.left);
                        ConsoleDebug("window.innerWidth", window.innerWidth);
                        ConsoleDebug("tooltip setLeft", newDecal);
                        setLeft(newDecal);
                    }
                } else if (left != 0) setLeft(0);
            }}
            key='tootilp-custom-adw'
            className='custom-tooltip-adw'
            style={{
                position: 'absolute',
                left: (position?.left ?? 0) - (left ?? 0),
                top: position?.top ?? 0,
                opacity: text ? 1 : 0,
                visibility: text ? 'visible' : 'hidden'
            }}><span>{text}</span></div>
    </>
}