import * as React from "react";
import { GenericPopover } from "./GenericPopover.bin";
import ReactJson from "react-json-view";
import { Client } from "hub-lib/client/client.bin";

let anchorId = 0;

type JSONViewerType = { data: any, onClick?: () => void }
export function JSONViewer({ data, onClick }: JSONViewerType) {
    const [anchorKey] = React.useState(`JSONViewer-${anchorId++}`);
    return <div id={anchorKey} style={{ textAlign: "center" }}>
        <GenericPopover anchorParent={anchorKey} icon="zoom" onClick={onClick}>
            <ReactJson src={data} displayDataTypes={false} sortKeys displayObjectSize={false} />
        </GenericPopover>
    </div>
}

type JSONRequestViewerType = { id: string, docType: string }
export function JSONRequestViewer({ id, docType }: JSONRequestViewerType) {

    const [data, setData] = React.useState<any>(undefined);

    const load = async () => {
        console.log("[load]", id, docType);
        if (data !== undefined || !id) return;
        const res = (await Client.searchVertex(docType, { "@rid": id }).catch(e => { console.log(e); return null; }))?.data?.results?.[0];
        setData(res);
    }

    return <JSONViewer data={data ?? {}}
        onClick={load} />
}