import { ref_Media, ref_MediaId } from "./ref_Media.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Media, mm_MediaId } from "./mm_Media.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasMediaId = rid;

export class vw_mm_HasMedia extends ReferentialHasViews<src_MMId, mm_MediaId, ref_MediaId> {
	"@rid"?: vw_mm_HasMediaId;

}