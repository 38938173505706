import * as React from 'react';

import { GetCurrentLocale, Trad } from 'trad-lib';
import { withRouter } from 'react-router-dom';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Messages, eStatusType } from 'hub-lib/dto/client/ref_Messages.bin';
import { AdwRow, eRowEvent } from 'adwone-lib/index';
import { ref_Estimates } from 'hub-lib/dto/client/ref_Estimates.bin';
import { GetSort } from 'format-lib/index.bin';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { Filter } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import { GetLastDayOfYear } from 'tools-lib';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { VertexGrid } from '../Generic/VertexGrid.bin';
import { AdwTelerikGrid } from '../Generic/AdwTelerikGrid.bin';
import Loader from '../../layout/Loader';
import { CustomAddMessage } from './customAddMessage.bin';
import { CustomDropMessage } from './customDropMessage.bin';
import { EstimateDetailsFiltersComponent } from './EstimateDetailsFiltersComponent.bin';
import { MessagesActions } from './MessagesActions.bin';

import { Notify, NotifyError } from '../../../utils/Notify.bin';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { styleGridContainer } from '../../../styles/theme';
import {
    BreadcrumbsCustomContainer,
    ConfigurationPanelContainer,
    SelectedItemsContainer,
    ToolbarContainer,
} from '../Generic/ToolbarAdw';
import { ConfigurationPanel } from '../Messages/ConfigurationPanel';
import history from '../../../utils/history';
import { CreateGrid, GetDefaultColumnConfiguration } from '../../VertexGrid/Generic/ModeleGrid/MessagesGridCreator';
import { eDialogMode } from '../../ConfigurableComponents/GenericDialog.bin';
import { MessagesDialog } from '../Messages/MessagesDialog.bin';
import { RootState, SetMode, store } from '../../../redux/store';
import { setMessageSync } from '../../../redux/messageEditorSlice';
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin';
import { CustomBadge } from '../Adwone-admin/Referential/BadgesUsage.bin';
import { ModeleColumnsCreator } from '../ModeleCreator/ModeleColumnsCreator.bin';
import { Element } from "../Generic/Common.bin";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { MessagesToolbar } from '../Messages/MessagesToolbar';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { setTable } from '../../../redux/gridConfigurationsSlice';
import { DataGrid } from '../Generic/ModeleGrid/DataGrid';
import { DataGridComponent } from '../Generic/ModeleGrid/DataGridComponent';
import { ModeleGrid } from '../Generic/ModeleGrid/ModeleGrid';
import { eIndicateurType, IndicateurComputed, IndicateurLink } from 'adwone-engine/index.bin';
import { clone, propertyOf, Typed } from 'hub-lib/tools.bin';
import { vw_mm_HasFormat } from 'hub-lib/models/orientdb/vw_mm_HasFormat.bin';
import { src_MM } from 'hub-lib/models/orientdb/src_MM.bin';
import { vw_tsm_HasFormat } from 'hub-lib/models/orientdb/vw_tsm_HasFormat.bin';
import { src_TSM } from 'hub-lib/models/orientdb/src_TSM.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { vw_tsm_HasEmplacement } from 'hub-lib/models/orientdb/vw_tsm_HasEmplacement.bin';
import { vw_mm_HasPlacement } from 'hub-lib/models/orientdb/vw_mm_HasPlacement.bin';
import { vw_mm_HasCurrency } from 'hub-lib/models/orientdb/vw_mm_HasCurrency.bin';
import { vw_mm_HasAdvertisingCompanies } from 'hub-lib/models/orientdb/vw_mm_HasAdvertisingCompanies.bin';
import { vw_mm_HasSupport } from 'hub-lib/models/orientdb/vw_mm_HasSupport.bin';
import { vw_mm_HasBroadcastArea } from 'hub-lib/models/orientdb/vw_mm_HasBroadcastArea.bin';
import { IsMMUser } from '../../../utils/localstorage.bin';

// const IconLeftAccordionSummary = withStyles({
//     expandIcon: {
//         order: -1,
//     },
// })(AccordionSummary);

let timeout: any = null;

class TState {
    estimate: ref_Estimates;
    Advertiser: any;
    AdvertiserGroup: any;
    Brand: any;
    config: Filter;
    expandItems = true;

    /**
     * Grid of already bound messages
     */
    gridAdded?: DataGrid<ref_Messages>;


    /**
     * Grid of available messages to bound
     */
    gridAvailables?: DataGrid<ref_Messages>;
    // gridAvailablesLength: number;

    // messageEdited?: ref_Messages;
    Product: any;
    selectedItems: AdwRow<ref_Messages>[] = [];
    selectedItemsToEstimate: AdwRow<ref_Messages>[] = [];
    sort?: SortDescriptor[];
    viewMode: "AddedTable" | "AvailableTable" = "AvailableTable";

}
class EstimateDetails extends React.Component<any, TState> {
    constructor(props: any) {
        super(props);

        this.state = new TState();
    }

    async componentDidMount() {

        console.log("[componentDidMount]");

        const rid = decodeURIComponent(this.props.match.params.id);
        const [estimate] = await Client.searchVertexTyped(ref_Estimates, { "@rid": rid, Active: true });

        if (!estimate)
            history.push('/estimates');
        else {
            const config = new Filter();
            if (estimate.Campaign) {
                config.Campaign = estimate.Campaign as any;
            } else {
                config.AdvertiserGroup = estimate.AdvertiserGroup ? [estimate.AdvertiserGroup] : [];
                config.Advertiser = estimate.Advertiser ? [estimate.Advertiser] : [];
                config.Brand = estimate.Brand ? [estimate.Brand] : [];
                config.Product = estimate.Product ? [estimate.Product] : [];
                config.Media = estimate.Media ? [estimate.Media] : [];
                config.Start = new Date(estimate.Start);
                config.End = estimate.End ? new Date(estimate.End) : GetLastDayOfYear();
            }
            config.Status.push(eStatusType.Confirmed);
            for (const [key, value] of Object.entries(config)) {
                if (!value || (Array.isArray(value) && value.length == 0))
                    delete config[key];
            }
            this.setState(
                {
                    estimate,
                    config,
                }, () => {
                    if (!this.state.gridAdded && !this.state.gridAvailables)
                        this.redraw();
                }
            );
        }
    }

    onConfChange = (state: any) => {

        console.log(`[onConfChange]`)

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const { gridAvailables, gridAdded } = this.state;
            const newParams = { ...state };
            for (const [key, value] of Object.entries(state)) {
                if (!value || (Array.isArray(value) && value.length == 0))
                    delete newParams[key];
            }
            this.setState({ config: newParams });
            const vertexParamsAvailables = gridAvailables.props.vertexParams;
            for (const [key] of Object.entries(new Filter()))
                delete vertexParamsAvailables[key];

            gridAvailables.props.vertexParams = { ...vertexParamsAvailables, ...newParams };
            gridAdded.UpdateRows();
            gridAvailables.UpdateRows();
        }, 500);
    };

    error = (msg: string) => {
        Notify(msg, 'error');
    };

    refreshContent = () => {
        const { gridAdded, gridAvailables } = this.state;
        //gridAdded.Initialize();
        //gridAvailables.Initialize();
    };

    updateContent = (update?: string | boolean) => {
        const { gridAdded, gridAvailables } = this.state;
        gridAdded.UpdateRows();
        if (!update)
            gridAvailables.UpdateRows();
        this.setState({ selectedItems: [], selectedItemsToEstimate: [] });
    };

    sendEstimates = (messages: any[], action: string, update?: string) => {
        const { estimate } = this.state;
        return Client.persistVertex(
            ref_Estimates.name,
            {
                '@rid': estimate['@rid'],
                action,
                update,
                messages,
            },
            false
        );
    };

    onChangeEstimateIdAdd = async (newMesssage: any) => {
        console.log("[onChangeEstimateIdAdd]", newMesssage);
        try {
            await this.sendEstimates([newMesssage['@rid']], 'Write');
            this.updateContent();
        } catch (error) {
            console.error(error);
        }
    };

    onMassActionClick = async (rows: AdwRow<ref_Messages>[], action: string, update?: string) => {
        try {
            await this.sendEstimates(
                rows.map((e) => e.dataItem['@rid']),
                action,
                update
            );
            this.updateContent(update);
        } catch (error) {
            console.log(error);
        }
    };

    getNameDialog = async (className: string, _rid: string) =>
        (await Client.searchVertex<{ Name: string }>(className, { '@rid': _rid, properties: ['Name'] }))
            ?.data?.results?.[0]?.Name ?? Trad('unknown');

    onValidateDialog = async (m: ref_Messages): Promise<void> => {
        const { gridAvailables, gridAdded } = this.state;
        const start = m?.Start?.toLocaleDateString(GetCurrentLocale());
        const end = m?.End?.toLocaleDateString(GetCurrentLocale());
        const support = await this.getNameDialog(ref_Supports.name, m.Support);
        const format = await this.getNameDialog(ref_Property.name, m.Format);
        const placement = m.Placement ? await this.getNameDialog(ref_Property.name, m.Placement) : '';

        try {
            let notifType = '';
            if (m['@rid']) {
                //  update
                await Client.updateVertex(ref_Messages.name, m, false);
                notifType = Trad('modify_success_male');
            } else {
                // creation
                await Client.createVertex(ref_Messages.name, m, false);
                notifType = Trad('creation_succes_male');
            }

            // if (editDiffusion)
            //     setState({ editDiffusion: false });
            Notify(
                `${Trad('message')} : ${start} - ${end} ${support} ${format} ${placement} ${notifType}`,
                'success'
            );
            store.dispatch(setMessageSync(null));
            SetMode(ref_Messages.name, undefined);
            await gridAdded.UpdateRows();
            await gridAvailables.UpdateRows();
        } catch (e: any) {
            NotifyError(e?.response?.data);
        }
    };

    onChangeEstimateIdRemove = async (newMesssage: any) => {
        try {
            await this.sendEstimates([newMesssage['@rid']], 'Delete');
            this.updateContent();
        } catch (err: any) {
            let errorMessage = Trad('cant_delete_message');
            if (err?.response?.data?.error?.data?.message)
                errorMessage = `${errorMessage} - ${err.response.data.error.data.message}`;

            this.error(errorMessage);
        }
    };

    redraw = async () => {

        console.log("[Redraw]");

        const { config, estimate } = this.state;
        const newGridAdded = await CreateGrid({
            vertexParams: { Active: true, Source: ["ADWONE"], 'Deversement.Estimate': [estimate?.["Import"]?.ExternalID, estimate['@rid']].filter(e => e) },
            vertexGridParams: {
                afterSearch: (msg: ref_Messages[]) => [...(msg ?? [])].sort((a, b) => {
                    const dateA = a?.Deversement?.InfoCO?.LastUpdate
                        ?? Object.values(a?.Deversement?.Fees ?? {})?.[0]?.InfoCO?.LastUpdate;
                    const dateB = b?.Deversement?.InfoCO?.LastUpdate
                        ?? Object.values(b?.Deversement?.Fees ?? {})?.[0]?.InfoCO?.LastUpdate;
                    const valA = dateA ? new Date(dateA).getTime() : 0;
                    const valB = dateB ? new Date(dateB).getTime() : 0;
                    return valB - valA;
                }),
            },
            dataGridProps: {
                cancelMode: false
            }
        });
        const configuration = clone(await GetDefaultColumnConfiguration(ref_Messages.name));
        if (IsMMUser()) {
            configuration.Columns = clone([
                checkAllLinks,
                ...configuration.Columns]);
            configuration.FrozenPosition = (configuration.FrozenPosition ?? 0) + 1;
        }

        const newGridAvailables = await CreateGrid({
            vertexParams: { ...config, 'Deversement.Estimate': null },
            configuration: configuration
        });

        console.log("[redraw] newGridAvailables configuration 2", newGridAvailables);

        const sort = GetSort<ref_Messages>(ref_Messages);
        this.setState(
            {
                gridAdded: newGridAdded,
                gridAvailables: newGridAvailables,
                sort,
            },
            () => {
                const { gridAvailables, gridAdded } = this.state;
                // gridAvailables.onRowsChanged.addListener(eRowEvent.sourceChanged, (rows) => {
                //     this.setState({
                //         gridAvailablesLength: rows.length,
                //     });
                // });
                // gridAdded.onRowsChanged.addListener(eRowEvent.sourceChanged, (rows) => {
                //     this.setState({
                //         gridAddedLength: rows.length,
                //     });
                // });
            }
        );
    };

    onViewChange = (viewMode) => {
        console.log("[onViewChange]", viewMode);
        this.setState({ viewMode })
    }

    selectionChange = (rows: AdwRow<ref_Messages>[]) => {
        console.log("[selectionChange]", rows);
        // this.forceUpdate();
        this.setState({ selectedItems: rows });
    }

    selectionToEstimateChange = (rows: AdwRow<ref_Messages>[]) => {
        console.log("[selectionToEstimateChange]", rows);
        this.setState({ selectedItemsToEstimate: rows });
    }

    onEdit = (dataItem: ref_Messages) => {
        Client.searchVertexTyped(ref_Messages, { "@rid": dataItem["@rid"], properties: ["*", "Attachments"] }).then(([data]) => {
            console.log("Data", data);
            SetMode(ref_Messages.name, eDialogMode.modify);
        })
    }

    render() {
        const {
            config,
            estimate,
            gridAdded,
            gridAvailables,
            selectedItems,
            selectedItemsToEstimate,
            sort,
            viewMode
        } = this.state;
        if (!gridAvailables || !gridAdded)
            return <Loader />;

        // gridAdded.onRowsChanged.addListener(eRowEvent.sourceChanged, () => {
        //     this.setState({
        //         gridAddedLength: gridAdded.Rows.length,
        //     });
        // });

        const confComponent = (
            <ConfigurationPanel
                elements={[{
                    type: "icon",
                    title: () => Trad(`Table_view`.toLocaleLowerCase()),
                    icon: "listView",
                    element: (
                        <div>
                            <Element className="adw-row">
                                <ModeleColumnsCreator
                                    disableLocalStorage
                                    hideViewSelector
                                    objectType={ref_TableConfigurations}
                                    onChange={(configuration) => {
                                        const conf = { ...new ref_TableConfigurations(), ...(configuration ?? {}) };
                                        gridAvailables.props.configuration = conf;
                                        gridAdded.props.configuration = conf;
                                        this.refreshContent();
                                        store.dispatch(setTable(conf));
                                    }}
                                    modele={gridAdded.props.configuration}
                                    type={ref_Messages.name} />
                            </Element>
                        </div>)
                },
                {
                    type: 'icon',
                    title: () => Trad('filters'),
                    icon: 'filterAlt',
                    // badge: hasConfig(config) ? <Badge cutoutBorder align={{ vertical: "top", horizontal: "start" }} /> : null,
                    element: (
                        <EstimateDetailsFiltersComponent
                            onConfChange={this.onConfChange}
                            config={config}
                        />
                    ),
                },
                ]}
            />
        );

        const EstimateToolbar = () => <MessagesToolbar>
            <ToolbarContainer>
                <SelectedItemsContainer>
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        {selectedItems?.length > 0 &&
                            <>
                                <div className="margin-right">
                                    <CustomBadge
                                        count={selectedItems.length}
                                        icon="ref_Messages"
                                        tradClassKey={ref_Messages.name}
                                    />
                                </div>
                                <CustomButton
                                    Label={Trad('undeverser')}
                                    className="custom_btn_danger"
                                    startIcon={<RemoveCircleOutlineIcon />}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.onMassActionClick(selectedItems, 'Delete');
                                    }}
                                />
                                <MessagesActions
                                    selectedItems={selectedItems}
                                    onUpdate={(update) => this.onMassActionClick(selectedItems, "Update", update)}
                                    contractStatus={estimate.ContractStatus}
                                />
                            </>
                        }
                        {selectedItemsToEstimate?.length > 0 &&
                            <>
                                <div className="margin-right">
                                    <CustomBadge
                                        count={selectedItemsToEstimate.length}
                                        icon="ref_Messages"
                                        tradClassKey={ref_Messages.name}
                                    />
                                </div>
                                <CustomButton
                                    Label={Trad('Déverser')}
                                    className="custom_btn_primary_validation"
                                    startIcon={<AddCircleOutlineIcon />}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.onMassActionClick(selectedItemsToEstimate, 'Write');
                                    }}
                                />
                            </>
                        }
                    </div>
                </SelectedItemsContainer>
                <BreadcrumbsCustomContainer>
                    <BreadcrumbsCustom
                        hasSelectedItems={selectedItems?.length > 0 || selectedItemsToEstimate?.length > 0}
                        elements={[
                            { text: Trad('home'), href: '/' },
                            { text: Trad('estimates'), href: '/estimates' },
                            {
                                text: `${estimate?.Code} - ${estimate?.Lib} - ${new Date(estimate?.Start).toLocaleDateString(
                                    GetCurrentLocale()
                                )}`,
                            },
                        ]}
                    />
                </BreadcrumbsCustomContainer>
            </ToolbarContainer>
            <ConfigurationPanelContainer>
                {confComponent}
            </ConfigurationPanelContainer>
        </MessagesToolbar>

        const hiddenStyle: React.CSSProperties = { display: 'none' };
        console.log(`[MessageList] render`, estimate);
        return (
            <div className='EstimateDetails' style={{ width: '100%' }}>
                <div id="anchor-popup" />

                {viewMode == "AddedTable" &&
                    <div key={viewMode + "view_AddedTable"} style={viewMode !== "AddedTable" ? hiddenStyle : {}}>
                        <MessageList
                            {...{ name: "gridAdded" }}
                            dataGrid={gridAdded}
                            toolbar={() => <EstimateToolbar />}
                            modeleGridProps={{
                                customCommandCellFunction: this.onChangeEstimateIdRemove,
                                customCommandCell: CustomDropMessage,
                                selectionChange: this.selectionChange,
                                sort: sort
                            }} />
                    </div>}

                {viewMode == "AvailableTable"
                    && <div key={viewMode + "view_AvailableTable"} style={viewMode !== "AvailableTable" ? hiddenStyle : {}}>
                        <MessageList
                            {...{ name: "gridAvailables" }}
                            dataGrid={gridAvailables}
                            toolbar={() => <EstimateToolbar />}
                            modeleGridProps={{
                                customCommandCellFunction: this.onChangeEstimateIdAdd,
                                customCommandCell: CustomAddMessage,
                                selectionChange: this.selectionToEstimateChange,
                                sort: sort
                            }} />
                    </div>}

                <FooterCellOptions
                    viewMode={viewMode}
                    onViewChange={this.onViewChange}
                    updateContent={() => { }}
                    gridAdded={gridAdded}
                    gridAvailables={gridAvailables} />
            </div>
        );
    }
}

export default withRouter(EstimateDetails);

type FooterCellOptionsProps = {
    viewMode: "AddedTable" | "AvailableTable",
    onViewChange: (viewMode: "AddedTable" | "AvailableTable") => void,
    updateContent: () => void,
    gridAdded: DataGrid<ref_Messages>,
    gridAvailables: DataGrid<ref_Messages>
};

function FooterCellOptions({ viewMode, onViewChange, updateContent, gridAdded, gridAvailables }: FooterCellOptionsProps) {

    const [gridAddedLength, setGridAddedLength] = React.useState(gridAdded.Rows.length);
    const [gridAvailablesLength, setGridAvailablesLength] = React.useState(gridAvailables.Rows.length);

    React.useEffect(() => {
        gridAdded.onRowsChanged.removeAllListeners(eRowEvent.sourceChanged);
        gridAdded.onRowsChanged.addListener(eRowEvent.sourceChanged, () => {
            console.log("[gridAdded] onRowsChanged", gridAdded.Rows.length);
            setGridAddedLength(gridAdded.Rows.length);
        });

        gridAvailables.onRowsChanged.removeAllListeners(eRowEvent.sourceChanged);
        gridAvailables.onRowsChanged.addListener(eRowEvent.sourceChanged, () => {
            setGridAvailablesLength(gridAvailables.Rows.length);
        });
    });

    const tabs = [
        { title: 'estimate_messages_added', viewMode: 'AddedTable', length: gridAddedLength },
        { title: 'estimate_messages_availables', viewMode: 'AvailableTable', length: gridAvailablesLength },
    ];

    const [selected, setSelected] = React.useState<number>(0);
    const tabIndex = tabs.findIndex(tab => tab.viewMode === viewMode);

    React.useEffect(() => {
        setSelected(tabIndex)
    }, [tabIndex])

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
        onViewChange(tabs[e.selected].viewMode as any);
        updateContent()
    };
    return (
        <TabStrip tabContentStyle={{ display: 'none' }} selected={selected} onSelect={handleSelect}>
            {tabs.map((item, index) => {
                return (
                    <TabStripTab
                        title={
                            <BadgeContainer>
                                <Badge position='inside' size='small' >{item.length > 99 ? "99+" : item.length}</Badge>
                                <div style={{ paddingRight: 18 }}>{Trad(item.title)}</div>
                            </BadgeContainer>
                        }
                        key={index}>
                    </TabStripTab>
                );
            })}
        </TabStrip>
    );
};

const MessageList = (props: { dataGrid: DataGrid<ref_Messages>, modeleGridProps: Partial<ModeleGrid<ref_Messages>['props']>, toolbar?: () => JSX.Element | Element }) =>
    <DataGridComponent
        toolbar={props?.toolbar ? () => props.toolbar() : null}
        afterConfigurationLoaded={(conf) => conf.ViewMode = 'Table'}
        configurations={props.dataGrid.props.configuration}
        objectType={ref_Messages}
        genre="male"
        loadMedias={true}
        createGrid={params => props.dataGrid}
        modeleGridProps={props.modeleGridProps}
        getDialog={(props, state, onValidate, onClose) =>
            <MessagesDialog
                onValidate={onValidate}
                importRid={state?.importRid}
                selectedMedia={state.selectedMedia?.["@rid"]}
                open
                message={state.elementEdited}
                handleClose={onClose}
                canRepeat />
        } />

const checkSupportCol = Typed<IndicateurLink>({
    type: eIndicateurType.link,
    name: 'checklink.Support',
    valueType: eKPIType.Number,
    options: {
        cross: true,
        links: [{
            property: propertyOf<ref_Messages>('Support'),
            className: vw_mm_HasSupport.name,
            sourceType: src_MM.name
        }, {
            property: propertyOf<ref_Messages>('BroadcastArea'),
            className: vw_mm_HasBroadcastArea.name,
            sourceType: src_MM.name
        }]
    }
})

const checkCurrencyCol = Typed<IndicateurLink>({
    type: eIndicateurType.link,
    name: 'checklink.Currency',
    valueType: eKPIType.Number,
    options: {
        links: [{
            property: propertyOf<ref_Messages>('Currency'),
            className: vw_mm_HasCurrency.name,
            sourceType: src_MM.name
        }]
    }
})

const checkFormatCol = Typed<IndicateurLink>({
    type: eIndicateurType.link,
    name: 'checklink.Format',
    valueType: eKPIType.Number,
    options: {
        links: [{
            property: propertyOf<ref_Messages>('Format'),
            className: vw_mm_HasFormat.name,
            sourceType: src_MM.name
        }, {
            property: propertyOf<ref_Messages>('Format'),
            className: vw_tsm_HasFormat.name,
            sourceType: src_TSM.name
        }],
    }
})

const checkPlacementCol = Typed<IndicateurLink>({
    type: eIndicateurType.link,
    name: 'checklink.Placement',
    valueType: eKPIType.Number,
    options: {
        links: [{
            property: propertyOf<ref_Messages>('Placement'),
            className: vw_mm_HasPlacement.name,
            sourceType: src_MM.name
        }, {
            property: propertyOf<ref_Messages>('Placement'),
            className: vw_tsm_HasEmplacement.name,
            sourceType: src_TSM.name
        }],
    }
})

const checkAdvCompanyComCol = Typed<IndicateurLink>({
    type: eIndicateurType.link,
    name: 'checklink.AdvCompanyCom',
    valueType: eKPIType.Number,
    options: {
        links: [{
            property: propertyOf<ref_Messages>('AdvCompany_Com'),
            className: vw_mm_HasAdvertisingCompanies.name,
            sourceType: src_MM.name
        }],
    }
})

const checkAdvCompanyFinCol = Typed<IndicateurLink>({
    type: eIndicateurType.link,
    name: 'checklink.AdvCompanyFin',
    valueType: eKPIType.Number,
    options: {
        links: [{
            property: propertyOf<ref_Messages>('AdvCompany_Fin'),
            className: vw_mm_HasAdvertisingCompanies.name,
            sourceType: src_MM.name
        }],
    }
})

const checkAllLinks = Typed<IndicateurComputed>({
    type: eIndicateurType.computed,
    name: 'checklink.AllLinks',
    field: 'checklink.AllLinks',
    valueType: eKPIType.String,
    operator: '==',
    options: { valueEquals: '1' },
    indicateurs: [checkSupportCol, checkFormatCol, checkPlacementCol, checkCurrencyCol, checkAdvCompanyComCol, checkAdvCompanyFinCol],
});