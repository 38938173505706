import { rid } from "../../models/orientdb/CommonTypes.bin";
import { User } from "../../models/orientdb/User.bin";
import { ref_AdvertiserGroupsId } from "../../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisersId } from "../../models/orientdb/ref_Advertisers.bin";
import { ref_BrandsId } from "../../models/orientdb/ref_Brands.bin";
import { ref_BroadcastAreasId } from "../../models/orientdb/ref_BroadcastAreas.bin";
import { ref_Companies } from "../../models/orientdb/ref_Companies.bin";
import { ref_CurrenciesId } from "../../models/orientdb/ref_Currencies.bin";
import { ref_DepartmentsId } from "../../models/orientdb/ref_Departments.bin";
import { ref_Media, ref_MediaId } from "../../models/orientdb/ref_Media.bin";
import { ref_ProductsId } from "../../models/orientdb/ref_Products.bin";
import { ref_SupportsId } from "../../models/orientdb/ref_Supports.bin";
import { IAdvertiserHierarchy, IAttachmentsOwner, IDateRangeModel, IMediaCompanyModel, IMediaModel, INamed, IUpsertInfo, kpis } from "../../types";
import { ref_Imports, ImportElementStatus } from "../admin/ref_Imports.bin";
import { ref_Attachments, ref_Visuals } from "./ref_Attachments.bin";
import { ref_Groups } from "./ref_Groups.bin";

export type ref_CampaignsId = rid;

export type EstimateType = {
	Source: "Sellsy" | "MM",
	ExternalID: string,
	EstimateLib?: string,
	PdfLink?: string,
	CreateParams?: { [key: string]: any }
}

type PerformanceKPI = { objective: number, done: number };

export type TimePerformanceKPI = {
    time: string;
    Performances: { [key: string]: PerformanceKPI }
}

export class ref_Campaigns implements INamed, IDateRangeModel, IUpsertInfo, IMediaModel, IMediaCompanyModel, IAdvertiserHierarchy, IAttachmentsOwner {
	"@rid"?: ref_CampaignsId;
	Name: string;
	Start: Date;
	End: Date;
	Brand?: ref_BrandsId;
	Budget?: number;
	//MessagesBudget?: number;
	KPIs: kpis & {
		CountMessages?: number;
		MaxStartDate?: Date;
		MinEndDate?: Date
	};
	KPIsMedia?: { [mediaRid: ref_Media['@rid']]: ref_Campaigns['KPIs'] };
	AdvertiserGroup?: ref_AdvertiserGroupsId;
	Currency?: ref_CurrenciesId;
	ReturnedCurrency: ref_CurrenciesId;
	Advertiser?: ref_AdvertisersId;
	Media?: ref_MediaId;
	Support?: ref_SupportsId;
	AdvCompany_Com?: rid;
	AdvCompany_Fin?: rid;
	BroadcastArea?: ref_BroadcastAreasId;
	Agency_Res?: ref_Companies["@rid"];
	Agency_Fact?: ref_Companies["@rid"];
	AdvertiserDirect?: boolean;
	Group?: ref_Groups["@rid"];
	Product?: ref_ProductsId;
	Active: boolean;
	Source: "ADWONE" | "MAP";
	Import?: {
		Data: { [key: string]: any };
		Id: ref_Imports["@rid"];
		Status: ImportElementStatus;
	};
	Created_by?: User["@rid"];
	Created_at?: Date;
	Updated_by?: User["@rid"];
	Updated_at?: Date;
	Attachments?: { [key: string]: ref_Attachments } = {}; // not in DB !!!
	Visuals?: ref_Visuals[]; // not in DB !!!
	EstimateNumber?: string; // not in DB !!!
	Departments?: ref_DepartmentsId[]; // not in DB !!!
	Estimates?: EstimateType[];
	ConfirmationTimeLimit?: Date;
	ConfirmationComments?: string;
	TechDeliveryComments?: string;
	TechDeliveryTimeLimit?: Date;
	CancellationTimeLimit?: Date;
	CancellationComments?: string;
	ModelProperties?: { [key: string]: any } = {};
	Performances?: {
		[ridMedia: string]: {
			wave?: string;
			target?: string;
			GRP?: number;
			contacts?: number;
			coverage?: number;
			coverage_thousands?: number;
			repetition?: number;
			TimeMode?: "total" | "days" | "weeks";
			TimePerformance? : TimePerformanceKPI[];
		}
	} = {}
}