import React from "react";
import {
    MultiSelect,
    MultiSelectFilterChangeEvent,
    MultiSelectProps,
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Client } from "hub-lib/client/client.bin";
import { Trad, TradComposed } from "trad-lib";
import { ContainerComponent } from "./ContainerComponent";

type CustomMultiSelectProps<T> = MultiSelectProps & {
    minimumFilterLength: number;
    vertex: new () => T;
    searchKey?: keyof T;
    title?: string;
};

export function CustomMultiSelect<T>(props: CustomMultiSelectProps<T>) {
    const [data, setData] = React.useState([]);
    const [value, setValue] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
        const { minimumFilterLength } = props;
        const noData = (
            <h4 style={{ fontSize: "1em" }}>
                <span className={`k-icon ${minimumFilterLength && value.length < minimumFilterLength ? "k-i-button" : "k-i-warning"}`} style={{ fontSize: "2.5em" }} />
                <br />
                {minimumFilterLength && value.length < minimumFilterLength ? TradComposed("no_data_found_until", [minimumFilterLength]) : Trad("no_data_found")}
            </h4>
        );

        return React.cloneElement(element, { ...element.props }, noData);
    };

    const filterChange = async (event: MultiSelectFilterChangeEvent) => {
        const { minimumFilterLength, vertex, searchKey } = props;
        const filter = event.filter;
        setValue(filter.value);
        let allData = [];
        if (filter.value.length >= minimumFilterLength) {
            setLoading(true);
            allData = await Client.searchVertexTyped(vertex, { _strictParams: { [searchKey ?? "Name"]: { "$regex": (filter.value || "")?.toUpperCase() } } });
            setLoading(false);
        }
        const newData = filterBy(allData, filter);

        setData(newData);
    };

    return <ContainerComponent
        title={props.title}
        className="configuration-multiselect unlock">
        <MultiSelect
            {...props}
            listNoDataRender={listNoDataRender}
            loading={loading}
            className="custom-multiselect"
            textField={props.searchKey ?? "Name" as any}
            dataItemKey={"@rid"}
            data={data}
            filterable
            onFilterChange={filterChange}
        />
    </ContainerComponent>;
}