import { rid } from "../models/orientdb/CommonTypes.bin";
import { lnk_ChangeRate } from "../dto/client/lnk_ChangeRate.bin";
import { ref_CurrenciesId } from "../models/orientdb/ref_Currencies.bin";
import { lnk_HasCurrencyExtended } from "../dto/referential/lnk_HasCurrencyExtended.bin";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";
import { eStatusType, ref_Messages } from "../dto/client/ref_Messages.bin";
import { groupBy, memoizeAsync } from "../tools.bin";
import { logError } from "tools-lib";
import { kpis } from "../types";


/*
lnk_HasCurrencyExtended =>
Company_Class: "ref_Advertisers"
Company_Name: "CAUDALIE ESPAGNE"
Company_rid: "#49:13"
Currency_Code: ["€"]
Currency_Name: ["EUR"]
Currency_rid: ["#359:0"]
End: undefined
Parent_Class: ["ref_AdvertiserGroups"]
Parent_Name: ["CAUDALIE"]
Parent_rid: ["#202:0"]
Start: undefined
*/


/*
@class: "lnk_ChangeRate"
@rid: "#362:28"
@version: 1
Company: "#52:12"
End: Thu Dec 31 2020 00:00:00 GMT+0100 (heure normale d’Europe centrale) {}
Rate: 0.85
Start: Wed Jan 01 2020 00:00:00 GMT+0100 (heure normale d’Europe centrale) {}
in: "#359:0"
out: "#359:4"
*/

export class ReturnCurrencyProvider {

    allRestit: lnk_HasCurrencyExtended[] = undefined;
    allRates: lnk_ChangeRate[] = undefined;

    public static HasCurrencyProvider: () => lnk_HasCurrencyExtended[] | Promise<lnk_HasCurrencyExtended[]>;
    public static ChangeRateProvider: () => lnk_ChangeRate[] | Promise<lnk_ChangeRate[]>;

    /** Delais d'expiration en ms, si aucun appel n'est fait à GetCurrency pendant ce delais alors on réinit l'objet,
     * sinon on repousse l'expiration de ce même delais à chaque nouvel appel */
    expires: number;
    expiresTimeout: any;

    constructor(expires?: number) {
        this.expires = expires;
    }

    delayExpiration() {
        if (this.expires) {
            clearTimeout(this.expiresTimeout);
            this.expiresTimeout = setTimeout(() => {
                this.allRestit = undefined;
                this.allRates = undefined;
                this.memoFunc = null;
            }, this.expires);
        }
    }

    HasExpired() {
        return (!this.allRestit || !this.allRates);
    }

    GetCurrencySync(groupAdvertiser?: rid, advertiser?: rid, buyCurrency?: ref_CurrenciesId, Start?: Date, End?: Date): { rate: number, currency: rid } {

        if (!this.allRestit || !this.allRates) {
            console.error(`CurrenciesManager expired !!`);
            return null;
        }

        if (Start) Start = new Date(Start);
        if (End) End = new Date(End);

        /** Looking for currency of advert or group advert */
        let getRestit = (_rid: rid) => _rid && this.allRestit.find(rest => rest.Company_rid?.toString() === _rid);
        let currency = (getRestit(advertiser) ?? getRestit(groupAdvertiser))?.Currency_rid?.[0]?.toString();

        if (currency) {
            let getRate = (_rid: rid) => _rid && this.allRates?.filter((r: lnk_ChangeRate) => {
                return r.Company?.toString() === _rid
                    && r.out === currency
                    && r.in === buyCurrency
                    && r.Active == true
            });

            /** Check if we can find any configured change rate */
            let rateAdv: lnk_ChangeRate[] = getRate(advertiser);
            let rateGrp: lnk_ChangeRate[] = getRate(groupAdvertiser);

            let addDays = (date: Date, days: number) => {
                const before = date.getTimezoneOffset();
                date.setDate(date.getDate() + days)
                const after = date.getTimezoneOffset();
                date.setMinutes(date.getMinutes() - (after - before));
            }

            let isBetween = (date: Date) => (r: lnk_ChangeRate) => {
                if (date < r.Start) return false;
                if (r.End && isFinite(<any>r.End) && date > r.End) return false;
                return true;
            }

            let rates: number[] = [];

            let start = new Date(Start);
            let end = new Date(End);

            while (start <= end) {
                let whileRate = rateAdv?.find(isBetween(start)) ?? rateGrp?.find(isBetween(start));
                rates.push(whileRate?.Rate || 1);

                addDays(start, 1);
            }

            let rate = rates.length ? (rates.reduce((a, b) => a + b) / rates.length) : 1;
            return { rate, currency };
        }
    }

    async GetCurrency(groupAdvertiser?: rid, advertiser?: rid, buyCurrency?: ref_CurrenciesId, Start?: Date, End?: Date): Promise<{ rate: number, currency: rid }> {

        if (Start) Start = new Date(Start);
        if (End) End = new Date(End);

        if (!ReturnCurrencyProvider.HasCurrencyProvider || !ReturnCurrencyProvider.ChangeRateProvider)
            throw new Error("CurrenciesManager not configured");

        await this.init();

        this.delayExpiration();

        return this.GetCurrencySync(groupAdvertiser, advertiser, buyCurrency, Start, End);
    }

    memoFunc;
    async init() {
        if (!this.allRestit || !this.allRates) {
            if (!this.memoFunc) this.memoFunc = memoizeAsync((async () => {
                const allRestit = await ReturnCurrencyProvider.HasCurrencyProvider();
                const allRates = await ReturnCurrencyProvider.ChangeRateProvider();
                allRates.forEach(rate => {
                    rate.Start = new Date(rate.Start);
                    if (rate.End)
                        rate.End = new Date(rate.End);
                });
                return { allRestit, allRates };
            }).bind(this));
            const { allRestit, allRates } = await this.memoFunc();

            this.allRestit = allRestit;
            this.allRates = allRates;
        }
    }

    public async ComputeCampaignBudget(campaign: ref_Campaigns, _messages: ref_Messages[]) {
        try {
            const kpisKeys = Object.keys(new kpis());
            if (!campaign.KPIs)
                campaign.KPIs = new kpis();

            // Remove cancelled messages
            _messages = _messages?.filter?.(m => m.Status != eStatusType.Cancelled) ?? [];
            if (_messages?.length > 0) {
                campaign.KPIs.MaxStartDate = new Date((_messages.reduce((a, b) => (new Date(a.Start) > new Date(b.Start) ? a : b))).Start);
                campaign.KPIs.MinEndDate = new Date((_messages.reduce((a, b) => (new Date(a.End) < new Date(b.End) ? a : b))).End);
            }
            const computeMessages = async (messages: ref_Messages[], target: ref_Campaigns['KPIs']) => {
                kpisKeys.forEach(kpiName => target[kpiName] = 0);
                target.CountMessages = messages?.length || 0;

                if (messages)
                    for (const m of messages) {
                        if (campaign.ReturnedCurrency && m.Currency != campaign.ReturnedCurrency) {
                            const currency = await this.GetCurrency(campaign.AdvertiserGroup, campaign.Advertiser, m.Currency, m.Start, m.End);
                            if (!currency) {
                                kpisKeys.forEach(kpiName => target[kpiName] = <any>"invalid");
                                return;
                            }
                            kpisKeys.forEach(kpiName => target[kpiName] += currency.rate * m?.KPIs[kpiName]);
                        } else
                            kpisKeys.forEach(kpiName => target[kpiName] += m?.KPIs[kpiName] ?? 0);
                    }
            }

            await computeMessages(_messages, campaign.KPIs);

            if (!campaign.KPIsMedia) campaign.KPIsMedia = {};
            if (!campaign.Performances) campaign.Performances = {};

            if (campaign.Media) {
                if (!campaign.Performances[campaign.Media])
                    campaign.Performances[campaign.Media] = {};
            }
            for (const [media, messagesByMedia] of Object.entries(groupBy(_messages, m => m.Media))) {
                if (!campaign.Performances[media])
                    campaign.Performances[media] = {};
                campaign.KPIsMedia[media] = new kpis();
                await computeMessages(messagesByMedia, campaign.KPIsMedia[media]);
            };
        } catch (error) {
            logError(error, {
                Category: "ComputeCampaignBudget",
                Description: `Error while computing campaign budget`,
            })
        }
    }
}