import { eLayerType } from "tools-lib";

/**
 * For log management request
 */
export class logArg {
    layer?: eLayerType;
    details?: number;
}

export enum eAction {
    Create = "create",
    Read = "read",
    Update = "update",
    Delete = "delete"
}

/**
 * Log user actions
 */
export class logUserArg {
    Category: string;
    Action: eAction | string;
    Status: "success" | "fail" = "success";
    Informations: any;
    details?: number = 0;
    Elapsed?: number;
    formatVersion: number = 1;
    url: string;
}