import { ref_Companies, ref_CompaniesId } from "./ref_Companies.bin";
import { ref_Contracts, ref_ContractsId } from "./ref_Contracts.bin";
import { ref_Countries, ref_CountriesId } from "./ref_Countries.bin";
import { UserRights, UserRightsId } from "./UserRights.bin";
import { ref_SSO, ref_SSOId } from "./ref_SSO.bin";
import { ref_Sources, ref_SourcesId } from "./ref_Sources.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_CustomersId = rid;

export class ref_Customers extends V {
	"@rid"?: ref_CustomersId;
	Active: boolean;
	Activity: "Buying" | "Selling";
	Authorization: "MediaManager" | "AdwOne" | "MediaPilot";
	Billing: "MediaManager" | "AdwOne";
	Comments: string;
	Company: ref_CompaniesId;
	Contract: ref_ContractsId;
	Country: ref_CountriesId;
	End: Date;
	FormatedExports: number;
	Licences: number;
	MailsDomain: string[];
	MasterDataKey: string;
	Rights: UserRightsId;
	SSO: ref_SSO;
	Sources: ref_SourcesId[];
	Start: Date;
	Type: "Client" | "Prospect" | "Factice";
	Vpn: boolean;
	Sellsy?: { client_id: string, client_secret: string };
	AdvertiserMode : "ref_Campaigns" | "ref_Messages";
	SupportMode : "ref_Campaigns" | "ref_Messages";
}