import * as React from 'react'
import { styled, Tooltip, TooltipProps } from '@material-ui/core';
import { TooltipManager } from 'adwone-lib';

class TState {
    position: {
        x: number,
        y: number
    } = { x: undefined, y: undefined }

}
export class GenericTooltipProps {
    tooltipContent: string | JSX.Element | (() => JSX.Element)
    children?: React.ReactElement<any, any>
    style?: any
    contentStyle?: any
    contentClassName?: string;
}

export function GenericTooltip(props: GenericTooltipProps) {
    const { tooltipContent, children, contentStyle, contentClassName } = props;
    if (typeof tooltipContent === "string")
        return <div onMouseOver={e => TooltipManager.Push({ target: e.target, text: tooltipContent })}>
            <div className={`tootlip-content ${contentClassName ?? ""}`} style={contentStyle}>
                {children}
            </div>
        </div>

    return <GenericTooltipComponent {...props} contentClassName={contentClassName} />;
}

export function GenericTooltipComponent({ tooltipContent, contentClassName, style, children, contentStyle }: GenericTooltipProps) {

    const [state, setState] = React.useState<TState>(new TState());
    const [content, setContent] = React.useState<string | JSX.Element>("");

    React.useEffect(() => {
        const newContent = typeof tooltipContent == "function" ? <div className='ToolTipBaseTemplate'>{tooltipContent()}</div> : tooltipContent;
        setContent(newContent);
    }, [tooltipContent]);

    const updatePosition = (e) => {
        const newState = new TState();
        newState.position.x = e['pageX']
        newState.position.y = e['pageY']
        setState(newState);
    }

    return <HtmlTooltip
        onOpen={updatePosition}
        onMouseMove={updatePosition}
        title={content}
        style={style}
        PopperProps={{
            anchorEl: {
                clientHeight: 0,
                clientWidth: 0,
                getBoundingClientRect: () => ({
                    x: state.position.x,
                    y: state.position.y,
                    top: state.position.y,
                    left: state.position.x,
                    right: state.position.x,
                    bottom: state.position.y,
                    width: 0,
                    height: 0,
                    toJSON: undefined
                })
            }
        }} >
        <div className={`tootlip-content ${contentClassName ?? ""}`} style={contentStyle}>
            {children}
        </div>
    </HtmlTooltip>
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${'MuiTooltip-tooltip'}`]: {

    },
}));