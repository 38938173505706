import * as React from 'react'
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker'
import { Client } from 'hub-lib/client/client.bin'
import { Trad } from 'trad-lib'
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin'
import { GetToday } from 'tools-lib'
import { AdwAutocomplete } from "adwone-lib/index"
import { addDays } from 'date-fns';
import { TextField } from '@material-ui/core'
import Loader from '../Loader'

export class PopupSettingsLogs extends React.Component<any, any>{

  constructor(props: any) {
    super(props)
    this.state = {
      open: true,
      Start: GetToday(),
      End: addDays(GetToday(), 1),
      users: undefined,
      layers: undefined,
      User: '',
      Layer: ''
    }
  }

  async componentDidMount() {
    let users = (await Client.distinct({ collection: "logs", property: "User", documentType: "logDocument" })).data.results.filter((e: string) => e !== "undefined")
    users = users.sort((a: string, b: string) => { return a.localeCompare(b) })
    users.unshift(Trad("all"))

    let layers = (await Client.distinct({ collection: "logs", property: "Layer", documentType: "logDocument" })).data.results
    layers = layers.sort((a: string, b: string) => { return a.localeCompare(b) })
    layers.unshift(Trad("all"))

    this.setState({
      users,
      layers,
      User: users[0],
      Layer: layers[0]
    })
  }

  handleClose = () => {
    this.props.onValidate(this.state.User, this.state.Layer, this.state.Start, this.state.End)
    this.setState({ open: false })
  }

  render() {
    const { users, layers } = this.state
    return (
      <>
        <GenericDialog
          open={this.state.open}
          dialogTitle={Trad("table_init")}
          disableCancel={true}
          actions={true}
          submitAction={this.handleClose}
          submitTitle={Trad("apply")}
          // submitClass="custom_btn_primary"
          dialogContent={
            <>
              {(!users && !layers) && <Loader />}
              {(users?.length > 0 && layers?.length > 0) &&
                <>
                  <div className='adw-row'>
                    <AdwAutocomplete
                      disableClearable
                      options={this.state.users}
                      value={this.state.User}
                      onChange={(event: any, value: any) => { this.setState({ User: value }) }}
                      renderInput={(params) => <TextField {...params} label={Trad("users")} variant="outlined" />}
                    />
                  </div>
                  <div className='adw-row'>
                    <AdwAutocomplete
                      disableClearable
                      options={this.state.layers}
                      value={this.state.Layer}
                      onChange={(event: any, value: any) => { this.setState({ Layer: value }) }}
                      renderInput={(params) => <TextField {...params} label={Trad("layer")} variant="outlined" />}
                    />
                  </div>
                  <div className='adw-row'>
                    <DateRangepicker
                      defaultStart={this.state.Start}
                      defaultStop={this.state.End}
                      handleChangeDate={(v) => {
                        this.setState({
                          Start: v.start,
                          End: v.end
                        })
                      }} />
                  </div>
                </>
              }
            </>
          }
        >

        </GenericDialog>
      </>
    )
  }
}