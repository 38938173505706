import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ComputatedCascade, DiscountManager, getAvailableAgreements, getAvailableDiscountRates, InitDiscountRates, netType } from 'hub-lib/business/DiscountManager.bin';
import { Client } from 'hub-lib/client/client.bin';
import { IRid } from 'hub-lib/models/IRid.bin';
import { eKPI, eKPIType, KPIsManagerCache, lnk_HasKPIExtended } from 'hub-lib/models/KPIsManager.bin';
import { lnk_AdvertisingCompanySupport } from 'hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin';
import { lnk_HasBroadcastArea } from 'hub-lib/models/orientdb/lnk_HasBroadcastArea.bin';
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin';
import { ref_AdvertisingCompanyRole } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { ref_SubAgencies } from 'hub-lib/models/orientdb/ref_SubAgencies.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { clone, compareObjects, distinct, duplicate, extractSub, firstOrDefault, GetHashCode, hasOwnProperty, SerializeError, Typed } from 'hub-lib/tools.bin';
import { Trad } from 'trad-lib';
import { getDefaultFormatOffer, getFormatOffer } from '../components/VertexGrid/Messages/MessageEditor/OfferTools';
import { FilterStorage, GetUser } from '../utils/localstorage.bin';
import { Notify } from '../utils/Notify.bin';
import { GetMessageOffers, SetBroadcastPriceOffer, SetPriceOffer } from '../utils/Tools';
import { RootState, store } from './store';
import { DateZone } from 'tools-lib';
import { ref_DiscountClasses } from 'hub-lib/models/orientdb/ref_DiscountClasses.bin';
import { ref_Discount } from 'hub-lib/models/types/vertex.bin';
import { eDiscountOptionType } from 'hub-lib/models/external.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_Offers } from 'hub-lib/dto/client/ref_Offers.bin';
import { ref_Agreements } from 'hub-lib/dto/client/ref_Agreements.bin';
import { eGroupCategories, ref_Groups } from 'hub-lib/dto/client/ref_Groups.bin';
import { ref_GlobalAgreements } from 'hub-lib/dto/client/ref_GlobalAgreements.bin';
import { ref_Publications } from 'hub-lib/dto/client/ref_Publications.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { UserExtended } from 'hub-lib/dto/referential/UserExtended.bin';
import { GetKPITemplate } from 'format-lib/index.bin';
import { OnSupportChange, IEditorState, SetGroup, SetPerformancesKPIFromOJD, TCurrency, UpdateAgencyState, UpdateBroadcastState, UpdateCurrencyState } from './storeUtils';
import BigNumber from 'bignumber.js';
import { ref_CampaignsSerializable, ToRefCampaign, ToRefCampaignSerializable } from './campaignEditorSlice';
import { eRights, RightManager } from 'hub-lib/models/types/rights.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { clnt_DiscountRate } from 'hub-lib/dto/client/clnt_Referentials';
import { eDialogMode } from '../components/ConfigurableComponents/GenericDialog.bin';

export type CursorPosition = {
    top: number,
    left: number
}

export interface ref_MessagesSerializable extends Omit<ref_Messages, "Start" | "End"> {
    Start: number;
    End: number
}

export interface ref_OffersSerializable extends Omit<ref_Offers, "Start" | "End"> {
    Start: number;
    End: number
}

export interface MessageEditorState extends IEditorState<ref_Messages, ref_MessagesSerializable> {
    currentNetType: netType;
    cascade: ComputatedCascade;
    currentAgreement: ref_Agreements;
    repeat: boolean;
    currencyCode: string;
    currency: ref_Currencies;
    offers: ref_OffersSerializable[];
    offer: ref_OffersSerializable;
    offerFormat: ref_OffersSerializable;
    offerEmpl: ref_OffersSerializable;
    getOffers: () => ref_Offers[];
    AdvCompany_ComOptions: (LnkAdv | ref_SubAgencies)[];
    AdvCompany_FinOptions: (LnkAdv | ref_SubAgencies)[];
    agreements: ref_Agreements[];
    globalAgreement: ref_GlobalAgreements;
    kpis: lnk_HasKPIExtended[];
    campaign?: ref_CampaignsSerializable;
    clntDiscountRates: clnt_DiscountRate;
    getCampaign: () => ref_Campaigns;
}

function ToRefMessage(message: ref_MessagesSerializable): ref_Messages {
    return {
        ...duplicate(message),
        Start: message.Start ? new Date(message.Start) : undefined,
        End: message.End ? new Date(message.End) : undefined,
        CancellationTimeLimit: message.CancellationTimeLimit ? new Date(message.CancellationTimeLimit) : undefined,
        ConfirmationTimeLimit: message.ConfirmationTimeLimit ? new Date(message.ConfirmationTimeLimit) : undefined,
        TechDeliveryTimeLimit: message.TechDeliveryTimeLimit ? new Date(message.TechDeliveryTimeLimit) : undefined
    }
}

function ToRefMessageSerializable(message: ref_Messages): ref_MessagesSerializable {
    return {
        ...duplicate(message),
        Start: message.Start ? new Date(message.Start).getTime() : undefined,
        End: message.End ? new Date(message.End).getTime() : undefined,
    }
}

const initialState: MessageEditorState = {
    mode: undefined,
    currentNetType: "CO",
    cascade: null,
    currentAgreement: undefined,
    globalAgreement: null,
    agreements: null,
    lockNext: false,
    validationIssues: [],
    repeat: false,
    currencyCode: "",
    currency: undefined,
    data: undefined,
    offers: undefined,
    offer: undefined,
    offerFormat: undefined,
    offerEmpl: undefined,
    publications: undefined,
    agencyOptions: undefined,
    currencyOptions: undefined,
    broadcastOptions: undefined,
    AdvCompany_ComOptions: undefined,
    AdvCompany_FinOptions: undefined,
    clntDiscountRates: undefined,
    kpis: undefined,
    campaign: undefined,
    groups: {
        MediaFamily: [],
        PlacementCategory: []
    },
    get() {
        return this.data && ToRefMessage(this.data)
    },
    getCampaign() {
        return this.campaign && ToRefCampaign(this.campaign)
    },

    getOffers() {
        return this.offers?.map(o => ({
            ...duplicate(o),
            Start: o.Start ? new Date(o.Start) : undefined,
            End: o.End ? new Date(o.End) : undefined
        })) ?? []
    }
}

export const initAdvComOptions = createAsyncThunk(
    'messageEditor/initAdvComOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const AdvCompany_ComOptions = await UpdateAdvertisingCompany("AdvCompany_Com", state.messageEditor, state.messageEditor.get());
            return { AdvCompany_ComOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initAdvFinOptions = createAsyncThunk(
    'messageEditor/initAdvFinOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const AdvCompany_FinOptions = await UpdateAdvertisingCompany("AdvCompany_Fin", state.messageEditor, state.messageEditor.get());
            return { AdvCompany_FinOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initMessageAgencyOptions = createAsyncThunk(
    'messageEditor/initAgencyOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const agencyOptions = await UpdateAgencyState(state.messageEditor, state.messageEditor.get());
            return { agencyOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initMessageCurrencyOptions = createAsyncThunk(
    'messageEditor/initCurrencyOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const currencyOptions = await UpdateCurrencyState(state.messageEditor, state.messageEditor.get());
            return { currencyOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initMessageBroadcastOptions = createAsyncThunk(
    'messageEditor/initBroadcastOptions',
    async (_: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const broadcastOptions = await UpdateBroadcastState(state.messageEditor, state.messageEditor.get());
            return { broadcastOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export function UpdateSPQ(m: ref_Messages) {
    const rateGrossDefaultFormat = Math.round(100 * m.KPIs.Gross / m.KPIs.PriceDefaultFormat) / 100;
    const rateGrossValoDefaultFormat = Math.round(100 * m.KPIs.GrossVal / m.KPIs.PriceDefaultFormat) / 100;
    m.KPIs.RateGrossDefaultFormat = Number.isNaN(rateGrossDefaultFormat) ? 0 : rateGrossDefaultFormat;
    m.KPIs.RateGrossValoDefaultFormat = Number.isNaN(rateGrossValoDefaultFormat) ? 0 : rateGrossValoDefaultFormat;
}

const ConsoleDebug = (message?: any, ...optionalParams: any[]) => {
    if (localStorage.getItem('debugSetMessage'))
        console.log(`[debugSetMessage] ${message}`, ...optionalParams);
}

export const setNet = createAsyncThunk(
    'messageEditor/setNet',
    async (props: { base: ref_DiscountClasses, mode: eDiscountOptionType, value: number }, thunkAPI) => {

        console.log(`[setNet] BEGIN`, props);
        const state = thunkAPI.getState() as RootState;
        const message = state.messageEditor.get();
        const campaign = state.messageEditor.getCampaign();

        const bbaDiscounts = (await DiscountManager.getCategoryTypes())[1]; // category bba
        const discounts = message.Discounts.filter(d => bbaDiscounts.includes(d.DiscountType));
        let messageCascade: ComputatedCascade = null;

        try {
            const { base, value, mode } = props;
            let row: ref_Discount = discounts.find(r => r.DiscountClass == base["@rid"]);
            console.log("[setNet] Row exists", row);
            if (!row) {
                const classes = await DiscountManager.GetDiscountClasses();
                const discountClass = classes.find(c => c["@rid"] == base["@rid"]);
                row = {
                    "@rid": null,
                    DiscountClass: base["@rid"],
                    DiscountType: base.DiscountType,
                    IsRate: true,
                    Rank: 0,
                    Operator: discountClass.Operator as any,
                    LastPosition: discountClass.LastPosition,
                    Intervals: null
                };
                message.Discounts.push(row);
            }

            //const initValue = DiscountManager.getModulation(row, mode);
            if (!row[mode]) {
                row.IsRate = true;
                const cofoData = DiscountManager.getModulation(row, mode);
                row[mode] = { Value: cofoData.Value ?? 0, Rate: cofoData.Rate ?? 0 };
            }

            console.log(`[setNet] row[mode]?.Value`, row[mode]?.Value, row[mode]?.Value != 0);

            const cascade = (await DiscountManager.UpdateCascade(message, campaign))[mode];
            const getRowBase = (discountClass: string) => cascade.find(r => r.Discount.DiscountClass == discountClass);

            console.log(`[setNet] cascade`, cascade);

            if (getRowBase(row.DiscountClass).Base != 0) {
                let calcBase = value;
                if (message.DiscountMode == "cascade") {
                    const position = discounts.indexOf(row);
                    let length = discounts.length;
                    for (let i = length - 1; i > position; i--) {
                        let r = discounts[i];
                        const cofoData = DiscountManager.getModulation(r, mode);
                        if (!r.IsRate)
                            calcBase += cofoData?.Value ?? 0;
                        else if (r[mode]?.Rate != 1)
                            calcBase = new BigNumber(calcBase / (1 - cofoData?.Rate ?? 0)).dp(2, 4).toNumber();
                    }
                }
                else {
                    const rank = Number(row.Rank);
                    const rows = discounts.filter(r => Number(r.Rank) >= rank && r != row);
                    const maxRank = Math.max(...rows.map(r => Number(r.Rank)));

                    console.log("[setNet] Rows", rows);
                    for (let i = maxRank; i > rank; i--) {
                        const rankRows = rows.filter(r => r.Rank == i);
                        let rates = 0;
                        for (const r of rankRows) {
                            const cofoData = DiscountManager.getModulation(r, mode);
                            if (!r.IsRate)
                                calcBase += cofoData?.Value ?? 0;
                            else
                                rates += cofoData?.Rate ?? 0;
                        }
                        if (rates != 1)
                            calcBase = new BigNumber(calcBase / (1 - rates)).dp(2, 4).toNumber();
                    }

                    for (const r of rows.filter(r => r.Rank == rank)) {
                        const cofoData = DiscountManager.getModulation(r, mode);
                        if (!r.IsRate)
                            calcBase += cofoData?.Value ?? 0;
                        else
                            calcBase += getRowBase(row.DiscountClass).Base * (cofoData?.Rate ?? 0);
                    }
                }

                console.log(`[setNet] calcBase`, calcBase, 'getRowBase(row.DiscountClass)', getRowBase(row.DiscountClass), 'rate', 1 - (calcBase / getRowBase(row.DiscountClass).Base));

                const calcDiff = (newValue: number) => {
                    let diff = getRowBase(row.DiscountClass).Base - (new BigNumber(newValue).dp(2, 4).toNumber() + new BigNumber(calcBase).dp(2, 4).toNumber());
                    return newValue + diff;
                }

                if (!row.IsRate) {
                    let newValue = getRowBase(row.DiscountClass).Base - calcBase;
                    let fixedNewValue = calcDiff(newValue);
                    if (fixedNewValue < 0)
                        fixedNewValue = 0;
                    row[mode].Value = fixedNewValue;
                } else {
                    let rate = 1 - (calcBase / getRowBase(row.DiscountClass).Base);
                    let newValue = getRowBase(row.DiscountClass).Base * rate;
                    let fixedNewValue = calcDiff(newValue);
                    row[mode].Value = fixedNewValue;
                    row.IsRate = false;
                }
                // if (rate < 0)
                //     rate = 0;
                // row[mode].Rate = rate;
            }

            messageCascade = await DiscountManager.UpdateCascade(message, campaign);
            console.log(`[setNet] END`, { message, base, mode, value });

        } catch (error) {
            console.log(`[setNet] ERROR`, error);
        }

        return { message: ToRefMessageSerializable(message), cascade: messageCascade };
    }
)

export const updateOffer = createAsyncThunk(
    'messageEditor/updateOffer',
    async (message: ref_Messages, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const offer = state.messageEditor.offer;

            const offerFormat = state.messageEditor.offerFormat;
            let messageCascade: ComputatedCascade = null;
            const campaign = state.messageEditor.getCampaign();
            if (offerFormat)
                messageCascade = await SetPriceOffer(message, campaign, offerFormat, 'Format');

            if (offer?.ModelProperties?.Emplacement)
                messageCascade = await SetPriceOffer(message, campaign, offer, 'Placement', false);

            return { message: ToRefMessageSerializable(message), cascade: messageCascade };
        } catch (error) {
            console.error(error);
        }
    }
)

export const setMessage = createAsyncThunk(
    'messageEditor/message',
    async (message: ref_Messages, thunkAPI) => {
        try {
            const time7642 = new Date().getTime();
            ConsoleDebug('message param: ', message);

            const state = thunkAPI.getState() as RootState;
            ConsoleDebug('Mode: ', state.messageEditor.mode);
            ConsoleDebug('previous msg', state.messageEditor.data);

            const isInitializing = Boolean(!state.messageEditor.data);
            const oldMsg = (state.messageEditor.data ?? new ref_Messages);
            const oldCampaigns = state.messageEditor.campaign;

            const newMsg = clone(message);
            const isCreating = newMsg && !newMsg['@rid'];

            const kpiManager = KPIsManagerCache.GetInstance(ref_Messages.name);
            const properties = await kpiManager.GetUserProperties();

            // LEGACY, en cas de message enregistré sans DiscountMode
            if (!isInitializing && newMsg && !newMsg.DiscountMode) {
                newMsg.DiscountMode = new ref_Messages().DiscountMode;
            }

            if (newMsg.Start && ['string', 'number'].includes(typeof newMsg.Start)) newMsg.Start = new Date(newMsg.Start);
            if (newMsg.End && ['string', 'number'].includes(typeof newMsg.End)) newMsg.End = new Date(newMsg.End);

            let campaign: ref_Campaigns = state.messageEditor.getCampaign();
            let agreements: ref_Agreements[] = null;
            let currentAgreement = undefined;
            const medias = (await KPIsManagerCache.GetMedias());
            const digital = medias.find(m => m.Code == "DIG");

            if (isInitializing) {
                await DiscountManager.lockDiscountsLockedFromMAP(newMsg);

                let campRid = newMsg.Campaign;
                // si on est en train d'initialiser le message en création,
                // on récupère les valeurs par défaut de la campagne sélectionnée dans les filtres
                if (!newMsg.Campaign && !newMsg['@rid']) {
                    const filters = FilterStorage.GetLocalStorageFiltersObj();
                    if (filters.Campaign?.length == 1 && filters.Campaign[0])
                        campRid = filters.Campaign[0];
                }
                if (campRid) {
                    const params = { "@rid": campRid, Source: "ADWONE" };
                    campaign = firstOrDefault(await Client.searchVertexTyped(ref_Campaigns, params));
                    ConsoleDebug('set default campaign: ', params, campaign);
                }
                if (campaign && isCreating)
                    kpiManager.SetMessagePropertiesFromCampaign(campaign, newMsg);

                if (digital && newMsg.Media == digital['@rid']) {
                    newMsg.IsGrossCPM = true;
                    await kpiManager.InitializeCPM(newMsg, newMsg.KPIs);
                }

                if ((newMsg.AdvertiserGroup || newMsg.Group) &&
                    newMsg.Currency &&
                    newMsg.Support &&
                    newMsg.Start) {
                    agreements = await getAvailableAgreements(newMsg);
                } else {
                    agreements = [];
                }

                // Si on est en train d'initialiser le message, on sauvegarde la nouvelle version de l'agreement
                if (newMsg.Agreement)
                    [currentAgreement] = await Client.searchVertexTyped(ref_Agreements, { "@rid": newMsg.Agreement });
                else currentAgreement = null;
            }
            else {
                // S'il y a une valeur et que le support change on réinitialize les valeurs par défaut
                if (oldMsg.Support != newMsg.Support) {
                    OnSupportChange("ref_Messages", oldMsg, newMsg);
                    if ((newMsg.GlobalAgreement || newMsg.Agreement) && !newMsg.PricingLocked) {
                        newMsg.GlobalAgreement = null;
                        newMsg.Agreement = null;
                        newMsg.Discounts = newMsg.Discounts?.filter(d => !d.Agreement);
                    }
                }
                if (newMsg.Campaign != oldMsg.Campaign) {

                    /*if (importRid) {
                        params["CacheInfos"] = { Key: importRid, Type: "Import" }
                    }*/
                    campaign = message.Campaign ? firstOrDefault(await Client.searchVertexTyped(ref_Campaigns, { "@rid": message.Campaign, Source: "ADWONE" })) : null;

                    const kpiManager = KPIsManagerCache.GetInstance(ref_Messages.name);
                    kpiManager.SetMessagePropertiesFromCampaign(campaign, newMsg);
                }
            }
            const hasPerformances = await kpiManager.HasPerformances(newMsg.Media);
            const oldPerfKPIs = hasPerformances ? oldMsg.KPIs : oldCampaigns?.KPIs;
            const newPerfKPIs = hasPerformances ? newMsg.KPIs : campaign?.KPIs;

            if (!newMsg.ModelProperties) newMsg.ModelProperties = {};

            let kpis: lnk_HasKPIExtended[] = null;
            if (newMsg?.Media && newMsg.Media != oldMsg.Media)
                kpis = await kpiManager.GetLnkHasKPIs(newMsg.Media);

            if (!isInitializing && state.messageEditor.kpis) {
                if (newMsg.IsGrossCPM != oldMsg.IsGrossCPM)
                    await kpiManager.InitializeCPM(newMsg, newPerfKPIs);

                if (newMsg.IsGrossCPM) {
                    const kpiCPM = state.messageEditor.kpis.find(kpi => kpi.KPI == newMsg.KpiCPM);
                    if (newMsg.KpiCPM != oldMsg.KpiCPM ||
                        newMsg.KPIs["GrossCPM"] != oldMsg.KPIs["GrossCPM"] ||
                        newPerfKPIs[kpiCPM.Id] != oldPerfKPIs[kpiCPM.Id]) {
                        const base = newPerfKPIs?.[kpiCPM.Id] ?? 0;
                        newMsg.KPIs.Gross = base * newMsg.KPIs["GrossCPM"] / 1000;
                    }
                }

            }

            if (newMsg.KPIs?.Gross != oldMsg.KPIs?.Gross || newMsg.KPIs?.GrossVal != oldMsg.KPIs?.GrossVal) {
                ConsoleDebug('UpdateSPQ');
                UpdateSPQ(newMsg);
            }

            let broadcastOptions: ref_BroadcastAreas[] = undefined;
            let AdvCompany_ComOptions: (LnkAdv | ref_SubAgencies)[] = undefined;
            let AdvCompany_FinOptions: (LnkAdv | ref_SubAgencies)[] = undefined;
            let currencyOptions: TCurrency[] = undefined;
            let clntDiscountRates: clnt_DiscountRate = undefined;

            const cloneMessageEditor = clone(state.messageEditor);
            if (newMsg.Start && (new Date(oldMsg.Start).getTime() != new Date(newMsg.Start).getTime())) {
                cloneMessageEditor["AdvCompany_ComOptions"] = null;
                cloneMessageEditor["AdvCompany_FinOptions"] = null;
            }
            if (newMsg.Campaign && !kpiManager.SellingMode()) {
                ConsoleDebug('Update: BroadcastOptions, AdvCompanyOptions, CurrencyOptions');
                broadcastOptions = await UpdateBroadcastState(cloneMessageEditor, newMsg);
                AdvCompany_ComOptions = await UpdateAdvertisingCompany("AdvCompany_Com", cloneMessageEditor, newMsg);
                AdvCompany_FinOptions = await UpdateAdvertisingCompany("AdvCompany_Fin", cloneMessageEditor, newMsg);
                currencyOptions = await UpdateCurrencyState(cloneMessageEditor, newMsg);
            }

            if (!newMsg.Support) {
                newMsg.Currency = null;
                newMsg.BroadcastArea = null;
            }
            if (!newMsg.BroadcastArea) {
                newMsg.Format = null;
                newMsg.Placement = null;
            }

            /** Agreement */
            if (!isInitializing) {
                // TODO : à revoir dans le cadre de la réactivation des intervalles
                // il faudra sans doute applyDiscount si les discounts, ou le Gross ont changé
                if (newMsg.Agreement != oldMsg.Agreement)
                    await DiscountManager.initAgreement(newMsg, { refreshCurrent: true });
                else if (newMsg.GlobalAgreement != oldMsg.GlobalAgreement)
                    await DiscountManager.initGlobalAgreement(newMsg);

                if (newMsg.Currency &&
                    newMsg.Start) {
                    if (oldMsg.BroadcastArea !== newMsg.BroadcastArea ||
                        oldMsg.Currency !== newMsg.Currency ||
                        oldMsg.Media != newMsg.Media ||
                        new Date(oldMsg.Start).getTime() != new Date(newMsg.Start).getTime()) {
                        await DiscountManager.initGlobalAgreement(newMsg);
                    }
                }

                if ((newMsg.AdvertiserGroup || newMsg.Group) &&
                    newMsg.Currency &&
                    newMsg.Support &&
                    newMsg.Start) {
                    if (oldMsg.BroadcastArea !== newMsg.BroadcastArea ||
                        oldMsg.Currency !== newMsg.Currency ||
                        oldMsg.Support != newMsg.Support ||
                        oldMsg.Media != newMsg.Media ||
                        oldMsg.Advertiser != newMsg.Advertiser ||
                        oldMsg.AdvertiserGroup != newMsg.AdvertiserGroup ||
                        oldMsg.Brand != newMsg.Brand ||
                        oldMsg.Product != newMsg.Product ||
                        oldMsg.Format != newMsg.Format ||
                        oldMsg.Placement != newMsg.Placement ||
                        new Date(oldMsg.Start).getTime() != new Date(newMsg.Start).getTime()) {
                        const ag = newMsg.Agreement;
                        const agGlobal = newMsg.GlobalAgreement;
                        await DiscountManager.initAgreement(newMsg);
                        //await DiscountManager.applyDiscounts(<ref_Messages><unknown>newMsg, { initAgreement: true });
                        agreements = await getAvailableAgreements(newMsg);
                        if (ag != newMsg.Agreement || agGlobal != newMsg.GlobalAgreement) {
                            Notify(Trad("change_agreement_auto"), "warning");
                        }
                    }
                } else {
                    agreements = [];
                }
            }

            let globalAgreement: ref_GlobalAgreements = null;
            if (newMsg.GlobalAgreement != state.messageEditor.globalAgreement?.['@rid'])
                globalAgreement = newMsg.GlobalAgreement ? (await Client.searchVertexTyped(ref_GlobalAgreements, { "@rid": newMsg.GlobalAgreement }))?.[0] : null;

            /** Price Offer */
            let offers = state.messageEditor.offers;
            let offer = state.messageEditor.offer;
            let offerFormat = state.messageEditor.offerFormat;
            const isInitializingOffer = offers !== undefined;
            if (!isInitializingOffer ||
                oldMsg.BroadcastArea !== newMsg.BroadcastArea ||
                oldMsg.Media !== newMsg.Media ||
                oldMsg.Support != newMsg.Support ||
                oldMsg.Start != newMsg.Start?.getTime?.() ||
                oldMsg.End != newMsg.End?.getTime?.()) {

                offers = (await GetMessageOffers(newMsg)).map(o => ({
                    ...o,
                    Start: o.Start ? new Date(o.Start).getTime() : undefined,
                    End: o.End ? new Date(o.End).getTime() : undefined,
                }));
                offer = null;
                offerFormat = null;

                ConsoleDebug('load offers', offers, extractSub(clone(newMsg), ["BroadcastArea", "Media", "Start", "Support"]))

                if (isInitializing) {
                    if (newMsg.Format) { offer = getDefaultFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], offers }); offerFormat = offer; }
                    if (newMsg.Placement) offer = getFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], placement: newMsg.Placement, offers });
                }

                if (isInitializingOffer && !isInitializing) {
                    if (newMsg.Format) {
                        offer = getDefaultFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], offers });
                        offerFormat = offer;
                        await SetPriceOffer(newMsg, campaign, offer, "Format");
                    }
                    if (newMsg.Placement) {
                        offer = getFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], placement: newMsg.Placement, offers });
                        await SetPriceOffer(newMsg, campaign, offer, "Placement", false);
                    }
                }
            }

            if (!isInitializing) {
                if (oldMsg.Format != newMsg.Format || oldMsg.ModelProperties["Couleur"] != newMsg.ModelProperties["Couleur"]) {
                    offer = getDefaultFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], offers });
                    offerFormat = offer;
                    await SetPriceOffer(newMsg, campaign, offer, "Format", !newMsg.PricingLocked);
                    if (newMsg.Placement) {
                        offer = getFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], placement: newMsg.Placement, offers });
                        await SetPriceOffer(newMsg, campaign, offer, "Placement", false);
                    } if (newMsg.ModelProperties?.BroadcastPlacement) {
                        offer = getFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], placement: newMsg.ModelProperties?.BroadcastPlacement, offers });
                        SetBroadcastPriceOffer(newMsg, offer);
                    }
                    const [preCreatedMsg] = await Client.preCreateVertex(ref_Messages, [newMsg]);
                    newMsg.KPIs = { ...newMsg.KPIs, ...preCreatedMsg?.KPIs };
                }

                if (oldMsg.Placement != newMsg.Placement) {
                    offer = getFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], placement: newMsg.Placement, offers });
                    await SetPriceOffer(newMsg, campaign, offer, "Placement");
                    //SetPriceOffer(newMsg, getPlacementOffer({ "@rid": message.Placement }, offers), "Placement");
                }

                if (oldMsg.ModelProperties?.BroadcastPlacement != newMsg.ModelProperties?.BroadcastPlacement) {
                    if (!newMsg.ModelProperties?.BroadcastPlacement) newMsg.KPIs.BroadcastGross = 0;
                    else await SetBroadcastPriceOffer(newMsg, getFormatOffer({ format: newMsg.Format, couleur: newMsg?.ModelProperties["Couleur"], placement: newMsg.ModelProperties?.BroadcastPlacement, offers }));
                }
                if (isCreating)
                    await SetPerformancesKPIFromOJD(newMsg, oldMsg);
            }

            /* Clnt Discount Rates */
            if ((newMsg.AdvertiserGroup || newMsg.Advertiser) && newMsg.BroadcastArea && newMsg.Media) {
                if (isInitializing || (oldMsg.BroadcastArea !== newMsg.BroadcastArea ||
                    oldMsg.Media != newMsg.Media ||
                    oldMsg.Advertiser != newMsg.Advertiser ||
                    oldMsg.AdvertiserGroup != newMsg.AdvertiserGroup)) {
                    const previousDiscountRates = clone(state.messageEditor.clntDiscountRates);
                    clntDiscountRates = await getAvailableDiscountRates(newMsg);
                    console.log("Rates", clntDiscountRates, isCreating);
                    if (!isInitializing || state.messageEditor.mode == eDialogMode.duplicate)
                        await InitDiscountRates(newMsg, clntDiscountRates, previousDiscountRates);
                }
            }

            /** SupportDetails */
            if (properties.some(p => p.name == "ModelProperties.SupportDetails") && oldMsg.Support !== newMsg.Support && !isInitializing) {
                const _properties = ["Description", "ClntData.ModelProperties.Description"];
                const support = (await Client.searchVertexTyped(ref_Supports, { "@rid": newMsg.Support, properties: _properties }))?.[0];
                newMsg.ModelProperties["SupportDetails"] = support?.[_properties[1]] ?? support?.[_properties[0]];
            }
            /** Media Family */
            let mediaFamilyOptions: ref_Groups[] = null;
            if (properties.some(p => p.name == "ModelProperties.MediaFamily") && oldMsg.Support !== newMsg.Support) {
                ConsoleDebug('Update: MediaFamilyOptions');
                mediaFamilyOptions = await SetGroup(newMsg, newMsg.Support, eGroupCategories.MediaFamily, "MediaFamily", "MediaFamilyOther", !isInitializing)
            }
            /** Placement Category */
            let placementCategoryOptions: ref_Groups[] = null;
            if (oldMsg.Placement !== newMsg.Placement) {
                ConsoleDebug('Update: PlacementCategoryOptions');
                placementCategoryOptions = await SetGroup(newMsg, newMsg.Placement, eGroupCategories.PlacementCategory, "PlacementCategory", "PlacementCategoryOther", !isInitializing)
            }

            if (!newMsg.Discounts) newMsg.Discounts = [];

            let currency = state.messageEditor.currency;
            let currencyCode = state.messageEditor.currencyCode;

            if (newMsg.Currency != currency?.['@rid']) {
                currency = undefined;
                currencyCode = "";
                if (newMsg.Currency) {
                    currency = (await Client.searchVertexTyped(ref_Currencies, { "@rid": newMsg.Currency }))[0];
                    currencyCode = currency.Code;
                }
            }

            const kpiCPMHasChanged = () => {
                const oldKpis = oldMsg.Discounts?.filter(d => d.IsCPM).map(d => oldPerfKPIs[d.KpiCPM]);
                const newKpis = newMsg?.Discounts?.filter(d => d.IsCPM).map(d => newPerfKPIs[d.KpiCPM]);
                return !compareObjects(oldKpis, newKpis);
            }

            let cascade: ComputatedCascade = undefined;
            // si les discount ont changé
            // TODO à optimiser
            if (newMsg.KPIs?.Gross != oldMsg.KPIs?.Gross
                || !compareObjects(newMsg.Discounts, oldMsg.Discounts)
                || kpiCPMHasChanged())
                cascade = await DiscountManager.UpdateCascade(newMsg, campaign);

            // if net has changed, because of a change in agreement/GlobalAgreement/format/placement/start we notify the user
            if (!isInitializing && newMsg.KPIs?.NetCO != oldMsg.KPIs?.NetCO
                &&
                (newMsg.Agreement != oldMsg.Agreement
                    || newMsg.GlobalAgreement != oldMsg.GlobalAgreement
                    || newMsg.Format != oldMsg.Format
                    || newMsg.Placement != oldMsg.Placement
                    || new Date(oldMsg.Start).getTime() != new Date(newMsg.Start).getTime()))
                Notify(`${Trad("net_has_changed")}: ${GetKPITemplate(eKPIType.Price)(newMsg.KPIs?.NetCO)} ${currencyCode ?? ''}`, "warning");

            const validationIssues = SetValidationIssues(campaign, newMsg, state.messageEditor.validationIssues);

            const _time7642 = new Date().getTime();
            ConsoleDebug(`SetMessage Elapsed ${_time7642 - time7642}ms`, newMsg, validationIssues);


            return { cascade, message: ToRefMessageSerializable(newMsg), campaign: campaign ? ToRefCampaignSerializable(campaign) : null, validationIssues, offers, offer, offerFormat, currency, currencyCode, currencyOptions, broadcastOptions, mediaFamilyOptions, placementCategoryOptions, AdvCompany_ComOptions, AdvCompany_FinOptions, agreements, globalAgreement, kpis, currentAgreement, clntDiscountRates }
        } catch (error) {
            console.error(error);
            Notify(Trad('error'), 'error');
            Client.log({ Category: 'ERROR', Action: 'setMessage', Informations: SerializeError(error) });
        }
    }
)

export function SetValidationIssues(campaign: ref_Campaigns, message: ref_Messages | ref_MessagesSerializable, oldValidationIssues: string[]): (keyof ref_Messages)[] {
    const sellingMode = GetUser()?.customer?.Activity == 'Selling';
    const issues = [];

    let mandatory = ["Campaign", "Start", "Format"];
    if (GetUser()?.customer?.AdvertiserMode == ref_Messages.name) {
        if (RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read))
            mandatory.push("AdvertiserGroup");
        else
            mandatory.push("Advertiser");
    }
    if (GetUser()?.customer?.SupportMode == ref_Messages.name)
        mandatory = [...mandatory, ...["Support", "Currency", "BroadcastArea"]];

    mandatory.forEach(p => {
        if (!message[p])
            issues.push(p);
    })
    if (campaign?.KPIs[eKPI.Pagination] && message.KPIs?.[eKPI.Position] > campaign.KPIs[eKPI.Pagination]) {
        if (!oldValidationIssues.includes(eKPI.Position))
            Notify(Trad('position_is_greater_than_pagination'), 'warning');
        issues.push(eKPI.Position);
    }
    return issues;
}

export const messageEditorSlice = createSlice({
    name: 'messageEditor',
    initialState: clone(initialState),
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        builder.addCase(setMessage.pending, (state, action) => {
            state.lockNext = true;
        })

        const fulfilledCallback = (state: MessageEditorState, action) => {
            if (action.payload?.message)
                state.data = action.payload.message;
            if (action.payload?.validationIssues)
                state.validationIssues = action.payload.validationIssues;
            if (action.payload?.currencyCode)
                state.currencyCode = action.payload.currencyCode;
            if (action.payload?.campaign !== undefined)
                state.campaign = action.payload.campaign;
            if (action.payload?.offers)
                state.offers = action.payload.offers;
            if (action.payload?.offer !== undefined)
                state.offer = action.payload.offer;
            if (action.payload?.offerFormat !== undefined)
                state.offerFormat = action.payload.offerFormat;
            if (action?.payload?.currencyOptions)
                state.currencyOptions = action.payload.currencyOptions;
            if (action?.payload?.broadcastOptions)
                state.broadcastOptions = action.payload.broadcastOptions;
            if (action?.payload?.mediaFamilyOptions)
                state.groups.MediaFamily = action.payload.mediaFamilyOptions;
            if (action?.payload?.agencyOptions)
                state.agencyOptions = action.payload.agencyOptions;
            if (action?.payload?.placementCategoryOptions)
                state.groups.PlacementCategory = action.payload.placementCategoryOptions;
            if (action?.payload?.AdvCompany_ComOptions)
                state.AdvCompany_ComOptions = action.payload.AdvCompany_ComOptions;
            if (action?.payload?.AdvCompany_FinOptions)
                state.AdvCompany_FinOptions = action.payload.AdvCompany_FinOptions;
            if (action?.payload?.agreements)
                state.agreements = action.payload.agreements;
            if (action?.payload?.globalAgreement)
                state.globalAgreement = action.payload.globalAgreement;
            if (action?.payload?.kpis)
                state.kpis = action.payload.kpis;
            if (action?.payload?.currentAgreement !== undefined)
                state.currentAgreement = action.payload.currentAgreement;
            if (action?.payload?.cascade !== undefined)
                state.cascade = action.payload.cascade;
            if (action.payload?.clntDiscountRates !== undefined)
                state.clntDiscountRates = action.payload.clntDiscountRates;
        }

        builder.addCase(setMessage.fulfilled, (state, action) => {
            fulfilledCallback(state, action);
            state.lockNext = false;
        });

        builder.addCase(updateOffer.fulfilled, (state, action) => {
            fulfilledCallback(state, action);
        });

        builder.addCase(setNet.fulfilled, fulfilledCallback);
        builder.addCase(initMessageAgencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initMessageCurrencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initMessageBroadcastOptions.fulfilled, fulfilledCallback);
        builder.addCase(initAdvComOptions.fulfilled, fulfilledCallback);
        builder.addCase(initAdvFinOptions.fulfilled, fulfilledCallback);
    },
    reducers: {
        lockNext: (state, action: PayloadAction<void>) => {
            state.lockNext = true;
        },
        unlockNext: (state, action: PayloadAction<void>) => {
            state.lockNext = false;
        },
        toggleRepeat: (state, action: PayloadAction<void>) => {
            state.repeat = !state.repeat;
        },
        disableRepeat: (state, action: PayloadAction<void>) => {
            state.repeat = false;
        },
        setMessageMode: (state, action: PayloadAction<eDialogMode>) => {
            state.mode = action.payload;
        },
        setStart: (state, action: PayloadAction<{ start: Date, force?: boolean }>) => {
            const start = action.payload.start;
            state.data.Start = start?.toString() as any;

            if (action.payload.force) {

            }
        },
        setMessagePublications: (state, action: PayloadAction<ref_Publications[]>) => {
            state.publications = action.payload ? duplicate(action.payload) : undefined;
        },
        setCurrentNetType: (state, action: PayloadAction<netType>) => {
            state.currentNetType = action.payload;
        },
        setMessageSync: (state, action: PayloadAction<ref_Messages>) => {

            if (!action.payload) {
                const cloneValues = clone(initialState);
                Object.entries(cloneValues).forEach(([key, value]) => {
                    state[key] = value;
                });
                return;
            }

            const newMsg: ref_MessagesSerializable = {
                ...duplicate(action.payload),
                Start: action.payload.Start ? new Date(action.payload.Start).getTime() : undefined,
                End: action.payload.End ? new Date(action.payload.End).getTime() : undefined,
            }

            if (!newMsg.Discounts)
                newMsg.Discounts = [];

            if (!newMsg.ModelProperties) newMsg.ModelProperties = {};
            state.data = newMsg;
        },
        clearMessageEditor: (state, action: PayloadAction<void>) => {
            state.currencyOptions = undefined;
            state.broadcastOptions = undefined;
            state.AdvCompany_ComOptions = undefined;
            state.AdvCompany_FinOptions = undefined;
        },


        // setOffers: (state, action: PayloadAction<ref_Offers[]>) => {
        //     state.offers = action?.payload.map(o => ({
        //         ...duplicate(o),
        //         Start: o.Start ? new Date(o.Start).getTime() : undefined,
        //         End: o.End ? new Date(o.End).getTime() : undefined,
        //     }))
        // }
    },
})

type GetDefaultBroadcastAreasRes = {
    broadcastAreaOptions: ref_BroadcastAreas[],
    broadcastAreaDefault: ref_BroadcastAreas['@rid']
}

export async function GetDefaultAdvertisingCompany(support: ref_Supports['@rid'], broadcastArea?: ref_BroadcastAreas['@rid']) {
    try {

        //let defaultAdvCompany: ref_AdvertisingCompanies['@rid'] = null;
        let options: (LnkAdv | ref_SubAgencies)[] = undefined;
        ConsoleDebug('UpdateAdvertisingCompany');
        const tempParams = duplicate({
            Active: true,
            out: [support],
            properties: ["in", "@class", "in.Name as Name", "in.Active as Active", "in.@rid as outRid", "Default", "Roles", "DefaultBroadcastArea"],
            // ...params
        });

        const user = JSON.parse(localStorage.getItem("user")) as UserExtended;
        const newOptions = (await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, tempParams) as (lnk_AdvertisingCompanySupport & { Name: string })[])
            .filter(e => e.Active);
        const subAgencies = user?.customer?.Authorization == "MediaManager" ? await Client.searchVertexTyped(ref_SubAgencies) : [];
        options = [...newOptions, ...subAgencies].sort((a, b) => a.Name.localeCompare(b.Name));

        options = distinct(options, _value => _value?.['outRid'] ?? _value?.['@rid']);

        let defaultValue: IRid['@rid'] = null;
        const setValue = (value: LnkAdv | ref_SubAgencies) => {
            if (!value) {
                defaultValue = null;
                return;
            }

            let newValue: IRid["@rid"];
            if (value["@class"] == lnk_AdvertisingCompanySupport.name) newValue = (value as LnkAdv).outRid;
            else newValue = value["@rid"];
            defaultValue = newValue;
        }
        const setDefaultValue = async () => {

            if (options?.length === 1)
                return setValue(options[0]);

            const _options = options?.filter(o => o["@class"] == lnk_AdvertisingCompanySupport.name) as LnkAdv[]

            let defaultVal: LnkAdv | ref_SubAgencies;

            if (!defaultVal)
                defaultVal = _options?.find(v => v.Default);

            if (!defaultVal && broadcastArea) {
                const found = _options?.find(e => e.DefaultBroadcastArea == broadcastArea);
                if (found) {
                    defaultValue = found.in
                    return;
                }
            }

            setValue(defaultVal);
        }
        await setDefaultValue();

        return { options: options.map(o => ({ '@rid': o['outRid'] ?? o['@rid'], Name: o['Name'] })), defaultValue };
    } catch (error) {
        console.log(`error UpdateAdvertisingCompany`, error)
    }
}


export async function GetDefaultBroadcastAreas(support: ref_Supports['@rid']): Promise<GetDefaultBroadcastAreasRes> {
    let broadcastAreaOptions: ref_BroadcastAreas[] = undefined;

    ConsoleDebug('UpdateBroadcastState');

    const dispatchChanged = (broadcastArea: IRid) => {
        return {
            broadcastAreaOptions,
            broadcastAreaDefault: broadcastArea?.["@rid"]
        }
    }

    const lnkAdv = await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, { "out": support })
    const adv = distinct(lnkAdv.map(l => l.in), e => e);
    const broadcastAreas = await Client.searchVertexTyped(lnk_HasBroadcastArea, { "in": [support, ...adv] });

    const setDefault = async () => {
        broadcastAreaOptions = await Client.searchVertexTyped(ref_BroadcastAreas, {
            Active: true,
            "@rid": distinct(broadcastAreas.map(l => l.out), e => e),
            properties: ["@rid", "Name"]
        })

        if (broadcastAreaOptions.length == 1) {
            return dispatchChanged(broadcastAreaOptions[0]);
        }

        // const selectedElement = broadcastAreaOptions?.find(o => o["@rid"] == message.BroadcastArea);
        // if (selectedElement)
        //     return dispatchChanged(selectedElement);

        // recherche les régies associées au support
        let advDefault = distinct(lnkAdv.map(lnk => lnk.in), e => e);
        // si une seule régie alors elle est par défaut. Sinon recherche les régies dont le lien est par défaut
        if (advDefault.length > 1)
            advDefault = distinct(lnkAdv.filter(lnk => lnk.Default).map(lnk => lnk.in), e => e);

        if (advDefault.length) {
            // recherche de la zone de diff par défaut entre dans le lient support <=> régie
            const found = lnkAdv
                .filter(lnk => advDefault.includes(lnk.in) && lnk.DefaultBroadcastArea && (advDefault.length == 1 || lnk.Default))
                .map(lnk => lnk.DefaultBroadcastArea);

            if (found.length)
                return dispatchChanged(broadcastAreaOptions.find(o => found.includes(o["@rid"])))

            // recherche de la zone de diff par défaut de la première régie trouvée
            const broadcastAdvDefault = broadcastAreas.find(lnkB => advDefault.includes(lnkB.in) && lnkB.Default)?.out;
            if (broadcastAdvDefault)
                return dispatchChanged(broadcastAreaOptions.find(o => o["@rid"] == broadcastAdvDefault));
        }

        // recherche des zones de diff par défaut du support
        const defaultElement = broadcastAreaOptions.find(o => broadcastAreas.some(lnk => lnk.out == o["@rid"] && lnk.Default));
        return dispatchChanged(defaultElement);
    }

    return setDefault();
}

export type LnkAdv = (lnk_AdvertisingCompanySupport & {
    outRid: IRid["@rid"];
})

let financialRid: IRid["@rid"] = undefined;
let commercialRid: IRid["@rid"] = undefined;

async function UpdateAdvertisingCompany(property: "AdvCompany_Com" | "AdvCompany_Fin", messageEditor: MessageEditorState, message: ref_MessagesSerializable | ref_Messages) {
    try {
        const currentOptions = messageEditor[`${property}Options`];
        let options: (LnkAdv | ref_SubAgencies)[] = undefined;
        const { Support, ApplyComBarter, KPIs } = messageEditor.data ?? {};
        if (currentOptions == undefined || Support !== message.Support || ApplyComBarter != message.ApplyComBarter ||
            ((!KPIs?.Barter && message.KPIs.Barter) || (KPIs?.Barter && !message.KPIs.Barter))) {
            ConsoleDebug('UpdateAdvertisingCompany');
            const setValue = (value: LnkAdv | ref_SubAgencies) => {
                if (!value) {
                    message[property] = null;
                    return;
                }

                let newValue: IRid["@rid"];
                if (value["@class"] == lnk_AdvertisingCompanySupport.name) newValue = (value as LnkAdv).outRid;
                else newValue = value["@rid"];
                message[property] = newValue;
            }

            let params = undefined;
            let barterCompanies: ref_AdvertisingCompanies[] = [];

            switch (property) {
                case "AdvCompany_Com":
                    if (!commercialRid) commercialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Commercial" }))?.data?.results?.[0]?.["@rid"];
                    params = { Roles: commercialRid }
                    break;
                case "AdvCompany_Fin":
                    if (!financialRid) financialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Financial" }))?.data?.results?.[0]?.["@rid"];
                    params = { Roles: financialRid }
                    break;
                default:
                    break;
            }
            if ((message.KPIs.Barter || message.SmartBarter) &&
                (property == "AdvCompany_Fin" || message.ApplyComBarter)) {
                barterCompanies = await Client.searchVertexTyped(ref_AdvertisingCompanies, { "IsBarter": true });
            }
            const tempParams = duplicate({
                Active: true,
                out: [message.Support],
                properties: ["in", "Start", "End", "@class", "in.Name as Name", "in.Start as ValidStart", "in.End as ValidEnd", "in.@rid as outRid", "Default", "Roles", "DefaultBroadcastArea"],
                ...params
            });

            const user = JSON.parse(localStorage.getItem("user")) as UserExtended;
            let newOptions = await Client.searchVertexTyped(lnk_AdvertisingCompanySupport, tempParams) as (lnk_AdvertisingCompanySupport & { Name: string })[];
            const subAgencies = user?.customer?.Authorization == "MediaManager" ? await Client.searchVertexTyped(ref_SubAgencies) : [];

            if (message.Start) {
                newOptions = newOptions.filter(lnk =>
                    DateZone(lnk.Start) <= message.Start && (!lnk.End || message.Start <= DateZone(lnk.End))
                    && DateZone(lnk["ValidStart"]) <= message.Start && (!lnk["ValidEnd"] || message.Start <= DateZone(lnk["ValidEnd"])));
            }

            options = [...newOptions, ...subAgencies, ...barterCompanies].sort((a, b) => a.Name.localeCompare(b.Name));

            const setDefaultValue = async () => {

                if (options?.length === 1)
                    return setValue(options[0]);

                const _options = options?.filter(o => o["@class"] == lnk_AdvertisingCompanySupport.name) as LnkAdv[]

                let defaultVal: LnkAdv | ref_SubAgencies;

                if (message[property]) {
                    defaultVal = _options?.find(v => v.outRid == message[property]);
                    if (!defaultVal)
                        defaultVal = options?.find(v => v["@rid"] == message[property]);
                }

                if (!defaultVal)
                    defaultVal = _options?.find(v => v.Default);

                if (!defaultVal && message.BroadcastArea) {
                    const found = _options?.find(e => e.DefaultBroadcastArea == message.BroadcastArea);
                    if (found) {
                        message[property] = found.in
                        return;
                    }
                }

                setValue(defaultVal);
            }

            if (currentOptions === undefined) {
                if (message[property]) {
                    const defaultValue = options?.find(o => o["@rid"] == message[property] || (o as any).outRid == message[property]);
                    if (!defaultValue) {
                        console.log(`add inexising`)
                        const [missingValue] = await Client.searchVertexTyped(ref_AdvertisingCompanies, { "@rid": message[property] });
                        if (missingValue) {
                            (missingValue as any).warning = Trad('data_not_found');
                            options = [missingValue, ...options];
                        }
                    }
                }
            }
            await setDefaultValue();
        }

        return options;
    } catch (error) {
        console.log(`error UpdateAdvertisingCompany`, error)
    }
}

// Action creators are generated for each case reducer function
export const { setMessageMode, setCurrentNetType, setMessageSync, setMessagePublications, setStart, lockNext, unlockNext, toggleRepeat, disableRepeat, clearMessageEditor } = messageEditorSlice.actions

export const messageEditorReducer = messageEditorSlice.reducer;


