import { rid } from "../../models/orientdb/CommonTypes.bin";
import { lnk_HasModule } from "../../models/orientdb/lnk_HasModule.bin";
import { ref_Customers } from "../../models/orientdb/ref_Customers.bin";

export class ref_CustomersExtended extends ref_Customers {
    EstimatesAPI: string;
	FinancialAPI: string;
    MediaManagerAPI: string;
    MediaPilotAPI: string;
    Children: { "@rid": rid, Name: string }[]
    lnkHasModule: lnk_HasModule[]
    AllocatedLicences: number
    DisplayMAPData : boolean
}