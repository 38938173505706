
import { Grid, GridCell, GridColumn, GridNoRecords, GridRowProps } from "@progress/kendo-react-grid";
import { Indicateur } from "adwone-engine/index.bin";
import { getIcon, VertexAutocomplete } from "adwone-lib";
import { propertyOf, toArray } from "hub-lib/tools.bin";
import * as React from "react";
import { Trad } from "trad-lib";
import { Grid as MUIGrid } from "@material-ui/core";
import { RowSize } from "../../../../../styles/theme";
import { CustomButton } from "../../../../ConfigurableComponents/CustomButton.bin";
import { ContainerComponent } from "../../../Generic/ContainerComponent";
import { CustomIconButton } from "../../../Generic/CustomIconButton";
import "./theme.scss";
import { ref_PivotGridConfigurations } from "hub-lib/dto/client/ref_PivotGridConfigurations.bin";
import { DragAndDrop } from "@progress/kendo-react-common";

type DimensionsPickerProps<T> = {
    title?: string;
    data: T[],
    values: DimensionsPickerProps<T>['data'],
    onChange: (values: DimensionsPickerProps<T>['data']) => void,
    config: ref_PivotGridConfigurations;
    datakey?: string;
    activeItem?: T;
    setActiveItem?: (value: T) => void;
    setToDelete?: (value: T) => void;
};

type ReorderContextProps<T> = {
    reorder: (dataItem: T) => void;
    dragStart: (dataItem: T) => void;
};

const ReorderContext = React.createContext<ReorderContextProps<Indicateur>>({
    reorder: () => {},
    dragStart: () => {},
});

type DragContextProps = {
    dragFrom: string;
    setDragFrom: (value: string) => void;
}

const DragContext = React.createContext<DragContextProps>({
    dragFrom: "",
    setDragFrom: () => {}
});

interface DraggableRowProps extends GridRowProps {
    elementProps: any;
    dragFrom: string;
    row: any;
}

const DraggableRow = (props: DraggableRowProps) => {
    const { dragStart, reorder } = React.useContext(ReorderContext);

    const additionalProps = {
        onDragStart: (e) => dragStart(props.dataItem),
        onDragOver: (e) => {
            e.preventDefault();
            reorder(props.dataItem);
        },
        draggable: true,
    };

    return React.cloneElement(
        props.row,
        { ...props.row.props, ...additionalProps },
        props.row.props.children
      );
}

type IndicateurPickerProps = DimensionsPickerProps<Indicateur>;
export function DimensionsPicker({ data, title, values, onChange, config, datakey, activeItem, setActiveItem, setToDelete }: IndicateurPickerProps) {
    const { dragFrom, setDragFrom } = React.useContext(DragContext);
    const [addingNew, setAddingNew] = React.useState(false);
    const gridRef = React.useRef<Grid>(null);

    React.useEffect(() => {
        const gridElement = gridRef.current.element;

        gridElement.addEventListener('dragover', dragOverEvent);
        gridElement.addEventListener('drop', dropEvent);

        return () => {
            gridElement.removeEventListener('dragover', dragOverEvent);
            gridElement.removeEventListener('drop', dropEvent);
        }
    }, [dragFrom, activeItem])

    const dragOverEvent = (e: DragEvent) => {
        e.preventDefault();
    }

    const dropEvent = (e: DragEvent) => {
        e.preventDefault();

        if (dragFrom && dragFrom !== datakey) {
            // Add to values if not already in
            // And remove from previous
            // Check by field
            if (!values.some(i => i.name === activeItem.name)) {
                onChange([...values, activeItem]);
                setToDelete(activeItem);
            }
        }
    }

    const reorder = (dataItem: Indicateur) => {
        if (!activeItem || (activeItem === dataItem)) return;

        const reorderedData = values.slice();

        const prevIndex = reorderedData.findIndex(i => i === activeItem);

        if (prevIndex === -1) return;

        const nextIndex = reorderedData.findIndex(i => i === dataItem);

        reorderedData.splice(prevIndex, 1);
        reorderedData.splice(nextIndex, 0, activeItem);

        onChange(reorderedData);
    }

    const dragStart = (dataItem: Indicateur) => {
        if (activeItem != dataItem) {
            setActiveItem(dataItem);
        }

        setDragFrom(datakey);
    }

    return <ContainerComponent id="a" title={title ?? ''} className="tcd-dimensions-picker-container">
        <div id="b" className="tcd-dimensions-picker">
            {/** Affichage des indicateurs déjà dans la configuration */}
            <ReorderContext.Provider value={{ reorder, dragStart }}>
                <DragAndDrop>
                    <Grid id="c" ref={gridRef} className="indicateurs-grid" data={addingNew ? [...values, null] : values} rowRender={(row, rowProps) => (
                        <DraggableRow row={row} dragFrom={dragFrom} elementProps={row.props} {...rowProps} />
                    )}>
                        <GridNoRecords>
                            {Trad('no-dimensions-selected')}
                        </GridNoRecords>

                        <GridColumn width={25} title="" cell={DragCell()} />
                        <GridColumn
                            field={propertyOf<IndicateurPickerProps['data'][0]>('name')}
                            cell={p => {
                                if (!p.dataItem)
                                    return <td>
                                        <VertexAutocomplete
                                            label=""
                                            options={data.filter(item => !config.Rows.map(i => i.name).includes(item.name) && !config.Columns.map(i => i.name).includes(item.name))}
                                            getOptionLabel={(i: Indicateur) => i.name}
                                            onChange={v => {
                                                setAddingNew(false);
                                                onChange([...values, ...toArray(v)]);
                                            }} />
                                    </td>;
                                return <GridCell {...p} />;
                            }} />

                        <GridColumn
                            cell={(c) => (
                                <td>
                                    <CustomIconButton
                                        disabled={(values?.length ?? 0) <= 1}
                                        onClick={() => {
                                            if (!c.dataItem) setAddingNew(false);
                                            else onChange(values.filter(v => v != c.dataItem));
                                            // this.onRemove(c.dataItem)
                                        }}>
                                        {getIcon("close")}
                                    </CustomIconButton>
                                </td>
                            )}
                            width={28}
                        />
                    </Grid>
                </DragAndDrop>
            </ReorderContext.Provider>
            <div style={{ height: RowSize }}>
                <CustomButton
                    className="custom_btn_secondary add-indicateur-button"
                    startIcon={getIcon("plus_rounded")}
                    Label={Trad('add')}
                    onClick={() => setAddingNew(true)} />
            </div>
        </div>
    </ContainerComponent>
}

const DragCell = (): any => {
    return class DragCell extends React.Component<any, any> {
        render() {
            const item = this.props.dataItem;
            const cursor: React.CSSProperties["cursor"] = "move";
            return (
                <td style={{ textAlign: 'center', cursor }} className={`${!item ? 'cell-disable' : ''}`}
                    /*draggable="true"*/
                >
                    <span /*data-drag-handle="true"*/ className="k-icon k-i-reorder" style={{ cursor, textAlign: 'center' }} />
                </td>
            );
        }
    }
}

interface RowsColumnsDimensionsPickerProps {
    dimensions: Indicateur[];
    config: ref_PivotGridConfigurations;
    onRowsChange: (values: Indicateur[]) => void;
    onColumnsChange: (values: Indicateur[]) => void;
}

export const RowsColumnsDimensionsPicker = (props: RowsColumnsDimensionsPickerProps) => {
    const [dragFrom, setDragFrom] = React.useState<string>("");
    const [activeItem, setActiveItem] = React.useState<Indicateur | null>(null);
    const [toDelete, setToDelete] = React.useState<Indicateur | null>(null);

    React.useEffect(() => {
        if (toDelete && dragFrom) {
            if (dragFrom === "rows") {
                props.onRowsChange(props.config.Rows.filter(i => i.name !== toDelete.name));
            }
            else if (dragFrom === "columns") {
                props.onColumnsChange(props.config.Columns.filter(i => i.name !== toDelete.name));
            }

            setToDelete(null);
        }
    }, [toDelete]);

    return (
        <DragContext.Provider value={{ dragFrom, setDragFrom }}>
            <MUIGrid item xs={6} style={{ paddingRight: 3, marginTop: 6 }}>
                <DimensionsPicker
                    title={Trad('rows')}
                    data={props.dimensions}
                    values={props.config.Rows ?? []}
                    onChange={props.onRowsChange}
                    config={props.config}
                    datakey="rows"
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    setToDelete={setToDelete}
                />
            </MUIGrid>
            <MUIGrid item xs={6} style={{ paddingLeft: 3, marginTop: 6 }}>
                <DimensionsPicker
                    title={Trad('columns')}
                    data={props.dimensions}
                    values={props.config.Columns ?? []}
                    onChange={props.onColumnsChange}
                    config={props.config}
                    datakey="columns"
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    setToDelete={setToDelete}
                />
            </MUIGrid>
        </DragContext.Provider>
    )
}