import * as React from 'react';
import { clone } from 'hub-lib/tools.bin';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../../redux/projectSlice';
import { RootState, store } from '../../../redux/store';
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker';

type DateRangePickerFilterProps = {
    onChange?: (e: {
        Start: Date,
        End: Date
    }) => void;
    min?: Date;
    maxYearsRange?: number;
}

export function DateRangePickerFilter({ onChange, min, maxYearsRange }: DateRangePickerFilterProps) {

    const dispatch = useDispatch();
    const start = useSelector((root: RootState) => root.project.filters?.Start);
    const end = useSelector((root: RootState) => root.project.filters?.End);

    if (!start || !end)
        return <div></div>

    return <DateRangepicker
        min={min}
        maxYearsRange={maxYearsRange}
        defaultStart={new Date(start)}
        defaultStop={new Date(end)}
        handleChangeDate={async (e: { start: Date, end: Date }) => {
            if (e.end) {
                const modeleFilters = clone(store.getState().project.filtersModele);
                const config = clone(store.getState().project.filters);
                config.Start = new Date(e.start);
                config.End = new Date(e.end);
                dispatch(setFilters({ ...modeleFilters, Filters: config }));
                onChange?.({ Start: config.Start, End: config.End });
            }
        }} />
}