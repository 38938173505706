export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const GET_ITEMS = 'GET_ITEMS'
export const ITEMS_LOADING = 'ITEMS_LOADING'
export const NO_ITEMS = 'NO_ITEMS'
export const GET_SUPPORT_DETAILS = 'GET_SUPPORT_DETAILS'
export const SUPPORT_DETAILS_LOADING = 'SUPPORT_DETAILS_LOADING'
export const GET_SUPPORT_OFFERS = 'GET_SUPPORT_OFFRES'
export const GET_SUPPORT_ISSUES = 'GET_SUPPORT_ISSUES'
export const GET_USERS = 'GET_USERS'
export const USERS_LOADING = 'USERS_LOADING'
export const ADD_CONTACT = 'ADD_CONTACT'
export const REMOVE_CONTACT = 'REMOVE_CONTACT'
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS'
export const CHANGE_CONTACT_STATUS = 'CHANGE_CONTACT_STATUS'
export const IMPORT_REFRESHED = 'IMPORT_REFRESHED'
export const IMPORT_DONE = 'IMPORT_DONE'
export const IMPORT_IN_PROGRESS = 'IMPORT_IN_PROGRESS'