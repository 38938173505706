import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
import { ref_ExportConfigurations } from "./ref_ExportConfigurations.bin";
import { ref_FilterConfigurations } from "./ref_FilterConfigurations.bin";
import { ref_TableConfigurations } from "./ref_TableConfigurations.bin";

export class ref_ProjectConfigurations extends ref_ConfigurationsBase {
    ConfTableActive?: boolean;
    ConfTable?: ref_TableConfigurations["@rid"];
    ConfFilterActive?: boolean;
    ConfFilter?: ref_FilterConfigurations["@rid"];
    ConfExportActive?: boolean;
    ConfExport?: ref_ExportConfigurations["@rid"];
}