import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Trad } from "trad-lib";
import { VertexAutocomplete } from "adwone-lib";
import { ref_GitScript } from "hub-lib/models/ref_GitScript";
import ReactJson from "react-json-view";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import { Client } from "hub-lib/client/client.bin";
import { duplicate } from "hub-lib/tools.bin";


class TProps {
    updateScriptID: any
}



const GitScriptDialog = (props: TProps) => {

    const { updateScriptID } = props;
    const [script, setScript] = useState<ref_GitScript>({
        "@rid": undefined,
        Name: '',
        ScriptGitUri: '',
        Params: {},
        Active: true
    });

    useEffect(() => {
        updateScriptID(script['@rid']);
      }, [script["@rid"]]);

    const onObjectFilePropertyChanged = (event: any, propertyName: string) => {
        let tmpScript = duplicate(script);
        tmpScript[propertyName] = event.target.value;
        setScript(tmpScript);
    }

    const onCreateNewGitScript = async () => {
        const res = await Client.createVertex(ref_GitScript.name, script);
        let tmpScript = duplicate(script);
        tmpScript["@rid"] = res.data.results["@rid"];
        setScript(tmpScript);
    }

    const onModifyGitScript = async () => {
        const res = await Client.updateVertex(ref_GitScript.name, script);
    }

    const onDeleteGitScript = async () => {
        const res = await Client.deleteVertex(ref_GitScript.name, script['@rid']);
        let tmpScript = new ref_GitScript();
        setScript(tmpScript);
    }

    const selectedScriptExist = () => {
        return script['@rid'] !== undefined;
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: 12 }}>
                <VertexAutocomplete
                    disableClearable
                    label={Trad("git script name")}
                    key={script["@rid"]}
                    type={ref_GitScript.name}
                    params={{ Active: true }}
                    onChange={async (scriptDB: ref_GitScript) => {
                        let tmpScript = await (await Client.searchVertex(ref_GitScript.name, { "@rid": scriptDB['@rid'] })).data.results[0];
                        setScript(tmpScript);
                    }}
                />
            </Grid>
            <Grid item xs={4} className="adw-row">
                <TextField style={{ width: "100%" }}
                    variant="outlined"
                    label={Trad("name")}
                    value={script.Name}
                    onChange={(event) => { onObjectFilePropertyChanged(event, 'Name') }}
                />
            </Grid>
            <Grid item xs={4} className="adw-row">
                <TextField style={{ width: "100%" }}
                    variant="outlined"
                    label={Trad("url")}
                    value={script.ScriptGitUri}
                    onChange={(event) => { onObjectFilePropertyChanged(event, 'ScriptGitUri') }}
                />
            </Grid>
            <Grid item xs={12}>
                <ReactJson
                    src={script.Params}
                    key={script["@rid"]}
                    name={Trad("params")}
                    displayDataTypes={false}
                    sortKeys
                    displayObjectSize={false}
                    enableClipboard={false}
                    onAdd={(e) => (script.Params = e.updated_src as any)}
                    onEdit={(e) => (script.Params = e.updated_src as any)}
                    onDelete={(e) => (script.Params = e.updated_src as any)}
                />
            </Grid>
            {selectedScriptExist() ?
                <Grid container direction="row" item xs={12} className='block-container'>
                    <CustomButton style={{ marginRight: 10 }} Label={Trad("modify")} className="custom_btn_primary" onClick={() => { onModifyGitScript(); }} />
                    <CustomButton style={{ marginRight: 10 }} Label={Trad("delete")} className="custom_btn_secondary" onClick={() => { onDeleteGitScript(); }} />
                </Grid>
                :
                <Grid container direction="row" item xs={12} className='block-container'>
                    <CustomButton style={{ marginRight: 10 }} Label={Trad("save")} className="custom_btn_primary" onClick={() => { onCreateNewGitScript(); }} />
                </Grid>
            }
        </Grid>
    )
}
export default GitScriptDialog;