import { Referentials } from "./orientdb/Referentials.bin";
import { V } from "./orientdb/V.bin";
import { rid } from "./orientdb/CommonTypes.bin";

export type ref_CouplagesId = rid;
export type ref_SupplierCampaignsId = rid;


export class ref_SupplierCampaigns extends V implements Referentials {
    "@rid"?: ref_SupplierCampaignsId;
    Active: boolean;
    Name: string;
    Start: Date;
    End: Date;
    Advertiser?: number;
    MandataireReservation?: number;
    Support?: number; // QUOTAS
    Nature?: number;
    Media?: number;
    Gross?: number;
    Net?: number;
    SpotsNumber: number;
}
export class ref_Couplages extends V implements Referentials {
    "@rid"?: ref_CouplagesId;
    Active: boolean;
    Code: string;
    Name: string;
    Start: Date;
    End: Date;
    Advertiser?: number[];
    Brand?: number[];
    Product?: number[];
    MandataireReservation?: number[];
    Support?: number[]; // QUOTAS
    Nature?: number[];
    Media?: number[];
    Gross?: number;
    Net?: number;
    SpotsNumber: number;
    Campaigns: ref_SupplierCampaigns[];
    //Nb GRP (?)
}

export class ref_Spots extends V implements Referentials {
    "@rid"?: ref_CouplagesId;
    Active: boolean;
    Name: string;
    DatePrev: Date;
    DateDiff: Date;
    Campaign?: number;
    CampaignName?: string;
    Support?: number;
    Format?: number;
    Placement?: string;
    PosDiff?: number;
    Gross?: number;
    Net?: number;
    Type?: string;
}

export enum SpotEmplacement {
    Indifferent = 0,
    TE = 1,
    T2 = 2,
    T3 = 3,
    AF = 4,
    FE = 5,
    TE_T2_FE = 8,
    Sans = 9,
    TE_FE = 12,
    AAF = 13,
    D4 = 14,
    D6 = 15,
    TE_T2 = 16,
    TE_T2_T3 = 17,
    TE_T2_T3_FE = 18,
    AAF_AF_FE = 19,
    AF_FE = 20
}

export enum SpotType {
    Réservé = 0,
    Confirmé = 1,
    Demande = 2,
    Annulé = 3,
    Supprimé = 4
  }