import {IMPORT_DONE, IMPORT_IN_PROGRESS, IMPORT_REFRESHED} from '../actions/types'

const initialState = {
    list: [],
    refreshList: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case IMPORT_REFRESHED:
            //reinit refreshList
            return {
                ...state,
                refreshList: false,
            }
        case IMPORT_IN_PROGRESS:
            //find element if exist and maj
            return {
                ...state,
                list: state.list.find(e=>e["@rid"] == action.payload["@rid"]) ? [ ...state.list.filter(e=>e["@rid"]!=action.payload["@rid"]), action.payload ] : [...state.list, action.payload],
                refreshList: action.forceUpdate === true
            }
        case IMPORT_DONE:
            //find element and delete him
            return {
                ...state,
                list: [...state.list.filter(e=>e["@rid"]!=action.payload["@rid"])],
                refreshList: true
            }
        default:
            return state
    }
}