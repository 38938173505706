import * as React from "react";

import ListItemLink from './ListItemLink'
import Collapse from "@material-ui/core/Collapse"
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { getIcon } from "adwone-lib/index";

export type SubMenuElement = {
    icon: string,
    name: string,
    path?: string,
    children?: SubMenuElement[]
}

export type SubMenuProps = {
    menu: SubMenuElement
}

function SubMenu({ menu }: SubMenuProps) {

    const isMounted = React.useRef(false);
    const [open, setOpen] = React.useState(false);

    const handleClick = () => setOpen(!open);

    React.useState(() => {
        if (!isMounted.current && menu.children.some(e => e.path == window.location.pathname))
            setOpen(true);
        isMounted.current = true;
    })

    return <>
        <ListItem button onClick={handleClick} className="navigation_drawer_list_item">
            <ListItemIcon className="navigation_drawer_list_item_icon">
                {menu.icon}
            </ListItemIcon>
            <ListItemText primary={<Typography className="navigation_drawer_list_item_text">{menu.name}</Typography>} />
            {open ? getIcon("less") : getIcon("more")}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {menu.children.map(el => {
                    return el.path
                        ? <ListItemLink key={el.name} isSub={true} icon={<ListItemIcon className="navigation_drawer_list_item_icon">{el.icon}</ListItemIcon>} primary={<Typography className="navigation_drawer_list_item_text" >{el.name}</Typography>} to={el.path} />
                        : <div key={el.name} className="navigation_drawer_list_subitem_submenu"><SubMenu menu={el} /></div>
                })}
            </List>
        </Collapse>
    </>
}
export default SubMenu;