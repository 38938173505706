import * as React from "react"
import { Trad } from "trad-lib";
import { CustomGroupButton } from "../../ConfigurableComponents/CustomGroupButton.bin";
import { getIcon } from "adwone-lib/index"

export class TProps<T> {
    label?: string;
    options: T[];
    onChanged?: (selected: T) => void;
    //addNew?: () => void;
    disabled?: boolean;
    id?: string;
    isIcon?: boolean;
    disableMoreIcon?: boolean;
    className?: string;
    tooltip?: string;
}
class TState {
    selectedIndex: number;
}

export class CustomAddButtons<T extends { Name?: string }> extends React.Component<TProps<T>, TState> {

    constructor(props: TProps<T>) {
        super(props);
        let newState = new TState();
        newState.selectedIndex = 1;
        this.state = newState;
    }

    handleMenuItemClick = (index: number) => {
        this.setState({ selectedIndex: index }, () => {
            this.props.onChanged?.(this.props.options?.[this.state.selectedIndex])
            //this.props.addNew?.(this.props.options[this.state.selectedIndex])
        })
    }

    render() {
        const { tooltip } = this.props;
        return (
            <>
                <CustomGroupButton
                    title={tooltip}
                    style={{ float: 'right', marginRight: 0 }}
                    Label={this.props.label}
                    isIcon={this.props.isIcon}
                    disableMoreIcon={this.props.disableMoreIcon}
                    containerClassname={(this.props.disabled || this.props.options?.length === 0 ? "button_group_disabled" : "custom_btn_primary")}
                    buttonClassName={this.props.className ?? "custom_btn_primary "}
                    disabled={this.props.disabled || this.props.options?.length === 0}
                    startIcon={getIcon("plus")}
                    onClick={() => {
                        if (!this.props.options)
                            this.handleMenuItemClick(undefined)
                    }}
                    buttons={this.props.options?.map((op, index) => (
                        { Label: Trad(op["Name"]), onClick: () => this.handleMenuItemClick(index) }
                    ))} />
            </>
        )
    }
}