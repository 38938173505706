
import { DateRangepicker } from "../../ConfigurableComponents/DateRangepicker"
import { HierarchyComboManager } from "../HierarchyComponent/HierarchyComponent.bin";
import { Element } from "../Generic/Common.bin";
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";

class TProps {
    config?: Filter;
    onConfChange?: any;
}

export function EstimateDetailsFiltersComponent({ config, onConfChange }: TProps) {

    return <div>
        {/** *** FILTRES *** */}
        <Element className="adw-row">
            <DateRangepicker
                disabled
                defaultStart={config.Start}
                defaultStop={config.End}
                min={config.Start}
                handleChangeDate={(e: { start: Date, end: Date }) => {
                    if (e.end) {
                        config.Start = new Date(e.start);
                        config.End = new Date(e.end);
                        onConfChange?.(config);
                    }
                }} />
        </Element>

        {/** *** SOURCE *** */}
        {/* <DataMapSwitch
            onChange={sources => {
                config.Source = sources;
                onConfChange?.(config)
            }} /> */}

        <HierarchyComboManager
            fullWidth
            multi
            disableProperty={{
                AdvertiserGroup: config.AdvertiserGroup ? true : false,
                Advertiser: config.Advertiser ? true : false,
                Brand: config.Brand ? true : false,
                Product: config.Product ? true : false
            }}
            store={config}
            onConfChanged={onConfChange} />

    </div>
}
