import { getIcon } from "adwone-lib";
import { Client } from "hub-lib/client/client.bin";
import React from "react";
import stringSimilarity from "string-similarity";
import { Notify } from "../../../../utils/Notify.bin";
import { GenericTooltip } from "../../../ConfigurableComponents/GenericTooltip.bin";
import { CustomIconButton } from "../../Generic/CustomIconButton";

type SimilarityCheckerProps = {
    type: string;
    name: () => string;
}

export function SimilarityChecker({ type, name }: SimilarityCheckerProps) {

    const [isSearching, setIsSearching] = React.useState(false);
    const [closeElements, setCloseElements] = React.useState<{ score, name, id }[]>(null);

    const onSearch = async () => {

        if (!name())
            return;

        setIsSearching(true);
        let close = null;
        try {

            if (["Emplacement", "Format"].includes(type))
                type = "ref_Property";

            const res = (await Client.searchVertex(type, { Active: true, properties: ['Name'] }, true, { cache: false }))?.data?.results;
            const nameUpper = name()?.toLocaleUpperCase?.();
            close = res.map(e => {
                const nameExisting = e?.Name?.toLocaleUpperCase?.();
                if (!nameExisting)
                    return;

                const score = stringSimilarity.compareTwoStrings(nameExisting, nameUpper);
                return {
                    score,
                    name: e.Name,
                    id: e["@rid"]
                }
            }).filter(e => e && e?.score >= 0.6).sort((a, b) => b.score - a.score);
        } catch (e) {
            console.error(e);
            Notify("Error while searching for similar elements", "error");
        }

        setIsSearching(false);

        setCloseElements(close);
        console.log(close);
    }

    return <span style={{ display: 'flex', alignItems: 'center' }}>
        {closeElements?.length > 0
            && <span style={{ color: '#F4611D', display: 'flex', alignItems: 'center' }}>
                <GenericTooltip tooltipContent={() => <TooltipCloseElements elements={closeElements} />}>
                    {getIcon("warning")}
                </GenericTooltip>
            </span>}
        {closeElements && !closeElements.length && <span style={{ color: '#4CAF50' }}>{getIcon("check")}</span>}
        {!isSearching && <CustomIconButton onClick={onSearch}>{getIcon("search")}</CustomIconButton>}
        {isSearching && <CustomIconButton className="rotating-element">{getIcon("autorenew")}</CustomIconButton>}
    </span>
}

type TooltipCloseElementsProps = {
    elements: { name, id, score }[]
}
function TooltipCloseElements({ elements }) {
    // display table
    return (
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Score</th>
                </tr>
            </thead>
            <tbody>
                {elements.map((element, index) => (
                    <tr key={index}>
                        <td>{element.id}</td>
                        <td>{element.name}</td>
                        <td>{Math.round(element.score * 100)}%</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
} 