import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { rid } from 'hub-lib/models/orientdb/CommonTypes.bin';
import { Referentials } from 'hub-lib/models/orientdb/Referentials.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { ref_PropertyType } from 'hub-lib/models/orientdb/ref_PropertyType.bin';
import { src_MM } from 'hub-lib/models/orientdb/src_MM.bin';
import { Views } from 'hub-lib/models/orientdb/Views.bin';
import { metaEdge } from 'hub-lib/models/types.bin';
import { GetToday } from 'tools-lib';
import { getLinkFromName } from '../components/layout/SuperAdmin/LinkManager/LinkManagerTools';
import { FilterDescriptor } from '@progress/kendo-data-query';


type FilterReferential = {
    includeLinkedRef: boolean
    includeUnlinkedRef: boolean
    includeActiveRef: boolean
    includeInactiveRef: boolean
    searchRef: string;
    columnFilters: FilterDescriptor[];
    // searchRid: string;
}

type FilterView = {
    searchView: string
    // searchParentId: string
    includeLinkedView: boolean
    includeUnlinkedView: boolean
    excludeInactiveView: boolean
    filterViewUpdate: boolean
    minDateViewUpdate: Date
}

export interface LinkMgrState {
    filtersReferential: FilterReferential
    filtersView: FilterView

    selectedDimension: string;
    selectedSource: src_MM | undefined;
    selectedLink: metaEdge;
    selectedProptype: ref_PropertyType | undefined;

    /** Contenu du tableau référentiel */
    modalities: Referentials[];

    /** Contenur du tableau des vues */
    modalitiesViews: Views[];
    topModalitiesViews: Views[];
    filteredModalitiesViews: Views[];

    links: metaEdge[];

    inId: rid | undefined;
    outIds: rid[];
    outIdsAuto: rid[];

    loaders: {
        links: boolean;
        gridRef: boolean;
        gridView: boolean;
    }
}

const initialState: LinkMgrState = {
    filtersReferential: {
        includeLinkedRef: true,
        includeUnlinkedRef: true,
        includeActiveRef: true,
        includeInactiveRef: false,
        searchRef: "",
        columnFilters: []
        // searchRid: ""
    },

    filtersView: {
        searchView: "",
        // searchParentId: "",
        includeLinkedView: true,
        includeUnlinkedView: true,
        excludeInactiveView: true,
        filterViewUpdate: false,
        minDateViewUpdate: GetToday()
    },

    selectedDimension: ref_Advertisers.name,
    selectedSource: undefined,
    selectedLink: undefined,
    selectedProptype: undefined,

    modalities: [],
    modalitiesViews: [],
    topModalitiesViews: [],
    filteredModalitiesViews: [],

    links: [],

    inId: "",
    outIds: [],
    outIdsAuto: [],

    loaders: {
        links: false,
        gridRef: false,
        gridView: false
    }
}

export const linkMgrSlice = createSlice({
    name: 'linkMgr',
    initialState: initialState as LinkMgrState,
    reducers: {
        select: (state, action: PayloadAction<Partial<Pick<LinkMgrState, "selectedDimension" | "selectedLink" | "selectedSource" | "selectedProptype">>>) => {

            const { selectedDimension, selectedSource } = action.payload;

            /** Si la dimension ou la source ont changé */
            if ((selectedDimension && selectedDimension != state.selectedDimension) ||
                (selectedSource && selectedSource != state.selectedSource)) {
                Object.assign(state, action.payload);
                updateSelectedLink(state);
            }

            Object.assign(state, action.payload);
        },
        setReferentialFilters: (state, action: PayloadAction<Partial<FilterReferential>>) => {
            Object.assign(state.filtersReferential, action.payload);
        },
        setViewFilters: (state, action: PayloadAction<Partial<FilterView>>) => {
            Object.assign(state.filtersView, action.payload);
        },
        setState: (state, action: PayloadAction<Partial<LinkMgrState>>) => {

            const { links, selectedLink } = action.payload;

            /** Si les links on été set, on tente de sélectionner un link */
            if ((links && links != state.links) && !selectedLink) {
                Object.assign(state, action.payload);
                updateSelectedLink(state);
            }

            Object.assign(state, action.payload);
        },
        loadingGridRef: (state, action: PayloadAction<boolean>) => {
            state.loaders.gridRef = action.payload;
        },
        loadingGridView: (state, action: PayloadAction<boolean>) => {
            state.loaders.gridView = action.payload;
        },
        loadingLinks: (state, action: PayloadAction<boolean>) => {
            state.loaders.links = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { select, setReferentialFilters, setState, setViewFilters, loadingGridRef, loadingGridView, loadingLinks } = linkMgrSlice.actions

export const linkMgrReducer = linkMgrSlice.reducer;

function updateSelectedLink(state: LinkMgrState) {
    if (state.selectedDimension && state.selectedSource) {
        if (state.selectedDimension != ref_Property.name) {
            const selectedLink = getLinkFromName(state.links, state.selectedDimension, state.selectedSource);
            state.selectedLink = selectedLink;
        }
    }
}
