import * as React from 'react';
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Tooltip from '@material-ui/core/Tooltip'
import { Trad } from 'trad-lib';
import { getIcon } from 'adwone-lib';
import { CustomIconButton } from '../Generic/CustomIconButton';

export function CustomDropMessage({ editField, edit }, customCommandCellFunction) {
    return class extends React.Component<any, any> {
        render() {
            const { dataItem } = this.props;
            const inEdit = dataItem[editField];
            const isBilled = dataItem.State == "Facturé" || dataItem.State == "Billed";
            return inEdit ? <></> : <>
                <td className="k-grid-content-sticky k-command-cell add-border-left" style={{ right: '-1px' }} >
                    <CustomIconButton
                        className={dataItem.selected == true ? 'is-selected' : 'primary_color'}
                        onClick={() => edit(dataItem)}>
                        {getIcon('edit_inline')}
                    </CustomIconButton>
                    { isBilled &&
                        <Tooltip title={Trad("message_billed")}>

                            <IconButton aria-label="edit" disabled>
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    }

                    { !isBilled &&
                        <IconButton className="error_color" aria-label="edit" disabled={dataItem.dataItem.Invalide} onClick={() => {
                            customCommandCellFunction(dataItem.dataItem)
                        }}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    }
                </td>
                {(dataItem.State == "Facturé" || dataItem.State == "Billed") &&
                    <Tooltip title={Trad("message_billed")}>
                        <td className="k-grid-content-sticky k-command-cell add-border-left" style={{ right: '-1px' }} >

                            <IconButton aria-label="edit" disabled>
                                <RemoveCircleOutlineIcon />
                            </IconButton>

                        </td>
                    </Tooltip>
                }
                {(dataItem.State != "Facturé" || dataItem.State != "Billed") &&
                    <td className="k-grid-content-sticky k-command-cell add-border-left" style={{ right: '-1px' }} >
                        <IconButton className="error_color" aria-label="edit" disabled={dataItem.dataItem.Invalide} onClick={() => {
                            customCommandCellFunction(dataItem.dataItem)
                        }}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </td>
                }
            </>
        }
    }
}