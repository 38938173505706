import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItems from './ListItems.bin'
import Slide from '@material-ui/core/Slide'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import ProfilMenu from './ProfilMenu.bin'
import history from '../../../utils/history'
import Logo from '../../../utils/adwone.png'

const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    width: `calc(100% - 0px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  content: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    flexGrow: 1,
    padding: 0,
    height: '100vh',
    backgroundColor: '#fff',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
}))
function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}
const Aside = ({ children }, props) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }
  return (
    <div className="navigation_container" style={{ display: 'block' }}>
      <Drawer
        className="navigation_drawer"
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: "navigation_drawer_paper",
        }}
      >
        <div className="navigation_drawer_header">
          <div className="navigation_drawer_header_content">

            <IconButton onClick={handleDrawerClose} className="navigation_drawer_header_content_button">
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>

          </div>
        </div>

        <ListItems />
      </Drawer>


      <HideOnScroll {...props}>



        <AppBar
          position="fixed"
          className={`appBarShift ${clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}`}
          style={{ backgroundColor: '#FFF', color: 'black' }}>

          <Toolbar className="appBarShift">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="end"
              className={clsx(classes.menuButton, open && classes.hide)}>
              <MenuIcon />
            </IconButton>

            <IconButton onClick={handleDrawerClose} className={clsx(classes.menuButton, !open && classes.hide)}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>

            <div className='toolbarcontent'>
              <img alt="AdwOne" className='toolbarcontent' style={{ cursor: 'pointer' }} onClick={() => history.push(`/`)} src={Logo} />
              {/* <Typography variant="h6" noWrap style={{ cursor: 'pointer' }} onClick={() => history.push(`/`)}>
                AdwOne
              </Typography> */}
            </div>


            <ProfilMenu />
          </Toolbar>

        </AppBar>
      </HideOnScroll>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className="navigation_drawer_header" />
        {children}
      </main>
    </div>
  )
}
export default Aside
