import {IMPORT_DONE, IMPORT_IN_PROGRESS, IMPORT_REFRESHED} from './types'

export const refreshedImports = () => dispatch => {
    dispatch({
        type: IMPORT_REFRESHED
    })
}

export const refreshImports = (res, forceUpdate) => dispatch => {
    dispatch({
        type: IMPORT_IN_PROGRESS,
        payload: res,
        forceUpdate
    })
}
export const finishImports = (res) => dispatch => {
    dispatch({
        type: IMPORT_DONE,
        payload: res
    })
}