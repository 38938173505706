import React from "react";
// import { CustomPivotGridV2 } from "./CustomPivotGridV2";
import { PivotGridHandle } from "@progress/kendo-react-pivotgrid";
import { CustomPivotGridConfigurationPanel } from "./CustomPivotGridConfigurationPanel";
import { GetHashCode, clone, compareObjects, duplicate } from "hub-lib/tools.bin";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "hub-lib/client/client.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { CrossedTableTelerikTree } from "../../../crossedTable/CrossedTableTelerikTree.bin";
import { AggregateExport, SanitizeFilter } from "hub-lib/models/external.bin";
import { ACrossedTable } from "../../../crossedTable/CrossedTable.bin";
import { CrossedTableConfig } from "../../../crossedTable/CrossedTableConf.bin";
import { setCurrentTCD } from "../../../../redux/tcdSlice";
import Loader from "../../../layout/Loader";

let ctComponent: ACrossedTable<any, any, any> = null;

export const MessagesPivotGridV2 = () => {
    const dispatch = useDispatch();
    const filters = duplicate(useSelector((root: RootState) => root.project.filters, compareObjects));
    const tcdConfig = useSelector((root: RootState) => root.columsConfigurations.configurations?.[ref_Messages.name]?.pivotGrid);
    const [loading, setLoading] = React.useState(true);

    const generateConfigCrossedTable = async () => {
        const confCT = { ...new CrossedTableConfig(), ...filters };
        confCT.rowventils = tcdConfig.Rows;
        confCT.hideDetailsRows = false;

        confCT.selectedIndicateurs = tcdConfig.Kpis;
        confCT.colventils = tcdConfig.Columns;

        const copy = JSON.parse(JSON.stringify(confCT));
        if (copy.Start) copy.Start = new Date(copy.Start);
        if (copy.End) copy.End = new Date(copy.End);
        return copy;
    }

    React.useEffect(() => {
        if (filters?.Start && filters?.End) {
            const getPivotData = async () => {
                setLoading(true);
                const document = new AggregateExport();
                try {
                    document.document = ref_Messages.name;
                    document.dimensions = clone(tcdConfig.Rows);
                    document.filter = clone(filters ?? {});
                    document.columns = clone(tcdConfig.Kpis);
                    document.dimensionsColumns = clone(tcdConfig.Columns);
                    document.hideDetailsRows = true;
                    document.rowTotal = "top";
                    document.rowTotalActivated = true;
                    document.ViewModeExport = "CrossedTable";

                    if (!document.filter.properties) document.filter.properties = [];
                    if (!document.filter.properties.includes('Start')) document.filter.properties.push('Start');
                    if (!document.filter.properties.includes('End')) document.filter.properties.push('End');

                    document.Start = filters.Start;
                    document.End = filters.End;

                    const _body = clone(document);
                    await SanitizeFilter(_body.document, _body.filter);
                    const resp = await Client.aggregate<ref_Messages>(_body);

                    dispatch(setCurrentTCD(_body));

                    const { table } = resp.data;

                    const totalRow = table.Rows[0];
                    table.Rows = totalRow.Children;
                    totalRow.Children = undefined;

                    table.Rows.push(totalRow);

                    // const data = table.Rows.flatMap(r => r.Data);
                    const conf = await generateConfigCrossedTable();

                    await ctComponent.Load(table, conf, table.Columns.filter(c => !c.IsRow));
                } catch (error) {
                }
                finally {
                    setLoading(false);
                }
            }
            getPivotData();
        }
    }, [GetHashCode(filters), GetHashCode(tcdConfig)])

    return (
        <div className="grid_container" style={{ height: 'calc(100vh - 80px)' }}>
            <div className="grid_body">
                <CustomPivotGridConfigurationPanel
                    typeName={ref_Messages.name}
                />
                {loading && <Loader time />}
                <CrossedTableTelerikTree
                    customMessageHeight={`calc(100vh - 140px)`}
                    onRef={(ref) => ctComponent = ref}
                />
            </div>
        </div>
    );
}