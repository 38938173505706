import { Grid } from '@material-ui/core';
import { TPropsVertexAuto, VertexAutocomplete } from 'adwone-lib';
import { QuestionsForm, QuestionSelect, QuestionSelectOption } from 'hub-lib/dto/QuestionsForm';
import { GetHashCode, toArray } from 'hub-lib/tools.bin';
import { Trad } from "trad-lib";

export type GenericFormProps = {
    form: QuestionsForm,
    store: Object,
    order?: { [key: string]: string },
    disabled?: boolean,
    onAnswer?: (store: GenericFormProps['store'], key: string, value: any) => any
}
export function GenericForm({ form, store, order, disabled, onAnswer }: GenericFormProps) {

    if (!form || Object.entries(form).length === 0)
        return <></>;

    Object.entries(form).forEach(([questionKey, question]) => {
        if (question.default && !store.hasOwnProperty(questionKey))
            store[questionKey] = question.default;
    });

    return <Grid container className='block-container'>
        {Object.entries(form).map(([key, question]) =>
            <Grid key={key} item xs={order?.[key] ? 6 : 12} className={order?.[key] ?? "message_details_full"} >
                <Selector
                    disabled={disabled ?? false}
                    question={question as QuestionSelect}
                    current={store[key]}
                    onAnswer={(answers) => {
                        const arrayAnswers = toArray(answers);
                        const value = (question as QuestionSelect)['multiple'] ? arrayAnswers.map(e => e.id) : arrayAnswers?.[0]?.id;
                        store[key] = value;
                        onAnswer?.(store, key, value);
                    }} />
            </Grid>)}
    </Grid>
}

type SelectorProps = { question: QuestionSelect, current: string, disabled: boolean, onAnswer: (answers: QuestionSelectOption[]) => void };
function Selector({ question, current, disabled, onAnswer }: SelectorProps) {

    const propsMulti: Pick<TPropsVertexAuto<QuestionSelectOption>, 'defaultValue' | 'onChange' | 'multiple'> = {
        multiple: question.multiple,
        defaultValue: (options: QuestionSelect['options']) =>
            options.find(e => e.id === current || e.id === question.default),
        onChange: onAnswer
    }

    return <VertexAutocomplete
        disabled={disabled}
        nullOnClear
        key={`autocomplete-${question.id}-${GetHashCode(question.options?.map(e => e.id))}`}
        {...propsMulti as any}
        label={Trad(question.label)}
        options={question.options}
        getOptionLabel={(option: QuestionSelectOption) => option.label}
    />
}