

import * as React from "react";
import PropTypes from "prop-types";
import { Responsive, WidthProvider, Layouts, Layout } from "react-grid-layout";

import './exemple-style.css'
import './style.css'
import { SetLayout } from "../../utils/localstorage.bin";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export class TState {
  currentBreakpoint: "lg" | "md" | "sm" | "xs" | "xxs";
  compactType: "vertical" | "horizontal";
  mounted: boolean;
  layouts: any;
}

export class TProps {
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: { lg: number, md: number, sm: number, xs: number, xxs: number };
  initialLayout?: any;
  generateDOM: any;
  onChange?: () => any;
}

export default class GridLayout extends React.Component<TProps, TState> {

  static propTypes = {
    onLayoutChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: "layout",
    rowHeight: 30,
    onLayoutChange: function () {
      console.log(`default implementation`)
    },
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  };

  constructor(props: any) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      compactType: "horizontal",
      mounted: false,
      layouts: { lg: props.initialLayout }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  generateDOM() {
    return this.state.layouts.lg.map(function (l, i) {
      return (
        <div key={i} className={l.static ? "static" : ""}>
          {l.static ? (
            <span
              className="text"
              title="This item is static and cannot be removed or resized."
            >
              Static - {i}
            </span>
          ) : (
            <span className="text">{i}</span>
          )}
        </div>
      );
    });
  }

  onBreakpointChange(breakpoint: any) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onCompactTypeChange() {
    const { compactType: oldCompactType } = this.state;

    let compactType = null;
    if (oldCompactType === "horizontal") compactType = "vertical";
    else if (oldCompactType !== "vertical") compactType = "horizontal";

    this.setState({ compactType });
  }

  onLayoutChange(layout: any, layouts: any) {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.props.onLayoutChange?.(layout, layouts);
    }, 100);

  }

  render() {
    return (
      <div>
        <ResponsiveReactGridLayout
          {...this.props}
          draggableCancel={'.NonDraggableArea'}
          layouts={this.state.layouts}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={(currentLayout: Layout[], allLayouts: Layouts) => {
            SetLayout(currentLayout);
            this.onLayoutChange(currentLayout, allLayouts);
          }}
          onWidthChange={this.onLayoutChange}
          // WidthProvider option
          measureBeforeMount={false}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          useCSSTransforms={this.state.mounted}
          compactType={this.state.compactType}
          preventCollision={!this.state.compactType}
        >
          {this.props.generateDOM()}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}
