import { Checkbox } from '@progress/kendo-react-inputs';
import * as React from 'react'

export class SelectionComponent<T extends { isSelected: boolean, Children: T[] }> extends React.Component<{ onSelectionChange: () => any, dataItem: T, hideTd?: boolean }, any> {
    render() {

        let row: T = this.props.dataItem;

        let checkBox = <Checkbox
            style={{ textAlign: 'center' }}
            checked={row?.isSelected}
            onClick={() => {

                if (!row)
                    return;

                let recurseAllSelected: (e: T[]) => boolean = (e) => {
                    e = e?.filter(c => c);
                    if (e?.some(c => !c.isSelected))
                        return false;

                    let children = e?.map(c => c.Children)?.reduce((a, b) => a.concat(b), []);
                    if (!children?.length) return true;

                    return recurseAllSelected(children);
                }

                let selectRecurse: (e: T[], s: boolean) => void = (e, s) => {
                    e = e?.filter(c => c);
                    e?.forEach((c: any) => {
                        if (c) {
                            c.isSelected = s;
                            if (c.baseRow)
                                c.baseRow.isSelected = s;
                        }
                    });

                    let children = e?.map(c => c.Children).reduce((a, b) => a.concat(b), []);
                    if (children.length)
                        selectRecurse(children, s);
                }

                let allSelected = recurseAllSelected(row.Children);
                if (allSelected && !row.isSelected) selectRecurse([row], true);
                else if (allSelected) selectRecurse([row], false);
                else if (!allSelected) selectRecurse([row], true);

                this.props.onSelectionChange();
            }} />

        if (this.props.hideTd)
            return <>{checkBox}</>;

        return (
            <td className="k-grid-content-sticky selection-column-cell" style={{ left: "0px", textAlign: "center" }}>
                {checkBox}
            </td>);
    }
}