
import React from "react";
import { ADWColumn, IsDebugMode } from "adwone-lib/index";
import { GridBase } from "./GridBase.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { JSONViewer } from "../../ConfigurableComponents/JSONViewer";

export function AddDevModeColumns<TVertex>(columns: ADWColumn<TVertex>[]) {
    if (IsDebugMode()) {

        const detailsCol = new ADWColumn<TVertex>("Details", "Details" as any, ePropType.Any);
        detailsCol.cellContent = (cell, row) => <JSONViewer data={row?.dataItem ?? {}} />
        detailsCol.width = 50;
        columns.unshift(detailsCol);
        columns.unshift(new ADWColumn<TVertex>("Active", "Active" as any, ePropType.Boolean));
        let ridCol = new ADWColumn<TVertex>("@rid", "@rid" as any);
        ridCol.cellContent = (cell, row) => <span>{row?.dataItem?.["@rid"]}</span>
        ridCol.width = 200;
        columns.unshift(ridCol);
    }
}