import React from 'react'
import Template from '../Template'
import { addDays } from "date-fns";
import { ADWColumn, AdwRow } from 'adwone-lib';
import { propertyOf } from 'hub-lib/tools.bin';
import { GenericPopover } from '../../ConfigurableComponents/GenericPopover.bin';
import ReactJson from 'react-json-view';
import { ePropType } from 'hub-lib/models/VertexProperty.bin';
import { Trad, TradProp } from 'trad-lib';
import { VertexGrid } from '../../VertexGrid/Generic/VertexGrid.bin';
import { Format } from 'format-lib/index.bin';
import Loader from '../Loader';
import { ConfigurationPanel } from '../../VertexGrid/Messages/ConfigurationPanel';
import { BreadcrumbsCustomContainer, ConfigurationPanelContainer, ToolbarAdw } from '../../VertexGrid/Generic/ToolbarAdw';
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom';
import { AdwTelerikGrid } from '../../VertexGrid/Generic/AdwTelerikGrid.bin';
import { DateRangepicker } from '../../ConfigurableComponents/DateRangepicker';
import { GetToday } from 'tools-lib';
import { Element } from '../../VertexGrid/Generic/Common.bin';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Client } from 'hub-lib/client/client.bin';
import { Log } from 'hub-lib/dto/admin/Log.bin';
import { IRid } from 'hub-lib/models/IRid.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';

let anchorId = 0;
function MissingProperties() {

  const [grid, setGrid] = React.useState<VertexGrid<Log>>()

  const createColJson = (bindingPath: string) => {
    const openLog = new ADWColumn<Log>(TradProp(bindingPath), bindingPath, ePropType.String, false);
    openLog.width = 110;
    openLog.cellValue = (cellValue: any, dataItem?: AdwRow<Log>) => {

      const anchorKey = `anchor-${anchorId++}`;
      const changes = dataItem.dataItem?.[bindingPath];
      const date = dataItem.dataItem?.Date;
      dataItem[`${bindingPath}_cellValue`] = changes ? JSON.stringify(changes) : "";

      return <div id={anchorKey}>
        <GenericPopover anchorParent={anchorKey} icon="zoom">
          <div style={{ display: "flex" }}>
            <div>
              <div className="adw-title">
                <div style={{ fontSize: "12px" }}>{new Date(date).toLocaleString()}</div>
              </div>
              <ReactJson src={changes} displayDataTypes={false} sortKeys displayObjectSize={false} />
            </div>
          </div>
        </GenericPopover>
      </div>
    }
    return openLog;
  }

  React.useEffect(() => {
    if (grid)
      return;
    const columns: ADWColumn<Log>[] = [];
    const hiddenProperties: string[] = [];
    hiddenProperties.push(propertyOf<Log>("checked"));
    hiddenProperties.push(propertyOf<Log>("Report"));
    hiddenProperties.push(propertyOf<Log>("CallID"));
    columns.push(createColJson("Report"));

    // nouvelle colonne
    const previewColumn = new ADWColumn<Log>(TradProp("preview"), "preview", ePropType.String, false);
    previewColumn.width = 500;
    previewColumn.cellValue = (cellValue: any, row?: AdwRow<Log>) => {
      return <DetailsElement
        element={row.dataItem.Report?.Error?.SerializedError?.context}
        fields={row.dataItem.Report?.Error?.SerializedError?.properties ?? []} />
    };

    columns.push(previewColumn);

    const checkedColumn = new ADWColumn<Log>(TradProp("Checked"), "Checked", ePropType.Boolean, false);
    checkedColumn.width = 75;
    checkedColumn.cellValue = (cellValue: any, row?: AdwRow<Log>) => {
      return <CheckBoxCell row={row} />
    }
    columns.push(checkedColumn);


    // hiddenProperties.push(propertyOf<Log>("source"));
    // hiddenProperties.push(propertyOf<Log>("date"));

    const saved = localStorage.getItem("MissingProperties-filters");
    const conf = new TState().config;
    if (saved) {
      const savedConf = JSON.parse(saved);

      if (savedConf.Start)
        conf.Start = new Date(savedConf.Start);
      if (savedConf.End)
        conf.End = new Date(savedConf.End);
    }

    const newgrid = new VertexGrid<Log>({
      objectPrototype: Log,
      devMode: false,
      columns,
      width: {
        Layer: 100,
        checked: 70
      },
      // order: ["@rid", "Layer", categoryPath, actionPath, "User", "Date", "CallID"],
      vertexParams: {
        ...conf,
        'Report.Error.Message': 'not_found_properties'
      },
      hiddenProperties,
    });
    setGrid(newgrid);
  });

  const removeAll = (config: any) => {
    if (!config) return {};
    let newParams: any = { ...config }
    for (let [key, value] of Object.entries(config)) {
      if (value === Trad("all"))
        delete newParams[key]

      if (value && Array.isArray(value) && !value.length)
        delete newParams[key]
    }
    return newParams
  }

  const onConfChange = (config: any) => {
    let vertexParams = grid.props.vertexParams
    grid.props.vertexParams = removeAll({ ...vertexParams, ...config }) ?? {};
    grid.UpdateRows();
  }



  if (!grid)
    return <Loader />;
  const confComponent = <ConfigurationPanel
    elements={[{
      type: "icon",
      title: () => Trad("filters"),
      icon: "filterAlt",
      element: <FiltersComponent onConfChange={onConfChange} />
    }]} />

  return (
    <div className="grid_container">
      <div style={{ width: '100%' }}>
        <ToolbarAdw>
          <ConfigurationPanelContainer>
            {confComponent}
          </ConfigurationPanelContainer>
          <BreadcrumbsCustomContainer>
            <BreadcrumbsCustom
              elements={[
                { text: Trad("home"), href: "/" },
                { text: Trad("logs_mgt") }
              ]} />
          </BreadcrumbsCustomContainer>
        </ToolbarAdw>
        <AdwTelerikGrid
          grid={grid}
          sort={[{ field: "date", dir: "desc" }]}
          uneditable
          hideToolbar
        />
      </div>
    </div>
  )

}

export default Template(MissingProperties);

class TState {
  config: {
    Start: Date,
    End: Date,
  }

  /**
   *
   */
  constructor() {
    this.config = {
      Start: GetToday(),
      End: addDays(GetToday(), 1),
    }
  }
}

class TProps {
  onConfChange: (conf: TState["config"]) => void;
}

export class FiltersComponent extends React.Component<TProps, TState> {

  constructor(props: TProps) {
    super(props);
    let newState = new TState();
    if (localStorage.getItem("MissingProperties-filters")) {
      newState.config = JSON.parse(localStorage.getItem("MissingProperties-filters")!);
      newState.config.Start = new Date(newState.config.Start);
      newState.config.End = new Date(newState.config.End);
    }

    if (!newState.config.Start)
      newState.config.Start = new Date(newState.config.Start)
    if (!newState.config.End)
      newState.config.End = addDays(new Date(newState.config.Start), 1)
    this.state = newState;
  }

  render() {
    let { config } = this.state;
    return (
      <>
        <Element className="adw-row">
          <DateRangepicker
            defaultStart={config.Start}
            defaultStop={config.End}
            handleChangeDate={(v) => {
              config.Start = v.start
              config.End = v.end
              localStorage.setItem("MissingProperties-filters", JSON.stringify(config));
              this.forceUpdate();
              this.props.onConfChange(this.state.config);
            }} />

        </Element>
      </>
    )
  }
}

const cacheUniq = new Map<string, string>();

type DetailsElementProps = { element: IRid, fields: string[] }
function DetailsElement({ element, fields }: DetailsElementProps) {

  const [label, setLabel] = React.useState<string>("");
  React.useEffect(() => {
    if (!label)
      Promise.resolve().then(async () => {
        const meta = (await Client.getMetadata(ref_Messages.name))?.data?.results;

        const uniq = fields.map((f, i) => `${f}: (${element[f]})`).join(",");
        if (cacheUniq[uniq])
          return setLabel(cacheUniq[uniq]);

        const allresults = await Promise.all(fields.map(async f => {
          const prop = meta?.find(p => p.name == f);
          const [resElement] = (await Client.searchVertex(prop.linkedClass, { "@rid": element[f] }))?.data?.results ?? [];
          return resElement;
        }));

        const newLabel = fields.map((f, i) => `${TradProp(f, ref_Messages)}: (${allresults[i]['@rid']}) ${Format(allresults[i])}`).join(" ");
        cacheUniq[uniq] = newLabel;
        setLabel(newLabel);
      });
  }, [element?.['@rid']])

  return <span>{label}</span>
}

type CheckboxCellProps = { row: AdwRow<Log> }
const CheckBoxCell = ({ row }: CheckboxCellProps) => {

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  return <Checkbox
    checked={row.dataItem?.checked}
    onChange={e => {
      row.dataItem.checked = e.value;
      Client.updateVertex(Log.name, { '@rid': row.dataItem['@rid'], checked: e.value })
        .then(() => forceUpdate());
    }} />
}