import { IRid } from "../../../models/IRid.bin";

export enum NielsenMediaDeliveryFrequence {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY"
}

export class ref_NielsenClientSubscription implements IRid {
    "@rid"?: string;
    client: string = "";
    dataStartDate: number = 0;
    folderToPutFiles: string = "";
    majorCategoriesToInclude: string[] = [];
    midCategoriesToInclude: string[] = [];
    minorCategoriesToInclude: string[] = [];
    advertisersToInclude: string[] = [];
    brandsToInclude: string[] = [];
    brandProductsToInclude: string[] = [];
    productsToInclude: string[] = [];
    mediaTypesToInclude: string[] = [];
    mediaChannelsToInclude: string[] = [];
    spotaspot: boolean = true;
    deliveryFrequencyByMedia: {
        media: string;
        deliveryFrequency: NielsenMediaDeliveryFrequence;
        deliveryDay: number;
    }[] = [
        {
            "media": "Cinema",
            "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
            "deliveryDay": 1
        },
        {
            "media": "Digital",
            "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
            "deliveryDay": 1
        },
        {
            "media": "Outdoor",
            "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
            "deliveryDay": 1
        },
        {
            "media": "Press",
            "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
            "deliveryDay": 1
        },
        {
            "media": "Radio",
            "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
            "deliveryDay": 1
        },
        {
            "media": "Social",
            "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
            "deliveryDay": 1
        },
        {
            "media": "Video",
            "deliveryFrequency": NielsenMediaDeliveryFrequence.MONTHLY,
            "deliveryDay": 1
        }
    ];
}