import { ref_BroadcastAreasId } from "../../models/orientdb/ref_BroadcastAreas.bin";
import { ref_CurrenciesId } from "../../models/orientdb/ref_Currencies.bin";
import { ref_MediaId } from "../../models/orientdb/ref_Media.bin";
import { ref_ModelId } from "../../models/orientdb/ref_Model.bin";
import { ref_SupportsId } from "../../models/orientdb/ref_Supports.bin";
import { UserId } from "../../models/orientdb/User.bin";
import { IMediaModel, INamed, IUpsertInfo, kpis } from "../../types";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { IRid } from "../../models/IRid.bin";


// export class ref_ExternalData implements IRid, INamed, IUpsertInfo, IMediaModel {
//     "@rid"?: rid;
//     Active: boolean;
//     Key: string;
//     Support: ref_SupportsId;
//     BroadcastArea: ref_BroadcastAreasId;
//     Media: ref_MediaId;
//     Model: ref_ModelId;
//     Currency: ref_CurrenciesId;
//     Created_by?: UserId;
//     Created_at?: Date;
//     Updated_by?: UserId;
//     Updated_at?: Date;
//     Name: string;
//     Start: Date;
//     End: Date;
//     ModelProperties: { [key: string]: string | number };
//     KPIs: kpis = new kpis();
//     DataImported: { [key: string]: any }[];
//     Source?: string;
// }

export class ref_ExternalData implements IRid, IUpsertInfo, IMediaModel {
    "@rid"?: rid;
    Active: boolean;
    Key: string;
    Support: ref_SupportsId;
    BroadcastArea: ref_BroadcastAreasId;
    Media?: ref_MediaId;
    Model?: ref_ModelId;
    Currency?: ref_CurrenciesId;
    Created_by?: UserId;
    Created_at?: Date;
    Updated_by?: UserId;
    Updated_at?: Date;
    Start: Date;
    End: Date;
    ModelProperties?: { [key: string]: string | number };
    KPIs?: kpis = new kpis();
    DataImported: { [key: string]: any }[];
    Source?: string;
}