import { Grid, InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import { Client } from 'hub-lib/client/client.bin'
import { User } from 'hub-lib/models/orientdb/User.bin'
import { UserExtended } from 'hub-lib/dto/referential/UserExtended.bin'
import { eRoles } from 'hub-lib/business/rights/rights.bin'
import * as React from 'react'
import { Trad, TradClassName, TradProp } from 'trad-lib'
import { Notify } from '../../../utils/Notify.bin'
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'
import { ErrorMessage } from '../../ConfigurableComponents/ErrorMessage.bin'
import { VertexAutocomplete, getIcon } from "adwone-lib/index"
import Loader from '../Loader'
import Template from '../Template'
import { Row } from '../../Tools'
import { BreadcrumbsCustom } from '../../BreadcrumbsCustom'
import { useState, useEffect } from 'react'
import { duplicate, isPasswordStrong } from 'hub-lib/tools.bin'
import { FormatDate } from 'tools-lib'
import { AvailableAdvertisersGrid } from './AvailableAdvertisersGrid'
import { AvailableOptionsGrid } from './AvailableOptionsGrid'
import { ref_Departments } from 'hub-lib/models/orientdb/ref_Departments.bin'
import { IRid } from 'hub-lib/models/IRid.bin'
import { CustomIconButton } from '../../VertexGrid/Generic/CustomIconButton'
import { BreadcrumbsCustomContainer, ToolbarAdw } from '../../VertexGrid/Generic/ToolbarAdw'
import { PasswordComplexity } from '../../auth/PasswordComplexity'

function Profile() {

    const [user, setUser] = useState<UserExtended>(undefined);
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [activeChangePass, setActiveChangePass] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [old_password, setOld_password] = useState("");
    const [new_password, setNew_password] = useState("");
    const [new_password_confirmation, setNew_password_confirmation] = useState("");
    const [error_msg, setError_msg] = useState("");

    const updateUser = async () => {
        setLoading(true);
        const u = (await Client.getUser()).user;
        setUser(u);
        setLoading(false);
    }

    useEffect(() => {
        setShowError(false)
        setActiveChangePass(true);

        if (new_password !== new_password_confirmation) {
            setShowError(true)
            setError_msg(Trad("different_passwords"))
        }
        // if (new_password && !isPasswordStrong(new_password)) {
        //     setShowError(true)
        //     setError_msg(Trad("password_weak"))
        // }
        if (new_password === new_password_confirmation && isPasswordStrong(new_password))
            setActiveChangePass(false);

        if (!user)
            updateUser();
    })

    if (loading)
        return <Loader />;

    const updateUserInfos = async () => {
        await Client.updateVertex(UserExtended.name, user);
        window.location.reload();
    }

    const updateUserPassword = async () => {
        try {
            await Client.updateVertex(User.name, { "@rid": user["@rid"], password: new_password_confirmation, old_password: old_password }, false)
            Notify(Trad("pass_changed_success"), "success");
            window.location.reload();
        } catch (error: any) {
            Client.faileUpdate(User.name, error.response.data);
        }
    }

    const forceUpdate = () =>
        setUser(duplicate(user));

    return <div className="grid_container">
        <div style={{ width: '100%' }}>
            <ToolbarAdw>
                <BreadcrumbsCustomContainer>
                    <BreadcrumbsCustom elements={[
                        { text: Trad("home"), href: "/" },
                        { text: Trad("edit_profile") }
                    ]} />
                </BreadcrumbsCustomContainer>
            </ToolbarAdw>
            <div style={{ width: '100%', display: 'flex' }}>

                {/** Block informations */}
                <div className='message_details_leftcombo' style={{ width: '50%' }}>
                    <Grid className="adw-title adw-form-title">
                        <div>{getIcon("person")}</div>
                        {Trad("profile_infos")}
                    </Grid>
                    <form noValidate>
                        <Row>
                            <Grid container className='block-container'>
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <TextInput id="user_firstname"
                                        label={`${Trad("firstname")} *`}
                                        value={user.person.FirstName}
                                        onChange={(e) => { user.person.FirstName = e.target.value; forceUpdate() }} />
                                </Grid>
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <TextInput id="user_lastname"
                                        label={`${Trad("lastname")} *`}
                                        value={user.person.LastName}
                                        onChange={(e) => { user.person.LastName = e.target.value; forceUpdate() }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="message_details_leftcombo">
                                    <TextInput id="user_mail"
                                        type="email"
                                        disabled={user.profileName != "administrateur" && user.profileName != eRoles.superAdministrateur}
                                        label={`${TradProp("mail")} *`}
                                        value={user.mail}
                                        onChange={(e) => { user.mail = e.target.value; forceUpdate() }} />
                                </Grid>
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <TextInput id="user_job"
                                        label={Trad("job")}
                                        value={user.job}
                                        onChange={(e) => { user.job = e.target.value; forceUpdate() }} />
                                </Grid>
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <TextInput id="user_phone"
                                        label={Trad("phone")}
                                        value={user.phone}
                                        onChange={(e) => { user.phone = e.target.value; forceUpdate() }} />
                                </Grid>
                                <Grid item xs={12} className="message_details_leftcombo">
                                    <TextInput id="user_company"
                                        label={TradProp("company", UserExtended)}
                                        value={user.company?.Name ?? ""}
                                        disabled={true} />
                                </Grid>
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <TextInput id="user_role"
                                        label={TradProp("profile", UserExtended)}
                                        value={Trad(user.profileName)}
                                        disabled={true} />
                                </Grid>
                                <Grid item xs={6} className="message_details_leftcombo">
                                    <TextInput id="user_registration"
                                        label={TradProp("registrationDate", UserExtended)}
                                        value={user.registrationDate ? FormatDate(user.registrationDate) : " "}
                                        disabled={true} />
                                </Grid>
                                {user.customer &&
                                    <Grid item xs={12} className="message_details_leftcombo">
                                        <VertexAutocomplete
                                            type={ref_Departments.name}
                                            label={TradClassName(ref_Departments.name)}
                                            params={{ Company: user?.customer?.Company }}
                                            defaultValue={((departments: IRid[]) => departments?.find((v) => v["@rid"] === user?.Department))}
                                            disabled={true}
                                        />
                                    </Grid>
                                }
                                <div style={{ width: '99%', float: 'right', textAlign: 'right' }}>
                                    <CustomButton
                                        Label={Trad("save")}
                                        className={"custom_btn_primary_validation"}
                                        onClick={() => updateUserInfos()}
                                    />
                                </div>
                            </Grid>
                        </Row>
                    </form>

                </div>

                {/** Block mot de passe */}
                <div className='message_details_rightcombo' style={{ width: '50%' }}>
                    <Grid className="adw-title adw-form-title">
                        <div>{getIcon("lock")}</div>
                        {Trad("change_password")}
                    </Grid>
                    <form noValidate>
                        <div className='adw-row' style={{ width: '100%' }}>
                            <TextField
                                id="user_oldpass"
                                type={showOldPassword ? "text" : "password"}
                                label={Trad("old_pass")}
                                value={old_password}
                                name="password"
                                fullWidth
                                InputProps={{
                                    style: { fontSize: 14 },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CustomIconButton onClick={() => setShowOldPassword(!showOldPassword)} >
                                                {getIcon(showOldPassword ? "visibility" : "visibility_off")}
                                            </CustomIconButton>
                                        </InputAdornment>
                                    )
                                }}
                                variant='outlined'
                                onChange={(e) => setOld_password(e.target.value)}
                            />
                        </div>
                        <div className='adw-row' style={{ width: '100%' }}>
                            <TextField id="user_newpass"
                                type={showPassword ? "text" : "password"}
                                label={Trad("new_pass")}
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    style: { fontSize: 14 },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CustomIconButton onClick={() => setShowPassword(!showPassword)} >
                                                {getIcon(showPassword ? "visibility" : "visibility_off")}
                                            </CustomIconButton>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    setNew_password(e.target.value);
                                }}
                            />
                        </div>
                        <div className='adw-row' style={{ width: '100%' }}>
                            <TextField id="user_newpassconfirm"
                                type={showConfirmPassword ? "text" : "password"}
                                label={Trad("new_pass_confirm")}
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    style: { fontSize: 14 },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CustomIconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} >
                                                {getIcon(showConfirmPassword ? "visibility" : "visibility_off")}
                                            </CustomIconButton>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    setNew_password_confirmation(e.target.value);
                                }} />
                            {showError && <ErrorMessage message={error_msg} margin={'20px 0'} padding={'10px'} />}
                        </div>
                        {new_password && new_password != '' && <div className='adw-row' style={{ width: '100%' }}>
                            <PasswordComplexity password={new_password} />
                        </div>}
                    </form>
                    <div style={{ width: '50%', float: 'right', textAlign: 'right' }}>
                        <CustomButton
                            Label={Trad("btn_change_password")}
                            className={"custom_btn_primary"}
                            disabled={activeChangePass}
                            onClick={() => updateUserPassword()}
                        />
                    </div>
                </div>
            </div>


            <div className='adw-row'>
                <AvailableOptionsGrid />
            </div>

            <div className='adw-row'>
                <AvailableAdvertisersGrid />
            </div>
        </div>
    </div>
}

function TextInput(p: TextFieldProps) {
    return <TextField
        autoComplete='off'
        style={{ width: '100%' }}
        variant="outlined"
        {...p} />

}

export default Template(Profile)