import { ref_Currencies, ref_CurrenciesId } from "./ref_Currencies.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { ref_BroadcastAreas, ref_BroadcastAreasId } from "./ref_BroadcastAreas.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";

export type lnk_HasBroadcastAreaId = rid;

export class lnk_HasBroadcastArea extends E<ReferentialsId, ref_BroadcastAreasId> {
	"@rid"?: lnk_HasBroadcastAreaId;
	Active: boolean;
	Default: boolean;
	DefaultCurrency: ref_CurrenciesId;
	End: Date;
	Start: Date;

}