import { GenericPopover } from "../../ConfigurableComponents/GenericPopover.bin";
import {
    Upload,
    UploadFileInfo,
    UploadFileStatus,
} from "@progress/kendo-react-upload";
import React from "react";
import { Client, URLServerProvider } from "hub-lib/client/client.bin";
import { fileRoute } from "hub-lib/index.bin";
import { VertexAutocomplete } from "adwone-lib";
import { ref_Attachments, ref_Files } from "hub-lib/dto/client/ref_Attachments.bin";
import { Trad } from "trad-lib";
import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import ReactDOM from "react-dom";
import { Notify } from "../../../utils/Notify.bin";

type UploadFileInfoCustom = {
    attachment?: ref_Attachments;
    "@rid"?: string;
} & UploadFileInfo;

type TProps = {
    title?: string;
    attachments: ref_Files[];
    type?: string;
    onChange: (attachment: ref_Files) => void;
    onRemove: (attachmentId: ref_Files["@rid"]) => void;
    popover?: boolean;
    autoComplete?: boolean;
    rid: string;
    extensions?: string[];
    uploadDisabled?: boolean;
};

export function FilesPicker({ title, attachments, type, onChange, onRemove, popover, autoComplete, rid, extensions, uploadDisabled }: TProps) {
    const [files, setFiles] = React.useState<UploadFileInfoCustom[]>(null);
    const [toAdd, setToAdd] = React.useState([]);

    const [divRef, setDivRef] = React.useState<HTMLDivElement>();

    const transform = (a: ref_Files): UploadFileInfoCustom => ({
        name: a.Name,
        progress: 100,
        size: a.Size,
        extension: `.${/(?:\.([^.]+))?$/.exec(a.Name)[1]}`,
        status: UploadFileStatus.Initial,
        uid: a.Content,
        attachment: a,
        "@rid": a.Content,
    });

    React.useEffect(() => {
        if (!files && attachments && attachments.length)
            setFiles(attachments.map(transform));
    });

    React.useEffect(() => {
        if (!divRef) return;

        const dropZone = divRef.getElementsByClassName("k-upload-dropzone")[0];

        if (dropZone && files?.length > 0)
            dropZone["style"]["display"] = "none";
        else
            dropZone["style"]["display"] = "flex";
        const allWithClass = Array.from(
            (divRef?.getElementsByClassName('k-file') || [])
        );
        for (const el of allWithClass) {
            if (!el.getElementsByClassName("adw-download-button").length) {
                const uid = el.getAttribute("data-uid");
                const button = el.getElementsByClassName('k-upload-actions')[0];
                const span = document.createElement('span');

                ReactDOM.render(
                    <button className="adw-download-button k-button k-button-icon k-flat k-upload-action" onClick={async (e) => await downloadFile(uid)}>
                        <span className="k-icon k-i-download"></span>
                    </button>
                    , span)

                if (button)
                    button.prepend(span);
            }
        }
    }, [files]);

    const onRemoveRequest = (
        files: UploadFileInfo[],
        options: { formData: FormData; requestOptions: any }
    ): Promise<{ uid: string }> => {
        const uid = files[0].uid;
        return Promise.resolve({ uid });
    };

    const renderPicker = () => {
        return (
            <>
                {title ? <div className="adw-title">{title}</div> : null}
                {autoComplete && <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 12, width: "-webkit-fill-available" }}>
                        <VertexAutocomplete
                            key={`key-${files ? files.map(e => e["@rid"]).join() : ""}`}
                            label={Trad("add_existing_files")}
                            type={ref_Attachments.name}
                            params={{ Active: true, AttachementType: "file", properties: ["*"] }}
                            onChange={(file: ref_Files) => {
                                setToAdd([file]);
                            }}
                            afterLoadFilter={(els: ref_Files[]) => {
                                if (!files)
                                    return els;
                                const set = new Set(files.filter(e => e.attachment).map(e => e?.attachment["@rid"]));
                                return els.filter((el) => !set.has(el["@rid"]));
                            }}
                        />
                    </div>
                    <div style={{ minWidth: "fit-content" }}>
                        <CustomButton
                            startIcon={getIcon("plus")}
                            Label={Trad(`add`)}
                            className="custom_btn_primary"
                            onClick={() => {
                                const filesToAdd = toAdd.map(transform);
                                for (const a of toAdd) {
                                    onChange(a);
                                }
                                setFiles(filesToAdd);
                            }}
                        />
                    </div>
                </div>}
                <Upload
                    disabled={Boolean(uploadDisabled)}
                    batch={false}
                    onAdd={(event) => {
                        setFiles(event.newState);
                    }}
                    onRemove={async (event) => {
                        const [file] = event.affectedFiles;
                        onRemove(file["@rid"]);
                        setFiles(event.newState);
                    }}
                    onProgress={(event) => {
                        setFiles(event.newState);
                    }}
                    onStatusChange={async (event) => {
                        const [file] = event.affectedFiles;
                        if (event?.response?.response?.status === 400) {
                            const dataError = event?.response?.response?.data;
                            if (dataError?.type == 'error_message') Notify(dataError.message, 'error');
                            else Notify(Trad('cannot_upload_file'), 'error');
                            //remove the file from the list
                            setFiles(event.newState.filter(e => e.uid !== file.uid));
                            return;
                        }

                        const el = event.newState.find((e) => e.uid === file.uid);
                        if (el && event.response) {
                            const resp = event.response.response;
                            el["@rid"] = resp.id;
                            el["uid"] = resp.id;
                            el["attachment"] = resp.attachment;
                            onChange(resp.attachment);
                        }
                        setFiles(event.newState);
                    }}
                    onBeforeUpload={(event) => {
                        event.additionalData = { type };
                    }}
                    onBeforeRemove={(event) => {
                        const [file] = event.files;
                        event.additionalData = { _id: file["@rid"], attachment: file["attachment"]["@rid"], targetRid: rid };
                    }}
                    restrictions={{
                        maxFileSize: 10000000, // 10MO
                        allowedExtensions: extensions,
                    }}
                    saveField={"attachment"}
                    withCredentials
                    saveUrl={URLServerProvider.provide(fileRoute) + fileRoute + "/attachment"}
                    removeUrl={onRemoveRequest}
                    multiple={false}
                    files={files}
                />
            </>
        );
    };

    const downloadFile = async (id: string) => {
        const file = files.find(e => e["@rid"] === id);
        await Client.dowloadAttachmentFile({ _id: id, filename: file.name });
    }

    return <div className="adw-upload" onClick={(e) => e.stopPropagation()}
        style={{ width: "100%", padding: 0 }}
        ref={ref => {
            if (!ref)
                return;

            const uploadButton = ref.getElementsByClassName("k-upload-button")[0];

            if (uploadButton?.children.length >= 2) {
                uploadButton.removeChild(uploadButton?.children[1]);
                const span = document.createElement('span');
                span.className = "material-icons MuiIcon-root";
                span.innerText = "attach_file";
                uploadButton.appendChild(span);
            }

            setDivRef(ref)
        }}>
        {
            popover ? (
                <GenericPopover count={files ? files.length : 0} icon="attachFile" style={{ width: 600 }} >
                    {renderPicker()}
                </GenericPopover >
            ) : (
                renderPicker()
            )}
    </div >

}
