import { OrientInstance, OrientInstanceId } from "./OrientInstance.bin";
import { rid } from "./CommonTypes.bin";

export type EId = rid;

export class E<TIn, TOut> extends OrientInstance {
	"@rid"?: EId;
	in: TIn;
	out: TOut;

}