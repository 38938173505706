import { IRid } from "hub-lib/models/IRid.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import React, { useEffect } from "react";
import { Trad } from "trad-lib";
import { CustomButton } from "../ConfigurableComponents/CustomButton.bin";
import { DicoCreateTrad, eDialogMode, GenericDialog } from "../ConfigurableComponents/GenericDialog.bin";
import { CampaignForm, GetStoreCampaignWithComments } from "../VertexGrid/Campaigns/CampaignForm";
import { EstimateMode } from "../VertexGrid/Campaigns/EstimateMode";
import { MultiAdvertisersMode } from "../VertexGrid/Campaigns/MultiAdvertisersMode";
import { IsObjectValid } from "validation-lib/index.bin";
import { Notify, NotifyError } from "../../utils/Notify.bin";
import { Client } from "hub-lib/client/client.bin";
import { IAdvertiserHierarchy } from "hub-lib/types";
import { SetMode } from "../../redux/store";

type NewCampaignButtonProps = { store: IAdvertiserHierarchy & { Campaign?: IRid["@rid"] }, onChange: (campaign: ref_Campaigns) => void }
export function NewCampaignButton({ store, onChange }: NewCampaignButtonProps) {

    const [open, setOpen] = React.useState(false);
    const [multiAdvertisers, setMultiAdvertisers] = React.useState(false);
    const [estimateMode, setEstimateMode] = React.useState(false);
    const [validationMode, setValidationMode] = React.useState<boolean>(false);

    return <div>
        <CustomButton
            className="custom_btn_secondary_white"
            style={{ marginLeft: "auto", width: 220 }}
            Label={Trad("create_campaign")}
            onClick={() => {
                SetMode(ref_Campaigns.name, eDialogMode.create);
                setOpen(true);
            }} />

        <GenericDialog
            dialogTitle=""
            titleClass='display-none'
            id="messages_dialog_createupdate"
            dialogClassname="messages"
            submitClass={'custom_btn_primary_validation'}
            open={open}
            actions
            submitTitle={DicoCreateTrad()[eDialogMode.create]}
            submitAction={async () => {

                const data = GetStoreCampaignWithComments();
                if (IsObjectValid<ref_Campaigns>(ref_Campaigns, data).length) {
                    Notify("Certains champs ne sont pas valides.", "warning");
                    setValidationMode(true);
                    return;
                }
                try {
                    const campaign = (await Client.createVertex(ref_Campaigns.name, data, false))?.data?.results;
                    Notify(`${Trad('campaign')} : ${campaign.Name} ${Trad('creation_succes_feminine')}`, 'success');
                    store.Campaign = campaign["@rid"];
                    store.AdvertiserGroup = campaign.AdvertiserGroup;
                    store.Advertiser = campaign.Advertiser;
                    store.Brand = campaign.Brand;
                    store.Product = campaign.Product;
                    setOpen(false);
                    onChange?.(campaign);
                }
                catch (error: any) {
                    NotifyError(error?.response?.data)
                }
            }}
            handleClose={() => {
                SetMode(ref_Campaigns.name, undefined);
                setOpen(false)
            }}
        // dialogTitle={<div>{DicoTitleCampaignTrad()[eDialogMode.create]}</div>}
        >

            <div className="multiAdvertisersMode-component" style={{ zIndex: 9000, padding: 8, top: 0 }}>
                <MultiAdvertisersMode
                    multiAdvertisers={multiAdvertisers}
                    onChange={value => setMultiAdvertisers(value)} />
                <EstimateMode
                    estimateMode={estimateMode}
                    onChange={value => setEstimateMode(value)} />
            </div>

            <div style={{ zIndex: 8000, position: "relative", height: "100%" }}>
                <CampaignForm
                    estimatedMode={estimateMode}
                    multiAdvertisers={multiAdvertisers}
                    validationMode={validationMode}
                    selectedMedia={null} />
            </div>
        </GenericDialog>
    </div>
}