import * as React from 'react'
import { clone, compareObjects } from 'hub-lib/tools.bin';
import { store, getSchedulerSelector } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { setSchedulerTemplate } from '../../../../redux/gridSlice';
import { SchedulerProps } from './SchedulerConfigDialog';

export function SchedulerChangeTrigger<T>({ objectType }: SchedulerProps<T>) {

    const selector = getSchedulerSelector(objectType.name);
    const template = clone(useSelector(selector, compareObjects));
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        if (isMounted.current) {
            console.log("[SchedulerChangeTrigger]", template);
            store.dispatch(setSchedulerTemplate(template))
        }
        isMounted.current = true;
    })

    return <></>
}