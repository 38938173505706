import * as React from "react";
import {
  Chat,
  ChatMessageSendEvent,
  Message,
} from "@progress/kendo-react-conversational-ui";
// import '@progress/kendo-theme-default/dist/all.css';
import { ChatUIProps } from "./ChatUI.types";
import axios from "axios";
import { BubbleDialog } from "../../../../components/BubbleDialog";
import { Client } from 'hub-lib/client/client.bin';
import { v4 } from 'uuid';

const user = {
  id: 1,
  avatarAltText: "KendoReact Conversational UI RICSU",
};

const bot = { id: 0 };

const conversationId = v4();
const initialMessages: Message[] = [
  {
    author: bot,
    timestamp: new Date(),
    text: "Bonjour! Mon nom est AVA, votre assistant personnel. Comment puis-je vous aider?"
  }
];

export const AVABubbleDialog = (props: { target: string, url: string }) => {
  const [isAvaActivated, setIsAvaActivated] = React.useState(undefined);
  React.useEffect(() => {
    if (isAvaActivated !== undefined) return;
    const checkAva = async () => {
      let res = await Client.Post("/doc/isAvaActivated", {});
      setIsAvaActivated(res.data);
    };
    checkAva();
  }, [isAvaActivated]);

  if (!isAvaActivated)
    return <></>

  return <BubbleDialog>
    <ChatUI target={props.target} url={props.url} />
  </BubbleDialog>
}

export const ChatUI: React.FC<ChatUIProps> = (props) => {
  const [messages, setMessages] = React.useState(initialMessages);

  const addNewMessage = async (event: ChatMessageSendEvent) => {
    let botResponse = Object.assign({}, event.message);
    botResponse.text = await answerQuestion(event.message.text || "");

    botResponse.author = bot;
    setMessages([...messages, event.message]);
    setTimeout(() => {
      setMessages((oldMessages) => [...oldMessages, botResponse]);
    }, 1000);
  };

  const answerQuestion = async (question: string) => {

    let res = await Client.Post(`/doc/${props.target}/ask`, { question: question, conversationId: conversationId })
      .catch((err) => { console.error(err); return { data: { answer: null } }; })

    // let res = await axios({
    //   method: 'post',
    //   url: props.url + `/doc/${props.target}/ask`,
    //   data: {
    //     question: question,
    //   }
    // }).catch((err) => { console.error(err); return { data: { answer: null } }; });

    return res.data['answer'] || "I don't know the answer to that question.";
  }

  return (
    <div>
      <Chat
        user={user}
        messages={messages}
        onMessageSend={addNewMessage}
        placeholder={"Rédigez un message..."}
        width={400}
      />
    </div>
  );
};

export default ChatUI;