import { ref_Currencies, ref_CurrenciesId } from "./ref_Currencies.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Currencies, mm_CurrenciesId } from "./mm_Currencies.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasCurrencyId = rid;

export class vw_mm_HasCurrency extends ReferentialHasViews<src_MMId, mm_CurrenciesId, ref_CurrenciesId> {
	"@rid"?: vw_mm_HasCurrencyId;

}