

export enum eFileType {
    ExcelTemplate =  "ExcelTemplate"
}
export class FileDescriptor {
    chunkSize: number;
    contentType: string;
    filename: string;
    length: number;
    md5: string;
    metadata: {
        docType: eFileType,
        company: string;
    };
    uploadDate: string;
    _id: string;
}