import React, { ComponentPropsWithRef } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    borderShort: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        width: "10px",
    },
    borderLong: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        width: "-webkit-fill-available",
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        // fontWeight: 400,
        // fontSize: 11.9,
        // lineHeight: 1,
        // letterSpacing: "0.00938em",
        // color: "#0000008a",
        whiteSpace: "nowrap",

    },
    // icon: {
    //     color: theme.palette.primary.main
    // }
}));

type DividerWithTextProps = {
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    startOnClickIcon?: () => void;
    endOnClickIcon?: () => void;
    hideLines?: boolean;
} & ComponentPropsWithRef<"div">;

export const DividerWithText = (props: DividerWithTextProps) => {
    const classes = useStyles();
    return (
        <div className={classes.container} {...props}>
            {props.startIcon && (
                <div className="rightIcon-true" onClick={props.startOnClickIcon}>
                    {props.startIcon}
                </div>
            )}
            {!props.startIcon && (
                <div
                    className={classes.borderShort}
                    style={props.hideLines ? { visibility: "hidden" } : undefined}
                />
            )}
            <span className={classes.content}>{props.children}</span>
            <div
                className={classes.borderLong}
                style={props.hideLines ? { visibility: "hidden" } : undefined}
            />
            {props.endIcon && (
                <div className="rightIcon-true" onClick={props.endOnClickIcon}>{props.endIcon}</div>
            )}
        </div>
    );
};
