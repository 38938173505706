import { ref_Property, ref_PropertyId } from "./ref_Property.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Formats, mm_FormatsId } from "./mm_Formats.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasFormatId = rid;

export class vw_mm_HasFormat extends ReferentialHasViews<src_MMId, mm_FormatsId, ref_PropertyId> {
	"@rid"?: vw_mm_HasFormatId;

}