
import { Client } from "hub-lib/client/client.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { IPropertiesProvider, Property } from "./GridBase.bin";

export class PropertiesProvider implements IPropertiesProvider {

    configurationProvider: () => ref_TableConfigurations;

    constructor(configurationProvider?: () => ref_TableConfigurations) {
        this.configurationProvider = configurationProvider;
    }

    async Provide(type: string): Promise<Property[]> {
        let properties = (await Client.getMetadata(type, false)).data.results
        const configuration = this.configurationProvider();

        if (configuration) {
            properties = configuration?.Columns
                ?.map(c => ({
                    c, p: c?.options?.["MetaData"] ? {
                        ...c?.options?.["MetaData"],
                        ...{ name: c?.field }
                    } : properties.find(p => p?.name == c?.field)
                }))
                ?.filter(c => c.p)
                ?.map(c => ({
                    ...c.p,
                    options: c.c.options
                }));
        }
        return properties ?? [];

    }
}