import * as React from "react";
import { Editor, EditorChangeEvent, EditorTools, EditorUtils } from '@progress/kendo-react-editor';

import '../../styles/commentEditor.scss';
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { GetCurrentLocale } from "trad-lib";

const {
    Bold, Italic, Underline, Strikethrough,
    // Subscript, Superscript,
    // AlignLeft, AlignCenter, AlignRight, AlignJustify,
    // Indent, Outdent,
    OrderedList, UnorderedList,
    // Undo, Redo,
    FontSize, FontName,
    // FormatBlock,
    Link, Unlink,
    // InsertImage, ViewHtml,
    // InsertTable,
    // AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
    // DeleteRow, DeleteColumn, DeleteTable,
    // MergeCells, SplitCell
} = EditorTools;

type TextEditorProps = { onChange?: (value: string) => void, content?: string }
export const TextEditor = ({ onChange, content }: TextEditorProps) =>
    <div style={{ overflow: "hidden", borderRadius: 25, width: "100%", height: "100%" }}>
        <CustomEditor
            onChange={(v) => onChange(v)}
            content={content} />
    </div>

function CustomEditor({ onChange, content }) {
    return <Editor
        className="CustomEditor"
        tools={[
            [Bold, Italic, Underline, Strikethrough],
            // [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            [OrderedList, UnorderedList],
            FontSize, FontName,
            // FormatBlock,
            [Link, Unlink],
            // [Link, Unlink, ViewHtml],
        ]}
        onChange={(e) => setTimeout(() => onChange(EditorUtils.getHtml(e.target.view.state)), 100)}
        defaultContent={content ?? ""} />
}



