
import * as React from "react";
import GridLayout from "./GridLayout.bin";
import { CustomLayout } from "./CustomLayout.bin";
import { GetLayout, SetLayout } from "../../utils/localstorage.bin";
import { WidgetAddedEvent } from "./SearchWidgets.bin";
import { EventEmitter } from "events";
import { getIcon } from "adwone-lib/index";
import { CustomButton } from "../ConfigurableComponents/CustomButton.bin";
import { CustomIconButton } from "../VertexGrid/Generic/CustomIconButton";

export let WidgetRemovedEvent: EventEmitter = new EventEmitter();

export class TProps {
    children?: React.ReactElement<CustomLayout>[] | React.ReactElement<CustomLayout>
}

export class CustomGridLayout extends React.Component<TProps, any> {

    constructor(props: TProps) {
        super(props);
        this.state = {
            version: 0
        }
    }

    componentDidMount = () => {
        WidgetAddedEvent.addListener("added", this.widgetAdded)
    }

    componentWillUnmount = () => {
        WidgetAddedEvent.removeListener("added", this.widgetAdded)
    }

    widgetAdded = (idWidget: string) => {

        let layout = GetLayout();
        if (layout.find((lay: any) => lay.i === idWidget))
            return;

        let child = this.getChildren().find((childLayout: React.ReactElement<CustomLayout>) => childLayout.key === idWidget);
        if (child) {
            layout.push({ ...child.props, i: child.key, children: undefined });
            SetLayout(layout);
            this.setState({ version: this.state.version + 1 })
        }
    }

    getChildren = (): React.ReactElement<CustomLayout>[] => {
        let children: React.ReactElement<CustomLayout>[] = undefined;
        if (Array.isArray(this.props.children)) children = this.props.children as React.ReactElement<CustomLayout>[];
        else children = [this.props.children as any];
        return children;
    }

    getLayouts = () => {
        let saveLayout = GetLayout();
        if (!saveLayout) {
            saveLayout = this.getChildren().map(c => { return { ...c.props, i: c.key } });
            SetLayout(saveLayout);
        }
        return saveLayout;
    }

    generateDOM = () => {

        let layoutsKeys: string[] = this.getLayouts().map((layout: any) => layout.i);

        return this.getChildren()
            .filter((child: React.ReactElement<CustomLayout>) => layoutsKeys.includes(child.key.toString()))
            .map(c => {
                return <div key={c.key}>
                    {c}
                    <CustomIconButton
                        className="remove_buttons custom_btn_secondary_remove popover_button"
                        onClick={() => {
                            let saveLayout: { i: string }[] = GetLayout();
                            saveLayout = saveLayout.filter((lay: { i: string }) => lay.i !== c.key);
                            SetLayout(saveLayout);
                            WidgetRemovedEvent.emit("removed", c.key);
                            this.forceUpdate();
                        }}>
                        {getIcon("delete")}
                    </CustomIconButton>
                </div>
            });
    }

    render() {
        let { version } = this.state;
        return (<GridLayout
            key={`GridLayout-version-${version}`}
            generateDOM={this.generateDOM}
            initialLayout={this.getLayouts()}
            rowHeight={45}
            cols={{ lg: 4, md: 4, sm: 4, xs: 4, xxs: 4 }} />);
    }
}