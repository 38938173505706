import * as React from "react";
import { Grid } from "@material-ui/core";
import { IsDebugMode } from "adwone-lib";
import { netTypes, netType, DiscountManager, getAvailableDiscountClasses } from "hub-lib/business/DiscountManager.bin";
import { Trad } from "trad-lib";
import { firstCol, firsColwithRank, IntervalsDesactivated } from "./AgreementDialog";
import { numberCols } from "./IntervalsDialog.bin";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { DiscountMode } from "../Messages/DIscountMode.bin";
import { eAgreementConfig, ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { ref_GlobalAgreements } from "hub-lib/dto/client/ref_GlobalAgreements.bin";
import { MenuAddDiscount } from "../Messages/discounts/MenuAddDiscount";
import { DiscountCategoryArray } from "../Messages/DiscountCategoryArray.bin";
import { checkDiscountClass } from "../Messages/discounts/validators.bin";
import { Client } from "hub-lib/client/client.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { CustomBadge } from "../Filters/MenuItemFilters";
import { GetHashCode } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../redux/store";
import { setAgreement, setConfig } from "../../../redux/agreementEditorSlice";
import { RadioButton } from "@progress/kendo-react-inputs";
import { colWidth, removeCol } from "../Messages/DiscountEditor/DiscountEditor.bin";
import { RowSize } from "../../../styles/theme";
import { boderRow } from "../Messages/DiscountEditor/Rows/DiscountGridRow";

type AgreementDiscountsProps = {
    agreement: ref_Agreements | ref_GlobalAgreements,
    endEdit?: () => void,
    // onOpenIntervalsClicked?: (discount: ref_Discount) => void,
    isGlobalAgreement?: boolean
}

class AgreementDiscountsState {
    cofo: netType = "CO";
    loaded: boolean = false;
    currencyCode: string = "";
}

type CellProps = {
    text: string,
    justify?: string
}
function GenerateCell({ text, justify }: CellProps) {
    return <span style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: justify ?? "flex-start" }}>{text}</span>
}

type DiscountCategoryProps = {
    firstCol: number | string,
    styles?: React.CSSProperties,
    currencyCode?: string,
    label: string
}

function DiscountCategoryHeader(props: DiscountCategoryProps) {
    const { firstCol, styles, label } = props;

    return <div className="discountGridRowTitle" style={{ display: 'flex', height: RowSize }}>
        <div style={{ width: firstCol, ...styles }}>
            <GenerateCell text={label} />
        </div>
        {/** Taux */}
        <div style={{ width: colWidth, ...styles }}>
            <GenerateCell text="-" justify="flex-end" />
        </div>
        {/** Montant */}
        {<div style={{ width: colWidth, ...styles }}>
            <GenerateCell text="-" justify="flex-end" />
        </div>}
        <div style={{ width: removeCol }}></div>
    </div>
}

export function AgreementDiscounts({ agreement, /* onOpenIntervalsClicked, */ isGlobalAgreement: _isGlobalAgreement, endEdit }: AgreementDiscountsProps) {

    let discountTab1: DiscountCategoryArray;
    let discountTab2: DiscountCategoryArray;
    let discountTab3: DiscountCategoryArray;

    const [state, setState] = React.useState(new AgreementDiscountsState())
    const { cofo, loaded, currencyCode } = state;
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const getTabStripIndex = () => Object.values(netTypes).indexOf(cofo);
    const setTabStripIndex = (index: number) => setState({ ...state, cofo: Object.values(netTypes)[index] as netType });


    React.useEffect(() => {
        if (!loaded) Promise.resolve().then(async () => {
            await DiscountManager.Load();
            const _currencyCode = (await Client.searchVertexTyped(ref_Currencies, { "@rid": agreement.Currency }))?.[0]?.Code
            setState({ ...state, loaded: true, currencyCode: _currencyCode });
        })
    });

    const UpdateDiscounts = () => {
        const discounts1 = discountTab1?.getDiscountAndUpdate() ?? [];
        const discounts2 = discountTab2?.getDiscountAndUpdate() ?? [];
        const discounts3 = discountTab3?.getDiscountAndUpdate() ?? [];

        const discounts = discounts1.concat(discounts2).concat(discounts3);

        if (IsDebugMode()) {
            console.log(`UpdateDiscounts previous agreement.Discounts`, agreement.Discounts);
            console.log(`UpdateDiscounts getDiscount`, discounts);
        }

        discounts.forEach(d => d.Agreement = agreement["@rid"]);
        agreement.Discounts = discounts;
        setState({ ...state });
    }

    if (!loaded)
        return <div />;

    /** Métier: pas de gracieux sur les accords  */
    // const discountTypes = DiscountManager.GetDiscountTypesLoaded().filter(t => t.Name != "Gracieux");
    // const categoryTypes = DiscountManager.getCategoryTypesLoaded(n => n != "Gracieux");

    /** On réinclue les gracieux */
    const discountTypes = DiscountManager.GetDiscountTypesLoaded().filter(t => t.Name != "Honoraires");
    const categoryTypes = DiscountManager.getCategoryTypesLoaded(n => n != "Honoraires");

    /** Filtre les classes en fonction des types qu'on peut ajouter */
    const discountClasses = DiscountManager.GetDiscountClassesLoaded().filter(c => discountTypes.some(t => t["@rid"] == c.DiscountType));

    return <Grid key={`discount-editor-agreement-${agreement.DiscountMode}`} container className='block-container' style={{ position: "relative" }}>

        <Grid container direction="row-reverse" style={{ position: "absolute", right: 0, paddingLeft: 10, zIndex: 10 }}>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={6} className="message_details_left">
                        <DiscountMode
                            discountMode={agreement.DiscountMode}
                            onChange={mode => {
                                agreement.DiscountMode = mode;
                                forceUpdate();
                            }} />
                    </Grid>

                    <Grid item xs={6}>
                        <Grid container direction="row-reverse" className="message_details_right" style={{ position: "relative" }}>
                            <Grid item>
                                <div style={{ position: "absolute", right: 0 }}>
                                    <MenuAddDiscount
                                        classes={getAvailableDiscountClasses(agreement?.Discounts, cofo, agreement?.Media, ["Honoraires"])}
                                        onClick={async (dType, dClass) => {
                                            const cat = categoryTypes.find((ct: any) => ct.includes(dType["@rid"]));
                                            const idx = categoryTypes.indexOf(cat);

                                            const baseObject = { Type: dClass["@rid"], DiscountType: dType["@rid"] };
                                            if (idx == 0) await discountTab1?.addNew(dType["@rid"], false, baseObject)
                                            if (idx == 1) await discountTab2?.addNew(dType["@rid"], false, baseObject)
                                            if (idx == 2) await discountTab3?.addNew(dType["@rid"], false, baseObject)

                                            UpdateDiscounts();
                                        }} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <TabStrip selected={getTabStripIndex()} onSelect={e => setTabStripIndex(e.selected)} className="discount-tabstrip">

            <TabStripTab title={<TabLabel label="CO" />}>
                {cofo == "CO" && <TabComponent agreement={agreement} />}
            </TabStripTab>

            <TabStripTab title={<TabLabel label="FO" />}>
                {cofo == "FO" && <TabComponent agreement={agreement} />}
            </TabStripTab>

            <TabStripTab title={<TabLabel label="FOS" />}>
                {cofo == "FOS" && <TabComponent agreement={agreement} />}
            </TabStripTab>
        </TabStrip>
    </Grid>;

    type TabComponentProps = { agreement: AgreementDiscountsProps['agreement'], onChange?: () => void; }

    function TabComponent({ agreement }: TabComponentProps) {

        const UpdateDiscounts = (agreement: ref_Agreements | ref_GlobalAgreements) => {
            const discounts1 = discountTab1?.getDiscountAndUpdate() ?? [];
            const discounts2 = discountTab2?.getDiscountAndUpdate() ?? [];
            const discounts3 = discountTab3?.getDiscountAndUpdate() ?? [];

            const discounts = discounts1.concat(discounts2).concat(discounts3);

            if (IsDebugMode()) {
                console.log(`UpdateDiscounts previous agreement.Discounts`, agreement.Discounts);
                console.log(`UpdateDiscounts getDiscount`, discounts);
            }

            discounts.forEach(d => d.Agreement = agreement["@rid"]);
            agreement.Discounts = discounts;
            store.dispatch(setAgreement(agreement));
        }
        const columnDefaultStyle = { paddingLeft: 8, paddingRight: 5 };
        const generateDiscountTabCategory = (title: string, types: string[], onRef: (ref: DiscountCategoryArray) => void, additionalCommand?: boolean, emptyspace?: boolean) => {
            return <>
                <DiscountCategoryHeader
                    label={title}
                    firstCol={firstCol(false)}
                    styles={columnDefaultStyle} />
                <DiscountCategoryArray
                    hideIntervalCells={IntervalsDesactivated}
                    isRemovable={() => true}
                    onRemoveClick={() => {
                        UpdateDiscounts(agreement);
                        endEdit?.();
                        setState({ ...state });
                        forceUpdate();
                    }}
                    //enterEdit={this.disableButtonFinancial}
                    endEdit={() => endEdit?.()}
                    validator={{
                        // "Rate": checkValueRate(true),
                        // "Value": checkValueRate(),
                        "DiscountClass": checkDiscountClass
                    }}
                    // additionnalCommands={additionalCommand ? [{
                    //     icon: getIcon("estimates"),
                    //     function: (row: any) => {
                    //         onOpenIntervalsClicked?.(row.dataItem);
                    //     },
                    // }] : []}
                    emptyspace={emptyspace}
                    ranking={() => agreement.DiscountMode !== 'cascade'}
                    draggable={() => false}
                    allClasses={discountClasses}
                    allTypes={discountTypes}
                    currencyCode={currencyCode}
                    onChange={() => UpdateDiscounts(agreement)}
                    ref={onRef}
                    discountsProvider={() => agreement.Discounts}
                    types={types}
                    classes={discountClasses.filter(c => types.includes(c.DiscountType)).map(c => c["@rid"])}
                    type={cofo} />
            </>
        }

        return <Grid item xs={12}>
            <div className="discountGridRowTitle gray-back" style={{ width: "100%", display: "flex", height: RowSize, borderBottom: boderRow }} key={`header_discounts_table${agreement.DiscountMode}`}>
                {(agreement.DiscountMode !== 'cascade') && <div style={{ width: numberCols, ...columnDefaultStyle }}>
                    <GenerateCell text={Trad("rank")} justify="flex-end" /></div>}
                <div style={{ width: agreement.DiscountMode == 'cascade' ? firstCol(false) : firsColwithRank(false), ...columnDefaultStyle }}>
                    <GenerateCell text="Identification" /></div>
                {/* <div style={{ width: numberCols, textAlign: 'right', paddingRight, paddingBottom: 10 }}>{Trad("intervals")}</div> */}
                <div style={{ width: numberCols, textAlign: 'right', ...columnDefaultStyle }}> <GenerateCell text={Trad("rate")} justify="flex-end" /></div>
                <div style={{ width: numberCols, textAlign: 'right', ...columnDefaultStyle }}> <GenerateCell text={Trad("amount")} justify="flex-end" /></div>
                <div style={{ width: removeCol }} />
            </div>
            <div className="discount-editor-array-container">
                {generateDiscountTabCategory(Trad("base_rate"), categoryTypes[0], (ref) => discountTab1 = ref, false, true)}
                {generateDiscountTabCategory(Trad("Brut Base Achat"), categoryTypes[1], (ref) => discountTab2 = ref, true)}
                {generateDiscountTabCategory(Trad("net_space"), categoryTypes[2], (ref) => discountTab3 = ref, true)}
            </div>
        </Grid>
    }
}

type TabLabelProps = { label: netType };
function TabLabel({ label }: TabLabelProps) {
    const hasChanged = useSelector((state: RootState) => state.agreementEditor.hasChanged[label]);
    return <div className='badge-template'>
        {hasChanged && <div className='badge-template-icon'><CustomBadge /></div>}
        <span>{label}</span>
    </div>;
}

function HasConfig(selector: eAgreementConfig, applicationConfig: netType[]): boolean {
    if (selector == eAgreementConfig.COFOFOS)
        return applicationConfig.includes("CO");
    if (selector == eAgreementConfig.FOFOS)
        return applicationConfig.includes("FO") && !applicationConfig.includes("CO");
    return !applicationConfig.includes("FO") && !applicationConfig.includes("CO");
}

export function ApplicationConfigSelector() {
    const applicationConfig = useSelector((state: RootState) => state.agreementEditor.applicationConfig);
    return <Grid container alignItems="center" className="block-container" key={`radio-${GetHashCode(applicationConfig)}`}>
        <Grid item>
            <span className="margin-right">{Trad("apply_agreement_on")} :</span>
            {(Object.keys(eAgreementConfig) as Array<eAgreementConfig>).map((key) => (
                <div className="margin-right">
                    <RadioButton
                        name={key}
                        value={key}
                        checked={HasConfig(key, applicationConfig)}
                        label={Trad(key)}
                        onChange={(e) => {
                            let config: any = ["FOS"];
                            if (e.value != eAgreementConfig.FOS)
                                config.push("FO");
                            if (e.value == eAgreementConfig.COFOFOS)
                                config.push("CO");
                            store.dispatch(setConfig(config));
                        }}
                    />
                </div>
            ))}
        </Grid>
    </Grid>
}
