import * as React from "react";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { GetHashCode, propertyOf } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { initMessageAgencyOptions } from "../../../../redux/messageEditorSlice";
import { GetEditorState, RootState, SetModel, SetModelSync, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { initCampaignAgencyOptions } from "../../../../redux/campaignEditorSlice";
import { ModelPropertiesEditor } from "./ModelPropertiesEditor";
import { Grid } from "@material-ui/core";
import { otherRid } from "../../../../redux/storeUtils";
import { Checkbox } from "@progress/kendo-react-inputs";


type MandataireEditorArgs = { model: "ref_Messages" | "ref_Campaigns" }

export function MandataireEditor({ model }: MandataireEditorArgs) {

    const dispatch = useDispatch();
    const Agency_Res = useSelector((root: RootState) => GetEditorState(model, root).get()?.Agency_Res);
    const options = useSelector((root: RootState) => GetEditorState(model, root).agencyOptions);
    const lockNext = useSelector((root: RootState) => GetEditorState(model, root).lockNext);
    const Agency_ResOther = useSelector((root: RootState) => GetEditorState(model, root).data.ModelProperties?.["Agency_ResOther"]);
    const AdvertiserDirect = useSelector((root: RootState) => GetEditorState(model, root).data.AdvertiserDirect);
    React.useEffect(() => {
        if (options === undefined)
            dispatch(model == "ref_Campaigns" ? initCampaignAgencyOptions(undefined) : initMessageAgencyOptions(undefined));
    }, [options])

    const getElement = options => options?.find(o => o["@rid"] == Agency_Res);

    return <>
        <Grid item xs={12} className="message_details_full">
            <Checkbox label={TradProp("AdvertiserDirect", ref_Messages)}
                checked={AdvertiserDirect}
                onChange={async (event) => {
                    const data = GetEditorState(model, store.getState()).get();
                    data.AdvertiserDirect = event.value;
                    if (data.AdvertiserDirect) {
                        data.Agency_Res = null;
                        data.ModelProperties["Agency_ResOther"] = null;
                    }
                    SetModelSync(model, data);
                }} />
        </Grid>
        <Grid item xs={6} className="message_details_leftcombo">
            <FavoriteVertexAutoComplete
                key={`MandataireEditor-${GetHashCode({ options, Agency_Res })}`}
                disableClearable
                disabled={lockNext || AdvertiserDirect}
                loading={lockNext}
                // enabledOnlyLoading={isLoading}
                label={TradProp("Agency_Res", ref_Messages)}
                options={options ?? []}
                defaultValue={getElement}
                onChange={async value => {
                    if (Agency_Res != value["@rid"]) {
                        const data = GetEditorState(model, store.getState()).get();
                        data.Agency_Res = value["@rid"];

                        if (data.Agency_Res && data.Agency_Res !== otherRid)
                            data.ModelProperties["Agency_ResOther"] = null;
                        else
                            data.ModelProperties["Agency_ResOther"] = "";

                        SetModel(model, data);
                    }
                    return value;
                }} />
        </Grid>
        <Grid item xs={6} className="message_details_rightcombo">
            {/* if we choice other */}
            {Agency_ResOther != null &&
                <ModelPropertiesEditor model={model} property='Agency_ResOther' label={TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.Agency_ResOther` as any, ref_Messages)} />
            }
        </Grid>
    </>

}