import * as React from "react";
import "../../../../styles/LinkManager.scss";
import { Slider, SliderLabel } from "@progress/kendo-react-inputs";
import { ClickModalityHandler, divModalityUnlinkHandler, iconLinked, isLinked, isRidLinked, updateVertex, viewClassname } from "./LinkManagerTools";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { Client } from "hub-lib/client/client.bin";
import { ReferentialHasViews } from "hub-lib/models/orientdb/ReferentialHasViews.bin";
import { useState } from "react";
import { eSort, findBestViews } from "tools-lib";
import { ViewTree } from "./ViewList";
import { setState } from "../../../../redux/linkMgrSlice";
import { CustomButton } from "../../../ConfigurableComponents/CustomButton.bin";
import { TooltipManager } from "../../../CustomTooltip";
import { ConsoleDebug } from "../../../../utils/localstorage.bin";
import { Trad, TradClassName } from "trad-lib";
import { eDialogMode, GenericDialog } from "../../../ConfigurableComponents/GenericDialog.bin";
import { HierarchyReferential } from "../Referential.bin";
import { GetParentClass } from "../../../VertexGrid/Adwone-admin/Referential/ReferentialTools";
import { ReferentialForm } from "../../../VertexGrid/Adwone-admin/Referential/ReferentialForm.bin";
import { Views } from "hub-lib/models/orientdb/Views.bin";
import { mm_Advertisers } from "hub-lib/models/orientdb/mm_Advertisers.bin";
import { mm_Supports } from "hub-lib/models/orientdb/mm_Supports.bin";
import { mm_AdvertisingCompanies } from "hub-lib/models/orientdb/mm_AdvertisingCompanies.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Countries } from "hub-lib/models/orientdb/ref_Countries.bin";
import { vw_mm_HasAdvertiserGroup } from "hub-lib/models/orientdb/vw_mm_HasAdvertiserGroup.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { Notify } from "../../../../utils/Notify.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { mm_AdvertiserGroups } from "hub-lib/models/orientdb/mm_AdvertiserGroups.bin";
import { vw_mm_HasAdvertisingCompanyGroup } from "hub-lib/models/orientdb/vw_mm_HasAdvertisingCompanyGroup.bin";
import { vw_mm_HasAdvertisingCompanies } from "hub-lib/models/orientdb/vw_mm_HasAdvertisingCompanies.bin";
import { ref_AdvertisingCompanyGroups } from "hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin";
import { mm_AdvertisingCompanyGroups } from "hub-lib/models/orientdb/mm_AdvertisingCompanyGroups.bin";
import { tsm_AdvertisingCompanies } from "hub-lib/models/orientdb/tsm_AdvertisingCompanies.bin";
import { ref_AdvertisingCompanyRole } from "hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { Typed } from "hub-lib/tools.bin";
import { lnk_AdvertisingCompanySupport } from "hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin";
import moment from "moment";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { Button } from "@progress/kendo-react-buttons";
import { mm_Brands } from "hub-lib/models/orientdb/mm_Brands.bin";
import { vw_mm_HasBrand } from "hub-lib/models/orientdb/vw_mm_HasBrand.bin";
import { vw_mm_HasAdvertiser } from "hub-lib/models/orientdb/vw_mm_HasAdvertiser.bin";
import { mm_Products } from "hub-lib/models/orientdb/mm_Products.bin";
import { AdvertiserGroupExtended } from "hub-lib/dto/referential/AdvertiserGroupExtended.bin";
import { AdvertisingCompanyExtended } from "hub-lib/dto/referential/AdvertisingCompanyExtended.bin";
import { BrandExtended } from "hub-lib/dto/referential/BrandExtended.bin";
import { ProductExtended } from "hub-lib/dto/referential/ProductExtended.bin";
import { SupportExtended } from "hub-lib/dto/referential/SupportExtended.bin";

export function LinkList() {
    return <>
        <div className="header-list link">
            {/* Bouton */}
            <LinkListheaders />
        </div>
        <LinkListListBox />
    </>
}


const mapperView = async (view: Views) => {

    const fillCompanyInfos = async (obj: { Name, Adress, City, ZipCode, Siren, Siret, Gln, Country, Tva }, view: Views, parent?: { link: string, ref: string }) => {
        obj.Name = view.Label?.toUpperCase();
        obj.Adress = view["MD_addresses"]?.[0]?.addressLine2;
        obj.City = view["MD_addresses"]?.[0]?.addressLine5;
        obj.ZipCode = view["MD_addresses"]?.[0]?.postCode;
        obj.Siret = view["MD_siretEdi"] ?? view["MD_siretNumber"];
        obj.Siren = view["MD_sirenNumber"];
        obj.Gln = view["MD_gln"];
        obj.Tva = view["MD_vatEuNumber"];

        const countryCode = view["MD_countryCode"];
        if (countryCode)
            obj.Country = (await Client.searchVertexTyped(ref_Countries, { codeAlpha3: countryCode }))?.[0]?.["@rid"];

        const groupViewId = view["MD_parentGroupId"];
        if (parent && groupViewId) {
            const groupLinks = (await Client.searchVertex(parent.link, {
                "out.ExternalID": groupViewId,
                in: store.getState().linkMgr.selectedSource["@rid"],
                "Referential.@class": parent.ref
            }))?.data?.results;

            if (groupLinks.length > 1)
                Notify(Trad("more_than_one_group_found"), "warning");

            if (groupLinks.length > 0)
                obj["parent"] = groupLinks[0].Referential;
        }
    }

    const fillTSMCompanyInfos = async (obj: { Name, Adress, City, ZipCode, Siret, Gln, Country }, view: Views) => {
        obj.Name = view.Label?.toUpperCase();
        obj.Adress = view["TSM_Adress"];
        obj.City = view["TSM_Ville"];
        obj.ZipCode = view["TSM_ZipCode"];
        obj.Siret = view["TSM_Siret"];
    }

    switch (view["@class"]) {

        case mm_AdvertisingCompanyGroups.name:
            const advCGrp = new AdvertisingCompanyExtended();
            await fillCompanyInfos(advCGrp, view);
            return advCGrp;

        case mm_AdvertiserGroups.name:
            const advtGroup = new AdvertiserGroupExtended();
            await fillCompanyInfos(advtGroup, view);
            return advtGroup;

        case mm_AdvertisingCompanies.name:
            const advC = new AdvertisingCompanyExtended();
            await fillCompanyInfos(advC, view, { link: vw_mm_HasAdvertisingCompanyGroup.name, ref: ref_AdvertisingCompanyGroups.name });
            return advC;

        case mm_Supports.name:
            const sup = new SupportExtended();
            sup.Name = view.Label;
            if (view["MD_media"]?.code)
                sup.Medias = (await Client.searchVertexTyped(ref_Media, { Code: view["MD_media"].code }))?.map(m => m["@rid"]);


            sup.lnkAdvertisingCompanies = [];
            const advCompaniesRoles = await Client.searchVertexTyped(ref_AdvertisingCompanyRole);
            const advCom = advCompaniesRoles.find(r => r.Name == "Commercial");
            const advFin = advCompaniesRoles.find(r => r.Name == "Financial");

            const addSuppliers = async (prop: string, role: string) => {
                for (const link of (view[prop] ?? [])) {
                    const [advCompany] = await Client.searchVertexTyped(vw_mm_HasAdvertisingCompanies, {
                        "out.ExternalID": link["supplierId"],
                        in: store.getState().linkMgr.selectedSource["@rid"],
                        "Referential.@class": ref_AdvertisingCompanies.name
                    });

                    if (advCompany) {
                        sup.lnkAdvertisingCompanies.push(Typed<Partial<lnk_AdvertisingCompanySupport>>({
                            Active: true,
                            Default: link["default"],
                            DefaultBroadcastArea: null,
                            Roles: [role],
                            Start: moment.utc(link["startDate"], "YYYY-MM-DD").toDate(),
                            End: moment.utc(link["endDate"], "YYYY-MM-DD").toDate(),
                            in: advCompany?.Referential
                        }) as any);
                    }
                }
            }

            await addSuppliers("MD_comSupplierLinks", advCom["@rid"]);
            await addSuppliers("MD_finSupplierLinks", advFin["@rid"]);

            const currencyCode = view["MD_defaultCurrencyCode"];
            if (currencyCode) {
                const [currency] = await Client.searchVertexTyped(ref_Currencies, { Name: currencyCode });
                if (currency) {
                    sup.lnkCurrencies = [{
                        Default: true,
                        Active: true,
                        Start: moment.utc("01-01-2014", "DD-MM-YYYY").toDate(),
                        End: null,
                        out: currency["@rid"],
                        in: undefined
                    }];
                }
            }

            if (sup.lnkAdvertisingCompanies?.length) {
                const [area] = await Client.searchVertexTyped(ref_BroadcastAreas, { Name: sup.Name.toLocaleUpperCase().split("/") });
                if (area)
                    sup.lnkAdvertisingCompanies.forEach(l => l.DefaultBroadcastArea = area["@rid"])
            }

            return sup;

        case mm_Advertisers.name:
            const adv = new ref_Advertisers();
            await fillCompanyInfos(adv, view, { link: vw_mm_HasAdvertiserGroup.name, ref: ref_AdvertiserGroups.name });
            return adv;

        case tsm_AdvertisingCompanies.name:
            const advComp = new AdvertisingCompanyExtended();
            await fillTSMCompanyInfos(advComp, view);
            return advComp;
        case mm_Brands.name:
            const brand = new BrandExtended();
            brand.Name = view.Label?.toUpperCase();
            if (view["MD_advertiserIds"]?.length) {
                const mmParentIds = view["MD_advertiserIds"];
                const links = await Client.searchVertexTyped(vw_mm_HasAdvertiser, {
                    "out.ExternalID": mmParentIds,
                    in: store.getState().linkMgr.selectedSource["@rid"]
                });
                brand.parent = links.filter(l => l.Referential).map(l => l.Referential);
            }
            return brand;
        case mm_Products.name:
            const product = new ProductExtended();
            product.Name = view.Label?.toUpperCase();
            if (view["MD_brandIds"]?.length) {
                const mmParentIds = view["MD_brandIds"];
                const links = await Client.searchVertexTyped(vw_mm_HasBrand, {
                    "out.ExternalID": mmParentIds,
                    in: store.getState().linkMgr.selectedSource["@rid"]
                });
                product.parent = links.filter(l => l.Referential).map(l => l.Referential);
            }
            return product;
        default:
            return { Name: view.Label?.toUpperCase() }

    }
}

function LinkListheaders() {

    // selector pour refresh les buttons
    const links = useSelector((root: RootState) => root.linkMgr.links);
    const [createReferentialOpen, setCreateReferentialOpen] = useState(null);
    const topModalitiesViews = useSelector((root: RootState) => root.linkMgr.topModalitiesViews);
    const outIdsAuto = useSelector((root: RootState) => root.linkMgr.outIdsAuto);
    const outIds = useSelector((root: RootState) => root.linkMgr.outIds);
    const inId = useSelector((root: RootState) => root.linkMgr.inId);
    const modalities = useSelector((root: RootState) => root.linkMgr.modalities);
    const selectedDimension = useSelector((root: RootState) => root.linkMgr.selectedDimension);
    const selectedProptype = useSelector((root: RootState) => root.linkMgr.selectedProptype);
    const dispatch = useDispatch();

    let selectedvertex = selectedDimension;
    if (selectedDimension == ref_Property.name && selectedProptype)
        selectedvertex = selectedProptype?.Type;

    const hierarchy = Object.entries(HierarchyReferential ?? {}).find(([k, v]) => v?.includes(selectedDimension))

    const clickLinkHandler = async (getRef: () => any) => {
        const { selectedLink, selectedSource, filteredModalitiesViews, topModalitiesViews } = store.getState().linkMgr;

        if (!selectedLink || !selectedSource) return;

        if (!inId || !outIds?.length) {
            alert("error cannot link");
            return;
        }

        ConsoleDebug('clickLinkHandler outIds', outIds, 'outIdsAuto', outIdsAuto);

        const toLink = topModalitiesViews.filter(m => !isLinked(m, "Views"));
        console.log(`[toLink]`, toLink);

        const message = toLink?.length < 10
            ? `êtes vous sûr de vouloir lier ces éléments: ` + toLink.map(m => m.Label).join(", ")
            : `êtes vous sûr de vouloir lier ces éléments: ` + toLink.length + " éléments";

        if (window.confirm(message)) {

            const res = await Client.searchVertex(selectedLink.name, { in: selectedSource["@rid"], out: toLink?.map(m => m["@rid"]) })
            const links: ReferentialHasViews<any, any, any>[] = res.data.results;
            links.forEach((link) => {
                link.Referential = getRef();
            });

            await updateVertex(links);
            dispatch(setState({ filteredModalitiesViews: [...filteredModalitiesViews] }));
        }
    }

    const isLinkActive = inId && (outIds?.some(id => !isRidLinked(id)) || outIdsAuto?.length);
    const isUnLinkActive = inId && outIds?.some(id => isRidLinked(id));

    const referentialName = inId && (modalities?.find(m => m["@rid"] == inId) as any)?.Name;
    return <>
        <span style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <span className="link-title-header">Liaisons: </span>
            {inId && <div className="is-text-selected link-title-header container-overflow-hide"
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: referentialName })}>
                <span className="text-overflow-hide">
                    {referentialName}
                </span>
            </div>}
            {selectedDimension && <span>({TradClassName(selectedDimension)})</span>}
        </span>

        <div className={inId ? "link_button" : "link_button hidden"}>
            <LinkAutoButton />
        </div>

        <div className={`buttons-link ${inId ? "" : "hidden"}`}>
            <CustomButton disabled={!isUnLinkActive} className="custom_btn_danger" onClick={() => clickLinkHandler(() => null)} Label="Délier" style={{ marginRight: 10 }} />
            <CustomButton disabled={!isLinkActive} className="custom_btn_primary_validation" onClick={() => clickLinkHandler(() => inId)} Label="Lier" />
        </div>

        {selectedDimension && !inId && topModalitiesViews?.length &&
            <div className="buttons-link">
                <CustomButton className="custom_btn_primary_validation" onClick={async () => {
                    const defaultValues = await mapperView(topModalitiesViews[0]);
                    setCreateReferentialOpen(defaultValues)
                }} Label={Trad("Créer référentiel")} />
            </div>}

        {createReferentialOpen && <GenericDialog
            open={true}
            dialogTitle={`${Trad(eDialogMode.create)} ${TradClassName(selectedvertex)}`}>
            <div className='adw-form-dialog-container'>
                <ReferentialForm
                    onSave={newElement => {
                        store.dispatch(setState({
                            inId: newElement['@rid'],
                            modalities: [newElement, ...modalities]
                        }));
                        setCreateReferentialOpen(false);
                    }}
                    defaultValues={createReferentialOpen}
                    mode={eDialogMode.create}
                    onCancel={() => setCreateReferentialOpen(false)}
                    parentClass={GetParentClass(selectedDimension, hierarchy?.[1])}
                    selectedVertex={selectedvertex} />
            </div>
        </GenericDialog>
        }
    </>
}

function LinkAutoButton() {

    const inId = useSelector((root: RootState) => root.linkMgr.inId);
    const [minRating, setMinRating] = useState(0.8);
    const dispatch = useDispatch();

    const clickLinkAutoHandler = () => {
        const { modalities, modalitiesViews, outIds, outIdsAuto, filteredModalitiesViews, selectedDimension } = store.getState().linkMgr;
        if (inId) {
            const matches = findBestViews(selectedDimension, modalities?.find((m) => m["@rid"] == inId), modalitiesViews?.filter(e => !isLinked(e, "Views")), { sort: eSort.desc, minRating });
            const newOutIds = [...(outIds.filter(e => !outIdsAuto.includes(e)))];
            const newOutIdsAuto = [];
            for (const match of matches) {
                if (newOutIds.indexOf(match.view["@rid"]) === -1) {
                    newOutIdsAuto.push(match.view["@rid"]);
                }
                newOutIds.push(match.view["@rid"]);
            }

            dispatch(setState({
                outIds: newOutIds,
                outIdsAuto: newOutIdsAuto,
                topModalitiesViews: modalitiesViews?.filter((m) => newOutIds.includes(m["@rid"])),
                filteredModalitiesViews: [...filteredModalitiesViews]
            }));
        }
    }

    return <div className="auto-link-component">

        <label className="k-checkbox-label">Similarités:</label>
        <div>
            <Slider buttons={false} step={1} defaultValue={minRating} min={0.4} max={1} onChange={e => setMinRating(e.value)}>
                <SliderLabel position={0.4}>40%</SliderLabel>
                <SliderLabel position={0.6}>60%</SliderLabel>
                <SliderLabel position={0.8}>80%</SliderLabel>
                <SliderLabel position={1}>100%</SliderLabel>
            </Slider>
        </div>

        <CustomButton className="custom_btn_primary validation-button search-button" onClick={() => clickLinkAutoHandler()} Label="Rechercher" />
    </div>
}

function LinkListListBox() {
    // selector important pour update le isLinked
    const links = useSelector((root: RootState) => root.linkMgr.links);
    const topModalitiesViews = useSelector((root: RootState) => root.linkMgr.topModalitiesViews)?.map(v => ({ id: v["@rid"], children: null, ...v })) ?? [];

    return <ViewTree
        loaderSelector="links"
        classNameContainer="link"
        views={topModalitiesViews}
        rowRender={(row, props) => {

            const unlinkButton = isLinked(props.dataItem) &&
                <div className="unlink-button-container" style={{ float: "right" }}>
                    <Button
                        className="unlink-button">
                        <i className="material-icons"
                            onClick={(e) => {
                                e.stopPropagation();
                                divModalityUnlinkHandler(props.dataItem["@rid"]);
                            }}>{iconLinked(props.dataItem)}</i></Button>
                </div>

            return <tr {...(row.props as any)}
                className={`item-clickable k-item ${row.props.className} ${viewClassname(props.dataItem)} `}
                onClick={async (_props: MouseEvent) => {

                    if (isLinked(props.dataItem))
                        return;

                    await ClickModalityHandler(props, true);
                }}><>{props.children}{unlinkButton}</></tr>;
        }} />
}

