import * as React from 'react'
import { Trad, TradProp } from 'trad-lib';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Brands } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_Products } from 'hub-lib/models/orientdb/ref_Products.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { VertecesStyleComponent } from '../VertecesStyleComponent';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_SchedulerConfigurations } from 'hub-lib/dto/client/ref_SchedulerConfigurations.bin';
import { Client } from 'hub-lib/client/client.bin';
import { ref_PropertyType } from 'hub-lib/models/orientdb/ref_PropertyType.bin';
import { lnk_AdvertisingCompanySupport } from 'hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin';
import { ref_AdvertisingCompanyRole } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin';
import { eCompare } from 'hub-lib/operators.bin';
import { clone, compareObjects, GetSubElement, memoizeAsyncBase, propertiesOf, propertyOf } from 'hub-lib/tools.bin';
import { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { indicateurToOptions, LabelLinesCreator, PropertyConfig } from './LabelLinesCreator';
import { CustomBadge } from '../../../Filters/MenuItemFilters';
import { RootState, store, getSchedulerSelector, getScheduler } from '../../../../../redux/store';
import { ref_Visuals } from 'hub-lib/dto/client/ref_Attachments.bin';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory';
import { IAdvertiserHierarchy, IMediaModel } from 'hub-lib/types';
import { eColumnType } from 'hub-lib/models/types.bin';
import { Indicateur } from 'adwone-engine/index.bin';
import { setScheduler } from '../../../../../redux/gridConfigurationsSlice';
import { SchedulerProps } from '../SchedulerConfigDialog';

class SchedulerModeleCreatorState {
    vertecesToMap: PropertyConfig[];
    indicateurs: Indicateur[];
};

async function GetSchedulerStyledProperties(objectType: string) {
    // const indicateurs = (await AggregatorFactory.GetInstance().Get(objectType).MetadataToIndicateurOptions())
    //     .filter(i => i.columnType === eColumnType.Property)
    //     .map(i => i.indicateur);

    const indicateurs = (await AggregatorFactory.GetInstance().Get(objectType).Provide())
        .map(i => i.indicateur);

    const advStyles: PropertyConfig[] = [];
    const AddStyle = (propertyName: string, config: PropertyConfig) => {
        if (indicateurs.some(i => i.field == propertyName))
            advStyles.push(config);
    };

    const propertyTypeEmplacement = (await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Emplacement" }))?.data?.results?.[0]?.["@rid"];
    const propertyTypeFormat = (await Client.get<ref_PropertyType>(ref_PropertyType, { Type: "Format" }))?.data?.results?.[0]?.["@rid"];
    const financialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Financial" }))?.data?.results?.[0]?.["@rid"];
    const commercialRid = (await Client.get<ref_AdvertisingCompanyRole>(ref_AdvertisingCompanyRole, { Name: "Commercial" }))?.data?.results?.[0]?.["@rid"];

    AddStyle("Placement", {
        vertex: ref_Property, params: {
            "_operators": [{
                property: "PropertyType",
                value: propertyTypeEmplacement,
                compare: eCompare.Contains
            }]
        }, label: "Placement",
        key: propertyOf<ref_Messages>("Placement")
    });
    AddStyle("Format", { vertex: ref_Property, params: { PropertyType: propertyTypeFormat }, label: "Format", key: propertyOf<ref_Messages>("Format") });
    AddStyle("Campaign", { vertex: ref_Campaigns, key: propertyOf<ref_Messages>("Campaign"), params: { Source: store.getState().project.filters.Source ?? "ADWONE" } });
    AddStyle("Product", { vertex: ref_Products, key: propertyOf<IAdvertiserHierarchy>("Product") });
    AddStyle("Brand", { vertex: ref_Brands, key: propertyOf<IAdvertiserHierarchy>("Brand") });
    AddStyle("Advertiser", { vertex: ref_Advertisers, key: propertyOf<IAdvertiserHierarchy>("Advertiser") });
    AddStyle("AdvertiserGroup", { vertex: ref_AdvertiserGroups, key: propertyOf<IAdvertiserHierarchy>("AdvertiserGroup") });
    AddStyle("BroadcastArea", { vertex: ref_BroadcastAreas, key: propertyOf<IMediaModel>("BroadcastArea") });
    AddStyle("Media", { vertex: ref_Media, key: propertyOf<IMediaModel>("Media") });
    AddStyle("Support", { vertex: ref_Supports, key: propertyOf<IMediaModel>("Support") });
    AddStyle("AdvCompany_Fin", {
        vertex: lnk_AdvertisingCompanySupport, params: {
            Roles: financialRid,
            properties: ["in", "in.Name as Name", "in.Active as Active", "Default", "Roles"]
        },
        label: "AdvCompany_Fin",
        propName: "in",
        key: propertyOf<ref_Messages>("AdvCompany_Fin")
    });
    AddStyle("AdvCompany_Com", {
        vertex: lnk_AdvertisingCompanySupport, params: {
            Roles: commercialRid,
            properties: ["in", "in.Name as Name", "in.Active as Active", "Default", "Roles"]
        },
        label: "AdvCompany_Com",
        propName: "in",
        key: propertyOf<ref_Messages>("AdvCompany_Com")
    });
    AddStyle("Currency", {
        vertex: ref_Currencies,
        params: {
            properties: ["Name", "Code", "@class"]
        },
        key: propertyOf<IMediaModel>("Currency")
    });
    if (objectType == ref_Messages.name)
        advStyles.push({ vertex: ref_Visuals, key: "ModelProperties.AdCreation" });
    return { advStyles, indicateurs };
}

export const GetSchedulerStyledPropertiesMemo = memoizeAsyncBase((objType) => GetSchedulerStyledProperties(objType));

export function SchedulerModeleCreator<T>({ objectType }: SchedulerProps<T>) {

    const selector = getSchedulerSelector(objectType.name);
    const template = clone(useSelector(selector, compareObjects));
    const dispatch = useDispatch();
    const [state, setState] = useState(new SchedulerModeleCreatorState());
    const { vertecesToMap, indicateurs } = state;
    const labelBuilders: string[] = propertiesOf<ref_SchedulerConfigurations>("LabelBuildTop", "LabelBuild", "LabelBuildBottom");

    React.useEffect(() => {
        if (!state.indicateurs?.length)
            (async () => {
                const { advStyles, indicateurs } = await GetSchedulerStyledPropertiesMemo(objectType.name);
                const templateStored = clone(getScheduler(objectType.name));
                try {
                    // Handle legacy labels
                    // Conversion from propertyBuilder to indicateurBuilder
                    if (templateStored) {
                        const convertToIndicateurBuilder = (builders: ref_SchedulerConfigurations['LabelBuild']) => {
                            if (!builders?.some(b => b.Type == "property")) return builders;
                            return builders.map(b => {
                                if (b.Type == "property") {
                                    const indicateur = indicateurs.find(i => i.field == b.Value && !i.options);
                                    if (indicateur) return indicateurToOptions(indicateur);
                                }
                                return b;
                            });
                        }

                        if (templateStored.StartLabel) templateStored.StartLabel = convertToIndicateurBuilder([templateStored.StartLabel])[0];
                        if (templateStored.EndLabel) templateStored.EndLabel = convertToIndicateurBuilder([templateStored.EndLabel])[0];
                        templateStored.LabelBuild = convertToIndicateurBuilder(templateStored?.LabelBuild);
                        templateStored.LabelBuildTop = convertToIndicateurBuilder(templateStored?.LabelBuildTop);
                        templateStored.LabelBuildBottom = convertToIndicateurBuilder(templateStored?.LabelBuildBottom);
                    }
                } catch (error) {
                    console.error(error);
                }

                setState({
                    indicateurs,
                    vertecesToMap: advStyles,
                });
            })();
    })

    const generateAdvancedStyles = (barProperty?: string) => {
        if (barProperty)
            return vertecesToMap.filter(s => s.key != barProperty);
        return vertecesToMap;
    }

    return <>
        {/** label builds */}
        {indicateurs?.length
            && <>
                <LabelLinesCreator
                    key={`LabelLinesCreator_${template?.['@rid']}`} // LabelLinesCreator should be converted to a functional component to avoid this
                    indicateurs={indicateurs}
                    template={template}
                    lines={labelBuilders}
                    propertyConfigs={vertecesToMap ?? []}
                    validate={(temp) => {
                        dispatch(setScheduler(temp));
                    }}
                    onChange={(labels, startLabel, endLabel, barProperty) => {
                        template.LabelBuildTop = labels["LabelBuildTop"];
                        template.LabelBuild = labels["LabelBuild"];
                        template.LabelBuildBottom = labels["LabelBuildBottom"];
                        template.StartLabel = startLabel;
                        template.EndLabel = endLabel;
                        template.BarProperty = barProperty;
                        dispatch(setScheduler(template));
                    }} />

                {/** personnalisation avancée */}
                <AdvancedStyles
                    key={`AdvancedStyles_${template.BarProperty}`}
                    validate={temp => dispatch(setScheduler(temp))}
                    onResizeValue={() => {/*this.props.onResizeValue?.()*/ }}
                    template={template}
                    objectType={objectType}
                    vertecesToMap={generateAdvancedStyles(template.BarProperty) ?? []} />
            </>}
    </>
}

type AdvancedStylesArg<T> = {
    validate: (template: ref_SchedulerConfigurations) => any,
    onResizeValue?: () => void,
    vertecesToMap: {
        vertex: new () => any;
        params?: any;
        label?: string;
        propName?: string;
        key: any;
    }[],
    template: ref_SchedulerConfigurations,
    objectType: new () => T;
}

function AdvancedStyles<T>({ validate, vertecesToMap, template, objectType }: AdvancedStylesArg<T>) {

    const [selectedTab, setSelectedTab] = useState(0);
    //const [resizePeriod, setResizePeriod] = useState(SchedulerStorage.get()?.resizePeriod ?? false);
    const resizePeriod = useSelector(getSchedulerSelector(objectType.name))?.resizePeriod ?? false;
    const data = useSelector((root: RootState) => root.grid.data);

    const map = new Map<string, Set<any>>();
    vertecesToMap.forEach(v => {
        const hashes = new Set();
        map.set(v.key, hashes);
        data?.forEach(d => {
            const value = GetSubElement(d, v.key);
            if (value) hashes.add(value)
        });
    });

    const generateTabLabel = (label: string, vertexName: string, key: string) => {

        const tabSet = map.get(key);
        const tabSetHasHasTemplate = tabSet?.size > 0 && Array.from(tabSet).some(e => Boolean(template?.Style?.[e]));

        return <div className='badge-template'>
            {tabSetHasHasTemplate && <div className='badge-template-icon'><CustomBadge /></div>}
            <span>{label}</span>
        </div>;
    }

    return <>
        {template &&
            <>
                <FormControlLabel
                    className='resize-dates-scheduler-checkbox'
                    style={{ margin: 0 }}
                    control={
                        <Checkbox
                            checked={resizePeriod}
                            onChange={() => {
                                const data = clone(getScheduler(objectType.name));
                                data.resizePeriod = !resizePeriod;
                                store.dispatch(setScheduler(data));
                            }}
                            name="subTotal"
                            color="primary" />
                    }
                    label={Trad("resize_active_period")} />

                <TabStrip selected={selectedTab} className="advancedStyles" scrollable={true} onSelect={e => setSelectedTab(e.selected)}>
                    {vertecesToMap.map((e, i) =>
                        <TabStripTab
                            key={`TabStripTab-${i}`}
                            title={generateTabLabel((e.label && TradProp(e.label)) ?? Trad(e.vertex.name), e.vertex.name, e.key)}
                            {...generateTab(i)}>
                            <VertecesStyleComponent
                                vertex={e.vertex}
                                propName={e.propName}
                                params={e.params ?? {}}
                                propKey={e.key}
                                template={template}
                                onChange={() => validate(template)} />
                        </TabStripTab>)}
                </TabStrip>
            </>
        }
    </>
}

const generateTab = (index: any) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}