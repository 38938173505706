import React from 'react'

import { Trad } from 'trad-lib';
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin'
import { getIcon } from "adwone-lib/index"
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'

function LanguagesDialog(props: any) {
  const { onClose, open } = props;


  const handleClose = () => {
    onClose();
  }
  let title = <>{Trad("available_languages")}<span onClick={handleClose} style={{ float: 'right', margin: '0 5px', color: '#DDDDDD', cursor: 'pointer' }}>{getIcon("close")}</span></>
  return (
    <GenericDialog
      dialogTitle={title}
      open={open}
      handleClose={handleClose}
      dialogContent={
        <>
          Test
        </>
      }
    >

    </GenericDialog>
  );
}




export default function PopupLanguages(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <CustomButton
        Label={<span style={{ textDecoration: 'underline' }}>{Trad("available_languages")}</span>}
        style={{marginRight: 5}}
        className={"custom_btn_nostyle"}
        startIcon={getIcon("info")} 
        onClick={handleClickOpen}/>
      <LanguagesDialog open={open} onClose={handleClose} />
    </span>
  );
}