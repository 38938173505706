
export enum eRoles {
    superAdministrateur = "super-administrateur",
    administrateur = "administrateur",
    adwoneAuth = "adwoneAuth",
    manager = "manager",
    superviseur = "superviseur",
    dataManager = "dataManager",
}

export let maskRole: { [prop in eRoles]: number } = {
    [eRoles.superAdministrateur]: 1 << 0,
    [eRoles.administrateur]: 1 << 1,
    [eRoles.adwoneAuth]: 1 << 2,
    [eRoles.manager]: 1 << 3,
    [eRoles.superviseur]: 1 << 4,
    [eRoles.dataManager]: 1 << 5
}

export function HasAccess(mask: number, operators: Operator[] | Operator) {
    let ops = Array.isArray(operators) ? operators : [operators];

    for (const op of ops) {

        if (typeof op !== 'string') {
            // if one and has no access, then return false
            if (op.and) {
                if (op.and.some(a => !HasAccess(mask, a)))
                    return false;
            }

            // if one Or has access, then return true
            if (op.or) {
                if (!op.or.some(a => HasAccess(mask, a)))
                    return false;
            }
        } else {
            // Then consider others as And operators
            if (((maskRole[op] || 0) & mask) == 0)
                return false;
        }
    }

    return true;
}

export type Operator = {
    or?: Operator[],
    and?: Operator[]
} | string;