import { lnk_HasCurrency } from "hub-lib/models/orientdb/lnk_HasCurrency.bin";
import { lnk_HasCompany } from "../../models/orientdb/lnk_HasCompany.bin";
import { lnk_Mandate } from "../../models/orientdb/lnk_Mandate.bin";
import { ref_AdvertiserGroups } from "../../models/orientdb/ref_AdvertiserGroups.bin";
import { clnt_Companies } from "../client/clnt_Referentials";

export class AdvertiserGroupExtended extends ref_AdvertiserGroups {
    lnkCurrencies: lnk_HasCurrency[];
    lnkContacts : lnk_HasCompany[];
    lnkMandates : lnk_Mandate[];
    ClntData? : clnt_Companies;
}