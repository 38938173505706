import { ref_SchedulerConfigurations } from 'hub-lib/dto/client/ref_SchedulerConfigurations.bin';
import { Client } from 'hub-lib/client/client.bin';
import { clone, compareObjects } from 'hub-lib/tools.bin';
import { getSchedulerSelector } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ModeleCreatorBase } from '../../ModeleCreator/ModeleCreatorBase';
import { setScheduler } from '../../../../redux/gridConfigurationsSlice';
import { SchedulerProps } from './SchedulerConfigDialog';

export function SchedulerModelePicker<T>({ objectType }: SchedulerProps<T>) {

    const selector = getSchedulerSelector(objectType.name);
    const template = clone(useSelector(selector, compareObjects));
    const dispatch = useDispatch();

    return <ModeleCreatorBase
        disableLocalStorage
        selector={selector}
        objectType={ref_SchedulerConfigurations}
        type={objectType.name}
        modele={template}
        saveConfiguration={_template => Client.updateVertex(ref_SchedulerConfigurations.name, _template)}
        onChange={_template => dispatch(setScheduler(_template))} />
}