import { ADWColumn } from "adwone-lib";
import { ref_Publications } from "hub-lib/dto/client/ref_Publications.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { TradProp } from "trad-lib";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Template from "../Template";
import { ExternalDataFilters } from "./OffersGrid";

export function PublicationsGridComponent() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {
            const IdColumns: ADWColumn<ref_Publications>[] = [
                new ADWColumn("SupportId", "Support", ePropType.String),
                new ADWColumn("BroadcastAreaId", "BroadcastArea", ePropType.String),
                // new ADWColumn("MediaId", "Media", ePropType.String),
                // new ADWColumn("CurrencyId", "Currency", ePropType.String)
            ];

            IdColumns.forEach(c => c.width = 110);

            const columns: ADWColumn<ref_Publications>[] = [
                new ADWColumn(TradProp("Source"), "Source", ePropType.String),
                new ADWColumn(TradProp("Updated_at"), "Updated_at", ePropType.Datetime),
                new ADWColumn(TradProp("Created_at"), "Created_at", ePropType.Datetime),
                ...IdColumns
            ];

            // Publication: Date;
            // Dated: string;
            // TechnicalClosure: Date;
            // CommercialClosure: Date;
            // Reference: string;
            // Theme: string;

            const hiddenProperties: (keyof ref_Publications)[] = ["DataImported"];
            const grid = new VertexGrid<ref_Publications>({
                disableStore: true,
                objectPrototype: ref_Publications,
                order: ["Source", "Start", "End", "Media", "Support", "BroadcastArea", "Publication", "Dated", "TechnicalClosure", "CommercialClosure", "Reference", "Theme", "Updated_at", "Created_at"],
                devMode: false,
                columns,
                vertexParams: {
                    All: true,
                    Active: true,
                    properties: ["*"]
                },
                width: {
                    Reference: 100,
                    Start: 110,
                    End: 110
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return <DefaultGrid
        objectPrototype={ref_Publications}
        configurationPanelElements={ExternalDataFilters}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}

export const PublicationsGrid = Template(PublicationsGridComponent);