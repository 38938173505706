
import * as React from "react";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { Trad } from "trad-lib";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { DiscountCategoryArray } from "../DiscountCategoryArray.bin";
import { DiscountManager, InitEditableDiscountsList, getAvailableDiscountClasses, netType, netTypes } from "hub-lib/business/DiscountManager.bin";
import { GetCellTemplate } from "format-lib/index.bin";
import { compareArrays } from "hub-lib/tools.bin";
import { Grid } from "@material-ui/core";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { MenuAddDiscount } from "../discounts/MenuAddDiscount";
import Loader from "../../../layout/Loader";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../redux/store";
import { setCurrentNetType, setMessage } from "../../../../redux/messageEditorSlice";
import { GlobalAgreementComponent } from "./GlobalAgreementComponent";
import { TitleComponent } from "./TitleComponent";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory"
import { AgreementSelector } from "./Agreements/AgreementSelector";
import { DiscountModeContainer } from "./DiscountModeContainer";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { IntervalsDesactivated } from "../../Agreements/AgreementDialog";
import { DiscountGridRowTitle } from "./Rows/DiscountGridRowTitle";
import { DiscountGrid } from "./Rows/DiscountGrid";
import { DiscountGridRow } from "./Rows/DiscountGridRow";
import { RowSize } from "../../../../styles/theme";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { ref_GlobalAgreements } from "hub-lib/dto/client/ref_GlobalAgreements.bin";
import { MessageAggregator } from "hub-lib/aggregator/MessageAggregator";
import { LockedPricing } from "./LockedPricing";

export let removeCol = 27;
export let dragCol = 50;
export let colWidth = 120;
export let lastCol = 50;
export let scrollSize = 0;
export let colNumber = IntervalsDesactivated ? 3 : 4;
export let firstCol = `calc(100% - ${(colWidth * colNumber) + removeCol * 2}px)`;
export let firsColwithRank = `calc(100% - ${(colWidth * (colNumber + 1)) + removeCol * 2}px)`

let classes: ref_DiscountClasses[] = null;
let types: ref_DiscountTypes[] = null;
let categoryTypes: string[][] = null;

const getClasses = (types: rid[]) =>
    classes.filter(c => types.includes(c.DiscountType))

const template = GetCellTemplate(ePropType.Double);
export function DiscountEditor() {

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const cofo = useSelector((root: RootState) => root.messageEditor.currentNetType);

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            if (!classes && !types && !categoryTypes) {
                await InitEditableDiscountsList();
                const messageAggregator = AggregatorFactory.GetInstance().Get(ref_Messages) as MessageAggregator;
                classes = await messageAggregator.getDiscountClasses();
                types = await messageAggregator.getDiscountTypes();
                categoryTypes = await DiscountManager.getCategoryTypes();
                forceUpdate();
            }
        })
    })

    if (!classes || !types || !categoryTypes)
        return <Loader />;

    const getTabStripIndex = () =>
        Object.values(netTypes).indexOf(cofo);

    const setTabStripIndex = (index: number) =>
        store.dispatch(setCurrentNetType(Object.values(netTypes)[index]))
    //setState({ ...state, cofo: Object.values(netTypes)[index] as netType });

    return <div className='message_details_full'>

        {(RightManager.hasRight(ref_Agreements.name, eRights.read) || RightManager.hasRight(ref_GlobalAgreements.name, eRights.read)) && <Grid container>

            {RightManager.hasRight(ref_GlobalAgreements.name, eRights.read) && <Grid item xs={6} className="message_details_leftcombo">
                <LockedPricing>
                    <GlobalAgreementComponent />
                </LockedPricing>
            </Grid>}

            {RightManager.hasRight(ref_Agreements.name, eRights.read) && <Grid className="message_details_rightcombo" item xs={6}>
                <Grid container direction="row-reverse">
                    <Grid item xs={12}>
                        <LockedPricing>
                            <AgreementSelector />
                        </LockedPricing>
                    </Grid>
                </Grid>
            </Grid>}
        </Grid>}

        <Grid container direction="row-reverse" style={{ position: "absolute", right: 0, paddingLeft: 10, zIndex: 10 }}>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={6} className="message_details_left">
                        <DiscountModeContainer />
                    </Grid>

                    <Grid item xs={6} className="message_details_right" style={{ position: "relative" }}>
                        <Grid container>
                            <Grid item>
                                <AddButton
                                    cofo={cofo}
                                    onAdd={(dType: ref_DiscountTypes, dClass: ref_DiscountClasses) => {
                                        const message = store.getState().messageEditor.get();
                                        const discount: ref_Discount = {
                                            ...new ref_Discount(),
                                            DiscountType: dType["@rid"],
                                            DiscountClass: dClass["@rid"],
                                            Operator: dClass.Operator as any,
                                            Rank: dClass.Rank,
                                            Value: 0,
                                            Rate: 0,
                                            IsRate: true,
                                            [cofo]: { Rate: 0, Value: 0 }
                                        };

                                        if (!message.Discounts)
                                            message.Discounts = [];
                                        message.Discounts.push(discount);

                                        store.dispatch(setMessage(message));
                                    }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <TabStrip selected={getTabStripIndex()} onSelect={e => setTabStripIndex(e.selected)} className="discount-tabstrip">

            <TabStripTab title="CO">
                {cofo == "CO" && <TabComponent />}
            </TabStripTab>

            <TabStripTab title="FO">
                {cofo == "FO" && <TabComponent />}
            </TabStripTab>

            <TabStripTab title="FOS">
                {cofo == "FOS" && <TabComponent />}
            </TabStripTab>
        </TabStrip>

    </div >;
}

type AddButtonProps = {
    cofo: netType;
    onAdd?: (dType: ref_DiscountTypes, dClass: ref_DiscountClasses) => void;
}

function AddButton({ cofo, onAdd }: AddButtonProps) {
    const message = useSelector((root: RootState) => root.messageEditor.get(), (a, b) => compareArrays(a?.Discounts?.map(d => d.DiscountClass), b?.Discounts?.map(d => d.DiscountClass)));
    return <LockedPricing style={{ position: "absolute", right: 0 }}>
        <MenuAddDiscount
            classes={getAvailableDiscountClasses(message?.Discounts, cofo, message.Media)}
            onClick={onAdd} />
    </LockedPricing>
}

function TabComponent({ }) {

    const cofo = useSelector((root: RootState) => root.messageEditor.currentNetType);
    const getNet = (m: ref_Messages, str: "Net" | "Total") =>
        DiscountManager.getNet(str, m, cofo);

    const DiscountCategoryTab = (p: { refPtr?: (r: DiscountCategoryArray) => void, base: (m: ref_Messages) => number, types: string[], title: string, baseDiscountTypes?: string[], editBase?: boolean }) => <>
        <div>
            <DiscountGridRowTitle
                label={p.title}
                valueGetter={m => template(p.base(m))}
                {...(p.editBase ? {
                    editable: true,
                    baseClasses: getClasses(p.baseDiscountTypes ?? [])
                } : {})} />

            <DiscountGrid
                filter={d => p.types.includes(d.DiscountType)} />
        </div>
    </>

    return <>
        <div className="discount-editor-array-container">
            <LockedPricing>
                <TitleComponent />

                {/** Les appels doivent rester en mode fonction sinon les focus se perdent à l'édition */}
                {DiscountCategoryTab({
                    title: Trad("base_rate"),
                    // refPtr: r => bvarray.current = r,
                    base: m => m.KPIs.Gross,
                    types: categoryTypes[0]
                })}

                {DiscountCategoryTab({
                    title: Trad("Brut Base Achat"),
                    // refPtr: r => bbaarray.current = r,
                    base: m => m.KPIs.GrossBa,
                    types: categoryTypes[1]
                })}

                {DiscountCategoryTab({
                    title: Trad("net_space"),
                    // refPtr: r => nearray.current = r,
                    base: m => getNet(m, "Net"),
                    types: categoryTypes[2],
                    baseDiscountTypes: categoryTypes[1],
                    editBase: true
                })}

                <TotalRow />
            </LockedPricing>
        </div>
        <div className="gray-font" style={{ textAlign: 'end' }}>{`* ${Trad("calculated_values")}`}</div>
    </>
}

function TotalRow() {

    const currencyCode = useSelector((root: RootState) => root.messageEditor.currencyCode) ?? "";
    const value = useSelector((root: RootState) => {
        const cofo = root.messageEditor.currentNetType
        const message = root.messageEditor.get()
        return template(DiscountManager.getNet("Total", message, cofo));
    });

    return <DiscountGridRow
        className="gray-back discountGridRowTitle"
        rowStyle={{ height: RowSize }}
        intervalCellClassName=""
        amountCellClassName=""
        labelCell={<div style={{ height: '100%', alignItems: 'center', display: 'flex' }}><span>Total</span></div>}
        intervalCell={<span className="discountGridRowTitle_cell">{value + ` ${currencyCode ?? ''}`}</span>} />
}


