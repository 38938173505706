import './tradInitialisation';
import './configuration.bin';
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./index.css";
import App from "./App.bin";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";
import './config.bin'
import { AlertComponent } from './components/AlertComponent.bin';
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-NSVMCM5'
// }


// const tagManagerArgs = {
//   gtmId: 'GTM-MB84J3BH'
// }

// TagManager.initialize(tagManagerArgs)
ReactDOM.render(
  <div>
    <Router history={history}>
      <App />

    </Router>
    <AlertComponent />
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
