import { Client } from 'hub-lib/client/client.bin';
import { SerializeError } from 'hub-lib/tools.bin';
import * as React from 'react'

export function createErrorBoundary(Jsxelement: any) {
  class ErrorBoundaryEncapsultated extends React.Component<{}, any> {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
      Client.log({
        Category: "crash_front",
        Informations: {
          Error: SerializeError(error),
          errorInfo: SerializeError(errorInfo)
        }
      }).catch(e => console.error(e))
    }

    render() {
      if (this.state.hasError) {
        // Vous pouvez afficher n'importe quelle UI de repli.
        return <h5>Something went wrong.</h5>;
      }

      return <Jsxelement />
    }
  }

  return <ErrorBoundaryEncapsultated />
}

export class ErrorBoundary extends React.Component<{}, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
    Client.log({
      Category: "crash_front",
      Informations: {
        Error: SerializeError(error),
        errorInfo: SerializeError(errorInfo)
      }
    }).catch(e => console.error(e))
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de repli.
      return <h5>Something went wrong.</h5>;
    }

    return this.props.children;
  }
}