import * as React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export enum eDialogMode {
    create = "create",
    modify = "modify",
    duplicate = "duplicate"
}

export class GenericDialogProps {
    id?: string;
    handleClose?: () => void;
    open: boolean;
    style?: React.CSSProperties;
    titleClass?: string;
    dialogTitle: any;
    iconsTitle?: JSX.Element;
    submitAction?: () => void;
    submitTitle?: string;
    submitClass?: string;
    startIcon?: any;
    dialogContent?: JSX.Element | string;
    children?: any;
    actions?: boolean;
    disableCancel?: boolean;
    additionalButtons?: JSX.Element | JSX.Element[];
    cancelAction?: any;
    disablePrimaryButton?: boolean;
    disableCancelButton?: boolean;
    beforeButton?: JSX.Element;
    closable?: boolean;
    dialogProps?: Partial<DialogProps>;
    dialogContainerStyle?: React.CSSProperties;
    dialogClassname?: string;
}

export function BasicGenericDialog({
    id,
    handleClose,
    open,
    style,
    titleClass,
    dialogTitle,
    closable,
    iconsTitle,
    dialogContent,
    children,
    actions,
    additionalButtons,
    beforeButton,
    dialogProps,
    dialogContainerStyle,
    dialogClassname
}: GenericDialogProps) {

    return <Dialog
        disableEscapeKeyDown
        scroll="body"
        PaperProps={{ className: "overflow-visible paper-container", id }}
        onClose={(e, reason) => {
            if (reason != "backdropClick")
                handleClose();
        }}
        open={open}
        maxWidth={'lg'}
        style={style ?? {}}
        aria-labelledby="dialog_title"
        {...dialogProps}
        disableEnforceFocus={true}
    >

        <DialogTitle id="dialog_title" className={`${titleClass ?? 'dialog_title'}`}>
            {dialogTitle}
            {closable && <span style={{ float: "right", color: "black", cursor: "pointer", marginLeft: 10, fontWeight: 300 }} onClick={() => handleClose()}>x</span>}
            {iconsTitle}
        </DialogTitle>

        <DialogContent className={`DialogContent-container ${dialogClassname}`} style={dialogContainerStyle ?? {}}>
            {dialogContent ?? children}
        </DialogContent>
        {actions &&
            <DialogActions className="dialog_actions">
                {beforeButton}
                {additionalButtons}
            </DialogActions>
        }
    </Dialog>
}