import qs from "qs";
import { clone } from "../tools.bin";
import { ClientAXIOS, Config, Resp } from "./axios.bin";

const UserKey = () => "User-" + JSON.parse(localStorage.getItem('user'))?.["@rid"];

type RespToken = { access_token: string; refresh_token: string };

export class ClientOAUT2 extends ClientAXIOS {
    client_id: string;
    client_secret: string;
    access_token: string;
    refresh_token: string;
    private urlTokenBase: string | (() => string);
    private redirectBase: string | (() => string);
    oautConfig: Config;

    get urlToken() {
        return typeof this.urlTokenBase === "function" ? this.urlTokenBase() : this.urlTokenBase;
    }

    get redirect_uri() {
        return typeof this.redirectBase === "function" ? this.redirectBase() : this.redirectBase;
    }

    constructor(
        client_id: string,
        client_secret: string,
        urlBase: string | (() => string),
        urlTokenBase: string | (() => string),
        redirectBase: string | (() => string),
        config?: {
            oaut?: Config,
            client?: Config,
        }
    ) {
        super(urlBase, config?.client);
        this.client_id = client_id;
        this.client_secret = client_secret;
        this.urlTokenBase = urlTokenBase;
        this.redirectBase = redirectBase;
        this.oautConfig = {
            withCredentials: true,
            ...(config?.oaut ?? {}),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                ...(config?.oaut?.headers ?? {}),
            },
        };
        this.initilize();
    };

    protected async initilizeToken() {
        if (typeof localStorage !== "undefined") {
            this.access_token = localStorage.getItem(this.getStorageKey('access_token'));
            this.refresh_token = localStorage.getItem(this.getStorageKey('refresh_token'));
        }
    }

    protected async updateToken() {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(this.getStorageKey("access_token"), this.access_token);
            localStorage.setItem(this.getStorageKey("refresh_token"), this.refresh_token);
        }
    }

    protected async removeToken() {
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem(this.getStorageKey('access_token'));
            localStorage.removeItem(this.getStorageKey('refresh_token'));
        }
    }

    protected async initilize() {
        await this.initilizeToken();

        if (this.access_token)
            this.setHeaderAuthorization();
    };

    getStorageKey(key: string) {
        if (this.client_id === "hub-website") {
            return key;
        }
        return `${UserKey()}_${this.client_id.slice(0, 5)}_${key}`;
    }

    async logout() {
        this.access_token = null;
        this.refresh_token = null;
        await this.removeToken();
        if (this.defaultAxiosConfig?.headers?.["Authorization"]) {
            delete this.defaultAxiosConfig.headers["Authorization"];
        }
    }

    private setHeaderAuthorization() {
        if (!this.defaultAxiosConfig.headers) {
            this.defaultAxiosConfig.headers = {};
        }
        this.defaultAxiosConfig.headers["Authorization"] = `Bearer ${this.access_token}`;
    }

    async getRefreshToken(): Promise<any> {
        const body = {
            client_id: this.client_id,
            client_secret: this.client_secret,
            grant_type: "refresh_token",
            refresh_token: this.refresh_token,
        };
        const resp = await super.execPost<RespToken>(
            this.urlToken,
            qs.stringify(body),
            this.oautConfig
        );
        return this.handleNewToken(resp);
    }

    async getToken(code: string, additionalBody?: any) {
        let body = {
            client_id: this.client_id,
            client_secret: this.client_secret,
            redirect_uri: this.redirect_uri,
            grant_type: "authorization_code",
            code: code,
            ...(additionalBody ?? {})
        };
        const resp = await super.execPost<RespToken>(
            this.urlToken,
            qs.stringify(body),
            this.oautConfig
        );
        return this.handleNewToken(resp);
    }

    async getTokenClientCredentials(): Promise<any> {
        let body = {
            client_id: this.client_id,
            client_secret: this.client_secret,
            grant_type: "client_credentials",
        };
        const resp = await super.execPost<RespToken>(
            this.urlToken,
            qs.stringify(body),
            this.oautConfig
        );
        return this.handleNewToken(resp);
    }

    private handleNewToken(resp: Resp<RespToken>) {
        this.access_token = resp.data.access_token;
        this.refresh_token = resp.data.refresh_token;

        this.updateToken();

        this.setHeaderAuthorization();
        return resp;
    };

    protected onGetRefreshTokenError(err: any, args) {

    }

    protected onRequestError(err: any,args) {

    }

    private async checkRefreshToken(
        req: () => Promise<any>
    ) {
        try {
            const resp = await req();
            return resp;
        } catch (err) {

            console.log('err?.response?.data', err?.response?.data)

            if (err?.response?.data?.error == "invalid_token" || err?.response?.data?.error?.message == "Access token has been revoked") { //TODO
                return this.getRefreshToken()
                    .then(() => req())
            }

            //this.onRequestError(err);
            console.log(err?.response?.data);
            throw err;
        }
    };

    public async isAuth(): Promise<boolean> {
        if (!this.access_token) {
            return false;
        }
        return true;
    }

    protected async execPost<T = any>(url: string, body: any, config?: Config): Promise<Resp<T>> {
        return this.checkRefreshToken(() => super.execPost(url, body, config));
    }

    protected async execPostBase<T = any>(url: string, body: any, config?: Config): Promise<Resp<T>> {
        return super.execPostSecure(url, body, config);
    }

    protected async execPut<T = any>(url: string, body: any, config?: Config): Promise<Resp<T>> {
        return this.checkRefreshToken(() => super.execPut(url, body, config));
    }

    protected async execGet<T = any>(url: string, config?: Config): Promise<Resp<T>> {
        return this.checkRefreshToken(() => super.execGet(url, config));
    }

    protected async execDelete<T = any>(
        url: string,
        config?: Config,
        data = undefined
    ): Promise<Resp<T>> {
        return this.checkRefreshToken(
            () => super.execDelete(url, config, data)
        );
    }
}
