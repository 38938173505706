import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { duplicate } from 'hub-lib/tools.bin';


export interface ConnectedState {
    users: 
    {
        '@rid': string;
        name: string;
        connected: boolean;
    }[]
}

const initialState: ConnectedState = {
    users: 
        {
            '@rid': undefined,
            name: undefined,
            connected: undefined
        }['']
    ,
}

export const connectedStatusSlice = createSlice({
    name: 'connectedUsers',
    initialState,
    reducers: {
        setConnectedUsers: (state, action: PayloadAction<ConnectedState>) => {
            state.users = action.payload.users;
        },
    },
})

export const { setConnectedUsers } = connectedStatusSlice.actions

export const connectedStatusReducer = connectedStatusSlice.reducer;