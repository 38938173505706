
import { EventEmitter } from 'events';

export let eventAdded: string = 'added';
export let eventDeleted: string = 'deleted';
export let eventSelected: string = 'selected';
export let eventContentChanged: string = 'contentChanged';

export let eventTabManagerChanged: string = 'tabManagerChanged';
export let TabManagerEvent: EventEmitter = new EventEmitter();

export class TabManager {

    id: string;

    onTabChanged: EventEmitter = new EventEmitter();

    selectedTab: ITab;
    tabs: ITab[] = [];
    autoId: number = 0;

    initMode: boolean;

    constructor(id: string) {
        this.id = id;
        this.onTabChanged.addListener(eventAdded, this.notifyChanged);
        this.onTabChanged.addListener(eventDeleted, this.notifyChanged);
        this.onTabChanged.addListener(eventSelected, this.notifyChanged);
        this.onTabChanged.addListener(eventContentChanged, this.notifyChanged);
    }

    notifyChanged = () => {
        if (!this.initMode)
            TabManagerEvent.emit(eventTabManagerChanged, this);
    }

    addTab(tab: ITab) {

        tab.id = this.autoId++;
        tab.onTabChanged = () => {
            this.onTabChanged.emit(eventContentChanged)
        }

        if (!this.initMode && this.tabs.length == 0)
            this.selectedTab = tab;
        if (this.tabs.length < 5) {
            this.tabs.push(tab);
            if (!this.initMode)
                this.selectedTab = tab
            this.onTabChanged.emit(eventAdded, [tab]);
        }
    }

    deleteTab(tab: ITab) {
        if (this.tabs.length > 1) {
            if (tab == this.selectedTab) {
                let newIndex = this.tabs.indexOf(tab);
                this.tabs = this.tabs.filter(t => t != tab);
                if (newIndex >= this.tabs.length)
                    newIndex = this.tabs.length - 1;
                this.selectedTab = this.tabs[newIndex];
            } else {
                this.tabs = this.tabs.filter(t => t != tab);
            }

            this.onTabChanged.emit(eventDeleted, tab);
        }
    }

    select(tab: ITab) {
        this.selectedTab = tab;
        this.onTabChanged.emit(eventSelected, tab);
    }

}

export interface ITab {
    id: any;
    name: string;
    getComponent: () => JSX.Element;
    save: () => void;
    onTabChanged: () => void;
}

export abstract class ATab {

    id: any;
    name: string;

    onTabChanged: () => void;

    abstract getComponent: () => JSX.Element;
    abstract save: () => void;
}