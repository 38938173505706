import { Trad } from 'trad-lib';
import './password.scss';

export function PasswordComplexity({ password }) {
    return <div>
        <div className="password-complexity">
            <div className={password.length >= 10 ? 'password-complexity_valid' : 'password-complexity_invalid'}>{Trad('password_weak_min_chars')}</div>
            <div className={password.match(/[a-z]/) ? 'password-complexity_valid' : 'password-complexity_invalid'}>{Trad('password_weak_lowercase')}</div>
            <div className={password.match(/[A-Z]/) ? 'password-complexity_valid' : 'password-complexity_invalid'}>{Trad('password_weak_uppercase')}</div>
            <div className={password.match(/[0-9]/) ? 'password-complexity_valid' : 'password-complexity_invalid'}>{Trad('password_weak_number')}</div>
            <div className={password.match(/[^a-zA-Z0-9]/) ? 'password-complexity_valid' : 'password-complexity_invalid'}>{Trad('password_weak_specialchar')}</div>
        </div>
    </div>

}