import * as React from "react";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { NumericTextBox, NumericTextBoxHandle } from "@progress/kendo-react-inputs";
import { GridCellProps } from '@progress/kendo-react-grid';
import { DiscountCategoryArray } from "../DiscountCategoryArray.bin";
import { GetCurrentLocale } from "trad-lib";
import { AgreementValueComparer } from "./AgreementValueComparer";

export type CellPercentPropsType = GridCellProps & { editable: boolean, ptr: DiscountCategoryArray, itemChange: (event: { dataItem: any, value: any, field?: string, endEdit?: boolean }) => any };
export function CellPercentComponent({ dataItem, field, ptr, editable, itemChange }: CellPercentPropsType) {

    const [isEditing, setIsEditing] = React.useState(false);
    const ref = React.createRef<NumericTextBoxHandle>();

    React.useEffect(() => {
        if (isEditing) {
            ref.current.focus();
            ref.current.element.select();
        }
    }, [isEditing])

    const discount = dataItem as ref_Discount;
    const key: keyof ref_Discount = field as any;

    const value = (dataItem[key] as number) * 100;
    let valid = true;
    const validator = ptr?.props?.validator?.[key];
    if (validator) {
        const newDiscount = ptr.toDiscount(dataItem);
        const discounts = ptr.state.gridData
            .filter(d => !d.inEdit)
            .map(ptr.toDiscount);

        valid = validator(newDiscount, discounts, ptr.props.type)?.valid;
    }
    if (editable && (dataItem.inEdit || isEditing)) {
        let id = `${dataItem["@rid"]}-${key}`;
        return (
            <td colSpan={1}
                className="k-grid-edit-cell"
                onKeyDown={e => {
                    if (e.code == "Enter" || e.code == "NumpadEnter") {
                        itemChange({ dataItem, value: dataItem[key], field: key, endEdit: true })
                        setIsEditing(false)
                    }
                }}>
                <NumericTextBox
                    ref={ref}
                    format="n8"
                    onBlur={() => {
                        itemChange({ dataItem, value: dataItem[key], field: key, endEdit: true })
                        setIsEditing(false)
                    }}
                    className={'CellNumberComponent-editing-cell'}
                    id={id}
                    spinners={true}
                    min={0}
                    max={100}
                    step={0.01}
                    value={value}
                    valid={valid}
                    onChange={(e) => itemChange({ dataItem, value: e.value / 100, field: key })} />
            </td>);
    }

    const template = (_value) => _value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 })

    let className = "";
    let star = false;
    if (!discount.IsRate) {
        star = true;
        className = "gray-font";
    }

    const isEditingMessage = Boolean(ptr?.props?.message);
    return <td className={className + " k-grid-edit-cell"} style={{ textAlign: 'right', position: 'relative' }}
        onClick={() => {
            if (!editable)
                return;
            setIsEditing(true);
        }}>

        {isEditingMessage
            && <AgreementValueComparer
                type="rate"
                discount={discount}
                formater={template} />}

        {star
            && <div className="star_cell">*</div>}
        {template(value) + '%'}
    </td>
}
