
import { TextField } from '@material-ui/core';
import { KPIsManagerCache, lnk_HasPropertyTypeExtended, ValueChoicesTypeSort } from 'hub-lib/models/KPIsManager.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { GetHashCode, propertyOf } from 'hub-lib/tools.bin';
import * as React from 'react'
import { useSelector } from 'react-redux';
import { Trad, TradProp, TradValue } from 'trad-lib';
import { GetEditorState, RootState, SetModelSync, store } from '../../../../redux/store';
import { AdwAutocomplete } from "adwone-lib/index";
import { changeEndValue } from './DiffusionEditor';
import { Checkbox } from '@progress/kendo-react-inputs';
import { UpdateOJDData } from 'hub-lib/business/ojd';

type ModelPropertiesEditorArgs = { property: string, label?: string, model: "ref_Messages" | "ref_Campaigns" }
export function ModelPropertiesChoicesEditor({ property, model, label }: ModelPropertiesEditorArgs) {

    const propertyValue = useSelector((root: RootState) => GetEditorState(model, root).data.ModelProperties?.[property]);
    const [valueChoices, setValueChoices] = React.useState<lnk_HasPropertyTypeExtended["ValueChoices"]>();
    const [booleanValues, setBooleanValues] = React.useState<{ [key: string]: boolean }>(null);

    React.useEffect(() => {
        if (!valueChoices) {
            Promise.resolve().then(async () => {
                const data = GetEditorState(model, store.getState()).get();
                const properties: lnk_HasPropertyTypeExtended[] = await KPIsManagerCache.GetInstance(model).GetLnkHasProperties(data.Media);

                const _valueChoices = properties?.find(p => p.Id == property).ValueChoices;
                if (_valueChoices?.length == 2) {
                    const _boolValues = {};

                    _valueChoices.forEach(v => {
                        const tradYes = TradValue(v["@class"], "Name", "Yes");
                        const tradNo = TradValue(v["@class"], "Name", "No");
                        if (tradYes == v.value)
                            _boolValues[v.rid] = true;
                        if (tradNo == v.value)
                            _boolValues[v.rid] = false;
                    })
                    if (Object.keys(_boolValues).length == 2)
                        setBooleanValues(_boolValues);
                }
                setValueChoices(_valueChoices.sort(ValueChoicesTypeSort));
            })
        }
    })

    const value = valueChoices?.find(v => v.rid == propertyValue);
    const labelInput = TradProp(`${propertyOf<ref_Messages>("ModelProperties")}.${property}` as any, ref_Messages);

    if (booleanValues) {
        const trueValue = Object.keys(booleanValues).find(k => booleanValues[k] == true);
        return <Checkbox label={labelInput}
            checked={propertyValue == trueValue}
            onChange={async (event) => {
                const data = GetEditorState(model, store.getState()).get();
                if (!data.ModelProperties)
                    data.ModelProperties = {};
                data.ModelProperties[property] = Object.keys(booleanValues).find(k => booleanValues[k] == event.value);
                SetModelSync(model, data);
            }} />
    }
    return <AdwAutocomplete
        key={`${value}-${GetHashCode(valueChoices)}`}
        loading={!valueChoices}
        disabled={!valueChoices}
        options={valueChoices ?? []}
        getOptionLabel={(v => {
            if (typeof v == "string") return v;
            return v["@class"] ? TradValue(v["@class"], "Name", v.value) : v.value
        })}
        value={value ?? (property == "OjdYear" ? Trad("editor_source") : null)}
        onChange={async (event: any, value: any) => {
            const data = GetEditorState(model, store.getState()).get();
            if (!data.ModelProperties)
                data.ModelProperties = {};
            data.ModelProperties[property] = value?.rid;
            console.log("ModelPropertiesChoicesEditor", property, value?.rid);
            if (property == "Periodicity")
                await changeEndValue(model, data);
            if (property == "OjdYear")
                await UpdateOJDData(data, true);
            if (property == "AdCreation") {
                if (data.ModelProperties[property] != "#-1:-1")
                    data.ModelProperties["AdCreationOther"] = null;
                else
                    data.ModelProperties["AdCreationOther"] = value?.value;
            }
            SetModelSync(model, data);
        }}
        renderInput={(params: any) => <TextField {...params} variant="outlined" label={labelInput} />}
    />
}