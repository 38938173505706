import React from 'react'
import Template from '../Template'
import { connect } from 'react-redux';
import { selectProject } from '../../../redux/projectSlice';
import history from "../../../utils/history";
import { ReleaseVersionGrid } from '../../VertexGrid/Messages/ReleaseVersionGrid.bin';

class ReleaseVersion extends React.Component<any,any> {
    componentDidMount() {
        const projectRid = new URLSearchParams(window.location.search).get("project");
        if (projectRid) {
            this.props.selectProject(projectRid);
            history.replace("/version")
        }
    }
    render() {
        return (
            <>
                <div className="grid_container">
                    <ReleaseVersionGrid/>
                </div>
            </>
        )
    }
}

export default connect(null, { selectProject })(Template(ReleaseVersion));