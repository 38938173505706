import { Client } from 'hub-lib/client/client.bin'
import { IRid } from 'hub-lib/models/IRid.bin'
import { ReferentialHasViews } from 'hub-lib/models/orientdb/ReferentialHasViews.bin'
import { GetHashCode, memoizeAsyncBase } from 'hub-lib/tools.bin'
import * as React from 'react'
import { Trad } from 'trad-lib'
import { GetSrcMMUser, GetUser } from '../../../../utils/localstorage.bin'
import { src_MM } from 'hub-lib/models/orientdb/src_MM.bin'
import { src_TSM } from 'hub-lib/models/orientdb/src_TSM.bin'

type WarningMMProps = {
    cross?: boolean,
    children?: any,
    ids: {
        id: IRid['@rid'],
        linkClass: string
    }[],
    hasErrors?: (errors: string[], ids: WarningMMProps['ids']) => boolean;
}

type WarningMMState = {
    errors: string[];
    ids: WarningMMProps['ids'];
}

const getLinks = memoizeAsyncBase((linkClass: string, params: any) => Client.searchVertex(linkClass, params).then(res => res.data.results));

export function WarningMM({ children, ids, hasErrors, cross }: WarningMMProps) {

    const [state, setState] = React.useState<WarningMMState>(undefined);

    React.useEffect(() => {
        const source = GetSrcMMUser();
        if (source && GetHashCode(state?.ids) != GetHashCode(ids)) {
            if (!ids.length || ids.some(e => !e.id)) return setState(undefined);
            Promise.resolve().then(async () => {
                let outElements: IRid['@rid'][];
                const errors: string[] = [];
                for (const { id, linkClass } of ids) {

                    let sourceType = src_MM.name;
                    if (linkClass.includes('_tsm_'))
                        sourceType = src_TSM.name;

                    const params = {
                        '@class': linkClass,
                        Referential: id,
                        in: GetUser()?.sources?.find(s => s["@class"] == sourceType)?.['@rid'],
                        ...(outElements ? { out: outElements } : {})
                    };
                    const res = await getLinks(linkClass, params);
                    if (Boolean(!res?.map(e => e.out)?.length))
                        errors.push(linkClass);
                    if (cross)
                        outElements = res?.map?.(e => e?.out)?.filter?.(e => e);
                }

                setState({ errors, ids });
            });
        }
    })

    const errors = state?.errors;
    const errored = state && (hasErrors?.(errors, ids) ?? !(errors?.length < ids.length));
    const className = errored ? 'warning-element' : '';

    return <div className={className}>
        {errored && <div className='WarningMM-legend'>{Trad(`mm_link_missing`)}</div>}
        {children}
    </div>
}