
export * from "./ADWGrid";
export * from "./ADWGridComponent";

import * as ADWGridImp from "./ADWGrid";
import * as ADWGridComponentImp from "./ADWGridComponent";

export class AdwGrid {
    static ADWGrid = ADWGridImp;
    static ADWGridComponent = ADWGridComponentImp;
}