
import * as React from 'react'
import moment from 'moment';

import Week from './Week.bin'
import Events from './Events.bin'
import { Trad } from 'trad-lib';


import { Client } from 'hub-lib/client/client.bin'
import { DateZone } from 'tools-lib';
import { ref_CalendarEvent } from 'hub-lib/dto/client/ref_CalendarEvent';

class TState {
    selectedMonth: moment.Moment = moment();
    selectedDay: moment.Moment = moment().startOf("day");
    selectedMonthEvents: {
        title: string;
        description: string;
        category: string;
        date: moment.Moment;
        end: moment.Moment;
        allDay: boolean;
    }[] = [];
    showEvents: boolean = false;
}

export default class Calendar extends React.Component<any, TState> {
    constructor(props: any) {
        super(props);

        let newState = new TState()
        this.state = newState


    }
    componentDidMount() {
        this.initialiseEvents();
    }
    goToCurrentMonthView = () => {
        this.setState({
            selectedMonth: moment(),
            selectedDay: moment().startOf("day")
        });
    }
    previous = () => {
        const currentMonthView = this.state.selectedMonth;

        this.setState({
            selectedMonth: currentMonthView.subtract(1, "month")
        });
    }

    next = () => {
        const currentMonthView = this.state.selectedMonth;
        this.setState({
            selectedMonth: currentMonthView.add(1, "month")
        });
    }

    select = (day: any) => {
        this.setState({
            selectedMonth: day.date,
            selectedDay: day.date.clone(),
            showEvents: true
        });
    }
    renderMonthLabel = () => {
        const currentMonthView = this.state.selectedMonth;
        return (
            <span className="box month-label">
                {currentMonthView.format("MMMM YYYY")}
            </span>
        );
    }

    renderTodayLabel = () => {
        return (
            <span className="box today-label" onClick={this.goToCurrentMonthView}>
                {Trad("today")}
            </span>
        );
    }
    renderDayLabel = () => {
        const currentSelectedDay = this.state.selectedDay;
        return (
            <span className="box month-label">
                {Trad("eventsof")} {currentSelectedDay.format("DD/MM/YYYY")}
            </span>
        );
    }
    renderWeeks = () => {
        const currentMonthView = this.state.selectedMonth;
        const currentSelectedDay = this.state.selectedDay;
        const monthEvents = this.state.selectedMonthEvents;

        let weeks = [];
        let done = false;
        let previousCurrentNextView = currentMonthView
            .clone()
            .startOf("month")
            .subtract(1, "d")
            .day(Trad("monday"));
        let count = 0;
        let monthIndex = previousCurrentNextView.month();

        while (!done) {
            weeks.push(
                <Week
                    key={`${currentMonthView}${currentSelectedDay}${count}`}
                    previousCurrentNextView={previousCurrentNextView.clone()}
                    currentMonthView={currentMonthView}
                    monthEvents={monthEvents}
                    selected={currentSelectedDay}
                    select={(day: any) => this.select(day)}
                />
            );
            previousCurrentNextView.add(1, "w");
            done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
            monthIndex = previousCurrentNextView.month();
        }
        return weeks;
    }

    initialiseEvents = () => {
        const allEvents: TState['selectedMonthEvents'] = [];
        Client.searchVertexTyped(ref_CalendarEvent, { Active: true }).then(calendarEvents => {
            calendarEvents.forEach(e => {
                const title = e.Name
                const description = e.Description
                const category = e.Category
                const allDay = e.IsAllDay
                const date = moment(e.Start)
                let end = moment(e.End)
                if (moment(end).diff(date, "day") === 1 && allDay) {
                    end = date
                    end.set({
                        hour: 23,
                        minute: 59,
                        second: 59
                    })
                }

                if (moment(end).diff(date, "day") > 1)
                    end.subtract(1, 'day')
                allEvents.push({ title, description, category, date, end, allDay })
            })

            this.setState({ selectedMonthEvents: allEvents });
        }).catch(e => { })
    }

    render() {
        const currentMonthView = this.state.selectedMonth;
        const currentSelectedDay = this.state.selectedDay;
        return (
            <div>
                <div className="calendar-rectangle" style={{ marginBottom: 20 }}>
                    <div id="calendar-content" className="calendar-content">
                        <section className="main-calendar">
                            <header className="calendar-header">
                                <div className="row title-header">
                                    <i
                                        className="box arrow fa fa-angle-left"
                                        onClick={this.previous}
                                    />
                                    <div className="box header-text">
                                        {this.renderTodayLabel()}
                                        {this.renderMonthLabel()}
                                    </div>
                                    <i className="box arrow fa fa-angle-right" onClick={this.next} />
                                </div>

                            </header>
                            <div className="days-container">
                                <div><DayNames /></div>
                                {this.renderWeeks()}
                                <CategoryNames />
                            </div>
                        </section>
                    </div>
                </div>
                <section className="events-calendar">
                    <header className="calendar-header">
                        <div className="row title-header">
                            {this.renderDayLabel()}
                        </div>
                    </header>
                    <Events
                        selectedMonth={currentMonthView}
                        selectedDay={currentSelectedDay}
                        selectedMonthEvents={this.state.selectedMonthEvents}
                    />
                </section>
            </div>
        )
    }
}


class DayNames extends React.Component {
    render() {
        return (
            <div className="row days-header">
                <span className="box day-name">{Trad("mon")}</span>
                <span className="box day-name">{Trad("tue")}</span>
                <span className="box day-name">{Trad("wed")}</span>
                <span className="box day-name">{Trad("thu")}</span>
                <span className="box day-name">{Trad("fri")}</span>
                <span className="box day-name">{Trad("sat")}</span>
                <span className="box day-name">{Trad("sun")}</span>
            </div>
        );
    }
}

class CategoryNames extends React.Component {
    render() {
        return (
            <ul className="list_Categories">
                <li className="list_Category web">{Trad("webinars")}</li>
                <li className="list_Category sv">{Trad("survey_data")}</li>
                <li className="list_Category mkt">{Trad("market")}</li>
            </ul>
        );
    }
}
