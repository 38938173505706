import React from 'react';
import { getIcon } from "adwone-lib/index"
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { Grid } from '@material-ui/core';
import { Trad } from 'trad-lib';
import { CustomIconButton } from '../Generic/CustomIconButton';
import { Switch } from '@progress/kendo-react-inputs';
import { Format } from 'format-lib/index.bin';

export function EditGridActions(arg: any, customCommandCellFunction: (row: any, changeStatus?: boolean) => void) {
  return function (props) {

    const [state, setState] = React.useState({ open: false })
    const { open } = state;
    const { dataItem: row } = props;
    const { dataItem: element } = row;

    return (
      <td className="k-grid-content-sticky k-command-cell add-border-left" style={{ right: 0, display: 'flex', justifyContent: "center", alignItems: "center" }}>
        <Switch
          size='small'
          checked={element.Active}
          name={Trad("status")}
          onChange={() => setState({ open: true })} />

        <CustomIconButton style={{ padding: 10 }} className="primary_color" aria-label="edit" onClick={() => {
          customCommandCellFunction(row)
        }}>
          {getIcon("edit")}
        </CustomIconButton>
        <GenericDialog
          open={open}
          dialogTitle={element.Active ? Trad("set_inactive") : Trad("set_active")}
          actions
          submitClass={"custom_btn_danger"}
          submitTitle={element.Active ? Trad("set_inactive") : Trad("set_active")}
          cancelAction={() => setState({ open: false })}
          submitAction={() => {
            setState({ open: false });
            customCommandCellFunction(row, true);
          }}
          dialogContent={
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                {element.Active ? Trad("set_inactive") : Trad("set_active")} {`${Format(element)}`}
              </Grid>
            </Grid>
          }
        />
      </td>
    );
  }
}