import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import React, { useState } from "react";

type BaseProps = {
    children: React.ReactNode,
    objectType: string,
}

type KeyValidatorBaseProps = {
    validate: (metadataProperties: string[], metadataKPIs: string[]) => boolean
} & BaseProps

export function KeyValidatorBase({ children, objectType, validate }: KeyValidatorBaseProps) {
    const [metadataProperties, setMetadataProperties] = useState<string[]>(null);
    const [metadataKPIs, setMetadataKPIs] = useState<string[]>(null);

    React.useEffect(() => {
        KPIsManagerCache.GetInstance(objectType).GetUserProperties().then(meta => {
            setMetadataProperties(meta.map(p => p.name));
        });
        KPIsManagerCache.GetInstance(objectType).GetUserKPIs().then(kpi => {
            setMetadataKPIs(kpi.map(p => p.Name));
        });
    }, [objectType]);

    if (!metadataProperties || !metadataKPIs || !validate(metadataProperties, metadataKPIs))
        return <></>;

    return <>{children}</>;
}

type KeyValidatorProps = { propertyName: string, notExists?: boolean } & BaseProps
export function KeyValidator({ children, objectType, propertyName, notExists }: KeyValidatorProps) {
    const propertyNames = [propertyName, propertyName + 's']; // Trick, for ref_Agreements
    const validate: KeyValidatorBaseProps['validate'] = (metadataProperties: string[], metadataKPIs: string[]) => {
        const includeProp = propertyNames.some(p => metadataProperties.includes(p) || metadataKPIs.includes(p));
        const notExistsBoolean = Boolean(notExists);
        return includeProp != notExistsBoolean;
    }

    return <KeyValidatorBase objectType={objectType} validate={validate}>
        {children}
    </KeyValidatorBase>;
}

type MultiKeysValidatorProps = { propertyNames: string[], options?: { atLeastOne?: boolean } } & BaseProps
export function MultiKeysValidator({ children, objectType, propertyNames, options }: MultiKeysValidatorProps) {

    const validate: KeyValidatorBaseProps['validate'] = (metadataProperties: string[], metadataKPIs: string[]) => {
        const includeProps = propertyNames.map(p => metadataProperties.includes(p) || metadataKPIs.includes(p));
        const atLeastOne = options?.atLeastOne;
        if (atLeastOne)
            return includeProps.includes(true);
        return includeProps.every(Boolean);
    }

    return <KeyValidatorBase objectType={objectType} validate={validate}>
        {children}
    </KeyValidatorBase>;
}