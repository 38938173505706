import * as React from 'react'
import { Trad } from 'trad-lib';
import { CustomButton } from '../../../ConfigurableComponents/CustomButton.bin';
import { getIcon } from "adwone-lib/index";
import { ref_Publications } from 'hub-lib/dto/client/ref_Publications.bin';
import { SameDayDates } from 'hub-lib/tools.bin';
import { FormatDate } from 'tools-lib';

export class TPros {
    day: Date;
    events: ref_Publications[];
    applyPublication: (value: ref_Publications, date: Date) => void;
}

const AdwRowLegend = ({ label, value }: { label: string, value: string }) =>
    <div className='legend' style={{ height: `20%` }}>
        <label>{label}</label>
        <div>{value}</div>
    </div>

export default function Events({ day, events, applyPublication }: TPros) {

    const monthEventsRendered = events
        .filter(event => SameDayDates(event.Publication, day))
        .map((event, i) =>
            <div key={`event-${i}`} className="event-container" style={{ height: '100%' }} >
                <div style={{ textAlign: "left", paddingTop: 2 }}>
                    <AdwRowLegend label={Trad("broadcasting_date")} value={FormatDate(event?.Publication)} />
                    <AdwRowLegend label={Trad("property_Dated")} value={event?.Dated} />
                    <AdwRowLegend label={Trad("number")} value={event?.Reference} />
                    <AdwRowLegend label={Trad("property_EditorialTheme")} value={event?.Theme?.trim?.()} />
                    <AdwRowLegend label={Trad("source")} value={Trad(event?.Source)} />
                </div>
                <CustomButton className="custom_btn_primary legend-box-action" Label={Trad("apply")} startIcon={getIcon("arrow_down")}
                    onClick={() => applyPublication(event, event.Publication)} />
            </div>);

    return <div className="day-events" style={{ height: '100%' }}>
        {monthEventsRendered}
        {monthEventsRendered.length === 0 &&
            <div className="event-container">
                <span className="no-events">{Trad("no_publication_available")}</span>
                <CustomButton className="custom_btn_primary legend-box-action" Label={Trad("apply")} startIcon={getIcon("arrow_down")}
                    onClick={() => applyPublication(null, day)} />
            </div>
        }
    </div>
}