import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { clone, compareObjects, JSONEqualityComparer, propertyOf } from "hub-lib/tools.bin";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Trad } from "trad-lib";
import { RootState, store } from "../../../../redux/store";
import { CellNumberComponent } from "../discounts/CellNumberComponent.bin";
import { CellPercentComponent } from "../discounts/CellPercentComponent.bin";
import { colWidth, removeCol } from "./DiscountEditor.bin";
import { LockedPricing } from "./LockedPricing";

export type BarterGridProps = {
    classes: ref_DiscountClasses[];
    //discounts: ref_Discount[];
    onChange?: (percents: ref_Discount[]) => void;
}



export function BarterGrid({ classes, onChange }: BarterGridProps) {
    const base = useSelector((root: RootState) => root.messageEditor.get().KPIs?.Barter);
    const barterPercents = useSelector((root: RootState) => root.messageEditor.get().BarterPercents, JSONEqualityComparer);

    const computeItems = React.useCallback((event?: any) => {
        const barterPercents = clone(store.getState().messageEditor.data.BarterPercents);
        const copy = clone(barterPercents)
        if (event) {
            let elmnt = barterPercents.find(d => d.DiscountClass === event.dataItem.DiscountClass);
            if (elmnt && event.field == "Rate") {
                elmnt[event.field] = event.value;
                let other = barterPercents.find(d => d.DiscountClass !== event.dataItem.DiscountClass);
                other[event.field] = 1 - event.value;
                elmnt.IsRate = true;
                other.IsRate = true;
            }

            if (elmnt && event.field == "Value") {
                elmnt[event.field] = event.value;
                let other = barterPercents.find(d => d.DiscountClass !== event.dataItem.DiscountClass);
                other[event.field] = base - elmnt[event.field];
                elmnt.IsRate = false;
                other.IsRate = false;
            }
        }
        if (barterPercents) {
            for (const d of barterPercents) {
                if (d.IsRate)
                    d.Value = d.Rate * base;
                else {
                    if (base != 0)
                        d.Rate = d.Value / base;
                    else
                        d.Rate = 0;
                }
            }
        }
        if (!compareObjects(copy, barterPercents)) {
            onChange(barterPercents);
        }
    }, [onChange])

    const cellPercent = React.useCallback((props: GridCellProps) => <CellPercentComponent {...props} editable={true} ptr={null} itemChange={computeItems} />, [computeItems]);
    const cellNumberPrice = (props: any) => <CellNumberComponent {...props} ptr={null} editable={true} opt={{ type: ePropType.Double, min: 0, max: base }} currency={null} itemChange={computeItems} />;

    if (!base)
        return <></>;
    computeItems();
    return <div className="discount-editor-array-container barter-container">
        <LockedPricing>
            <Grid
                className={"DiscountCategoryArray"}
                data={barterPercents}>
                <GridColumn cell={(props: GridCellProps) => {
                    const c = classes.find(c => c["@rid"] == (props.dataItem as ref_Discount).DiscountClass);
                    return <td>{Trad(c?.Name)}</td>
                }} />
                <GridColumn field={propertyOf<ref_Discount>('Rate')} width={colWidth} editor="numeric" cell={cellPercent} />
                <GridColumn field={propertyOf<ref_Discount>('Value')} width={colWidth} editor="numeric" cell={cellNumberPrice} />
                <GridColumn width={removeCol * 2} cell={(props: GridCellProps) => { return <td></td> }} />
            </Grid>
        </LockedPricing>
    </div>
}