import * as React from 'react'
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ToArrayObject, extractSub, toArray, compareObjects } from 'hub-lib/tools.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { AdvertiserHierarchyComponent } from '../../AdvertiserHierarchyComponent/AdvertiserHierarchyComponent';
import { GetEditorState, RootState, SetModel, SetModelSync, store } from '../../../../redux/store';
import { StoreCreationCampaigns } from '../../AdvertiserHierarchyComponent/StoreFilters';
import { AdvertisersGroupsPicker } from '../../../AdvertisersGroupsPicker/AdvertisersGroupsPicker';

type HierarchyAdvertiserPickerProps = {
    model: "ref_Campaigns" | "ref_Messages";
    multiAdvertisers: boolean;
    estimatedMode: boolean;
    onChange: () => void;
};

export function HierarchyAdvertiserPicker({ model, multiAdvertisers, estimatedMode, onChange }: HierarchyAdvertiserPickerProps) {

    const data = useSelector((root: RootState) => GetEditorState(model, root).get(), compareObjects);

    const HasChanged = (newData: ref_Campaigns | ref_Messages) => {
        return data["@rid"] &&
            (newData.AdvertiserGroup !== data.AdvertiserGroup || newData.Advertiser !== data.Advertiser);
    }
    return <>
        {!multiAdvertisers &&
            <AdvertiserHierarchyComponent
                objectName={model}
                multiSelection={false}
                lockedKeys={estimatedMode ? ["AdvertiserGroup", "Advertiser", "Brand", "Product"] : []}
                applyFilters
                reduxStore={StoreCreationCampaigns}
                store={ToArrayObject(data)}
                onChange={newData => {
                    /*
                                        const hasEstimates = (campaign?.Estimates || []).filter(e => e.ExternalID !== null)?.length > 0;
                                        if (hasEstimates && (Campaign.AdvertiserGroup !== toArray(newCampaign.AdvertiserGroup)?.[0] ||
                                            Campaign.Advertiser !== toArray(newCampaign.Advertiser)?.[0] ||
                                            Campaign.Brand !== toArray(newCampaign.Brand)?.[0] ||
                                            Campaign.Product !== toArray(newCampaign.Product)?.[0])) {
                                            state.selected = 0;
                                            state.CreateEstimate = false;
                                            state.UpdateEstimate = true;
                                            Notify(Trad("warning_delete_estimates"), "warning");
                                            Campaign.Estimates = [];
                                        }*/
                    const storeData = GetEditorState(model, store.getState()).get();
                    storeData.AdvertiserGroup = toArray(newData.AdvertiserGroup)?.[0];
                    storeData.Advertiser = toArray(newData.Advertiser)?.[0];
                    storeData.Brand = toArray(newData.Brand)?.[0];
                    storeData.Product = toArray(newData.Product)?.[0];
                    const hasChanged = HasChanged(storeData);
                    SetModel(model, storeData);
                    if (hasChanged)
                        onChange();
                }} />}

        {multiAdvertisers && <Grid item xs={6} style={{ marginBottom: 20 }}>
            <AdvertisersGroupsPicker
                onChange={group => {
                    const storeData = GetEditorState(model, store.getState()).get();
                    storeData.Group = group;
                    SetModelSync(model, storeData);
                }}
                defaultValue={data?.Group} />
        </Grid>}
    </>
}