import * as React from 'react'
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { Trad, TradClassName, TradProp, TradValue } from 'trad-lib';
import { Grid } from '@material-ui/core';
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { CustomButton } from '../../../ConfigurableComponents/CustomButton.bin';
import { HierarchyComboManager } from '../../HierarchyComponent/HierarchyComponent.bin';
import { ref_Brands } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { VertexAutocomplete } from "adwone-lib/index";
import { IRid } from 'hub-lib/models/IRid.bin';
import { eDialogMode, GenericDialog } from '../../../ConfigurableComponents/GenericDialog.bin';
import { AdressForm } from './AdressForm';
import { CompanyInfosForm } from './CompanyInfosForm';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { Client } from 'hub-lib/client/client.bin';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { useEffect, useState } from 'react';
import { lnk_Hierarchy } from "hub-lib/models/orientdb/lnk_Hierarchy.bin";
import { Loader } from '@progress/kendo-react-indicators';
import { AdwTelerikGrid } from '../../Generic/AdwTelerikGrid.bin';
import { VertexGrid } from '../../Generic/VertexGrid.bin';
import { lnk_HasBroadcastArea } from 'hub-lib/models/orientdb/lnk_HasBroadcastArea.bin';
import { AdwRow, eComputeEvent } from "adwone-lib/index";
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin';
import { CustomTextField, CustomTextFieldUpper } from '../../Generic/CustomTextField';
import { HierarchyReferential, ReferentialElement } from '../../../layout/SuperAdmin/Referential.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { DateNoZone } from 'tools-lib';
import { lnk_HasCompany } from 'hub-lib/models/orientdb/lnk_HasCompany.bin';
import { ref_Persons } from 'hub-lib/models/orientdb/ref_Persons.bin';
import { styleGridContainer } from '../../../../styles/theme';
import { lnk_AdvertisingCompanySupport } from 'hub-lib/models/orientdb/lnk_AdvertisingCompanySupport.bin';
import { lnk_HasCurrency } from 'hub-lib/models/orientdb/lnk_HasCurrency.bin';
import { ref_AdvertisingCompanyRole } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin';
import { createLinkGrid, DefaultStartDate, GetParentClass, NewElement, searchHierarchy } from './ReferentialTools';
import { distinct, duplicate, GetHashCode, groupBy, propertyOf, Typed } from 'hub-lib/tools.bin';
import { ref_Periodicity } from 'hub-lib/models/orientdb/ref_Periodicity.bin';
import { Notify } from '../../../../utils/Notify.bin';
import { ref_PropertyType } from 'hub-lib/models/orientdb/ref_PropertyType.bin';
import { getIcon } from "adwone-lib/index";
import { Referentials } from 'hub-lib/models/orientdb/Referentials.bin';
import { ConsoleDebug } from '../../../../utils/localstorage.bin';
import { ContactForm } from './ContactForm';
import { AdvertiserHierarchy } from 'hub-lib/dto/client/ref_FilterConfigurations.bin';
import { TreeListRefNode } from './ReferentialTreeList';
import { Checkbox } from '@progress/kendo-react-inputs';
import { lnk_Mandate } from 'hub-lib/models/orientdb/lnk_Mandate.bin';
import { ref_AgencyGroups } from 'hub-lib/models/orientdb/ref_AgencyGroups.bin';
import { ref_Agencies } from 'hub-lib/models/orientdb/ref_Agencies.bin';
import { WebsiteTextBox } from './WebsiteTextBox';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { Redirect } from 'react-router';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_PersonExtended } from 'hub-lib/dto/referential/ref_PersonExtended';
import { ClntAdvertiserForm, ClntSupportForm } from './ClntReferentialForm';
import { clnt_Referentials } from 'hub-lib/dto/client/clnt_Referentials';
import { ContainerComponent } from '../../Generic/ContainerComponent';
import { TextEditor } from '../../TextEditor.bin';
import { SimilarityChecker } from './SimilarityChecker';

class TProps {
    defaultValues?: any;
    mode: eDialogMode;
    parentClass?: string;
    selectedItem?: any;
    selectedParent?: string;
    selectedVertex: string;
    hierarchyClassName?: string[];
    extendedProperties?: string[];
    onCancel?: () => void;
    onSave: (e: ReferentialElement, mode: eDialogMode) => void;
    onDelete?: (e: ReferentialElement) => void;
}

let _onEditBroadcastAreas: { [key: string]: lnk_HasBroadcastArea[] } = {};

function RemoveLinkDetails({ item }) {
    const [State, setState] = useState<{
        messages: number
    }>(undefined);

    useEffect(() => {
        if (!State) {
            if (item.dataItem["@rid"]) {
                const link = item.dataItem;

                if (link["@class"] == lnk_AdvertisingCompanySupport.name) {
                    const filter = [{ class: ref_Supports.name, value: item.dataItem["out"], typeForced: true },
                    { class: ref_AdvertisingCompanies.name, value: item.dataItem["in"] }]
                    Client.countVertex(ref_Messages.name, { filter, distinct }).then(res => {
                        setState({ messages: res.data.results });
                        return;
                    });
                }
            }
            setState({ messages: 0 });
        }
    })

    return <>
        <div>Nombre de messages : {State?.messages}</div>
    </>
}

export function ReferentialForm(props: TProps) {
    _onEditBroadcastAreas = {};

    const clntForm = props.extendedProperties != null;

    const [state, setStateBase] = useState<{
        Loading: boolean,
        RefElement: ReferentialElement,
        Parent: IRid,
        AdvertiserConfig: AdvertiserHierarchy,
        Roles: ref_AdvertisingCompanyRole[],
        MediaPress: string,
        IsMediaPress: boolean,
        AdvComGrid: VertexGrid<lnk_AdvertisingCompanySupport>,
        AdvFinGrid: VertexGrid<lnk_AdvertisingCompanySupport>,
        BroadcastAreaGrid: VertexGrid<lnk_HasBroadcastArea>,
        ContactGrid: VertexGrid<lnk_HasCompany>,
        CurrencyGrid: VertexGrid<lnk_HasCurrency>,
        MandateGrid: VertexGrid<lnk_Mandate>,
        SupportGrid: VertexGrid<lnk_AdvertisingCompanySupport>,
        NewClassName: string,
        DisableActions: boolean,
        PropertyTypes: ref_PropertyType[],
        EditedElement: Referentials,
        ExceptParentRids: string[],
        LinkedPropertyTypes: string[]

    }>({ Loading: true } as any);

    const setState = (val: typeof state) => {
        if (GetHashCode(val) != GetHashCode(state)) {
            if (state.RefElement && val.RefElement != state.RefElement) {
                state.AdvComGrid = undefined;
                state.AdvFinGrid = undefined;
                state.CurrencyGrid = undefined;
                state.BroadcastAreaGrid = undefined;
            }
            setStateBase(val);
        }
    }

    const {
        Loading,
        RefElement,
        Parent,
        AdvertiserConfig,
        Roles,
        MediaPress,
        IsMediaPress,
        AdvComGrid,
        AdvFinGrid,
        BroadcastAreaGrid,
        ContactGrid,
        CurrencyGrid,
        MandateGrid,
        SupportGrid,
        NewClassName,
        EditedElement,
        DisableActions,
        PropertyTypes,
        ExceptParentRids,
        LinkedPropertyTypes
    } = state;


    const getParent = async (rid: string, recurse: boolean = false) => {
        if (rid) {
            const params = { "@rid": rid, options: { lnkCurrencies: true }, properties: ["*", ...props.extendedProperties ?? []] };
            if (recurse)
                params["parent"] = true;
            const parent = await searchHierarchy(props.parentClass, params);

            if (parent?.["lnkCurrencies"]?.length || !parent?.["parent"])
                setState({ ...state, Parent: parent });
            else if (parent["parent"])
                getParent(parent["parent"], recurse);
        }
    }

    const fillParent = (element: ReferentialElement, config: AdvertiserHierarchy) => {
        if (props.selectedVertex === ref_Brands.name)
            (RefElement as any).parent = config.Advertiser;
        if (props.selectedVertex === ref_Products.name)
            (RefElement as any).parent = config.Brand;
    }

    const configureElement = (element: any, parent: string) => {
        if (parent && !element.parent) {
            element.parent = [parent];
        }
        if (props.selectedVertex != ref_PersonExtended.name)
            element.Start = element.Start ?? DefaultStartDate;
        if (props.mode !== eDialogMode.modify)
            element.Active = true;
        if (props.mode == eDialogMode.duplicate) {
            element.Name = `${element.Name}(${Trad("copy")})`;
            for (const lnk of ["lnkAdvertisingCompanies", "lnkCurrencies", "lnkBroadcastAreas", "lnkContacts"])
                if (element[lnk])
                    element[lnk].forEach(e => {
                        delete e["@rid"];
                        if (e["in"] == element["@rid"])
                            e["in"] = undefined;
                        if (e["out"] == element["@rid"])
                            e["out"] = undefined;
                    });
        }

        if (props.selectedVertex == ref_Supports.name) {
            if (!element["Medias"])
                element["Medias"] = [];
            if (!element["lnkAdvertisingCompanies"])
                element["lnkAdvertisingCompanies"] = []
            if (!element["lnkCurrencies"])
                element["lnkCurrencies"] = []
            if (props.mode !== eDialogMode.create)
                loadAdvBroadcastArea(distinct(element.lnkAdvertisingCompanies?.map(adv => adv.in), e => e));
        }
        if (PropertyTypes) {
            element["PropertyType"] = PropertyTypes.map(t => t["@rid"]);
            if (!element["Medias"])
                element["Medias"] = [];
        }
        if (!element["lnkBroadcastAreas"] && [ref_AdvertisingCompanies.name, ref_Supports.name].includes(props.selectedVertex))
            element["lnkBroadcastAreas"] = [];
        if (!element["lnkContacts"] && NewElement(props.selectedVertex) instanceof ref_Companies)
            element["lnkContacts"] = [];

        const hierarchy = new AdvertiserHierarchy();
        let childrenRids: string[] = [];
        if (props.selectedVertex === ref_Brands.name)
            hierarchy.Advertiser = element.parent;
        if (props.selectedVertex === ref_Products.name)
            hierarchy.Brand = element.parent;
        if (props.selectedVertex != ref_Brands.name && props.selectedVertex != ref_Products.name && Array.isArray(element.parent))
            element.parent = element.parent[0];
        if (props.selectedVertex === ref_Advertisers.name || props.selectedVertex === ref_BroadcastAreas.name)
            getParent(parent, props.selectedVertex === ref_BroadcastAreas.name);
        if (props.selectedVertex == props.parentClass && props.mode == eDialogMode.modify)
            getNodeIds(props.selectedItem?.Children, childrenRids);

        setState({ ...state, RefElement: element, ExceptParentRids: childrenRids, AdvertiserConfig: (hierarchy.Advertiser || hierarchy.Brand) ? hierarchy : undefined });
    }

    const getNodeIds = (nodes: TreeListRefNode[], rids: string[]) => {
        if (nodes && nodes.length) {
            for (let i = 0; i < nodes.length; i++) {
                rids.push(nodes[i].id);
                getNodeIds(nodes[i].Children, rids);
            }
        }
    }

    const getStoreDataAdvertisingCompanyGrid = (grid: VertexGrid<lnk_AdvertisingCompanySupport>, row: AdwRow<lnk_AdvertisingCompanySupport>, field: string, data: any) => {
        if (field === "DefaultBroadcastArea" && row["in"]) {
            if (_onEditBroadcastAreas[row["in"]]) {
                const broadcastRids = distinct(_onEditBroadcastAreas[row["in"]].map(lnk => lnk.out), id => id);
                data = data.filter(d => broadcastRids.includes(d["@rid"]));
            }
            else
                data = [];
        }
        return data;
    }

    useEffect(() => {

        if (!PropertyTypes && (props.selectedVertex == "Emplacement" || props.selectedVertex == "Format")) {
            const propertyTypes: string[] = [props.selectedVertex];
            const _linkedPropertyTypes = [];
            if (props.selectedVertex == "Emplacement") {
                propertyTypes.push("BroadcastPlacement");
                _linkedPropertyTypes.push("Majoration");
                //_linkedPropertyTypes.push("Rubrique");
            }
            else {
                _linkedPropertyTypes.push("Couleur");
                _linkedPropertyTypes.push("Implantation");
            }
            Client.searchVertexTyped(ref_PropertyType, { "Type": propertyTypes }).then(res => setState({ ...state, PropertyTypes: res, LinkedPropertyTypes: _linkedPropertyTypes }))
            return;
        }
        if (['Format', 'Emplacement', ref_Supports.name].includes(props.selectedVertex) && !MediaPress) {
            Client.searchVertexTyped(ref_Media, { Code: "PR" }).then(res => setState({ ...state, MediaPress: res?.[0]?.["@rid"] }));
            return
        }
        if ((props.selectedVertex == ref_Supports.name || props.selectedVertex == ref_AdvertisingCompanies.name) && !Roles) {
            Client.searchVertexTyped(ref_AdvertisingCompanyRole).then(res => setState({ ...state, Roles: res }));
            return;
        }

        if (!RefElement && props.mode == eDialogMode.create) {
            const newElement = NewElement(props.selectedVertex);
            Object.entries(props.defaultValues ?? {}).forEach(([k, v]) => newElement[k] = v);

            configureElement(newElement, props.selectedParent);
            return;
        }

        if (!RefElement && props.selectedItem && props.mode !== eDialogMode.create) {
            ConsoleDebug(`call searchHierarchy`, props);
            searchHierarchy(props.selectedVertex, { "@rid": props.selectedItem["@rid"], Active: [true, false], "properties": ["*", "out(\"lnk_Hierarchy\") as parent", ...props.extendedProperties ?? []] })
                .then(res => {
                    // Fix pour ref_PersonExtended, car le search vertex le retransformait en ref_persons
                    if (props.selectedItem.Contact)
                        res["Contact"] = props.selectedItem.Contact;
                    ConsoleDebug(`call configureElement`, res, props.selectedItem);
                    if (!res["Siret"]) {
                        res["Siret"] = props.selectedItem.Siret;
                    }
                    configureElement(res, props.selectedItem.parent);
                });
            return;
        }

        if (Loading && RefElement) {
            const _state = duplicate(state);
            if (!AdvComGrid && props.selectedVertex == ref_Supports.name) {
                let comRole = Roles.find(r => r.Name == "Commercial")?.['@rid'];
                const rows = (RefElement["lnkAdvertisingCompanies"] as lnk_AdvertisingCompanySupport[])?.filter(l => l.Roles?.includes(comRole));
                let comGrid = createLinkGrid<ReferentialElement, lnk_AdvertisingCompanySupport>(lnk_AdvertisingCompanySupport, "lnkAdvertisingCompanies", () => _state.RefElement, props.mode, "out", { Roles: [comRole] },
                    { hiddenProperties: ["Roles"], rows });
                Promise.resolve().then(async () => comGrid.store["DefaultBroadcastArea"] = await Client.searchVertexTyped(ref_BroadcastAreas));
                comGrid.onChange = onChangeAdvertisingCompany;
                const getStoredDataFunc = comGrid.GetStoredData.bind(comGrid);
                comGrid.GetStoredData = async (row: AdwRow<lnk_AdvertisingCompanySupport>, field: string) => {
                    const value = await getStoredDataFunc(row, field);
                    return getStoreDataAdvertisingCompanyGrid(comGrid, row, field, value);
                };
                _state.AdvComGrid = comGrid;
            }
            if (!AdvFinGrid && props.selectedVertex == ref_Supports.name) {
                let finRole = Roles.find(r => r.Name == "Financial")?.['@rid'];
                const rows = (RefElement["lnkAdvertisingCompanies"] as lnk_AdvertisingCompanySupport[])?.filter(l => l.Roles?.includes(finRole));
                let finGrid = createLinkGrid<ReferentialElement, lnk_AdvertisingCompanySupport>(lnk_AdvertisingCompanySupport, "lnkAdvertisingCompanies", () => _state.RefElement, props.mode, "out", { Roles: [finRole] },
                    { hiddenProperties: ["Roles"], rows });
                Promise.resolve().then(async () => finGrid.store["DefaultBroadcastArea"] = await Client.searchVertexTyped(ref_BroadcastAreas));
                finGrid.onChange = onChangeAdvertisingCompany;
                const getStoredDataFunc = finGrid.GetStoredData.bind(finGrid);
                finGrid.GetStoredData = async (row: AdwRow<lnk_AdvertisingCompanySupport>, field: string) => {
                    const value = await getStoredDataFunc(row, field);
                    return getStoreDataAdvertisingCompanyGrid(finGrid, row, field, value);
                };
                _state.AdvFinGrid = finGrid;
            }
            if (!SupportGrid && props.selectedVertex == ref_AdvertisingCompanies.name) {
                const rows = (RefElement["lnkSupports"] as lnk_AdvertisingCompanySupport[]) ?? [];
                let supportGrid = createLinkGrid<ReferentialElement, lnk_AdvertisingCompanySupport>(lnk_AdvertisingCompanySupport, "lnkSupports", () => _state.RefElement, props.mode, "in", {}, { rows });
                //Promise.resolve().then(async () => supportGrid.store["DefaultBroadcastArea"] = await Client.searchVertexTyped(ref_BroadcastAreas));

                _state.SupportGrid = supportGrid;
            }
            if (!BroadcastAreaGrid && [ref_AdvertisingCompanies.name, ref_Supports.name].includes(props.selectedVertex)) {
                const gridProps = {};
                if (props.selectedVertex == ref_Supports.name)
                    gridProps["hiddenProperties"] = ["DefaultCurrency"];
                const broadcastAreaGrid = createLinkGrid<ReferentialElement, lnk_HasBroadcastArea>(lnk_HasBroadcastArea, "lnkBroadcastAreas", () => _state.RefElement, props.mode, "in", {}, gridProps);
                _state.BroadcastAreaGrid = broadcastAreaGrid;
            }
            if (!CurrencyGrid && props.selectedVertex == ref_Supports.name) {
                let currencyGrid = createLinkGrid<ReferentialElement, lnk_HasCurrency>(lnk_HasCurrency, "lnkCurrencies", () => _state.RefElement, props.mode, "in", {},
                    { rows: RefElement["lnkCurrencies"] });
                _state.CurrencyGrid = currencyGrid;
            }
            if (!MandateGrid && [ref_AdvertiserGroups.name, ref_Advertisers.name, ref_AgencyGroups.name, ref_Agencies.name].includes(props.selectedVertex)) {
                const gridProps = { hiddenProperties: ["Roles", "Media"] };
                let direction: "in" | "out" = "in";
                if ([ref_AdvertiserGroups.name, ref_Advertisers.name].includes(props.selectedVertex))
                    direction = "out";
                const mandateGrid = createLinkGrid<ReferentialElement, lnk_Mandate>(lnk_Mandate, "lnkMandates", () => _state.RefElement, props.mode, direction, {},
                    gridProps);
                _state.MandateGrid = mandateGrid;
            }
            if (!ContactGrid && NewElement(props.selectedVertex) instanceof ref_Companies) {
                const contactGrid = createLinkGrid<ReferentialElement, lnk_HasCompany>(lnk_HasCompany, "lnkContacts", () => _state.RefElement, props.mode, "in",
                    { properties: ["*", "out.FirstName as FirstName", "out.LastName as LastName", "Contact.Email as Email"] },
                    {
                        order: [
                            propertyOf<lnk_HasCompany>("Start"),
                            propertyOf<lnk_HasCompany>("End")
                        ]
                    });
                _state.ContactGrid = contactGrid;
            }
            if (props.mode == eDialogMode.duplicate)
                delete RefElement['@rid'];

            if (['Format', 'Emplacement', ref_Supports.name].includes(props.selectedVertex))
                _state.IsMediaPress = RefElement["Medias"].includes(MediaPress);

            if (props.selectedVertex != ref_Supports.name || (_state.AdvComGrid && _state.AdvFinGrid && _state.BroadcastAreaGrid && _state.CurrencyGrid))
                _state.Loading = false;

            setState(_state);
        }
    })

    const loadAdvBroadcastArea = async (advRids: string[]) => {
        if (advRids.length > 0) {
            const links = groupBy(await Client.searchVertexTyped(lnk_HasBroadcastArea, { in: advRids, "Active": true }), l => l.in);
            advRids?.forEach(rid => _onEditBroadcastAreas[rid] = links[rid] ?? []);
        }
    }

    const onChangeAdvertisingCompany = async (row: AdwRow<lnk_AdvertisingCompanySupport>, field: string) => {
        if (field == "in") {
            if (!_onEditBroadcastAreas[row["in"]])
                await loadAdvBroadcastArea([row["in"]]);
            row["DefaultBroadcastArea"] = _onEditBroadcastAreas[row["in"]].find(lnk => lnk.Default)?.out;
            if (!row["DefaultBroadcastArea"] && _onEditBroadcastAreas[row["in"]].length == 1)
                row["DefaultBroadcastArea"] = _onEditBroadcastAreas[row["in"]][0].out;
            row.event.emit(eComputeEvent.forceUpdate, { field: "DefaultBroadcastArea" });
        }
    }

    const Save = async (element: ReferentialElement) => {
        Notify(Trad(`${props.mode}_in_progress`), "info");
        setState({ ...state, DisableActions: true });
        try {
            let refElement = { ...element, Name: element.Name?.trim() };
            if (props.extendedProperties) {
                let clntElement = element["ClntData"] as clnt_Referentials;
                clntElement.Referential = element['@rid'];
                if (clntElement['@rid'])
                    await Client.updateVertex(clnt_Referentials.name, clntElement);
                else
                    clntElement = (await Client.createVertex(clnt_Referentials.name, clntElement)).data.results;
            }
            else {
                LinkedPropertyTypes?.forEach(type => delete refElement[`${type}_values`]);
                if (props.mode == eDialogMode.modify)
                    await Client.updateVertex(NewElement(props.selectedVertex).constructor.name, refElement);
                else
                    refElement = (await Client.createVertex(NewElement(props.selectedVertex).constructor.name, refElement)).data.results;
            }
            setStateBase({ ...state, DisableActions: false });
            props?.onSave(refElement, props.mode);
        }
        catch (e) {
            setStateBase({ ...state, DisableActions: false });
            console.error(e)
        }
    }

    const onSaveDialogGrid = (referential: ReferentialElement, className: string, action: "create" | "edit") => {
        setState({ ...state, NewClassName: undefined, EditedElement: undefined });
        if (className == ref_AdvertisingCompanies.name) {
            AdvComGrid.InitializeColumns();
            AdvFinGrid.InitializeColumns();
        }
        if (className == ref_Currencies.name)
            CurrencyGrid.InitializeColumns();
        if (className == ref_BroadcastAreas.name)
            BroadcastAreaGrid.InitializeColumns();

        if (className == ref_PersonExtended.name) {

            // const personExt = referential as unknown as ref_PersonExtended;
            // RefElement["lnkContacts"].push({
            //     ...new lnk_HasCompany(),
            //     "@class": lnk_HasCompany.name,
            //     Start: DefaultStartDate,
            //     in: RefElement['@rid'],
            //     out: personExt['@rid'],
            //     Contact: personExt.Contact['@rid']
            // });

            // ContactGrid.AddRows([{
            //     ...new lnk_HasCompany(),
            //     "@class": lnk_HasCompany.name,
            //     Start: DefaultStartDate,
            //     Active: true,
            //     in: RefElement['@rid'],
            //     out: personExt['@rid'],
            //     FirstName: personExt.FirstName,
            //     LastName: personExt.LastName,
            //     Email: personExt.Contact?.Email,
            //     Contact: personExt.Contact?.['@rid']
            // } as any]);
            // ConsoleDebug(`Adding (ref_PersonExtended): `, referential, "to", RefElement);

            ContactGrid.InitializeColumns();
        }
    }

    if (Loading)
        return <Loader />

    ConsoleDebug(`RefElement`, RefElement);
    ConsoleDebug(`ExceptParentRids`, ExceptParentRids);
    const hasCover = props?.selectedVertex === ref_Supports.name && props?.selectedItem?.coverUrl;
console.log('props',props)
    return (
        <>
            <Grid container className='adw-form-fields-container'>

                {hasCover && <img src={hasCover} />}

                <Grid container style={{
                    width: (hasCover ? "calc(100% - 205px)" : "100%"),
                    height: "fit-content",
                    marginLeft: "auto"
                }}>

                    {/** Name, Code, Label */}
                    {![ref_Currencies.name, ref_PersonExtended.name].includes(props.selectedVertex) &&
                        <Grid item xs={12} className="message_details_full">
                            <span style={{ display: 'flex', marginRight: 5, alignItems: 'center' }}>
                                <CustomTextFieldUpper
                                    disabled={clntForm}
                                    value={RefElement.Name}
                                    label={`${Trad("Name")} *`}
                                    onChange={val => RefElement.Name = val} />
                                <div style={{ marginRight: 5 }} />
                                <SimilarityChecker type={props.selectedVertex} name={() => RefElement.Name} />
                            </span>
                        </Grid>
                    }
                    {(props.selectedVertex === ref_PersonExtended.name || props.selectedVertex === ref_Persons.name) &&
                        <>
                            <Grid item xs={6} className="message_details_leftcombo">
                                <CustomTextField
                                    value={RefElement["FirstName"]}
                                    label={`${TradProp("FirstName", ref_Persons)} *`}
                                    onChange={value => RefElement["FirstName"] = value}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>
                            <Grid item xs={6} className="message_details_rightcombo">
                                <CustomTextField
                                    value={RefElement["LastName"]}
                                    label={`${TradProp("LastName", ref_Persons)} *`}
                                    onChange={value => RefElement["LastName"] = value}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>

                            {(props.selectedVertex === ref_PersonExtended.name) && <>
                                <ContactForm element={RefElement as unknown as ref_PersonExtended} />
                            </>}
                        </>
                    }
                    {props.selectedVertex === ref_Currencies.name &&
                        <>
                            <Grid item xs={3} className="message_details_leftcombo">
                                <CustomTextField
                                    value={RefElement.Name}
                                    disabled={clntForm}
                                    label={`${TradProp("Name", ref_Currencies)} *`}
                                    placeholder={Trad("example_short_label")}
                                    onChange={value => RefElement.Name = value}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>
                            <Grid item xs={3} className="message_details_leftcombo">
                                <CustomTextField
                                    value={RefElement["Code"]}
                                    disabled={clntForm}
                                    label={`${TradProp("Code", ref_Currencies)} *`}
                                    placeholder={Trad("example_symbol")}
                                    onChange={value => RefElement["Code"] = value}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>
                            <Grid item xs={6} className="message_details_rightcombo">
                                <CustomTextField
                                    value={RefElement["Label"]}
                                    disabled={clntForm}
                                    label={`${TradProp("Label", ref_Currencies)} *`}
                                    placeholder={Trad("example_long_label")}
                                    onChange={value => RefElement["Label"] = value}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </Grid>
                        </>
                    }
                    {/** Period */}
                    {!clntForm && props.selectedVertex !== ref_PersonExtended.name &&
                        <>
                            <Grid item xs={6} className="message_details_leftcombo">
                                <SimpleDatePicker label={`${Trad("start_valid")} *`} disabled={clntForm} defaultValue={RefElement.Start ? DateNoZone(RefElement.Start) : null} onChange={(event) => { RefElement.Start = event.value; }} />
                            </Grid>
                            <Grid item xs={6} className="message_details_rightcombo">
                                <SimpleDatePicker label={Trad("end_valid")} disabled={clntForm} defaultValue={RefElement.End ? DateNoZone(RefElement.End) : null} onChange={(event) => { RefElement.End = event.value; }} resetDate={() => { RefElement.End = null; }} />
                            </Grid>
                        </>
                    }
                    {/** Company form infos */}
                    {!clntForm && NewElement(props.selectedVertex) instanceof ref_Companies &&
                        <>
                            <AdressForm element={RefElement as ref_Companies} disabled={clntForm} />
                            {/** AdvertisingCompany.IsBarter */}
                            {NewElement(props.selectedVertex) instanceof ref_AdvertisingCompanies &&
                                <Grid item xs={12} className="message_details_full">
                                    <Checkbox
                                        checked={RefElement["IsBarter"]}
                                        disabled={clntForm}
                                        label={Trad(`IsBarter`)}
                                        onChange={(e) => RefElement["IsBarter"] = e.value}
                                    />
                                </Grid>
                            }
                            <CompanyInfosForm element={RefElement as ref_Companies} disabled={clntForm} />
                        </>
                    }
                    {!clntForm && props.selectedVertex == ref_Supports.name &&
                        <Grid item xs={12} className="message_details_full">
                            <WebsiteTextBox value={(RefElement as ref_Supports).Website} disabled={clntForm} onChange={(v) => (RefElement as ref_Supports).Website = v} />
                        </Grid>
                    }
                    {!clntForm && IsMediaPress && LinkedPropertyTypes &&
                        LinkedPropertyTypes.map(type => {
                            return <Grid item xs={12} className="message_details_full">
                                <VertexAutocomplete
                                    label={type}
                                    multiple
                                    disabled={clntForm}
                                    defaultValue={(options: any[]) => options?.filter((v) => RefElement["LinkedProperties"]?.includes(v["@rid"]))}
                                    onChange={(value: IRid[]) => {
                                        if (RefElement["LinkedProperties"]) {
                                            const oldValues = RefElement[`${type}_values`].map((v: any) => v["@rid"]);
                                            RefElement["LinkedProperties"] = RefElement["LinkedProperties"].filter(p => !oldValues.includes(p));
                                            RefElement["LinkedProperties"] = [...RefElement["LinkedProperties"], ...value?.map((v: any) => v["@rid"])];
                                        }
                                        else
                                            RefElement["LinkedProperties"] = value?.map((v: any) => v["@rid"]);
                                        RefElement[`${type}_values`] = value;
                                    }}
                                    type={ref_Property.name}
                                    afterLoadFilter={options => {
                                        if (RefElement["LinkedProperties"]?.length) {
                                            RefElement[`${type}_values`] = options?.filter((v) => RefElement["LinkedProperties"]?.includes(v["@rid"]));
                                        }
                                        return options;
                                    }}
                                    params={{ "PropertyType.Type": type }}
                                />
                            </Grid>
                        })
                    }
                    {/** Medias */}
                    {!clntForm && ['Format', 'Emplacement', ref_Supports.name].includes(props.selectedVertex) &&
                        <Grid item xs={6} className="message_details_leftcombo">
                            <VertexAutocomplete
                                label={`${Trad("medias")} *`}
                                disabled={clntForm}
                                disableClearable
                                defaultValue={(options: any[]) => options?.find((v) => v["@rid"] === RefElement["Medias"]?.[0])}
                                onChange={(value: IRid) => {
                                    RefElement["Medias"] = [value["@rid"]];
                                    if (props.selectedVertex === ref_Supports.name) {
                                        if (!RefElement["Medias"].includes(MediaPress))
                                            RefElement["Periodicity"] = undefined;
                                    }
                                    setState({ ...state, IsMediaPress: RefElement["Medias"].includes(MediaPress) });
                                }}
                                type={ref_Media.name}
                            />
                        </Grid>
                    }
                    {/** Periodicity */}
                    {!clntForm && IsMediaPress && props.selectedVertex == ref_Supports.name &&
                        <Grid item xs={6} className="message_details_rightcombo">
                            <VertexAutocomplete
                                label={`${TradProp("Periodicity", ref_Supports)} *`}
                                disabled={clntForm}
                                getOptionLabel={(v => TradValue(v["@class"], "Name", v.Name))}
                                defaultValue={(options: IRid[]) => options.find(o => o["@rid"] == RefElement["Periodicity"])}
                                disableClearable
                                onChange={(value: IRid) => RefElement["Periodicity"] = value["@rid"]}
                                type={ref_Periodicity.name} />
                        </Grid>
                    }


                    {/** Currency */}
                    {!clntForm && (props.selectedVertex === ref_AdvertiserGroups.name || props.selectedVertex === ref_Advertisers.name || props.selectedVertex === ref_BroadcastAreas.name) &&
                        <Grid item xs={6} className="message_details_leftcombo">
                            <VertexAutocomplete
                                type={ref_Currencies.name}
                                disabled={clntForm}
                                onResetValue={(options: any) => undefined as any}
                                params={{
                                    properties: ["@rid", "Name", "Code"],
                                }}
                                defaultValue={(options) => {
                                    let currency = options.find(e => (RefElement as any)?.lnkCurrencies?.[0]?.out == e["@rid"]);
                                    if (!currency) {
                                        currency = options.find(e => e["@rid"] == Parent?.["lnkCurrencies"]?.[0]?.out ?? undefined)
                                    }
                                    return currency;
                                }}
                                getOptionLabel={(option: ref_Currencies) => `${option.Name} (${option.Code})`}
                                key={"currency" + Parent?.["lnkCurrencies"]?.[0]?.out}
                                label={TradProp("Currency", ref_Campaigns)}
                                onChange={(value) => {
                                    if (!value)
                                        (RefElement as any).lnkCurrencies = []
                                    else
                                        (RefElement as any).lnkCurrencies = [{ out: value["@rid"], in: RefElement?.["@rid"], Active: true }]
                                }}
                            />
                        </Grid>
                    }
                    {/** Description */}
                    {!clntForm && props.selectedVertex == ref_Supports.name &&
                        <Grid item xs={12} className="message_details_full">
                            <ContainerComponent title={TradProp("Description", ref_Supports)} className="auto-height no-padding no-margin">
                                <TextEditor
                                    content={(RefElement as ref_Supports).Description}
                                    onChange={(value: string) => (RefElement as ref_Supports).Description = value} />
                            </ContainerComponent>
                        </Grid>
                    }
                    {/** Parent Properties */}
                    {!clntForm && props.parentClass &&
                        <>
                            {(RefElement as any).parent && [ref_AdvertiserGroups.name, ref_BroadcastAreas.name].includes(props.parentClass) &&
                                <Grid item xs={6} className='message_details_rightcombo' key={`key_${Parent?.["lnkCurrencies"]?.[0]?.out}`}>
                                    <VertexAutocomplete
                                        type={ref_Currencies.name}
                                        params={{
                                            properties: ["@rid", "Name", "Code"],
                                        }}
                                        disabled
                                        getOptionLabel={(option: ref_Currencies) => `${option.Name} (${option.Code})`}
                                        defaultValue={(options) => options.find(e => e["@rid"] == Parent?.["lnkCurrencies"]?.[0]?.out ?? undefined)}
                                        label={Trad("Parent_returned_currency")}
                                    />
                                </Grid>
                            }
                            {!AdvertiserConfig && <Grid item xs={12} className="message_details_full">
                                <VertexAutocomplete
                                    label={(props.parentClass == props.selectedVertex) ? Trad("parent") : TradClassName(props.parentClass)}
                                    disabled={clntForm}
                                    disableClearable={(props.parentClass == ref_AdvertiserGroups.name)}
                                    defaultValue={(options: IRid[]) => options?.find((v) => v["@rid"] === RefElement["parent"])}
                                    afterLoadFilter={(options: IRid[]) => options?.filter(o => o['@rid'] != RefElement['@rid'] && !ExceptParentRids.includes(o['@rid']))}
                                    onChange={(value: IRid) => {
                                        RefElement["parent"] = value["@rid"]
                                        getParent(value["@rid"], props.parentClass == ref_BroadcastAreas.name)
                                    }}
                                    type={props.parentClass}
                                />
                            </Grid>}
                        </>
                    }
                </Grid>
                {/** Hierarchy Advertiser */}
                {!clntForm && AdvertiserConfig &&
                    <Grid item xs={12} className="message_details_full">
                        <HierarchyComboManager
                            isDisableClearable
                            store={AdvertiserConfig}
                            classInitializer={props.parentClass}
                            onConfChanged={() => fillParent(RefElement, AdvertiserConfig)}
                            hideBrand={props.selectedVertex === ref_Brands.name}
                            hideProduct={props.selectedVertex === ref_Products.name}
                            multi
                        />
                    </Grid>
                }
                {/** Commercial Advertising Company Links */}
                {!clntForm && AdvComGrid &&
                    <>
                        <Grid item xs={12} className="adw-title adw-form-title">
                            <div>{getIcon("group")}</div>
                            {Trad("static/undefined/AdvCompany_Com")}
                        </Grid>
                        <Grid className="message_details_full" style={{ overflow: "auto", width: "100%" }}>
                            <AdwTelerikGrid
                                grid={AdvComGrid}
                                deleteInline={!clntForm}
                                addButton={!clntForm}
                                customButtons={clntForm ? <></> : <CustomButton
                                    style={{ float: 'right' }}
                                    className="custom_btn_secondary_white margin-right"
                                    Label={Trad("create_adwCmp")}
                                    onClick={() => setState({ ...state, NewClassName: ref_AdvertisingCompanies.name })} />
                                }
                                customAddText={Trad("add_existing_adwCmp")}
                                gridHeight={styleGridContainer.supportForm.height}
                            />
                        </Grid>
                    </>
                }
                {/** Financial Advertising Company Links */}
                {!clntForm && AdvFinGrid &&
                    <>
                        <Grid item xs={12} className="adw-title adw-form-title">
                            <div>{getIcon("group")}</div>
                            {Trad("static/undefined/AdvCompany_Fin")}
                        </Grid>
                        <Grid className="message_details_full" style={{ overflow: "auto", width: "100%" }}>
                            <AdwTelerikGrid
                                grid={AdvFinGrid}
                                deleteInline={!clntForm}
                                addButton={!clntForm}
                                customButtons={clntForm ? <></> : <CustomButton
                                    style={{ float: 'right' }}
                                    className="custom_btn_secondary_white margin-right"
                                    Label={Trad("create_adwCmp")}
                                    onClick={() => setState({ ...state, NewClassName: ref_AdvertisingCompanies.name })} />
                                }
                                customAddText={Trad("add_existing_adwCmp")}
                                gridHeight={styleGridContainer.supportForm.height}
                            />
                        </Grid>
                    </>
                }
                {/** BroadcastArea Links */}
                {!clntForm && BroadcastAreaGrid &&
                    <>
                        <Grid item xs={12} className="adw-title adw-form-title">
                            <div>{getIcon("location")}</div>
                            {Trad("broadcast_areas")}
                        </Grid>
                        <Grid className="message_details_full" style={{ overflow: "auto", width: "100%" }}>
                            <AdwTelerikGrid
                                grid={BroadcastAreaGrid}
                                deleteInline={!clntForm}
                                addButton={!clntForm}
                                customButtons={clntForm ? <></> : <CustomButton
                                    style={{ float: 'right' }}
                                    className="custom_btn_secondary_white margin-right"
                                    Label={Trad("create_area")}
                                    onClick={() => setState({ ...state, NewClassName: ref_BroadcastAreas.name })} />
                                }
                                customAddText={Trad("add_existing_area")}
                                gridHeight={styleGridContainer.supportForm.height}
                            />
                        </Grid>
                    </>
                }
                {/** Supports Links */}
                {!clntForm && SupportGrid &&
                    <>
                        <Grid item xs={12} className="adw-title adw-form-title">
                            <div>{getIcon("group")}</div>
                            {Trad("supports")}
                        </Grid>
                        <Grid className="message_details_full" style={{ overflow: "auto", width: "100%" }}>
                            <AdwTelerikGrid
                                grid={SupportGrid}
                                deleteInline={!clntForm}
                                addButton={!clntForm}
                                customRemoveInlineContent={item => <RemoveLinkDetails item={item} />}
                                customAddText={Trad("add_existing_support")}
                                gridHeight={styleGridContainer.supportForm.height}
                            />
                        </Grid>
                    </>
                }
                {/** Currency Links */}
                {!clntForm && CurrencyGrid &&
                    <><Grid item xs={12} className="adw-title adw-form-title">
                        <div>{getIcon("euro")}</div>
                        {Trad("currencies")}
                    </Grid>
                        <Grid className="message_details_full" style={{ overflow: "auto", width: "100%" }}>
                            <AdwTelerikGrid
                                grid={CurrencyGrid}
                                deleteInline={!clntForm}
                                addButton={!clntForm}
                                customButtons={clntForm ? <></> : <CustomButton
                                    style={{ float: 'right' }}
                                    className="custom_btn_secondary_white margin-right"
                                    Label={Trad("create_currency")}
                                    onClick={() => setState({ ...state, NewClassName: ref_Currencies.name })} />
                                }
                                customAddText={Trad("add_existing_currency")}
                                gridHeight={styleGridContainer.supportForm.height}
                            />
                        </Grid>
                    </>
                }
                {/** Mandate Links */}
                {!clntForm && MandateGrid &&
                    <Grid className="message_details_full" style={{ overflow: "auto", width: "100%" }}>
                        <AdwTelerikGrid
                            grid={MandateGrid}
                            deleteInline={!clntForm}
                            addButton={!clntForm}
                            customAddText={Trad("add_mandate")}
                            gridHeight={styleGridContainer.supportForm.height}
                        />
                    </Grid>
                }
                {/** Contact Links */}
                {!clntForm && NewElement(props.selectedVertex) instanceof ref_Companies &&
                    <Grid className="message_details_full" style={{ overflow: "auto", width: "100%" }}>
                        <AdwTelerikGrid
                            grid={ContactGrid}
                            deleteInline={!clntForm}
                            addButton={!clntForm}
                            customButtons={clntForm ? <></> : <CustomButton
                                style={{ float: 'right' }}
                                className="custom_btn_secondary_white margin-right"
                                startIcon={getIcon("plus")}
                                Label={Trad("create_contact")}
                                onClick={() => setState({ ...state, NewClassName: ref_PersonExtended.name })} />
                            }
                            customAddText={Trad("add_existing_contact")}
                            gridHeight={styleGridContainer.supportForm.height}
                        />
                    </Grid>}

                {clntForm && props.selectedVertex == ref_Supports.name &&
                    <ClntSupportForm Referential={RefElement} Parent={Parent} />
                }
                {clntForm && (props.selectedVertex === ref_AdvertiserGroups.name || props.selectedVertex === ref_Advertisers.name) &&
                    <ClntAdvertiserForm Referential={RefElement} Parent={Parent} />
                }
            </Grid>
            {/** New Element Dialog */}
            {
                NewClassName &&
                <GenericDialog
                    open={true}
                    dialogTitle={`${Trad(eDialogMode.create)} ${TradClassName(NewClassName)}`}>
                    <div className='adw-form-dialog-container'>
                        <ReferentialForm
                            onSave={newElement => onSaveDialogGrid(newElement, NewClassName, "create")}
                            mode={eDialogMode.create}
                            onCancel={() => setState({ ...state, NewClassName: undefined })}
                            parentClass={GetParentClass(NewClassName, HierarchyReferential?.[NewClassName])}
                            selectedVertex={NewClassName} />
                    </div>
                </GenericDialog>
            }

            {
                EditedElement &&
                <GenericDialog
                    open={true}
                    dialogTitle={`${Trad(eDialogMode.modify)} ${TradClassName(EditedElement['@class'])}`}>
                    <div className='adw-form-dialog-container'>
                        <ReferentialForm
                            selectedItem={EditedElement}
                            onSave={newElement => onSaveDialogGrid(newElement, EditedElement['@class'], "edit")}
                            mode={eDialogMode.modify}
                            onCancel={() => setState({ ...state, EditedElement: undefined })}
                            parentClass={GetParentClass(EditedElement['@class'], HierarchyReferential?.[EditedElement['@class']])}
                            selectedVertex={EditedElement['@class']} />
                    </div>
                </GenericDialog>
            }

            <div className='adw-form-action-left-container'>
                <div className="adw-form-action">
                    {props.mode === eDialogMode.modify && props.onDelete && !clntForm &&
                        <CustomButton
                            Label={Trad("delete")}
                            className="custom_btn_danger"
                            disabled={DisableActions}
                            onClick={() => props?.onDelete(RefElement)}
                        />}
                </div>
            </div>

            <div className='adw-form-action-container'>
                <div className="adw-form-action">
                    <CustomButton
                        Label={Trad("cancel")}
                        disabled={DisableActions}
                        className="custom_btn_primary_cancel cancel"
                        onClick={() => props?.onCancel()}
                    />
                    <CustomButton
                        Label={Trad(props.mode)}
                        disabled={DisableActions}
                        className="custom_btn_primary_validation"
                        onClick={() => Save(RefElement)}
                    />
                </div>
            </div>
        </>)
}
