import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CronType, GitScriptCrons, ImportCrons, LEGOCrons, MDRaportCrons, ref_Crons } from "hub-lib/dto/admin/ref_Crons.bin";
import { duplicate } from "hub-lib/tools.bin";
import * as React from "react";
import ReactJson from "react-json-view";
import { Trad } from "trad-lib";
import { VertexAutocomplete } from "adwone-lib/index";
import CheckBoxGroup from "../../ConfigurableComponents/CheckboxGroup.bin";
import { SimpleDateTimePicker } from "../../ConfigurableComponents/SimpleDateTimepicker.bin";
import { TimePicker } from "../../ConfigurableComponents/TimePicker.bin";
import { CustomTextFieldUpper } from "../Generic/CustomTextField";
import { ref_CustomersExtended } from "hub-lib/dto/referential/ref_CustomersExtended.bin";
import { InfoAdornment } from "../Messages/InfoAdornment";
import GitScriptDialog from "../GitScript/GitScriptDialog";
import { LEGOConfiguration } from "./LEGOConfiguration";

class TProps {
    cron: ref_Crons;
    days: {
        label: string;
        value: number;
        shortLabel: string;
    }[];
    defaultCron: ref_Crons;
}
/**
 * @description
 * @author Bastien Douib
 * @param {TProps} props
 * @returns {*}
 */
const CronsDialog = (props: TProps) => {
    const { cron, days, defaultCron } = props;
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [calcDate, setCalcDate] = React.useState(new Date());
    const updateCalcDate = (item: ref_Crons): void => {
        if (item.Schedule?.Planned?.Date && item.Schedule?.Planned?.LoopSecond) {
            const date = new Date(item.Schedule.Planned.Date);
            date.setSeconds(date.getSeconds() + item.Schedule.Planned.LoopSecond);
            setCalcDate(date);
        }
    };

    const displayAvailableParams = () => {
        let object: { [key: string]: any };
        switch (cron.CronType) {
            case CronType.LEGO:
                object = new LEGOCrons().getParams()
                break;
            case CronType.MDReport:
                object = new MDRaportCrons().getParams()
                break;
            case CronType.GitScript:
                object = new GitScriptCrons().getParams()
                break;
            default:
                object = new ImportCrons().getParams()
                break;
        }
        return <ReactJson
            sortKeys
            displayObjectSize={false}
            enableClipboard={false}
            theme={"brewer"}
            name="Availlable params"
            src={object}
        />;
    }
    return (
        <Grid container className="main-grid-crons-dialog">
            <Grid item xs={12} style={{ marginBottom: 12, marginLeft: 12 }}>
                <FormLabel id="schedule-radio-buttons-group">{Trad("Schedule")}</FormLabel>
                <RadioGroup
                    aria-labelledby="schedule-radio-buttons-group"
                    row
                    value={cron.Schedule.Recurrence ? "recurrence" : "planned"}
                    onChange={(e) => {
                        cron.Schedule = {};
                        if (e.target.value === "recurrence") {
                            cron.Schedule.Recurrence = duplicate(defaultCron.Schedule.Recurrence);
                        } else {
                            cron.Schedule.Planned = {
                                Date: new Date(),
                            };
                        }
                        forceUpdate();
                    }}
                >
                    <FormControlLabel
                        style={{ marginLeft: -9 }}
                        value="recurrence"
                        control={<Radio />}
                        label={Trad("recurrence")}
                    />
                    <FormControlLabel value="planned" control={<Radio />} label={Trad("planned")} />

                    {cron.Schedule.Planned && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className="MuiButtonBase-root MuiIconButton-root"
                                    value={cron.Schedule.Planned.LoopSecond}
                                    defaultChecked={cron.Schedule.Planned.LoopSecond !== undefined}
                                    onChange={(e) => {
                                        if (e.value) {
                                            cron.Schedule.Planned.LoopSecond = 604800; // 1 semaine;
                                        } else {
                                            delete cron.Schedule.Planned.LoopSecond;
                                        }
                                        updateCalcDate(cron);
                                        forceUpdate();
                                    }}
                                />
                            }
                            label={Trad("loop")}
                        />
                    )}
                </RadioGroup>
            </Grid>
            {cron.Schedule.Recurrence && (
                <Grid container style={{ marginBottom: 12 }}>
                    <Grid item xs={6}>
                        <CheckBoxGroup checksbox={days} array={cron.Schedule.Recurrence.DayOfWeek} />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 6 }}>
                        <TimePicker
                            key={`${cron.Schedule.Recurrence.Hour} ${cron.Schedule.Recurrence.Minute}`}
                            label={Trad("time_view")}
                            format="HH:mm"
                            defaultValue={
                                new Date(
                                    2000,
                                    2,
                                    10,
                                    cron.Schedule.Recurrence.Hour,
                                    cron.Schedule.Recurrence.Minute
                                )
                            }
                            onChange={(e) => {
                                cron.Schedule.Recurrence.Hour = e.value.getHours();
                                cron.Schedule.Recurrence.Minute = e.value.getMinutes();
                                forceUpdate();
                            }}
                            resetTime={() => {
                                cron.Schedule.Recurrence.Hour = defaultCron.Schedule.Recurrence.Hour;
                                cron.Schedule.Recurrence.Minute = defaultCron.Schedule.Recurrence.Minute;
                                forceUpdate();
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            {cron.Schedule.Planned && (
                <Grid container style={{ marginBottom: 12 }}>
                    <Grid item xs={6} style={{ paddingRight: 6 }}>
                        <SimpleDateTimePicker
                            label={Trad("planned_date")}
                            defaultValue={new Date(cron.Schedule.Planned.Date)}
                            onChange={(e) => (cron.Schedule.Planned.Date = e.value)}
                            resetDate={() => (cron.Schedule.Planned.Date = new Date())}
                        />
                    </Grid>
                    {cron.Schedule.Planned.LoopSecond && (
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={6} style={{ padding: "0 6px" }}>
                                    <TextField
                                        type="Number"
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        label={Trad("seconds", true)}
                                        defaultValue={cron.Schedule.Planned.LoopSecond}
                                        onChange={(e) => {
                                            cron.Schedule.Planned.LoopSecond = parseInt(e.target.value);
                                            if (isNaN(cron.Schedule.Planned.LoopSecond))
                                                cron.Schedule.Planned.LoopSecond = 0;
                                            updateCalcDate(cron);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ paddingLeft: 6 }}>
                                    <SimpleDateTimePicker
                                        disabled
                                        key={"loopSendCalc" + calcDate}
                                        label={Trad("next_planned_date")}
                                        defaultValue={new Date(calcDate)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
            <Grid container style={{ marginBottom: 12 }}>
                <Grid item xs={6} style={{ paddingRight: 6 }}>
                    <CustomTextFieldUpper
                        value={cron.Name}
                        label={Trad("Name")}
                        onChange={(val) => (cron.Name = val)}
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 6 }}>
                    <VertexAutocomplete
                        {...((cron.CronType && {
                            startAdornment:
                                <InfoAdornment element={() => displayAvailableParams()} />
                        }) ?? {})}
                        label={Trad("static/ref_Crons/CronType")}
                        options={Object.values(CronType)}
                        defaultValue={(types: CronType[]) => types.find((e) => e == cron.CronType)}
                        onResetValue={() => undefined}
                        onChange={(type: CronType) => {
                            cron.CronType = type;
                            forceUpdate();
                        }}
                    />
                </Grid>
            </Grid>
            {cron.CronType === CronType.LEGO
                && <LEGOConfiguration cron={cron as LEGOCrons} onChange={(cron) => forceUpdate()} />}

            {cron.CronType === CronType.ExportAuto
                && <LEGOConfiguration cron={cron as LEGOCrons} onChange={(cron) => forceUpdate()} modeleActivated />}

            <Grid item xs={12}>
                <ReactJson
                    src={cron.TaskParams}
                    name={Trad("params")}
                    displayDataTypes={false}
                    sortKeys
                    displayObjectSize={false}
                    enableClipboard={false}
                    onAdd={(e) => (cron.TaskParams = e.updated_src as any)}
                    onEdit={(e) => (cron.TaskParams = e.updated_src as any)}
                    onDelete={(e) => (cron.TaskParams = e.updated_src as any)}
                />
            </Grid>
            {cron.CronType === CronType.GitScript &&
                <GitScriptDialog
                    updateScriptID={(ID: string) => {
                        (cron as GitScriptCrons).TaskParams.scriptID = ID;
                        forceUpdate();
                    }} />
            }
        </Grid>
    )
}

export default CronsDialog;