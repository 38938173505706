import * as React from "react";

import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import { GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import { Trad } from "trad-lib";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { CustomIconButton } from "../Generic/CustomIconButton";
import { TooltipManager } from "../../CustomTooltip";
import { getIcon } from "adwone-lib/index";

export function IntervalsCommandCell(arg: {
    edit: any,
    remove: any,
    add: any,
    update: any,
    discard: any,
    cancel: any,
    editField: keyof ref_Discount | string,
    isDisabled?: (d: ref_Discount) => boolean,
    additionnalCommands?: { icon: JSX.Element, function: (d: any) => void, color?: string }[]
}) {

    let { edit, remove, add, update, discard, cancel, editField, isDisabled, additionnalCommands } = arg;

    return class extends React.Component<any, any> {

        constructor(props: any) {
            super(props);
            this.state = {
                open: false
            }
        }

        render() {

            let open = (this.state as any)?.open;
            const dataItem: ref_Discount = this.props.dataItem;

            const inEdit = dataItem[editField];
            const isNewItem = dataItem["@rid"] === undefined;
            return inEdit ? (
                <td className="k-command-cell clear-padding">

                    <CustomIconButton style={{ color: 'rgb(101, 171, 61)' }} aria-label="edit" onClick={() => isNewItem ? add(dataItem) : update(dataItem)}>
                        <DoneIcon />
                    </CustomIconButton>

                    <CustomIconButton className="picto custom_btn_secondary_cancel" aria-label="edit" onClick={() => isNewItem ? discard(dataItem) : cancel(dataItem)}>
                        {getIcon("undo")}
                    </CustomIconButton>

                </td>
            ) : (
                <td className="k-command-cell clear-padding">

                    {additionnalCommands?.map((c, i) => (
                        <CustomIconButton key={`additionnalCommands-cell-${i}`} disabled={isDisabled && isDisabled(dataItem)} style={{ color: c.color ?? '#009BCE' }} aria-label="edit" onClick={() => c.function(dataItem)}>
                            {c.icon}
                        </CustomIconButton>))}
                    <CustomIconButton disabled={isDisabled && isDisabled(dataItem)} style={{ color: '#009BCE' }} aria-label="edit" onClick={() => edit(dataItem)}>
                        <EditIcon />
                    </CustomIconButton>
                    <CustomIconButton disabled={isDisabled && isDisabled(dataItem)} style={{ color: '#009BCE' }} aria-label="edit" onClick={() => this.setState({ open: !open })}>
                        <DeleteIcon />
                    </CustomIconButton>
                    <GenericDialog
                        dialogTitle={Trad("confirmation")}
                        open={open}
                        submitTitle={Trad("yes")}
                        dialogContent={<p>{Trad("delete_confirmation")}</p>}
                        submitClass={"custom_btn_danger"}
                        handleClose={() => this.setState({ open: !open })}
                        actions={true}
                        submitAction={() => {
                            this.setState({ open: !open });
                            remove(dataItem)
                        }}
                    />
                </td>
            );
        }
    }
}