
import { IRid } from "../../models/IRid.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";

class LogBase {
    Date: Date;
    Layer: any;
    Report: any
}
export class Log extends LogBase implements IRid {
    "@rid"?: rid;
    User: string;
    CallID: any;
    IP: string;
    checked?: boolean;
}