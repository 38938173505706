import * as React from "react";
import { TooltipManager } from "adwone-lib/index";
import { Trad } from "trad-lib";
import { HasRightToViewMode, ref_TableConfigurations, ViewModeType } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { getIcon } from "../../../ConfigurableComponents/IconManager.bin";
import { IconButton } from "@material-ui/core";
import { DataGrid } from "./DataGrid";
import { setTable } from "../../../../redux/gridConfigurationsSlice";

type TabType = { title: string, viewMode: ViewModeType, icon: JSX.Element };
const availableTabs: TabType[] = [
    { title: 'table_view', viewMode: "Table", icon: getIcon("listView") },
    { title: 'crossedtable_view', viewMode: 'CrossedTable', icon: getIcon("tableView") },
    { title: 'scheduler_view', viewMode: "Scheduler", icon: getIcon("schedulerView") },
    { title: 'dashboard_view', viewMode: "Dashboard", icon: getIcon("dashboard") }
];

type FooterCellOptionsProps<T> = { grid: DataGrid<T> };
export function FooterCellOptions<T>({ grid }: FooterCellOptionsProps<T>) {
    const [selected, setSelected] = React.useState<number>(0);

    const currentView = useSelector((root: RootState) => root.columsConfigurations.configurations[grid.objectPrototype.name]?.table?.ViewMode ?? "Table");

    const tabs = availableTabs.filter(t => HasRightToViewMode(grid.objectPrototype.name, t.viewMode));
    const tabIndex = tabs
        .findIndex(tab => tab.viewMode === currentView);

    const dispatch = useDispatch();

    React.useEffect(() => {
        setSelected(tabIndex)
    }, [tabIndex])

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
        onChangeMode(tabs[e.selected].viewMode);
    };

    const onChangeMode = async (value: ref_TableConfigurations["ViewMode"]) => {
        let configuration = grid.props.configuration;
        configuration.ViewMode = value;
        dispatch(setTable(configuration));
    };

    if (tabs.length < 2)
        return null;

    return (
        <TabStrip className="FooterCellOptions" tabContentStyle={{ display: 'none' }} selected={selected} onSelect={handleSelect}>
            {tabs.map((item, index) => {
                return (
                    <TabStripTab
                        title={<TitleTab content={item} currentView={currentView} />}
                        key={index}>
                    </TabStripTab>
                );
            })}
        </TabStrip>
    );
};

const TitleTab = (props: any) =>
    <div>
        <IconButton
            onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad(props.content.viewMode.toLocaleLowerCase() + "_view") })}
            key={`icon-${props.content.viewMode}`}
            className={"navigation " + (props.content.viewMode == props.currentView ? "is-selected" : "")}
            aria-label="edit">
            {props.content.icon}
        </IconButton>
        <span className="k-spacer" />
        {Trad(props.content.title)}
    </div>
