
export var loginRoute = "/login";
export var resetRoute = "/reset";
export var TwoFactorVerifyRoute = "/twofactor/verify";
export var newpassRoute = "/newpass";
export var domainsRoute = "/domains";
export var tokenRoute = "/token";
export var authRoute = "/auth";
export var graphRoute = "/graph";
export var linkRoute = "/link";
export var logsRoute = "/logs";
export var versionRoute = "/version";
export var dimensionRoute = "/dimension";
export var externalRoute = "/external";
export var metadataRoute = "/metadata";
export var searchRefRoute = "/referential/search";
export var calendarRoute = "/calendar";
export var localeRoute = "/locale";
export var fileRoute = "/file";
export var alertRoute = "/alert";
export var externalimportRoute = "/externalimport";
export var sellsyRoute = "/sellsy";