import React from "react";
import { VertexAutocomplete } from "adwone-lib";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { duplicate, extractSub, GetHashCode } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { TradClassName } from "trad-lib";
import { StoreProps } from "./AdvertiserHierarchyComponent";
import { RootStateFilters } from "./StoreFilters";
import { Client } from "hub-lib/client/client.bin";

export function CampaignFilter({ store, onChange }: StoreProps) {
    const advertiserGroups = store?.AdvertiserGroup

    const params = {
        ...(advertiserGroups?.length > 0 ? { AdvertiserGroup: advertiserGroups } : {}),
        ...extractSub(store, ["Source", "Start", "End"]),
        Active: true,
        properties: ["@rid", "Name"]
    }

    const [availableCampaigns, setAvailableCampaigns] = React.useState<Pick<ref_Campaigns, '@rid' | 'Name'>[]>(undefined);

    const getMissingCampaigns = async (existings: typeof availableCampaigns) => {
        let missingCampaigns: ref_Campaigns[] = [];
        const missingCamp = store?.Campaign?.filter(c => !existings?.find(a => a["@rid"] == c));
        if (missingCamp?.length > 0) {
            missingCampaigns = await Client.searchVertexTyped(ref_Campaigns, { "@rid": missingCamp, properties: ["@rid", "Name"] });
            missingCampaigns.forEach(c => c['className'] = 'missing-element');
        }
        return missingCampaigns ?? [];
    }

    React.useEffect(() => {
        Promise.resolve().then(async () => {
            const campaigns = await Client.searchVertexTyped(ref_Campaigns, params);
            const missingCampaigns = await getMissingCampaigns(campaigns);
            setAvailableCampaigns([...campaigns, ...missingCampaigns]);
        });
    }, [GetHashCode(params)]);

    React.useEffect(() => {
        if (availableCampaigns != undefined)
            Promise.resolve().then(async () => {
                // if campaign in store is not available anymore, we add it to the list
                const missingCamp = await getMissingCampaigns(availableCampaigns)
                if (missingCamp?.length > 0)
                    setAvailableCampaigns([...availableCampaigns, ...missingCamp]);
            });
    }, [GetHashCode(store?.Campaign)]);


    const onChanged = (values: typeof availableCampaigns) => {
        store.Campaign = values.map(v => v["@rid"]);
        onChange?.(store);
    };

    return <VertexAutocomplete
        key={`camp_key_${GetHashCode(availableCampaigns?.map(c => c["@rid"]).join("_"))}`}
        label={TradClassName(ref_Campaigns.name)}
        multiple
        refreshOnPropChanged
        options={availableCampaigns ?? []}
        defaultValue={(values: typeof availableCampaigns) => {
            // const selectedValues =  values?.filter(v => store?.Campaign?.includes(v["@rid"])) ?? [];
            const selectedValues = store?.Campaign?.map(c => values.find(a => a["@rid"] == c) ?? {
                "@rid": c,
                Name: c,
                className: Boolean(availableCampaigns) ? 'missing-element' : 'missing-element-hidden'
            }) ?? [];
            // if (selectedValues.length != (store?.Campaign?.length ?? 0))
            //     onChanged(selectedValues);
            return selectedValues;
        }}
        onChange={onChanged}
    />
}