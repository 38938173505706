import { Referentials, ReferentialsId } from "./Referentials.bin";
import { ref_Currencies, ref_CurrenciesId } from "./ref_Currencies.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";

export type lnk_HasCurrencyId = rid;

export class lnk_HasCurrency extends E<ReferentialsId, ref_CurrenciesId> {
	"@rid"?: lnk_HasCurrencyId;
	Active: boolean;
	Default: boolean;
	End: Date;
	Start: Date;

}