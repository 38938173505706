import { Grid, TextField } from '@material-ui/core';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';
import * as React from 'react'
import { Trad } from 'trad-lib';
import { ErrorMessage } from '../../../ConfigurableComponents/ErrorMessage.bin';
import { WebsiteTextBox } from './WebsiteTextBox';

class TProps {
    element: ref_Companies;
    disabled?: boolean;
}

class TState {
    TVAError: boolean = false
    SIRENError: boolean = false
    SIRETError: boolean = false
    GLNError: boolean = false
}

const TvaRegex = new RegExp("^([a-zA-Z]{2}[0-9]{11})$")

export class CompanyInfosForm extends React.Component<TProps, TState>{
    constructor(props: TProps) {
        super(props);
        this.state = new TState()
    }
    render() {
        let { element, disabled } = this.props
        let { TVAError, SIRENError, SIRETError, GLNError } = this.state
        let isSiretInvalid = () => (element.Siret?.length !== 14 && element.Siret !== '') || !element.Siret?.startsWith(element.Siren)
        let isSirenInvalid = () => (element.Siren?.length !== 9 && element.Siren !== '')

        return (
            <>
                <Grid item xs={12}><p style={{ fontWeight: 'bold' }}>{`${Trad("at_least_one")} *`}</p></Grid>
                <Grid item xs={6} className="message_details_leftcombo">
                    <TextField
                        variant="outlined"
                        label="SIREN"
                        disabled={disabled}
                        value={(element as any).Siren}
                        type="number"
                        onChange={(e) => {
                            (element as any).Siren = e.target.value
                            this.setState({
                                SIRENError: isSirenInvalid(),
                                SIRETError: element.Siret && element.Siret !== '' ? isSiretInvalid() : false
                            })
                        }}
                        style={{ width: '100%' }}
                    />
                    {SIRENError &&
                        <ErrorMessage message={Trad("siren_error")} margin={'20px 0'} padding={'10px'} />
                    }
                </Grid>
                <Grid item xs={6} className="message_details_rightcombo">
                    <TextField
                        variant="outlined"
                        label="GLN"
                        disabled={disabled}
                        value={element.Gln}
                        type="number"
                        onChange={(e) => {
                            element.Gln = e.target.value
                            this.setState({
                                GLNError: element.Gln.length !== 13 && element.Gln !== ''
                            })
                        }}
                        style={{ width: '100%' }}
                    />
                    {GLNError &&
                        <ErrorMessage message={Trad("gln_error")} margin={'20px 0'} padding={'10px'} />
                    }
                </Grid>
                <Grid item xs={6} className="message_details_leftcombo">
                    <TextField
                        variant="outlined"
                        label="SIRET"
                        disabled={disabled}
                        type="number"
                        value={element.Siret}
                        onChange={(e) => {
                            element.Siret = e.target.value
                            this.setState({
                                SIRETError: isSiretInvalid()
                            })
                        }}
                        style={{ width: '100%' }}
                    />
                    {SIRETError &&
                        <ErrorMessage message={Trad("siret_error")} margin={'20px 0'} padding={'10px'} />
                    }
                </Grid>
                <Grid item xs={6} className="message_details_rightcombo">
                    <TextField
                        variant="outlined"
                        label="TVA UE"
                        disabled={disabled}
                        value={element.Tva}
                        onChange={(e) => {
                            element.Tva = e.target.value
                            this.setState({
                                TVAError: !TvaRegex.test(element.Tva) && element.Tva !== ''
                            })
                        }}
                        style={{ width: '100%' }}
                    />
                    {TVAError &&
                        <ErrorMessage message={Trad("tva_number_invalid")} margin={'20px 0'} padding={'10px'} />
                    }
                </Grid>
                <Grid item xs={12} className="message_details_full">
                    <WebsiteTextBox value={element.Website} disabled={disabled} onChange={(v) => element.Website = v} />
                </Grid>
            </>
        )
    }
}