import { ref_CustomersExtended } from 'hub-lib/dto/referential/ref_CustomersExtended.bin'
import * as React from 'react'
import { CustomTextField } from '../../VertexGrid/Generic/CustomTextField'

type SSOConfigurationArg = { sso: ref_CustomersExtended["SSO"] }
export function SSOConfiguration({ sso }: SSOConfigurationArg) {

    const [state, setState] = React.useState<ref_CustomersExtended["SSO"]>(sso);
    const style: React.CSSProperties = {
        marginTop: 10
    }

    return <>
        <CustomTextField style={style} label='CALLBACK_URL' value={sso.CALLBACK_URL} onChange={v => { sso.CALLBACK_URL = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='ENTRY_POINT' value={sso.ENTRY_POINT} onChange={v => { sso.ENTRY_POINT = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='ERROR' value={sso.ERROR} onChange={v => { sso.ERROR = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='HOME' value={sso.HOME} onChange={v => { sso.HOME = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='IDP_PUBLIC' value={sso.IDP_PUBLIC} onChange={v => { sso.IDP_PUBLIC = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='ISSUER' value={sso.ISSUER} onChange={v => { sso.ISSUER = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='ISSUER_PRIVATE' value={sso.ISSUER_PRIVATE} onChange={v => { sso.ISSUER_PRIVATE = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='ROUTER_PATH' value={sso.ROUTER_PATH} onChange={v => { sso.ROUTER_PATH = v; setState({ ...sso }) }} />
        <CustomTextField style={style} label='KEY_SCHEMA_MAIL' value={sso.KEY_SCHEMA_MAIL} onChange={v => { sso.KEY_SCHEMA_MAIL = v; setState({ ...sso }) }} />
    </>
}