import { ref_DiscountClasses, ref_DiscountClassesId } from "./ref_DiscountClasses.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_DiscountClasses, mm_DiscountClassesId } from "./mm_DiscountClasses.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasDiscountClassId = rid;

export class vw_mm_HasDiscountClass extends ReferentialHasViews<src_MMId, mm_DiscountClassesId, ref_DiscountClassesId> {
	"@rid"?: vw_mm_HasDiscountClassId;

}