import { ref_Property, ref_PropertyId } from "./ref_Property.bin";
import { src_TSM, src_TSMId } from "./src_TSM.bin";
import { tsm_Emplacement, tsm_EmplacementId } from "./tsm_Emplacement.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_tsm_HasEmplacementId = rid;

export class vw_tsm_HasEmplacement extends ReferentialHasViews<src_TSMId, tsm_EmplacementId, ref_PropertyId> {
	"@rid"?: vw_tsm_HasEmplacementId;

}