import { classType, rid } from "../../models/orientdb/CommonTypes.bin";
import { Referentials } from "../../models/orientdb/Referentials.bin";
import { ref_AdvertiserGroups } from "../../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "../../models/orientdb/ref_Advertisers.bin";
import { ref_Property } from "../../models/orientdb/ref_Property.bin";
import { ref_Supports } from "../../models/orientdb/ref_Supports.bin";
import { INamed, IUpsertInfo } from "../../types";
import { User } from "../../models/orientdb/User.bin";
import { IRid } from "../../models/IRid.bin";
import { eRights, RightManager } from "../../models/types/rights.bin";

export class ref_GroupsOld {
    '@rid'?: rid;
    Active: boolean;
    Name: string;
    Class: classType;
    Type?: string;
    Default?: boolean;
    Advertisers?: ref_Advertisers['@rid'][];
    IDs: rid[];
}

export enum eGroupCategories {
    MediaFamily = "MediaFamily",
    SupportCategory = "SupportCategory",
    PlacementCategory = "PlacementCategory",
    Magasin = "Magasin",
}

export type GroupOf = {
    Class: classType; // ref_Supports
    Type?: string; // Emplacement
};

export type GroupArea = {
    IDs: rid[];
} & GroupOf;

export class ref_Groups implements IRid, INamed, IUpsertInfo {
    "@rid"?: rid;
    Active: boolean;
    Name: string;
    Default?: boolean;
    Category: eGroupCategories;
    Of: GroupOf;
    IDs: rid[];
    Area?: GroupArea[];
    Created_by?: User["@rid"];
	Created_at?: Date;
	Updated_by?: User["@rid"];
	Updated_at?: Date;
}
export class MediaFamilyGroup extends ref_Groups {
    Category = eGroupCategories.MediaFamily;
    Of = {
        Class: ref_Supports.name,
    };
}
export class SupportCategoryGroup extends ref_Groups {
    Category = eGroupCategories.SupportCategory;
    Of = {
        Class: ref_Supports.name,
    };
}
export class PlacementCategoryGroup extends ref_Groups {
    Category = eGroupCategories.PlacementCategory;
    Of = {
        Class: ref_Property.name,
        Type: "Emplacement",
    };
}
export class MagasinGroup extends ref_Groups {
    Category = eGroupCategories.Magasin;
    Of = {
        Class: ref_Advertisers.name,
    };
}

export type GroupClass = {
    Hierarchy?: {
        params?: any;
        class?: string;
        filter?: (e: any) => any;
    };
} & ref_Groups;

export const hierarchyAdvertiserGroups: GroupClass["Hierarchy"] = {
    class: ref_AdvertiserGroups.name,
    params: {
        Active: true,
        deep: [ref_Advertisers.name],
        format: { children: "items" },
        properties: ["@class", "Name"]
    },
    filter: (e) => [ref_AdvertiserGroups.name, ref_Advertisers.name].includes(e["@class"]),
};

export const hierarchyAdvertisers: GroupClass["Hierarchy"] = {
    class: ref_Advertisers.name,
    params: {
        Active: true,
        properties: ["@class", "Name"],
    },
    filter: (e) => [ref_Advertisers.name].includes(e["@class"]),
};

export const getHierarchy = () => {
    if (RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read)) {
        return hierarchyAdvertiserGroups;
    } else if (RightManager.hasRight(ref_Advertisers.name, eRights.read)) {
        return hierarchyAdvertisers;
    }
}

export const GroupClassList: GroupClass[] = [
    new MediaFamilyGroup(),
    //new SupportCategoryGroup(),
    new PlacementCategoryGroup(),
    /*{
        ...new MagasinGroup(),
        Hierarchy: hierarchyAdvertiserGroups,
    },*/
];
