import * as React from 'react'
import { JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { setMessageSync } from "../../../../redux/messageEditorSlice";
import { GetEditorState, RootState, SetModelSync, store } from "../../../../redux/store";
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';

type DatePropertyEditorArgs = { property: keyof ref_Messages & string, label: string, model: "ref_Messages" | "ref_Campaigns" }
export function DatePropertyEditor({ property, label, model }: DatePropertyEditorArgs) {

    const propertyValue = useSelector((root: RootState) => GetEditorState(model, root).get()[property], JSONEqualityComparer);

    return <SimpleDatePicker
        popupAlign={{
            horizontal: "left",
            vertical: "bottom"
        }}
        label={label}
        defaultValue={propertyValue ? new Date(propertyValue) : null}
        resetDate={() => {
            const data = GetEditorState(model, store.getState()).get();
            (data as any)[property] = null;
            SetModelSync(model, data);
        }}
        onChange={(event) => {
            const data = GetEditorState(model, store.getState()).get();
            (data as any)[property] = new Date(event.value);
            SetModelSync(model, data);
        }}
    />
}