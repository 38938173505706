import { IRid } from "../IRid.bin";
import { classType } from "./CommonTypes.bin";
import { rid } from "./CommonTypes.bin";

export type OrientInstanceId = rid;

export class OrientInstance implements IRid {
	"@rid"?: rid;
	"@class"?: classType;
	"@version"?: string;

}