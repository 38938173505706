import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import React from "react";
import { FavoriteVertexAutoComplete } from "../AdwAutocomplete/FavoriteVertexAutoComplete";
import { Trad } from "trad-lib";
import { TextField } from "@material-ui/core";
import { GetHashCode } from "hub-lib/tools.bin";
import { GetDefaultAdvertisingCompany, LnkAdv } from "../../redux/messageEditorSlice";
import { ref_SubAgencies } from "hub-lib/models/orientdb/ref_SubAgencies.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { IRid } from "hub-lib/models/IRid.bin";

type AdvertisingCompaniesLinkSelectorProps = {
    onChange: (value: IRid['@rid']) => void,
    defaultValue?: ref_AdvertisingCompanies['@rid'],
    support: ref_Supports['@rid'],
    broadcastArea: ref_BroadcastAreas['@rid']
}

export function AdvertisingCompaniesLinkSelector({ support, broadcastArea, defaultValue, onChange }: AdvertisingCompaniesLinkSelectorProps) {
    const [value, setValue] = React.useState<IRid['@rid']>();
    const [options, setOptions] = React.useState<(IRid & { Name: string })[]>();

    const selectValue = (_value: IRid['@rid']) => {
        setValue(_value);
        onChange(_value);
    }

    React.useEffect(() => {
        GetDefaultAdvertisingCompany(support, broadcastArea)
            .then(res => {
                setOptions(res.options);
                selectValue(res.defaultValue);
            })
    }, [support, broadcastArea])

    const getDefaultValue = _options => {
        const res = _options.find(o => o["@rid"] == defaultValue);
        return res;

    }
    return <FavoriteVertexAutoComplete
        key={`AdvertisingCompaniesLinkSelector-${support}-${GetHashCode({ options, defaultValue })}`}
        options={options ?? []}
        type={ref_AdvertisingCompanies.name}
        label={Trad(ref_AdvertisingCompanies.name)}
        defaultValue={getDefaultValue}
        onChange={_value => selectValue(_value?.["@rid"])}
        onResetValue={(options: any) => undefined as any}
        renderInput={(params) =>
            <><TextField
                error={value === undefined}
                fullWidth
                {...params}
                label={Trad(ref_AdvertisingCompanies.name)}
                variant="outlined"
                inputProps={{
                    ...params.inputProps
                }}
            /></>
        } />
}