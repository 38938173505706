import { rid } from "../../models/orientdb/CommonTypes.bin";
import { IUpsertInfo } from "../../types";
import { User } from "../../models/orientdb/User.bin";
import { ref_DiscountClassesId } from "../../models/orientdb/ref_DiscountClasses.bin";
import { ref_BroadcastAreasId } from "../../models/orientdb/ref_BroadcastAreas.bin";

export class clnt_Referentials implements IUpsertInfo {
    "@rid"?: rid;
    Active: boolean;
    Referential?: rid;
    Created_by?: User["@rid"];
    Created_at?: Date;
    Updated_by?: User["@rid"];
    Updated_at?: Date;
    ModelProperties: { [key: string]: any } = {};
}

export class clnt_DiscountRate {
    BroadcastArea?: ref_BroadcastAreasId;
    Rates: { [ridDiscountClasses: string]: number }
}

export class clnt_Companies extends clnt_Referentials {
    DiscountRates?: {
        [ridMedia: string]: clnt_DiscountRate[];
    }
}
