
import { FormatCellsMemoized } from "adwone-engine/EngineTools";
import { CreateIndicateur, Indicateur, IndicateurToString } from "adwone-engine/index.bin";
import { CellValue } from "adwone-engine/types.bin";
import { Format, GetCellTemplate } from "format-lib/index.bin";
import { clone, GetSubElement, JoinElements, toArray } from "../../tools.bin";
import { IRid } from "../../models/IRid.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { OUserId } from "../../models/orientdb/OUser.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
import { ref_Messages } from "./ref_Messages.bin";
import { ClassProperty, ePropType } from "../../models/VertexProperty.bin";

export type ref_SchedulerConfigurationsId = rid;
export type Color = {
    id?: string,
    code: string
}

export type TemplatedRid = {
    type: string,
    key?: string,
    textStyle?: {
        bold?: boolean,
        italic?: boolean,
        underline?: boolean,
        color?: Color,
        hidden?: boolean
    }
}

export type propertyBuilder = {
    Type: "property";

    /**
     * key of something if Type = property
     * Text if Type = text
     */
    Value: string;
}

export type textBuilder = {
    Type: "text";

    /**
     * key of something if Type = property
     * Text if Type = text
     */
    Value: string;
}

export type indicateurBuilder = {
    Type: "indicateur";

    /**
     * key of something if Type = property
     * Text if Type = text
     */
    Value: Indicateur;
}

export type LabelBuilder = textBuilder | propertyBuilder | indicateurBuilder;

export class ref_SchedulerConfigurations extends ref_ConfigurationsBase {
    "@rid"?: ref_SchedulerConfigurationsId;
    Active: boolean = true;
    Default?: boolean = false;
    Name: string = "default name";
    Owner: OUserId;
    StartLabel: LabelBuilder;
    EndLabel?: LabelBuilder;
    LabelBuildTop: LabelBuilder[] = [];
    LabelBuild: LabelBuilder[] = [];
    LabelBuildBottom: LabelBuilder[] = [];
    Style: { [ridElement: string]: TemplatedRid } = {};
    StyleDefault: TemplatedRid = null;
    BarProperty?: string;
    resizePeriod?: boolean
}



const priority: (keyof ref_Messages | string)[] = [
    "Placement",
    "Format",
    "Campaign",
    "Product",
    "Brand",
    "Advertiser",
    "AdvertiserGroup",
    "BroadcastArea",
    "Support",
    "AdvCompany_Fin",
    "AdvCompany_Com",
    "Media",
    "Currency",
    "ModelProperties.AdCreation"
];

export function GetKeyTemplates(key: keyof ref_Messages | string, messages: ref_Messages[], style: ref_SchedulerConfigurations['Style']): (TemplatedRid & { rid: string })[] {
    const allRids: string[] = messages.map(m => GetSubElement(m, key))
        .map(v => Array.isArray(v) ? v : [v])
        .reduce((a, b) => a.concat(b), [])
        .filter(v => typeof v === "string");
    const uniqRids = Array.from(new Set(allRids));
    const res = uniqRids
        .filter(_rid => style?.[_rid])
        .map(_rid => (clone({ ...style?.[_rid], rid: _rid })));
    return res;
}

export function GetTemplate(messages: ref_Messages[], style: ref_SchedulerConfigurations['Style']): (TemplatedRid & { rid: string }) {

    if (!style || !messages)
        return null;

    for (const k of priority) {
        const res = GetKeyTemplates(k, messages, style);
        if (res.length === 1) {
            return res[0];
        }
    }

    return null
}

export function GetTemplates(messages: ref_Messages[], style: ref_SchedulerConfigurations['Style']): (TemplatedRid & { rid: string })[] {
    if (!style || !messages)
        return null;
    return priority.flatMap(k => GetKeyTemplates(k, messages, style));
}


export async function BuildLabel(messages: ref_Messages[], template: ref_SchedulerConfigurations, allMetadata: ClassProperty[], objProvider: (v: string, p: any) => Promise<IRid[]>, labelField: 'LabelBuild' | 'LabelBuildTop' | 'LabelBuildBottom' | 'StartLabel' | 'EndLabel' = 'LabelBuild') {

    if (!messages)
        return;

    const text: string[] = [];
    if (template?.[labelField])
        for (const elmnt of toArray(template?.[labelField])) {

            if (elmnt.Type === "indicateur") {
                const indInstance = CreateIndicateur(elmnt.Value);
                const value = await indInstance.Compute(messages);
                const cellVal: CellValue[] = [{ Value: value, IndicateurSignature: IndicateurToString(indInstance), Type: 'cell', Formated: '' }];
                const [formatedCell] = toArray(await FormatCellsMemoized(ref_Messages.name, indInstance, cellVal));
                text.push(formatedCell?.Formated ?? value);
                continue;
            }

            if (elmnt.Type === "property") {

                const metadataProp = allMetadata.find(meta => meta.name == elmnt.Value);
                const cellTemplate = GetCellTemplate(ePropType.Date);
                if (metadataProp)
                    switch (metadataProp.type) {

                        case ePropType.Date:
                            if (elmnt.Value == "Start") text.push(cellTemplate(new Date([...messages].sort((a, b) => new Date(a[elmnt.Value]).getTime() - new Date(b[elmnt.Value]).getTime())?.[0]?.[elmnt.Value])));
                            else if (elmnt.Value == "End") text.push(cellTemplate(new Date([...messages].sort((a, b) => new Date(b.End).getTime() - new Date(a.End).getTime())?.[0]?.End)));
                            else text.push(cellTemplate(new Date([...messages].sort((a, b) => new Date(a[elmnt.Value]).getTime() - new Date(b[elmnt.Value]).getTime())?.[0]?.[elmnt.Value])));
                            break;

                        case ePropType.Link:
                        case ePropType.LinkSet:
                        case ePropType.LinkList:

                            const rids = messages
                                .map(m => GetSubElement(m, elmnt.Value))
                                .filter(v => v);

                            if (rids.length) {
                                const objts = (await objProvider(metadataProp.linkedClass, { "@rid": rids })) ?? [];
                                const strs = Array.from(new Set(objts.map(o => Format(o))));
                                text.push(JoinElements(strs));
                            }

                            break;

                        default:
                            text.push(JoinElements(messages.map(m => GetSubElement(m, elmnt.Value)?.toString?.())));
                            break;
                    }

                continue;
            }

            console.error('element not iplemented.', elmnt);
        }

    // apply trim
    const textTrim = text
        .filter(Boolean)
        .map(t => {
            if (typeof t === "string")
                t.trim();
            return t;
        });

    // remove empty
    const textNoEmpty = textTrim.filter(t => t);

    return textNoEmpty;
}
