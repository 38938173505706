import * as React from "react";
import { compareObjects, duplicate, GetHashCode } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";

export type FiltersChangeTriggerProps = { onChange: (data: Partial<Filter>) => void, modele?: true }
export function FiltersChangeTrigger({ onChange, modele }: FiltersChangeTriggerProps) {

    const isMounted = React.useRef(false);
    const filters = duplicate(useSelector((root: RootState) => modele ? root.project.filtersModele?.Filters : root.project.filters, compareObjects));
    const isInitialized = useSelector((root: RootState) => root.project.isInitialized);

    React.useEffect(() => {
        if (isMounted.current)
            onChange(filters);
    }, [GetHashCode(filters)])

    React.useEffect(() => {
        if (isInitialized)
            isMounted.current = true;
    }, [isInitialized])

    return <></>
}