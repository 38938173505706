import * as React from "react";
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { SupportHierarchyComponent } from "../SupportHierarchyComponent/SupportHierarchyComponent";
import { StoreFiltersMessages } from "../AdvertiserHierarchyComponent/StoreFilters";
import { clearEmptyValues } from "hub-lib/tools.bin";
import { VisualFilters } from "./VisualFilters";
import { KeyValidator } from "../../../utils/KeyValidator";

export class TProps {
    objectType: string;
    config: Partial<Filter>;
    applyConfig: (config: Partial<Filter>) => any;
}

export function AdvancedFilters({
    objectType,
    config,
    applyConfig }: TProps) {

    const onChange = (filters) => applyConfig?.(clearEmptyValues({ ...config, ...filters }));

    return <>
        <SupportHierarchyComponent
            objectType={objectType}
            store={config}
            reduxStore={StoreFiltersMessages}
            onChange={onChange} />

        <KeyValidator propertyName="ModelProperties.AdCreation" objectType={objectType}>
            <VisualFilters
                store={config}
                onChanged={onChange} />
        </KeyValidator>
    </>;
}