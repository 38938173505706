import { ref_Property, ref_PropertyId } from "./ref_Property.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Placements, mm_PlacementsId } from "./mm_Placements.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasPlacementId = rid;

export class vw_mm_HasPlacement extends ReferentialHasViews<src_MMId, mm_PlacementsId, ref_PropertyId> {
	"@rid"?: vw_mm_HasPlacementId;

}