import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { extractSub, firstOrDefault, JSONEqualityComparer } from "hub-lib/tools.bin";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../../redux/messageEditorSlice";
import { RootState, store } from "../../../../redux/store";
import { CampaignPicker, AdvertiserInfos, SupportInfos } from "../../Campaigns/CampaignPicker";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { Trad } from "trad-lib";
import { Grid } from "@material-ui/core";
import { KeyValidator } from "../../../../utils/KeyValidator";

type CampaignPickerEditorArgs = {
    importRid?: any,
    validationMode?: boolean
}

export function CampaignPickerEditor({ importRid, validationMode }: CampaignPickerEditorArgs) {

    const dispatch = useDispatch();

    const kpiManager = KPIsManagerCache.GetInstance(ref_Messages.name);
    const properties = kpiManager.SelectorProperties();
    const lockNext = useSelector((root: RootState) => root.messageEditor.lockNext);
    const message = useSelector((root: RootState) => extractSub(root.messageEditor.get(), properties as (keyof ref_Messages)[]), JSONEqualityComparer);
    const campaign = useSelector((root: RootState) => root.messageEditor.getCampaign());
    const [loadingCamp, setLoadingCamp] = React.useState<ref_Campaigns["@rid"]>();

    const ReloadSelectedCampaign = async (campaignRid: string) => {
        const data = store.getState().messageEditor.get();
        data.Campaign = campaignRid;
        dispatch(setMessage(data));
    };

    React.useEffect(() => {
        const _message = store.getState().messageEditor.get();

        if (_message.Campaign && !campaign) {
            if (loadingCamp == _message.Campaign) return; // already loading
            setLoadingCamp(_message.Campaign);
            Promise.resolve().then(async () => await ReloadSelectedCampaign(_message.Campaign));
        }
    })

    return <>
        <Grid item xs={12} className="adw-title">{Trad("campaign_association")}</Grid>
        <Grid container className='block-container'>
            <CampaignPicker
                isLocked={lockNext}
                defaultValue={campaign}
                hideSearchButton={Boolean(importRid)}
                validationMode={validationMode}
                params={{ ...(importRid && { CacheInfos: { Key: importRid, Type: "Import" }, properties: ["*"] }) }}
                onChange={async value => await ReloadSelectedCampaign(value?.["@rid"])}
            />
            <KeyValidator objectType="ref_Campaigns" propertyName="Advertiser">
                <AdvertiserInfos
                    store={message}
                    campaign={campaign}
                    onStoreChanged={(_store: any, propsLocked) => {
                        const message = store.getState().messageEditor.get();
                        propsLocked.forEach(p => message[p as any] = _store[p]);
                        dispatch(setMessage(message));
                    }}
                />
            </KeyValidator>
            <KeyValidator objectType="ref_Campaigns" propertyName="Support">
                <SupportInfos campaign={campaign} />
            </KeyValidator>
        </Grid>
    </>
}