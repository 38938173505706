import axios, { AxiosRequestConfig } from "axios";
import { EventEmitter } from "events";
import { clone } from "../tools.bin";

export type Resp<T> = {
    data: T;
    status: number;
    statusText: string;
    headers: any;
    config: any;
    request?: any;
};

export type Config = AxiosRequestConfig;

export class ClientAXIOS {
    private urlBase: string | (() => string);

    private _defaultAxiosConfig: AxiosRequestConfig;
    get defaultAxiosConfig(): AxiosRequestConfig {
        return this._defaultAxiosConfig;
    }

    get headers(): AxiosRequestConfig["headers"] {
        return {
        }
    }

    get url() {
        return typeof this.urlBase === "function" ? this.urlBase() : this.urlBase;
    }

    onRequestDone: EventEmitter = new EventEmitter();

    constructor(urlBase: string | (() => string), config: AxiosRequestConfig = {}) {
        this.urlBase = urlBase;
        this._defaultAxiosConfig = config;
    }

    protected async mergeConfigs(config?: AxiosRequestConfig, config2?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        return {
            ...config,
            ...config2,
            headers: {
                ...(config?.headers ?? {}),
                ...(config2?.headers ?? {}),
                ...this.headers
            }
        };
    };

    protected onRequestError(err: any, args) {

    }

    protected async execPost<T = any>(
        url: string,
        body: any,
        config?: AxiosRequestConfig
    ): Promise<Resp<T>> {

        const time1594 = new Date().getTime();
        const mergeConfig = await this.mergeConfigs(this.defaultAxiosConfig, config)
        const args = clone({
            url,
            body,
            mergeConfig,
            config,
            defaultAxiosConfig: this.defaultAxiosConfig

        })
        const cloneArgs = clone(args);
        try {
            const resp = await axios.post(args.url, args.body, mergeConfig);
            try {
                this.onRequestDone.emit("POST", url);
            } catch (error) {

            }
            return resp;

        } catch (error) {
            const _time1594 = new Date().getTime();
            this.onRequestError(error, { time: _time1594 - time1594, ...cloneArgs });
            throw error;
        }
    }

    protected async execPostSecure<T = any>(
        url: string,
        body: any,
        config?: AxiosRequestConfig
    ): Promise<Resp<T>> {

        const args = clone({
            url, body, config: await this.mergeConfigs(this.defaultAxiosConfig, config)
        })

        try {
            const resp = await axios.post(args.url, args.body, args.config);
            return resp;

        } catch (error) {
            console.error('[execPostSecure]', error);
        }
    }

    protected async execPut<T = any>(
        url: string,
        body: any,
        config?: AxiosRequestConfig
    ): Promise<Resp<T>> {
        const resp = await axios.put(url, body, await this.mergeConfigs(this.defaultAxiosConfig, config));
        this.onRequestDone.emit("PUT", url);
        return resp;
    }

    protected async execGet<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<Resp<T>> {
        const resp = await axios.get(url, await this.mergeConfigs(this.defaultAxiosConfig, config));
        this.onRequestDone.emit("GET", url);
        return resp;
    }

    protected async execDelete<T = any>(
        url: string,
        config?: AxiosRequestConfig,
        data = undefined
    ): Promise<Resp<T>> {
        const resp = await axios.delete(url, { ...await this.mergeConfigs(this.defaultAxiosConfig, config), data });
        this.onRequestDone.emit("DELETE", url);
        return resp;
    }
}

export class ClientBearer extends ClientAXIOS {
    bearer: string;
    client_id: string;
    client_secret: string;
    token_url: string;

    get defaultAxiosConfig(): AxiosRequestConfig {
        return {
            headers: {
                Authorization: `Bearer ${this.bearer}`
            }
        };
    }

    protected async mergeConfigs(config?: AxiosRequestConfig, config2?: AxiosRequestConfig): Promise<AxiosRequestConfig> {

        if (!this.bearer) {

            if (!this.client_id || !this.client_secret || !this.token_url)
                throw new Error('Configuration error: client_id, client_secret and token_url are required for ClientBearer');

            const resp = await axios.post(
                this.token_url,
                `client_id=${this.client_id}&client_secret=${this.client_secret}&grant_type=client_credentials`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );

            this.bearer = resp.data.access_token;
        }

        const baseConfig = await super.mergeConfigs(config, config2);
        const finaleConfig = await super.mergeConfigs(baseConfig, this.defaultAxiosConfig);
        return finaleConfig;
    }
}
