import { V, VId } from "./V.bin";
import { DiscountBase } from "../../dto/referential/ref_DiscountClassesExtended";
import { ref_DiscountTypes, ref_DiscountTypesId } from "./ref_DiscountTypes.bin";
import { ref_Media, ref_MediaId } from "./ref_Media.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_DiscountClassesId = rid;

export class ref_DiscountClasses extends Referentials {
	"@rid"?: ref_DiscountClassesId;
	Base: DiscountBase;
	DiscountType: ref_DiscountTypesId;
	LastPosition: boolean;
	Medias: ref_MediaId[];
	Name: string;
	Operator: number;
	Rank: number;

}