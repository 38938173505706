import * as React from "react";
import { DatePicker, DatePickerProps } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";

import { getIcon } from "adwone-lib/index";
import { CustomDateInput } from "./CustomDateInput.bin";
import { ContainerComponent } from "../VertexGrid/Generic/ContainerComponent";

export class TProps {
    calendar?: DatePicker['props']['calendar'];
    resetDate?: () => void;
    id?: string;
    ignorePopup?: boolean;
    ignoreContainer?: boolean;
    popupAlign?: {
        horizontal: 'left' | 'center' | 'right';
        vertical: 'top' | 'center' | 'bottom';
    };
}

let id = 0;

class TState {
    datePickerKey: number = 0;
}
export class SimpleDatePicker extends React.Component<DatePickerProps & TProps, TState> {
    constructor(props: DatePickerProps & TProps) {
        super(props);
        this.state = new TState();
        id++;
    }
    _el: HTMLElement;

    render() {
        const { ignorePopup, ignoreContainer } = this.props;

        let classNameValid = '';
        if (this.props.hasOwnProperty('valid') && !this.props.valid)
            classNameValid = ' k-invalid ';

        const content = <div className="custom_daterangepicker-container">
            <div
                ref={(el) => (this._el = el)}
                className={`custom_daterangepicker`}
                id={this.props.id ?? "custom_daterangepicker"}
            >
                {/* {this.props.label && <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled">{this.props.label}</label>} */}
                <DatePicker
                    {...this.props}
                    label={null}
                    dateInput={CustomDateInput()}
                    className={`content-simpledatepicker ${this.props.className ?? ""}`}
                    key={`SimpleDatePicker_${this.state.datePickerKey}_${id}`}
                    {...(this.props.calendar && { calendar: this.props.calendar })}
                    {...(!ignorePopup && { popup: (props) => <Popup {...props} popupAlign={this.props.popupAlign ?? props.popupAlign} appendTo={this._el} /> })}
                />
            </div>
            {this.props.resetDate && (
                <div
                    className="clear-date-button"
                    onClick={() => {
                        this.props.resetDate();
                        this.setState({ datePickerKey: this.state.datePickerKey + 1 });
                    }}
                >
                    {getIcon("clear")}
                </div>
            )}
        </div>

        return <>
            {ignoreContainer && content}
            {!ignoreContainer
                && <ContainerComponent
                    className={classNameValid}
                    title={this.props.label}>
                    {content}
                </ContainerComponent>}
        </>

    }
}
