import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ref_Campaigns, TimePerformanceKPI } from "hub-lib/dto/client/ref_Campaigns.bin";
import { SerializeError, clone, duplicate, propertyOf, JSONEqualityComparer } from "hub-lib/tools.bin";
import { RootState } from "./store";
import { ConsoleDebug, GetUser, IsMMUser } from "../utils/localstorage.bin";
import { Client } from "hub-lib/client/client.bin";
import { Notify } from "../utils/Notify.bin";
import { Trad, TradComposed } from "trad-lib";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { IEditorState, OnSupportChange, SetGroup, SetPerformancesKPIFromOJD, TCurrency, UpdateAgencyState, UpdateBroadcastState, UpdateCurrencyState } from "./storeUtils";
import { eGroupCategories, ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { ref_Comments } from "hub-lib/dto/client/ref_Attachments.bin";
import { ref_Publications } from "hub-lib/dto/client/ref_Publications.bin";
import { RightManager, eRights, eFunctions } from "hub-lib/models/types/rights.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { QuestionsForm } from "hub-lib/dto/QuestionsForm";
import { ref_Estimates } from "hub-lib/dto/client/ref_Estimates.bin";
import { eDialogMode } from "../components/ConfigurableComponents/BasicGenericDialog.bin";
import moment from "moment";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";

export interface ref_CampaignsSerializable extends Omit<ref_Campaigns, "Start" | "End"> {
    Start: number;
    End: number
}

export interface CampaignEditorState extends IEditorState<ref_Campaigns, ref_CampaignsSerializable> {
    // createEstimate: boolean;
    // updateEstimate: boolean;
    enableEstimate: boolean;
    selectedEstimate: number;
    attachments: { [type: string]: string };
    form: QuestionsForm
}

const initialState: CampaignEditorState = {
    mode: undefined,
    enableEstimate: false,
    form: null,
    data: undefined,
    selectedEstimate: 0,
    // createEstimate: false,
    // updateEstimate: false,
    attachments: undefined,
    agencyOptions: undefined,
    currencyOptions: undefined,
    broadcastOptions: undefined,
    lockNext: false,
    validationIssues: [],
    publications: undefined,
    groups: { MediaFamily: [] },
    get() {
        return this.data && ToRefCampaign(this.data)
    }
}

export function ToRefCampaign(campaign: ref_CampaignsSerializable): ref_Campaigns {
    return {
        ...duplicate(campaign),
        Start: campaign.Start ? new Date(campaign.Start) : undefined,
        End: campaign.End ? new Date(campaign.End) : undefined
    }
}

export function ToRefCampaignSerializable(campaign: ref_Campaigns): ref_CampaignsSerializable {
    return {
        ...duplicate(campaign),
        Start: campaign.Start ? new Date(campaign.Start).getTime() : undefined,
        End: campaign.End ? new Date(campaign.End).getTime() : undefined,
    }
}

export function SetValidationIssues(campaign: ref_Campaigns | ref_CampaignsSerializable, oldValidationIssues: string[]): (keyof ref_Campaigns)[] {

    const issues = [];
    let mandatory = ["Name", "Start"];
    if (GetUser()?.customer?.AdvertiserMode == ref_Campaigns.name) {
        if (RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read))
            mandatory.push("AdvertiserGroup");
        else
            mandatory.push("Advertiser");
    }
    if (GetUser()?.customer?.SupportMode == ref_Campaigns.name)
        mandatory = [...mandatory, ...["Support", "Currency", "BroadcastArea"]];

    mandatory.forEach(p => {
        if (!campaign[p])
            issues.push(p);
    })
    return issues;
}

export function TotalWarningData(data: Array<TimePerformanceKPI>) {
    const grps = data.filter(d => d.time != "total").map(d => d.Performances["GRP"]);

    const grpSums = grps.reduce((partial: { sumObjective: number, sumDone: number }, grp) => (
        {
            sumObjective: partial.sumObjective + grp.objective,
            sumDone: partial.sumDone + grp.done
        }), { sumObjective: 0, sumDone: 0 });
    const total = data.find(d => d.time == "total");
    total.Performances["GRP"]["ObjectiveWarning"] = grps.length != 0 && grpSums.sumObjective != total.Performances["GRP"].objective;
    total.Performances["GRP"]["DoneWarning"] = grps.length != 0 && grpSums.sumDone != total.Performances["GRP"].done;
}

async function UpdatePerformances(newCampaign: ref_Campaigns, oldCampaign: ref_Campaigns) {

    const GetIndicateurName = (mode: string) => {
        if (mode == "days")
            return Trad("number_of_days");
        else if (mode == "weeks")
            return Trad("number_of_weeks");
        return null;
    }

    const TimeFormat = (iterator: number, mode: string) => {
        if (!newCampaign.Start)
            return null;
        const prefixe = mode == "days" ? "J" : "S";
        const date = moment(newCampaign.Start).add(iterator, mode == "days" ? "days" : "weeks");
        return `${prefixe}${iterator + 1} - ${date.format("DD/MM")}`;
    }

    const PeriodeChange = () => {
        return moment(newCampaign.Start).format("DD/MM/YY") != moment(oldCampaign.Start).format("DD/MM/YY") ||
            moment(newCampaign.End).format("DD/MM/YY") != moment(oldCampaign.End).format("DD/MM/YY")
    }

    const ModeChange = (mediaId) => {
        return newCampaign.Performances[mediaId].TimeMode != oldCampaign?.Performances?.[mediaId].TimeMode;
    }

    const initTimeLine = (_data: TimePerformanceKPI[], timeKpis: string[], keyTime: string, _oldData: TimePerformanceKPI[]) => {
        let line = { time: keyTime, Performances: {} };
        let oldLine = _oldData?.find(d => d.time == keyTime);

        timeKpis.forEach(kpi => {
            if (oldLine?.Performances?.[kpi])
                line.Performances[kpi] = clone(oldLine.Performances[kpi]);
            else
                line.Performances[kpi] = { objective: 0, done: 0 };
        })
        _data.push(line);
    };

    if (newCampaign.Performances) {
        const indicateurs = await AggregatorFactory.GetInstance().Get(ref_Campaigns).Provide();
        const periodChange = PeriodeChange();
        const timeValues: { [key: string]: number } = {};

        const defaultKPIs = ["GRP", "coverage", "CGRP"];
        const optionalKPIs = ["contacts", "coverage_thousands", "repetition"];



        for (const mediaId of Object.keys(newCampaign.Performances)) {
            const timeKpis: string[] = [];
            const totalPerformance = newCampaign.Performances[mediaId].TimePerformance?.find(tp => tp.time == "total")?.Performances;
            const activeKPIs = totalPerformance ? Object.keys(totalPerformance)?.filter(key => !defaultKPIs.includes(key)) : [];

            if (periodChange || ModeChange(mediaId)) {
                const indicateurName = GetIndicateurName(newCampaign.Performances[mediaId].TimeMode);
                let value = 0;
                if (indicateurName) {
                    if (!timeValues[indicateurName]) {
                        const indicateur = indicateurs.find(i => i.indicateur.name == indicateurName)?.indicateur;
                        timeValues[indicateurName] = Math.round(await indicateur.Compute([newCampaign as any]));
                    }
                    value = timeValues[indicateurName];
                }
                const newPerformances = newCampaign.Performances[mediaId].TimePerformance;
                const oldPerformances = oldCampaign.Performances[mediaId].TimePerformance;
                initTimeLine(newPerformances, timeKpis, "total", oldPerformances);
                for (let i = 0; i < value; i++) {
                    const key = TimeFormat(i, newCampaign.Performances[mediaId].TimeMode);
                    initTimeLine(newPerformances, timeKpis, key, oldPerformances);
                }
                TotalWarningData(newPerformances);
            }
        }
    }
}

export const setCampaign = createAsyncThunk(
    'campaignEditor/campaign',
    async (campaign: ref_Campaigns, thunkAPI) => {
        try {
            const time7642 = new Date().getTime();
            ConsoleDebug('campaign param: ', campaign);

            const state = thunkAPI.getState() as RootState;
            ConsoleDebug('previous ', state.campaignEditor.data);

            const isInitializing = Boolean(!state.campaignEditor.data);
            const oldCampaign = (state.campaignEditor.data ?? new ref_Campaigns);

            const newCampaign = clone(campaign);
            const isCreating = newCampaign && !newCampaign['@rid'];

            const kpiManager = KPIsManagerCache.GetInstance(ref_Campaigns.name);
            const properties = await kpiManager.GetUserProperties();

            if (newCampaign.Start && ['string', 'number'].includes(typeof newCampaign.Start)) newCampaign.Start = new Date(newCampaign.Start);
            if (newCampaign.End && ['string', 'number'].includes(typeof newCampaign.End)) newCampaign.End = new Date(newCampaign.End);

            let broadcastOptions: ref_BroadcastAreas[] = undefined;
            let currencyOptions: TCurrency[] = undefined;

            if (!isInitializing && oldCampaign.Support !== newCampaign.Support) {
                OnSupportChange("ref_Campaigns", oldCampaign, newCampaign);
            }

            if (newCampaign.Media) {
                const cloneCampaignEditor = clone(state.campaignEditor);
                broadcastOptions = await UpdateBroadcastState(cloneCampaignEditor, newCampaign);
                currencyOptions = await UpdateCurrencyState(cloneCampaignEditor, newCampaign);
            }

            let mediaFamilyOptions: ref_Groups[] = null;
            let enableEstimate = false;
            if (isInitializing && !isCreating) {
                enableEstimate = campaign?.Estimates?.length !== 0;
            }
            if (isInitializing) {
                if (!newCampaign.Performances)
                    newCampaign.Performances = {};
                if (newCampaign.Media) {
                    if (!newCampaign.Performances[campaign.Media])
                        newCampaign.Performances[campaign.Media] = {};
                }
                else {
                    const medias = await KPIsManagerCache.GetMedias();
                    medias.forEach(m => {
                        if (!newCampaign.Performances[m["@rid"]])
                            newCampaign.Performances[m["@rid"]] = {};
                    })
                    console.log("campaign.Performances", newCampaign.Performances);
                }
            }

            /** SupportDetails */
            if (properties.some(p => p.name == "ModelProperties.SupportDetails") && oldCampaign.Support !== newCampaign.Support && !isInitializing) {
                const support = (await Client.searchVertexTyped(ref_Supports, { "@rid": newCampaign.Support }))?.[0];
                newCampaign.ModelProperties["SupportDetails"] = support?.Description;
            }

            if (properties.some(p => p.name == "ModelProperties.MediaFamily") && oldCampaign.Support !== newCampaign.Support)
                mediaFamilyOptions = await SetGroup(newCampaign, newCampaign.Support, eGroupCategories.MediaFamily, "MediaFamily", "MediaFamilyOther", !isInitializing)

            if (!isInitializing && isCreating)
                await SetPerformancesKPIFromOJD(newCampaign, oldCampaign);

            const validationIssues = SetValidationIssues(newCampaign, state.campaignEditor.validationIssues);

            const _time7642 = new Date().getTime();
            ConsoleDebug(`SetCampaign Elapsed ${_time7642 - time7642}ms`, newCampaign, validationIssues);

            let form: QuestionsForm = null;
            if (RightManager.hasRight(eFunctions.ref_Estimates, eRights.read)
                && (enableEstimate != state.campaignEditor.enableEstimate
                    || (newCampaign?.AdvertiserGroup != oldCampaign?.AdvertiserGroup
                        || newCampaign?.Advertiser != oldCampaign?.Advertiser
                        || newCampaign?.Brand != oldCampaign?.Brand
                        || newCampaign?.Product != oldCampaign?.Product))) {
                const mode = isCreating ? eDialogMode.create : eDialogMode.modify;
                form = await Client.getForm(ref_Estimates.name, { [propertyOf<ref_Estimates>('Campaign')]: newCampaign, mode });
            }

            return { campaign: ToRefCampaignSerializable(newCampaign), enableEstimate, form, /*createEstimate,*/ broadcastOptions, validationIssues, currencyOptions, mediaFamilyOptions }
        } catch (error) {
            console.error(error);
            Notify(Trad('error'), 'error');
            Client.log({ Category: 'ERROR', Action: 'setCampaign', Informations: SerializeError(error) });
        }
    }
)

export const initCampaignAgencyOptions = createAsyncThunk(
    'campaignEditor/initAgencyOptions',
    async (_: ref_Campaigns, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const agencyOptions = await UpdateAgencyState(state.campaignEditor, state.campaignEditor.get());
            return { agencyOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initCampaignCurrencyOptions = createAsyncThunk(
    'campaignEditor/initCurrencyOptions',
    async (_: ref_Campaigns, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const currencyOptions = await UpdateCurrencyState(state.campaignEditor, state.campaignEditor.get());
            return { currencyOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initCampaignBroadcastOptions = createAsyncThunk(
    'campaignEditor/initBroadcastOptions',
    async (_: ref_Campaigns, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootState;
            const broadcastOptions = await UpdateBroadcastState(state.campaignEditor, state.campaignEditor.get());
            return { broadcastOptions }
        } catch (error) {
            console.error(error)
        }
    }
)

export const initAttachments = createAsyncThunk(
    'campaignEditor/initAttachments',
    async (_data: ref_Campaigns, thunkAPI) => {
        try {
            let attachments = {
                "External": (_data?.Attachments?.["External"] as ref_Comments)?.Text ?? "",
                "Internal": (_data?.Attachments?.["Internal"] as ref_Comments)?.Text ?? ""
            }
            return { attachments }
        } catch (error) {
            console.error(error)
        }
    }
)

export const campaignEditorSlice = createSlice({
    name: 'campaignEditor',
    initialState: clone
        (initialState),
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        builder.addCase(setCampaign.pending, (state, action) => {
            state.lockNext = true;
        })

        const fulfilledCallback = (state: CampaignEditorState, action) => {
            if (action.payload?.campaign)
                state.data = action.payload.campaign;
            if (action.payload?.validationIssues)
                state.validationIssues = action.payload.validationIssues;
            if (action?.payload?.attachments)
                state.attachments = action.payload.attachments;
            if (action?.payload?.agencyOptions)
                state.agencyOptions = action.payload.agencyOptions;
            if (action?.payload?.currencyOptions)
                state.currencyOptions = action.payload.currencyOptions;
            if (action?.payload?.broadcastOptions)
                state.broadcastOptions = action.payload.broadcastOptions;
            if (action?.payload?.mediaFamilyOptions)
                state.groups.MediaFamily = action.payload.mediaFamilyOptions;
            if (action?.payload?.form)
                state.form = action.payload.form;
            if (action?.payload?.enableEstimate)
                state.enableEstimate = action.payload.enableEstimate;
        }

        builder.addCase(setCampaign.fulfilled, (state, action) => {
            fulfilledCallback(state, action);
            state.lockNext = false;
        });

        builder.addCase(initAttachments.fulfilled, fulfilledCallback);
        builder.addCase(initCampaignAgencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initCampaignCurrencyOptions.fulfilled, fulfilledCallback);
        builder.addCase(initCampaignBroadcastOptions.fulfilled, fulfilledCallback);
    },
    reducers: {
        setCampaignMode: (state, action: PayloadAction<eDialogMode>) => {
            state.mode = action.payload;
        },
        setCampaignSync: (state, action: PayloadAction<ref_Campaigns>) => {

            if (!action.payload) {
                const cloneValues = clone(initialState);
                Object.entries(cloneValues).forEach(([key, value]) => {
                    state[key] = value;
                });
                return;
            }

            const newCampaign: ref_CampaignsSerializable = {
                ...duplicate(action.payload),
                Start: action.payload.Start ? new Date(action.payload.Start).getTime() : undefined,
                End: action.payload.End ? new Date(action.payload.End).getTime() : undefined,
            }
            if (!newCampaign.ModelProperties) newCampaign.ModelProperties = {};
            state.data = newCampaign;
            state.validationIssues = SetValidationIssues(newCampaign, state.validationIssues);
            console.log("SetCampaignSync", newCampaign);
        },
        setCampaignPublications: (state, action: PayloadAction<ref_Publications[]>) => {
            state.publications = action.payload ? duplicate(action.payload) : undefined;
        },
        clearCampaignEditor: (state, action: PayloadAction<void>) => {
            state.data = undefined;
            state.currencyOptions = undefined;
            state.broadcastOptions = undefined;
        },
        setSelectedEstimate: (state, action: PayloadAction<number>) => {
            state.selectedEstimate = action.payload;
        },
        // setCreateEstimate: (state, action: PayloadAction<boolean>) => {
        //     if (state.createEstimate != action.payload)
        //         state.data.Estimates = [];
        //     state.createEstimate = action.payload;
        //     state.selectedEstimate = 0;
        // },
        // setUpdateEstimate: (state, action: PayloadAction<boolean>) => {
        //     state.createEstimate = action.payload;
        //     state.updateEstimate = action.payload;
        // },
        setEnableEstimate: (state, action: PayloadAction<boolean>) => {
            state.enableEstimate = action.payload;
            if (!action.payload)
                state.data.Estimates = [];
            else if (!state.data.Estimates?.length) {
                const isMMUser = IsMMUser();
                if (!state.data.Estimates)
                    state.data.Estimates = [];
                state.data.Estimates.push({ EstimateLib: TradComposed("estimate_number", ["1"]), Source: isMMUser ? "MM" : "Sellsy", ExternalID: null, CreateParams: {} });
            }

            state.selectedEstimate = 0;
        },
        AddNewEstimate: (state, action: PayloadAction<{ nb?: number | string, source?: string, clear?: boolean, createParams?: any }>) => {
            const { nb, source, clear, createParams } = action.payload;
            const isMMUser = IsMMUser();
            if (clear) {
                // state.createEstimate = true;
                state.data.Estimates = [];
                //setState({ ...state, selected: 0, CreateEstimate: true });*/
            }
            state.data.Estimates.push({ EstimateLib: TradComposed("estimate_number", [nb.toString()]), Source: source ?? isMMUser ? "MM" : "Sellsy", ExternalID: null, CreateParams: createParams ?? {} });
            //forceUpdate();
        },
        setAttachment: (state, action: PayloadAction<{ type: string, value: string }>) => {
            state.attachments[action.payload.type] = action.payload.value;
        }
    }
})

// Action creators are generated for each case reducer function
export const { setCampaignMode, setCampaignSync, clearCampaignEditor, setSelectedEstimate, setEnableEstimate, /*setCreateEstimate, setUpdateEstimate,*/ AddNewEstimate, setAttachment, setCampaignPublications } = campaignEditorSlice.actions

export const campaignEditorReducer = campaignEditorSlice.reducer;