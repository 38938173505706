import { Client } from 'hub-lib/client/client.bin'
import history from '../utils/history'
import { UrlParser } from 'tools-lib'

import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS
} from './types'


export const login = (res) => dispatch => {

    let route = null;
    const state = UrlParser("state");
    if (state) {
        try {
            route = JSON.parse(atob(decodeURIComponent(state)))?.redirect;
        } catch (error) {
            
        }
    }

    Client.log({
        Category: "auth",
        Action: "login",
        Informations: { Connect: 'Success', Category: 'Authentification' }
    })
    dispatch({
        type: LOGIN_SUCCESS,
        payload: res.user
    })
    history.push(route ?? '/')
}

/*
export const login = ({ email, password }) => dispatch => {

    Client.login(email, password)
        .then(res => {
            Client.log("Connection", { Connect: 'Success', Category: 'Authentification' })
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data.user
            })
            history.push('/')
        })
        .catch(err => {
            console.log(err)
        })
}
*/

export const logout = () => dispatch => {

    Client.logout()
        .then(() => {
            Client.log({
                Category: "auth",
                Action: "logout",
                Informations: { Deconnect: 'Success', Category: 'Authentification' }
            })
            dispatch({
                type: LOGOUT_SUCCESS
            })
            history.push('/login')
        })
        .catch(err => {
            console.log(err)

        })
}