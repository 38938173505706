import * as React from "react";
import { Trad } from "trad-lib";
import { eStateType, ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { Notify } from "../../../utils/Notify.bin";
import { CustomGroupButton } from "../../ConfigurableComponents/CustomGroupButton.bin";
import { AdwRow } from "adwone-lib/index";
import { getIcon } from "../../ConfigurableComponents/IconManager.bin";
import { MMContractStatus } from "client-mbs/EstimatesResponse.bin";
class TProps {
    selectedItems: AdwRow<ref_Messages>[];
    onUpdate : (update : string) => void;
    contractStatus: string;
}
export class MessagesActions extends React.Component<TProps, any> {
    findBilledMessage = (rows: AdwRow<ref_Messages>[]) => {
        return rows.filter((r) => r.dataItem.State === eStateType.Billed).length > 0;
    };

    error(msg: string) {
        Notify(msg, "error");
    }
/*
    update = (messages: AdwRow<ref_Messages>[], update : "CO" | "FO") => {
        const { estimateId, estimateCode, grid, contractStatus, afterUpdate } = this.props;
        Client.updateVertex(
            ref_Estimates.name,
            {
                "@rid": estimateId,
                action: "Update",
                update: update,
                number : estimateCode,
                messages: messages.map((e) => e.dataItem["@rid"]),
                type: contractStatus,
            },
            false
        )
            .then((res) => {
                Notify(Trad(update + "_updated"), "success");
                grid.UpdateRows();
                afterUpdate();
                return res;
            })
            .catch((err) => {
                let errorMessage = Trad("cant_update_" + update);
                if (err?.response?.data?.error?.data?.message) {
                    errorMessage = `${errorMessage} - ${err.response.data.error.data.message}`;
                }
                this.error(errorMessage);
            });
    };
*/

    render() {
        const { selectedItems, contractStatus } = this.props;
        const buttons = [
            {
                Label: Trad("update_CO"),
                onClick: () => this.props.onUpdate("CO"),
                disabled: this.findBilledMessage(selectedItems),
            },
        ];
        if (contractStatus == MMContractStatus.Mandataire) {
            buttons.push({
                Label: Trad("update_FO"),
                onClick: () => this.props.onUpdate("FO"),
                disabled: false,
            });
        }
        return (
            <CustomGroupButton startIcon={getIcon("reload")} Label={Trad("update")} buttons={buttons} buttonClassName={"custom_btn_primary_validation"} />
        );
    }
}
