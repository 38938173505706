import { DiscountManager } from 'hub-lib/business/DiscountManager.bin';
import { ref_DiscountClasses } from 'hub-lib/models/orientdb/ref_DiscountClasses.bin';
import { JSONEqualityComparer } from 'hub-lib/tools.bin';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

export const SelectorDiscountComputation = (discountClass: ref_DiscountClasses['@rid']) => {
    return useSelector((state: RootState) => {
        const { cascade, currentNetType } = state.messageEditor;
        if (!cascade || !currentNetType) return { computation: null, value: null };
        const computation = cascade[currentNetType].find(d => d.Discount.DiscountClass == discountClass)
        if (!computation) return { computation: null, value: null };

        const value = DiscountManager.getModulation(computation.Discount, currentNetType);
        return { computation, value };
    }, JSONEqualityComparer);
}