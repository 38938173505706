import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
import { ref_KPIs } from "../../models/orientdb/ref_KPIs.bin";
import { ref_Currencies } from "../../models/orientdb/ref_Currencies.bin";
import { IndicateurKPI } from "adwone-engine/index.bin";

export class ref_DashboardConfigurations extends ref_ConfigurationsBase {
    indicateur?: IndicateurKPI;
    currency?: ref_Currencies;
    filters?: {
        [filter: string]: string | string[]
    };
    kilo?: boolean;
    compareWithPreviewYear?: boolean;
    budget?: number;
}

export function RepaireDashboardConfigurations(toRepaire: ref_DashboardConfigurations) {
	return { ...new ref_DashboardConfigurations(), ...toRepaire };
}