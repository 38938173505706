
import * as React from "react";
import { CustomGridLayout } from "./CustomGridLayout.bin";
import { CustomLayout } from "./CustomLayout.bin";
import { KPIsManagerCache, eKPI, eKPIType } from "hub-lib/models/KPIsManager.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { eStatusType, ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ControllableChart } from "../graphs/ControllableChart.bin";
import { TradClassName, Trad } from "trad-lib";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { propertyOf } from "hub-lib/tools.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { Row } from "adwone-engine/types.bin";
import { ref_FilterConfigurations } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { FilterConfigurationWidget } from "./Widgets/FilterConfigurationWidget.bin";
import { ref_ProjectConfigurations } from "hub-lib/dto/client/ref_ProjectConfigurations.bin";
import { ProjectConfigurationWidget } from "./Widgets/ProjectConfigurationWidget.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";

export let key1 = `key-${ref_Advertisers.name} / ${eKPI.Net}`;
export let key2 = `key-${ref_Media.name} / ${eKPI.Net}`;
export let key3 = `key-${ref_Advertisers.name}2 / ${eKPI.Net}`;
export let key4 = `key-ref_Status / ${eKPI.Net}`;
export let key5 = `key-${ref_FilterConfigurations.name}`;
export let key6 = `key-${ref_ProjectConfigurations.name}`;

class TSTate {
    kpiPieChart?: rid;
}

export class DashBoardGridLayout extends React.Component<any, TSTate> {

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    async componentDidMount() {
        let kpiPieChart = (await KPIsManagerCache.GetKPIs()).find(k => k.Name == eKPI.Net);
        this.setState({ kpiPieChart: kpiPieChart["@rid"] });
    }

    render() {
        let { kpiPieChart } = this.state;
        let title2 = `${Trad('Net_investment_by_media')}`;
        let title1 = `${Trad('Net_investment_by_advertiser')}`;
        let title4 = `${Trad('number_msg_status')}`;
        let title5 = `${Trad('direct_filter_configuration')}`;
        let title6 = `${Trad('direct_project_configuration')}`;

        return (<div className="DashBoardGridLayout-container">
            <div className="DashBoardGridLayout-children">
                <CustomGridLayout>

                    <CustomLayout title={title1} key={key1} x={0} y={0} w={2} h={8}>
                        {kpiPieChart &&
                            <ControllableChart
                                multiple
                                chartKey={key1}
                                vertexAutocomplete={[
                                    {
                                        label: TradClassName(ref_AdvertiserGroups.name),
                                        vertexType: ref_AdvertiserGroups.name,
                                        vertexProperty: propertyOf<ref_Messages>("AdvertiserGroup")
                                    }
                                ]}
                                type={"donut"}
                                dimension={{ field: propertyOf<ref_Messages>("Advertiser"), type: "@rid", linkedClass: ref_Advertisers.name }}
                                KPI={kpiPieChart}
                                KPIType={eKPIType.Price} />}
                    </CustomLayout>

                    <CustomLayout title={title2} key={key2} x={2} y={0} w={2} h={8}>
                        {kpiPieChart &&
                            <ControllableChart
                                multiple
                                chartKey={key2}
                                vertexAutocomplete={[
                                    {
                                        label: TradClassName(ref_AdvertiserGroups.name),
                                        vertexType: ref_AdvertiserGroups.name,
                                        vertexProperty: propertyOf<ref_Messages>("AdvertiserGroup")
                                    }
                                ]}
                                type={"donut"}
                                // activateTrad={true}
                                dimension={{ field: propertyOf<ref_Messages>("Media"), type: "@rid", linkedClass: ref_Media.name }}
                                KPI={kpiPieChart}
                                KPIType={eKPIType.Price} />}
                    </CustomLayout>

                    <CustomLayout title={title4} key={key4} x={0} y={8} w={2} h={8}>
                        {kpiPieChart &&
                            <ControllableChart
                                multiple
                                chartKey={key4}
                                // activateTrad={true}
                                vertexAutocomplete={[
                                    {
                                        label: TradClassName(ref_AdvertiserGroups.name),
                                        vertexType: ref_AdvertiserGroups.name,
                                        vertexProperty: propertyOf<ref_Messages>("AdvertiserGroup")
                                    }
                                ]}
                                type={"donut"}
                                dimension={{ field: propertyOf<ref_Messages>("Status"), type: "string" }}
                                customKPI={(a: Row<ref_Messages>) => a?.CountData} />}
                    </CustomLayout>

                    <CustomLayout title={title5} key={key5} x={2} y={8} w={2} h={8}>
                        <FilterConfigurationWidget />
                    </CustomLayout>

                    <CustomLayout title={title6} key={key6} x={2} y={8} w={2} h={8}>
                        {RightManager.hasRight(eFunctions.ref_ProjectConfigurations, eRights.read) && <ProjectConfigurationWidget />}
                    </CustomLayout>

                    {/*<CustomLayout title={title1} key={key3} x={0} y={9} w={4} h={8}>
                    {kpiPieChart &&
                        <ControllableChart
                            label={TradClassName(ref_AdvertiserGroups.name)}
                            vertexType={ref_AdvertiserGroups.name}
                            vertexProperty={propertyOf<ref_Messages>("AdvertiserGroup")}
                            type={"column"}
                            dimension={ref_Advertisers.name}
                            KPI={kpiPieChart}
                            filters={statusFilters} />}
                </CustomLayout>}

                {/*<CustomLayout key="element-3" x={3} y={0} w={1} h={8}>
                    <div>mon element 3</div>
                    </CustomLayout>*/}

                </CustomGridLayout>
            </div>
        </div>);
    }
}