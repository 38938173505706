import { eIndicateurType } from "adwone-engine/index.bin";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";
import { AggregatorBase } from "./AggregatorBase";
import { AggregatorFactory, IndicateurOption } from "./AggregatorFactory";
import { eKPIType, KPIsManagerCache } from "../models/KPIsManager.bin";
import { eColumnType } from "../models/types.bin";
import { GetComputedIO, GetTimeIOs } from "./IndicateurListing";
import { clone, distinct, propertyOf, Typed } from "../tools.bin";
import { Trad, TradProp } from "trad-lib";
import { ref_Messages } from "../dto/client/ref_Messages.bin";

export class CampaignAggregator extends AggregatorBase<ref_Campaigns> {

    protected async GetDefaultColumnNames() {
        const properties = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties();

        let columnNames = [];
        if (properties.some(p => p.name == "Advertiser"))
            columnNames = [...columnNames, ...[
                TradProp("Name", this._objectType),
                TradProp("Start", this._objectType),
                TradProp("End", this._objectType),
                TradProp("Source", this._objectType),
                TradProp("AdvertiserGroup", this._objectType),
                TradProp("Advertiser", this._objectType),
                TradProp("Brand", this._objectType),
                TradProp("Product", this._objectType),
                TradProp("Departments", this._objectType),
                Trad("Budget"),
                Trad("Nombre de messages"),
                Trad("Net"),
                TradProp("EstimateNumber", this._objectType),
                TradProp("ReturnedCurrency", this._objectType),
                TradProp("Created_by", this._objectType)
            ]];
        if (properties.some(p => p.name == "Support"))
            columnNames = [...columnNames, ...[
                TradProp("Media", this._objectType),
                TradProp("Departments", this._objectType),
                TradProp("Support", this._objectType),
                TradProp("BroadcastArea", this._objectType),
                TradProp("Start", this._objectType),
                TradProp("End", this._objectType),
                TradProp('ModelProperties.Periodicity', this._objectType),
                TradProp('ModelProperties.MediaFamily', this._objectType),
                TradProp("Name", this._objectType),
                Trad("Budget"),
                Trad("Net"),
                Trad("Remaining Budget"),
                TradProp("Currency", this._objectType),
                TradProp("EstimateNumber", this._objectType),
                Trad("Pagination"),
                Trad("Nombre total"),
                Trad("Remaining Pages"),
                Trad("Nombre de messages"),
                Trad("internal_comment"),
                Trad("external_comment"),
                TradProp("Created_by", this._objectType)
            ]];
        return distinct(columnNames);
    }

    protected async GetDefaultVentilationNames() {
        const properties = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties();

        if (properties.some(p => p.name == "Support"))
            return [TradProp("Support", this._objectType)]
        return [TradProp("Advertiser", this._objectType)];
    }

    constructor() {
        super(ref_Campaigns);
    }

    protected GetForcedIndicateurs() {
        return {
            'ModelProperties.MediaFamily': this.GetMediaFamilyII(),
            'ModelProperties.Periodicity': this.GetPeriodicityII(),
            'Agency_Res': this.GetAgencyResII(),
        }
    }

    public async MetadataToIndicateurOptions() {
        let propertyIOs: IndicateurOption[] = await super.MetadataToIndicateurOptions();

        const departmentsIO = Typed<IndicateurOption>({
            indicateur: {
                type: eIndicateurType.info,
                name: TradProp("Departments", this._objectType),
                valueType: eKPIType.Rid,
                field: "Departments"
            },
            columnType: eColumnType.Property
        });

        const attachment_IO = (type: string) => Typed<IndicateurOption>({
            indicateur: {
                type: eIndicateurType.info,
                name: Trad(`${type.toLowerCase()}_comment`),
                valueType: eKPIType.String,
                field: `Attachments`,
                options: {
                    subProperty: `${type}.Text`,
                    formater: { type: "html" }
                }
            },
            columnType: eColumnType.Property
        });

        return [...propertyIOs, departmentsIO, attachment_IO("Internal"), attachment_IO("External"), ...GetTimeIOs()];
    }

    ProvideMessagesOptions: () => Promise<IndicateurOption[]> = async () => {
        const msgAggregator = AggregatorFactory.GetInstance().Get(ref_Messages);
        const msgKpis = clone((await msgAggregator.Provide()));
        msgKpis.forEach(i => {
            i.indicateur.name = `Message ${i.indicateur.name}`;
            if (!i.indicateur.options) i.indicateur.options = {};
            i.indicateur.options.linksDescriptor = {
                className: ref_Messages.name,
                property: propertyOf<ref_Messages>('Campaign')
            }
        });
        return msgKpis;
    };

    Provide: () => Promise<IndicateurOption[]> = async () => {

        const propertyIOs: IndicateurOption[] = await this.MetadataToIndicateurOptions();
        const kpiIOs: IndicateurOption[] = (await this.KPIToIndicateurOptions())
            .filter(io => io.indicateur.field != "TotalCount");

        const computedIOs: IndicateurOption[] = [];

        const netFO_IO = kpiIOs.find(i => i.indicateur.field == "NetFO");
        const budget_IO = kpiIOs.find(i => i.indicateur.field == "Budget");
        const properties = await KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties();
        //Si les campagnes n'ont pas de support alors le budget est en devise restituée
        let budgetKpiType = eKPIType.Price;
        if (!properties.some(p => p.name == "Support")) {
            budgetKpiType = eKPIType.PriceReturned;
            const price_IOs = kpiIOs.filter(i => i.indicateur.valueType == eKPIType.Price);
            price_IOs.forEach(io => io.indicateur.valueType = budgetKpiType);
        }
        if (netFO_IO && budget_IO)
            computedIOs.push(GetComputedIO(budgetKpiType, "Remaining Budget", "-", [budget_IO.indicateur, netFO_IO.indicateur]));

        //Nombre Total
        const numberTotal_IO = GetComputedIO(eKPIType.Decimal, "Nombre total", "+",
            [
                kpiIOs.find(p => p.indicateur.name == Trad("Nombre gracieux")).indicateur,
                kpiIOs.find(p => p.indicateur.name == Trad("Nombre payant")).indicateur
            ]);
        computedIOs.push(numberTotal_IO)

        const pagination_IO = kpiIOs.find(i => i.indicateur.field == "Pagination");
        if (pagination_IO)
            computedIOs.push(GetComputedIO(eKPIType.Decimal, "Remaining Pages", "-", [pagination_IO.indicateur, numberTotal_IO.indicateur]));

        let contents: IndicateurOption[] = [...propertyIOs, ...kpiIOs, ...computedIOs, ...await this.ProvideMessagesOptions()];

        return this.Sort_IO(contents);
    }
}