import { ref_Indexables, ref_IndexablesId } from "./ref_Indexables.bin";
import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_ContactsId = rid;

export class ref_Contacts extends V implements ref_Indexables {
	"@rid"?: ref_ContactsId;
	Email: string;
	Job: string;
	Phone: string;
	Type: string;

}