import * as React from 'react'

import MultipleCombo from './MultipleCombo.bin'
import { addMonths } from 'date-fns'
import { Client } from 'hub-lib/client/client.bin'
import { ref_Sources } from 'hub-lib/models/orientdb/ref_Sources.bin'
import { DateRangepicker } from '../ConfigurableComponents/DateRangepicker'
import { TradProp, Trad } from 'trad-lib'
import { GetToday, DateNoZone } from 'tools-lib'
import { HierarchyComboManager } from '../VertexGrid/HierarchyComponent/HierarchyComponent.bin'
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { FilterStorage, IsDebugMode } from '../../utils/localstorage.bin'
import { propertyOf } from 'hub-lib/tools.bin'
import { VertexAutocomplete } from "adwone-lib/index"
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin'
import { AggregateExport } from 'hub-lib/models/external.bin'
import { ADWProperty } from 'hub-lib/types'
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin'
import { Indicateur, IsIndicateurReturned } from 'adwone-engine/index.bin'
import { AggregatorFactory } from 'hub-lib/aggregator/AggregatorFactory'
import { ElementContainer } from '../VertexGrid/Generic/Common.bin'

export class TProps {
    state: TConfState;
    onConfChanged: (conf: any) => void;
}


export class CrossedTableConfig extends Filter {

    /**
     * Document to aggregate (ref_Messages by default)
     */
    documentType: string = ref_Messages.name;

    /* ventilations selectionnées */
    rowventils: (ADWProperty | Indicateur)[] = [];
    colventils: Indicateur[] = [];

    rowventilsIndicateurs: Indicateur[] = [];
    indicateurs: Indicateur[] = [];
    Start: Date = GetToday();
    End: Date = addMonths(GetToday(), 3);
    selectedIndicateurs: Indicateur[] = undefined;

    referentialOnly?: boolean = false;
    hierarchical: boolean = true;

    hideDetailsRows: boolean = true;
    groupingRows: boolean = false;

    scheduler: boolean = false;

    static ToExternalConfig(params: CrossedTableConfig): AggregateExport {

        let rowVentils = params.rowventils;

        /** Check returned currencies */
        if (params.selectedIndicateurs?.some(ind => IsIndicateurReturned(ind))) {
            /** Then force ventil to returned currency */
            if (rowVentils && !rowVentils.some(r => r.field === propertyOf<ref_Messages>("ReturnedCurrency")))
                rowVentils = [{
                    field: propertyOf<ref_Messages>("ReturnedCurrency"),
                    type: "@rid",
                    linkedClass: ref_Currencies.name,
                }, ...rowVentils]
        }

        const arg: AggregateExport = {
            type: "aggregate",
            document: params.documentType ?? ref_Messages.name,
            dimensions: rowVentils,
            //dimensionsColumns: params.colventils,
            dimensionsColumns: [],
            columns: params.selectedIndicateurs,
            filter: { ...Filter.getFilters(params) },
            referentialOnly: params.referentialOnly,
            Start: params.Start,
            End: params.End,
            hideDetailsRows: params.hideDetailsRows,
            groupingRows: params.groupingRows,
            //ViewMode: params.ViewMode,
        }

        // if (arg.filter && !arg.filter.properties)
        //     arg.filter.properties = ["*"];

        if (!arg.filter) arg.filter = {};
        if (!arg.filter.properties) arg.filter.properties = [];
        if (!arg.filter.properties.includes('Start')) arg.filter.properties.push('Start');
        if (!arg.filter.properties.includes('End')) arg.filter.properties.push('End');

        if (IsDebugMode())
            console.log(`params.selectedIndicateurs => AggregateExport`, params.selectedIndicateurs, arg);

        return arg;
    }
}

export class TConfState {

    versionConf: number = 0;

    selectedSource: string;
    sources: string[];

    comboChoices: ADWProperty[] = [];
    defaultComboValue: ADWProperty = { field: "*", type: null };

    initRowValue: ADWProperty[] = [];
    initColumnValue: Indicateur[] = [];

    kpiGraph: Indicateur[];
    KpiGraphSelected: string;

    graphType: any[] = [{ label: "Doughnut" }, { label: "Barchart" }];
    graphTypeSelected: string;

    crossedTable: CrossedTableConfig;
    crossedGraph: CrossedTableConfig;

    viewTypes: string[] = ["Hub", "Media manager"];
    selectedViewType: string;

    versionChips: number = 0;

    constructor() {
        this.selectedViewType = this.viewTypes[1];
    }

    static Extract = (state: TConfState): any => {
        return {
            crossedTable: state.crossedTable,
            crossedGraph: state.crossedGraph,
            selectedSource: state.selectedSource,
            graphTypeSelected: state.graphTypeSelected
        };
    }
}

export class CrossedTableConf extends React.Component<TProps, TConfState> {

    constructor(props: TProps) {
        super(props);
        this.state = this.props.state;
    }

    static async InitializeNewConfiguration() {

        let newstate = new TConfState();
        function onlyUnique(value: any, index: number, self: any[]) {
            return self.indexOf(value) === index;
        }

        const dims = await Client.getDimensions();
        newstate.comboChoices = [newstate.defaultComboValue, ...dims];
        newstate.initRowValue = [dims[0]];
        newstate.graphTypeSelected = newstate.graphType?.[0]?.label;

        let sources: ref_Sources[] = (await Client.searchVertexTyped(ref_Sources));
        newstate.sources = sources.map(s => s["@class"]).filter(onlyUnique);
        newstate.selectedSource = "src_AdwOne";

        let indicateurs = (await AggregatorFactory.GetInstance().Get(ref_Messages).Provide()).map(i => i.indicateur);

        newstate.crossedTable = new CrossedTableConfig();
        newstate.crossedTable.indicateurs = indicateurs;
        newstate.crossedTable.rowventils = newstate.initRowValue;
        newstate.crossedTable.colventils = newstate.initColumnValue;
        newstate.crossedTable.selectedIndicateurs = newstate.crossedTable.indicateurs;
        newstate.crossedTable.scheduler = false;

        newstate.crossedTable.referentialOnly = (newstate.viewTypes.indexOf(newstate.selectedViewType) == 0);
        //
        let loadConf = FilterStorage.GetLocalStorageFiltersObj();
        if (loadConf) {
            Object.entries(loadConf).forEach(([key, value]) => (newstate.crossedTable as any)[key] = value);
            newstate.crossedTable.Start = loadConf.Start ? DateNoZone(loadConf.Start) : GetToday();
            newstate.crossedTable.End = loadConf.End ? DateNoZone(loadConf.End) : addMonths(GetToday(), 3);
        }

        return newstate;
    }

    notifyChange = () => {

        for (var k in this.state) {
            let val = (this.state as any)[k];
            (this.props.state as any)[k] = val;
        }

        this.props.onConfChanged(TConfState.Extract(this.state))
    }

    handleSourceChanged = (event: any) =>
        this.setState({ selectedSource: event.target.value })

    handleViewTypeChanged = (value: any) => {
        this.state.crossedTable.referentialOnly = this.state.viewTypes.indexOf(value) == 0;
        this.setState({ selectedViewType: value }, this.notifyChange);
    }

    handleRowChanged = (_ventils: ADWProperty[]) =>
        this.setState({ initRowValue: _ventils }, () => {
            console.log(`[handleRowChanged]`, _ventils)
            this.props.state.initRowValue = _ventils;
            this.state.crossedTable.rowventils = this.state.initRowValue;
            this.notifyChange();
        });

    handleColChanged = (_ventils: Indicateur[]) =>
        this.setState({ initColumnValue: _ventils }, () => {
            this.state.crossedTable.colventils = this.state.initColumnValue;
            this.notifyChange();
        });

    handleChangeDate = (value: any) => {
        this.state.crossedTable.Start = value.start;
        this.state.crossedTable.End = value.end;
        this.notifyChange();
    }
    render() {

        let Title = (props: any) => {
            return <div style={{ marginTop: 30, width: "100%", padding: "0 20px" }}>
                <span style={{ fontSize: '1rem', fontWeight: 500 }}>{props.text}</span>
            </div>
        }

        let Element = (props: any) => {
            return <div className={`adw-row ${props.className ?? ""}`}>
                {props.children}
            </div>
        }

        let { crossedTable, versionConf, versionChips } = this.state;
        return <>
            {/** PERIODE */}
            {/* <Title text={Trad("period")} /> */}
            <Element>
                <DateRangepicker defaultStart={crossedTable.Start} defaultStop={crossedTable.End} handleChangeDate={this.handleChangeDate} />
            </Element>

            <Element>
                <VertexAutocomplete
                    key={`SourceVertex-${versionConf}`}
                    multiple={true}
                    options={["ADWONE", "MAP"]}
                    label={Trad("Source")}
                    defaultValue={() => {
                        if (crossedTable.Source?.length) return crossedTable.Source;
                        return [];
                    }}
                    onChange={async (value: any) => {
                        crossedTable.Source = value;
                        this.forceUpdate();
                        this.setState({ versionChips: versionChips + 1 })
                        this.notifyChange();
                    }} />
            </Element>

            <HierarchyComboManager
                key={`HierarchyComboManager-${versionConf}`}
                store={crossedTable} multi={true}
                onConfChanged={() => {
                    this.notifyChange();
                    this.setState({ versionChips: versionChips + 1 })
                }} />

            {/** LIGNES VENTILATIONS */}
            {/* <Title text={Trad("lines")} /> */}
            <ElementContainer>
                <MultipleCombo
                    key="lignes"
                    choices={this.state.comboChoices}
                    defaultValue={this.state.defaultComboValue}
                    initValue={this.state.initRowValue}
                    onChange={this.handleRowChanged}
                    transform={(str) => TradProp(str.field, ref_Messages)}
                />
            </ElementContainer>

            {/** KPIS */}
            {/* <Title text={Trad("values")} /> */}
            <Element className="panel-conf-container">
                <VertexAutocomplete
                    label={Trad("values")}
                    multiple
                    options={this.state.crossedTable.indicateurs.filter((e: Indicateur) => {
                        return !this.state.crossedTable.selectedIndicateurs.find(l => {
                            return l.name === e.name
                        })
                    })}
                    getOptionLabel={(option) => option.name}
                    defaultValue={() => this.state.crossedTable.selectedIndicateurs}
                    onChange={(value) => {
                        this.state.crossedTable.selectedIndicateurs = (value as Indicateur[]);
                        this.forceUpdate();
                        this.notifyChange();
                    }} />
            </Element>
        </>
    }
}
