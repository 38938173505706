import * as React from "react";
import { ContainerComponent } from "../../../VertexGrid/Generic/ContainerComponent";
import { OverflowExpendable } from "./OverflowExpendable.bin";

export function ChipBox({ children, title }) {
    return (
        <div style={{margin: 4}}>
            <ContainerComponent title={title} style={{padding: 5, height: "100%", display: "block"}}>
                <OverflowExpendable>
                    {children}
                </OverflowExpendable>
            </ContainerComponent>
        </div>
    )
}