import * as React from 'react'

export class TProps {
    width?: string;
    message: any;
    padding?: string;
    margin?: string;
}

export class ErrorMessage extends React.Component<TProps, any> {
    constructor(props: TProps) {
        super(props);
    }
    render() {

        return (
            <div className="error_color error_color_background" style={{width: this.props.width,  padding: this.props.padding, margin: this.props.margin, borderRadius: 5}}>
                {this.props.message}
            </div>
        )
    }
}