import { rid } from "../../models/orientdb/CommonTypes.bin";
import { ref_CampaignsId } from "./ref_Campaigns.bin";
import { INamed, IUpsertInfo } from "../../types";
import { User } from "../../models/orientdb/User.bin";

export type ref_AttachmentsId = rid;
export type ref_VisualsId = rid;

export class ref_Attachments {
    "@rid"?: ref_AttachmentsId;
    Content: any; // the Attachment
    Active: boolean;
    AttachementType: string;
    DocumentOwners: rid[];
    Type: string;
}


export class ref_Comments extends ref_Attachments {
    AttachementType = "comment";
    Type: "External" | "Internal";
    Text: string;
}

export class ref_Files extends ref_Attachments {
    AttachementType = "file";
    Name: string; // nom du fichier
    Size: number; // taille du fichier
    Content: rid;
}

export enum eVisualStatus {
    "Create",
    "Pending",
    "Ended",
    "Sent"
}
export class ref_Visuals implements IUpsertInfo, INamed {
    "@rid"?: ref_VisualsId;
    Name: string;
    Active: boolean;
    Campaign: ref_CampaignsId;
    File?: ref_AttachmentsId;
    FileInfo? : ref_Files;
    Created_by?: User["@rid"];
	Created_at?: Date;
	Updated_by?: User["@rid"];
	Updated_at?: Date;
    Status?: eVisualStatus;
}

export class ref_HyperLinks extends ref_Attachments {
    AttachementType: string = "hyperlink";
    Type = "Tech"
    Content: string;
}