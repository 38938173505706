import { mm_Supports } from "hub-lib/models/orientdb/mm_Supports.bin";
import { Referentials } from "hub-lib/models/orientdb/Referentials.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { Views } from "hub-lib/models/orientdb/Views.bin";
import stringSimilarity from "string-similarity";

export enum eSort {
    asc = "ASC",
    desc = "DESC"
}

export type FindBestMatchOptions = {
    minRating?: number;
    sort?: eSort;
    distBest?: number;
}

export function findBestMatch(name: string, list: string[], options: FindBestMatchOptions = {}): stringSimilarity.Rating[] {
    // find matches
    const { ratings, bestMatch } = stringSimilarity.findBestMatch(name, list);
    let matches = ratings;

    // minimum
    if (options.minRating) {
        matches = matches.filter(m => m.rating >= options.minRating);
    }

    // sort
    if (options.sort) {
        const asc = (a: { rating: number; }, b: { rating: number; }) => a.rating - b.rating;
        const desc = (a: { rating: number; }, b: { rating: number; }) => b.rating - a.rating;
        switch (options.sort) {
            case eSort.asc:
                matches = matches.sort(asc);
                break;
            case eSort.desc:
                matches = matches.sort(desc);
                break;
        }
    }

    // distBest
    if (options.distBest) {
        matches = matches.filter(m => m.rating >= bestMatch.rating - options.distBest);
    }

    return matches;
}

export function ViewTextToCompare(dimensionName: string, v: Views) {
    if (v["@class"] == mm_Supports.name) {
        const viewNameSplitted = v.Label.toLocaleLowerCase().split("/");
        if (dimensionName == ref_BroadcastAreas.name && viewNameSplitted.length > 1) {
            return viewNameSplitted[1];
        }
        return viewNameSplitted[0];
    }
    return v.Label.toLocaleLowerCase();
}

export function findBestViews(dimensionName: string, ref: Referentials, views: Views[], options: FindBestMatchOptions = {}): { view: Views, rating: number }[] {
    const matches = findBestMatch(ref["Name"].toLocaleLowerCase(), Array.from(new Set(views.map(e => ViewTextToCompare(dimensionName, e)))), options);

    return matches.map(m =>
        views.filter(v => ViewTextToCompare(dimensionName, v) === m.target)
            .map(v => ({
                view: v,
                rating: m.rating
            })
            )).reduce((a, b) => [...a, ...b], []);
}