
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { clone, propertyOf } from 'hub-lib/tools.bin';
import { ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { ConsoleDebug, TableStorage } from '../utils/localstorage.bin';
import { ref_SchedulerConfigurations } from 'hub-lib/dto/client/ref_SchedulerConfigurations.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { Client } from 'hub-lib/client/client.bin';
import { ref_ExportConfigurations } from 'hub-lib/dto/client/ref_ExportConfigurations.bin';
import { ref_ConfigurationsBase } from 'hub-lib/dto/client/ref_ConfigurationsBase.bin';
import { ref_PivotGridConfigurations } from 'hub-lib/dto/client/ref_PivotGridConfigurations.bin';

// function _setTable(state: ProjectState, config: ref_TableConfigurations) {
//     TableStorage.set(config);
//     state.columnsModele = duplicate(config as any);
// }

export type typedConfigurationsBase = {
    table?: ref_TableConfigurations,
    scheduler?: ref_SchedulerConfigurations,
    export?: ref_ExportConfigurations,
    pivotGrid?: ref_PivotGridConfigurations
}

export interface columnsConfigurationsState {
    isInitialized?: boolean,
    configurations: {
        [configurationsName: string]: typedConfigurationsBase
    }
}

export const initializeConfigurations = createAsyncThunk(
    'columnsConfigurations/initializeConfigurations',
    async (_, thunkAPI): Promise<any> => {

        const cloned: columnsConfigurationsState = clone({
            configurations: TableStorage.getAll()
        });
        // TODO: find a better way to do this
        const types = [
            ref_Messages.name,
            ref_Campaigns.name
        ]

        for (const type of types) {
            if (!cloned.configurations)
                cloned.configurations = {};
            if (!cloned.configurations[type])
                cloned.configurations[type] = {};
            if (!cloned.configurations[type].table)
                cloned.configurations[type].table = (await Client.searchVertexTyped(ref_TableConfigurations, { [propertyOf<ref_TableConfigurations>('Table')]: type, Default: true }))[0];
            if (!cloned.configurations[type].scheduler)
                cloned.configurations[type].scheduler = (await Client.searchVertexTyped(ref_SchedulerConfigurations, { [propertyOf<ref_SchedulerConfigurations>('Table')]: type, Default: true }))[0];
            if (!cloned.configurations[type].export)
                cloned.configurations[type].export = (await Client.searchVertexTyped(ref_ExportConfigurations, { [propertyOf<ref_ExportConfigurations>('Table')]: type, Default: true }))[0];
            if (!cloned.configurations[type].pivotGrid)
                cloned.configurations[type].pivotGrid = (await Client.searchVertexTyped(ref_PivotGridConfigurations, { [propertyOf<ref_PivotGridConfigurations>('Table')]: type, Default: true }))[0];
        }

        TableStorage.set(cloned.configurations);
        cloned.isInitialized = true;
        return cloned;
    })

const saveModele = (key: keyof typedConfigurationsBase, modele: ref_ConfigurationsBase, configurations: columnsConfigurationsState['configurations']) => {
    const configurationsName = modele.Table;
    if (!configurations[configurationsName])
        configurations[configurationsName] = {};
    configurations[configurationsName][key] = <any>clone(modele);
    ConsoleDebug(`[saveModele] ${key}: `, modele, configurations);
    TableStorage.set(configurations);
}

export const columnsConfigurationsSlice = createSlice({
    name: 'columnsConfigurations',
    initialState: { configurations: {} } as columnsConfigurationsState,
    extraReducers: (builder) => {
        builder.addCase(initializeConfigurations.fulfilled, (state, action: PayloadAction<columnsConfigurationsState>) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                state[key] = value
            })
        })
    },
    reducers: {
        setTable: (state, action: PayloadAction<ref_TableConfigurations>) => {
            saveModele('table', action.payload, state.configurations);
        },
        setScheduler: (state, action: PayloadAction<ref_SchedulerConfigurations>) => {
            saveModele('scheduler', action.payload, state.configurations);
        },
        setExport: (state, action: PayloadAction<ref_ExportConfigurations>) => {
            saveModele('export', action.payload, state.configurations);
        },
        setPivotGrid: (state, action: PayloadAction<ref_PivotGridConfigurations>) => {
            saveModele('pivotGrid', action.payload, state.configurations)
        }
    },
});

// Action creators are generated for each case reducer function
export const { setTable, setScheduler, setExport, setPivotGrid } = columnsConfigurationsSlice.actions

export const gridConfigurationsReducer = columnsConfigurationsSlice.reducer;