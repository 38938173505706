import * as React from 'react'
import { RowWrapper } from "../CrossedTableTelerikTree.bin"
import { CreateIndicateur, IndicateurToString } from 'adwone-engine/index.bin';
import { ref_TableConfigurations } from 'hub-lib/dto/client/ref_TableConfigurations.bin';
import { IndicateurBase } from 'adwone-engine/index.bin';
import { GetKPITemplate } from 'format-lib/index.bin';
import { Trad } from 'trad-lib';
import './TooltipGroup.scss';
import { getTable } from '../../../redux/store';
import { EngineTools } from 'adwone-engine/EngineTools';
import { CellValue } from 'adwone-engine/types.bin';

let tooltipid = 0;

export function ToolTipGroup(row: RowWrapper<any> & { objectType: string }) {
    console.log('[toolTipGroup]', row.id, row.Data.length, row.Data[0])
    return <div key={`tooltip-id-group-${row.id}`}>
        <div className="clearfix" style={{ marginBottom: 5 }}>
            <div style={{ float: "left", fontSize: '15px', fontWeight: 'bold' }}>{Trad("number_messages") + ':'}</div>
            <div style={{ float: "left", fontSize: '15px', fontWeight: 'bold' }}>{`${row?.Data?.length}`}</div>
        </div>
        {ToolTipMessage(row)}
    </div>
}

export function ToolTipMessage(row: RowWrapper<any> & { objectType: string }) {
    const configuration: ref_TableConfigurations = getTable(row.objectType);
    console.log('[toolTipMessage]', row.id, row.Data.length, row, configuration)
    const indics = (configuration?.Columns ?? []).filter(i => !i.isSchedulerHidden);
    return <div>
        {indics.map((i, idx) => {
            const ind = CreateIndicateur(i);
            return <div key={`tooltip-id-message-${tooltipid++}`} className="clearfix" style={{ marginBottom: 5, display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ minWidth: 100, fontWeight: 'bold', textDecoration: 'underline', flexShrink: 0 }}>{`${ind.name}:`}</div>

                <IndicateurValue row={row} indicateur={ind} />
                {/* <span style={{ textOverflow: 'clip', whiteSpace: 'normal', wordWrap: 'break-word' }}>{'azertyuiopqsdfghjklmwxcvbnazertyuiopqsdfghjklmwxcvbnazertyuiopqsdfghjklmwxcvbn'}</span> */}

            </div>
        })}
    </div>
}

type IndicateurValueProps = { row: RowWrapper<any> & { objectType: string }, indicateur: IndicateurBase };
const IndicateurValue = ({ row, indicateur }: IndicateurValueProps) => {
    const [value, setValue] = React.useState<any>(null);
    React.useEffect(() => {
        if (!value)
            Promise.resolve()
                .then(async () => {
                    const cell: CellValue = ({
                        Formated: '',
                        Value: null,
                        IndicateurSignature: IndicateurToString(indicateur),
                        Type: "cell"
                    })
                    cell.Value = await indicateur.Compute(row.Data);
                    EngineTools.AddCurrencyToCells(cell, indicateur, row.Data);
                    const [formatedCell] = await EngineTools.FormatCells(row.objectType, indicateur, [cell]);

                    let formatedValue = formatedCell.Formated;

                    // max 100 characters
                    if (typeof formatedValue === 'string') {
                        formatedValue = (formatedValue.length > 100) ? formatedValue.substring(0, 100) + '...' : formatedValue;
                        formatedValue = formatedValue.replaceAll('|', ', ');
                    }

                    setValue(formatedValue);
                })
    });

    if (value?.length > 20) {
        return <div style={{ overflow: 'hidden', whiteSpace: 'normal', wordWrap: 'break-word', display: 'block', width: '100%' }}>{value}</div>
    }
    return <div style={{ overflow: 'hidden', whiteSpace: 'normal', wordWrap: 'break-word', display: 'block', flexGrow: 1 }}>{value}</div>
}