import { getIcon } from 'adwone-lib';
import * as React from 'react'
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';

export class ContactSupportParams {
  title: string;
  open: boolean;
  handleClose?: () => void;
}

export function ContactSupportDialog(props: ContactSupportParams) {
  let title = <div>{props.title}<span onClick={props.handleClose} style={{ float: 'right', margin: '0 5px', color: '#DDDDDD', cursor: 'pointer' }}>{getIcon("close")}</span></div>
  return (
    <GenericDialog
      dialogTitle={title}
      open={props.open}
      handleClose={props.handleClose}
      id='contact_support_dialog'
      dialogContent={
        <iframe style={{ height: '70vh', width: '100vh' }}
          src="https://share.hsforms.com/1uxbrCBGORY2DftthfZ6-2A3uo90"
          frameBorder="0">
        </iframe>
      }
    />
  )
}