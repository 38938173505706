import * as React from "react"
import { TextField } from "@material-ui/core";
import { AdwAutocomplete } from "adwone-lib";
import { ref_Groups } from "hub-lib/dto/client/ref_Groups.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { GetEditorState, RootState, SetModelSync, store } from "../../../../redux/store";
import { otherRid } from "../../../../redux/storeUtils";

type GroupEditorArgs = {
    property: string;
    require?: string;
    modelProperty?: boolean;
    other?: string;
    model: "ref_Messages" | "ref_Campaigns";
};

export function GroupEditor({ property, require, modelProperty, other, model }: GroupEditorArgs) {
    const dispatch = useDispatch();
    const group = useSelector((root: RootState) => modelProperty ? GetEditorState(model, root).data.ModelProperties?.[property] : GetEditorState(model, root).data[property]);
    const ids = useSelector((root: RootState) => GetEditorState(model, root).data[require]);
    const valueChoices = useSelector((root: RootState) => GetEditorState(model, root).groups[property]);

    const isDisabled = !valueChoices?.length || (require && !ids?.length);

    if (!valueChoices) return <></>;

    const onChange = (_group: ref_Groups) => {
        const storeData = GetEditorState(model, store.getState()).get();
        const data = modelProperty ? storeData.ModelProperties : storeData;

        if (!storeData.ModelProperties)
            storeData.ModelProperties = {};

        data[property] = _group?.["@rid"] ?? null;

        if (other) {
            if (data[property] && data[property] !== otherRid) data[other] = null;
            else data[other] = "";
        }
        SetModelSync(model, storeData);
    }

    let value = valueChoices?.find(o => o["@rid"] == group);
    const storeData = GetEditorState(model, store.getState()).get();
    const data = modelProperty ? storeData.ModelProperties : storeData;
    if (other && !group && data[other])
        value = valueChoices?.find(v => v["@rid"] == otherRid);

    return (
        <AdwAutocomplete
            key={`${GetHashCode(valueChoices)}${GetHashCode(value)}`}
            loading={isDisabled}
            disabled={isDisabled}
            options={valueChoices}
            getOptionLabel={(g) => g.Name}
            value={value}
            onChange={(event: any, group: any) => onChange(group)}
            renderInput={(params: any) => (
                <TextField {...params} variant="outlined" label={TradProp(property, ref_Messages)} />
            )}
        />
    );
}
