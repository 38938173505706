import { rid } from "./orientdb/CommonTypes.bin";
import { ref_KPIsId } from "./orientdb/ref_KPIs.bin";
import { eIndicateurType, Indicateur } from "adwone-engine/index.bin";
import { eKPIType, KPIsManagerCache } from "./KPIsManager.bin";
import { ref_DiscountClassesId } from "./orientdb/ref_DiscountClasses.bin";
import { ADWProperty } from "../types";
import { ref_SchedulerConfigurations } from "../dto/client/ref_SchedulerConfigurations.bin";
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { clone } from "../tools.bin";

export type filterRefArg = {
  in?: rid[],
  out?: rid[]
};

export enum eDiscountOptionValue {
  Rate = "Rate",
  Value = "Value"
}

export enum eDiscountOptionType {
  CO = "CO",
  FO = "FO",
  FOS = "FOS"
}

export type DiscountOptions = {
  rid: rid;
  value: eDiscountOptionValue,
  type: eDiscountOptionType,
  isPriceReturned?: boolean,
  isPriceBound?: boolean,
  barter?: boolean
}

export type Column = ColumnKPIValue | ColumnKPIDiscount | ColumnInfo;

export class ColumnKPIValue {
  columnType: eIndicateurType.kpi = eIndicateurType.kpi;
  id: ref_KPIsId;
  valueType: eKPIType;
  title?: string;
  bound?: boolean;

  constructor(id?: ref_KPIsId, valueType?: eKPIType, bound?: boolean) {
    this.id = id;
    this.valueType = valueType;
    this.bound = bound;
  }
}

export class ColumnKPIDiscount {
  columnType: eIndicateurType.discount = eIndicateurType.discount;
  id: ref_DiscountClassesId;
  valueType: eKPIType;
  options?: DiscountOptions;
  title?: string;

  constructor(id?: ref_DiscountClassesId, options?: DiscountOptions) {
    this.id = id;
    this.options = options;
    this.valueType = options?.value === "Rate" ? eKPIType.Percent : eKPIType.Price;
  }
}

export class ColumnInfo {
  columnType: eIndicateurType.info = eIndicateurType.info;

  /**
   * === field
   */
  id: string;

  title?: string;

  constructor(id?: string) {
    this.id = id;
  }
}

export function GetKPIColumns(columns: Column[]): (ColumnKPIValue | ColumnKPIDiscount)[] {
  const cols: (ColumnKPIValue | ColumnKPIDiscount)[] = [];
  for (const column of columns) {
    if (column.columnType === eIndicateurType.kpi || column.columnType === eIndicateurType.discount) {
      cols.push(column);
    }
  }
  return cols;
}

export type SplitType = {
  field: string,
  propertyType: string
};

export class ExportBase {

  headerFilters?: CompositeFilterDescriptor;
  sort?: SortDescriptor[];

  /**
   * pour les exports
   */
  ViewModeExport?: "Table" | "CrossedTable" | "Scheduler" | "SchedulerLight" = "Table";


  document: string;

  /**
    * Excel template name
    */
  templateName?: string;

  /**
  * filtres
  */
  filter?: { [prop: string]: string[] | string | boolean | Date | any };


  /**
  * Indicateurs à calculer
  */
  columns: Indicateur[];
  // Pris en compte seulement si columns = null|undefined
  columnsGeneration?: "fromData" | "fromMetadata";
  /**
   * Optionnal, to split excel tab by property
   */
  splitTab?: ADWProperty | Indicateur;
  addSplitDimensionToTotal?: boolean;

  rowTotal?: "top" | "bottom" = "bottom";
  rowTotalActivated?: boolean;
}

export class AggregateExport extends ExportBase {

  type: "aggregate";

  referentialOnly?: boolean;

  /**
   * uuid to refer to a specific cache data that has been already calculated
   */
  Uuid?: string;

  /**
   * Source type data-adwanted|data-map|...
   */
  sources?: string[];

  /**
   * Advertisers/Support/...
   */
  dimensions?: (ADWProperty | Indicateur)[];
  dimensionsColumns?: Indicateur[];

  /**
   * Date bornes
   */
  Start?: Date;
  End?: Date;
  // dates?: {
  //   begin?: Date,
  //   end?: Date
  // }

  /**
   * Sous total dans le calendrier
   */
  temporalTotal?: "week" | "month" | "trimester" | "semester";
  temporalTotalPercents?: boolean;

  stretchTotal?: boolean;

  // legacy, old values: "day" | "week"
  timeView?: "day" | "week" | "week_named" | "week_dated" = "week_named";
  includeDays?: boolean;

  groupingTotalTable?: boolean;

  applySchedulerUserTemplate?: boolean = true;
  schedulerUserTemplate?: ref_SchedulerConfigurations;

  /**
   * Indique le niveau le plus haut de calcul de sous total
   */
  totalLevel?: string | "none";

  totalDetails?: "all" | "numeric" = "numeric";

  hideGroupingColumns?: boolean;
  hideDetailsRows?: boolean;
  hideDetailsData?: boolean;
  groupingRows?: boolean;

  // only compute total of the table
  onlyTotal?: boolean;
}

export async function SanitizeFilter(objectType: string, filters: { [prop: string]: any }) {
  const ignoredProps = ['@rid', 'Active', 'properties', 'CacheInfos'];

  if (filters) {
    const userProps = (await KPIsManagerCache.GetInstance(objectType).GetUserProperties()).map(p => p.name);
    for (const prop in filters) {
      if (!userProps.includes(prop) && !ignoredProps.includes(prop)) {
        console.log(`[SanitizeFilter]: removing prop ${prop}`);
        delete filters[prop];
      }
    }
  }

  return filters;
}

export class TableExport extends ExportBase {
  type: "table";
}

export type distinctArg = {
  collection: string,
  property: string,
  documentType: string
}