import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    percentage: 0.8,
    reload: false
}

const percentageSimilaritySlice = createSlice({
    name: "similarityPercentage",
    initialState,
    reducers: {
        setPercentage: (state, action) => {
            state.percentage = action.payload;
        },
        setReload: (state, action) => {
            state.reload = action.payload;
        }
    }

});

export const { setPercentage, setReload } = percentageSimilaritySlice.actions

export const percentageSimilarityReducer = percentageSimilaritySlice.reducer;
