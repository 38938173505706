import { AdwRow, TooltipManager, getIcon } from 'adwone-lib';
import * as React from 'react';
import { Trad } from 'trad-lib';
import { CustomIconButton } from '../Generic/CustomIconButton';

export function ItemNameButtonCell({ value, onClick, warningMessage }: {
    value: string,
    onClick: () => any,
    warningMessage?: string
}) {
    return (
        <div className="campaign-cell clearfix display-center" style={{ position: 'relative' }}>
            <div className="campagain-cell-name"
                onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: value })}>
                {value}
            </div>
            <div className="display-center messages-by-campaign-icon">
                {
                    warningMessage &&
                    <div className="display-center"
                        onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad(warningMessage) })}>
                        {getIcon('info', {}, "warning_color")}
                    </div>
                }
                <div style={{ cursor: 'pointer' }} onMouseOver={(e) => TooltipManager.Push({ target: e.target, text: Trad('see_messages') })} >
                    <CustomIconButton
                        onClick={onClick} >
                        {getIcon('go')}
                    </CustomIconButton>
                </div>
            </div>
        </div>
    );
}