import * as React from "react";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from "@progress/kendo-react-inputs";
import { TooltipManager } from "adwone-lib";
import { Trad } from "trad-lib";

export type CellLockValueComponentType = GridCellProps & { onLockChanged: (value: boolean, discount: ref_Discount) => void, canChangeValue: (discount: ref_Discount) => boolean };
export function CellLockValueComponent({ dataItem, onLockChanged, canChangeValue }: CellLockValueComponentType) {
    const discount = dataItem as ref_Discount;
    const isLocked = !Boolean(discount.Agreement);
    return <td
        className="no-padding"
        style={{ textAlign: 'center', position: 'relative' }}
        onMouseOver={e => {
            if (!canChangeValue(discount))
                return TooltipManager.Push({ target: e.target, text: Trad("cannot_unlock_user_discount") });
            if (isLocked)
                return TooltipManager.Push({ target: e.target, text: Trad("discount_locked") });
            if (!isLocked)
                return TooltipManager.Push({ target: e.target, text: Trad("discount_unlocked") });
        }}>
        <Checkbox
            value={isLocked}
            disabled={isLocked}
            onChange={(event) => {

                if (isLocked)
                    return;

                if (event.value)
                    discount.Agreement = null;

                onLockChanged(event.value, discount);
            }} />

    </td>
}
