import React from 'react'

import { Client } from 'hub-lib/client/client.bin'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Loader from '../Loader'
import { Trad, TradClassName } from 'trad-lib';
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin'
import { getIcon } from "adwone-lib/index"
import { CustomIconButton } from '../../VertexGrid/Generic/CustomIconButton'
import { TooltipManager } from '../../CustomTooltip'

type Format = {
  groupeAnnonceur: string,
  annonceur: string,
  currency: string
};

export function CurrenciesDialog(props: any) {
  const { onClose, open } = props;
  const [summary, setSummary] = React.useState([])
  const headCells = [
    { id: "groupeAnnonceur", Label: TradClassName("ref_AdvertiserGroups") },
    { id: "annonceur", Label: TradClassName("ref_Advertisers") },
    { id: "currency", Label: Trad("property_out") }
  ]
  const myOrderBy = (rows: Format[], prop: any, order: any): Format[] => {

    if (!prop || !order)
      return rows;

    if (!rows)
      return rows;

    return rows.sort((a: any, b: any) => {
      if (prop === "annonceur" && a[prop] === Trad("all").toUpperCase()) {
        return -1
      }
      return a[prop].localeCompare(b[prop])
    });
  }

  if (open && summary.length === 0) {
    Client.searchVertex(
      "lnk_HasCurrency",
      {
        "AllCompanies": true
      }
    ).then(r => {
      let data = r.data.results
      let format: Format[] = []
      data.forEach((e: any) => {
        if (e !== undefined) {
          if (e.Company_Class === "ref_Advertisers") {
            let groupeAnnonceur = e.Parent_Name[0] ?? ''
            let annonceur = e.Company_Name ?? ''
            let currency = (e.Currency_Name && e.Currency_Code) ? `${e.Currency_Name[0]}${'('}${e.Currency_Code[0]}${')'}` : ''
            format.push({ groupeAnnonceur: groupeAnnonceur, annonceur: annonceur, currency: currency })
          } else {
            let groupeAnnonceur = e.Company_Name
            let annonceur = Trad("all").toUpperCase()
            let currency = (e.Currency_Name && e.Currency_Code) ? `${e.Currency_Name[0]}${'('}${e.Currency_Code[0]}${')'}` : ''
            format.push({ groupeAnnonceur: groupeAnnonceur, annonceur: annonceur, currency: currency })
          }
        }
      })
      let ordered = myOrderBy(myOrderBy(format, 'annonceur', 'asc'), 'groupeAnnonceur', 'asc')
      setSummary(ordered)
    })
  }

  const handleClose = () => {
    onClose();
  }
  let title = <>{Trad("currencies_settings")}<span onClick={handleClose} style={{ float: 'right', margin: '0 5px', color: '#DDDDDD', cursor: 'pointer' }}>{getIcon("close")}</span></>
  return (
    <GenericDialog
      dialogTitle={title}
      open={open}
      handleClose={handleClose}
      dialogContent={
        <>
          {summary.length === 0 && <Loader />}
          {summary.length > 0 &&
            <TableContainer component={Paper} style={{maxHeight: '600px' }}>
              <Table stickyHeader aria-label="devises restituees">
                <TableHead>
                  <TableRow>
                    {headCells.map(headCell => (
                      <TableCell
                        key={headCell.id}
                      >
                        {headCell.Label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {summary.map((row: Format, i) => (
                    <TableRow key={`${i}${row.groupeAnnonceur}`}>
                      <TableCell component="th" scope="row" align="left">
                        {row.groupeAnnonceur}
                      </TableCell>
                      <TableCell align="left">{row.annonceur}</TableCell>
                      <TableCell align="left">{row.currency}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </>
      }
    >

    </GenericDialog>
  );
}


export function PopupCurrencies(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomIconButton
          className="navigation custom_btn_secondary no-radius no-shadow"
          onClick={handleClickOpen}
          style={{width: "100%", height: "100%"}}
          onMouseOver={(e) => TooltipManager.Push({ target: e.target, text : Trad("currencies_settings") })}>
          {getIcon("info")}
        </CustomIconButton>
      {/*<CustomButton
        Label={<span style={{ textDecoration: 'underline' }}>{Trad("currencies_settings")}</span>}
        style={{marginRight: 5}}
        className={"custom_btn_nostyle"}
        startIcon={getIcon("info")}
      onClick={handleClickOpen}/>*/}
      <CurrenciesDialog open={open} onClose={handleClose} />
    </>
  );
}