
import * as RecordID from "orientjs/lib/recordid.js";
import { PropertyName } from "./types.bin";

/**
 * Description of vertex property
 */
export class ClassProperty {

    /**
     * Property name
     */
    name: PropertyName;

    /**
     * Property type
     */
    type: ePropType;
    hidden?: boolean;
    globalId?: number;
    mandatory?: boolean;
    readonly?: boolean;
    notNull?: boolean;
    defaultValue?: any;
    min?: string;
    max?: string;
    customFields?: any;
    collate?: string;
    description?: string;

    linkedClass?: string;
    linkedType?: ePropType;
}

/**
 * Property types
 * Based on: https://orientdb.com/docs/3.0.x/general/Types.html
 */
export enum ePropType {
    Boolean = 0,
    Integer,
    Short,
    Long,
    Float,
    Double,
    Datetime,
    String,
    Binary,
    Embedded,
    EmbeddedList,
    EmbeddedSet,
    EmbeddedMap,
    Link,
    LinkList,
    LinkSet,
    LinkMap,
    Byte,
    Transient,
    Date,
    Custom,
    Decimal,
    LinkBag,
    Any
}

export function IsLink(type: ePropType) {
    return [
        ePropType.Link,
        ePropType.LinkList,
        ePropType.LinkSet,
        ePropType.LinkBag].includes(type)
}

/*Belkacem*/
export type ClassType = "Edge" | "Vertex" | "Generic";

export const eHandleTypes = {
    [ePropType.Boolean]: "boolean",
    [ePropType.Integer]: "number",
    [ePropType.Short]: "number",
    [ePropType.Long]: "number",
    [ePropType.Float]: "number",
    [ePropType.Double]: "number",
    [ePropType.Datetime]: "Date",
    [ePropType.String]: "string",
    [ePropType.Binary]: "number",
    [ePropType.Embedded]: "Object",
    [ePropType.EmbeddedList]: "Object",
    [ePropType.EmbeddedSet]: "Object",
    [ePropType.EmbeddedMap]: "Object",
    [ePropType.Link]: "Object",
    [ePropType.LinkList]: "Object",
    [ePropType.LinkSet]: "Object",
    [ePropType.LinkMap]: "Object",
    [ePropType.Byte]: "number",
    [ePropType.Transient]: "any",
    [ePropType.Date]: "Date",
    [ePropType.Custom]: "any",
    [ePropType.Decimal]: "number",
    [ePropType.LinkBag]: "any",
    [ePropType.Any]: "any",
}

export var linkable = ["Embedded",
    "EmbeddedList",
    "EmbeddedSet",
    "EmbeddedMap",
    "Link",
    "LinkList",
    "LinkSet",
    "LinkMap",];
/*Belkacem*/

export var test = undefined;

export function IsInt(value: ePropType): boolean {
    let numberTypes: ePropType[] =
        [
            ePropType.Integer,
            ePropType.Short,
            ePropType.Long,
            ePropType.Byte
        ];
    return numberTypes.includes(value);
}

export function IsLinkset(value: ePropType): boolean {
    let numberTypes: ePropType[] =
        [
            ePropType.LinkSet
        ];
    return numberTypes.includes(value);
}

export function parseLinkset(values: any[] | any): any {
    if (Array.isArray(values))
        return values.map(value => typeof value === "string" ? RecordID.parse(value) : value);
    return new RecordID(values);
}

export function IsDecimal(value: ePropType): boolean {
    let numberTypes: ePropType[] =
        [
            ePropType.Float,
            ePropType.Double,
            ePropType.Decimal,
        ];
    return numberTypes.includes(value);
}

