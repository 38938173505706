import { OjdWave } from "./models/OjdWave";
import { mm_DiscountClasses } from "./models/orientdb/mm_DiscountClasses.bin";
import { ref_BroadcastAreas } from "./models/orientdb/ref_BroadcastAreas.bin";
import { ref_DiscountClasses } from "./models/orientdb/ref_DiscountClasses.bin";
import { ref_Supports } from "./models/orientdb/ref_Supports.bin";
import { ClassProperty } from "./models/VertexProperty.bin";
import { Operator } from "./operators.bin";

export class DataProvider {
    static search: <Ref>(vertexName: string, params?: any, op?: Operator[]) => Promise<Ref[]> = () => {
        throw new Error("Method not implemented.");
    }

    static searchMemoized: <Ref>(vertexName: string, params?: any, op?: Operator[]) => Promise<Ref[]> = () => {
        throw new Error("Method not implemented.");
    }

    static getMetadata: (vertexName: string, all?: boolean) => Promise<ClassProperty[]> = () => {
        throw new Error("Method not implemented.");
    }

    static getOJDWaves: (params: { support: ref_Supports['@rid'], broadcastArea: ref_BroadcastAreas['@rid'] }) => Promise<string[]> = () => {
        throw new Error("Method not implemented.");
    }

    static getOJD: (params: { support: ref_Supports['@rid'], broadcastArea: ref_BroadcastAreas['@rid'], year: string }) => Promise<OjdWave[]> = () => {
        throw new Error("Method not implemented.");
    };

    static getVwMMDiscountClasses: () => Promise<({ ExternalID: mm_DiscountClasses['ExternalID'], Referential: ref_DiscountClasses['@rid'] })[]> = () => {
        throw new Error("Method not implemented.");
    }
}