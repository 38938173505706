import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { JSONEqualityComparer } from "hub-lib/tools.bin";
import { DiscountClassGridRow } from "./Discount/DiscountClassGridRow";

class DiscountGridProps {
    filter?: (d: ref_Discount) => boolean;
}

export function DiscountGrid({ filter }: DiscountGridProps) {
    const discounts = useSelector((state: RootState) => state.messageEditor.get().Discounts.filter(filter ?? Boolean), JSONEqualityComparer);
    return <div className="DiscountGrid">
        {discounts.map(d => <DiscountClassGridRow key={`d_${d.DiscountClass}`} discount={d} />)}
    </div>
}