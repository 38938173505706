import { DiscountManager, netType } from "hub-lib/business/DiscountManager.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";

export function GetDiscountRate(discount: ref_Discount, cofo: netType) {
    if (!discount)
        return null;

    // CO, FO ou FOS
    const baseValue = DiscountManager.getModulation(discount, cofo);
    let value = (baseValue?.Rate ?? 0) * 100 * discount.Operator;
    if (value == -0)
        value = 0;
    return value;
}