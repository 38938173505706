import { Indicateur } from "adwone-engine/index.bin";
import { rid } from "./models/orientdb/CommonTypes.bin";
import { ref_AdvertiserGroups } from "./models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "./models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "./models/orientdb/ref_Brands.bin";
import { ref_Products } from "./models/orientdb/ref_Products.bin";
import { UserId } from "./models/orientdb/User.bin";
import { ref_Campaigns } from "./dto/client/ref_Campaigns.bin";
import { ref_MediaId } from "./models/orientdb/ref_Media.bin";
import { ref_SupportsId } from "./models/orientdb/ref_Supports.bin";
import { ref_BroadcastAreasId } from "./models/orientdb/ref_BroadcastAreas.bin";
import { ref_CurrenciesId } from "./models/orientdb/ref_Currencies.bin";
import { ref_CompaniesId } from "./models/orientdb/ref_Companies.bin";
import { ref_Attachments } from "./dto/client/ref_Attachments.bin";

export type ADWProperty = ADWPropertyString | ADWPropertyRid | Indicateur;
export type ADWPropertyString = {
    field: string;
    type: "string";
}

export type ADWPropertyRid = {
    field: string;
    type: "@rid";
    linkedClass: string;
}

/**
 * Arguments de push dans history pour ouvrir une campagne
 */
export type HistoryStateArg = {
    type: 'campaignRedirection';
    campaignName: string;
    campaignRid: ref_Campaigns["@rid"];
    advertiser_groupRid: ref_AdvertiserGroups["@rid"];
    advertiserRid: ref_Advertisers["@rid"];
    brandRid: ref_Brands["@rid"];
    productRid: ref_Products["@rid"];
    campaignRedirection: true;
} | {
    type: 'import';
    importRid: rid;
}

export interface INamed {
    Name: string;
}

export interface IUpsertInfo {
    Created_at?: Date;
	Created_by?: UserId;
    Updated_at?: Date;
	Updated_by?: UserId;
}

export class kpis {

	Ratio?: number = 0;
	RatioDiviser?: number = 0;

	Gross?: number = 0;
	GrossVal?: number = 0;
	GrossBa?: number = 0;
	BroadcastGross?: number = 0;

	NetCO?: number = 0;
	NetFO?: number = 0;
	NetFOS?: number = 0;
	NetNego?: number = 0;

	TotalCO?: number = 0;
	TotalFO?: number = 0;
	TotalFOS?: number = 0;

    FreeCount?: number = 0;
    PaidCount?: number = 0;

	PriceDefaultFormat?: number;
	RateGrossDefaultFormat?: number;
	RateGrossValoDefaultFormat?: number;

	Barter?: number;
}

export interface IDateRangeModel {
    Start?: Date;
	End?: Date;
}

export interface IAdvertiserHierarchy {
	Group?: rid | rid[];
	AdvertiserGroup?: rid | rid[];
	Advertiser?: rid | rid[];
	Brand?: rid | rid[];
	Product?: rid | rid[];
}

export interface IMediaModel {
    Support?: ref_SupportsId;
    BroadcastArea?: ref_BroadcastAreasId;
    Currency?: ref_CurrenciesId;
    Media?: ref_MediaId;
    KPIs?: kpis;
    ModelProperties?: { [key: string]: any };
}

export interface IAttachmentsOwner {
    "@rid"?: rid;
    Attachments?: { [key: string]: ref_Attachments }
}

export interface IMediaCompanyModel {
    AdvCompany_Com?: rid;
	AdvCompany_Fin?: rid;
	Agency_Res?: ref_CompaniesId;
	Agency_Fact?: ref_CompaniesId;
}