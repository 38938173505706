import Template from '../Template'
import { ref_Campaigns } from 'hub-lib/dto/client/ref_Campaigns.bin';
import { DataGridComponent } from '../../VertexGrid/Generic/ModeleGrid/DataGridComponent';
import { DicoCreateTrad, eDialogMode, GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { CampaignForm, GetStoreCampaignWithComments } from '../../VertexGrid/Campaigns/CampaignForm';
import { MultiAdvertisersMode } from '../../VertexGrid/Campaigns/MultiAdvertisersMode';
import { EstimateMode } from '../../VertexGrid/Campaigns/EstimateMode';
import { RootState } from '../../../redux/store';
import React from 'react';
import { IsObjectValid } from 'validation-lib/index.bin';
import { Notify } from '../../../utils/Notify.bin';
import { DuplicationCampaignArgs } from 'hub-lib/models/types.bin';
import { CreateCampaignDataGrid } from '../../VertexGrid/Campaigns/CampaignsGridCreator';
import { useSelector } from 'react-redux';
import { KeyValidator } from '../../../utils/KeyValidator';
import { KPIsManagerCache } from 'hub-lib/models/KPIsManager.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';

export class TProps {
    open: boolean;
    selectedMedia: ref_Media['@rid'];
    handleClose?: () => void;
    onValidate: (c: ref_Campaigns, duplicateParams? : any) => any;
    campaign?: ref_Campaigns;
    importRid?: string;
}

export function CampaignsDialog({ selectedMedia, campaign, open, onValidate, handleClose }: TProps) {
    const [validationMode, setValidationMode] = React.useState<boolean>(false);
    const [estimateMode, setEstimateMode] = React.useState(false);
    const [multiAdvertisers, setMultiAdvertisers] = React.useState(false);
    const [duplicateParams, setDuplicateParams] = React.useState<DuplicationCampaignArgs>({
        messages: false,
        messagesCanceled: false,
        messagesWithStatus: false,
        broadcastPlacement: false,
        pricingLocked: false
    });
    const lockNext = useSelector((root: RootState) => root.campaignEditor.lockNext);
    const mode = useSelector((root: RootState) => root.campaignEditor.mode);
    const validationIssues = useSelector((root: RootState) => root.campaignEditor.validationIssues);

    return <GenericDialog
        open={open}
        handleClose={handleClose}
        dialogTitle=""
        titleClass='display-none'
        id="messages_dialog_createupdate"
        dialogClassname="messages"
        submitClass={'custom_btn_primary_validation'}
        actions
        disablePrimaryButton={lockNext || validationIssues?.length > 0}
        submitTitle={DicoCreateTrad()[mode]}
        submitAction={() => {
            const campaign = GetStoreCampaignWithComments();
            if (IsObjectValid<ref_Campaigns>(ref_Campaigns, campaign).length) {
                Notify("Certains champs ne sont pas valides.", "warning");
                setValidationMode(true);
                return;
            }
            onValidate(campaign, duplicateParams);
        }}
        cancelAction={handleClose}>
        <div className="multiAdvertisersMode-component" style={{ zIndex: 9000, padding: 8, top: 0 }}>
            <KeyValidator objectType="ref_Campaigns" propertyName="Group">
                <MultiAdvertisersMode
                    multiAdvertisers={Boolean(campaign?.Group) || multiAdvertisers}
                    onChange={(value) => setMultiAdvertisers(value)}
                />
            </KeyValidator>
            <KeyValidator objectType="ref_Campaigns" propertyName="Estimates">
                {mode !== eDialogMode.modify
                    && <EstimateMode
                        estimateMode={estimateMode}
                        onChange={(value) => setEstimateMode(value)}
                    />}
            </KeyValidator>
        </div>

        <div style={{ zIndex: 8000, position: "relative", height: "100%" }}>
            <CampaignForm
                campaign={campaign}
                selectedMedia={selectedMedia}
                multiAdvertisers={multiAdvertisers}
                estimatedMode={estimateMode}
                validationMode={validationMode}
                duplicateParams={duplicateParams}
            />
        </div>
    </GenericDialog>
}

const Campaigns = () => {

    const [loadMedias, setLoadMedias] = React.useState<boolean>(null);

    React.useEffect(() => {
        if (loadMedias == null)
            KPIsManagerCache.GetInstance(ref_Campaigns.name).GetUserProperties()
                .then(properties => setLoadMedias(properties.some(p => p.name == "Media")));
    })

    if (loadMedias == null)
        return <></>

    return <div className="grid_container">
        {/* <CampaignsGrid /> */}

        <DataGridComponent
            objectType={ref_Campaigns}
            loadMedias={loadMedias}
            genre="feminine"
            editProperties={["Attachments", "Estimates"]}
            createGrid={params => CreateCampaignDataGrid(params)}
            getDialog={(props, state, onValidate, onClose) =>
                <CampaignsDialog
                    open
                    selectedMedia={state.selectedMedia?.["@rid"]}
                    campaign={state.elementEdited}
                    handleClose={onClose}
                    onValidate={onValidate} />
            } />
    </div>
}

export default Template(Campaigns);
