import { IRid } from "hub-lib/models/IRid.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { vw_mm_HasBroadcastArea } from "hub-lib/models/orientdb/vw_mm_HasBroadcastArea.bin";
import { vw_mm_HasSupport } from "hub-lib/models/orientdb/vw_mm_HasSupport.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { GetHashCode } from "hub-lib/tools.bin";
import * as React from "react"
import { useDispatch, useSelector } from "react-redux";
import { TradProp } from "trad-lib";
import { initMessageBroadcastOptions } from "../../../../redux/messageEditorSlice";
import { GetEditorState, RootState, SetModel, store } from "../../../../redux/store";
import { FavoriteVertexAutoComplete } from "../../../AdwAutocomplete/FavoriteVertexAutoComplete";
import { CheckElement } from "./CheckElement";
import { WarningMM } from "./WarningMM";
import { initCampaignBroadcastOptions } from "../../../../redux/campaignEditorSlice";

type BroadcastAreaEditorArgs = { onChange?: () => void, model : "ref_Messages" | "ref_Campaigns" }

export function BroadcastAreaEditor({ onChange, model }: BroadcastAreaEditorArgs) {

    const dispatch = useDispatch();
    const BroadcastArea = useSelector((root: RootState) => GetEditorState(model, root).get().BroadcastArea);
    const Support = useSelector((root: RootState) => GetEditorState(model, root).get().Support);
    const broadcastOptions = useSelector((root: RootState) => GetEditorState(model, root).broadcastOptions);
    const lockNext = useSelector((root: RootState) => GetEditorState(model, root).lockNext);

    React.useEffect(() => {
        if (broadcastOptions === undefined)
            dispatch(model == "ref_Campaigns" ? initCampaignBroadcastOptions(undefined) : initMessageBroadcastOptions(undefined));
    }, [broadcastOptions])

    const dispatchChanged = async (broadcastArea: IRid) => {
        const data = GetEditorState(model, store.getState()).get();
        if (data.BroadcastArea != broadcastArea?.["@rid"]) {
            data.BroadcastArea = broadcastArea?.["@rid"];
            console.log("Set BroadcastArea", data.BroadcastArea);
            await SetModel(model, data);
            onChange?.();
        }
        return broadcastArea;
    }

    return <WarningMM
        cross
        ids={[
            { id: Support, linkClass: vw_mm_HasSupport.name },
            { id: BroadcastArea, linkClass: vw_mm_HasBroadcastArea.name }
        ]}>
        <FavoriteVertexAutoComplete
            key={`BroadcastAreaEditor-${GetHashCode({ broadcastOptions, BroadcastArea })}`}
            disableClearable
            disabled={lockNext}
            loading={lockNext}
            type={ref_BroadcastAreas.name}
            refreshOnPropChanged
            label={TradProp("BroadcastArea", ref_Messages) + " *"}
            options={broadcastOptions ?? []}
            defaultValue={(options: IRid[]) => CheckElement(options?.find?.(o => o["@rid"] == BroadcastArea), BroadcastArea, ref_BroadcastAreas.name)}
            onChange={async (broadcastArea: IRid) => {
                dispatchChanged(broadcastArea);
            }} />
    </WarningMM>
}