import * as React from "react";
import { makeStyles } from '@material-ui/core/styles'
// import CircularProgress from '@material-ui/core/CircularProgress'
import { Loader as LoaderKendo } from "@progress/kendo-react-indicators";

type Options = {
    center?: boolean
}

const useStyles = makeStyles<any, Options>(() => ({
    root: {
        width: '100%',
        height: '100%'
    },
    loader: props => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translateX(-50%) ${props.center ? "translateY(-50%)" : "perspective(1px)"}`,
    })
}))

const Loader = (props: { text?: string, size?: "large" | "medium" | "small", center?: boolean, time?: boolean }) => {
    const classes = useStyles({ center: props.center });
    const { text, size, time } = props;
    const start = React.useRef(Date.now()).current;
    const [timeMs, setTimeMs] = React.useState(null);

    React.useEffect(() => {
        if (time) {
            const interval = setInterval(() => {
                const time = Date.now() - start;
                setTimeMs(`${Math.trunc(time / 1000)} sec`);
            }, 1000);
            return () => clearInterval(interval);
        }
    })

    const loaderContent = <span className="loader-kendo-container">
        <LoaderKendo size={size ?? "large"} type={"converging-spinner"} />
    </span>

    return (
        <div className={classes.root}>
            <div className={classes.loader}>
                {(!text && !timeMs) ? loaderContent :
                    <div className={'loading-text-container'}>
                        {loaderContent}
                        <h4 className={'loading-text'}>{(text ?? '') + (timeMs ?? '')}</h4>
                    </div>}

            </div>
        </div>
    );
}

export default Loader;
