import { Grid } from "@material-ui/core";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { TreeListCellProps, TreeListColumnProps, TreeListNumericFilter, TreeListTextFilter } from "@progress/kendo-react-treelist";
import { ref_Media, ref_MediaId } from "hub-lib/models/orientdb/ref_Media.bin";
import { ReferentialElement } from "../../../layout/SuperAdmin/Referential.bin";
import * as React from 'react'
import { KPIsManagerCache } from "hub-lib/models/KPIsManager.bin";
import { GetCurrentLocale, Trad, TradProp } from "trad-lib";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { Client } from "hub-lib/client/client.bin";
import { CustomTreeList } from "../../Generic/CustomTreeList";
import { TreeListEditRefNode } from "./ReferentialTreeList";
import Loader from "../../../layout/Loader";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { clnt_Companies, clnt_Referentials } from "hub-lib/dto/client/clnt_Referentials";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { classNames } from "@progress/kendo-react-common";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { IRid } from "hub-lib/models/IRid.bin";
import { VertexAutocomplete } from "adwone-lib";
import moment from "moment";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { ContainerComponent } from "../../Generic/ContainerComponent";
import { TextEditor } from "../../TextEditor.bin";

class ClntReferentialFormProps {
    Referential: ReferentialElement;
    Parent: IRid;
}

const TREELIST_COL_INDEX_ATTRIBUTE = "data-grid-col-index";
export const PercentTemplate = (_value) => _value?.toLocaleString(GetCurrentLocale(), { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export function CustomTreeListPercentCell(props: TreeListCellProps) {
    const { dataItem, field } = props;
    const cellData = dataItem[field];

    const defaultRendering = (
        <td
            style={{ ...props.style, ...{ textAlign: "right" } }}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            role={"gridcell"}
            {...{ [TREELIST_COL_INDEX_ATTRIBUTE]: props.colIndex }}>
            <span>
                {PercentTemplate(cellData * 100) + '%'}
            </span>
        </td>);
    return props.render
        ? props.render.call(undefined, defaultRendering, props)
        : defaultRendering;
}

export function CustomTreeListPercentEditor(props: TreeListCellProps) {
    const onChange = React.useCallback(
        (event) => {
            if (props.onChange) {
                props.onChange({
                    dataItem: props.dataItem,
                    level: props.level,
                    field: props.field,
                    syntheticEvent: event,
                    value: event.target.value / 100,
                });
            }
        },
        [props.onChange, props.dataItem, props.level, props.field]
    );
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const editCellClasses = classNames(
        {
            ["k-state-selected"]: props.isSelected,
        },
        props.className
    );

    const value = (props.dataItem[props.field] as number) * 100;

    const defaultRendering = (
        <td
            style={props.style}
            className={editCellClasses}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            role={"gridcell"}
            {...navigationAttributes}
            {...{ [TREELIST_COL_INDEX_ATTRIBUTE]: props.colIndex }}
        >
            <NumericTextBox
                format="n2"
                className={'CellNumberComponent-editing-cell'}
                spinners={true}
                min={0}
                max={100}
                step={0.01}
                value={value}
                onChange={onChange} />
        </td>
    );

    return props.render
        ? props.render.call(undefined, defaultRendering, props)
        : defaultRendering;
};

export function ClntSupportForm({ Referential, Parent }: ClntReferentialFormProps) {
    if (!Referential?.["ClntData"]) {
        Referential["ClntData"] = new clnt_Referentials();
    }

    const descriptionValue = Referential["ClntData"].ModelProperties?.["Description"] ?? Referential["Description"];

    return <Grid item xs={12} className="message_details_full">
        <ContainerComponent title={TradProp("Description", ref_Supports)} className="auto-height no-padding no-margin">
            <TextEditor
                content={descriptionValue}
                onChange={(value: string) => {
                    if (!Referential["ClntData"].ModelProperties)
                        Referential["ClntData"].ModelProperties = {};
                    Referential["ClntData"].ModelProperties["Description"] = value;
                }} />
        </ContainerComponent>
    </Grid>
}

export function ClntAdvertiserForm({ Referential, Parent }: ClntReferentialFormProps) {
    const [selected, setSelected] = React.useState<number>(0);
    const [medias, setMedias] = React.useState<ref_Media[]>();
    const [broadcastAreas, setBroadcastAreas] = React.useState<ref_BroadcastAreas[]>();
    const [columns, setColumns] = React.useState<TreeListColumnProps[]>();
    const [honoClasses, setHonoClasses] = React.useState<ref_DiscountClasses[]>();
    const discountClassNames = ["Honoraires", "Honoraires sur frais techniques"];
    const ParentIdField = "ParentId";
    const getMonths = () => {
        const months = [];
        for (let i = 0; i < 12; i++) {
            months.push({ id: i, name: Trad("month_" + i) });
        }
        return months;
    }

    if (!Referential?.["ClntData"]) {
        Referential["ClntData"] = new clnt_Companies();
    }

    React.useEffect(() => {
        KPIsManagerCache.GetMedias().then(setMedias);
    }, [Boolean(medias)])

    React.useEffect(() => {
        Client.searchVertexTyped(ref_DiscountClasses).then(classes => {
            const honoClasses = classes.filter(c => discountClassNames.includes(c.Name));
            setHonoClasses(honoClasses);
            setColumns([
                {
                    field: "Name",
                    width: "auto",
                    title: Trad("Name"),
                    filter: TreeListTextFilter,
                    expandable: true,
                    resizable: true
                },
                ...honoClasses.map(c => ({
                    field: c["@rid"],
                    width: 200,
                    title: c.Name,
                    filter: TreeListNumericFilter,
                    cell: CustomTreeListPercentCell,
                    editCell: CustomTreeListPercentEditor,
                    expandable: false,
                    resizable: false
                }))]
            );
        })
    }, [Boolean(columns)])

    React.useEffect(() => {
        Client.searchVertexTyped(ref_BroadcastAreas, {
            deep: true, format: {
                children: "items"
            }
        }).then(setBroadcastAreas);
    }, [Boolean(broadcastAreas)])

    const createNode = (d: ref_BroadcastAreas, mediaId: ref_MediaId, level: number = 0, parent?: TreeListEditRefNode): TreeListEditRefNode => {
        let node: TreeListEditRefNode = new TreeListEditRefNode();
        node.id = d["@rid"];
        node["Name"] = d.Name;
        node[ParentIdField] = parent?.id;

        const clntReferential = Referential["ClntData"] as clnt_Companies;
        const clntReferentialParent = Parent?.["ClntData"] as clnt_Companies;
        if (!clntReferential.DiscountRates)
            clntReferential.DiscountRates = {};
        if (!clntReferential.DiscountRates[mediaId])
            clntReferential.DiscountRates[mediaId] = [];

        const discountRates = clntReferential.DiscountRates[mediaId]?.find(r => r.BroadcastArea == d["@rid"]);

        //Taux configuré sur le referential parent (Groupe Annonceur)
        let parentDiscountRates = clntReferentialParent?.DiscountRates?.[mediaId]?.find(r => r.BroadcastArea == d["@rid"]);

        //Taux configuré sur la zone de diffusion parent
        if (parent && !parentDiscountRates)
            parentDiscountRates = clntReferential.DiscountRates[mediaId]?.find(r => r.BroadcastArea == parent.id);

        honoClasses.forEach(c => {
            if (discountRates?.Rates?.[c["@rid"]])
                node[c["@rid"]] = discountRates?.Rates?.[c["@rid"]];
            else if (parentDiscountRates)
                node[c["@rid"]] = parentDiscountRates.Rates[c["@rid"]];
            else if (parent?.[c["@rid"]])
                node[c["@rid"]] = parent[c["@rid"]];
            else
                node[c["@rid"]] = 0;
        })

        if (d["items"])
            node.Children = d["items"].map(i => createNode(i, mediaId, level + 1, node));

        return node;
    }

    const initializeData = (media: ref_Media) => {
        return broadcastAreas.map(el => createNode(el, media["@rid"]));
    }

    const onItemChanged = (media: ref_Media, item: TreeListEditRefNode, field: string, parent: TreeListEditRefNode) => {
        const clntReferential = Referential["ClntData"] as clnt_Companies;
        const clntReferentialParent = Parent?.["ClntData"] as clnt_Companies;

        //Taux configuré
        let discountRates = clntReferential.DiscountRates[media["@rid"]].find(r => r.BroadcastArea == item.id);
        //Taux configuré sur le referentiel parent (Groupe Annonceur)
        let parentDiscountRates = clntReferentialParent?.DiscountRates?.[media["@rid"]]?.find(r => r.BroadcastArea == item.id);

        let zeroValues = true;
        const rates = {};
        honoClasses.forEach(c => {
            rates[c["@rid"]] = item[c["@rid"]];
            if (c["@rid"] == field && item[field] != 0)
                zeroValues = false;
            if (discountRates && c["@rid"] != field && discountRates.Rates[c["@rid"]] != 0)
                zeroValues = false;
        })

        if (!discountRates) {
            //Add data if NOT Zero values
            if (!zeroValues) {
                discountRates = { BroadcastArea: item.id, Rates: rates };
                clntReferential.DiscountRates[media["@rid"]].push(discountRates);
            }
        }
        else {
            //Update data if NOT Zero values
            if (!zeroValues)
                discountRates.Rates = rates;
            else //Remove data if Zero values
                clntReferential.DiscountRates[media["@rid"]] = clntReferential.DiscountRates[media["@rid"]].filter(r => r.BroadcastArea != item.id);
        }
        //If Zero Values, get Parent data
        if (zeroValues) {
            honoClasses.forEach(c => {
                if (parentDiscountRates)
                    item[c["@rid"]] = parentDiscountRates.Rates[c["@rid"]];
                else if (parent)
                    item[c["@rid"]] = parent[c["@rid"]];
            })
        }
        //Set Data to Children if no values in child
        const recurseRates = (root: TreeListEditRefNode) => {
            root.Children?.forEach(i => {
                let discountRates = clntReferential.DiscountRates[media["@rid"]].find(r => r.BroadcastArea == i.id);
                //Taux configuré sur le referential parent (Groupe Annonceur)
                let parentDiscountRates = clntReferentialParent?.DiscountRates?.[media["@rid"]]?.find(r => r.BroadcastArea == i.id);
                if (!discountRates && !parentDiscountRates) {
                    honoClasses.forEach(c => {
                        i[c["@rid"]] = item[c["@rid"]];
                    })
                    recurseRates(i);
                }
            })
        }
        recurseRates(item);
    }

    const loaded = Boolean(broadcastAreas) && Boolean(medias) && Boolean(columns);
    const clntReferential = Referential?.["ClntData"] as clnt_Companies;
    const clntParent = Parent?.["ClntData"] as clnt_Companies;

    return <>
        <Grid container>
            <Grid item xs={6} className="message_details_leftcombo">
                <VertexAutocomplete
                    options={getMonths()} label={Trad("fiscal_month")}
                    getOptionLabel={(i) => i.name}
                    defaultValue={(values) => {
                        let defaultValue = clntReferential?.ModelProperties?.["fiscal_month"];
                        if (!defaultValue)
                            defaultValue = clntParent?.ModelProperties?.["fiscal_month"] ?? 0;

                        return values.find(v => v.id == defaultValue);
                    }}
                    onResetValue={(values) => {
                        const index = clntParent?.ModelProperties?.["fiscal_month"] ?? 0;
                        return values.find(v => v.id == index);
                    }}
                    onChange={(value: { id: number, name: string }) => {
                        const parentValue = clntParent?.ModelProperties?.["fiscal_month"];
                        const toUpdate = Referential["ClntData"] as clnt_Companies;

                        if ((value?.id != null) && (!parentValue || value.id != parentValue)) {
                            if (!toUpdate.ModelProperties)
                                toUpdate.ModelProperties = {};
                            toUpdate.ModelProperties["fiscal_month"] = value.id;
                        }
                        else if (toUpdate?.ModelProperties?.["fiscal_month"] != null)
                            toUpdate.ModelProperties["fiscal_month"] = null;

                        //console.log("onChange", value, toUpdate?.ModelProperties?.["fiscal_month"]);
                    }} />
            </Grid>
            {Parent && <Grid item xs={6} className='message_details_rightcombo'>
                <VertexAutocomplete
                    disabled
                    options={getMonths()} label={Trad("parent_fiscal_month")}
                    getOptionLabel={(i) => i.name}
                    defaultValue={(values) => values.find(v => v.id == (clntParent?.ModelProperties?.["fiscal_month"] ?? 0))} />
            </Grid>}
            {!loaded && <Loader />}
            {loaded &&
                <Grid container style={{ display: "block" }}>
                    <TabStrip className="tabpanel_fullwidth" selected={selected} onSelect={e => setSelected(e.selected)}>
                        {medias.map(media => {
                            return <TabStripTab
                                key={`discountRates_${media["@rid"]}`}
                                title={Trad(media.Name)}>
                                <Grid xs={12} item style={{ height: '500px', overflow: 'auto', overflowX: 'hidden' }}>
                                    <CustomTreeList
                                        data={initializeData(media)}
                                        columns={columns}
                                        selectedField="Selected"
                                        expandField="Expanded"
                                        subItemsField="Children"
                                        parentIdField={ParentIdField}
                                        editField="InEdit"
                                        editMode="cell"
                                        onItemChanged={(item, field, parent) => onItemChanged(media, item, field, parent)}
                                    />
                                </Grid>
                            </TabStripTab>
                        })}
                    </TabStrip>
                </Grid>
            }
        </Grid>
    </>
}