import * as React from 'react'
import { JSONEqualityComparer } from "hub-lib/tools.bin";
import { useSelector } from "react-redux";
import { GetEditorState, RootState, SetModelSync, store } from "../../../../redux/store";
import { SimpleDatePicker } from '../../../ConfigurableComponents/SimpleDatepicker.bin';
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { TextField } from '@material-ui/core';
import { TradProp } from 'trad-lib';

type PropertyEditorArgs = { property: keyof ref_Messages & string, label: string, model: "ref_Messages" | "ref_Campaigns" }
export function PropertyEditor({ property, label, model }: PropertyEditorArgs) {

    const propertyValue = useSelector((root: RootState) => GetEditorState(model, root).get()[property], JSONEqualityComparer);

    return <TextField
        autoComplete='off'
        style={{ width: '100%' }}
        label={label ?? TradProp(property, ref_Messages)}
        value={propertyValue ?? ""}
        variant="outlined"
        onChange={(e) => {
            const data = GetEditorState(model, store.getState()).get();
            (data as any)[property] = e.target.value;
            SetModelSync(model, data);
        }} />
}