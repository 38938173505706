import { rid } from "./orientdb/CommonTypes.bin";
import { Referentials } from "./orientdb/Referentials.bin";
import { V } from "./orientdb/V.bin";

export type ref_ScriptId = rid;

export class ref_GitScript extends V implements Referentials {
    
    "@rid"?: ref_ScriptId;
    Name: string;
    ScriptGitUri: string;
    Params?: any;
    Active: boolean;
}