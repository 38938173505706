import { ref_Countries, ref_CountriesId } from "./ref_Countries.bin";
import { Referentials, ReferentialsId } from "./Referentials.bin";
import { rid } from "./CommonTypes.bin";

export type ref_CompaniesId = rid;

export class ref_Companies extends Referentials {
	"@rid"?: ref_CompaniesId;
	Adress: string;
	City: string;
	Country: ref_CountriesId;
	End: Date;
	Gln: string;
	Name: string;
	Siren: string;
	Siret: string;
	Start: Date;
	Tva: string;
	Website: string;
	ZipCode: string;

}