
import * as React from 'react'
import { Trad } from 'trad-lib';
import { GenericDialog } from '../ConfigurableComponents/GenericDialog.bin';
import { PassForm } from '../layout/Home/PassForm.bin';

export class Reset extends React.Component<any, any> {
    render() {
        return (<GenericDialog
            open={true}
            dialogTitle={Trad("title_change_password")}
            disableCancel
            id="change_pass_dialog"
            dialogContent={
                <PassForm token={new URLSearchParams(window.location.search).get("token")}>
                </PassForm>
            }
        />);
    }
}