import { V, VId } from "./V.bin";
import { rid } from "./CommonTypes.bin";

export type ref_KPIsId = rid;

export class ref_KPIs extends V {
	"@rid"?: ref_KPIsId;
	Aggregate: boolean;
	Name: string;

}