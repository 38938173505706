import { Client } from 'hub-lib/client/client.bin';
import * as React from 'react'
import { Trad, TradClassName } from 'trad-lib';
import { StyleFactory } from '../../../../utils/ToolsReact';
import { AdwAutocomplete } from "adwone-lib/index";
import { CustomButton } from '../../../ConfigurableComponents/CustomButton.bin';
import { CreateIndicateurInfo } from 'adwone-engine/index.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { clone } from 'hub-lib/tools.bin';

const styles = StyleFactory.create({
    container: {
        display: "flex"
    },
    combo: {
        width: "100%",
        marginRight: 10
    },
    button: {
        marginLeft: 0,
    }

});

type ExportComponentArgs = { classes: string[], filter?: any }
export function ExportComponent({ classes, filter }: ExportComponentArgs) {

    const [selectedClass, setSelectedClass] = React.useState<string>(classes?.[0])

    return <div style={styles.container}>
        <AdwAutocomplete
            style={styles.combo}
            contentEditable={false}
            disableClearable
            options={classes}
            value={selectedClass}
            getOptionLabel={(label) => TradClassName(label)}
            onChange={(event: any, value: any) => setSelectedClass(value)} />

        <CustomButton
            style={styles.button}
            Label={Trad("csv")}
            className="custom_btn_primary"
            onClick={() => {
                const indicators = [];
                let exportFilter = clone(filter);
                if (selectedClass != classes?.[0]) {
                    exportFilter["properties"] = ["out('lnk_Hierarchy') as Parent", "*"];
                    indicators.push(CreateIndicateurInfo("Parent", eKPIType.String, "Parent"));
                }
                Client.downloadExport("csv", {
                    type: "table",
                    document: selectedClass,
                    columns: indicators,
                    filter: exportFilter,
                    columnsGeneration: "fromMetadata"
                }, TradClassName(selectedClass))
            }} />
    </div>;

}