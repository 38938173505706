import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_AdvertisingCompanies } from "hub-lib/models/orientdb/ref_AdvertisingCompanies.bin";
import { ref_AdvertisingCompanyRole } from "hub-lib/models/orientdb/ref_AdvertisingCompanyRole.bin";
import { ref_BroadcastAreas } from "hub-lib/models/orientdb/ref_BroadcastAreas.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { ref_Media } from "hub-lib/models/orientdb/ref_Media.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { ref_SubAgencies } from "hub-lib/models/orientdb/ref_SubAgencies.bin";
import { SupportExtended } from "hub-lib/dto/referential/SupportExtended.bin";
import { eCompare } from "hub-lib/operators.bin";
import { clearEmptyValues, compareObjects, duplicate, extractSub } from "hub-lib/tools.bin";
import { RootStateFilters } from "../AdvertiserHierarchyComponent/StoreFilters";

let advComRole: ref_AdvertisingCompanyRole = undefined;
let advFinRole: ref_AdvertisingCompanyRole = undefined;

export class SupportStoreSingleRid {
    Media?: IRid["@rid"][];
    BroadcastArea?: IRid["@rid"];
    Currency?: IRid["@rid"];
    Support?: IRid["@rid"];
    AdvCompany_Com?: IRid["@rid"];
    AdvCompany_Fin?: IRid["@rid"];
    Format?: IRid["@rid"];
    Placement?: IRid["@rid"];
};

export class SupportStoreMultiRid {
    Media?: IRid["@rid"][];
    BroadcastArea?: IRid["@rid"][];
    Currency?: IRid["@rid"][];
    Support?: IRid["@rid"][];
    AdvCompany_Com?: IRid["@rid"][];
    AdvCompany_Fin?: IRid["@rid"][];
    Format?: IRid["@rid"][];
    Placement?: IRid["@rid"][];
};


export class StoreStateHierarchy {
    MediaOptions: ref_Media[];
    BroadcastAreaOptions: ref_BroadcastAreas[];
    CurrencyOptions: ref_Currencies[];
    AllBroadcastAreaOptions: ref_BroadcastAreas[];
    AllCurrencyOptions: ref_Currencies[];
    SupportOptions: SupportExtended[]
    AdvCompany_ComOptions: ref_AdvertisingCompanies[];
    AdvCompany_FinOptions: ref_AdvertisingCompanies[];
    FormatOptions: ref_Property[];
    PlacementOptions: ref_Property[];
}

export class StoreState extends StoreStateHierarchy {
    isLoaded: boolean;
    store: SupportStoreMultiRid;

    MediaLoading: boolean;
    BroadcastAreaLoading: boolean;
    CurrencyLoading: boolean;
    SupportLoading: boolean;
    AdvCompany_ComLoading: boolean;
    AdvCompany_FinLoading: boolean;
    FormatLoading: boolean;
    PlacementLoading: boolean;
}

const initialState: StoreState = {
    isLoaded: false,
    store: {},

    AllBroadcastAreaOptions: undefined,
    AllCurrencyOptions: undefined,
    MediaOptions: undefined,
    BroadcastAreaOptions: undefined,
    CurrencyOptions: undefined,
    SupportOptions: undefined,
    AdvCompany_ComOptions: undefined,
    AdvCompany_FinOptions: undefined,
    FormatOptions: undefined,
    PlacementOptions: undefined,

    MediaLoading: false,
    BroadcastAreaLoading: false,
    CurrencyLoading: false,
    SupportLoading: false,
    AdvCompany_ComLoading: false,
    AdvCompany_FinLoading: false,
    FormatLoading: false,
    PlacementLoading: false
}

export const setStore = createAsyncThunk(
    'supportStorefilters/setStore',
    async ({ store }: Partial<StoreState>, thunkAPI) => {
        try {

            store = clearEmptyValues(duplicate(store))

            const newStorage: Partial<StoreState> = {
                store,
                isLoaded: true,
            };

            const currentStorage = ((thunkAPI.getState() as RootStateFilters).supportStorage ?? {}) as StoreState;
            const isLoaded = currentStorage.isLoaded;

            // Load Medias
            newStorage.MediaOptions = currentStorage?.MediaOptions ?? await Client.searchVertexTyped(ref_Media);

            const mediasChanged = () => !compareObjects(extractSub(currentStorage?.store, ["Media"]), extractSub(newStorage.store, ["Media"]));
            const supportsChanged = () => !compareObjects(extractSub(currentStorage?.store, ["Support"]), extractSub(newStorage.store, ["Support"]));
            const advCmpChanged = () => !compareObjects(extractSub(currentStorage?.store, ["AdvCompany_Com", "AdvCompany_Fin"]), extractSub(newStorage.store, ["AdvCompany_Com", "AdvCompany_Fin"]));

            // Load Advertisers
            if (!isLoaded
                || mediasChanged()
                || advCmpChanged()
                || supportsChanged()
                // || advertisersChanged()
                // || brandsChanged()
            ) {

                if (!advComRole || !advFinRole) {
                    const advCompaniesRoles = await Client.searchVertexTyped(ref_AdvertisingCompanyRole);
                    advComRole = advCompaniesRoles.find(r => r.Name == "Commercial");
                    advFinRole = advCompaniesRoles.find(r => r.Name == "Financial");
                }

                // Load Broadcasts
                newStorage.AllBroadcastAreaOptions = currentStorage?.AllBroadcastAreaOptions ?? await Client.searchVertexTyped(ref_BroadcastAreas);

                // Load Currencies
                newStorage.AllCurrencyOptions = currentStorage?.AllCurrencyOptions ?? await Client.searchVertexTyped(ref_Currencies);

                if (!isLoaded) {
                    const advCompanies = await Client.searchVertexTyped(ref_AdvertisingCompanies);
                    newStorage.AdvCompany_ComOptions = advCompanies;
                    newStorage.AdvCompany_FinOptions = advCompanies;
                }

                if (!isLoaded || mediasChanged() || advCmpChanged()) {
                    const advCmpRids = Array.from(new Set([...(store.AdvCompany_Com ?? []), ...(store.AdvCompany_Fin ?? [])]));
                    const advCmp = advCmpRids.map(rid => (newStorage.AdvCompany_ComOptions ?? currentStorage.AdvCompany_ComOptions).find(e => e["@rid"] == rid))
                    const hasSubAgencies = advCmp.some(e => e["@class"] == ref_SubAgencies.name);

                    newStorage.SupportOptions = await Client.searchVertexTyped(SupportExtended, {
                        options: { lnkAdvertisingCompanies: true, lnkBroadcastAreas: false, lnkCurrencies: false, traverselnk: false, current: true },
                        properties: ["@rid", "Name"],
                        ...(!hasSubAgencies && advCmp.length && { parents: advCmpRids }),
                        ...(store.Media?.length && {
                            _operators: [{ property: "Medias", value: store.Media, compare: eCompare.ContainsAny }]
                        })
                    });

                    newStorage.SupportOptions = newStorage.SupportOptions.filter(s => {
                        if (store.AdvCompany_Com?.length && !(s.lnkAdvertisingCompanies.some(l => l.Roles.includes(advComRole["@rid"]) && store.AdvCompany_Com.includes(l.in))))
                            return false;
                        if (store.AdvCompany_Fin?.length && !(s.lnkAdvertisingCompanies.some(l => l.Roles.includes(advFinRole["@rid"]) && store.AdvCompany_Fin.includes(l.in))))
                            return false;
                        return true;
                    });

                }

                // Load Placements
                if (!isLoaded || mediasChanged()) {
                    const [property] = await Client.searchVertexTyped(ref_PropertyType, { Type: "Emplacement" });
                    const operators = [{
                        property: "PropertyType",
                        value: [property["@rid"]],
                        compare: eCompare.ContainsAny
                    }];
                    if (store.Media?.length)
                        operators.push({
                            property: "Medias",
                            value: store.Media ?? [],
                            compare: eCompare.ContainsAny
                        });
                    newStorage.PlacementOptions = await Client.searchVertexTyped(ref_Property, {
                        properties: ["@rid", "Name"],
                        _operators: operators
                    });
                }

                // Load Formats
                if (!isLoaded || mediasChanged()) {
                    const [property] = await Client.searchVertexTyped(ref_PropertyType, { Type: "Format" });
                    const operators = [{
                        property: "PropertyType",
                        value: [property["@rid"]],
                        compare: eCompare.ContainsAny
                    }];
                    if (store.Media?.length)
                        operators.push({
                            property: "Medias",
                            value: store.Media ?? [],
                            compare: eCompare.ContainsAny
                        })

                    newStorage.FormatOptions = await Client.searchVertexTyped(ref_Property, {
                        properties: ["@rid", "Name"],
                        _operators: operators
                    });
                }

                if (!isLoaded || supportsChanged() || mediasChanged() || advCmpChanged()) {
                    const allCurrencyOptions = newStorage.AllCurrencyOptions ?? currentStorage.AllCurrencyOptions;
                    const allBroadcastAreaOptions = newStorage.AllBroadcastAreaOptions ?? currentStorage.AllBroadcastAreaOptions;
                    /*const supportOptions = newStorage.SupportOptions ?? currentStorage.SupportOptions;

                    const supports = (store.Support?.map(s => supportOptions.find(o => o["@rid"] == s)) ?? supportOptions).filter(s => s);
                    const broadcastRids = new Set(supports.flatMap(s => s.lnkBroadcastAreas.filter(l => l.Active).map(l => l.out)));
                    const currencyRids = new Set(supports.flatMap(s => s.lnkCurrencies.filter(l => l.Active).map(l => l.out)));
*/
                    newStorage.BroadcastAreaOptions = allBroadcastAreaOptions;
                    newStorage.CurrencyOptions = allCurrencyOptions;

                    if (store.BroadcastArea)
                        store.BroadcastArea = store.BroadcastArea?.filter(id => newStorage.BroadcastAreaOptions.some(b => b["@rid"] == id))
                    if (store.Currency)
                        store.Currency = store.Currency?.filter(id => newStorage.CurrencyOptions.some(b => b["@rid"] == id))
                }
            }
            return newStorage;
        } catch (error) {
            console.log(`SupportHierarchyComponent error`, error);
            return {
                store,
                isLoaded: false,
            };
        }
    }
)

export const StoreSlice = createSlice({
    name: 'supportStorefilters',
    initialState,
    extraReducers: (builder) => {

        builder.addCase(setStore.pending, (state, action) => {

            const previousStore: SupportStoreMultiRid = duplicate(state?.store ?? {});
            const newStore: SupportStoreMultiRid = duplicate(action.meta.arg.store ?? {});

            if (!compareObjects(extractSub(previousStore, ["Media"]), extractSub(newStore, ["Media"]))) {
                state.SupportLoading = true;
                state.CurrencyLoading = true;
                state.BroadcastAreaLoading = true;
                state.FormatLoading = true;
                state.PlacementLoading = true;
            } else if (!compareObjects(extractSub(previousStore, ["Support", "AdvCompany_Com", "AdvCompany_Fin"]), extractSub(newStore, ["Support", "AdvCompany_Com", "AdvCompany_Fin"]))) {
                state.BroadcastAreaLoading = true;
                state.CurrencyLoading = true;
            }
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(setStore.fulfilled, (state, action) => {
            if (action.payload)
                Object.entries(action.payload).forEach(([k, v]) => {
                    state[k] = v;
                });

            Object.entries(state).forEach(([k, v]) => {
                if (k.endsWith("Loading"))
                    state[k] = false;
            })
        });
    },
    reducers: {

    },
})

// Action creators are generated for each case reducer function
export const { } = StoreSlice.actions

export const SupportStoreReducer = StoreSlice.reducer;