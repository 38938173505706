import { eRoles } from '../../business/rights/rights.bin';
import { UserExtended } from '../../dto/referential/UserExtended.bin';
import { toArray } from '../../tools.bin';

export enum eRights {
    create = 'CREATE',
    read = 'READ',
    readDetails = 'READ_DETAILS',
    update = 'UPDATE',
    delete = 'DELETE',
    viewScheduler = 'VIEW_SCHEDULER',
    viewCrossedTable = 'VIEW_CROSSED_TABLE',
    viewDashboard = 'VIEW_DASHBOARD',
    viewReporting = 'VIEW_REPORTING',
    export = 'EXPORT',
    formatedExport = 'FORMATED_EXPORT',
}

export const isCreate = (action: eRights.create | eRights.update): boolean => action === eRights.create;
export const isUpdate = (action: eRights.create | eRights.update): boolean => action === eRights.update;

export const defaultRightCrud = [eRights.create, eRights.read, eRights.update, eRights.delete];

export enum eFunctions {
    ref_Campaigns = 'ref_Campaigns',
    ref_Messages = 'ref_Messages',
    cache = 'cache',
    ref_Agreements = 'ref_Agreements',
    ref_GlobalAgreements = 'ref_GlobalAgreements',
    ref_Supports = 'ref_Supports',
    ref_AdvertisingCompanies = 'ref_AdvertisingCompanies',
    ref_BroadcastAreas = 'ref_BroadcastAreas',
    ref_Estimates = 'ref_Estimates',
    // Sellsy = 'Sellsy',
    ref_Currencies = 'ref_Currencies',
    ref_Media = 'ref_Media',
    ref_Property = 'ref_Property',
    ref_AdvertiserGroups = 'ref_AdvertiserGroups',
    ref_Advertisers = 'ref_Advertisers',
    ref_Brands = 'ref_Brands',
    ref_Products = 'ref_Products',
    ref_Agencies = 'ref_Agencies',
    ref_Imports = 'ref_Imports',
    ref_Crons = 'ref_Crons',
    ref_Groups = 'ref_Groups',
    ref_SubAgencies = 'ref_SubAgencies',
    ref_ProjectConfigurations = 'ref_ProjectConfigurations',
    User = 'User',
    Logs = 'Logs',
    Trad = 'Trad',
    ref_CalendarEvent = 'ref_CalendarEvent',
    ExcelTemplate = 'ExcelTemplate',
    UserRights = 'UserRights',
    ref_Customers = 'ref_Customers',
    lnk_ChangeRate = 'lnk_ChangeRate',
    ReferentialHasViews = 'ReferentialHasViews',
    ref_ReleaseVersion = 'ref_ReleaseVersion'
}

export type UserPermissions = {
    [element in eFunctions]?: eRights[];
};

export class RightManager {
    static rightsProvider: () => UserPermissions;
    static roleProvider: () => eRoles;
    static GetUser: () => UserExtended;

    static hasRight = (key: eFunctions | string, action: eRights | eRights[], roles?: eRoles | eRoles[]) => {
        if (!RightManager.rightsProvider) throw new Error('RightProvider is not defined');
        if (roles && !RightManager.roleProvider) throw new Error('RightProvider is not defined');
        if (roles && !toArray(roles).find(r => r === RightManager.roleProvider()))
            return false;
        return toArray(action).every((a) => !RightManager.rightsProvider()?.[key] || RightManager.rightsProvider()?.[key].includes(a));
    };
}
