import * as React from 'react'

import Template from '../Template'
import { Trad, TradClassName, TradProp } from 'trad-lib';
import { ReferentialGrid } from '../../VertexGrid/Adwone-admin/Referential/ReferentialGrid.bin';
import { ref_AdvertisingCompanies } from 'hub-lib/models/orientdb/ref_AdvertisingCompanies.bin';
import { ref_BroadcastAreas } from 'hub-lib/models/orientdb/ref_BroadcastAreas.bin';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { ref_Media } from 'hub-lib/models/orientdb/ref_Media.bin';
import { ref_Property } from 'hub-lib/models/orientdb/ref_Property.bin';
import { RightManager, eFunctions, eRights } from 'hub-lib/models/types/rights.bin';
import { ref_Supports } from 'hub-lib/models/orientdb/ref_Supports.bin';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from "@progress/kendo-react-layout";
import { ref_Advertisers } from 'hub-lib/models/orientdb/ref_Advertisers.bin';
import { ref_Agencies } from 'hub-lib/models/orientdb/ref_Agencies.bin';
import { ref_AdvertisingCompanyGroups } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin';
import { ref_AgencyGroups } from 'hub-lib/models/orientdb/ref_AgencyGroups.bin';
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin';
import { ref_Brands } from 'hub-lib/models/orientdb/ref_Brands.bin';
import { ref_Products } from 'hub-lib/models/orientdb/ref_Products.bin';
import { ref_SubAgencies } from 'hub-lib/models/orientdb/ref_SubAgencies.bin';
import { AdvertiserFiltersComponent } from '../../VertexGrid/Adwone-admin/Referential/AdvertiserFiltersComponent';
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin';
import { ReferentialTreeList } from '../../VertexGrid/Adwone-admin/Referential/ReferentialTreeList';
import { SupportExtended } from 'hub-lib/dto/referential/SupportExtended.bin';

class TState {
    selected: number = 0
}

export type ReferentialElement = ref_Companies | ref_BroadcastAreas | ref_Brands | ref_Products | ref_Currencies | ref_Supports | ref_Property | ref_SubAgencies;

export const HierarchyReferential: { [key: string]: string[] } = {
    [ref_Advertisers.name]: [ref_AdvertiserGroups.name, ref_Advertisers.name, ref_Brands.name, ref_Products.name],
    [ref_AdvertisingCompanies.name]: [ref_AdvertisingCompanyGroups.name, ref_AdvertisingCompanies.name],
    [ref_Agencies.name]: [ref_AgencyGroups.name, ref_Agencies.name, ref_SubAgencies.name],
    [ref_BroadcastAreas.name]: [ref_BroadcastAreas.name]
};

class Referential extends React.Component<any, TState> {
    constructor(props: any) {
        super(props)
        let newstate = new TState()
        this.state = newstate
    }
    handleSelect = (e: TabStripSelectEventArguments) => {
        this.setState({ selected: e.selected });
    };

    render() {
        return (
            <>
                <div>
                    <TabStrip className="tabpanel_fullwidth tabpanel_rightmenu" selected={this.state.selected} onSelect={this.handleSelect}>
                        {RightManager.hasRight(eFunctions.ref_Supports, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_Supports.name)}>
                                <ReferentialGrid
                                    vertex={SupportExtended}
                                    vertexName={ref_Supports.name}
                                    title={TradClassName(ref_Supports.name)} />
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_AdvertisingCompanies, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_AdvertisingCompanies.name)}>
                                <ReferentialTreeList hierarchyClassName={[ref_AdvertisingCompanyGroups.name, ref_AdvertisingCompanies.name]} filterName="advertisingCompanyFilter" />
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_BroadcastAreas, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_BroadcastAreas.name)}>
                                <ReferentialTreeList hierarchyClassName={[ref_BroadcastAreas.name]} />
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_Currencies, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_Currencies.name)}>
                                <ReferentialGrid
                                    vertex={ref_Currencies}
                                    vertexName={ref_Currencies.name}
                                    title={TradClassName(ref_Currencies.name)}
                                />
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_Media, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_Media.name)}>
                                <ReferentialGrid
                                    vertex={ref_Media}
                                    vertexName={ref_Media.name}
                                    title={TradClassName(ref_Media.name)}
                                />
                            </TabStripTab>
                        }

                        {RightManager.hasRight(eFunctions.ref_Property, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradProp("Format")}>
                                <ReferentialGrid
                                    vertex={ref_Property}
                                    vertexName={ref_Property.name}
                                    title={TradProp("Format")}
                                    propertyType={'Format'}
                                />
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_Property, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradProp("Placement")}>
                                <ReferentialGrid
                                    vertex={ref_Property}
                                    vertexName={ref_Property.name}
                                    title={TradProp("Placement")}
                                    propertyType={'Emplacement'}
                                />
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_Advertisers, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_Advertisers.name)}>
                                {/*<AdvertisersReferential />*/}
                                <ReferentialTreeList hierarchyClassName={[ref_AdvertiserGroups.name, ref_Advertisers.name, ref_Brands.name, ref_Products.name]}>
                                    <AdvertiserFiltersComponent />
                                </ReferentialTreeList>
                            </TabStripTab>
                        }
                        {RightManager.hasRight(eFunctions.ref_Agencies, [eRights.create, eRights.read, eRights.update, eRights.delete]) &&
                            <TabStripTab contentClassName="k-content" title={TradClassName(ref_Agencies.name)}>
                                <ReferentialTreeList hierarchyClassName={[ref_AgencyGroups.name, ref_Agencies.name, ref_SubAgencies.name]} filterName="agencyFilter" />
                            </TabStripTab>
                        }
                    </TabStrip>
                </div>
            </>
        );
    }
}

export default Template(Referential);