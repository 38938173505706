import { ref_PivotGridConfigurations } from "hub-lib/dto/client/ref_PivotGridConfigurations.bin";
import { AModeleCreatorBase, ModeleCreatorBaseProps } from "../../../ModeleCreator/ModeleCreatorBase";
import { RootState } from "../../../../../redux/store";
import { Grid } from "@material-ui/core";
import { DimensionsPicker, RowsColumnsDimensionsPicker } from "./DimensionsPicker";
import { Trad } from "trad-lib";
import { KPIsPicker } from "./KPIsPicker";
import { Indicateur } from "adwone-engine/index.bin";
import { duplicate } from "hub-lib/tools.bin";

export class PivotGridModeleCreatorProps extends ModeleCreatorBaseProps<ref_PivotGridConfigurations> {
    disableLocalStorage?: boolean;
    onChange: (conf: ref_PivotGridConfigurations, event?: { type: "contentChanged" | "selectionChanged"; }) => void;
    saveConfiguration?: (conf: ref_PivotGridConfigurations) => void;
    dimensions: Indicateur[];
    config: ref_PivotGridConfigurations;
}

export class PivotGridModeleCreator extends AModeleCreatorBase<PivotGridModeleCreatorProps, ref_PivotGridConfigurations> {

    constructor(props: PivotGridModeleCreatorProps) {
        super(props);

        this.onRowsChange = this.onRowsChange.bind(this);
        this.onColumnsChange = this.onColumnsChange.bind(this);
        this.KpisChange = this.KpisChange.bind(this);

        this.selector = (root: RootState) => root.tcd.pivotGridConfigToShow;
    }

    public onRowsChange(values: Indicateur[]) {
        const tmpTcdConfig = duplicate(this.props.config);

        tmpTcdConfig.Rows = values;

        this.save(tmpTcdConfig);
    }

    public onColumnsChange(values: Indicateur[]) {
        const tmpTcdConfig = duplicate(this.props.config);

        tmpTcdConfig.Columns = values;

        this.save(tmpTcdConfig);
    }

    public KpisChange(values: Indicateur[]) {
        const tmpTcdConfig = duplicate(this.props.config);

        tmpTcdConfig.Kpis = values;

        this.save(tmpTcdConfig);
    }

    afterRender() {
        return (
            <>
                {this.props.config &&
                <Grid container>
                    {/*<Grid item xs={6} style={{ paddingRight: 3, marginTop: 6 }}>
                        <DimensionsPicker
                            title={Trad('rows')}
                            data={this.props.dimensions}
                            values={this.props.config.Rows ?? []}
                            onChange={this.onRowsChange}
                            config={this.props.config} />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 3, marginTop: 6 }}>
                        <DimensionsPicker
                            title={Trad('columns')}
                            data={this.props.dimensions}
                            values={this.props.config.Columns ?? []}
                            onChange={this.onColumnsChange}
                            config={this.props.config} />
                    </Grid>*/}
                    <RowsColumnsDimensionsPicker
                        dimensions={this.props.dimensions}
                        config={this.props.config}
                        onRowsChange={this.onRowsChange}
                        onColumnsChange={this.onColumnsChange}
                    />
                    <Grid item xs={12} style={{ marginTop: 6 }}>
                        <KPIsPicker
                            value={this.props.config.Kpis}
                            onChange={configuration => this.KpisChange(configuration.Columns)}
                            isPivotGridEditor={true}
                        />
                    </Grid>
                </Grid>
                }
            </>
        )
    }
}