import { ref_Products, ref_ProductsId } from "./ref_Products.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_Products, mm_ProductsId } from "./mm_Products.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasProductId = rid;

export class vw_mm_HasProduct extends ReferentialHasViews<src_MMId, mm_ProductsId, ref_ProductsId> {
	"@rid"?: vw_mm_HasProductId;

}