import * as React from "react";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { Trad } from "trad-lib";
import { ADWColumn, AdwRow, getIcon } from "adwone-lib/index";
import { Grid } from "@material-ui/core";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { lastCol } from "./IntervalsDialog.bin";
import { Client } from "hub-lib/client/client.bin";
import { DiscountCategoryArray } from "../Messages/DiscountCategoryArray.bin";
import { GetFirstDayOfYear } from "tools-lib";
import { AdwTelerikGrid } from "../Generic/AdwTelerikGrid.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { GetKPITemplate, GetSort } from "format-lib/index.bin";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { SortDescriptor } from "@progress/kendo-data-query";
import { Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import Loader from "../../layout/Loader";
import { CreateIndicateurInfo } from "adwone-engine/index.bin";
import { GetSubElement, propertyOf } from "hub-lib/tools.bin";
import { eKPIType } from "hub-lib/models/KPIsManager.bin";
import { styleGridContainer } from "../../../styles/theme";
import { eDialogMode } from "../../ConfigurableComponents/GenericDialog.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { store } from "../../../redux/store";
import { GenericTooltip } from "../../ConfigurableComponents/GenericTooltip.bin";
import { DiscountManager, InitEditableDiscountsList } from "hub-lib/business/DiscountManager.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { CreateGrid } from "../Messages/MessagesGridCreatorOld";
import EventEmitter from "events";

export let numberCols = 120;
export let firstCol = (command: boolean, nbElements: number = 3) =>
    `calc(100% - ${numberCols * nbElements + (command ? lastCol : 0) + 27}px)`;
export let firsColwithRank = (command: boolean) => firstCol(command, 4);
export let scrollSize = 0;

let areGreater: ref_Messages["@rid"][] = [];

class TProps {
    mode: eDialogMode;
    agreement: ref_Agreements;
}

export class CustomDiscounts extends ref_Discount {
    CORate: number;
    COValue: number;
    FORate: number;
    FOValue: number;
    FOSRate: number;
    FOSValue: number;
}

class TState {
    grid?: VertexGrid<ref_Messages> = null;
    sort?: SortDescriptor[];
    config: Partial<Filter>;
}

class AgreementDialog extends React.Component<TProps, TState> {
    discountTab1: DiscountCategoryArray;
    discountTab2: DiscountCategoryArray;
    discountTab3: DiscountCategoryArray;
    freediscountTab: DiscountCategoryArray;

    constructor(props: TProps) {
        super(props);
        areGreater = [];
        const { agreement } = this.props;
        agreement.Discounts = agreement.Discounts ?? [];
        agreement.Start = agreement.Start ? new Date(agreement.Start) : GetFirstDayOfYear();
        agreement.DiscountMode = agreement.DiscountMode ?? "cascade";
        agreement.AutoUpdate = agreement.AutoUpdate ?? false;
        agreement.IntervalsAutoUpdate = agreement.IntervalsAutoUpdate ?? false;
        agreement.End = agreement.End ? new Date(agreement.End) : null;
        delete agreement["needConfirm"];
        let newState = new TState();
        this.state = newState;
    }

    getConfig = (): Filter => {
        const { agreement } = this.props;
        const config: Filter = {
            Active: true,
            Source: ["ADWONE"],
            ViewMode: "Table",
            //Agreement: [this.props.agreement["@rid"], null],
        } as any;

        if (!this.state) return config;

        if (agreement) config["currentAgr"] = { ...agreement, ...{ applyAgreementsConfig: store.getState().agreementEditor.applicationConfig } };
        if (agreement.AdvertiserGroup) config.AdvertiserGroup = [agreement.AdvertiserGroup];
        if (agreement.Advertisers?.length) config.Advertiser = agreement.Advertisers;
        if (agreement.Brands?.length) config.Brand = agreement.Brands;
        if (agreement.Products?.length) config.Product = agreement.Products;
        if (agreement.Start) config.Start = agreement.Start;
        if (agreement.End) config.End = agreement.End;
        if (agreement.Support) config.Support = [agreement.Support];
        if (agreement.BroadcastAreas?.length) config.BroadcastArea = agreement.BroadcastAreas;
        if (agreement.Formats?.length) config.Format = agreement.Formats;
        if (agreement.Placements?.length) config.Placement = agreement.Placements;
        if (agreement.Currency) config.Currency = [agreement.Currency];
        if (agreement.Group) config.Group = [agreement.Group];
        return config;
    };

    async componentDidMount() {
        await InitEditableDiscountsList();
        // const { agreement } = this.props;
        // if (!agreement.Messages) {
        //     if (agreement["@rid"] && this.props.mode !== eDialogMode.duplicate) {
        //         const [agr] = await Client.searchVertexTyped(ref_Agreements, { ["@rid"]: agreement["@rid"], messages: true });
        //         agreement.Messages = agr?.Messages;
        //     } else {
        //         agreement.Messages = [];
        //     }
        // }

        this.setState(
            {
                config: this.getConfig(),
            },
            () => this.createGrid()
        );
    }

    async createGrid() {
        const { agreement } = this.props;
        const statusColumn = new ADWColumn<ref_Messages>(Trad("AgreementStatus"), "AgreementStatus");
        statusColumn.cellValue = (cellValue: any, dataItem?: AdwRow<ref_Messages>) => {
            const status = (agreement["@rid"] && dataItem.dataItem.Agreement == agreement["@rid"]) ? "activated" : "disabled";
            const isLocked = agreement.Discounts.some(d => dataItem.dataItem.Discounts.some(md => md.DiscountClass == d.DiscountClass && md.Locked));
            return <div className="flex center between"><span className={`status_agreement ${status}`}>{Trad(status)}</span>{isLocked ? <GenericTooltip contentClassName="flex status_agreement_lock" tooltipContent={<>{Trad("locked_discounts")}</>}>{getIcon("lock")}</GenericTooltip> : undefined}</div>;
        };
        statusColumn.width = 140;

        let kpis = [];
        const applicationConfig = store.getState().agreementEditor.applicationConfig;
        //if (applicationConfig.includes("CO")) {
        kpis = [
            ...kpis,
            { field: "KPIs.NetCO", name: Trad("Net CO") },
            { field: "NewKPIs.NetCO", name: Trad("New Net CO") },
        ];
        //}

        //if (applicationConfig.includes("FO")) {
        kpis = [
            ...kpis,
            { field: "KPIs.NetFO", name: Trad("Net FO") },
            { field: "NewKPIs.NetFO", name: Trad("New Net FO") },
        ];
        //}

        //if (applicationConfig.includes("FOS")) {
        kpis = [
            ...kpis,
            { field: "KPIs.NetFOS", name: Trad("Net FOS") },
            { field: "NewKPIs.NetFOS", name: Trad("New Net FOS") }
        ];
        //}

        const newkpis = kpis.map((e) => new ADWColumn<ref_Messages>(Trad(e.name), propertyOf<ref_Messages>(e.field as any), ePropType.Double));

        const currencies = (await Client.get<ref_Currencies>(ref_Currencies)).data.results;

        const generateSpan = (valueToCompare: number, defaultValue: number, cellValue: any, dataItem?: AdwRow<ref_Messages>) => {
            const compared = valueToCompare - defaultValue;
            let className = "";
            let icon = null;

            if (compared === 0) {
                className = "equal";
                icon = <span style={{ width: "24px", textAlign: "center" }}>=</span>;
            } else if (compared >= 0) {
                className = "greater";
                icon = getIcon("arrow_drop_up");
                if (dataItem)
                    areGreater.push(dataItem.dataItem["@rid"]);
            } else {
                className = "lower";
                icon = getIcon("arrow_drop_down");
            }

            return <span className={`newkpis ${className}`}><GenericTooltip contentClassName="flex center" contentStyle={{ height: "100%" }} tooltipContent={Trad(`compare_agreement_${className}`)}>{icon}</GenericTooltip><div>{cellValue}</div></span>;
        }

        const grossbaCollumn = new ADWColumn<ref_Messages>(Trad("Brut Base Achat"), propertyOf<ref_Messages>("KPIs.GrossBa" as any), ePropType.Double);

        for (const c of [...newkpis, grossbaCollumn]) {
            c.baseColumn.valueType = eKPIType.Price;
            c.getValue = async (row) => {
                const currency = currencies.find(cur => cur["@rid"] === row.Currency);
                return `${GetKPITemplate(eKPIType.Price)(await c.getKPIValue(row))} ${currency.Code}`;
            };

            c.footerCellValue = (rows, value, currency) => {
                if (!currency)
                    return;
                const cellValue = `${GetKPITemplate(eKPIType.Price)(value)} ${currency}`;
                if (!c.bindingPath.includes("NewKPIs"))
                    return cellValue;

                const oldBindingPath = c.bindingPath.replace("New", "");
                let oldValue: number = 0;
                rows.forEach(r => {
                    oldValue += GetSubElement(r, oldBindingPath);
                });

                return generateSpan(value, oldValue, cellValue);
            }

            c.cellValue = (cellValue: any, row?: AdwRow<ref_Messages>) => {

                const currentValue = GetSubElement(row.dataItem, c.bindingPath);
                row[`${c.bindingPath}_cellValue`] = currentValue;

                if (!c.bindingPath.includes("NewKPIs"))
                    return <span style={{ textAlign: "right" }}><div>{cellValue}</div></span>;

                const newValue = GetSubElement(row.dataItem, c.bindingPath.replace("New", ""));
                return generateSpan(currentValue, newValue, cellValue, row);
            };

            c.width = 150;
        }

        const grid = await CreateGrid({
            vertexParams: this.state.config,
            vertexGridParams: {
                afterSearch: async (data) => {
                    for (const d of data)
                        await DiscountManager.lockDiscountsLockedFromMAP(d);
                    return data;
                },
                computeCellValues: true,
                width: {
                    Media: 100
                },
                order: [
                    "Campaign",
                    "AgreementStatus",
                    ...newkpis.map(k => k.bindingPath),
                    "Format",
                    "Placement",
                    "Media",
                    "Support",
                    "Start",
                    "Status",
                    "AdvertiserGroup",
                    "Advertiser",
                    "Brand",
                    "Product",
                    "KPIs.GrossBa",
                ],
                configuration: {
                    Active: true,
                    Default: false,
                    Name: ref_Messages.name,
                    Owner: null,
                    Table: ref_Messages.name,
                    FrozenPosition: 0,
                    Columns: [
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Campaign"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Format"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Placement"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Media"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Support"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Start"), eKPIType.Date),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Status"), eKPIType.String),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("AdvertiserGroup"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Advertiser"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Brand"), eKPIType.Rid),
                        CreateIndicateurInfo(propertyOf<ref_Messages>("Product"), eKPIType.Rid),
                    ],
                    ViewMode: "Table",
                    CrossedtableConfig: undefined,
                },
                forcedColumns: [
                    ...newkpis,
                    grossbaCollumn,
                    statusColumn
                ]
            },
        });
        // grid.elementsToSelect = this.props.agreement.Messages;
        const sort = GetSort<ref_Messages>(ref_Messages);
        this.setState({
            grid,
            sort,
        });
    }

    onConfChange = async () => {
        let config = null;
        config = this.getConfig();
        this.setState(
            {
                config,
            },
            async () => {
                const { grid, config } = this.state;
                grid.props.vertexParams = { ...config, properties: grid.props.vertexParams.properties };
                grid.elementsToSelect =
                    this.props.agreement["@rid"] && this.props.mode !== eDialogMode.duplicate
                        ? (
                            await Client.searchVertex(ref_Messages.name, {
                                Agreement: this.props.agreement["@rid"],
                                Source: "ADWONE",
                                Active: true,
                            })
                        )?.data?.results.map((e) => e["@rid"])
                        : [];
                grid.Initialize();
            }
        );
    };

    render() {
        const { agreement } = this.props;
        const { grid, sort } = this.state;

        return (
            <>
                {!agreement ? (
                    <Loader />
                ) : (
                    <div style={{ width: "100%", position: "relative" }}>
                        <CustomHeader agreement={agreement} />
                        {!grid ? <div style={{ height: styleGridContainer.agreementForm.height }}></div> : <AdwTelerikGrid
                            grid={grid}
                            gridHeight={styleGridContainer.agreementForm.height}
                            selectable
                            selectionChange={(_selectedRows) => {
                                agreement.Messages = _selectedRows.map((e) => e.dataItem["@rid"]);
                                // if (agreement.Messages.find(e => areGreater.includes(e))) {
                                //     agreement["needConfirm"] = true;
                                // }

                                agreement["needConfirm"] = agreement.Messages.some(e => areGreater.includes(e));
                                // this.forceUpdate();
                                eventSelectionChange.emit('selectionChange');
                            }}
                            hideToolbar
                            sort={sort}
                            uneditable
                            footer
                        />}
                    </div>
                )
                }
            </>
        );
    }
}

const eventSelectionChange = new EventEmitter();
function CustomHeader({ agreement }: { agreement: ref_Agreements }) {
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
    React.useEffect(() => {
        const update = () => { forceUpdate(); };
        eventSelectionChange.on('selectionChange', update);
        return () => { eventSelectionChange.removeListener('selectionChange', update); }
    });
    return (
        <Grid item xs={12} className="adw-title adw-form-title">
            {!Boolean(agreement.Messages?.length)
                ? <>
                    <div style={{ height: 18 }}>{getIcon("ref_Messages")}</div>
                    {Trad("available_messages")}
                </>
                : <>
                    {agreement.Messages.length + ` ${Trad("selected_items")}`}
                </>}
        </Grid>
    );
}

export default AgreementDialog;
