import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filters: { },
}

const supplierSlice = createSlice({
    name: "supplier",
    initialState,
    reducers: {
        setSpotFilters: (state, action) => {
            state.filters = action.payload;
        }
    }

});

export const { setSpotFilters } = supplierSlice.actions

export const supplierReducer = supplierSlice.reducer;
