import React from 'react';
import { useEffect, useState } from 'react';
import { CustomButton } from './ConfigurableComponents/CustomButton.bin';
import EventEmitter from 'events';
import { BasicGenericDialog } from './ConfigurableComponents/BasicGenericDialog.bin';

export class DialogEventEmitter extends EventEmitter {
  public push(params: GenericState) {
    this.emit("push", params);
  }

  public close() {
    this.emit("close");
  }
}

export const eventEmitter = new DialogEventEmitter();

type Button = {
  label: string;
  handleButton: () => void;
  className: string;
};

type GenericState = {
  title: string;
  content: JSX.Element | string;
  buttons?: Button[];
};

export function GenericModal(): JSX.Element {
  const [modalState, setModalState] = useState<GenericState>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const pushHandler = (params: GenericState) => {
      setModalState(params);
      setIsModalOpen(true);
    };

    const closeHandler = () => {
      setModalState(null);
      setIsModalOpen(false);
    };

    eventEmitter.addListener("push", pushHandler);
    eventEmitter.addListener("close", closeHandler);

    return () => {
      eventEmitter.removeListener("push", pushHandler);
      eventEmitter.removeListener("close", closeHandler);
    };
  }, []);

  return (
    <BasicGenericDialog
      open={isModalOpen}
      dialogTitle={modalState?.title}
      dialogContent={modalState?.content}
      handleClose={() => eventEmitter.emit("close")}
      actions
      additionalButtons={modalState?.buttons?.map((button: Button) => (
        <CustomButton
          className={button.className}
          key={button.label}
          Label={button.label}
          onClick={button.handleButton}
        />
      ))}
    />
  );
}
