import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { ref_AdvertiserGroups } from "hub-lib/models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "hub-lib/models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "hub-lib/models/orientdb/ref_Brands.bin";
import { ref_Products } from "hub-lib/models/orientdb/ref_Products.bin";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { clearEmptyValues, compareObjects, distinct, duplicate, extractSub, toArray } from "hub-lib/tools.bin";
import { RootStateFilters } from "./StoreFilters";

export class AdvertiserStoreSingleRid {
    AdvertiserGroup?: IRid["@rid"]
    Advertiser?: IRid["@rid"]
    Brand?: IRid["@rid"]
    Product?: IRid["@rid"]
    Campaign?: IRid["@rid"]
};

export class AdvertiserStoreMultiRid {
    AdvertiserGroup?: IRid["@rid"][]
    Advertiser?: IRid["@rid"][]
    Brand?: IRid["@rid"][]
    Product?: IRid["@rid"][]
    Campaign?: IRid["@rid"][]
};


export class StoreStateHierarchy {
    AdvertiserGroupOptions: ref_AdvertiserGroups[];
    AdvertiserOptions: ref_Advertisers[];
    BrandOptions: ref_Brands[];
    ProductOptions: ref_Products[];
    CampaignOptions: ref_Campaigns[];
}
export class StoreState extends StoreStateHierarchy {
    isLoaded: boolean;
    store: AdvertiserStoreMultiRid;

    AdvertiserGroupLoading: boolean;
    AdvertiserLoading: boolean;
    BrandLoading: boolean;
    ProductLoading: boolean;

    BrandDisabled: boolean;
    ProductDisabled: boolean;
}

const initialState: StoreState = {
    isLoaded: false,
    store: {},

    AdvertiserGroupOptions: undefined,
    AdvertiserOptions: undefined,
    BrandOptions: undefined,
    ProductOptions: undefined,
    CampaignOptions: undefined,

    AdvertiserGroupLoading: false,
    AdvertiserLoading: false,
    BrandLoading: false,
    ProductLoading: false,

    BrandDisabled: false,
    ProductDisabled: false
}

const addDefaultValues = async <T>(vertex: new () => T, store: string[], options: T[]): Promise<T[]> => {
    const defaultItems = store?.filter(o => !options.some(o => o["@rid"] == o["@rid"])) || [];
    if (defaultItems.length) {
        const ridParams = defaultItems.length == 1 ? defaultItems[0] : defaultItems;
        const missing = await Client.searchVertexTyped(vertex, { "@rid": ridParams, properties: ["Name", "Active"] });
        if (missing?.length)
            return [...options, ...missing];
    }
    return options;
}

export const setStore = createAsyncThunk(
    'storefilters/setStore',
    async ({ store }: Partial<StoreState>, thunkAPI) => {
        try {

            store = clearEmptyValues(duplicate(store))

            const newStorage: Partial<StoreState> = {
                store,
                isLoaded: true,
            };

            const currentStorage = ((thunkAPI.getState() as RootStateFilters).advertiserStorage ?? {}) as StoreState;
            const isLoaded = currentStorage.isLoaded;

            let addedAdvertisers: string[] = null;
            let addedBrands: string[] = null;
            let addedProducts: string[] = null;

            let removedAdvertisers: string[] = null;
            let removedBrands: string[] = null;
            let removedProducts: string[] = null;
            let removedGPAdvertisers: string[] = null;

            if (isLoaded) {
                addedProducts = store.Product?.filter(p => !currentStorage.store.Product?.includes(p));
                if (addedProducts?.length && RightManager.hasRight(ref_Brands.name, eRights.read)) {
                    const brands = await Client.searchVertexTyped(ref_Brands, { children: addedProducts, properties: ["@rid"] });
                    store.Brand = distinct([...(store.Brand) ?? [], ...brands.map(b => b["@rid"])], k => k);
                }

                addedBrands = store.Brand?.filter(p => !currentStorage.store.Brand?.includes(p));
                if (addedBrands?.length && RightManager.hasRight(ref_Advertisers.name, eRights.read)) {
                    const adertisers = await Client.searchVertexTyped(ref_Advertisers, { children: addedBrands, properties: ["@rid"] });
                    store.Advertiser = distinct([...(store.Advertiser) ?? [], ...adertisers.map(a => a["@rid"])], k => k);
                }

                addedAdvertisers = store.Advertiser?.filter(p => !currentStorage.store.Advertiser?.includes(p));
                if (addedAdvertisers?.length && RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read)) {
                    const adertiserGroups = await Client.searchVertexTyped(ref_AdvertiserGroups, { children: addedAdvertisers, properties: ["@rid"] });
                    store.AdvertiserGroup = distinct([...(store.AdvertiserGroup) ?? [], ...adertiserGroups.map(a => a["@rid"])], k => k);
                }

                removedProducts = currentStorage.store.Product?.filter(p => !store.Product?.includes(p));
                removedBrands = currentStorage.store.Brand?.filter(p => !store.Brand?.includes(p));
                removedAdvertisers = currentStorage.store.Advertiser?.filter(p => !store.Advertiser?.includes(p));
                removedGPAdvertisers = currentStorage.store.AdvertiserGroup?.filter(p => !store.AdvertiserGroup?.includes(p));
            }

            const params: {
                "ref_AdvertiserGroups"?: IRid["@rid"][],
                "ref_Advertisers"?: IRid["@rid"][],
                "ref_Brands"?: IRid["@rid"][],
                properties: string[]
            } = {
                properties: ["@rid", "Name"]
            };

            // Load AdvertiserGroups
            if (currentStorage?.AdvertiserGroupOptions) {
                newStorage.AdvertiserGroupOptions = currentStorage?.AdvertiserGroupOptions;
            } else if (RightManager.hasRight(ref_AdvertiserGroups.name, eRights.read)) {
                newStorage.AdvertiserGroupOptions = await Client.searchVertexTyped(ref_AdvertiserGroups);
            }

            if (isLoaded) {
                if (removedGPAdvertisers?.length && store.Advertiser?.length && RightManager.hasRight(ref_Advertisers.name, eRights.read)) {
                    removedAdvertisers = [...(removedAdvertisers ?? []), ...(await Client.searchVertexTyped(ref_Advertisers, { ref_AdvertiserGroups: removedGPAdvertisers, properties: ['@rid'] })).map(e => e["@rid"])]
                    removedAdvertisers = removedAdvertisers.filter(b => store.Advertiser.includes(b));
                    store.Advertiser = store.Advertiser.filter(b => !removedAdvertisers.includes(b));
                }

                if (removedAdvertisers?.length && store.Brand?.length && RightManager.hasRight(ref_Brands.name, eRights.read)) {
                    let results = await Client.searchVertexTyped(ref_Brands, { ref_Advertisers: removedAdvertisers, properties: ["@rid", "out(\"lnk_hierarchy\") as parents"] });
                    if (store.Advertiser?.length)
                        results = results.filter(r => !store.Advertiser.some(a => r["parents"]?.includes(a)));
                    removedBrands = [...(removedBrands ?? []), ...results.map(e => e["@rid"])]

                    removedBrands = removedBrands.filter(b => store.Brand.includes(b));
                    store.Brand = store.Brand.filter(b => !removedBrands.includes(b));
                }

                if (removedBrands?.length && store.Product?.length && RightManager.hasRight(ref_Products.name, eRights.read)) {
                    removedProducts = [...(removedProducts ?? []), ...(await Client.searchVertexTyped(ref_Products, { ref_Brands: removedBrands, properties: ['@rid'] })).map(e => e["@rid"])]
                    removedProducts = removedProducts.filter(b => store.Product.includes(b));

                    if (store.Brand?.length) {
                        // pour traiter le cas où on a des produits sur plusieurs marques et qu'on enlève une marque
                        const productsOfRemainingBrands = await Client.searchVertexTyped(ref_Products, { ref_Brands: store.Brand, properties: ['@rid'] });
                        removedProducts = removedProducts.filter(b => !productsOfRemainingBrands.some(p => p["@rid"] == b));
                    }

                    store.Product = store.Product.filter(b => !removedProducts.includes(b));
                }
            }

            const advertiserGroupsChanged = () => !compareObjects(extractSub(currentStorage?.store, ["AdvertiserGroup"]), extractSub(newStorage.store, ["AdvertiserGroup"]));
            const advertisersChanged = () => !compareObjects(extractSub(currentStorage?.store, ["Advertiser"]), extractSub(newStorage.store, ["Advertiser"]));
            const brandsChanged = () => !compareObjects(extractSub(currentStorage?.store, ["Brand"]), extractSub(newStorage.store, ["Brand"]));

            // Load Advertisers
            if (!isLoaded
                || advertiserGroupsChanged()
                || advertisersChanged()
                || brandsChanged()) {

                // On remet à 0 un niveau si le niveau supérieur est vide
                // if (!addedAdvertisers?.length && !store.AdvertiserGroup?.length)
                //     delete store.Advertiser;
                // if (!store.AdvertiserGroup?.length || !store.Advertiser?.length)
                //     delete store.Brand;
                // if (!store.AdvertiserGroup?.length || !store.Advertiser?.length || !store.Brand?.length)
                //     delete store.Product;

                // AdvertiserOptions
                if (RightManager.hasRight(ref_Advertisers.name, eRights.read)) {
                    if (store.AdvertiserGroup?.length)
                        params.ref_AdvertiserGroups = store.AdvertiserGroup;
                    if (!isLoaded || advertiserGroupsChanged())
                        newStorage.AdvertiserOptions = await Client.searchVertexTyped(ref_Advertisers, params);
                    if (!isLoaded)
                        newStorage.AdvertiserOptions = await addDefaultValues(ref_Advertisers, store.Advertiser, newStorage.AdvertiserOptions);
                    if (newStorage.store.Advertiser?.length) {
                        const advertisersId = toArray(store.Advertiser);
                        newStorage.store.Advertiser = advertisersId.filter(rid => (newStorage.AdvertiserOptions ?? currentStorage.AdvertiserOptions).some(o => o["@rid"] == rid));
                        params.ref_Advertisers = store.Advertiser;
                        delete params.ref_AdvertiserGroups;
                    }
                }

                // BrandOptions
                if (RightManager.hasRight(ref_Brands.name, eRights.read)) {
                    if (store.Advertiser?.length) {
                        if (!isLoaded || advertiserGroupsChanged() || advertisersChanged())
                            newStorage.BrandOptions = await Client.searchVertexTyped(ref_Brands, params);
                        if (!isLoaded)
                            newStorage.BrandOptions = await addDefaultValues(ref_Brands, store.Brand, newStorage.BrandOptions);
                        if (newStorage.store.Brand?.length) {
                            const brandsId = toArray(store.Brand);
                            store.Brand = brandsId.filter(rid => (newStorage.BrandOptions ?? currentStorage.BrandOptions).some(o => o["@rid"] == rid));
                            params.ref_Brands = store.Brand;
                            delete params.ref_Advertisers;
                        }
                        newStorage.BrandDisabled = false;
                    } else {
                        delete store.Brand;
                        newStorage.BrandOptions = [];
                        newStorage.BrandDisabled = true;
                    }
                }

                // ProductOptions
                if (RightManager.hasRight(ref_Products.name, eRights.read)) {
                    if (store.Advertiser?.length) {
                        if (!isLoaded || advertiserGroupsChanged() || advertisersChanged() || brandsChanged()) {
                            newStorage.ProductOptions = await Client.searchVertexTyped(ref_Products, params);
                            if (!isLoaded)
                                newStorage.ProductOptions = await addDefaultValues(ref_Products, store.Product, newStorage.ProductOptions);
                            if (store.Product?.length)
                                store.Product = store.Product.filter(rid => newStorage.ProductOptions.some(o => o["@rid"] == rid));
                            newStorage.ProductDisabled = false;
                        }
                    } else {
                        delete store.Product;
                        newStorage.ProductOptions = [];
                        newStorage.ProductDisabled = true;
                    }
                }
            }

            // if (applyFilters) {
            //     const advancedFilters = FilterStorage.getAdvancedFilters();
            //     if (advancedFilters?.AdvertiserGroup?.length > 1)
            //         newStorage.AdvertiserGroupOptions = newStorage.AdvertiserGroupOptions.filter((advGrpOptions) => advancedFilters.AdvertiserGroup.some((grpFilter) => advGrpOptions["@rid"] === grpFilter))

            //     if (advancedFilters?.Advertiser?.length > 1)
            //         newStorage.AdvertiserOptions = newStorage.AdvertiserOptions.filter((advOption) => advancedFilters.Advertiser.some((advFilter) => advOption["@rid"] === advFilter))

            //     if (advancedFilters?.Brand?.length > 1)
            //         newStorage.BrandOptions = newStorage.BrandOptions.filter((brOption) => advancedFilters.Brand.some((brFilter) => brOption["@rid"] == brFilter))

            //     if (advancedFilters?.Product?.length > 1)
            //         newStorage.ProductOptions = newStorage.ProductOptions.filter((brOption) => advancedFilters.Product.some((brFilter) => brOption["@rid"] == brFilter))
            // }
            return newStorage;
        } catch (error) {
            console.log(error)
        }
    }
)

export const StoreSlice = createSlice({
    name: 'storefilters',
    initialState,
    extraReducers: (builder) => {

        builder.addCase(setStore.pending, (state, action) => {

            const previousStore: AdvertiserStoreMultiRid = duplicate(state?.store ?? {});
            const newStore: AdvertiserStoreMultiRid = duplicate(action.meta.arg.store ?? {});

            if (!compareObjects(extractSub(previousStore, ["AdvertiserGroup"]), extractSub(newStore, ["AdvertiserGroup"]))) {
                state.AdvertiserLoading = true;
                state.BrandLoading = true;
                state.ProductLoading = true;
            } else if (!compareObjects(extractSub(previousStore, ["Advertiser"]), extractSub(newStore, ["Advertiser"]))) {
                state.BrandLoading = true;
                state.ProductLoading = true;
            } else if (!compareObjects(extractSub(previousStore, ["Brand"]), extractSub(newStore, ["Brand"]))) {
                state.ProductLoading = true;
            }
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(setStore.fulfilled, (state, action) => {
            Object.entries(action.payload).forEach(([k, v]) => {
                state[k] = v;
            });

            state.AdvertiserGroupLoading = false;
            state.AdvertiserLoading = false;
            state.BrandLoading = false;
            state.ProductLoading = false;
        });
    },
    reducers: {
        initStore: (state, action: PayloadAction<StoreState['store']>) => {
            state.store = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { initStore } = StoreSlice.actions

export const AdvertiserStoreReducer = StoreSlice.reducer;