import * as React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { propertyOf } from "hub-lib/tools.bin";
import { CustomIconButton } from "../VertexGrid/Generic/CustomIconButton";
import { getIcon } from "adwone-lib";

type Item = { Name: string, "@rid"?: any };
type AdvertisersGridProps = { data: Item[], onRemove?: (item: Item) => void }

export function CustomList({ data, onRemove }: AdvertisersGridProps) {
    return <Grid data={data}
        // onItemChange={this.itemChange}
        className={"modelecreator-tab custom-list"}>

        <GridColumn field={propertyOf<Item>('Name')} />
        {/* <GridColumn
            cell={(c) => <td><CustomIconButton onClick={() => onRemove?.(c.dataItem)}>
                {getIcon("close")}
            </CustomIconButton></td>}
            width={55} /> */}
    </Grid>
}