import { DiscountManager } from "hub-lib/business/DiscountManager.bin";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import React from "react";
import { DiscountGridRow } from "../DiscountGridRow";
import { ComputationBaseCellComponent } from "./ComputationBaseCellComponent";
import { RateCellComponent } from "./RateCellComponent";
import { AmountCellComponent } from "./AmountCellComponent";
import { CommandCellComponent } from "./CommandCellComponent";
import { CascadeRankCellComponent } from "./CascadeRankCellComponent";
import { Trad } from "trad-lib";
import { GetEditorState, RootState } from "../../../../../../redux/store";
import { useSelector } from "react-redux";
import { getIcon, TooltipManager } from "adwone-lib";
import { PercentTemplate } from "../../../../Adwone-admin/Referential/ClntReferentialForm";

const rowStyle: React.CSSProperties = {
    height: 28,
    alignItems: 'center',
}


type WarningProps = {
    discount: ref_Discount;
    children?: any,
}

export function Warning({ discount, children }: WarningProps) {
    const DiscountRates = useSelector((root: RootState) => root.messageEditor.clntDiscountRates);

    const checkDiscountRates = () => {
        if (DiscountRates && Object.keys(DiscountRates.Rates).includes(discount.DiscountClass) && DiscountRates.Rates[discount.DiscountClass] != discount.CO?.Rate)
            return DiscountRates.Rates[discount.DiscountClass];
        return null;
    }
    const warning = checkDiscountRates();
    const className = warning ? 'warning-element' : '';

    return <div className={className}>
        {warning && <div className='WarningDiscount-legend'
            onMouseOver={e => TooltipManager.Push({ target: e.target, text: `${Trad("warning_discountrates")} : ${PercentTemplate(warning * 100)} %` })}>
            {getIcon("warning")}
        </div>}
        {children}
    </div>
}

type DiscountClassGridRowProps = { discount: ref_Discount };

export function DiscountClassGridRow({ discount }: DiscountClassGridRowProps) {
    const dClass = DiscountManager.GetDiscountClassesLoaded().find(cl => cl["@rid"] == discount.DiscountClass);

    return <Warning discount={discount}><DiscountGridRow
        rowStyle={rowStyle}
        firstCell={<CascadeRankCellComponent discount={discount} />}
        labelCell={<span>{Trad(dClass.Name)}</span>}
        intervalCell={<ComputationBaseCellComponent discount={discount} />}
        rateCell={<RateCellComponent discount={discount} />}
        amountCell={<AmountCellComponent discount={discount} />}
        commandCell={<CommandCellComponent discount={discount} />}
    /></Warning>
}