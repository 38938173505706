import { CreateIndicateur, Indicateur, IndicateurToString } from "adwone-engine/index.bin";
import { CellValue } from "adwone-engine/types.bin";
import { ADWColumn, ADWGrid, AdwRow, ePropertyEvent } from "adwone-lib/index";
import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { lnk_ChangeRate } from "hub-lib/dto/client/lnk_ChangeRate.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { eBusinessCode } from "hub-lib/models/types.bin";
import { eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { memoizeAsyncBase } from "hub-lib/tools.bin";
import { recurse } from "tools-lib";
import { selectItems } from "../../../redux/gridSlice";
import { store } from "../../../redux/store";
import { ConsoleDebug } from "../../../utils/localstorage.bin";
import { GridBase } from "./GridBase.bin";

const ComputeMemoized = memoizeAsyncBase(async (indicateurInstance: Indicateur, msg: ref_Messages) => {
    const res = await indicateurInstance.Compute([msg]);
    return res;
})

export class VertexGrid<TVertex extends IRid> extends GridBase<TVertex> {

    getFormatedCell = async (col: ADWColumn<TVertex>, rows: AdwRow<TVertex>[]) => {
        const time7491 = new Date().getTime();
        if (col.baseColumn) {
            const ind = CreateIndicateur(col.baseColumn);
            const signature = IndicateurToString(ind);
            const time4668 = new Date().getTime();
            const cells: CellValue[] = [];
            for (const row of rows) {
                const res = await ComputeMemoized(ind, row.dataItem as any);
                const cellValue = new CellValue();
                cellValue.Value = res;
                cellValue.IndicateurSignature = signature;
                cellValue.Type = 'cell';
                cells.push(cellValue);
            }
            const _time4668 = new Date().getTime();
            ConsoleDebug(`[getFormatedCell] [compute] ${_time4668 - time4668}ms`);

            const time9009 = new Date().getTime();
            //const cellsFormated = await FormatCellsMemoized(this.objectPrototype.name, ind, cells);
            const _time9009 = new Date().getTime();
            ConsoleDebug(`[getFormatedCell] [formatCells] ${_time9009 - time9009}ms`);

            // cellsFormated.forEach((cell, i) => {
            //     rows[i][(col.bindingPath as string) + `_formated`] = cell.Formated ?? "";
            //     rows[i][GetHashCode((col.bindingPath as string)) + `_formated`] = cell.Formated ?? ""
            // });
        }
        const _time7491 = new Date().getTime();
        ConsoleDebug(`[getFormatedCell] Elapsed ${_time7491 - time7491}ms`);
    }

    async InitStore(bindingPath: string) {
        const elementType = this.properties.find(p => p.name == bindingPath)?.linkedClass;
        if (elementType) {
            const value = (await Client.searchVertex(elementType)).data.results;
            this.store[bindingPath] = value;
            return value;
        } else {
            this.store[bindingPath] = [];
        }
    }

    async GetStoredData(row: AdwRow<TVertex>, bindingPath: string) {
        let value = this.store[bindingPath];
        if (value === undefined) {
            return await this.InitStore(bindingPath);
        }
        return value;
    }

    elementsToSelect: TVertex["@rid"][] = [];

    protected isRowSelected(row: TVertex) {
        // implémentation de base, peut etre réécrit
        if (this.elementsToSelect?.includes(row["@rid"])) {
            this.elementsToSelect = this.elementsToSelect.filter(e => e != row["@rid"]);
            return true;
        }
        return false;
    }

    /**
     * Flush
     * @param rows
     */
    protected async endCompute(rows: AdwRow<TVertex>[]) {

        if (this.props.computeCellValues) {
            console.log(`{computeCellValues} enable`)
            await this.ComputeCellValues(rows);
        }

        this.elementsToSelect = [];
        const selectedItems: AdwRow<TVertex>[] = []
        recurse(rows, "Children", (e) => {
            if (e.selected)
                selectedItems.push(e);
        });
        store.dispatch(selectItems(selectedItems));
    }

    rowToObjectAfter(object: any, row: AdwRow<TVertex>) {
        object["@rid"] = row.dataItem["@rid"];
    }

    filterColumns(value: ADWColumn<TVertex>[]) {
        if (!RightManager.hasRight(lnk_ChangeRate.name, eRights.read))
            return value.filter(v => !v.baseColumn?.options?.['isPriceReturned']);
        return value;
    }

    async create(row: AdwRow<TVertex>) {
        const vertex = this.rowToObject(row);
        const errors: (keyof TVertex & string)[] = [];
        try {
            await this.validator(vertex, errors, row);
            if (!errors.length) {
                if (!this.props.onInlineNew) {
                    await Client.createVertex(this.objectPrototype.name + (this.props.objectPrototypeSuffixe ?? ""), vertex);
                    //Notify(Trad("create_success"), "success")
                    this.Initialize()
                }
                else
                    this.props.onInlineNew(vertex, row)
                return true
            }
        } catch (error: any) {
            const errorDesc: { type: eBusinessCode, properties: (keyof TVertex & string)[] } = error?.response?.data?.error?.data;
            if (errorDesc?.properties?.length)
                errorDesc.properties.forEach(p => errors.push(p))
        }

        if (errors?.length)
            ConsoleDebug(`Error on properties.`, errors);

        ADWGrid.onErrorProperties.emit(ePropertyEvent.error, errors);
        return false
    }

    async update(row: AdwRow<TVertex>) {
        let vertex = this.rowToObject(row);
        const errors: (keyof TVertex)[] = [];
        try {
            await this.validator(vertex, errors, row);
            if (!errors.length) {
                if (!this.props.onInlineEdited) {
                    this.props.onUpdateStarting?.(vertex, row);
                    await Client.updateVertex(this.objectPrototype.name + (this.props.objectPrototypeSuffixe ?? ""), vertex)
                    // Notify(Trad("update_success"), "success")
                    this.Initialize()
                }
                else {
                    this.props.onInlineEdited(vertex, row)
                    await this.ComputeRow(row, row.dataItem);

                    if (this.props.computeCellValues) {
                        console.log(`{computeCellValues} enable`)
                        await this.ComputeCellValues([row]);
                    }
                }

                return true
            }
        } catch (error) {
            console.log(`error`, error)
        }
        return false
    }

    async delete(rows: AdwRow<TVertex>[]) {
        const verteces = rows.map(row => row.dataItem["@rid"]);
        let vertecesAlt = rows.map(row => row.dataItem);
        if (!this.props.onInlineDeleted) {
            await Client.deleteVertex(this.objectPrototype.name + (this.props.objectPrototypeSuffixe ?? ""), verteces).catch(e => console.error(e))
            this.Initialize()
        }
        else {
            this.props.onInlineDeleted(vertecesAlt, rows)
        }
        return true;
    }
}