import { ref_AdvertisingCompanies, ref_AdvertisingCompaniesId } from "./ref_AdvertisingCompanies.bin";
import { src_MM, src_MMId } from "./src_MM.bin";
import { mm_AdvertisingCompanies, mm_AdvertisingCompaniesId } from "./mm_AdvertisingCompanies.bin";
import { ReferentialHasViews, ReferentialHasViewsId } from "./ReferentialHasViews.bin";
import { rid } from "./CommonTypes.bin";

export type vw_mm_HasAdvertisingCompaniesId = rid;

export class vw_mm_HasAdvertisingCompanies extends ReferentialHasViews<src_MMId, mm_AdvertisingCompaniesId, ref_AdvertisingCompaniesId> {
	"@rid"?: vw_mm_HasAdvertisingCompaniesId;

}