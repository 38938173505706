import * as React from "react";
import { VertexGrid } from "../Generic/VertexGrid.bin";
import { ADWColumn, AdwRow, getIcon } from "adwone-lib/index";
import { Trad, TradProp } from "trad-lib";
import DefaultGrid from "../DefaultGrid.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { GetOrder } from "format-lib/index.bin";
import { EstimateStatus, ref_Estimates } from "hub-lib/dto/client/ref_Estimates.bin";
import ReportEstimates from "./ReportEstimates.bin";
import { MMContractStatus, MMStatus } from "client-mbs/EstimatesResponse.bin";
import { EstimateForm } from "../../layout/Puchases/EstimateForm";
import SearchIcon from "@material-ui/icons/Search";
import history from "../../../utils/history";
import { RightManager, eFunctions, eRights } from "hub-lib/models/types/rights.bin";
import { EstimatesLinkedDetail } from "./EstimateLinkedDetails.bin";

export const EstimatesGrid = (props) => {
    const [grid, setGrid] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [ref, setRef] = React.useState<DefaultGrid<ref_Estimates>>(null);
    const [hasPDF, setHasPDF] = React.useState(false);
    const [hasInvalide, setHasInvalide] = React.useState(false);
    const [estimatedReported, setEstimatedReported] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {
            const columns: ADWColumn<ref_Estimates>[] = [];
            const hiddenProperties: string[] = [];
            const messagesCountColumn = new ADWColumn<ref_Estimates>(
                TradProp("MessagesCount", ref_Estimates),
                "MessagesCount",
                ePropType.Integer,
            );
            columns.push(messagesCountColumn);
            const statusColumn = new ADWColumn<ref_Estimates>(
                Trad("status"),
                "Status",
                ePropType.String,
                true
            );
            statusColumn.cellValue = (cellValue: any, dataItem: AdwRow<ref_Estimates>) => {
                const textTrad = Trad(dataItem.dataItem.Status);
                dataItem["Status_cellValue"] = textTrad;
                return <span className={`status_estimate_${dataItem.dataItem.Status}`}>{textTrad}</span>;
            };
            columns.push(statusColumn);
            hiddenProperties.push("Status");
            let grid = new VertexGrid<ref_Estimates>({
                objectPrototype: ref_Estimates,
                devMode: false,
                columns,
                vertexParams: {
                    Active: true,
                    countMessages: true,
                    properties: ["*", "Media.Name as MediaName"],
                },
                afterSearch: (data) => {
                    setHasPDF(data.some((e) => e.PdfLink));
                    setHasInvalide(data.some((e) => e.Invalide));
                    return data;
                },
                hiddenProperties,
                order: GetOrder<ref_Estimates>(ref_Estimates),
            });
            setGrid(grid);
        }
    });

    const closeReport = () => {
        setOpen(false);
        setEstimatedReported(undefined);
    };

    return <>
        <DefaultGrid
            ref={ref => setRef(ref)}
            key={`EstimatesGrid-${hasPDF}-${hasInvalide}`}
            filtersInToolbar
            filtersOrder={["Dates", "Hierarchy", "Agency", "Department", "Media", "Status", "ContractStatus"]}
            filtersParams={{ "Status": { options: Object.values(MMStatus) }, "ContractStatus": { options: Object.values(MMContractStatus) } }}
            filtersHideOptions={{ MapFilters: true }}
            objectPrototype={ref_Estimates}
            grid={grid}
            defaultItem={new ref_Estimates()}
            dialogContent={(item, mode) => <EstimateForm item={item} mode={mode} />}
            gridProps={{
                footer: true,
                ...{ DetailsComponent: RightManager.hasRight(eFunctions.ref_Estimates, eRights.readDetails) ? EstimatesLinkedDetail : undefined },
                commandCellArgs: {
                    isEditable: RightManager.hasRight(eFunctions.ref_Estimates, eRights.update),
                    additionalCommands: [{
                        icon: (dataItem: AdwRow<ref_Estimates>) => dataItem.dataItem.Invalide ? dataItem.dataItem.Import?.Status === EstimateStatus.Reported ? getIcon("schedule_send", {}, "warning_color") : getIcon("send", {}, "warning_color") : <SearchIcon />,
                        function: (row: AdwRow<ref_Estimates>) => {
                            if (row.dataItem.Invalide) {
                                setOpen(true);
                                setEstimatedReported(row.dataItem["@rid"]);
                            } else {
                                history.push(`/estimates/${row.dataItem["@rid"]}`)
                            }
                        }
                    }]
                }
            }}
        />
        {open && (
            <ReportEstimates
                rid={estimatedReported}
                open={open}
                toggleDialog={() => setOpen(false)}
                closeReport={() => {
                    ref.props.grid.UpdateRows();
                    closeReport()
                }}
            />
        )}
    </>
};
