import { ADWColumn, VertexAutocomplete } from "adwone-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Offers } from "hub-lib/dto/client/ref_Offers.bin";
import { src_CODIPRESSE } from "hub-lib/models/orientdb/src_CODIPRESSE.bin";
import { src_MEDIASIDE } from "hub-lib/models/orientdb/src_MEDIASIDE.bin";
import { src_PLS } from "hub-lib/models/orientdb/src_PLS.bin";
import { src_TSM } from "hub-lib/models/orientdb/src_TSM.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import { clone } from "hub-lib/tools.bin";
import * as React from "react";
import { Trad, TradProp } from "trad-lib";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import { ElementArg } from "../../VertexGrid/Messages/ConfigurationPanel";
import Template from "../Template";

export function OffersGridComponent() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {

            const formatCol = new ADWColumn<ref_Offers>(TradProp("Format", ref_Messages), "ModelProperties.Format", ePropType.Link);
            formatCol.cellValue = (value, dataItem) =>
                dataItem.dataItem?.["FormatName"] ?? "";

            const placementCol = new ADWColumn<ref_Offers>(TradProp("Emplacement", ref_Messages), "ModelProperties.Emplacement", ePropType.Link);
            placementCol.cellValue = (value, dataItem) =>
                dataItem.dataItem?.["EmplacementName"] ?? "";

            const IdColumns: ADWColumn<ref_Offers>[] = [
                new ADWColumn("SupportId", "Support", ePropType.String),
                new ADWColumn("BroadcastAreaId", "BroadcastArea", ePropType.String),
                new ADWColumn("MediaId", "Media", ePropType.String),
                new ADWColumn("CurrencyId", "Currency", ePropType.String)
            ];

            IdColumns.forEach(c => c.width = 110);

            const columns: ADWColumn<ref_Offers>[] = [
                new ADWColumn(TradProp("Source"), "Source", ePropType.String),
                new ADWColumn(TradProp("Updated_at"), "Updated_at", ePropType.Datetime),
                new ADWColumn(TradProp("Created_at"), "Created_at", ePropType.Datetime),
                formatCol,
                placementCol,
                new ADWColumn(TradProp("KPIs.Gross", ref_Messages), "KPIs.Gross", ePropType.Double),
                ...IdColumns
            ];
            const hiddenProperties: (keyof ref_Offers)[] = ["Name", "Key", "DataImported", "Model"];
            //const properties: string[] = [];
            const grid = new VertexGrid<ref_Offers>({
                disableStore: true,
                objectPrototype: ref_Offers,
                devMode: false,
                columns,
                order: ["Source", "Start", "End", "Media", "Support", "BroadcastArea", "Media", "Currency", "ModelProperties.Format", "ModelProperties.Emplacement", "KPIs.Gross", "Updated_at", "Created_at"],
                vertexParams: {
                    All: true,
                    Active: true,
                    properties: ["*", "BroadcastArea.Name as BroadcastAreaName",
                        "ModelProperties.Format.Name as FormatName",
                        "ModelProperties.Emplacement.Name as EmplacementName"]
                },
                width: {
                    Currency: 120,
                    Media: 110,
                    Start: 110,
                    End: 110
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return <DefaultGrid
        objectPrototype={ref_Offers}
        configurationPanelElements={ExternalDataFilters}
        grid={grid}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}

export const ExternalDataFilters: (defaultElements: ElementArg[], grid: DefaultGrid<any>) => any = (defaultElements, gridInstance) => {
    const baseComponent = defaultElements[1].element;
    defaultElements[1].element = <>
        {baseComponent}
        <VertexAutocomplete
            label="Sources"
            multiple
            options={[
                src_CODIPRESSE.name,
                src_MEDIASIDE.name,
                src_PLS.name,
                src_TSM.name
            ]}
            onChange={(value) => {
                const filters = clone(gridInstance.state.additionalConfig);
                filters['Sources'] = value;
                gridInstance?.setState({ additionalConfig: filters }, gridInstance.onConfChange);
                gridInstance?.setConfig(filters);
            }} />
    </>

    return defaultElements;
}

export const OffersGrid = Template(OffersGridComponent);