import { ref_PropertyType, ref_PropertyTypeId } from "./ref_PropertyType.bin";
import { ref_Model, ref_ModelId } from "./ref_Model.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";

export type lnk_HasPropertyTypeId = rid;

export class lnk_HasPropertyType extends E<ref_PropertyTypeId, ref_ModelId> {
	"@rid"?: lnk_HasPropertyTypeId;

}