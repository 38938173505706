import { ref_Media, ref_MediaId } from "./orientdb/ref_Media.bin";
import { lnk_HasKPI } from "./orientdb/lnk_HasKPI.bin";
import { ref_KPIs } from "./orientdb/ref_KPIs.bin";
import { ClassProperty, ePropType } from "./VertexProperty.bin";
import { eCompare, Operator } from "../operators.bin";
import { lnk_HasPropertyType } from "./orientdb/lnk_HasPropertyType.bin";
import { ref_Property } from "./orientdb/ref_Property.bin";
import { DataProvider } from "../provider";
import { TradValue } from "trad-lib";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";
import { ref_Messages } from "../dto/client/ref_Messages.bin";
import { eFunctions, eRights, RightManager } from "./types/rights.bin";
import { ref_Currencies } from "./orientdb/ref_Currencies.bin";
import { kpis } from "../types";

export enum eKPI {
    Total = "Nombre total",
    Paid = "Nombre payant",
    Free = "Nombre gracieux",
    GrossFormat = "Brut format défaut",
    GrossFormatRate = "Taux format défaut",
    GrossFormatValRate = "Taux valorisé format défaut",
    //Device = "Device",
    //TypeBuy = "Type d'achat",
    //TypePack = "Type de package",
    SOV = "SOV",
    //TypeActivity = "Type d'activité",
    ViewRate = "Viewability rate",
    Position = "Position",
    //TypeNav = "Type de navigation",
    Net = "Net",
    PaidCirculation = "Diffusion payée",
    TotalCirculation = "Diffusion totale",
    Subscriptions = "Abonnements",
    Release = "Tirage",
    ToDeliver = "Nb ex à livrer (passe incluse)",
    Pagination = "Pagination",
    Budget = "Budget",
    GRP = "GRP",
    Contacts = "contacts",
    CPM = "CPM",
    DeliveredCPM = "DeliveredCPM",
    DeliveredImpression = "DeliveredImpression",
    TargetingImpression = "TargetingImpression",
    VisibilityImpression = "VisibilityImpression",
    TotalClic = "TotalClic",
    DeliveredClic = "DeliveredClic",
    CPC = "CPC",
    DeliveredCPC = "DeliveredCPC",
    ClicRate = "ClicRate",
    CPV = "CPV",
    CompletionRate = "CompletionRate",
    DeliveringRate = "DeliveringRate",
    Video25PercentViews = "Video25PercentViews",
    Video50PercentViews = "Video50PercentViews",
    Video75PercentViews = "Video75PercentViews",
    Video100PercentViews = "Video100PercentViews"
}

export enum eKPIType {
    Number = "Number",
    Decimal = "Decimal",
    Percent = "Percent",
    Rid = "@rid",
    String = "String",
    Price = "Price",
    PriceReturned = "PriceReturned",
    PriceBound = "PriceBound",
    Date = "Date",
    DateTime = "DateTime",
    FullDecimal = "FullDecimal",
    Rate = "Rate",
}
export type KPICategory = "Format" | "Pricing" | "volumes" | "Performance" | "Audience" | "PlanPurchased" | "Additional" | "TechnicalSpecifications" | "ExternalReferences" | "Impressions" | "Clics" | "Videos";
export const PerformanceKPIs = [eKPI.PaidCirculation, eKPI.TotalCirculation, eKPI.Release, eKPI.Subscriptions, eKPI.ToDeliver];
export const PerformanceDIGKPIs = [eKPI.Total, eKPI.TotalClic];
export enum eTags {
    FullWidth = "FullWidth",
    TextEditor = "TextEditor",
    Website = "Website",
    Upload = "Upload"
}

export const eHandleTypes = {
    [ePropType.Decimal]: eKPIType.Number,
    [ePropType.Integer]: eKPIType.Number,
    [ePropType.Short]: eKPIType.Number,
    [ePropType.Long]: eKPIType.Number,
    [ePropType.Float]: eKPIType.Number,
    [ePropType.Double]: eKPIType.Number,
    [ePropType.Binary]: eKPIType.Number,
    [ePropType.Byte]: eKPIType.Number,
    [ePropType.Datetime]: eKPIType.Date,
    [ePropType.Date]: eKPIType.Date,
    [ePropType.String]: eKPIType.String,
    [ePropType.Link]: eKPIType.Rid,
    [ePropType.LinkSet]: eKPIType.Rid
}

export const eHandleTypesReverse = {
    [eKPIType.Decimal]: ePropType.Double,
    [eKPIType.Number]: ePropType.Integer,
    [eKPIType.Date]: ePropType.Date,
    [eKPIType.String]: ePropType.String,
    [eKPIType.Rid]: ePropType.Link,
    [eKPIType.Price]: ePropType.Double,
}

export function ToEKPIType(e: ePropType) {
    return eHandleTypes[e] || eKPIType.String;

}

export function ToEPropType(e: eKPIType) {
    // ce serait plus logique de mettre string par défaut mais ça a des répercussions dans les colonnes de KPIs
    return eHandleTypesReverse[e] ?? ePropType.Integer;

}

export function IsNumber(type: eKPIType) {
    return [eKPIType.Decimal, eKPIType.Rate, eKPIType.FullDecimal, eKPIType.Number, eKPIType.Percent, eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(type);
}

export function IsDate(type: eKPIType) {
    return [eKPIType.Date, eKPIType.DateTime].includes(type);
}

export function IsPrice(type: eKPIType) {
    return [eKPIType.Price, eKPIType.PriceReturned, eKPIType.PriceBound].includes(type);
}

export function KpiToFormat(kpi: string) {
    switch (kpi) {
        case "coverage":
            return "P2";
        case "contacts":
        case "coverage_thousands":
            return "n0";
        case "repetition":
            return "n1";
        default:
            return "n2";
    }
}

export interface IModelInfo {
    "@class"?: string;
    Id: string;
    Name: string;
    ValueType: eKPIType;
}

export class lnk_HasKPIExtended extends lnk_HasKPI implements IModelInfo {
    SourceType: string;
    Name: eKPI;
    NameLnk: string;
    Media: ref_MediaId;
}

type ValueChoiceType = { rid: string, value: string, "@class"?: string };
export class lnk_HasPropertyTypeExtended extends lnk_HasPropertyType implements IModelInfo {
    ValueChoices: ValueChoiceType[];
    Sort?: (a: ValueChoiceType, b: ValueChoiceType) => number;

    Name: string; //ref_PropertyType.Type
    Id: string; //ref_PropertyType.Type
    ValueType: eKPIType; //ref_PropertyType.ValueType
    Tags: (string | eTags)[];
    Rank?: number;
}

export const ValueChoicesTypeSort = (a: ValueChoiceType, b: ValueChoiceType) => {
    if (a.value == "Autres") return 1;
    if (b.value == "Autres") return -1;
    if (a["@class"] && b["@class"])
        return TradValue(a["@class"], "Name", a.value).localeCompare(TradValue(b["@class"], "Name", b.value));
    return a.value.localeCompare(b.value);
}


export class KPIsManager {
    static AdvertiserProperties = ["Group", "AdvertiserGroup", "Advertiser", "Brand", "Product"];
    static AgencyProperties = ["Agency_Res", "Agency_Fact"];
    static AdvCompanyProperties = ["AdvCompany_Com", "AdvCompany_Fin"];
    static EstimateProperties = ["Estimates",
        "EstimateNumber",
        "Deversement.Estimate",
        "Deversement.InfoCO.LastUpdate",
        "Deversement.InfoFO.LastUpdate",
        "Deversement.InfoCO.Diff",
        "Deversement.InfoFO.Diff",
        "Deversement.Id"];
    static SupportProperties = ["Support", "Media", "BroadcastArea", "Currency"];
    static SupportPressProperties = ["EditorialTheme", "BroadcastAreaDetail"];
    static SupportDetailProperties = ["ModelProperties.Dated",
        "ModelProperties.Reference",
        "ModelProperties.MediaFamily",
        "ModelProperties.MediaFamilyOther",
        "ModelProperties.Periodicity",
        "ModelProperties.PeriodicityComment",
        "ModelProperties.SupportDetails",
        "ModelProperties.OjdYear"];
    static TechConfCancelProperties = ["TechDeliveryTimeLimit",
        "TechDeliveryComments",
        "ConfirmationTimeLimit",
        "ConfirmationComments",
        "CancellationTimeLimit",
        "CancellationComments"];

    private lnk_HasProperties: {
        [key: string]: lnk_HasPropertyTypeExtended[]
    } = {};
    private lnk_HasKPIs: lnk_HasKPIExtended[] = null;
    private _objectType: string;
    protected metadata: ClassProperty[];

    constructor(objectType: string) {
        this._objectType = objectType;
    }

    public SellingMode(): boolean {
        return RightManager.GetUser()?.customer?.Activity == "Selling";
    }

    public async GetLnkHasKPIs(media?: ref_MediaId): Promise<lnk_HasKPIExtended[]> {
        if (!this.lnk_HasKPIs) {
            let results = await DataProvider.search<lnk_HasKPIExtended>(lnk_HasKPI.name,
                {
                    "out.DocumentType": this._objectType,
                    "in.Name": "AdwOne",
                    properties: ["*", "in.Name as SourceType", "KPI.Name as Name", "Name as NameLnk", "first(out.MediaTypes) as Media"]
                });
            this.lnk_HasKPIs = results.filter(r => r.Id);
        }
        if (media)
            return this.lnk_HasKPIs.filter(l => l.Media == media);

        return this.lnk_HasKPIs;
    }

    public async GetLnkHasProperties(media: ref_MediaId): Promise<lnk_HasPropertyTypeExtended[]> {
        if (!this.lnk_HasProperties[media]) {

            let lnkHasProperties = await DataProvider.search<lnk_HasPropertyTypeExtended>(lnk_HasPropertyType.name, {
                "out.DocumentType": this._objectType,
                "first(out.MediaTypes)": media,
                properties: ["*", "in.Type as Name", "in.Type as Id", "in.ValueType as ValueType", "in.Tags as Tags", "in.Rank as Rank"]
            });

            const propertyNames = (await this.GetUserProperties()).map(p => {
                const a = p.name.split(".");
                return a[a.length - 1];
            });
            lnkHasProperties = lnkHasProperties.filter(lnk => propertyNames.includes(lnk.Name));

            console.log("lnkHasProperties", lnkHasProperties);
            const ridPropertyTypes = lnkHasProperties.filter(r => r.ValueType === eKPIType.Rid && r.Id != "BroadcastPlacement");
            const RidType = ridPropertyTypes.map(r => r.in);
            if (RidType.length) {
                const operator: Operator = { property: "PropertyType", value: RidType, compare: eCompare.ContainsAny }
                const properties = await DataProvider.search<ref_Property>(ref_Property.name, { Active: true }, [operator]);

                for (const result of lnkHasProperties.filter(r => r.ValueType === eKPIType.Rid)) {
                    result.ValueChoices = properties.filter(p => p.PropertyType.includes(result.in)).map(p => ({ rid: p["@rid"], value: TradValue(ref_Property.name, "Name", p.Name), "@class": p["@class"] }));
                }
            }
            this.lnk_HasProperties[media] = lnkHasProperties;
        }
        return this.lnk_HasProperties[media];
    }

    public async GetUserKPIs(): Promise<ref_KPIs[]> {
        return KPIsManagerCache.GetKPIs();
    }

    public async GetUserProperties(): Promise<ClassProperty[]> {
        if (!this.metadata) {
            const metadata = (await DataProvider.getMetadata(this._objectType, true));
            this.metadata = metadata;
        }

        const properties = this.metadata.filter(m => m.name !== 'Active'
            && m.name !== 'Group'
            && RightManager.hasRight(m.linkedClass, eRights.read)
            && (!KPIsManager.EstimateProperties.includes(m.name) || RightManager.hasRight(eFunctions.ref_Estimates, eRights.read))
            && (m.name !== 'ReturnedCurrency' || RightManager.hasRight(eFunctions.lnk_ChangeRate, eRights.read)))

        return properties;
    }

    protected eKPIByCategory(category: KPICategory): eKPI[] {
        return null;
    }

    public async GetCategoryLnkHasKPIs(category: KPICategory, media?: ref_MediaId): Promise<lnk_HasKPIExtended[]> {

        const eKpis = this.eKPIByCategory(category);
        if (!eKpis)
            return [];
        const kpis = (await this.GetUserKPIs()).filter(k => eKpis.includes(k.Name as eKPI));
        if (!kpis.length)
            return [];
        const lnkHasKpis = await this.GetLnkHasKPIs(media);

        return lnkHasKpis.filter(e => kpis.some(kpi => kpi.Name == e.Name))
            .sort((e1, e2) => eKpis.indexOf(e1.Name) - eKpis.indexOf(e2.Name));
    }

    public SelectorProperties(): string[] {
        if (this.SellingMode())
            return ['Campaign', 'Start', 'End', ...KPIsManager.SupportProperties];
        return ['Campaign', ...KPIsManager.AdvertiserProperties];
    }

    public SetMessagePropertiesFromCampaign(campaign: ref_Campaigns, message: ref_Messages) {
        const properties = this.SelectorProperties();
        properties.forEach(p => {
            if (p == "Campaign")
                message[p] = campaign?.["@rid"];
            else
                message[p] = campaign?.[p];
        })

        if (this.SellingMode()) {
            KPIsManager.TechConfCancelProperties.forEach(p => message[p] = campaign?.[p]);
            KPIsManager.SupportPressProperties.forEach(p => {
                if (!message.ModelProperties)
                    message.ModelProperties = {};
                message.ModelProperties[p] = campaign?.ModelProperties?.[p];
            });
        }
    }

    public async HasPerformances(media: ref_MediaId): Promise<boolean> {
        const performances = await this.PerformanceFromMedia(media);
        const userKpis = await this.GetUserKPIs();
        return userKpis.filter(k => performances.some(pk => pk == k.Name)).length > 0;
    }

    public async PerformanceFromMedia(media: ref_MediaId) {
        const _medias = await KPIsManagerCache.GetMedias();
        if (media && media == _medias.find(m => m.Code == "DIG")?.["@rid"])
            return PerformanceDIGKPIs;
        return PerformanceKPIs;
    }

    public async InitializeCPM(msg: ref_Messages, kpis: kpis) {
        if (msg.IsGrossCPM) {
            const performance = (await this.PerformanceFromMedia(msg.Media))?.[0];
            let defaultKPICPM = (await this.GetLnkHasKPIs(msg.Media)).find(kpi => kpi.Name == performance);
            msg.KpiCPM = defaultKPICPM?.KPI;

            const base = kpis?.[defaultKPICPM.Id] ?? 0;

            if (base != 0)
                msg.KPIs["GrossCPM"] = msg.KPIs.Gross * 1000 / base;
            else
                msg.KPIs["GrossCPM"] = 0;
        }
        else {
            msg.KpiCPM = null;
            delete msg.KPIs["GrossCPM"];
        }
    }
}

class CampaignKPIsManager extends KPIsManager {
    /**
     *
     */
    constructor() {
        super(ref_Campaigns.name);
    }

    public async GetUserKPIs(): Promise<ref_KPIs[]> {
        let userKPIs = await super.GetUserKPIs();

        if (!this.SellingMode())
            userKPIs = userKPIs.filter(kpi =>
                kpi.Name != eKPI.Pagination
                && !PerformanceKPIs.some(p => kpi.Name == p));
        return userKPIs;
    }

    public async GetUserProperties(): Promise<ClassProperty[]> {
        let properties = await super.GetUserProperties();

        if (this.SellingMode())
            properties = properties.filter(p =>
                !KPIsManager.AdvertiserProperties.includes(p.name) &&
                p.name != "Visuals");
        else
            properties = properties.filter(p =>
                !KPIsManager.SupportProperties.includes(p.name) &&
                !KPIsManager.SupportDetailProperties.includes(p.name) &&
                !KPIsManager.SupportPressProperties.includes(p.name) &&
                !KPIsManager.TechConfCancelProperties.includes(p.name));

        properties = properties.filter(p =>
            !KPIsManager.AgencyProperties.includes(p.name) &&
            !KPIsManager.AdvCompanyProperties.includes(p.name));

        //console.log("Campaign Properties", properties);

        return properties;
    }

    protected eKPIByCategory(category: KPICategory): eKPI[] {
        switch (category) {
            case "Pricing":
                return [eKPI.Budget, eKPI.Pagination];
            case "Performance":
                return PerformanceKPIs;
        }
        return super.eKPIByCategory(category);
    }
}

class MessageKPIsManager extends KPIsManager {

    /**
     *
     */
    constructor() {
        super(ref_Messages.name);

    }

    public async GetUserKPIs(): Promise<ref_KPIs[]> {
        let userKPIs = await super.GetUserKPIs();

        if (this.SellingMode())
            userKPIs = userKPIs.filter(kpi =>
                !PerformanceKPIs.some(p => kpi.Name == p)
                && kpi.Name != eKPI.ViewRate
                && kpi.Name != eKPI.SOV);
        else
            userKPIs = userKPIs.filter(kpi => kpi.Name != eKPI.Position);
        return userKPIs;
    }

    public async GetUserProperties(): Promise<ClassProperty[]> {
        let properties = await super.GetUserProperties();

        if (!this.SellingMode())
            properties = properties.filter(p => p.name != "Agency_Fact");
        else
            properties = properties.filter(p =>
                !KPIsManager.AdvCompanyProperties.includes(p.name) &&
                !KPIsManager.SupportDetailProperties.includes(p.name) &&
                p.name != "Agency_Fact" && p.name != "ModelProperties.AdCreation");

        //console.log("Message Properties", properties);

        return properties;
    }

    protected eKPIByCategory(category: KPICategory): eKPI[] {
        switch (category) {
            case "Format":
                return [eKPI.Position];
            case "Pricing":
                return [eKPI.GrossFormat, eKPI.GrossFormatRate, eKPI.GrossFormatValRate];
            case "volumes":
                return [eKPI.Total, eKPI.Paid, eKPI.Free];
            case "Performance":
                return [eKPI.ViewRate, eKPI.SOV, ...PerformanceKPIs];
            case "Audience":
                return [eKPI.GRP, eKPI.Contacts];
            case "Impressions":
                return [
                    eKPI.Total,
                    eKPI.Paid,
                    eKPI.Free,
                    eKPI.CPM,
                    eKPI.DeliveredCPM,
                    eKPI.DeliveredImpression,
                    eKPI.TargetingImpression,
                    eKPI.VisibilityImpression];
            case "Clics":
                return [
                    eKPI.TotalClic,
                    eKPI.DeliveredClic,
                    eKPI.CPC,
                    eKPI.DeliveredCPC,
                    eKPI.ClicRate];
            case "Videos":
                return [
                    eKPI.CPV,
                    eKPI.CompletionRate,
                    eKPI.DeliveringRate,
                    eKPI.Video25PercentViews,
                    eKPI.Video50PercentViews,
                    eKPI.Video75PercentViews,
                    eKPI.Video100PercentViews];
        }
        return super.eKPIByCategory(category);
    }
}

export class KPIsManagerCache {
    private static kpis: ref_KPIs[] = null;
    private static medias: ref_Media[] = null;
    private static currencies: ref_Currencies[] = null;
    private static instances: { [prop: string]: KPIsManager } = {};

    public static async GetKPIs(): Promise<ref_KPIs[]> {
        if (!KPIsManagerCache.kpis) {
            KPIsManagerCache.kpis = await DataProvider.search<ref_KPIs>(ref_KPIs.name);
        }
        return KPIsManagerCache.kpis;
    }

    public static async GetCurrencies(): Promise<ref_Currencies[]> {
        if (!KPIsManagerCache.currencies) {
            KPIsManagerCache.currencies = (await DataProvider.search<ref_Currencies>(ref_Currencies.name));
        }
        return KPIsManagerCache.currencies;
    }

    public static async GetMedias(): Promise<ref_Media[]> {
        if (!KPIsManagerCache.medias) {
            KPIsManagerCache.medias = (await DataProvider.search<ref_Media>(ref_Media.name));
        }
        return KPIsManagerCache.medias;
    }

    public static GetInstance(objectType: string): KPIsManager {

        if (!KPIsManagerCache.instances[objectType]) {
            switch (objectType) {
                case "ref_Campaigns":
                    KPIsManagerCache.instances[objectType] = new CampaignKPIsManager();
                    break;
                case "ref_Messages":
                    KPIsManagerCache.instances[objectType] = new MessageKPIsManager();
                    break;
                default:
                    KPIsManagerCache.instances[objectType] = new KPIsManager(objectType);
                    break;
            }

        }

        return KPIsManagerCache.instances[objectType];
    }
}
