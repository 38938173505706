import { Client } from "hub-lib/client/client.bin";
import { IRid } from "hub-lib/models/IRid.bin";
import { classType, rid } from "hub-lib/models/orientdb/CommonTypes.bin";
import { E } from "hub-lib/models/orientdb/E.bin";
import { mm_Advertisers } from "hub-lib/models/orientdb/mm_Advertisers.bin";
import { mm_Brands } from "hub-lib/models/orientdb/mm_Brands.bin";
import { mm_Products } from "hub-lib/models/orientdb/mm_Products.bin";
import { ReferentialHasViews } from "hub-lib/models/orientdb/ReferentialHasViews.bin";
import { Referentials } from "hub-lib/models/orientdb/Referentials.bin";
import { ref_Agencies } from "hub-lib/models/orientdb/ref_Agencies.bin";
import { ref_AgencyGroups } from "hub-lib/models/orientdb/ref_AgencyGroups.bin";
import { ref_Companies } from "hub-lib/models/orientdb/ref_Companies.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_Sources } from "hub-lib/models/orientdb/ref_Sources.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { src_AdwOne } from "hub-lib/models/orientdb/src_AdwOne.bin";
import { src_CODIPRESSE } from "hub-lib/models/orientdb/src_CODIPRESSE.bin";
import { src_MM } from "hub-lib/models/orientdb/src_MM.bin";
import { Views, ViewsId } from "hub-lib/models/orientdb/Views.bin";
import { linkType, metaEdge, metaEdgeproperty } from "hub-lib/models/types.bin";
import { rmAccents } from "hub-lib/tools.bin";
import { loadingGridRef, loadingGridView, loadingLinks, setState } from "../../../../redux/linkMgrSlice";
import { store } from "../../../../redux/store";
import { ConsoleDebug, IsDebugMode, PrintMesureTime, StartMeasureTime } from "../../../../utils/localstorage.bin";
import { src_TSM } from "hub-lib/models/orientdb/src_TSM.bin";
import { src_PIGE } from "hub-lib/models/orientdb/src_PIGE.bin";

const sources = [
    src_AdwOne.name,
    src_CODIPRESSE.name,
    src_TSM.name,
    src_MM.name
]

let delaySearchModality;
let indexerLinksBySource: { [source: string]: { [className: string]: { [baseClassName: string]: rid[] } } } = {};
var indexerAllLinksBySource: { [source: string]: { [className: string]: rid[] } } = {};
var indexerAllLinks: { [key in classType]: rid[] } = {};
var indexerLinks: { [key in classType]: { [id in rid]: rid } } = {};
var indexerLinksOneWay: { [key in classType]: { [id in rid]: rid } } = {};

export const MappingProperties: { [key: string]: string[] } = {
    "Format": ["HasFormat"],
    "Emplacement": ["HasPlacement", "HasEmplacement"],
    "Couleur": ["HasCouleur"],
    "Rubrique": ["HasRubrique"],
    "Implantation": ["HasImplantation"],
    "Majoration": ["HasMajoration"],
}

export function getProp(edge: metaEdge, propname: linkType): metaEdgeproperty | undefined {
    for (let idx = 0; idx < edge.properties.length; idx++) {
        const prop = edge.properties[idx];
        if (prop.name === propname) return prop;
    }
    return undefined;
}

export function getDimension(edge: metaEdge): metaEdgeproperty | undefined {
    return getProp(edge, "Referential");
}

export function getView(edge: metaEdge): metaEdgeproperty | undefined {
    return getProp(edge, "out");
}

export function getSource(edge: metaEdge): metaEdgeproperty | undefined {
    return getProp(edge, "in");
}

export const getLinkFromName = (
    links: metaEdge[],
    dim: string,
    selectedSource: ref_Sources | undefined
): metaEdge | undefined => {
    if (!selectedSource) {
        console.log("No selected source");
        return undefined;
    }

    for (const link of links) {
        let dimLink = getDimension(link);
        let source = getSource(link);
        if (
            dimLink &&
            source &&
            dimLink.linkedClass === dim &&
            source.linkedClass === selectedSource["@class"]
        )
            return link;
    }
    return undefined;
};

export const getNbLinks = (selectedLink: metaEdge, source: IRid["@rid"]) => {
    if (selectedLink) {
        const key: string = selectedLink.name;
        if (key && source) return Object.keys(indexerLinksBySource[source]?.[key]?.["Views"] ?? {}).length;
    }
    return 0;
}

export const getTotalNbLinks = (selectedLink: metaEdge, source: IRid["@rid"]) => {
    if (selectedLink) {
        const key: string = selectedLink.name;
        if (key && source) return Object.keys(indexerAllLinksBySource[source]?.[key] ?? {}).length;
    }
    return 0;
}

export const isLinked = (element: IRid, baseClassName?: "Referentials" | "Views") => {
    return isRidLinked(element?.["@rid"], baseClassName);
};

export const isRidLinked = (rid: IRid["@rid"], baseClassName?: "Referentials" | "Views") => {
    const { selectedLink, selectedSource } = store.getState().linkMgr;

    if (selectedLink) {
        const key: string = selectedLink.name;
        const id: string | undefined = rid;
        //if (key && id && indexerLinks[key] && indexerLinks[key][id]) return true;


        if (key && id) {
            if (baseClassName) {
                if (indexerLinksBySource[selectedSource["@rid"]]?.[key]?.[baseClassName].includes(id))
                    return true;
            }
            else if (indexerLinksBySource[selectedSource["@rid"]]?.[key]?.["Views"].includes(id) ||
                indexerLinksBySource[selectedSource["@rid"]]?.[key]?.["Referentials"].includes(id))
                return true;
        }
    }
    return false;
};

export const iconLinked = (d: IRid) => {
    return isLinked(d) ? "link" : "link_off";
};

export const refClassname = (d: IRid) => {
    const { inId } = store.getState().linkMgr;

    let base = (d?.["Active"] === false) ? "is-inactive " : "";
    if (inId === d["@rid"]) return base + "is-selected";
    if (isLinked(d)) return base + "is-linked";

    return base;
};

export const getLinkFromIn = async (inId: string | undefined): Promise<ViewsId[]> => {
    if (inId) {
        const { selectedLink, selectedSource } = store.getState().linkMgr;
        if (selectedLink) {
            const link = await Client.searchVertex(selectedLink.name, {
                Referential: inId,
                in: selectedSource["@rid"],
            });
            if (link && link.data.results && link.data.results.length)
                return (link.data.results as E<rid, rid>[]).map(_link => _link.out);
        }
    }
    return [];
};

export const ClickRefModalityHandler = async (_event) => {
    const { inId, modalitiesViews, outIds, outIdsAuto } = store.getState().linkMgr;
    const d = _event.dataItem;

    if (inId === d["@rid"]) {
        /** Déselection */
        store.dispatch(setState({
            inId: undefined,
            outIds: [],
            outIdsAuto: [],
            topModalitiesViews: undefined,
        }));
    } else {
        /** Sélection */
        let outIds = await getLinkFromIn(d["@rid"]);
        store.dispatch(setState({
            inId: d["@rid"],
            outIds: outIds,
            outIdsAuto: [],
            topModalitiesViews: modalitiesViews?.filter((m) => outIds.includes(m["@rid"])),
        }));
    }

    /** Optimisation, on update la vue de droite seulement si des éléments non liés on été selectionnés pour les
     * faire réapparaître
     */
    if (outIds?.some(id => !isRidLinked(id)) || outIdsAuto?.length)
        UpdateFilteredModalityViews(100);
}

export const getModalities = (modalities: Views[] | undefined): any[] => {
    const { selectedLink } = store.getState().linkMgr;

    if (!modalities) return [];
    if (!selectedLink) return [];

    let propertyOut = "out_" + selectedLink.name;
    modalities?.forEach((m) => ((m as any)["LabelLower"] = m.Label?.toLocaleLowerCase()));

    return modalities.filter((m: any) => m[propertyOut]);
};

const filterView = (modality: Views, searchLower: string) => {
    const { includeLinkedView, includeUnlinkedView, excludeInactiveView, minDateViewUpdate, filterViewUpdate } = store.getState().linkMgr.filtersView;
    // const { outIds } = store.getState().linkMgr;
    const m = modality as any;
    let res =
        rmAccents(m["LabelLower"]?.toLowerCase())?.includes(rmAccents(searchLower));

    const dico = {
        [mm_Advertisers.name]: "MD_parentGroupId",
        [mm_Brands.name]: "MD_advertiserIds",
        [mm_Products.name]: "MD_brandIds",
    };

    if (dico[m["@class"]]) {
        if (m["@class"] === mm_Advertisers.name) {
            res = res || m[dico[m["@class"]]] === searchLower;
        } else {
            res = res || m[dico[m["@class"]]]?.map((id: string | number) => id.toString()).includes(searchLower);
        }
    }
    res = res || m["ExternalID"] == searchLower;

    if (!includeLinkedView) res = res && !isLinked(modality);
    if (!includeUnlinkedView) res = res && isLinked(modality);
    if (excludeInactiveView) res = res && (!modality.hasOwnProperty("MD_status") || modality["MD_status"] == "Active");

    if (filterViewUpdate) res = res &&
        ((!modality.hasOwnProperty("MD_upsertDate") && !modality.hasOwnProperty("TSM_upsertDate"))
            || new Date(modality["MD_upsertDate"]) >= minDateViewUpdate
            || new Date(modality["TSM_upsertDate"]) >= minDateViewUpdate);

    return res;
};

export function GetFilteredModalitiesViews(_modalitiesViews?: Views[]) {
    const { searchView } = store.getState().linkMgr.filtersView;
    const { modalitiesViews } = store.getState().linkMgr;
    const toFilter = _modalitiesViews ?? modalitiesViews;

    const searchLower = searchView.toLowerCase();
    return toFilter?.filter((v) => filterView(v, searchLower));
}

export const UpdateFilteredModalityViews = (delay: number = 1000) => {
    ConsoleDebug(`UpdateFilteredModalityViews, delay ${delay}`);
    store.dispatch(loadingGridView(true))
    const updateCb = () => {
        const filteredModalitiesViews = GetFilteredModalitiesViews();
        store.dispatch(setState({ filteredModalitiesViews }))
        store.dispatch(loadingGridView(false))
    }
    if (delay) {
        clearTimeout(delaySearchModality);
        delaySearchModality = setTimeout(updateCb, delay);
    }
    else {
        updateCb();
    }
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const viewClassname = (d: IRid) => {
    let { outIds, outIdsAuto } = store.getState().linkMgr;
    let base = (d?.["Active"] === false || d?.["MD_status"] == "Inactive") ? "is-inactive " : "";
    if (isLinked(d)) return base + "is-linked";
    if (outIdsAuto.includes(d["@rid"])) return base + "is-linked-auto";
    if (outIds.includes(d["@rid"])) return base + "is-selected";
    return base;
};

const getLinkFromOut = async (outId: string | undefined): Promise<rid> => {
    if (outId) {
        const { selectedLink, selectedSource } = store.getState().linkMgr;
        if (selectedLink) {
            const link = await Client.searchVertex(selectedLink.name, {
                in: selectedSource["@rid"],
                out: outId
            });
            if (link && link.data.results && link.data.results.length)
                return (link.data.results)[0]['Referential'];
        }
    }
    return undefined;
};

const getLinks = async (link?: metaEdge) => {
    const t = StartMeasureTime();
    if (!link) {
        indexerLinks = {};
        indexerLinksOneWay = {};
        indexerAllLinks = {};
    } else {
        delete indexerLinks[link.name];
        delete indexerLinksOneWay[link.name];
        delete indexerAllLinks[link.name];
        Object.keys(indexerLinksBySource).forEach(k => {
            delete indexerLinksBySource[k][link.name];
        });
    }

    const res = await Client.searchVertex(link?.name ?? ReferentialHasViews.name, {
        'in.@class': sources
    });
    if (res.data.results)
        res.data.results.forEach((link: ReferentialHasViews<rid, rid, rid>) => {

            if (link) {
                let classKey: classType | undefined = link["@class"];
                if (classKey) {
                    if (!indexerAllLinksBySource[link.in]) indexerAllLinksBySource[link.in] = {};
                    if (!indexerAllLinksBySource[link.in][classKey]) indexerAllLinksBySource[link.in][classKey] = [];
                    if (!indexerAllLinks[classKey]) indexerAllLinks[classKey] = [];
                    indexerAllLinks[classKey].push(link["@rid"])
                    indexerAllLinksBySource[link.in][classKey].push(link["@rid"])
                }
            }

            if (link && link.Referential && link.Referential !== "") {
                let classKey: classType | undefined = link["@class"];
                if (classKey) {

                    if (!indexerLinksBySource[link.in]) indexerLinksBySource[link.in] = {};
                    if (!indexerLinksBySource[link.in][classKey]) {
                        indexerLinksBySource[link.in][classKey] = { Referentials: [], Views: [] };
                    }
                    if (!indexerLinks[classKey]) indexerLinks[classKey] = {};
                    if (!indexerLinksOneWay[classKey]) indexerLinksOneWay[classKey] = {};
                    if (!indexerLinksBySource[link.in][classKey]["Referentials"].includes(link.Referential))
                        indexerLinksBySource[link.in][classKey]["Referentials"].push(link.Referential);
                    if (!indexerLinksBySource[link.in][classKey]["Views"].includes(link.out))
                        indexerLinksBySource[link.in][classKey]["Views"].push(link.out);
                    indexerLinks[classKey][link.out] = link.Referential;
                    indexerLinks[classKey][link.Referential] = link.out;
                    indexerLinksOneWay[classKey][link.out] = link.Referential;
                }
            }
        });

    // First, get dimensions and links
    const _res = await Client.getDimensionLinkTypes(ReferentialHasViews.name);
    const links = _res.data.results || [];

    PrintMesureTime(t, `getLinks ${link?.name ?? ""}`);
    return links;
}

export const updateLinks = async (link?: metaEdge) => {
    store.dispatch(loadingLinks(true));
    const links = await getLinks(link);
    store.dispatch(setState({ links }));
    store.dispatch(loadingLinks(false));
    if (!link)
        getData();
}

export const updateVertex = async (links: ReferentialHasViews<any, any, any>[]) => {
    const { selectedLink } = store.getState().linkMgr;
    await Client.updateVertex(selectedLink.name, links)
    await updateLinks(selectedLink)
    // store.dispatch(setState({ outIds: [], outIdsAuto: [], inId: undefined, topModalitiesViews: undefined }))
}

export const divModalityUnlinkHandler = (_rid: ViewsId) => {
    const { selectedLink, selectedSource } = store.getState().linkMgr;

    Client.searchVertex(selectedLink.name, { in: selectedSource["@rid"], out: _rid }).then((res) => {
        let links: ReferentialHasViews<any, any, any>[] = res.data.results;

        links.forEach((link) => {
            link.Referential = null;
        });

        if (window.confirm(`Le lien ${_rid} va etre supprimé : ${JSON.stringify(links)}`)) {
            updateVertex(links);
        }
    });
};

export const ClickModalityHandler = async (_event, updatefilters: boolean = false) => {
    const { modalitiesViews, outIds, outIdsAuto, inId } = store.getState().linkMgr;

    let res = false;

    const view: Views = _event.dataItem;

    if (!isLinked(view, "Views")) {
        const newOutIds = [...outIds];
        const indexOf = newOutIds.indexOf(_event.dataItem["@rid"]);
        if (indexOf >= 0) {
            newOutIds.splice(indexOf, 1);
        } else {
            newOutIds.push(_event.dataItem["@rid"]);
        }
        const newOutIdsAuto = [...outIdsAuto];
        const indexOfAuto = newOutIdsAuto.indexOf(_event.dataItem["@rid"]);
        if (indexOfAuto >= 0) {
            newOutIdsAuto.splice(indexOfAuto, 1);
        }
        store.dispatch(setState({
            outIds: newOutIds,
            outIdsAuto: newOutIdsAuto,
            topModalitiesViews: modalitiesViews?.filter((m) => newOutIds.includes(m["@rid"])),
        }))
        res = true;
    } else {
        if (inId)
            if (!window.confirm("Les modifications non enregistrées vont être perdues")) {
                return;
            }
        const newRid = await getLinkFromOut(_event.dataItem["@rid"]);
        const outIds = await getLinkFromIn(newRid);
        store.dispatch(setState({
            inId: newRid,
            outIds: outIds,
            topModalitiesViews: modalitiesViews?.filter((m) => outIds.includes(m["@rid"])),
        }))
    }

    // cet update ralentit la grille
    if (updatefilters)
        UpdateFilteredModalityViews();
    return res;
};

export async function updateReferential(selectedDimension: string) {

    store.dispatch(loadingGridRef(true));
    const { selectedProptype } = store.getState().linkMgr;

    const properties = ["*", "out('lnk_Hierarchy') as parentRid"];

    if ([ref_Supports.name, ref_Property.name].includes(selectedDimension)) {
        properties.push("first(Medias.Name) as Media");
    }
    if (selectedDimension == ref_Supports.name) {
        //properties.push("out(\"lnk_AdvertisingCompanySupport\").Name as Suppliers");
        properties.push("out(\"lnk_AdvertisingCompanySupport\") as Suppliers");
    }

    let params = { Active: [true, false], properties: properties };
    //La dimension ref_Companies concerne uniquement les agences et les groupes d'agences
    if (selectedDimension == ref_Companies.name)
        params = { ...params, ...{ "@class": [ref_Agencies.name, ref_AgencyGroups.name] } };
    const moda = await Client.searchVertex(selectedDimension, params as any)
    let modalities: Referentials[] = moda.data.results as Referentials[];
    modalities.forEach((m: any) => {
        // Hack pour User
        let mAny: any = m;
        if (!m.Name && mAny.name) m.Name = mAny.name;
    });

    if (modalities) {
        if (selectedDimension == ref_Property.name) {
            modalities = modalities.filter((m) => {
                let c: ref_Property = m as ref_Property;
                return c.PropertyType.includes(selectedProptype?.["@rid"]);
            });
        }

        modalities = [...modalities].sort((a: any, b: any) => a.Name.localeCompare(b.Name));


        store.dispatch(loadingGridRef(false));
        store.dispatch(setState({
            modalities,
            outIds: [],
            inId: undefined,
            topModalitiesViews: undefined,
            loading: false
        }))
    }
}

export async function selectLink(selectedLink: metaEdge) {
    let metadata = getProp(selectedLink, "out");
    if (metadata) {
        // this.setState({ loading: true, error: false });
        store.dispatch(loadingGridView(true));
        const { selectedSource, modalities, inId } = store.getState().linkMgr;

        const timeTotal = StartMeasureTime();
        const timeSearchVertex = StartMeasureTime();

        const moda = await Client.searchVertex(selectedLink.name, {
            in: selectedSource["@rid"],
            properties: ["expand(out)"],
        });

        PrintMesureTime(timeSearchVertex, `SearchVertex ${selectedLink.name} (${moda?.data?.results?.length})`);

        const views: Views[] = moda.data.results as Views[];
        const modalitiesViews = getModalities(views);
        modalitiesViews.sort((a, b) => {
            if (!a.Label) return -1;
            return a.Label.localeCompare(b.Label);
        });

        store.dispatch(loadingGridView(false));

        const timeFilter = StartMeasureTime();
        const filteredModalitiesViews = GetFilteredModalitiesViews(modalitiesViews)
        PrintMesureTime(timeFilter, "GetFilteredModalitiesViews");
        PrintMesureTime(timeTotal, "SelectLink");

        store.dispatch(setState({
            modalitiesViews,
            outIds: [],
            inId: undefined,
            topModalitiesViews: undefined,
            filteredModalitiesViews,
            loading: false,
        }));
    }
}

export const getData = async () => {

    const { selectedDimension, selectedLink } = store.getState().linkMgr;

    ConsoleDebug(`linkmgr getData`);

    if (selectedDimension) {

        await updateReferential(selectedDimension);

        if (selectedLink) {
            await selectLink(selectedLink);
        } else {
            store.dispatch(setState({
                modalitiesViews: [],
                outIds: [],
                inId: undefined,
                topModalitiesViews: undefined,
                filteredModalitiesViews: [],
                loading: false,
            }));
        }
    }
}