import React from 'react'
import Template from '../Template'
import { LogsGrid } from '../../VertexGrid/Adwone-admin/Logs/LogsGrid.bin';
import { PopupSettingsLogs } from './PopupSettingsLogs.bin';
import { addDays } from 'date-fns';

class Logs extends React.Component<any, any> {
    constructor(props: any){
        super(props)
        let storage = localStorage.getItem('configLogsGrid')
        this.state = {
            config: storage ? JSON.parse(storage) : undefined
          }
    }
    onValidate = (user: string, layer:string, Start: any) => {
        this.setState({
          config: {
            User :user,
            Layer :layer,
            Start: Start,
            End: addDays(Start, 1)
          }
        }, () => {
          localStorage.setItem('configLogsGrid', JSON.stringify(this.state.config))
        })
      }
    
    onConfChange = (config: any) => {
        localStorage.setItem('configLogsGrid', JSON.stringify(config))
    }
    render(){
        return (
            <>
                <div className="grid_container">
                {!this.state.config && <PopupSettingsLogs onValidate={this.onValidate} />}
                {this.state.config && <LogsGrid config={this.state.config} onConfChange={this.onConfChange} />}
                </div>
            </>
        );
    }
}

export default Template(Logs);