import * as React from 'react'

import { CrossedTableConfig } from './CrossedTableConf.bin';
import { eKPIType } from 'hub-lib/models/KPIsManager.bin';
import { Column, Row, Table } from 'adwone-engine/types.bin';
import Loader from '../layout/Loader';
import { ref_Currencies } from 'hub-lib/models/orientdb/ref_Currencies.bin';
import { Indicateur, IsIndicateurReturned } from 'adwone-engine/index.bin';
import { ADWProperty } from 'hub-lib/types';
import { Trad } from 'trad-lib';
import { SortDescriptor } from '@progress/kendo-data-query';

export class TProps<TCol, TData> {
    onRef?: (ct: ACrossedTable<any, TCol, TData>) => void;
}
let timeoutColumns;
export class CrossedTableState<TCol, TData> {

    rows: Row<TData>[] = [];
    cols: Column[] = [];

    crossedTable: CrossedTableConfig;
    columns: TCol[] = [];
    version: number = 0;

    isSearchPanelVisible: boolean = false;

    loading: boolean = false;

    sort: SortDescriptor[] = [];
}

export let customWidths: { [key: string]: string | number };
try {
    const customWidthsStorage = localStorage.getItem("customWidth_tab")
    customWidths = customWidthsStorage ? JSON.parse(customWidthsStorage) : {};
} catch (error) {
    customWidths = {};
}

export abstract class ACrossedTable<TP extends TProps<TCol, TData>, TCol, TData> extends React.Component<TP, CrossedTableState<TCol, TData>> {

    abstract createColumnParent: (id: any, header: string, children: JSX.Element[]) => any;
    abstract createColumnIndicateur: (indc: Indicateur, nbVentils: number, index: number, calculateCellValue: (row: Row<TData>) => { Formated: string, Value: any }, type?: eKPIType) => TCol;
    abstract createTreeList: (rows: Row<TData>[], columns: TCol[], rowventils: (ADWProperty | Indicateur)[]) => JSX.Element;
    abstract createPivotColumnIndicateur: (column: Column, currentIndicateurPath: string, isGrandTotalCol: boolean) => TCol;

    constructor(props: TP) {
        super(props);
        let newState = new CrossedTableState<TCol, TData>();
        this.state = newState;
    }

    componentDidMount() {
        this.props?.onRef?.(this);
    }

    shouldComponentUpdate(prev: TProps<TCol, TData>, next: CrossedTableState<TCol, TData>) {
        if (next?.loading != this.state?.loading) return true;
        if (next?.isSearchPanelVisible != this.state?.isSearchPanelVisible) return true;
        if (next?.version != this.state?.version) return true;
        return false;
    }

    onResizeColumns = (columns: { width?: number | string, field?: string }[]) => {
        columns.forEach(c => {
            if (c?.field && customWidths)
                customWidths[c.field] = c.width;
        });
        localStorage.setItem("customWidth_tab", JSON.stringify(customWidths));
        this.forceUpdate();
        clearTimeout(timeoutColumns);
        timeoutColumns = setTimeout(() => {
            this.RegenerateColumns();
        }, 1000);
    }

    beforeLoading = (data: Table<TData>) => {
        // nothing
    }

    Loading = () => {
        this.setState({ loading: true })
    }

    Load = (table: Table<TData>, crossedTable: CrossedTableConfig, pivotCols?: Column[]) =>
        new Promise((res, rej) => {
            try {
                if (crossedTable.End.getTime() <= crossedTable.Start.getTime())
                    crossedTable.End = new Date(crossedTable.Start);

                this.beforeLoading(table);
                this.setState({
                    crossedTable,
                },
                    () => this.setState({
                        loading: false,
                        rows: table?.Rows ?? [],
                        cols: table?.Columns ?? [],
                        columns: pivotCols ? this.GeneratePivotKPIColumns(pivotCols) : this.GenerateKPIColumns(),
                        version: this.state.version + 1
                    }, () => res(null)));
            } catch (error) {
                rej(error);
            }
        })

    RegenerateColumns = () =>
        this.setState({
            columns: this.GenerateKPIColumns(),
            version: this.state.version + 1
        })

    customizeHeaderFilters = () => {

        let nbventils = 0;
        if (this.props?.['selectable']) nbventils++;
        nbventils += this.state?.crossedTable?.rowventils?.length ?? 0;

        let rows = Array.from(document.getElementsByClassName("k-filter-row"))
        let row = rows?.[0];
        if (!row) return;

        let sum = 0;
        Array.from(row.children)
            .forEach((e: any, i: number) => {

                if (i >= (nbventils + this.state.crossedTable?.selectedIndicateurs?.length ?? 0))
                    return;

                if (!(i >= nbventils)) {
                    e.style.zIndex = 5;
                    e.style.left = `${sum}px`;
                } else {
                    e.style.zIndex = 4;
                }

                sum += e.offsetWidth;
            });
    }

    GeneratePivotKPIColumns = (pivotColumns: Column[]): TCol[] => {
        let colsToProcess = pivotColumns.filter(c => !c.IsRow);

        let groupedColsTitle = "";

        this.state.crossedTable.colventils.forEach((ventil, i) => {
            groupedColsTitle += ventil.name;

            if (i < this.state.crossedTable.colventils.length - 1)
                groupedColsTitle += " > ";
        });

        // suppression des dernieres colonnes de totaux
        const nbIndicateurs = this.state.crossedTable.selectedIndicateurs.length;
        const lastCols = colsToProcess.slice(colsToProcess.length - nbIndicateurs);
        colsToProcess = colsToProcess.slice(0, colsToProcess.length - nbIndicateurs);

        // const totalKpisCols = this.GenerateKPIColumns();
        // voir comment gérer le frozen de droite
        // totalKpisCols.forEach(c => c['frozen'] = true);
        const grandTotalBaseColumn: Column = {
            Indicateur: null,
            Cell: { Value: Trad("GRAND TOTAL"), Formated: Trad("GRAND TOTAL"), Type: "header" },
            IsRow: false,
            Children: lastCols
        }

        const allColumns = [this.createPivotColumnIndicateur(grandTotalBaseColumn, "", true)];

        if (colsToProcess?.length > 0) {
            const baseColumn: Column = {
                Indicateur: null,
                // Label: groupedColsTitle,
                Cell: { Value: groupedColsTitle, Formated: groupedColsTitle, Type: "header" },
                IsRow: false,
                Children: colsToProcess
            }

            const ret = this.createPivotColumnIndicateur(baseColumn, "", false);
            allColumns.unshift(ret);
        }

        return allColumns;
    }

    GenerateKPIColumns = (): TCol[] => {
        let res: TCol[] = [];
        const nbVentils = this.state.crossedTable?.rowventils?.length;
        this.state.crossedTable?.selectedIndicateurs?.forEach((indc, i) => {
            const calculateCellValue = (data: Row<TData>) => {
                const cell = data.ValuesTotal?.[i];
                return { Formated: cell?.Formated ?? "", Value: cell?.Value };
            };
            const col = this.createColumnIndicateur(indc, nbVentils, i, calculateCellValue, indc.valueType);
            res.push(col);
        });

        return res;
    }

    render() {

        let { rows, crossedTable: config, columns, loading } = this.state;
        let rowventils = config?.rowventils;

        if (config?.selectedIndicateurs?.find(ind => IsIndicateurReturned(ind))) {
            /** Then force ventil to returned currency */
            if (rowventils && !rowventils.some(r => r.field === "ReturnedCurrency"))
                rowventils = [{
                    field: "ReturnedCurrency",
                    type: "@rid",
                    linkedClass: ref_Currencies.name
                }, ...rowventils]
        }

        if (!rowventils?.length) {
            rows = rows?.[0]?.Children ?? [];
        }

        return (<>
            {loading && <Loader />}
            {this.createTreeList(rows, columns, rowventils)}
        </>);
    }
}




