import { GET_SUPPORT_DETAILS, SUPPORT_DETAILS_LOADING, GET_SUPPORT_ISSUES, GET_SUPPORT_OFFERS} from '../actions/types'

const initialState = {
    details:{},
    offers:[],
    issues:[],
    loading:true
}

export default function(state=initialState, action){
    switch(action.type){
        case GET_SUPPORT_ISSUES:
            return {
                ...state,
                issues: action.payload
            }
        case GET_SUPPORT_OFFERS:
            return {
                ...state,
                offers: action.payload
            }
        case GET_SUPPORT_DETAILS:
            return {
                ...state,
                details: action.payload,
                loading:false
            }
        case SUPPORT_DETAILS_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}