import * as React from 'react';
import { UrlParser } from 'tools-lib';
import history from "../../utils/history";
import { Client } from 'hub-lib/client/client.bin';

export function TokenComponent(props) {
    React.useEffect(() => {
        Promise.resolve().then(async () => {
            const param = UrlParser("code");
            if (param) {
                const url = decodeURIComponent(props.match.params.url);
                const api = decodeURIComponent(props.match.params.api);
                await Client.saveToken({ code: param, api });
                history.replace(`/${url}`);
            } else {
                throw new Error("noAuth");
            }
        })
    });
    return <></>;
}