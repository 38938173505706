
import { configureStore } from '@reduxjs/toolkit'
import { SupportStoreReducer } from '../SupportHierarchyComponent/SupportStoreSlice';
import { AdvertiserStoreReducer } from './AdvertiserStoreSlice';

export const StoreFiltersMessages = configureStore({
  reducer: {
    advertiserStorage: AdvertiserStoreReducer,
    supportStorage: SupportStoreReducer
  },
  devTools: {
    name: "FiltersMessages"
  }
})


export const StoreCreationCampaigns = configureStore({
  reducer: {
    advertiserStorage: AdvertiserStoreReducer
  },
  devTools: {
    name: "FiltersCreationCampaigns"
  }
})

// export const StoreFiltersCampaigns = configureStore({
//   reducer: {
//     advertiserStorage: AdvertiserStoreReducer
//   },
//   devTools: {
//     name: "FiltersCampaigns"
//   }
// })

export const StoreFiltersCampaignsSearch = configureStore({
  reducer: {
    advertiserStorage: AdvertiserStoreReducer
  },
  devTools: {
    name: "FiltersCampaignsSearch"
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStateFilters = ReturnType<typeof StoreFiltersMessages.getState>

