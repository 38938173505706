import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/types'

const initialState = {
    token: localStorage.getItem('token'),
    isLoading: false,
    loading: true,
    redirect: false
}


export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', 'connected')
            localStorage.setItem('user', JSON.stringify(action.payload))
            return {
                ...state,
                isLoading: false,
            }
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}