import { kpis } from "../../types";
import { ref_ExternalData } from "./ref_ExternalData";

export class ref_Circulations extends ref_ExternalData {
    KPIs?: kpis & {
        TotalCirculation?: number;
        Release?: number;
        PaidCirculation?: number;
        Subscriptions?: number;
    };
}