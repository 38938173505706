import React from "react";
import { Client } from "hub-lib/client/client.bin";

export class AlertComponent extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    async componentDidMount() {
        const alertStr = (await Client.getAlert()).data;
        if (alertStr && alertStr != "")
            this.setState({
                alertStr
            })
    }

    render() {
        const { alertStr } = this.state;
        if (!alertStr) return <></>;
        return <div className='alert-div' style={{
            zIndex: 100000,
            position: 'absolute',
            top: 40,
            left: '50%',
            transform: 'translate(-50%, -50%)',

        }}>{alertStr}</div>;
    }
}