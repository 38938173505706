import * as React from 'react'
import { connect } from 'react-redux';
import { Trad } from 'trad-lib';
import { CustomProgressBar } from '../../ConfigurableComponents/CustomProgressBar';
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin';
import { GenericTooltip } from '../../ConfigurableComponents/GenericTooltip.bin';

class TProps{
    open: boolean
    closeDialog: () => void
}
class ImportsDialog extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }
    render(){
        const {imports} = this.props
        return(
            <>{imports && 
            <GenericDialog
                open={this.props.open}
                id="custom_import_view_messages_dialog"
                dialogTitle={Trad('imports_in_progress')}
                disableCancel
                submitAction={this.props.closeDialog}
                submitTitle={Trad("close")}
                submitClass="custom_btn_secondary"
                dialogContent={<>{imports.length ? imports.map((i, index)=>{
                    return(<div style={{marginBottom: 15}} key={`progress_${index}`}>
                        <GenericTooltip tooltipContent={`${i.Report.Current}/${i.Report.Total}`}><CustomProgressBar maximum={i.Report.Total} current={i.Report.Current}/></GenericTooltip>
                        <div>{`${Trad("campaigns")}: ${i.Report.ref_Campaigns} / ${Trad("ref_Messages")}: ${i.Report.ref_Messages}`}</div>
                        </div>)
                }) : <p>{Trad("no_imports_in_progress")}</p>}</>}
                actions
            />}</>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    imports: state.imports.list,
  })

export default connect(mapStateToProps)(ImportsDialog)