import { ADWColumn } from "adwone-lib";
import { ref_Circulations } from "hub-lib/dto/client/ref_Circulations";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import * as React from "react";
import { TradProp } from "trad-lib";
import DefaultGrid from "../../VertexGrid/DefaultGrid.bin";
import { VertexGrid } from "../../VertexGrid/Generic/VertexGrid.bin";
import Template from "../Template";
import { ExternalDataFilters } from "./OffersGrid";

export function CirculationsGridComponent() {
    const [grid, setGrid] = React.useState(null);

    React.useEffect(() => {
        if (!grid) {

            const IdColumns: ADWColumn<ref_Circulations>[] = [
                new ADWColumn("SupportId", "Support", ePropType.String),
                new ADWColumn("BroadcastAreaId", "BroadcastArea", ePropType.String),
                // new ADWColumn("MediaId", "Media", ePropType.String),
                // new ADWColumn("CurrencyId", "Currency", ePropType.String)
            ];

            IdColumns.forEach(c => c.width = 110);

            const columns: ADWColumn<ref_Circulations>[] = [
                new ADWColumn(TradProp("Source"), "Source", ePropType.String),
                new ADWColumn(TradProp("KPIs.TotalCirculation"), "KPIs.TotalCirculation", ePropType.Integer),
                new ADWColumn(TradProp("KPIs.Release"), "KPIs.Release", ePropType.Integer),
                new ADWColumn(TradProp("KPIs.PaidCirculation"), "KPIs.PaidCirculation", ePropType.Integer),
                new ADWColumn(TradProp("KPIs.Subscriptions"), "KPIs.Subscriptions", ePropType.Integer),
                new ADWColumn(TradProp("Updated_at"), "Updated_at", ePropType.Datetime),
                new ADWColumn(TradProp("Created_at"), "Created_at", ePropType.Datetime),
                ...IdColumns
            ];

            // KPIs?: kpis & {
            //     TotalCirculation?: number;
            //     Release?: number;
            //     PaidCirculation?: number;
            //     Subscriptions?: number;
            // };

            const hiddenProperties: (keyof ref_Circulations)[] = ["DataImported", "Key", "Model", "Name"] as any;
            const grid = new VertexGrid<ref_Circulations>({
                disableStore: true,
                objectPrototype: ref_Circulations,
                order: ["Source", "Start", "End", "Support", "BroadcastArea", "KPIs.TotalCirculation", "KPIs.Release", "KPIs.PaidCirculation", "KPIs.Subscriptions", "Updated_at", "Created_at"],
                devMode: false,
                columns,
                vertexParams: {
                    All: true,
                    Active: true,
                    properties: ["*"],
                    Start: undefined,
                    End: undefined
                },
                width: {
                    Source: 120,
                    Start: 110,
                    End: 110
                },
                hiddenProperties,
            });
            setGrid(grid);
        }
    });
    return <DefaultGrid
        objectPrototype={ref_Circulations}
        grid={grid}
        configurationPanelElements={ExternalDataFilters}
        filtersHideOptions={{ Date: true }}
        generateConfig={(params) => {
            return {
                ...params,
                Start: undefined,
                End: undefined
            }
        }}
        gridProps={{
            selectable: false,
            onEdit: () => { },
            onDuplicate: () => { },
            uneditable: true
        }} />
}

export const CirculationsGrid = Template(CirculationsGridComponent);