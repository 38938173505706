
import * as React from 'react'
import { AutocompleteProps, Autocomplete } from '@material-ui/lab';
import { Trad } from 'trad-lib';
import { Chip, TextField } from '@material-ui/core';
import { getIcon } from './IconManager';

export function AdwAutocomplete<T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined>(props: Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>>) {
    return (<Autocomplete
        noOptionsText={Trad("no_options")}
        options={[]}
        renderInput={(params: any) => <TextField {...params} variant="outlined" />}
        renderTags={(value, getTagProps) =>
            <div>
                {value.map((option, index) => {
                    let label: React.ReactNode = null;
                    if (props['getOptionLabelComponent'])
                        label = props['getOptionLabelComponent'](option);
                    else
                        label = props.getOptionLabel?.(option) ?? option?.toString?.();

                    return <Chip
                        variant="outlined"
                        label={label}
                        {...getTagProps({ index })}
                        className={`chip-element ${option?.['className'] ?? ''}`}
                        deleteIcon={getIcon('closeCircleOutline', {}, "remove-icon")}
                    />
                })}
            </div>}
        {...props}
    />);
}