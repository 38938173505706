import { useState } from "react";
import { getIcon } from "./ConfigurableComponents/IconManager.bin";

export function BubbleDialog({ children }) {

    const [open, setOpen] = useState(false)

    return <div className="bubble-dialog" onClick={e => {
        if (!open)
            setOpen(true)
    }}>
        {getIcon("dialog")}
        {
            <div className={`bubble-dialog-content ${open ? 'bubble-visible' : 'bubble-hidden'}`}>
                {children}
                <div className="bubble-dialog-close" onClick={e => setOpen(false)}>
                    {getIcon("close")}
                </div>
            </div>
        }
    </div>
}