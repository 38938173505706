import React from 'react';

import { IntervalsDesactivated } from '../../../Agreements/AgreementDialog';
import { RowSize } from '../../../../../styles/theme';
export let removeCol = 27;
export let dragCol = 60;
export let colWidth = 120;
export let lastCol = 50;
export let scrollSize = 0;
export let colNumber = IntervalsDesactivated ? 3 : 4;
//export let firstCol = `calc(100% - ${(colWidth * colNumber) + removeCol * 2}px)`;
//export let firsColwithRank = `calc(100% - ${(colWidth * (colNumber + 1)) + removeCol * 2}px)`

const paddingRight = 12;
export const boderRow: React.CSSProperties['border'] = 'solid 1px rgba(0, 0, 0, 0.12)';
const styles = { paddingLeft: 10, paddingRight, Height: RowSize };
const stylesCellNumber: React.CSSProperties = {
    ...styles,
    height: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center'
};

export class DiscountGridRowProps {

    className?: string = null;
    intervalCellClassName?: string = null;
    amountCellClassName?: string = null;

    firstCell?: string | JSX.Element = null;
    labelCell?: string | JSX.Element = '-';
    intervalCell?: string | JSX.Element = '-';
    rateCell?: string | JSX.Element = '-';
    amountCell?: string | JSX.Element = '-';

    commandCell?: string | JSX.Element = null;
    rowStyle?: React.CSSProperties = {};
}

export function DiscountGridRow(props: DiscountGridRowProps) {
    const { labelCell, intervalCell, rateCell, amountCell, commandCell, rowStyle, firstCell, className, intervalCellClassName, amountCellClassName } = { ...new DiscountGridRowProps, ...props };
    return <div className={className ?? ''} style={{ display: 'flex', borderBottom: boderRow, ...(rowStyle ?? {}) }}>

        {/** DragDrop or Rank */}
        {firstCell
            && <div style={{ width: dragCol, ...styles }}>
                {firstCell}
            </div>}

        {/** Label colonne */}
        <div style={{ ...styles, flex: 1 }}>
            {labelCell}
        </div>

        {/** Intervals */}
        {/*!hideIntervalCells &&*/<div className={intervalCellClassName ?? 'gray-back'} style={{ width: colWidth, textAlign: 'right', height: '100%' }}>
            <div style={stylesCellNumber}>
                {intervalCell}
            </div>
        </div>}

        {/** Taux */}
        <div style={{ width: colWidth, textAlign: 'right', ...stylesCellNumber }}>
            {rateCell}
        </div>

        {/** Montant */}
        {<div className={amountCellClassName ?? 'gray-back'} style={{ width: colWidth, textAlign: 'right', height: '100%' }}>
            <div style={stylesCellNumber}>
                {amountCell}
            </div>
        </div>}

        {/** Decorator */}
        {<div style={{ width: lastCol + scrollSize, textAlign: 'right' }}>
            {commandCell}
        </div>}
    </div>
}