import * as React from 'react'
import { CustomButton } from '../../ConfigurableComponents/CustomButton.bin'
import { GenericDialog } from '../../ConfigurableComponents/GenericDialog.bin'
import { getIcon } from "adwone-lib/index"
import { ref_AdvertiserGroups } from 'hub-lib/models/orientdb/ref_AdvertiserGroups.bin'
import { ref_AdvertisingCompanyGroups } from 'hub-lib/models/orientdb/ref_AdvertisingCompanyGroups.bin'
import { ref_AgencyGroups } from 'hub-lib/models/orientdb/ref_AgencyGroups.bin'
import { NewClientForm } from './NewClientForm.bin'
import { ref_Companies } from 'hub-lib/models/orientdb/ref_Companies.bin'
import { Client } from 'hub-lib/client/client.bin'
import { Trad } from 'trad-lib'
import { CompanyTypes } from './CustomerSettingForm.bin'
import { Referentials } from 'hub-lib/models/orientdb/Referentials.bin'

class TProps {
    open: boolean
    closeDialog: () => void
    company: ref_Companies
    CloseNewclient: () => void
    changeCompany: (companyId: string) => void
}

class TState {
    companies: ref_Companies[]
    error_msg: string = ''
    showError: boolean = false
    loading: boolean = true
}

export let CompanyGroups = [ref_AdvertiserGroups.name, ref_AdvertisingCompanyGroups.name, ref_AgencyGroups.name]

export class NewClient extends React.Component<TProps, TState>{
    constructor(props: TProps) {
        super(props)
        let newState = new TState()

        props.company.Active = true;
        newState.companies = [props.company]
        this.state = newState
    }

    addCompany = () => {
        let companyType = CompanyTypes.find(t => t.name == this.props.company.constructor.name);
        let childType = CompanyTypes[CompanyTypes.indexOf(companyType) + 1];
        let child = new childType()
        child.Active = true;
        this.setState({
            companies: [...this.state.companies, child]
        })
    }
    deleteChild = (r: Referentials) => {
        this.setState({
            companies: this.state.companies.filter(e => e !== r)
        })
    }
    createCompany = () => {
        let companies = this.state.companies
        let parent: any = companies[0]

        if (CompanyGroups.includes(this.props.company.constructor.name)) {
            parent.children = companies.filter(e => companies.indexOf(e) > 0)
        }
        parent.Start = new Date();
        Client.createVertex(this.props.company.constructor.name, parent).then(res => {
            this.props.changeCompany(res.data.results["@rid"])
            this.props.CloseNewclient()
        }).catch(e => console.error(e));
    }

    render() {
        return (
            <>
                <GenericDialog
                    id="new_client_dialog"
                    open={this.props.open}
                    dialogTitle={Trad("company_creation")}
                    submitAction={() => { this.createCompany() }}
                    actions
                    cancelAction={() => { this.props.closeDialog() }}
                    submitTitle={CompanyGroups.includes(this.props.company.constructor.name) ? Trad("create_group") : Trad("create_company")}
                    additionalButtons={
                        CompanyGroups.includes(this.props.company.constructor.name) ?
                            <CustomButton
                                className="custom_btn_nostyle_primary"
                                style={{ position: 'absolute', left: 23, paddingLeft: 0, float: "left" }}
                                Label={Trad("add_sub_company")}
                                onClick={() => { this.addCompany() }}
                                startIcon={getIcon("plus_rounded")}
                            /> : undefined
                    }
                    dialogContent={
                        <>
                            {this.state.companies.map((s, i) => <NewClientForm key={i} referential={s} child={i != 0} deleteChild={this.deleteChild} />)}
                        </>}
                />
            </>
        )
    }
}