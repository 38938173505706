import { IRid } from "../../models/IRid.bin";
import { rid } from "../../models/orientdb/CommonTypes.bin";
import { UserId } from "../../models/orientdb/User.bin";
import { INamed } from "../../types";

export class ref_ConfigurationsBase implements IRid, INamed {
	"@rid"?: rid;
	DocumentType?: string;
	Active: boolean;
	Default?: boolean;
	Name: string;
	Owner: UserId;
	Table: string;
}