import { ref_BroadcastAreas, ref_BroadcastAreasId } from "./ref_BroadcastAreas.bin";
import { ref_AdvertisingCompanyRole, ref_AdvertisingCompanyRoleId } from "./ref_AdvertisingCompanyRole.bin";
import { ref_AdvertisingCompanies, ref_AdvertisingCompaniesId } from "./ref_AdvertisingCompanies.bin";
import { ref_Supports, ref_SupportsId } from "./ref_Supports.bin";
import { E, EId } from "./E.bin";
import { rid } from "./CommonTypes.bin";

export type lnk_AdvertisingCompanySupportId = rid;

export class lnk_AdvertisingCompanySupport extends E<ref_AdvertisingCompaniesId, ref_SupportsId> {
	"@rid"?: lnk_AdvertisingCompanySupportId;
	Active: boolean;
	Default: boolean;
	DefaultBroadcastArea: ref_BroadcastAreasId;
	End: Date;
	Roles: ref_AdvertisingCompanyRoleId[];
	Start: Date;

}