import * as React from "react";
import { Trad } from "trad-lib";
import { GenericDialog } from "../../ConfigurableComponents/GenericDialog.bin";
import { CalculBase } from "./CalculBase.bin";
import { Base, ref_Discount, ref_Intervals } from "hub-lib/models/types/vertex.bin";
import { Grid } from "@material-ui/core";
import { AdwAutocomplete } from "adwone-lib/index";
import { CustomButton } from "../../ConfigurableComponents/CustomButton.bin";
import { DiscountCategoryArray } from "../Messages/DiscountCategoryArray.bin";
import { Client } from "hub-lib/client/client.bin";
import { ref_DiscountClasses } from "hub-lib/models/orientdb/ref_DiscountClasses.bin";
import { ref_DiscountTypes } from "hub-lib/models/orientdb/ref_DiscountTypes.bin";
import { IsDebugMode } from "../../../utils/localstorage.bin";
import { extract } from "hub-lib/tools.bin";
import { checkMaximum, checkValueRate, checkMinimum } from "../Messages/discounts/validators.bin";
import { ref_Agreements } from "hub-lib/dto/client/ref_Agreements.bin";
import { getIcon } from "adwone-lib/index";
import { DiscountManager, netType } from "hub-lib/business/DiscountManager.bin";
import { Notify } from "../../../utils/Notify.bin";

export let numberCols = 120;
export let lastCol = 38;
export let scrollSize = 0;
export class CustomInterval extends ref_Discount {
  Minimum: number;
  Maximum: number;

  static toInterval(c: CustomInterval): ref_Intervals {
    return extract<ref_Intervals, CustomInterval>({
      "Maximum": "Maximum",
      "Minimum": "Minimum",
      "IsRate": "IsRate",
      "Rate": "Rate",
      "Value": "Value",
      "CO": "CO",
      "FO": "FO",
      "FOS": "FOS",
      "@rid": "@rid"
    }, c);
  }
}

class TProps {
  agreement: ref_Agreements;
  open: boolean;
  cofo: "CO" | "FO" | "FOS";
  discount: ref_Discount;
  calculBase?: boolean = false;
  cancelAction: () => void;
  submitAction: (discount: ref_Discount) => void;
  currencyCode: string;
}

class TState {
  discount: ref_Discount;
  intervals: CustomInterval[] = [];
  cofo: "CO" | "FO" | "FOS" = "CO";
  discountClasses: ref_DiscountClasses[];
  discountTypes: ref_DiscountTypes[];
  buttonDisabled: boolean = false;
  key: number = 0;
}
export class IntervalsDialog extends React.Component<TProps, TState> {

  discountTab: DiscountCategoryArray;

  constructor(props: TProps) {
    super(props);

    if (IsDebugMode())
      console.log(`IntervalsDialog ctor`, props.discount);

    let newState = new TState();
    newState.cofo = this.props.cofo;
    newState.discount = this.props.discount
    newState.discount.Base = newState.discount.Base ?? []
    newState.discount.Intervals = newState.discount.Intervals ?? []
    newState.intervals = [];
    this.state = newState;
  }

  async componentDidMount() {
    const discountClasses = await DiscountManager.GetDiscountClasses();
    const discountTypes = await DiscountManager.GetDiscountTypes();
    this.setState({
      discountClasses,
      discountTypes,
      intervals: this.formatIntervals(this.state.discount.Intervals)
    });
  }

  formatIntervals: (i: ref_Intervals[]) => CustomInterval[] = (intervals: ref_Intervals[]) => {
    const { discount } = this.state;
    return intervals?.map(e => {
      return {
        ...new ref_Discount,
        DiscountClass: discount.DiscountClass,
        DiscountType: discount.DiscountType,
        ...e
      };
    }) ?? []
  };


  UpdateIntervals = () => {
    const { discount } = this.state;
    const intervals = this.discountTab?.getCustomDiscount() ?? [];

    if (IsDebugMode())
      console.log(`getCustomDiscount from discountTab`, intervals);

    discount.Intervals = intervals.map(CustomInterval.toInterval);

    if (IsDebugMode())
      console.log(`new intervals`, discount.Intervals);

    this.setState({ intervals: this.formatIntervals(discount.Intervals) })
  }
  disableButton = () => {
    this.setState({
      buttonDisabled: !this.state.buttonDisabled
    })
  }
  render() {
    const { agreement } = this.props;
    const { discount, cofo, discountClasses, discountTypes, intervals } = this.state;
    return (
      <GenericDialog
        actions
        open={this.props.open}
        dialogTitle={Trad("volume_settings")}
        // submitClass={"custom_btn_primary"}
        submitTitle={Trad("valid")}
        disablePrimaryButton={this.state.buttonDisabled}
        cancelAction={this.props.cancelAction}
        submitAction={() => {
          if (!this.state.discount.Base.length) {
            Notify(Trad("missing_base"), "error");
          } else if (this.state.discount.Base.find((b => !b.Type["@class"] || b.Type["@class"] === undefined))) {
            Notify(Trad("invalid_base"), "error");
          } else {
            this.props.submitAction(this.state.discount)
          }
        }}
        dialogContent={
          <>
            {/** ASSIETTE */}
            {this.props.calculBase &&
              <Grid container style={{ marginBottom: 10 }}>
                <CalculBase
                  agreement={agreement}
                  title={Trad("assiette_remises")}
                  disableButtons={() => this.disableButton()}
                  bases={discount.Base}
                  onNewBase={(base: Base) => {
                    discount.Base.push(base)
                    this.forceUpdate()
                  }}
                  OnDeleteBase={(base: Base) => {
                    discount.Base = discount.Base.filter(e => e != base)
                    this.forceUpdate()
                  }}
                  onChangeElement={(base: Base, index: number) => {
                    discount.Base[index] = base
                    this.forceUpdate()
                  }}
                />
              </Grid>}

            <Grid key={`discount-editor-agreement-${cofo}`} container item xs={12} style={{ padding: "0px 0px 10px 15px" }}>

              <h3 style={{ display: "inline-block" }}>
                <div style={{ color: "#009BCE", width: 30, float: "left" }}>
                  {getIcon("intervals")}
                </div>
                {Trad('interval_settings')}

              </h3>

              <Grid item xs={12} style={{ padding: '0px 10px 10px 0px' }}>

                <div style={{ float: 'left', display: 'flex', marginBottom: 20 }}>
                  <div style={{ padding: '15px 10px 0 0', fontSize: '1rem', fontWeight: 500 }}>{Trad(`Tarification`)}</div>
                  <AdwAutocomplete
                    disableClearable
                    style={{ width: 150 }}
                    options={["CO", "FO", "FOS"]}
                    value={cofo}
                    onChange={(event, value) =>
                      this.setState({ cofo: value as netType })} />
                </div>

                <div style={{ float: 'right' }} className="clearfix">
                  <CustomButton
                    Label={Trad("add")}
                    disabled={this.state.buttonDisabled}
                    className={"custom_btn_primary"}
                    onClick={() => {

                      const existing = this.discountTab.getDiscount();
                      const maxs = existing.map(e => (e as unknown as ref_Intervals).Maximum);
                      const max = Math.max(...maxs) ?? 0;
                      const Minimum = max > 0 ? max + 1 : 0;
                      const Maximum = max > 0 ? max + 2 : 0;

                      this.discountTab.addNewFromDiscount({
                        ...this.state.discount,
                        Minimum,
                        Maximum,
                        Rate: 0,
                        Value: 0,
                        CO: undefined,
                        FO: undefined,
                        FOS: undefined
                      });
                      // this.disableButton()
                    }}
                  />
                </div>
              </Grid>

              {
                discountClasses &&
                <Grid item xs={12} style={{ padding: '0px 10px 10px 0px' }}>
                  <div style={{ display: 'flex', margin: '0 0 0 0', width: '100%', fontSize: 14, fontWeight: 'bold' }}>
                    <div style={{ width: numberCols, textAlign: 'right', paddingRight: 10, paddingBottom: 10 }}>{Trad("from_interval")}</div>
                    <div style={{ width: numberCols, textAlign: 'right', paddingRight: 10, paddingBottom: 10 }}>{Trad("to_interval")}</div>
                    <div style={{ width: numberCols, textAlign: 'right', paddingRight: 10, paddingBottom: 10 }}>{Trad("rate")}</div>
                    <div style={{ width: numberCols, textAlign: 'right', paddingRight: 10, paddingBottom: 10 }}>{Trad("amount")}</div>
                    <div style={{ width: lastCol + scrollSize }}></div>
                  </div>
                  <DiscountCategoryArray
                    isForIntervals
                    // endEdit={this.disableButton}
                    validator={{
                      "Minimum": checkMinimum,
                      "Maximum": checkMaximum,
                      "Rate": checkValueRate(true),
                      "Value": checkValueRate()

                    }}
                    key={this.state.key}
                    intervalActivated={true}
                    draggable={() => false}
                    allClasses={discountClasses}
                    allTypes={discountTypes}
                    currencyCode={this.props.currencyCode}
                    onChange={() => this.UpdateIntervals()}
                    refreshAfterDelete={() => this.setState({ key: this.state.key + 1 })}
                    ref={r => this.discountTab = r}
                    discountsProvider={() => intervals}
                    classes={discountClasses?.map(d => d["@rid"])}
                    type={cofo} />
                </Grid>
              }
            </Grid>



          </>
        }
      />
    );
  }
}
