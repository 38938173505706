import * as React from 'react'
import { ref_Messages } from 'hub-lib/dto/client/ref_Messages.bin';
import { BuildLabel, ref_SchedulerConfigurations } from 'hub-lib/dto/client/ref_SchedulerConfigurations.bin';
import { Client } from 'hub-lib/client/client.bin';
import { LabelSchedulerSeparator } from './GroupComponent';
import { TemplateToCSS } from '../../VertexGrid/Messages/MessagesToCSS';
import { memoizeAsync, toArray } from 'hub-lib/tools.bin';
import { DataProvider } from 'hub-lib/provider';

class TProps {
    template: ref_SchedulerConfigurations;
    messages: ref_Messages[];
    labelField?: 'LabelBuild' | 'LabelBuildTop' | 'LabelBuildBottom' | 'StartLabel' | 'EndLabel';
}

const getMetadataMemo = memoizeAsync((documentType: string) => DataProvider.getMetadata(documentType));

export function TextComponent({ messages, template, labelField = 'LabelBuild' }: TProps) {

    const [text, setText] = React.useState<string[]>(null);

    React.useEffect(() => {
        if (text == null)
            Promise.resolve().then(async () => {
                if (toArray(template?.[labelField])?.length) {
                    const allMetadata = await getMetadataMemo(ref_Messages.name);
                    const text = await BuildLabel(messages, template, allMetadata, async (v, p) => (await Client.searchVertex(v, p))?.data?.results, labelField);
                    setText(text.filter(t => t != "" && typeof t == "string"))
                }
            })
    })

    const style = TemplateToCSS(template?.StyleDefault);

    return <span className="custom-TextComponent">{text?.map((t, i) => {
        return <span key={`auto-${i}`}>
            {i > 0 && <LabelSchedulerSeparator style={{ backgroundColor: style.color, borderColor: style.color }} />}
            {t}</span>
    })}</span>
}
