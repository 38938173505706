import React from "react";

type AutoResizeUpdaterProps = { autoResize: boolean, refreshContent: (updateColumns?: boolean) => void };
export function AutoResizeUpdater({ autoResize, refreshContent }: AutoResizeUpdaterProps) {
    const isMounted = React.useRef(false);
    React.useEffect(() => {
        if (isMounted.current) {
            console.log('[AutoResizeUpdater]');
            refreshContent(false);
        }
        isMounted.current = true;
    }, [autoResize]);

    return <></>
}