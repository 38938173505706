import versions from './version.json';

export function getLatestVersion(): string {
    return versions["latest"];
}

export function isVersionMatching(version: string, versionName: string, service: string): boolean {
    if (versionName === versions["latest"])
        return true;
    return versions[versionName]?.[service] === version;
}