import { ref_AdvertiserGroupsId } from "../../models/orientdb/ref_AdvertiserGroups.bin";
import { ref_AdvertisersId } from "../../models/orientdb/ref_Advertisers.bin";
import { ref_BrandsId } from "../../models/orientdb/ref_Brands.bin";
import { ref_ProductsId } from "../../models/orientdb/ref_Products.bin";
import { ref_PropertyId } from "../../models/orientdb/ref_Property.bin";
import { ref_SupportsId } from "../../models/orientdb/ref_Supports.bin";
import { ref_MessagesId } from "./ref_Messages.bin";
import { ref_GlobalAgreements } from "./ref_GlobalAgreements.bin";
import { ref_AgencyGroupsId } from "../../models/orientdb/ref_AgencyGroups.bin";
import { ImportElementStatus, ref_Imports } from "../admin/ref_Imports.bin";

export enum eAgreementConfig {
    COFOFOS = "COFOFOS",
    FOFOS = "FOFOS",
    FOS = "FOS",
}

export class ref_Agreements extends ref_GlobalAgreements {
    AutoUpdate: boolean;
    IntervalsAutoUpdate: boolean;
    AdvertiserGroup?: ref_AdvertiserGroupsId;
    Advertisers?: ref_AdvertisersId[];
    Group?: ref_AgencyGroupsId;
    Brands?: ref_BrandsId[];
    Products?: ref_ProductsId[];
    Formats?: ref_PropertyId[];
    Placements?: ref_PropertyId[];
    Support: ref_SupportsId;
    Messages?: ref_MessagesId[]; // not in db (used to display messages)

	/**
	 * @description Old GlobalAgreement flag, keep it for prevent bug from old messages
	 */
	Global?: boolean;

    Import?: {
		Data: { [key: string]: any };
		Id: ref_Imports["@rid"];
		Status: ImportElementStatus;
	}
}
