
import axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { userArg, LoginResponse } from '../dto/admin/login.bin';
import { loginRoute, searchRefRoute, graphRoute, externalRoute, metadataRoute, linkRoute, dimensionRoute, logsRoute, versionRoute, tokenRoute, localeRoute, domainsRoute, resetRoute, newpassRoute, fileRoute, alertRoute, externalimportRoute, sellsyRoute } from '../index.bin';
import { searchArg, SearchResponse, MetaResp, linkType, referentialHasViewsResp, res as TRes, orientResp, CreateBody, UpdateBody, DeleteBody, DeleteBodyContent, CopyBody, PersistBody, CancelBody } from '../models/types.bin';
import { graphArg, GraphResponse } from '../models/graph.bin';
import { distinctArg, AggregateExport, TableExport } from '../models/external.bin';
import { referentialLink } from '../models/link.bin';
import { ref_Sources } from '../models/orientdb/ref_Sources.bin';
import { ref_KPIs } from '../models/orientdb/ref_KPIs.bin';
import { lnk_HasKPI } from '../models/orientdb/lnk_HasKPI.bin';
import { ref_SourceTypes } from '../models/orientdb/ref_SourceTypes.bin';
import { ref_Model } from '../models/orientdb/ref_Model.bin';
import { rid } from '../models/orientdb/CommonTypes.bin';
import { eAction, logUserArg } from '../models/requests.bin';
import { ReferentialHasViews } from '../models/orientdb/ReferentialHasViews.bin';
import { ClassProperty } from '../models/VertexProperty.bin';
import { Sort } from 'format-lib/index.bin';
import { clientCache } from './clientCache.bin';
import { Locale, Trad, TradProp } from 'trad-lib';
import { EventEmitter } from 'events';
import { Table } from 'adwone-engine/types.bin';
import { eSocketMsg } from '../models/socket.bin';
import { eFileType, FileDescriptor } from '../models/FileDescriptor.bin';
import { LinkedEstimate, ref_Estimates } from '../dto/client/ref_Estimates.bin';
import { ExternalCampaignImport } from 'hub-lib/models/custom/ExternalCampaignImport';
import { clone, duplicate, SerializeError, toArray } from '../tools.bin';
import { ADWProperty } from '../types';
import { ref_Messages } from '../dto/client/ref_Messages.bin';
import { Domain } from '../models/domain';
import { OjdWave } from '../models/OjdWave';
import { ClientOAUT2 } from './oaut2.bin';
import { LinkedDocuments, SellsyCampany } from 'client-sellsy/type.bin';
import { ref_Campaigns } from '../dto/client/ref_Campaigns.bin';
import { ref_UserSessions } from '../dto/client/ref_UserSessions';
import { QuestionsForm } from '../dto/QuestionsForm';
import { AdwoneEnvironments, GetFrontUrlFromEnv } from 'adw-url-provider';

export const ClientAxios = axios;

export type PromiseResolve<T> = (value?: T | PromiseLike<T>) => void;
export type PromiseReject = (error?: any) => void;

axios.defaults.withCredentials = true;

export class URLServerProvider {
    static provide(path?: string): string {
        throw new Error("URLServerProvider provide Not implemented")
    }
}

/**
 * @description Find a redirection with the splited (NO empty) path
 * @author Bastien Douib
 * @param {({ [key: string]: string | typeof dico })} dico
 * @param {string[]} path
 * @returns {*}  {(string | null)}
 */
export const redirectionFinder = (dico: { [key: string]: string | typeof dico }, path: string[]): string | null => {
    const find = dico[path[0]];
    path.shift();
    if (find) {
        if (typeof find === "string") return find;
        return redirectionFinder(find, path);
    }
    return null;
}

export class URLAdwoneProvider {
    static provide(): string {
        return GetFrontUrlFromEnv(process.env.NODE_ENV, process.env.ADWONE_ENV);
    }

    static provideFrontUrls(): string[] {
        const allUrls = AdwoneEnvironments.map(u => toArray(u.front)).flat();
        return allUrls;
    }
}

// export let client_id = 'hub-website';
// export let client_secret = '5159949e3ee3b18d54cfed2d721658ff5298ccf4';

// let headers = {
//     withCredentials: true,
//     headers: {
//         'Content-Type': 'application/json'
//     }
// };

export type Resp<T> = { data: T, status: number, statusText: string, headers: any, config: any, request?: any, error?: any };
export type LinkArg = { edgeType: string, params: any, to: rid };
export type AddVertexArg = { params: any, link?: LinkArg };

// let access_token = undefined;
// let refresh_token = undefined;

// /** for code sharing purposes (browser + server side) */
// if (typeof localStorage !== "undefined") {
//     access_token = localStorage.getItem('access_token');
//     refresh_token = localStorage.getItem('refresh_token');
// }

// function setHeaderAuthorization(bearer) {
//     headers.headers["Authorization"] = `Bearer ${bearer}`;
// }

// if (access_token)
//     setHeaderAuthorization(access_token);

export function GetUrlServer(path?: string) {
    return URLServerProvider.provide(path ?? null);
}

export type keyOf<T> = {
    [prop in keyof T]?: any;
};

class CachingPromise {

    static dico: { [prop: string]: Promise<any> } = {}

    static SetCache(key: string, promise: () => Promise<any>) {
        const existing = this.dico[key];
        if (!existing) {
            const newPromise = promise().then(values => {
                delete this.dico[key];
                return values;
            }).catch(e => {
                delete this.dico[key];
                throw e;
            });
            this.dico[key] = newPromise;
            return newPromise;
        }
        return existing.then(values => {
            return values;
        });
    }
}

type CreationType = { vertex: string, res: any[] | any };
type UpdateType = { vertex: string, params: any[] | any };
type DeleteType = { vertex: string, res?: any[] | any };
type CopyType = { vertex: string, res: any[] | any };
type PersistType = { vertex: string, res: any[] | any };
type CancelType = { vertex: string, res: any[] | any };

class DeleteEmitter extends EventEmitter {
    emit(event: "successful" | "fail", type: DeleteType): boolean {
        return super.emit(event, type);
    }

    addListener(event: "successful" | "fail", listener: (type: DeleteType) => void): any {
        return super.addListener(event, listener);
    }
}
class CreationEmitter extends EventEmitter {
    emit(event: "successful" | "fail", arg: CreationType): boolean {
        return super.emit(event, arg);
    }

    addListener(event: "successful" | "fail", listener: (arg: CreationType) => void): any {
        return super.addListener(event, listener);
    }
}
class UpdateEmitter extends EventEmitter {
    emit(event: "successful" | "fail", params: UpdateType): boolean {
        return super.emit(event, params);
    }

    addListener(event: "successful" | "fail", listener: (arg: UpdateType) => void): any {
        return super.addListener(event, listener);
    }
}
class CopyEmitter extends EventEmitter {
    emit(event: "successful" | "fail", arg: CopyType): boolean {
        return super.emit(event, arg);
    }

    addListener(event: "successful" | "fail", listener: (arg: CopyType) => void): any {
        return super.addListener(event, listener);
    }
}

class PersistEmitter extends EventEmitter {
    emit(event: "successful" | "fail", arg: PersistType): boolean {
        return super.emit(event, arg);
    }

    addListener(event: "successful" | "fail", listener: (arg: PersistType) => void): any {
        return super.addListener(event, listener);
    }
}

class CancelEmitter extends EventEmitter {
    emit(event: "successful" | "fail", arg: CancelType): boolean {
        return super.emit(event, arg);
    }

    addListener(event: "successful" | "fail", listener: (arg: CancelType) => void): any {
        return super.addListener(event, listener);
    }
}

type ParamsDecorator = { vertex: string, decorate: (params: any) => any };
export class ClientHUB extends ClientOAUT2 {

    static searchDecorator: ParamsDecorator[] = [];

    static AddSearchDecorator(decorator: ParamsDecorator) {
        ClientHUB.searchDecorator.push(decorator);
    }

    static SearchDecorate(vertex: string, params: any) {
        ClientHUB.searchDecorator
            .filter(d => d.vertex == vertex)
            .forEach(d => params = d.decorate(duplicate(params)));
        return params;
    }

    onErrorEvent: EventEmitter = new EventEmitter();
    notificationEvent: EventEmitter = new EventEmitter();
    onGetStatus: EventEmitter = new EventEmitter();
    onRequestDone: EventEmitter = new EventEmitter();
    onCreationVertex: CreationEmitter = new CreationEmitter();
    onReadVertex: CreationEmitter = new CreationEmitter();
    onCopyVertex: CopyEmitter = new CopyEmitter();
    onPersistVertex: PersistEmitter = new PersistEmitter();
    onCancelVertex: CancelEmitter = new CancelEmitter();
    onUpdateVertex: UpdateEmitter = new UpdateEmitter();
    onDeleteVertex: DeleteEmitter = new DeleteEmitter();

    private user: LoginResponse

    getSellsyInfos(): Promise<Resp<{ sellsy: ref_UserSessions["Sellsy"], urlAuthorization: string }>> {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/infos", {});
    }

    // getSellsyLinkedDocuments(body: ref_Estimates): Promise<Resp<LinkedDocuments>> {
    //     return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/search/linked", body);
    // }

    healthSellsy(): Promise<Resp<any>> {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + "/health", {});
    }

    searchSellsyCompanies(body: ref_Campaigns): Promise<Resp<SellsyCampany[]>> {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + `/search/companies`, body);
    }

    saveToken(body: { code: string, api: string }): Promise<any> {
        const path = `/${body.api}/token`;
        return this.execPost(URLServerProvider.provide(path) + path, body)
    }

    loginSellsy(): Promise<any> {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + `/login`, {});
    }

    logoutSellsy(): Promise<any> {
        return this.execPost(URLServerProvider.provide(sellsyRoute) + sellsyRoute + `/logout`, {});
    }
    // private checkRefreshToken = (req: () => Promise<any>) => {
    //     return req().catch(err => {
    //         if (err?.response?.data?.error == "invalid_token") {
    //             return this.geRefreshtToken().then(() => req())
    //         }
    //         throw err;
    //     });
    // }

    //private execPost(url, body, _headers): Promise<any> {
    //     return this.checkRefreshToken(() => axios.post(url, body, _headers))
    //         .then((res) => {
    //             this.onRequestDone.emit('POST', url)
    //             return res;
    //         });
    // }

    // private execPut(url, body, _headers): Promise<any> {
    //     return this.checkRefreshToken(() => axios.put(url, body, _headers))
    //         .then((res) => {
    //             this.onRequestDone.emit('PUT', url)
    //             return res;
    //         });
    // }

    // private execGet(url, _headers): Promise<any> {
    //     return this.checkRefreshToken(() => axios.get(url, _headers))
    //         .then((res) => {
    //             this.onRequestDone.emit('GET', url)
    //             return res;
    //         });
    // }

    // private execDelete(url, _headers, data = undefined): Promise<any> {
    //     return this.checkRefreshToken(() => axios.delete(url, { ..._headers, data }))
    //         .then((res) => {
    //             this.onRequestDone.emit('DELETE', url)
    //             return res;
    //         });
    // }

    bypassUser(mail: string) {
        const path = loginRoute + "/bypass";
        return this.execGet(URLServerProvider.provide(path) + path + `?login=${mail}`);
    }

    async getUser(force?: boolean) {
        if (!this.user || force) {
            this.user = (await this.getStatus())?.data
            return this.user
        }
        return this.user
    }
    /**
     * To log the user on the server
     * @param name
     * @param password
     */
    login(name: string, password: string): Promise<Resp<LoginResponse>> {
        let body: userArg = { username: name, password };
        const path = loginRoute + "/default";
        return this.execPost(URLServerProvider.provide(path) + path, body);
    }

    Post(route: string, body: any, config?: AxiosRequestConfig) {
        return this.execPost(URLServerProvider.provide(route) + route, body, config);
    }

    GetCookie() {
        return this.execGet(URLServerProvider.provide("/cookie") + "/cookie");
    }

    SetLocale(locale: Locale) {
        return this.execPost(URLServerProvider.provide(localeRoute) + localeRoute, { locale });
    }

    /**
     * To logout the user on the server
     * @param none
     */
    async logout(): Promise<any> {
        const r = await this.execDelete(URLServerProvider.provide(loginRoute) + loginRoute);
        this.onGetStatus.emit("DELETE");
        localStorage.removeItem("user");
        return r;
    }

    /**
     * To get connection status of the user on the server
     * @param none
     */
    getStatus(): Promise<Resp<LoginResponse>> {
        const path = loginRoute + "/session/info";
        return this.execGet(URLServerProvider.provide(path) + path)
            .then(async r => {
                this.onGetStatus.emit("GET", r.data.user);
                return r;
            });
    }

    async getElement<T>(path: string): Promise<T> {
        return (await this.execGet(URLServerProvider.provide(path) + path))?.data;
    }

    /**
     * Search texts in referentials
     * @param texts
     */
    search(texts: string[]): Promise<Resp<SearchResponse>> {
        let body: searchArg = { texts };
        return this.execPost(URLServerProvider.provide(searchRefRoute) + searchRefRoute, body);
    }

    /**
     * Search texts in referentials
     * @param texts
     */
    graph(id: string): Promise<Resp<GraphResponse>> {
        let body: graphArg = { id };
        return this.execPost(URLServerProvider.provide(graphRoute) + graphRoute, body);
    }

    /**
     * Aggregate data
     * @param body
     */
    aggregate<T>(body: AggregateExport, cancelToken?: CancelToken): Promise<Resp<{ table: Table<T> }>> {
        const options = cancelToken ? { cancelToken } : {};
        return this.execPost(URLServerProvider.provide(externalRoute) + externalRoute, body, options);
    }

    /**
     * Distinct
     * @param body
     */
    distinct(body: distinctArg): Promise<Resp<TRes<string>>> {
        const path = externalRoute + "/distinct";
        return this.execPost(URLServerProvider.provide(path) + path, body);
    }

    async downloadTcdExport(args: any) {
        const path = externalRoute + `/tcdexport`;
        function pad(s) { return (s < 10) ? '0' + s : s; }

        try {
            const now = new Date();
            const date = [now.getFullYear(), pad(now.getMonth() + 1), pad(now.getDate())].join('-');
            const hour = [pad(now.getHours()), pad(now.getMinutes()), pad(now.getSeconds())].join('-');
            const res = await this.execPost(URLServerProvider.provide(path) + path, args, { responseType: 'blob' });
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `export_PivotGrid_${date}_${hour}.xlsx`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            try {
                const error = JSON.parse(await e.response.data.text());
                this.onErrorEvent.emit("fail", { message: Trad('cannot_export_excel'), error: error });
            } catch (error_1) {
                this.notificationEvent.emit(eSocketMsg.notification, { message: Trad("cannot_export_excel"), messageType: "error" });
            }
        }
    }

    /**
     * Download excel csv or formated
     * @param type
     * @param body
     * @param title
     */
    async downloadExport(type: "csv" | "formated", body: AggregateExport | TableExport, title: string): Promise<Resp<{ table: Table<any> }>> {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date();
        let date = [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
        let hour = [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join('-');

        //this.notificationEvent.emit(eSocketMsg.notification, { message: Trad("export_in_progress"), messageType: "info" });

        this.log({ Category: `export_${type}`, Action: body.document, Informations: { body } });

        const path = externalRoute + `/export/${type}`;
        try {
            const res = await this.execPost(URLServerProvider.provide(path) + path, body, { responseType: 'blob' });
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `export_${title}_${date}_${hour}.${type === "formated" ? "xlsx" : "csv"}`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return res;
        } catch (e) {
            try {
                const error = JSON.parse(await e.response.data.text());
                this.onErrorEvent.emit("fail", { message: Trad('cannot_export_excel'), error: error });
            } catch (error_1) {
                this.notificationEvent.emit(eSocketMsg.notification, { message: Trad("cannot_export_excel"), messageType: "error" });
            }
        }
    }

    /**
     * Get available sources for the current user
     */
    getSources(): Promise<Resp<TRes<ref_Sources>>> {
        const path = `/${ref_Sources.name}`;
        return this.execGet(URLServerProvider.provide(path) + path);
    }

    /**
     * Get available type link of dimensions
     */
    getDimensionLinks(): Promise<Resp<referentialHasViewsResp>> {
        const path = `/${ReferentialHasViews.name}`;
        return this.execGet(URLServerProvider.provide(path) + path);
    }

    /**
     * get link types
     * @param dimension
     */
    getDimensionLinkTypes(dimension: string): Promise<Resp<MetaResp>> {
        const path = metadataRoute + `/${encodeURI(dimension)}/children`;
        return this.execGet(URLServerProvider.provide(path) + path);
    }

    cacheMetadata = {};
    async getMetadata(dimension: string, all: boolean = false): Promise<Resp<TRes<ClassProperty>>> {
        try {
            const key = dimension + all;
            if (!this.cacheMetadata[key]) {
                const path = metadataRoute + `/${encodeURI(dimension)}?all=${all}`;
                this.cacheMetadata[key] = duplicate((await this.execGet(URLServerProvider.provide(path) + path))?.data?.results);
            }
            return { data: { results: duplicate(this.cacheMetadata[key]) } } as any;
        } catch (error) {

        }
        return { data: { results: [] } } as any;
    }

    cacheAllMetadata = {};
    async getAllMetadata(dimension: string): Promise<Resp<TRes<ClassProperty>>> {
        if (!this.cacheAllMetadata[dimension]) {
            const path = metadataRoute + `/${encodeURI(dimension)}/getall`;
            this.cacheAllMetadata[dimension] = duplicate((await this.execGet(URLServerProvider.provide(path) + path))?.data?.results);
        }
        return { data: { results: duplicate(this.cacheAllMetadata[dimension]) } } as any;
    }

    private sort(vertexName) {
        return res => {
            if (res?.data?.results)
                res.data.results = Sort<any>(vertexName, res.data.results);
            return res;
        }
    }

    resetPassword(mail: string) {
        const path = `${loginRoute}${resetRoute}`;
        return this.execPost(URLServerProvider.provide(path) + path, { mail })
    }

    setNewPassword(token: string, password: string) {
        const path = `${loginRoute}${newpassRoute}`;
        return this.execPost(URLServerProvider.provide(path) + path, { token, password })
    }

    /*Ojd */
    getWaves(params: any = {}): Promise<string[]> {
        return this.execPost(URLServerProvider.provide("/ojd/dates") + '/ojd/dates', params, { timeout: 2000 })
            .then((res) => {
                return res?.data?.results;
            });
    }

    getOjd(params: any = {}): Promise<OjdWave[]> {
        return this.execPost(URLServerProvider.provide("/ojd/search") + '/ojd/search', params)
            .then((res) => {
                return res.data.results;
            });
    }

    /*hierarchy search*/
    searchVertex<T>(vertexName: string, _params: { "_operators"?: any } & any = {}, notify?: boolean, options?: { cache }): Promise<{ data: { results: any[] } }> {

        const params = ClientHUB.SearchDecorate(vertexName, _params);

        if (options?.cache === undefined || options?.cache === true) {
            let cache = clientCache.getCache(vertexName, params);
            if (cache) return Promise.resolve(cache).then(clone);
        }

        const path = "/" + vertexName + "/search";
        const fullPath = URLServerProvider.provide(path) + path;
        const cb = () => this.execPost(fullPath, params)
            .then(this.sort(vertexName))
            .then((res) => {
                clientCache.setCache(vertexName, params, res);
                return res;
            }).catch(error => {
                if (notify) {
                    try {
                        this.onReadVertex.emit("fail", { vertex: vertexName, res: error.response.data })
                    } catch (e) {

                    }
                }

                this.log({
                    Category: vertexName, Action: eAction.Read,
                    Informations: {
                        errorCode: error?.response?.status,
                        responseData: error?.response?.data ?? 'no response data',
                        params,
                        error
                    }, Status: "fail"
                });
                throw error
            });

        return CachingPromise.SetCache(JSON.stringify({ ...(params ?? {}), fullPath }), cb) as any;
    }

    protected onGetRefreshTokenError(err: any): void {
        // this.log({
        //     Category: 'onGetRefreshTokenError',
        //     Informations: {
        //         serializedError: SerializeError(err),
        //     }
        // });
    }

    protected onRequestError(err: any, args): void {
        const time = args.time;
        let category = `onRequestError ${args?.url ?? ""}`.trim();
        if (time) category += ` in ${time}ms`;

        const error = {
            args,
            Category: category,
            Action: err?.message ?? err?.response?.statusText ?? err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data?.error_description ?? err?.response?.data?.error_code ?? err?.response?.data?.error_message ?? err?.response?.data ?? 'no message',
            Informations: {
                serializedError: SerializeError(err),
            }
        }
        console.error('[onRequestError]', error);
        this.log(error);
    }

    getForm = (vertexName: string, params: any = {}): Promise<QuestionsForm> => {
        const path = "/" + vertexName + "/search/form";
        return this.execPost(URLServerProvider.provide(path) + path, params).then(res => res.data);
    }

    getLinkedHierchy = (vertexName: string, body: any): Promise<LinkedEstimate[]> => {
        const path = "/" + vertexName + "/search/linkedhierchy";
        return this.execPost(URLServerProvider.provide(path) + path, body).then(res => res.data);
    }

    countVertex<T>(vertexName: string, params: any = {}): Promise<{ data: { results: number } }> {
        const path = "/" + vertexName + "/count";
        return this.execPost(URLServerProvider.provide(path) + path, params)
    }

    async searchVertexTyped<T>(vertexClass: new () => T, _params: { "_operators"?: any } & any = {}): Promise<T[]> {
        const params = ClientHUB.SearchDecorate(vertexClass.name, _params);
        return (await this.searchVertex(vertexClass.name, params))?.data?.results;
    }

    searchFiles(params: any = {}, admin?: boolean): Promise<{ data: FileDescriptor[] }> {
        const adminPath = admin ? "/admin" : "";
        const path = `${fileRoute}/search${adminPath}`;
        return this.execPost(URLServerProvider.provide(path) + path, params);
    }

    deleteFiles(params: any = {}): Promise<{ data: FileDescriptor[] }> {
        const path = `${fileRoute}/delete`;
        return this.execPost(URLServerProvider.provide(path) + path, params);
    }

    dowloadFile(params?: { filename: string, _id: string }): Promise<{ data: FileDescriptor[] }> {
        let path = `${fileRoute}/download`;
        if (!params) path += '/default';
        return this.execPost(URLServerProvider.provide(path) + path, params, { responseType: 'blob' })
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', params?.filename ?? "default.xlsx"); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                return res;
            });
    }

    getURLAttachmentFile(params?: { _id: string }): Promise<string> {
        let path = `${fileRoute}/attachment/download`;
        if (!params) path += '/default';
        return this.execPost(URLServerProvider.provide(path) + path, params, { responseType: 'blob' })
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                return downloadUrl;
            });
    }

    dowloadAttachmentFile(params?: { filename: string, _id: string }): Promise<{ data: FileDescriptor[] }> {
        let path = `${fileRoute}/attachment/download`;
        if (!params) path += '/default';
        return this.execPost(URLServerProvider.provide(path) + path, params, { responseType: 'blob' })
            .then((res) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', params?.filename ?? "default.xlsx"); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                return res;
            });
    }

    uploadFile(file: any, params: { docType: eFileType, company: rid, Customer: rid }) {

        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            file,
            file.name
        );

        formData.append("docType", params.docType);
        formData.append("company", params.company);
        formData.append("Customer", params.Customer);

        const path = `${fileRoute}`;
        return axios.post(URLServerProvider.provide(path) + path, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    //create new vertex in hierarchy
    createVertex<T>(vertexName: string, params: CreateBody<T>, notify?: boolean): Promise<{ data: { results: T } }>
    createVertex<T>(vertexName: string, params: CreateBody<T>[], notify?: boolean): Promise<{ data: { results: T[] } }>
    async createVertex<T>(vertexName: string, params: CreateBody<T> | CreateBody<T>[], notify: boolean = true): Promise<{ data: { results: T } } | { data: { results: T[] } }> {
        clientCache.clearCache(vertexName);
        const path = "/" + vertexName;
        try {
            const res = await this.execPost(URLServerProvider.provide(path) + path, params);
            if (notify) {
                try {
                    this.onCreationVertex.emit("successful", { vertex: vertexName, res: res?.data?.results });
                } catch (e) {
                    console.error(e);
                }
            }

            this.log({ Category: vertexName, Action: eAction.Create, Informations: { params } });
            return res;
        } catch (error) {
            if (notify) {
                try {
                    this.onCreationVertex.emit("fail", { vertex: vertexName, res: error.response.data });
                } catch (e) {
                    console.error(e);
                }
            }

            this.log({ Category: vertexName, Action: eAction.Create, Informations: { params, error }, Status: "fail" });
            throw error;
        }
    }

    async preCreateVertex<T>(vertexType: new () => T, params: Partial<T>[], notify: boolean = true): Promise<Partial<T>[]> {
        const path = "/" + vertexType.name + "/precreate";
        const res = (await this.execPost(URLServerProvider.provide(path) + path, params))?.data?.results;
        return res;
    }

    faileUpdate(vertex: string, params: any) {
        this.onUpdateVertex.emit("fail", { vertex, params })
    }

    async broadcast(body: any, type: string) {
        const path = `/broadcast/${type}`;
        const res = (await this.execPost(URLServerProvider.provide(path) + path, body))?.data?.results;
        return res;
    }

    async getActiveSockets(): Promise<{ connected: { [name: string]: number }, disconnected: { [name: string]: number } }> {
        const path = '/socket-log';
        const res = (await this.execGet(URLServerProvider.provide(path) + path))?.data;
        return res;
    }

    //update vertex
    updateVertex<T>(vertexName: string, params: UpdateBody<T>, notify?: boolean, args?: { successfullMsg?: boolean }): Promise<{ data: { results: T } }>
    updateVertex<T>(vertexName: string, params: UpdateBody<T>[], notify?: boolean, args?: { successfullMsg?: boolean }): Promise<{ data: { results: T[] } }>
    async updateVertex<T>(vertexName: string, params: UpdateBody<T> | UpdateBody<T>[], notify: boolean = true, args?: { successfullMsg?: boolean }): Promise<{ data: { results: T } } | { data: { results: T[] } }> {
        clientCache.clearCache(vertexName);
        const path = "/" + vertexName;
        try {
            const res = await this.execPut(URLServerProvider.provide(path) + path, params);
            if (notify && (!args || args.successfullMsg)) {
                try {
                    this.onUpdateVertex.emit("successful", { vertex: vertexName, params: params });
                } catch (e) {
                    console.error(e);
                }
            }
            this.log({ Category: vertexName, Action: eAction.Update, Informations: { params } });
            return res;
        } catch (error) {
            if (notify) {
                try {
                    this.faileUpdate(vertexName, error.response.data);
                } catch (e) {
                    console.error(e);
                }
            }
            this.log({ Category: vertexName, Action: eAction.Update, Informations: { params, error }, Status: "fail" });
            throw error;
        }
    }

    //delete vertex
    deleteVertex(vertexName, ridElement: DeleteBodyContent, notify: boolean = true, additionalParams?: { [key: string]: any }) {
        clientCache.clearCache(vertexName);
        let deleteBody: DeleteBody = { "@rid": ridElement, ...additionalParams };
        const path = "/" + vertexName;
        return this.execDelete(URLServerProvider.provide(path) + path, {}, deleteBody)
            .then((res) => {

                if (notify) {
                    try {
                        this.onDeleteVertex.emit("successful", { vertex: vertexName })

                    } catch (error) {

                    }
                }

                this.log({ Category: vertexName, Action: eAction.Delete, Informations: { deleteBody } });

                return res;
            }).catch(e => {
                if (notify) {
                    try {
                        this.onDeleteVertex.emit("fail", { vertex: vertexName, res: e.response.data })
                    } catch (error) {

                    }
                }
                this.log({ Category: vertexName, Action: eAction.Delete, Informations: { deleteBody, error: e }, Status: "fail" });
                throw e
            });
    }

    //copy vertex
    copyVertex<T>(vertexName, params: CopyBody, notify: boolean = true): Promise<{ data: { results: T } }> {
        clientCache.clearCache(vertexName);
        const path = "/" + vertexName + "/copy";
        return this.execPost(URLServerProvider.provide(path) + path, params)
            .then((res) => {

                if (notify) {
                    try {
                        this.onCopyVertex.emit("successful", { vertex: vertexName, res: res?.data?.results })
                    } catch (error) {

                    }
                }
                this.log({ Category: vertexName, Action: eAction.Create + ' - copy', Informations: { params } });
                return res;
            }).catch(e => {
                if (notify) {
                    try {
                        this.onCopyVertex.emit("fail", { vertex: vertexName, res: e.response.data })
                    } catch (error) {

                    }
                }
                throw e
            });
    }

    //persist vertex
    persistVertex(vertexName, params: PersistBody, notify: boolean = true) {
        clientCache.clearCache(vertexName);
        const path = "/" + vertexName + "/persist";
        return this.execPut(URLServerProvider.provide(path) + path, params)
            .then((res) => {

                if (notify) {
                    try {
                        this.onPersistVertex.emit("successful", { vertex: vertexName, res: res?.data?.results })
                    } catch (error) {

                    }
                }

                return res;
            }).catch(e => {
                if (notify) {
                    try {
                        this.onPersistVertex.emit("fail", { vertex: vertexName, res: e.response.data })
                    } catch (error) {

                    }
                }
                throw e
            });
    }

    //copy vertex
    cancelVertex(vertexName, params: CancelBody, notify: boolean = true) {
        clientCache.clearCache(vertexName);
        const path = "/" + vertexName + "/cancel";
        return this.execPut(URLServerProvider.provide(path) + path, params)
            .then((res) => {

                if (notify) {
                    try {
                        this.onCancelVertex.emit("successful", { vertex: vertexName, res: res?.data?.results })
                    } catch (error) {

                    }
                }

                return res;
            }).catch(e => {
                if (notify) {
                    try {
                        this.onCancelVertex.emit("fail", { vertex: vertexName, res: e.response.data })
                    } catch (error) {

                    }
                }
                throw e
            });
    }

    //get calendar events from Microsoft account
    getCalendarEvents(params: any) {
        const path = "/calendar/api";
        return this.execPost(URLServerProvider.provide(path) + path, params);
    }

    /**
     * Get all redirection mails
     */
    getRedirectDomains(): Promise<Resp<TRes<Domain>>> {
        return this.execGet(URLServerProvider.provide(domainsRoute) + domainsRoute);
    }

    getAlert(): Promise<Resp<string>> {
        return this.execGet(URLServerProvider.provide(alertRoute) + alertRoute);
    }

    /**
     * link nodes
     * @param args
     */
    link(args: referentialLink[]) {
        return this.execPost(URLServerProvider.provide(linkRoute) + linkRoute, args);
    }

    link2<T>(edge: new () => T, params?: { [prop in keyof T]?: any }[]) {
        const path = linkRoute + "/" + edge.name;
        return this.execPost(URLServerProvider.provide(path) + path, params);
    }

    /**
     * Get modalities of the parameter dimension
     */
    async getDimensions(): Promise<ADWProperty[]> {
        let url = URLServerProvider.provide(dimensionRoute) + dimensionRoute;
        let res: ADWProperty[] = (await this.execGet(url))?.data?.results ?? [];
        res = res.sort((a, b) => TradProp(a.field as any, ref_Messages).localeCompare(TradProp(b.field as any, ref_Messages)));
        return res;
    }

    /**
     * Get modalities of the parameter dimension
     */
    get<T>(dim: new () => T, params?: keyOf<T>, properties?: string[]): Promise<Resp<TRes<T>>> {
        return this.searchVertex(dim.name, params) as any;
    }

    // getObjects(objectType: string, params?: { [prop: string]: any }): Promise<Resp<TRes<any>>> {

    //     let cache = clientCache.getCache(objectType, params);
    //     if (cache) return Promise.resolve(cache);

    //     let parameters = !params ? "" : `/? ${encode(params)}`;
    //     const path = `/ ${encodeURI(objectType)}${parameters}`;
    //     return this.execGet(URLServerProvider.provide(path) + path)
    //         .then(this.sort(objectType))
    //         .then((res) => {
    //             clientCache.setCache(objectType, params, res);
    //             return res;
    //         });
    // }

    /**
     *
     * @param linktype ex: HasSupportADWView
     */
    getViews(linktype: string) {
        const path = `/ link / ${encodeURI(linktype)}`;
        return this.execGet(URLServerProvider.provide(path) + path);
    }

    /**
     * Add vertices
     * @param dimension
     * @param body
     */
    addModalities(dimension: string, body: AddVertexArg[] | any): Promise<Resp<SearchResponse>> {
        const path = `/ ${encodeURI(dimension)}`;
        return this.execPost(URLServerProvider.provide(path) + path, body);
    }

    /**
     * Update vertices
     * @param dimension
     * @param body
     */
    updateModalities(dimension: string, body: any[] | any): Promise<Resp<SearchResponse>> {
        const path = `/ ${encodeURI(dimension)}`;
        return this.execPost(URLServerProvider.provide(path) + path, body);
    }

    /**
     * To log user action
     * @param Action title of action, ex: "Research"
     * @param Informations ex: { Search: "Peugeot", Category: "Advertiser" }
     * @param details verbosity detail: 0 is default, the more is detail the less is important info
     */
    async log(funcArg: Partial<logUserArg>) {
        try {
            const arg: logUserArg = {
                ...new logUserArg(),
                ...funcArg,
                url: window.location.href
            }
            const path = `${logsRoute}/user`;
            await super.execPostBase(URLServerProvider.provide(path) + path, arg);
        } catch (error) {

        }
    }

    getHubVersion(): Promise<Resp<string>> {
        return this.execGet(URLServerProvider.provide(versionRoute) + versionRoute);
    }

    // getToken(code: string): Promise<any> {
    //     let body = {
    //         client_id,
    //         client_secret,
    //         redirect_uri: `${window.origin}${loginRoute}`,
    //         grant_type: 'authorization_code',
    //         code: code
    //     }
    //     return axios.post(`${URLServerProvider.provide(tokenRoute)}${tokenRoute}`, qs.stringify(body), {
    //         ...headers,
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //     }).then(this.handleNewToken);
    // }

    // geRefreshtToken(): Promise<any> {
    //     let body = {
    //         client_id,
    //         client_secret,
    //         grant_type: 'refresh_token',
    //         refresh_token: refresh_token
    //     }
    //     return axios.post(`${URLServerProvider.provide(tokenRoute)}${tokenRoute}`, qs.stringify(body), {
    //         ...headers,
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    //     }).then(this.handleNewToken);
    // }

    signalInvalidEstimates(ids: rid[] | rid) {
        const path = `/${ref_Estimates.name}/notifyerror`;
        return this.execPost(URLServerProvider.provide(path) + path, Array.isArray(ids) ? ids : [ids]);
    }

    // private handleNewToken = (res: AxiosResponse<any>) => {
    //     access_token = res.data.access_token;
    //     refresh_token = res.data.refresh_token;

    //     localStorage.setItem('access_token', access_token);
    //     localStorage.setItem('refresh_token', refresh_token);

    //     setHeaderAuthorization(access_token);
    //     return res;
    // }

    getMMCascade(buySystem: string) {
        return this.execGet(URLServerProvider.provide('/cascade/insertion/') + `/cascade/insertion/${buySystem}`)
    }

    getMMVersion() {
        return this.execGet(URLServerProvider.provide('/media-ocean/version') + `/media-ocean/version`)
    }

    getStatusAPI() {
        return this.execGet(URLServerProvider.provide('/status') + `/status`).then(v => v?.data)
    }
    getPm2Logs() {
        return this.execGet(URLServerProvider.provide('/pm2logs') + `/pm2logs`).then(v => v?.data)
    }
    getGrafanaUrl() {
        return this.execGet(URLServerProvider.provide('/grafana') + `/grafana`).then(v => v?.data)
    }

    getStatusAPIAdmin() {
        return this.execGet(URLServerProvider.provide('/status/admin') + `/status/admin`).then(v => v?.data)
    }

    async getExternalImport(token: string): Promise<{
        type: string,
        date: Date,
        import: ExternalCampaignImport
    }> {
        const path = externalimportRoute;
        return (await this.execGet(`${URLServerProvider.provide(path)}${path}?token=${token}`))?.data
    }

    async pushExternalImport(body: ExternalCampaignImport): Promise<any> {
        const path = externalimportRoute;
        return (await this.execPost(`${URLServerProvider.provide(path)}${path}`, body))?.data
    }

    async getUserSessions() {
        const path = '/rights';
        return (await this.execGet(`${URLServerProvider.provide(path)}${path}`))?.data?.UserSessions;
    }
}

let isConnected = undefined;
export const createSession = async (login: string, pass: string) => {
    const resp = await Client.login(login, pass);
    const cookie = resp.headers["set-cookie"][0]; // get cookie from request
    axios.defaults.headers.Cookie = cookie;   // attach cookie to axiosInstance for future requests
    //console.log('createSession');
    //console.log(resp);
    isConnected = resp;
    return resp;
};

export class KPIContext {
    Ref?: ref_KPIs;
    View: lnk_HasKPI;
    Source: ref_SourceTypes;
    Model: ref_Model;
}

export const Client = new ClientHUB("hub-website", "5159949e3ee3b18d54cfed2d721658ff5298ccf4", () => URLServerProvider.provide(tokenRoute), () => `${URLServerProvider.provide(tokenRoute)}${tokenRoute}`, () => `${window.origin}${loginRoute}`, {
    client: {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    }
});
