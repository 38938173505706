import * as React from "react";
import "../../../../styles/LinkManager.scss";
import { ReferentialList } from "./ReferentialList";
import { ViewList } from "./ViewList";
import { LinkList } from "./LinkList";

import { RootState, store } from "../../../../redux/store";
import { updateLinks } from "./LinkManagerTools";
import { useEffect } from "react";
import Loader from "../../Loader";
import { useSelector } from "react-redux";
import { Splitter } from "@progress/kendo-react-layout";

function MyLoader() {
  const loading = useSelector((root: RootState) => root.linkMgr.loaders.links);
  return <div className="link-loader-container">{loading && <div className="link-loader-div"><Loader /></div>}</div>
};

export function RowHeader({ children }) {
  return <div className="link-row-header">
    {children}
  </div>
}

export function LinkManager() {

  const [panes, setPanes] = React.useState([
    {
      size: "33%",
      collapsible: true,
    },
    {},
  ]);

  const [panes2, setPanes2] = React.useState([
    {
      size: "40%",
      collapsible: true,
    },
    {},
  ]);

  useEffect(() => {
    const { links } = store.getState().linkMgr;
    if (!links?.length)
      updateLinks();
  });

  const onChange = (event) => {
    setPanes(event.newState);
  };

  const onChange2 = (event) => {
    setPanes2(event.newState);
  };

  return <div className="link_container">

    <MyLoader />

    {/* REFERENTIEL */}
    <Splitter
      className="list"
      panes={panes}
      onChange={onChange}>
      <ReferentialList />



      <Splitter
        className="list"
        panes={panes2}
        onChange={onChange2}>

        {/* LinkList */}
        <LinkList />

        {/* VUES */}
        <ViewList />

      </Splitter>


    </Splitter>


  </div>
}

const LinkColumn = ({ children, style }: any) =>
  <div className="list list-links-column" style={style ?? {}}>
    {children}
  </div>