import { discountExtended } from "../DiscountCategoryArray.bin"

export const checkMaximum = (d: discountExtended, rows: discountExtended[]) => {

    if (d.Minimum > d.Maximum)
        return {
            valid: false
        }

    if (d.Minimum === 0 && d.Maximum === 0)
        return {
            valid: false,
        }

    if (rows.some(r => d.Minimum <= r.Maximum && d.Maximum >= r.Minimum))
        return {
            valid: false,
        }

    return { valid: true }
}

export const checkMinimum = (d: discountExtended, rows: discountExtended[]) => {
    if (d.Minimum > d.Maximum)
        return {
            valid: false,
            message: 'minmum_not_inferior_maximum'
        }

    if (d.Minimum === 0 && d.Maximum === 0)
        return {
            valid: false,
            message: 'minmum_maximum_zero'
        }

    if (rows.some(r => d.Minimum <= r.Maximum && d.Maximum >= r.Minimum))
        return {
            valid: false,
            message: "interval_exists"
        }

    return {
        valid: true
    }
}

export const checkValueRate = (msg: boolean = false) => (d: discountExtended, rows: discountExtended[], type: "CO" | "FO" | "FOS") => {
    const val = type ? d[type] : d;
    if (!val?.Rate && !val?.Value)
        return {
            valid: false,
            message: msg ? "missing_value_rate" : null
        }
    return {
        valid: true
    }
}
export const checkDiscountClass = (d: discountExtended) => {
    if (!d.DiscountClass)
        return {
            valid: false,
            message: "missing_discountClass"
        }
    return {
        valid: true
    }
}