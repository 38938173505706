import * as React from "react";
import { IconButton } from "@material-ui/core";


type CustomIconButtonArgs = {
    onClick?: React.MouseEventHandler<any>;
    onMouseOver?: React.MouseEventHandler<any>;
    children?: any;
    "aria-label"?: string;
    className?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export function CustomIconButton(p: CustomIconButtonArgs) {
    return <IconButton {...p} className={"picto " + (p.className ?? "")}>
        {p.children}
    </IconButton>

}

const p = <IconButton />
