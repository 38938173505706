import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import React from "react";

class TProps {
    elements: {
        text: string;
        href?: string;
        onClick?: () => void
    }[];
    hasSelectedItems?: boolean;
}

export class BreadcrumbsCustom extends React.Component<TProps, any> {

    constructor(props: TProps) {
        super(props);
    }

    render() {

        const { elements, hasSelectedItems } = this.props;

        if (!elements.length)
            return <div></div>

        const copy = [...elements];
        const last = copy.pop();

        return (<Breadcrumbs className={"Breadcrumbs " + (hasSelectedItems ? "element-selected" : "")} aria-label="breadcrumb" style={{ float: 'left' }}>

            {copy.map((link, i) => ((link.href || link.onClick) ?
                <Link key={`Link-${i}`} color="inherit" href={link.href} onClick={() => link?.onClick()} style={{ cursor: "pointer" }}>
                    {link.text}
                </Link> :
                <Typography key={`${Date.now()}-Link-${i}`} color="inherit">{link.text}</Typography>

            ))}

            <p className="current-view" key={`Link-${copy.length + 1}`} color="inherit">{last.text}</p>
        </Breadcrumbs>)
    }
}