import * as React from "react";
import { ref_Discount } from "hub-lib/models/types/vertex.bin";
import { GridCellProps } from '@progress/kendo-react-grid';
import { CustomIconButton } from "../../Generic/CustomIconButton";
import { getIcon } from "adwone-lib/components/IconManager";

export type CellRemoveComponentType = GridCellProps & { isRemovable: (row: ref_Discount) => boolean, onRemoveClick: (row: ref_Discount) => void };
export function CellRemoveComponent({ dataItem, isRemovable, onRemoveClick }: CellRemoveComponentType) {
    const discount = dataItem as ref_Discount;
    return <td className="no-padding" style={{ textAlign: 'center', position: 'relative' }}>
        {isRemovable(discount) && <CustomIconButton className="no-padding" style={{ padding: 0 }} onClick={() => onRemoveClick?.(discount)}>
            {getIcon("clear")}
        </CustomIconButton>}
    </td>
}
